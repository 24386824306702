import React from 'react'
import Cliente from './ClienteItem'

function ClientesLista (props){
    const filterClientes = props.filterClientes.map(item=>{
        return <Cliente item ={item} />
    })
    ;
    

    return (       
                <div className="row p-2">
                    <div className="col s8">
                        <table className="table table-hover">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Código</th>
                                    <th scope="col">Nombre Cliente</th>
                                    <th scope="col">Dirección</th>
                                    <th scope="col">Telefono</th>
                                    <th scope="col">Celular</th>
                                    <th  className="text-center">Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                { filterClientes }
                            </tbody>
                        </table>
                    </div>
                </div>
            );
}
export default ClientesLista
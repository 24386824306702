import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import jsPDF from "jspdf";
import ReactExport from "react-data-export";
import "react-moment";
import moment from "moment";
import { BotonCancelar } from "../componentes/Plantillas/Botones";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class DetallesVentasTurnosActivosEmpresa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DetalleVentasTurnosActivosEmpresa: null
    };

    this.getDetalleVentasTurnosActivosEmpresa = this.getDetalleVentasTurnosActivosEmpresa.bind(
      this
    );
    this.handleSavePDF = this.handleSavePDF.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getDetalleVentasTurnosActivosEmpresa();
  }

  async getDetalleVentasTurnosActivosEmpresa() {
    try {
      const response = await fetch(
        `/api/dashboard/get/detalleventas`
      );
      const DetalleVentasTurnosActivosEmpresa = await response.json();
      this.setState({
        DetalleVentasTurnosActivosEmpresa:
          DetalleVentasTurnosActivosEmpresa.respuesta,
        isLoading: false
      });
      return DetalleVentasTurnosActivosEmpresa;
    } catch (error) {
      this.setState({ error, isLoading: false });
    }
  }

  getDataExportExcel(dataSet) {
    return [
      {
        columns: [
         
          {
            title: "FechaEmision",
            width: { wpx: 100 }
          },
        
          {
            title: "TipoComprobante",
            width: { wpx: 100 }
          },
          {
            title: "Serie",
            width: { wpx: 60 }
          },
          {
            title: "Numero Comprobante",
            width: { wpx: 100 }
          },       
          {
            title: "NroTipoDocumentoCliente",
            width: { wpx: 120 }
          },
          {
            title: "RazonSocial",
            width: { wpx: 200 }
          },
          {
            title: "Exportacion",
            width: { wpx: 100 }
          },
          {
            title: "Gravadas",
            width: { wpx: 100 }
          },
          {
            title: "Exoneradas",
            width: { wpx: 100 }
          },
          {
            title: "Inafectas",
            width: { wpx: 100 }
          },
          {
            title: "ISC",
            width: { wpx: 100 }
          },
          {
            title: "IGV",
            width: { wpx: 100 }
          },
          {
            title: "ICBPER",
            width: { wpx: 100 }
          },
          {
            title: "Total",
            width: { wpx: 100 }
          },
          {
            title: "TipoCambio",
            width: { wpx: 100 }
          }          
        ],

        data: dataSet.map(row => {
          return [
        
            {
              value: row.FechaEmision,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
               
            {
              value: row.Serie,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.NumeroComprobante,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
     
            {
              value: row.NroTipoDocumento,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.RazonSocial,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Exportacion || 0.0,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Gravadas || 0.0,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Exoneradas || 0.0,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Inafectas || 0.0,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.ISC || 0.0,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.IGV || 0.0,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.ICBPER || 0.0,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Total || 0.0,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            }
          ];
        })
      }
    ];
  }

  handleSavePDF() {
    const mmPageSize = [700, 450];
    const ptPageSize = mmPageSize.map(coord => coord * 2.83465);

    let doc = new jsPDF({
      orientation: "landscape",
      format: ptPageSize
    });
    var pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    doc.setFontSize(14);
    doc.text("REPORTE DE TURNOS ACTIVOS EMPRESA", 275, 10);
    doc.autoTable({
      body: this.state.DetalleVentasTurnosActivosEmpresa,
      theme: "grid",
      columnStyles: {
        text: {
          cellWidth: "wrap",
          overflow: "linebreak",
          halign: "right",
          valign: "middle"
        },
        FechaEmision: {
          cellWidth: 25,
          halign: "right",
          valign: "middle",
          overflow: "linebreak"
        },
        TipoComprobante: {
          cellWidth: 20,
          halign: "right"
        },
        Serie: {
          cellWidth: 20,
          halign: "right"
        },
        NumeroComprobante: {
          cellWidth: 20,
          halign: "right"
        },        
        NroTipoDocumento: {
          cellWidth: 30,
          halign: "right"
        },
        RazonSocial: {
          cellWidth: 80,
          halign: "right"
        },
        Exportacion: {
          cellWidth: 30,
          halign: "right"
        },
        Gravadas: {
          cellWidth: 30,
          halign: "right"
        },
        Exoneradas: {
          cellWidth: 30,
          halign: "right"
        },
        Inafectas: {
          cellWidth: 30,
          halign: "right"
        },
        ISC: {
          cellWidth: 30,
          halign: "right"
        },
        IGV: {
          cellWidth: 30,
          halign: "right"
        },
        ICBPER: {
          cellWidth: 30,
          halign: "right"
        },
        Total: {
          cellWidth: 30,
          halign: "right"
        }
      },
      columns: [
     
        { header: "EMISIÓN", dataKey: "FechaEmision" },
       
        { header: "TIPO COMPROB.", dataKey: "TipoComprobante" },
        { header: "SERIE", dataKey: "Serie" },
        { header: "NÚMERO", dataKey: "NumeroComprobante" },
        {
          header: "NRO. DOCUMENTO",
          dataKey: "NroTipoDocumento"
        },
        { header: "RAZÓN SOCIAL", dataKey: "RazonSocial" },
        { header: "EXPORTACIÓN", dataKey: "Exportacion" },
        { header: "GRAVADAS", dataKey: "Gravadas" },
        { header: "EXONERADAS", dataKey: "Exoneradas" },
        { header: "INAFECTAS", dataKey: "Inafectas" },
        { header: "ISC", dataKey: "ISC" },
        { header: "IGV", dataKey: "IGV" },
        { header: "ICBPER", dataKey: "ICBPER" },
        { header: "TOTAL", dataKey: "Total" }
 
      ]
    });
    doc.setFontSize(10);
    doc.setFontType("italic");
    doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

    doc.save("ReporteVentasTurnosActivos.pdf");
  }


  render() {
    let dataExcel = null;
    if (this.state.DetalleVentasTurnosActivosEmpresa) {
      dataExcel = this.getDataExportExcel(
        this.state.DetalleVentasTurnosActivosEmpresa
      );
    }

    return (
      <>
        {dataExcel ? (
          <div>
            <div className="container mt-5">
              <div className="">
                <BotonCancelar to="/dashboard" />
              </div>
              <div className="d-flex  justify-content-between align-items-center mb-2">
                <h3 className="">
                  Detalle de Ventas en Turnos activos de la Empresa
                </h3>
                <div>
                  <button className="boton__Rojo mr-2" onClick={this.handleSavePDF}>
                    {<i className="fas fa-file-pdf"></i>}
                  </button>
                  <ExcelFile
                    element={
                      <button className="boton__verdeOscuro">
                        <i className="fas fa-file-excel"></i>
                      </button>
                    }
                    filename="ReporteProductosPorAgotarStock"
                  >
                    <ExcelSheet
                      dataSet={dataExcel}
                      name="Productos"
                    ></ExcelSheet>
                  </ExcelFile>
                </div>
              </div>
              <ReactTable
                id="table-productos-to-xls"
                data={this.state.DetalleVentasTurnosActivosEmpresa}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                columns={[
                  {
                    columns: [
                      {
                        Header: "Cliente",
                        id: "RazonSocial",
                        accessor: d => d.RazonSocial,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["RazonSocial"]
                          }),
                        filterAll: true,
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              background:
                                rowInfo && rowInfo.row.Estado === "ANULADA"
                                  ? "#ffcccc"
                                  : null
                            }
                          };
                        }
                      },
                      {
                        Header: "Fecha",
                        id: "FechaEmision",
                        accessor: d =>
                          moment(d.FechaEmision).format("DD-MM-YYYY"),
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["FechaEmision"]
                          }),
                        filterAll: true,
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              background:
                                rowInfo && rowInfo.row.Estado === "ANULADA"
                                  ? "#ffcccc"
                                  : null
                            }
                          };
                        }
                      },
                      {
                        Header: "Sucursal",
                        id: "Sucursal",
                        accessor: d => d.Sucursal,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["Sucursal"]
                          }),
                        filterAll: true,
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              background:
                                rowInfo && rowInfo.row.Estado === "ANULADA"
                                  ? "#ffcccc"
                                  : null
                            }
                          };
                        }
                      },
                      {
                        Header: "Modalidad Pago",
                        id: "IdModalidadPago",
                        accessor: d => d.IdModalidadPago,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["IdModalidadPago"]
                          }),
                        filterAll: true,
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              background:
                                rowInfo && rowInfo.row.Estado === "ANULADA"
                                  ? "#ffcccc"
                                  : null
                            }
                          };
                        }
                      },
                      {
                        Header: "Estado",
                        id: "Estado",
                        accessor: d => d.Estado,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["Estado"]
                          }),
                        filterAll: true,
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              background:
                                rowInfo && rowInfo.row.Estado === "ANULADA"
                                  ? "#ffcccc"
                                  : null
                            }
                          };
                        }
                      },
                      {
                        Header: "Tipo Comprobante",
                        id: "TipoComprobante",
                        accessor: d => d.TipoComprobante,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["TipoComprobante"]
                          }),
                        filterAll: true,
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              background:
                                rowInfo && rowInfo.row.Estado === "ANULADA"
                                  ? "#ffcccc"
                                  : null
                            }
                          };
                        }
                      },
                      {
                        Header: "Serie",
                        id: "Serie",
                        accessor: d => d.Serie,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["Serie"]
                          }),
                        filterAll: true,
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              background:
                                rowInfo && rowInfo.row.Estado === "ANULADA"
                                  ? "#ffcccc"
                                  : null
                            }
                          };
                        }
                      },
                      {
                        Header: "Numero Comprobante",
                        id: "NumeroComprobante",
                        accessor: d => d.NumeroComprobante,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["NumeroComprobante"]
                          }),
                        filterAll: true,
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              background:
                                rowInfo && rowInfo.row.Estado === "ANULADA"
                                  ? "#ffcccc"
                                  : null
                            }
                          };
                        }
                      },
                      {
                        Header: "Moneda",
                        id: "Abreviatura",
                        accessor: d => d.Abreviatura,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["Abreviatura"]
                          }),
                        filterAll: true,
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              background:
                                rowInfo && rowInfo.row.Estado === "ANULADA"
                                  ? "#ffcccc"
                                  : null
                            }
                          };
                        }
                      },
                      {
                        Header: "Total",
                        id: "Total",
                        accessor: d =>
                          d.Total.toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }),
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["Total"]
                          }),
                        filterAll: true,
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              textAlign: "right",
                              background:
                                rowInfo && rowInfo.row.Estado === "ANULADA"
                                  ? "#ffcccc"
                                  : null
                            }
                          };
                        }
                      },
                      {
                        Header: "Gravadas",
                        id: "Gravadas",
                        accessor: d =>
                          d.Gravadas.toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }),
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["Gravadas"]
                          }),
                        filterAll: true,
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              textAlign: "right",
                              background:
                                rowInfo && rowInfo.row.Estado === "ANULADA"
                                  ? "#ffcccc"
                                  : null
                            }
                          };
                        }
                      },
                      {
                        Header: "Exoneradas",
                        id: "Exoneradas",
                        accessor: d =>
                          d.Exoneradas.toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }),
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["Exoneradas"]
                          }),
                        filterAll: true,
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              textAlign: "right",
                              background:
                                rowInfo && rowInfo.row.Estado === "ANULADA"
                                  ? "#ffcccc"
                                  : null
                            }
                          };
                        }
                      },
                      {
                        Header: "Inafectas",
                        id: "Inafectas",
                        accessor: d =>
                          d.Inafectas.toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }),
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["Inafectas"]
                          }),
                        filterAll: true,
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              textAlign: "right",
                              background:
                                rowInfo && rowInfo.row.Estado === "ANULADA"
                                  ? "#ffcccc"
                                  : null
                            }
                          };
                        }
                      },
                      {
                        Header: "IGV",
                        id: "IGV",
                        accessor: d =>
                          d.IGV.toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }),
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["IGV"]
                          }),
                        filterAll: true,
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              textAlign: "right",
                              background:
                                rowInfo && rowInfo.row.Estado === "ANULADA"
                                  ? "#ffcccc"
                                  : null
                            }
                          };
                        }
                      },
                      {
                        Header: "ICBPER",
                        id: "ICBPER",
                        accessor: d =>
                          d.ICBPER.toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }),
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["ICBPER"]
                          }),
                        filterAll: true,
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              textAlign: "right",
                              background:
                                rowInfo && rowInfo.row.Estado === "ANULADA"
                                  ? "#ffcccc"
                                  : null
                            }
                          };
                        }
                      },
                      {
                        Header: "Descuento",
                        id: "DescuentoTotal",
                        accessor: d =>
                          d.DescuentoTotal.toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }),
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["DescuentoTotal"]
                          }),
                        filterAll: true,
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              textAlign: "right",
                              background:
                                rowInfo && rowInfo.row.Estado === "ANULADA"
                                  ? "#ffcccc"
                                  : null
                            }
                          };
                        }
                      }
                    ]
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
                previousText="Anterior"
                nextText="Siguiente"
                noDataText="No se encontraron registros"
                loadingText="Cargando..."
                ofText="de"
                rowsText="filas"
                pageText="Página"
                pageSizeOptions={[10, 20, 25, 50, 100]}
              />
            </div>
          </div>
        ) : (
          <div>Cargando Ventas</div>
        )}
      </>
    );
  }
}

export default DetallesVentasTurnosActivosEmpresa;

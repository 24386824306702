import React from 'react';
import VehiculosForm from './VehiculosForm.js';
import {Link} from 'react-router-dom';
import { BotonGuardarLetras, BotonCancelar } from "../Plantillas/Botones";

class VehiculosAdd extends React.Component{

    constructor(){
        super()
        this.state={
            IdEmpresa:'',
            Placa:"",
            Modelo:"",
            FechaRevisionTecnica:"PUBLICO",
            FechaSOAT:"",
            EmpresaSOAT:"",
            NumeroSOAT:"",
            Estado:""
        }
        this.newVehiculo = this.newVehiculo.bind(this)
    }

    componentDidMount() {
        //setInterval(this.listaClientes,5000);
        const IdEmpresa = this.props.match.params.IdEmpresa;
        this.setState({
            IdEmpresa: IdEmpresa
        })
    }

    handleChangeUnicos = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
        // console.log(e.target.name, e.target.value)
    };

    newVehiculo = () => {
        try{
        // console.log("Registrado")
        fetch(`/api/vehiculos/addPorEmpresa/${this.state.IdEmpresa}`,{
                method:'POST',
                body:JSON.stringify({
                    Placa: this.state.Placa,
                    Modelo: this.state.Modelo,
                    FechaRevisionTecnica: this.state.FechaRevisionTecnica,
                    FechaSOAT:this.state.FechaSOAT,
                    EmpresaSOAT:this.state.EmpresaSOAT,
                    NumeroSOAT:this.state.NumeroSOAT
                }),
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                }
            })
            .then(res => res.json())
            .then(data => console.log(data))
            .catch(err => console.log(err));
            this.props.history.push(`/empresas/vehiculos/`)
        } catch (error) {
            this.setState({
              loading: false,
              error: error
            });
          }
    }

    render(){
        return(
            <React.Fragment>

                <div className="ml-4 mt-4">
                    <BotonCancelar to="/empresas/vehiculos"/>
                </div>

                <form onSubmit={this.newVehiculo} className="d-flex justify-content-center">
                    <div className="almacenForm">
                        <div className="AlmacenBoton">
                            <BotonGuardarLetras type="submit"/>
                        </div>
                        {/* <button className="btn btn-primary" type="submit">Guardar <i className="ml-1 fa fa-save"></i></button> */}
                        <VehiculosForm
                            state={this.state}
                            handleChangeUnicos={this.handleChangeUnicos}
                        />
                        <div align="center">
                            {/* <Link className="btn btn-secondary m-3" to="/empresas/vehiculos">
                                    Volver
                                </Link> */}
                            
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
        
    }

}

export default VehiculosAdd;
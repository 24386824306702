import React from 'react';
import TransportistasForm from './TransportistasForm.js';
import {Link} from 'react-router-dom';
import { BotonGuardarLetras, BotonCancelar,BotonLetrasRojo } from "../Plantillas/Botones";
import PageLoading from '../PageLoading.js';

class TransportistasEditPorEmpresa extends React.Component{

    constructor(){
        super()
        this.state={
            IdEmpresa:'',
            IdTransportista:"",
            IdEmpleado:"",
            dataEmpleados:[],
            Categoria:"",
            NumeroLicencia:"",
            loading:false,
            error:null
        }
        this.getTransportistaById = this.getTransportistaById.bind(this)
    }

    componentDidMount() {
        const IdTransportista = this.props.match.params.IdTransportista;
        
        this.getEmpleados(this.props.match.params.IdTransportista)
        .then(
              this.getTransportistaById(IdTransportista)
        )
        // console.log(this.state.IdEmpleado)
        //setInterval(this.listaClientes,5000);
    }

    getTransportistaById = async(IdTransportista) => {
        fetch(`/api/transportistas/getById/${IdTransportista}`)
        .then(res => res.json())
        .then(async data => {
          this.setState({
            IdTransportista:data.Transportista[0].IdTransportista,
            IdEmpleado:data.Transportista[0].IdEmpleado,
            Categoria:data.Transportista[0].Categoria,
            NumeroLicencia:data.Transportista[0].NumeroLicencia,
            loading: true
          })
        //   this.getEmpleados(data.Transportista[0].IdEmpresa, data.Transportista[0].IdEmpleado)
        });
    }

    async getEmpleados(IdTransportista) {
        // console.log(IdTransportista)
        await fetch(`/api/transportistas/empleadosPorEmpresa/${IdTransportista}`)
            .then(response => response.json())
            .then(data => {
                this.setState({ 
                    dataEmpleados: data.empleados, 
                    loading: false 
                }); 
                // console.log(data)
                }
            ).catch(error => this.setState({ error, loading: false }));
        //console.log(this.state.data)
    }
    
    handleEmpleadoChange(e){
        //console.log(e.value) 
            this.state.IdEmpleado = e.value 
    }
    handleChangeUnicos = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
        // console.log(e.target.name, e.target.value)
    };

    handleEmpleadoChange(e){
        //console.log(e.value) 
            this.state.IdEmpleado = e.value 
    }

    ListaEmpleados = (empleados) =>{
        return empleados.map(empleado => {
            //console.log(producto)
            return { name: empleado.Apellidos.concat(', ',empleado.Nombres), value: empleado.IdEmpleado };
        });
    }

    EditTransportista = () => {
        fetch(`/api/transportistas/edit/${this.state.IdTransportista}`,{
                method:'PUT',
                body:JSON.stringify({
                    Empleado:this.state.Empleado,
                    Categoria:this.state.Categoria,
                    NumeroLicencia:this.state.NumeroLicencia,
                }),
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                }
            })
            .then(res => res.json())
            .then(data => console.log(data))
            .catch(err => console.log(err));
            this.props.history.push('/empresas/transportistas')
    }

    RemoveTransportista = () => {
        // this.setState({ loading: true, error: null });
        try {
            fetch(`/api/transportistas/delete/${this.state.IdTransportista}`,{
                method:"DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
        this.props.history.push('/empresas/transportistas')
    }

    render(){
        if(this.state.loading === false && this.state.IdTransportista == ''){
            return <PageLoading/>;
        }
        return(
            <React.Fragment>
                <div className="ml-4 mt-4">
                    <BotonCancelar to="/empresas/transportistas"/>
                </div> 
                    <form onSubmit={this.EditTransportista} className="d-flex justify-content-center">
                        <div className="almacenForm">
                            <div className="AlmacenBoton">
                                <BotonLetrasRojo
                                    type="button"
                                    className="mr-3"
                                    onClick={this.RemoveTransportista}
                                    >Dar de Baja
                                </BotonLetrasRojo>
                                    {/* <button 
                                        className="btn btn-outline-warning"
                                        onClick={this.RemoveTransportista}>
                                        <i className="fas fa-trash-alt"></i>
                                    </button> */}
                                <BotonGuardarLetras type="submit"/>
                            </div>   

                                {/* <div className="card-body"> */}
                                    
                                    <TransportistasForm
                                        state={this.state}
                                        handleChangeUnicos={this.handleChangeUnicos}
                                        ListaEmpleados={this.ListaEmpleados}
                                        handleEmpleadoChange={this.handleEmpleadoChange}
                                    />
                                    <div align="center">
                                        {/* <Link className="btn btn-secondary m-3" to="/empresas/transportistas">
                                            Volver
                                        </Link> */}
                                        {/* <button className="btn btn-primary" type="submit">Guardar <i className="ml-1 fa fa-save"></i></button> */}
                                        </div>
                                    {/* </div> */}
                        </div>{/* Dinamico la Serie y número correlativo */}
                    </form> 
            </React.Fragment>
        )
    }
}

export default TransportistasEditPorEmpresa;
import React from "react";
import {BOLETA, calcularTotales, CONTADO, CREDITO, decimalAdjust, FACTURA, PAGO_CREDITO} from "../../Global";
import {extractTwoDecimalsForNumber, setValorUnitario} from "../../helpers/calcularVenta";
import {
    FindPrecioEspecial,
    FindPrecioFamiliar,
    FindPrecioMenor,
    GetPrecioCosto
} from "../../componentes/Preventas/PreciosPreventa";
import Noty from "noty";
import {notificarError, notificarMsg} from "../../componentes/Almacenes/AlmacenNotify";
import moment from "moment";
import ModalCliente from "../../componentes/clientes/ClienteModal";
import ClienteNuevo from "../../componentes/clientes/ClientesNuevo";
import BuscarClientePorDoc from "../../componentes/Preventas/BuscarClientePorDoc";
import Modal from "../../componentes/Modal";
import DescuentoVales from "../../componentes/Vales/DescuentoVales";

export default class ProcessVale extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            ventas: [],
            detalles: [],
            Gravado: 0,
            Inafecto: 0,
            Exonerado: 0,
            IGVPreventa: 0,
            Gratuitas: 0,
            totalMonto: 0,
            ICBPERPreventa: 0,
            redondeo: 0,
            idTipoDoc: null,
            idTipoPago: -1,
            tiposPago: [],
            cantCuotas: 1,
            cantDias: 1,
            cliente: {},
            error: null,
            modalClienteIsOpen: false,
            Documento: '',
            showModalDesc: false,
            aliasPlaceholder: 'Alias (Opcional)',
            alias: ''
        }
        this.idsVales = props.match.params.idsVales;
        this.montoTotalDetalle = this.montoTotalDetalle.bind(this);
        this.onChangeValuePago = this.onChangeValuePago.bind(this);
        this.process = this.process.bind(this);
        this.validarClient = this.validarClient.bind(this);
        this.validarMonto = this.validarMonto.bind(this);
        this.validarTipoPago = this.validarTipoPago.bind(this);
        this.DocChange = this.DocChange.bind(this);
        this.searchClients = this.searchClients.bind(this);
        this.AsignarValorDoc = this.AsignarValorDoc.bind(this);
        this.handleCloseModalCliente = this.handleCloseModalCliente.bind(this);
        this.onDescontar = this.onDescontar.bind(this);
        this.onSeleccionarTodosHandler = this.onSeleccionarTodosHandler.bind(this);
        this.onCheckItem = this.onCheckItem.bind(this);
        this.aliasChange = this.aliasChange.bind(this);
        this.fetchConfigAlias = this.fetchConfigAlias.bind(this);
        this.handleClickNuevo = this.handleClickNuevo.bind(this);
    }

    componentDidMount() {
        this.fetchVales();
        this.fetchTipoPagos();
        this.fetchConfigAlias();
    }

    async fetchConfigAlias() {
        this.setState({isLoading: true})
        const res = await fetch(`/api/preVentas/getConfEmpresa/${46}`);
        if (res.ok) {
            let data = await res.json();
            let vendeConPlaca = String(data[0].Estado) === "1";
            this.setState({aliasPlaceholder: vendeConPlaca ? 'Placa (opcional)' : this.state.aliasPlaceholder})
        } else
            notificarError('No se pudo cargar la conf para alias');
        this.setState({isLoading: false})
    }

    async searchClients(query) {
        let res = await fetch(`/api/clientes/buscar/?query=${query}`)
        if (res.ok) {
            let clientes = await res.json();
            const mappedClientes = clientes.map(c => {
                return {
                    ...c,
                    label: `${c.PrimerNombre} ${c.SegundoNombre || ""} - ${c.RazonSocial || ""} ${c.NroTipoDocumento || ""}`,
                    value: c.IdCliente,
                    numDoc: c.NroTipoDocumento,
                }
            });
            if (mappedClientes.length)
                this.setState({
                    cliente: mappedClientes[0],
                    idTipoDoc: mappedClientes[0].numDoc.length === 8 ? BOLETA : FACTURA,
                })
        } else
            notificarError('No se puede buscar los clientes.')
    }

    fetchVales() {
        this.setState({isLoding: true})
        fetch(`/api/vales/get-vales?idsVales=${this.idsVales}`)
            .then(res => res.json())
            .then(ventas => {
                let tmpDets = [];
                ventas.forEach(v => v.detsVales.forEach(dv => tmpDets.push(dv)))
                let dets = this.getNonRepeatedDets(tmpDets);
                let mappedDets = dets.map(dp => ({
                    ...dp,
                    IdTipoStock: dp.IdTipoStock,
                    IdPrecioPlantilla: dp.IdPrecioPlantilla,
                    Simbolo: dp.Simbolo,
                    Tributos: dp.Tributos,
                    TasaISC: dp.TasaISC,
                    PrecioEspecial: FindPrecioEspecial([], dp),
                    PrecioFamiliar: FindPrecioFamiliar([], dp),
                    PrecioCosto: GetPrecioCosto([], dp),
                    PrecioMenor: FindPrecioMenor([], dp),
                    checked: dp.Gratuito === 1,
                    IdUnidad: dp.IdUnidad,
                    oldPrecios: [],
                    Total: dp.Cantidad * dp.PrecioVenta,
                    selected: false
                }))
                this.montoTotalDetalle(mappedDets)
                this.setState({detalles: mappedDets, ventas: ventas})
            })
            .finally(() => this.setState({isLoading: false}))
    }

    fetchTipoPagos() {
        this.setState({isLoading: true})
        fetch('/api/pagos/tipopagos')
            .then(res => res.json())
            .then(data => this.setState({tiposPago: data.respuesta}))
            .finally(() => this.setState({isLoading: false}))
    }

    getNonRepeatedDets(dets) {
        let sumados = [];
        for (let i = 0; i < dets.length; i++) {
            let sumado = sumados.find(f => String(f.IdPresentacion) === String(dets[i].IdPresentacion))
            if (!sumado) {
                dets[i].idsVentas = dets.filter(d => String(d.IdPresentacion) === String(dets[i].IdPresentacion))
                    .map(d => d.IdPreventa)
                dets[i].Cantidad = dets.filter(d => String(d.IdPresentacion) === String(dets[i].IdPresentacion))
                    .map(d => d.Cantidad).reduce((a, b) => a + b, 0);
                sumados.push(dets[i]);
            }
        }
        return sumados;
    }

    montoTotalDetalle(items) {
        const itemsTmp = [...items];
        const totales = calcularTotales(itemsTmp);
        this.setState({
            Gravado: decimalAdjust('floor', totales.gravados, -2),
            Inafecto: decimalAdjust('floor', totales.inafectos, -2),
            Exonerado: decimalAdjust('floor', totales.exonerados, -2),
            IGVPreventa: decimalAdjust('floor', totales.gravados * 0.18, -2),
            Gratuitas: decimalAdjust('floor', totales.gratuitos, -2),
            totalMonto: totales.totalRedondeado,
            ICBPERPreventa: decimalAdjust('floor', totales.icbper, -2),
            redondeo: totales.redondeo
        })
    }

    InputPrecioVentaChange(event, item) {
        this.precioVentaChange(event.target.value, item);
    }

    precioVentaChange(val, item) {
        const listaItems = [...this.state.detalles]
        const key = listaItems.indexOf(item)
        const value = val;
        const itemcalculo = this.getItemCalculo(item);

        listaItems[key].oldPrecios.push(listaItems[key].PrecioVenta);
        listaItems[key].PrecioVenta = value
        listaItems[key].ValorUnitario = setValorUnitario(itemcalculo)
        listaItems[key].Total = listaItems[key].Cantidad * listaItems[key].PrecioVenta - listaItems[key].Descuento
        this.setState({detalles: listaItems})
        this.montoTotalDetalle(listaItems)
    }

    getItemCalculo(item) {
        return {
            "PrecioVenta": item.PrecioVenta,
            "Tributos": item.Tributos,
            "TasaISC": item.TasaISC,
            "Cantidad": item.Cantidad,
            "Gratuito": item.Gratuito,
            "Descuento": item.Descuento
        }
    }

    async onBlurInputPrecio(e, item) {
        const {value} = e.target;
        if (value < item.PrecioCosto) {
            new Noty({
                type: "warning",
                theme: "bootstrap-v4",
                layout: "topCenter",
                text: `El precio ingresado es menor al precio de costo`,
                timeout: 1500
            }).show()
            let precio = item.oldPrecios.reverse().find(g => Number(g) >= Number(item.PrecioCosto));
            this.precioVentaChange(precio || item.PrecioCosto, item)
        }
    }

    onChangeValuePago(e) {
        let obj = {};
        const name = e.target.getAttribute('name');
        let value = e.target.value;
        if (name === 'cantCuotas' || name === 'cantDias')
            value = value > 0 ? value : 1
        obj[name] = value;
        this.setState(obj);
    }

    validarClient() {
        if (Object.keys(this.state.cliente).length)
            return [true, '']
        return [false, 'Debe ingresar un cliente']
    }

    validarMonto() {
        if (this.state.totalMonto >= 700 && this.state.cliente.numDoc === "99999999")
            return [false, 'El monto excede a 700, seleccione otro cliente']
        return [true, '']
    }

    validarTipoPago() {
        if (String(this.state.idTipoPago) !== String("-1"))
            return [true, '']
        return [false, 'Debe ingresar un tipo de pago']
    }

    async process() {
        let [isValid, msg] = this.isValid();
        if (isValid)
            await this.convertVale()
        else
            notificarError(msg)
    }

    isValid() {
        const validators = [this.validarClient, this.validarMonto, this.validarTipoPago]
        for (const validator of validators) {
            let [isValid, msg] = validator();
            if (!isValid)
                return [isValid, msg]
        }
        return [true, ''];
    }

    async convertVale() {
        this.setState({isLoading: true})
        let isValidStatus = await this.areValidStatus();

        if (isValidStatus) {
            let idRegVenta = await this.createRegVenta();
            if (idRegVenta) {
                await this.addDetsVenta(idRegVenta);
                await this.addPayments(idRegVenta)
                this.sendVentaSunat(idRegVenta)
                notificarMsg('Se ha realizado con éxito.');
                return this.props.history.push(`/detalleregistroventas/${idRegVenta}`);
            }
        } else {
            this.setState({error: 'Existe vales ya procesados, volver a seleccionar.'})
            this.setState({isLoading: false})
        }
    }

    async areValidStatus() {
        let res = await fetch(`/api/vales/get-status?idVales=${this.idsVales}`);
        let estados = await res.json();
        return estados.find(e => e.Estado === 'PROCESADO') === undefined;
    }

    async createRegVenta() {

        let res = await fetch(`/api/vales/convert-vales?idsVales=${this.idsVales}`, {
            method: "POST",
            body: JSON.stringify({
                IdCliente: this.state.cliente.value,
                IdTipoOperacion: this.state.ventas.map(v => v.IdVale).join(''),
                TipoTabla: "Vales",
                Estado: 'GENERANDO',
                IdModalidadPago: String(this.state.idTipoPago) === String(PAGO_CREDITO) ? CREDITO : CONTADO,
                IdTipoComprobante: this.state.idTipoDoc,
                IdMoneda: 1,
                TipoCambio: 1,
                TasaIGV: 0.18,
                TasaICBPER: 0.3,
                DescuentoTotal: 0,
                Gravadas: this.state.Gravado,
                Exoneradas: this.state.Exonerado,
                Inafectas: this.state.Inafecto,
                ICBPER: this.state.ICBPERPreventa,
                Exportacion: 0.0,
                Total: this.state.totalMonto,
                Vuelto: 0,
                Observacion: `GENERADO DE: ${this.state.ventas.map(v => `${v.Serie}-${v.NumeroComprobante}-${v.aliasPlaca && v.aliasPlaca.length ? v.aliasPlaca : "Sin placa"}\n`).join(' ')}`,
                IGV: this.state.IGVPreventa,
                ISC: 0,
                Gratuitas: this.state.Gratuitas,
                PorcentajeDescuento: 0,
                IVAP: 0,
                TasaIVAP: 0,
                TasaISC: 0,
                descuento: 0,
                Redondeo: this.state.redondeo,
                alias: this.state.alias
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
        if (res.ok) {
            let json = await res.json();
            return json.IdRegistroVenta;
        } else
            this.setState({error: 'Ha ocurrido un error al momento de procesar la venta'});
    }

    async addDetsVenta(idRegistroVenta) {
        const items = this.state.detalles.map(d => {
            return [
                idRegistroVenta,
                d.IdStock,
                d.Cantidad,
                d.PrecioReferencial,
                d.PrecioVenta,
                d.IdAfectacionIgv,
                d.IdPresentacion,
                d.Total,
                d.Descuento,
                d.TipoCambio,
                d.ISC,
                d.IGV,
                d.ICBPER,
                d.IVAP,
                d.TasaISC,
                d.ValorUnitario,
                d.DescuentoSI,
                d.Gratuito,
            ];
        })

        let res = await fetch(`/api/ventas/detalles/new?discountStock=1`, {
            method: "POST",
            body: JSON.stringify({
                Items: items,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
        if (!res.ok)
            this.setState({error: 'Ha ocurrido un error al momento de registrar los detalles de venta'});
    }

    async addPayments(idRegistroVenta) {
        let pagos = String(this.state.idTipoPago) === String(PAGO_CREDITO) ? this.getPagosCreditos(idRegistroVenta) : [
            [
                idRegistroVenta,
                this.state.idTipoPago,
                Number(this.state.totalMonto),
                "PAGADO",
                '',
                moment(new Date()).format('YYYY-MM-DD'),
                true,
                0
            ]
        ]
        try {
            await this.createPago(pagos);
        } catch (e) {
            this.setState({error: 'Ha ocurrido un error al momento de registrar los pagos'});
        }
    }

    async createPago(pago) {
        let res = await fetch("/api/pagos/new", {
            method: "POST",
            body: JSON.stringify({
                DetallesPagos: [...pago]
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
        if (!res.ok)
            throw "error";
    }

    getPagosCreditos(idRegistroVenta) {
        let pagos = [];
        const monto = Number(this.state.totalMonto) / this.state.cantCuotas;
        let initialDate = moment(new Date()).add(this.state.cantDias, 'days');
        for (let i = 0; i < this.state.cantCuotas; i++) {
            pagos.push([
                idRegistroVenta,
                this.state.idTipoPago,
                monto,
                "PENDIENTE",
                '',
                initialDate.format('YYYY-MM-DD'),
                true,
                0
            ])
            initialDate = moment(initialDate).add(this.state.cantDias, 'days');
        }
        return pagos;
    }

    async sendVentaSunat(idRegistroVenta) {
        await fetch(`/api/configFactElectronica/ce/${idRegistroVenta}`);
    }

    handleCloseModalCliente() {
        this.setState({modalClienteIsOpen: false})
    }

    DocChange(e) {
        this.setState({Documento: e.target.value});
        this.searchClients(e.target.value)
    }

    handleClickNuevo() {
        this.setState({
            modalClienteIsOpen: true,
        })
    }

    AsignarValorDoc(data) {
        this.setState({Documento: data.NroTipoDocumento})
        this.searchClients(data.NroTipoDocumento);
    }

    onDescontar(dets, descuento) {
        let tmpDets = [...this.state.detalles];
        dets.forEach(d => {
            let i = tmpDets.indexOf(d);
            tmpDets[i].PrecioVenta = tmpDets[i].PrecioVenta - (tmpDets[i].PrecioVenta * (descuento / 100));
            tmpDets[i].selected = false;
        })
        this.setState({detalles: tmpDets, showModalDesc: false});
        notificarMsg("Se ha aplicado el descuento correspondiente.", 'success')
    }

    onSeleccionarTodosHandler() {
        this.setState({
            detalles: this.state.detalles.map(d => {
                return {...d, selected: true}
            })
        })
    }

    onCheckItem(item) {
        let tmpDets = [...this.state.detalles];
        let i = tmpDets.indexOf(item);
        tmpDets[i].selected = !tmpDets[i].selected;
        this.setState({detalles: tmpDets});
    }

    aliasChange(e) {
        this.setState({
            alias: e.target.value
        });
    }

    render() {
        const {idTipoDoc, detalles, isLoading, tiposPago, idTipoPago, cantCuotas, cantDias, error} = this.state;

        return (
            <div className="container">
                <div className="row mt-3">
                    <div className="col-sm-auto">
                        <a href="#" onClick={e => {
                            e.preventDefault()
                            this.props.history.push("/vales")
                        }}>Regresar</a>
                    </div>
                    <div className="col-sm-auto">
                        <h5>Documento a emitir: {idTipoDoc ? (idTipoDoc === BOLETA ? 'Boleta' : 'Factura') : ''}</h5>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm">
                        <BuscarClientePorDoc
                            style={{display: "flex", width: '600px'}}
                            hideGoBack={true}
                            hideAddCliente={false}
                            hideSearchCliente={true}
                            DocChange={this.DocChange}
                            Documento={this.state.Documento}
                            Alias={this.state.alias}
                            aliasClass={"x"}
                            aliasStyle={{width: "70%"}}
                            setActiveInputDoc={() => {
                            }}
                            AliasChange={this.aliasChange}
                            handleClickNuevo={this.handleClickNuevo}
                            placeholder={this.state.aliasPlaceholder}
                        />
                    </div>
                    <div className="col-sm">
                        {this.state.cliente.label}
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-sm-12">
                        <button style={{float: "right"}} onClick={this.onSeleccionarTodosHandler}
                                className="btn btn-outline-primary">
                            Seleccionar todos
                        </button>
                        <button style={{float: "right"}} onClick={() => this.setState({showModalDesc: true})}
                                className="btn btn-outline-secondary ">
                            Bajar precio
                        </button>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-12">
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <td>Presentación</td>
                                <td>Cantidad</td>
                                <td>Precio venta</td>
                                <td>Total</td>
                                <td>Opciones</td>
                            </tr>
                            </thead>
                            <tbody>
                            {detalles.length ? (detalles.map((d) => {
                                return (
                                    <tr key={d.IdPresentacion}>
                                        <td>
                                            {d.descripcion} {d.unidadMedida}
                                        </td>
                                        <td>
                                            {d.Cantidad}
                                        </td>
                                        <td>
                                            <input disabled={isLoading}
                                                   onClick={e => e.target.select()}
                                                   onBlur={e => this.onBlurInputPrecio(e, d)}
                                                   onChange={e => this.InputPrecioVentaChange(e, d)} type="number"
                                                   value={extractTwoDecimalsForNumber(d.PrecioVenta)}/>
                                        </td>
                                        <td>
                                            {d.Simbolo}
                                            {decimalAdjust('floor', d.Total, -2)}
                                        </td>
                                        <td>
                                            <input type="checkbox" checked={d.selected}
                                                   onChange={() => this.onCheckItem(d)}/>
                                        </td>
                                    </tr>
                                )
                            })) : ("No existen productos facturables en los vales seleccionados.")}

                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-auto">
                        {parseFloat(this.state.Gravado) > 0 ?
                            <p>
                                Gravado{" "}
                                <strong>
                                    {this.state.Gravado}
                                </strong>
                            </p>
                            : null
                        }
                    </div>
                    <div className="col-sm-auto">
                        {parseFloat(this.state.Inafecto) > 0 ?
                            <p>
                                Inafecto{" "}
                                <strong>
                                    {this.state.Inafecto}
                                </strong>
                            </p>
                            : null
                        }
                    </div>
                    <div className="col-sm-auto">
                        {parseFloat(this.state.Exonerado) > 0 ?
                            <p>
                                Exonerado{" "}
                                <strong>
                                    {this.state.Exonerado}
                                </strong>
                            </p>
                            : null
                        }
                    </div>
                    <div className="col-sm-auto">
                        {parseFloat(this.state.Gratuitas) > 0 ?
                            <p>
                                Gratuitas{" "}
                                <strong>
                                    {this.state.Gratuitas}
                                </strong>
                            </p>
                            : null
                        }
                    </div>
                    <div className="col-sm-auto">
                        {parseFloat(this.state.ICBPERPreventa) > 0 ?
                            <p>
                                ICBPER{" "}
                                <strong>
                                    {this.state.ICBPERPreventa}
                                </strong>
                            </p>
                            : null
                        }
                    </div>
                    <div className="col-sm-auto">
                        {parseFloat(this.state.IGVPreventa) > 0 ?
                            <p>
                                IGV{" "}
                                <strong>
                                    {this.state.IGVPreventa}
                                </strong>
                            </p>
                            : null
                        }
                    </div>
                    <div className="col-sm-auto">
                        {parseFloat(this.state.redondeo) > 0 ?
                            <p>
                                Redondeo <strong>
                                {decimalAdjust('floor', this.state.redondeo, -2)}
                            </strong>
                            </p>
                            : null
                        }
                    </div>
                    <div className="col-sm-auto">
                        <span>Total {this.state.totalMonto}
                        </span>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-auto">
                        Tipo de pago
                    </div>
                    <div className="col-sm">
                        <select name="idTipoPago" onChange={this.onChangeValuePago}
                                value={idTipoPago ? idTipoPago : "-1"} className="form-control">
                            <option value="-1" key="-1">Seleccionar</option>
                            {tiposPago.map(t => <option
                                key={t.IdTipoPago} value={t.IdTipoPago}>{t.Nombre}</option>)}
                        </select>
                    </div>
                </div>
                {String(idTipoPago) === String(PAGO_CREDITO) ? (
                    <div className="row mt-3">
                        <div className="col-sm-auto">
                            Cantidad de cuotas
                        </div>
                        <div className="col-sm">
                            <input type="number" className="form-control" min="1" name="cantCuotas" value={cantCuotas}
                                   onChange={this.onChangeValuePago}/>
                        </div>
                        <div className="col-sm-auto">
                            Cantidad de días
                        </div>
                        <div className="col-sm">
                            <input type="number" className="form-control" min="1" name="cantDias" value={cantDias}
                                   onChange={this.onChangeValuePago}/>
                        </div>
                    </div>
                ) : null}
                <div className="row mt-3">
                    <div className="col-sm">
                        {error || detalles.length === 0 ? (
                            <div className="alert alert-danger">
                                Ha ocurrido un error o no tiene detalles a procesar - {error}
                            </div>
                        ) : <button onClick={this.process} disabled={isLoading}
                                    className="btn btn-outline-primary">
                            Convertir
                        </button>}

                    </div>
                </div>

                <ModalCliente isOpen={this.state.modalClienteIsOpen} onClose={this.handleCloseModalCliente}
                              title="NUEVO CLIENTE">
                    <ClienteNuevo onClose={this.handleCloseModalCliente}
                                  AsignarValorDoc={this.AsignarValorDoc}
                    />
                </ModalCliente>

                <Modal title="Descontar precio" onClose={() => this.setState({showModalDesc: false})}
                       isOpen={this.state.showModalDesc}>
                    <DescuentoVales onDescontar={this.onDescontar}
                                    detalles={this.state.detalles.filter(d => d.selected)}/>
                </Modal>
            </div>
        );
    }
}

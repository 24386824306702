import ReporteDocSunatScreen from "../componentes/ReporteDocSunat/ReporteDocSunatScreen";
import React from "react";

export default class ReporteDocSunatPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="mt-3">
                <ReporteDocSunatScreen/>
            </div>
        )
    }
}

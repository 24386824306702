import React from "react";
import { BotonLetras, BotonCancelar } from "../Plantillas/Botones.js";
import '../styles/Kardex.css';
import sunat from "../images/sunat.png"
import sinComprobantes from "../images/sinComprobantes.jpg"

class EscogerStockInicial extends React.Component{

    render(){
        return(
            <React.Fragment>
                <div className="ProcesarBoton mt-1">
                    <BotonCancelar
                        onClick={this.props.history.goBack}
                        className="ml-4"
                    />
                </div>

                <div className="kardex__container">
                    <div className="kardex">
                        <div className="reporteVentasProd">

                            <div className="KardexDocumento mb-4 mt-4" >
                                <div className="imgcomprobante">
                                   <img src={sunat}/> 
                                </div>
                                <div className="input__KardexDivSmall imgcomprobante mt-4">
                                    <BotonLetras
                                        type="button"
                                        to="/stockinicial" 
                                    >Con Comprobante
                                    </BotonLetras>
                                </div>
                            </div>

                            <div className="KardexDocumento mb-4" >
                                <div className="imgcomprobante">
                                   <img src={sinComprobantes} style={{height: '83px'}}/> 
                                </div>
                                <div className="input__KardexDivSmall imgcomprobante mt-2">
                                    <BotonLetras
                                        type="button"
                                        to="/stockinicialSC" 
                                    >Sin Comprobante
                                    </BotonLetras>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default EscogerStockInicial
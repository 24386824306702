import {useState, useEffect} from 'react';
import Notify from '../componentes/Notify';

const getEmpresas = async (setEmpresas, signal) => {
    let respuesta;
    try {
        respuesta = await fetch("/api/empresas", {signal});
    } catch (error) {
        if (error.name === "AbortError")
            return
        console.error(error);
        Notify("Hubo un problema en la comunicación con el servidor", "error")
        return
    }
    const {empresas} = await respuesta.json()
    setEmpresas(empresas)
}

/**
 * Hook que devuelve la lista de empresas en un estado.
 *
 * Hace un request a `"/api/empresas"`, lo guarda en un estado, y lo devuelve
 * @param {*} initialState - Estado inicial a devolver antes de que finalice el fetch
 * @param {Array<*>} deps - Array de dependencias que se le pasan como 2do argumento a `useEffect`
 * @returns {Array<Objects>} Listado de empresas o `initialState`
 */
const useEmpresas = (initialState=null, deps=[]) => {
    const [empresas, setEmpresas] = useState(initialState);

    useEffect( () => {
        const controller = new AbortController();
        getEmpresas(setEmpresas, controller.signal);
        return () => controller.abort()
    }, deps)

    return empresas;
}

export default useEmpresas;
import React from 'react';


class VehiculosForm extends React.Component {

    render() {
        return (
            <React.Fragment>
                {/*  */}
                {/* Placa y modelo */}
                <div className="almacenDiv">
                    <b className="ml-4">Placa</b>
                    <div className="input__Almacen">
                        <input required
                               name="Placa"
                               className="input__linea"
                               type="text"
                               value={this.props.state.Placa}
                               onChange={this.props.handleChangeUnicos}
                        />
                    </div>
                </div>
                <div className="almacenDiv">
                    <b className="ml-4">Modelo</b>
                    <div className="input__Almacen">
                        <input required
                               name="Modelo"
                               className="input__linea"
                               type="text"
                               value={this.props.state.Modelo}
                               onChange={this.props.handleChangeUnicos}
                        />
                    </div>
                </div>

            </React.Fragment>
        )

    }

}

export default VehiculosForm;

import React from 'react';
import {Link} from 'react-router-dom';
import "./styles/Menu.css";


function MenuItem({children, Ruta, Nombre}) {

    const itemLink = (
        Ruta === null
            ? <span>{Nombre}</span>
            : <div href="#Menu" data-toggle="collapse"><Link to={Ruta}>{Nombre}</Link></div>
    )
    // console.log(children)
    const subMenuList = (
        children.length === 0
            ? null
            :
            children[0].props.Nombre != 'Empresa' ? (
                    <ul className="pl-4">
                        {children}
                    </ul>
                ) :
                <ul className="pl-0">
                    {children}
                </ul>
    )

    if (Nombre == "Administrador")
        return (
            <>
                <div>
                    {subMenuList}
                </div>
            </>
        );
    else if (Nombre == "Empresa")
        return (
            <>
                <div className="menu__nombrePadre d-flex align-items-center">
                    <div className="menu__nombreIcon collapsed"
                         href="#subMenuEmpresa" /* d-flex w-100 align-items-center justify-content-between */
                         data-toggle="collapse"
                         aria-expanded="false">
                        <h5 style={{cursor: "pointer"}}><i className="fas fa-building"/> Empresas</h5>
                    </div>
                </div>
                <div id="subMenuEmpresa" className="collapse subMenus__container">
                    {subMenuList}
                </div>
            </>
        );
    else if (Nombre == "Empresas")
        return (
            <>
                <div className="menu__nombre p-0 d-flex align-items-center">
                    ・
                    <Link to={Ruta || "#"}>Reg. Empresas</Link>
                </div>
                <div id="subMenuEmpresa" className="collapse subMenus__container">
                    {subMenuList}
                </div>
            </>
        );

    else if (Nombre == "Empresasss")
        return (
            <>

                <div id="subMenuEmpresa" className="collapse subMenus__container">
                    {subMenuList}
                </div>
            </>
        );
    else if (Nombre == "Puntos de Ventas")
        return (
            <>
                <div className="menu__nombre d-flex align-items-center">
                    ・
                    <Link to={Ruta || "#"}>P/V Cajas</Link>
                </div>
                <div id="subMenuEmpresa" className="collapse subMenus__container">
                    {subMenuList}
                </div>
            </>
        );

    else if (Nombre == "Registrar Empresa")
        return (
            <>

                <div id="subMenuEmpresa" className="collapse subMenus__container">
                    {subMenuList}
                </div>
            </>
        );
    else if (Nombre == "Gestión de Usuarios y Permisos")
        return (
            <>
                <div id="subMenuEmpresa" className="collapse subMenus__container">
                    {subMenuList}
                </div>
            </>
        );
    else if (Nombre == "Almacén")
        return (
            <>
                <div className="menu__nombrePadre d-flex align-items-center">
                    <div className="menu__nombreIcon collapsed" href="#subMenuAlmacen"
                         data-toggle="collapse"
                         aria-expanded="false">
                        <h5 style={{cursor: "pointer"}}><i className="fas fa-warehouse"/>Almacen</h5>
                    </div>
                </div>
                <div id="subMenuAlmacen" className="collapse subMenus__container">
                    {subMenuList}
                </div>
            </>
        );
    else if (Nombre == "Compras")
        return (
            <>
                <div className="menu__nombrePadre d-flex align-items-center"
                >
                    <div className="menu__nombreIcon collapsed" href="#subMenuCompras"
                         data-toggle="collapse"
                         aria-expanded="false">
                        <h5 style={{cursor: "pointer"}}><i className="fas fa-shopping-cart"/>Compras</h5>
                    </div>
                </div>
                <div id="subMenuCompras" className="collapse subMenus__container">
                    {subMenuList}
                </div>
            </>
        );
    else if (Nombre == "Configuraciones")
        return (
            <>
                <div className="menu__nombrePadre d-flex align-items-center">
                    <div className="menu__nombreIcon collapsed" href="#subMenuAdministracion"
                         data-toggle="collapse"
                         aria-expanded="false">
                        <h5 style={{cursor: "pointer"}}><i className="fas fa-cogs"/>Administración</h5>
                    </div>
                </div>
                <div id="subMenuAdministracion" className="collapse subMenus__container">
                    {subMenuList}
                </div>
            </>
        );
    else if (Nombre == "Dashboard")
        return (
            <>
                <div className="menu__nombrePadre d-flex align-items-center"
                     data-toggle="collapse"
                     aria-expanded="false"
                >
                    <h5><i className="fas fa-desktop"></i> <Link to={Ruta || "#"}>Dashboard</Link></h5>
                </div>
            </>
        );
    else if (Nombre == "Kardex")
        return (
            <>
                <div className="menu__nombrePadre d-flex align-items-center"
                    //  <div className="menu__nombreIcon collapsed"  href="#subMenuKardex"
                     data-toggle="collapse"
                     aria-expanded="false">

                    <h5><i className="fas fa-truck"></i> <Link to={Ruta || "#"}>Kardex</Link></h5>
                </div>
                {/* <div id="subMenuKardex" className="collapse subMenus__container">
        {subMenuList}
      </div> */}
            </>
        );
    else if (Nombre == "Stock Inicial")
        return (
            <>
                <div className="menu__nombrePadre d-flex align-items-center"
                    //  <div className="menu__nombreIcon collapsed"  href="#subMenuKardex"
                     data-toggle="collapse"
                     aria-expanded="false">

                    <h5><i className="fas fa-truck"/> <Link to={Ruta || "#"}>Stock Inicial</Link></h5>
                </div>
                {/* <div id="subMenuKardex" className="collapse subMenus__container">
        {subMenuList}
      </div> */}
            </>
        );
    else if (Nombre == "Todo Ventas")
        return (
            <>
                <div className="menu__nombrePadre d-flex align-items-center">
                    <div className="menu__nombreIcon collapsed" href="#subMenuVentas"
                         data-toggle="collapse"
                         aria-expanded="false">
                        <h5 style={{cursor: "pointer"}}><i className="fas fa-file-invoice"/>Ventas</h5>
                    </div>
                </div>
                <div id="subMenuVentas" className="collapse subMenus__container">
                    {subMenuList}
                </div>
            </>
        );

    else if (Nombre == "Vender")
        return (
            <>
                <div className="menu__nombrePadre d-flex align-items-center"
                     data-toggle="collapse"
                     aria-expanded="false"
                >
                    <h5><i className="fas fa-cash-register"/> <Link to={Ruta}>Vender</Link></h5>
                </div>
            </>
        );

    else {
        return (
            <>
                <div className="menu__nombre d-flex align-items-center">
                    ・
                    {itemLink}
                </div>
                {subMenuList}
            </>
        );
    }
}

export default MenuItem;

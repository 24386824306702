import React, { Component} from 'react';

class ModalImprimirReporte extends Component {
    render () {
        return(
            <React.Fragment>
                <div className="mt-4 ml-4 mr-4 mb-4">
                    <button
                        className="boton__verdeOscuro--letras ml-1 mr-1"
                        onClick={this.props.handleSavePDF}
                        title="Haz click para imprimir"
                        >
                        Imprimir A4 {<i className="fas fa-print"></i>}
                    </button>
                    <button
                        className="boton__verdeClaro--letras ml-1 mr-1"
                        onClick={this.props.handlePrintTicket}
                        title="Haz click para imprimir"
                        style={{height: '35px'}}
                        >
                        Imprimir Ticket {<i className="fas fa-print"></i>}
                    </button>
                </div>
            </React.Fragment>
        )
    }

}

export default ModalImprimirReporte;
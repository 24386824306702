import React from "react";

class ListaProductos extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div id="tableProdPreventa" className="lista_productos">
                    <div className="lista_productos__item">
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th>Producto/Presentación</th>
                                <th>Precio</th>
                                <th>Stock</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.listaProductos.slice(0, 10).map(producto => {
                                return (
                                    /* ***SI EL STOCK DISPONIBLE ES MAYOR AL STOCK MINIMO,*****
                                    * ******LA CELDA SE MUESTRA BLANCA EN EL NOMBRE **********
                                    * *****************Y GRIS EN EL STOCK****************    */
                                    (this.props.VenderConStock === 1) ?
                                        (
                                            (this.props.filtroTipoStock === 1) ?
                                                (
                                                    <tr onClick={() => this.props.AddProductoInList(producto)}
                                                        className="prodPreventaTr"
                                                        key={"" + producto.IdStock + producto.IdTipoStock + producto.IdProducto +
                                                        producto.IdPresentacion + producto.IdPrecioPlantilla + producto.IdPrecio +
                                                        producto.IdAlmacen}
                                                        title={producto.DescripcionProducto ? producto.DescripcionProducto.concat(" ", producto.DescripcionUnidad)
                                                            : producto.DescripcionUnidad}
                                                    >
                                                        <td style={{fontSize: "14px"}}>
                                                            {producto.NombreProducto} {producto.DescripcionUnidad} {producto.NombrePresentacion}
                                                        </td>
                                                        <td bgcolor="#01a59c" style={{padding: 0}}>
                                                            <td>
                                                                <label
                                                                    style={{color: "white"}}>{producto.Simbolo}</label>
                                                            </td>
                                                            <td className="col-12">
                                                                <div style={{color: "white", textAlign: "end"}}>
                                                                    {Math.round(producto.Precio * 100) / 100}
                                                                </div>
                                                            </td>
                                                        </td>
                                                        <td style={{
                                                            backgroundColor: "rgb(53,96,90,0.18)",
                                                            borderTop: "1px solid white"
                                                        }}>
                                                            <div style={{textAlign: "end"}}>
                                                                {this.props.StockDisponible(producto)
                                                                    .toLocaleString("en-GB", {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                    })
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) :
                                                (
                                                    <tr onClick={() => this.props.AddProductoInList(producto)}
                                                        className="prodPreventaTr"
                                                        key={"" + producto.IdStock + producto.IdTipoStock + producto.IdProducto +
                                                        producto.IdPresentacion + producto.IdPrecioPlantilla + producto.IdPrecio +
                                                        producto.IdAlmacen}
                                                        title={producto.DescripcionProducto ? producto.DescripcionProducto.concat(" ", producto.DescripcionUnidad)
                                                            : producto.DescripcionUnidad}
                                                    >
                                                        <td bgcolor="#f78b2d" style={{fontSize: "14px"}}>
                                                            {producto.NombreProducto} {producto.DescripcionUnidad} {producto.NombrePresentacion}
                                                        </td>
                                                        <td bgcolor="#01a59c" style={{padding: 0}}>
                                                            <td>
                                                                <label
                                                                    style={{color: "white"}}>{producto.Simbolo}</label>
                                                            </td>
                                                            <td className="col-12">
                                                                <div style={{color: "white", textAlign: "end"}}>
                                                                    {Math.round(producto.Precio * 100) / 100}
                                                                </div>
                                                            </td>
                                                        </td>
                                                        <td style={{
                                                            backgroundColor: "rgb(53,96,90,0.18)",
                                                            borderTop: "1px solid white"
                                                        }}>
                                                            <div style={{textAlign: "end"}}>
                                                                {this.props.StockDisponible(producto)
                                                                    .toLocaleString("en-GB", {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                    })
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                        ) :
                                        (
                                            (this.props.StockDisponible(producto) > producto.StockMinimo) ?
                                                (
                                                    (this.props.filtroTipoStock === 1) ?
                                                        (
                                                            <tr onClick={() => this.props.AddProductoInList(producto)}
                                                                className="prodPreventaTr"
                                                                key={"" + producto.IdStock + producto.IdTipoStock + producto.IdProducto +
                                                                producto.IdPresentacion + producto.IdPrecioPlantilla + producto.IdPrecio +
                                                                producto.IdAlmacen}
                                                                title={producto.DescripcionProducto ? producto.DescripcionProducto.concat(" ", producto.DescripcionUnidad)
                                                                    : producto.DescripcionUnidad}
                                                            >
                                                                <td style={{fontSize: "14px"}}>
                                                                    {producto.NombreProducto} {producto.DescripcionUnidad} {producto.NombrePresentacion}
                                                                </td>
                                                                <td bgcolor="#01a59c" style={{padding: 0}}>
                                                                    <td>
                                                                        <label
                                                                            style={{color: "white"}}>{producto.Simbolo}</label>
                                                                    </td>
                                                                    <td className="col-12">
                                                                        <div style={{color: "white", textAlign: "end"}}>
                                                                            {Math.round(producto.Precio * 100) / 100}
                                                                        </div>
                                                                    </td>
                                                                </td>
                                                                <td style={{backgroundColor: "rgb(53,96,90,0.18)"}}>
                                                                    <div style={{textAlign: "end"}}>
                                                                        {this.props.StockDisponible(producto)
                                                                            .toLocaleString("en-GB", {
                                                                                minimumFractionDigits: 2,
                                                                                maximumFractionDigits: 2
                                                                            })
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ) :
                                                        (
                                                            <tr onClick={() => this.props.AddProductoInList(producto)}
                                                                className="prodPreventaTr"
                                                                key={"" + producto.IdStock + producto.IdTipoStock + producto.IdProducto +
                                                                producto.IdPresentacion + producto.IdPrecioPlantilla + producto.IdPrecio +
                                                                producto.IdAlmacen}
                                                                title={producto.DescripcionProducto ? producto.DescripcionProducto.concat(" ", producto.DescripcionUnidad)
                                                                    : producto.DescripcionUnidad}
                                                            >
                                                                <td bgcolor="#f78b2d" style={{fontSize: "14px"}}>
                                                                    {producto.NombreProducto} {producto.DescripcionUnidad} {producto.NombrePresentacion}
                                                                </td>
                                                                <td bgcolor="#01a59c" style={{padding: 0}}>
                                                                    <td>
                                                                        <label
                                                                            style={{color: "white"}}>{producto.Simbolo}</label>
                                                                    </td>
                                                                    <td className="col-12">
                                                                        <div style={{color: "white", textAlign: "end"}}>
                                                                            {Math.round(producto.Precio * 100) / 100}
                                                                        </div>
                                                                    </td>
                                                                </td>
                                                                <td style={{backgroundColor: "rgb(53,96,90,0.18)"}}>
                                                                    <div style={{textAlign: "end"}}>
                                                                        {this.props.StockDisponible(producto)
                                                                            .toLocaleString("en-GB", {
                                                                                minimumFractionDigits: 2,
                                                                                maximumFractionDigits: 2
                                                                            })
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )

                                                ) :
                                                /* ******SI ES MENOR, LAS CELDAS SE MUESTRAN ROJIZAS ******* */
                                                (
                                                    <tr onClick={() => this.props.AddProductoInList(producto)}
                                                        className="prodPreventaTr"
                                                        key={"" + producto.IdStock + producto.IdTipoStock + producto.IdProducto +
                                                        producto.IdPresentacion + producto.IdPrecioPlantilla + producto.IdPrecio +
                                                        producto.IdAlmacen}
                                                        title={producto.DescripcionProducto ? producto.DescripcionProducto.concat(" ", producto.DescripcionUnidad)
                                                            : producto.DescripcionUnidad}
                                                    >
                                                        <td bgcolor="#fbfb96" style={{fontSize: "14px"}}>
                                                            {producto.NombreProducto} {producto.DescripcionUnidad} {producto.NombrePresentacion}
                                                        </td>
                                                        <td bgcolor="#01a59c" style={{padding: 0}}>
                                                            <td>
                                                                <label
                                                                    style={{color: "white"}}>{producto.Simbolo}</label>
                                                            </td>
                                                            <td className="col-12">
                                                                <div style={{color: "white", textAlign: "end"}}>
                                                                    {Math.round(producto.Precio * 100) / 100}
                                                                </div>
                                                            </td>
                                                        </td>
                                                        <td bgcolor="#fbfb96">
                                                            <div style={{textAlign: "end"}}>
                                                                {this.props.StockDisponible(producto)
                                                                    .toLocaleString("en-GB", {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                    })
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                        )

                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ListaProductos;

export const ACTION = {
    UPDATE: "update",
    DELETE: "delete"
}

export const ENTITY = {
    PREVENTA: "Preventas",
    VENTA: "RegistroVentas",
    VALE: "Vales"
}

export const ACTIONS = [
    {
        keyName: ACTION.UPDATE,
        label: "Editar o actualizar"
    },
    {
        keyName: ACTION.DELETE,
        label: "Eliminar o anular",
    }
]

export const ENTITIES = [
    {
        keyName: ENTITY.PREVENTA,
        label: "Preventas",
        tableKey: "IdPreventa"
    },
    {
        keyName: ENTITY.VENTA,
        label: "Ventas",
        tableKey: "IdRegistroVenta"
    },
    {
        keyName: ENTITY.VALE,
        label: "Vales",
        tableKey: "IdVale"
    }
]

import Noty from "noty";
import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";

export function notificationPrecioCosto(item) {
    new Noty({
        type: "warning",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: `${item.mensaje}`,
        timeout: 1500
    }).show();
}

export function notificationStock0(item) {
    new Noty({
        type: "warning",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: `${item.descripcion}  SE ENCUENTRA SIN STOCK DISPONIBLE`,
        timeout: 1500
    }).show();
}

export function notificationCantidadNegativa() {
    new Noty({
        type: "error",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: `INGRESAR UN CANTIDAD MAYOR A 0`,
        timeout: 2000
    }).show();
}

export function notificationFacturaRUC() {
    new Noty({
        type: "warning",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: `INGRESAR PRIMERO UN RUC VÁLIDO REGISTRADO EN EL SISTEMA EN "DATOS DE CLIENTE"`,
        timeout: 2000
    }).show();
}

export function notificationClienteNoExiste() {
    new Noty({
        type: "warning",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: `EL CLIENTE INGRESADO NO EXISTE EN EL SISTEMA, PUEDE INGRESARLO CON EL BOTON +`,
        timeout: 2000
    }).show();
}

export function notificationConfigBoletaRUC() {
    new Noty({
        type: "warning",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: `SU CONFIGURACIÓN DE EMPRESA NO LE PERMITE GENERAR BOLETAS CON RUC`,
        timeout: 2000
    }).show();
}

export function notificationStockSinComprobante() {
    new Noty({
        type: "warning",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: `STOCK SOLO DISPONIBLE CON VALE`,
        timeout: 2000
    }).show();
}

export function notificationBoletaVarios() {
    new Noty({
        type: "warning",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: `INGRESAR DNI DEL CLIENTE PARA ESTE MONTO`,
        timeout: 2000
    }).show();
}

export function notificationDescuentoMayorTotal() {
    new Noty({
        type: "warning",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: `VERIFICAR QUE EL DESCUENTO NO SEA MAYOR AL MONTO TOTAL DEL ITEM`,
        timeout: 2000
    }).show();
}

export function showNotification(msg, type = 'warning') {
    new Noty({
        type: type,
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: msg,
        timeout: 2000
    }).show();
}

export function notificationDescuentoGratuito() {
    new Noty({
        type: "warning",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: `NO SE PUEDE ASIGNAR UN DESCUENTO A UN ITEM GRATUITO`,
        timeout: 2000
    }).show();
}

export function notificationPrecioMenorAICBPER() {
    new Noty({
        type: "warning",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: `EL PRECIO DE VENTA NO PUEDE SER MENOR AL IMPUESTO POR ICBPER`,
        timeout: 2000
    }).show();
}

export function notificationProdValeInDetalle() {
    new Noty({
        type: "warning",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: `HAY PRODUCTOS DIPONIBLES SÓLO CON VALE EN LA LISTA`,
        timeout: 2000
    }).show();
}

export function notificationPreventaVacia() {
    new Noty({
        type: "warning",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: `ESCOJA LOS PRODUCTOS A VENDER`,
        timeout: 2000
    }).show();
}

export function notificationPreProcesarValeVacia() {
    new Noty({
        type: "warning",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: `ESCOJA LOS PRODUCTOS A VENDER`,
        timeout: 2000
    }).show();
}

export function notificationNoHayProducto() {
    new Noty({
        type: "warning",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: `NO SE ENCONTRÓ EL PRODUCTO`,
        timeout: 2000
    }).show();
}

import React from 'react'
import {defaultPostHeaders, pfetch} from "../../helpers/wrappers";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";

let _isMounted = false;

export class ConfirmAccess extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            code: "",
            error: null,
            params: {}
        }
        this.onSubmitCode = this.onSubmitCode.bind(this)
        this.onAddRequest = this.onAddRequest.bind(this)

        Object.defineProperty(this, 'isMounted', {
            get: () => _isMounted,
            set: v => {
                _isMounted = v
            }
        })
    }

    componentDidMount() {
        this.isMounted = true
        try {
            const query = this.props.location.search.replace("?data=", "")
            this.setState({params: JSON.parse(query)})
        } catch (e) {
            this.setState({error: "No se pudo identificar bien los datos."})
        }
    }

    componentWillUnmount() {
        this.isMounted = false
    }

    onSubmitCode() {
        const params = this.state.params
        pfetch({
            fetch: {
                url: "/api/config/validate-code",
                headers: defaultPostHeaders(),
                method: "POST",
                body: JSON.stringify({
                    action: params.action,
                    entity: params.entity,
                    entityId: params.entityId,
                    code: this.state.code
                })
            },
            before: () => this.setState({isLoading: true}),
            then: () => {
                const hasAlrdyParams = params.redirect.includes("?")
                this.props.history.push(`${params.redirect}${hasAlrdyParams ? "&" : "?"}code=${this.state.code}`)
            },
            formErrors: (d) => {
                notificarError(d.message)
            },
            catch: () => notificarError("Ocurrio un error al momento de enviar el código."),
            finally: () => {
                if (this.isMounted)
                    this.setState({isLoading: false})
            }
        })
    }

    onAddRequest() {
        const params = this.state.params
        pfetch({
            fetch: {
                url: "/api/config/add-request",
                headers: defaultPostHeaders(),
                method: "POST",
                body: JSON.stringify({
                    action: params.action,
                    entity: params.entity,
                    entityId: params.entityId,
                    description: params.description,
                })
            },
            before: () => this.setState({isLoading: true}),
            then: (d) => notificarMsg(d.message),
            formErrors: (d) => notificarError(d.message),
            catch: () => notificarError("Ocurrio un error al momento de enviar la solcitud."),
            finally: () => {
                if (this.isMounted)
                    this.setState({isLoading: false})
            }
        })
    }

    render() {
        if (this.state.error)
            return <div className="alert alert-danger">
                {this.state.error}
            </div>
        return (
            <div className="container mt-3">
                <div className="row mt-3">
                    <div className="col-sm-auto">
                        Código de acceso
                    </div>
                    <div className="col-sm">
                        <input type="text" className="form-control" value={this.state.code}
                               onChange={e => {
                                   const val = e.target.value
                                   this.setState({
                                       code: val
                                   })
                               }}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <button onClick={this.onSubmitCode}
                                disabled={this.state.isLoading}
                                className="btn btn-outline-primary">
                            {this.state.isLoading ? "Cargando" : "Ok"}
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <button onClick={this.onAddRequest} disabled={this.state.isLoading} className="btn btn-link">
                            {this.state.isLoading ? "Cargando" : "Enviar solicitud de permiso"}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

}

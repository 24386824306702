import React, {Component} from "react";
import RegistrosVentasUI from "./RegistrosVentasUI";
import jsPDF from "jspdf";
import swal from "sweetalert";
import {loadLogoByRUC} from "../../helpers/funcionesGlobales";

class RegistrosVentas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ventas: [],
            IdRegistroVentas: null,
            ple: null,
            Mes: null,
            Year: null,
            Ruc: null,
            reportePLE: null,
            xmlresponse: null,
            extImg: 'png',
        };
        this.getVentas = this.getVentas.bind(this);
        this.handleVerVenta = this.handleVerVenta.bind(this);
        this.handleSavePDF = this.handleSavePDF.bind(this);
        this.exportTxtPLE = this.exportTxtPLE.bind(this);
        this.getRUC = this.getRUC.bind(this);
        this.downloadPLE = this.downloadPLE.bind(this);
        this.handleMonthPickerChange = this.handleMonthPickerChange.bind(this);
        this.handleEnviarVenta = this.handleEnviarVenta.bind(this);
        this.handleMasReportesVentas = this.handleMasReportesVentas.bind(this);
    }

    componentDidMount() {
        const Fecha = new Date();
        this.getReportePLE(Fecha.getMonth(), Fecha.getFullYear());
        this.getVentas(Fecha.getMonth(), Fecha.getFullYear());
        this.setState({
            Mes: Fecha.getMonth(),
            Year: Fecha.getFullYear()
        });

        this.getRUC();
    }

    async getVentas(Mes, Year) {
        await fetch(`/api/ventas/lista/${Mes + 1}/${Year}`)
            .then(response => response.json())
            .then(ventas => {
                    this.setState({ventas: ventas.respuesta, isLoading: false})
                    console.log(ventas)
                }
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    async getPLE(Mes, Year) {
        try {
            const response = await fetch(`/api/ventas/ple/${Mes}/${Year}`);
            const ple = await response.json();
            this.setState({ple: ple.respuesta, isLoading: false});
            return ple;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getReportePLE(Mes, Year) {
        try {
            const response = await fetch(
                `/api/ventas/ple/reporte/${Mes + 1}/${Year}`
            );
            const reportePLE = await response.json();
            this.setState({reportePLE: reportePLE.respuesta, isLoading: false, extImg: reportePLE.extImg});
            return reportePLE;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getRUC() {
        try {
            const response = await fetch(`/api/ventas/ruc`);
            const Ruc = await response.json();
            this.setState({Ruc: Ruc.respuesta[0].Ruc, isLoading: false});
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    downloadPLE(filename, text) {
        var element = document.createElement("a");
        element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," + encodeURIComponent(text)
        );
        element.setAttribute("download", filename);

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    async exportTxtPLE() {
        const Mes = this.state.Mes + 1;
        const Year = this.state.Year;

        const ple = await this.getPLE(Mes, Year);

        if (ple.respuesta.length > 0) {
            const pleFixed = ple.respuesta.map(ple => ple.PLE).join("\n");

            /** CREANDO EL NOMBRE DEL ARCHIVO CON LA NOMENCLATURA EXIGIDA EN LA SUNAT **************/
            /** DOCUMENTACIÓN SE ENCUENTRA EN PAGINA DE SUNAT: NOMENCLATURA DE LIBROS ELECTRÓNICOS**/

            const IDFIJO = "LE";
            const RUC = this.state.Ruc;
            const YEAR = this.state.Year;
            let MES = (this.state.Mes + 1).toString();
            MES = MES.padStart(2, "0");
            console.log(MES);
            const DIA = "00";
            const IDLIBRO = "140100";
            const CODOPORTUNIDAD = "00";
            const IDOPERACIONES = 1;
            const IDCONTENIDO = 1;
            const MONEDA = 1;
            const IDPLEFIJO = 1;

            const nomenclaturaPLE =
                IDFIJO +
                RUC +
                YEAR +
                MES +
                DIA +
                IDLIBRO +
                CODOPORTUNIDAD +
                IDOPERACIONES +
                IDCONTENIDO +
                MONEDA +
                IDPLEFIJO;

            this.downloadPLE(nomenclaturaPLE, pleFixed);
        } else {
            swal(
                "ERROR",
                "NO EXISTE REGISTRO DE VENTAS EN LA FECHA SELECCIONADA",
                "error"
            );
        }
    }

    async handleMonthPickerChange(maskedValue, selectedYear, selectedMonth) {
        this.setState({
            Year: selectedYear,
            Mes: selectedMonth
        });
        await this.getReportePLE(selectedMonth, selectedYear);
        await this.getVentas(selectedMonth, selectedYear);
    }

    handleVerVenta(IdRegistroVentas) {
        return () => {
            this.props.history.push(`/detalleregistroventas/${IdRegistroVentas}`);
        };
    }

    handleEnviarVenta(IdRegistroVentas) {
        return () => {
            this.sendxml(IdRegistroVentas);
        };
    }

    async sendxml(IdRegistroVentas) {
        try {
            const response = await fetch(
                `/api/configFactElectronica/ce/${IdRegistroVentas}`
            );
            const xmlresponse = await response.json();
            this.setState({xmlresponse: xmlresponse.respuesta, isLoading: false});
            return xmlresponse;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    handleMasReportesVentas() {
        this.props.history.push("/registrosventas/reportes");
    }

    handleSavePDF = async () => {
        const mmPageSize = [760, 450];
        const ptPageSize = mmPageSize.map(coord => coord * 2.83465);
        let month = this.state.Mes + 1
        month = month.toString()
        let doc = new jsPDF({
            orientation: "landscape",
            format: ptPageSize
        });
        var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        doc.setFontSize(14);
        doc.setFontType("bold");
        doc.text("FORMATO 14.1: REGISTRO DE VENTAS E INGRESOS " + this.props.sesion.RazonSocial, 17, 20);
        doc.text("RUC: " + this.state.Ruc, 17, 30);
        doc.setFontType("normal");
        doc.text("PERÍODO: " + this.state.Year + month.padStart(2, "0") + "00", 17, 40);


        const logoP = loadLogoByRUC(this.state.Ruc, 'logo', this.state.extImg);
        const logo = await logoP;
        doc.addImage(logo, "PNG", pageWidth - 110, 10, 90, 20);

        doc.autoTable({
            startY: 50,
            body: this.state.reportePLE,

            theme: "plain",
            styles: {lineWidth: 0.5},
            head: [

                [
                    {
                        content:
                            "NÚMERO CORRELATIVO DEL REGISTRO O CÓDIGO ÚNICO DE LA OPERACIÓN",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle", overflow: "linebreak", cellWidth: 50}
                    },
                    {
                        content: "FECHA DE EMISIÓN DEL COMPROBANTE DE PAGO O DOCUMENTO",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle", cellWidth: 32}
                    },
                    // {
                    //   content: "FECHA DE VENCIMIENTO Y/O PAGO",
                    //   colSpan: 1,
                    //   rowSpan : 2,
                    //   styles: { halign: "center", valign: "middle", cellWidth: 32 }
                    // },
                    {
                        content: "COMPROBANTE DE PAGO O DOCUMENTO",
                        colSpan: 3,
                        rowSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "INFORMACIÓN DEL CLIENTE",
                        colSpan: 3,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "ESTADO DEL COMPROBANTE",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "MODALIDAD DE PAGO",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle"}
                    },

                    {
                        content: "CANTIDAD DE DÍAS",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "FECHA DE PAGO DEL CRÉDITO",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle"}
                    },

                    {
                        content: "VALOR FACTURADO DE LA EXPORTACIÓN",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "BASE IMPONIBLE DE LA OPERACIÓN GRAVADA",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "IMPORTE TOTAL DE LA OPERACIÓN EXONERADA O INAFECTA",
                        colSpan: 2,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "ISC",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "IGV Y/O IPM",
                        rowSpan: 2,
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "OTROS TRIBUTOS Y CARGOS QUE NO FORMAN PARTE DE LA BASE IMPONIBLE",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "IMPORTE TOTAL DEL COMPROBANTE DE PAGO",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle", cellWidth: 32}
                    },
                    {
                        content: "TIPO DE CAMBIO",
                        colSpan: 1,
                        rowSpan: 2,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "REFERENCIA DEL COMPROBANTE DE PAGO O DOCUMENTO ORIGINAL QUE SE MODIFICA",
                        colSpan: 4,
                        styles: {halign: "center", valign: "middle"}
                    },
                ],
                [

                    {
                        content: "TIPO",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "Nº SERIE",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "NÚMERO",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "TIPO DOCUMENTO DE IDENTIDAD",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "NÚMERO",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "APELLIDOS Y NOMBRES, DENOMINACIÓN O RAZÓN SOCIAL",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },

                    {
                        content: "EXONERADA",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "INAFECTA",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "FECHA",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "TIPO",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "SERIE",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle"}
                    },
                    {
                        content: "Nº DEL COMPROBANTE DE PAGO O DOCUMENTO",
                        colSpan: 1,
                        styles: {halign: "center", valign: "middle", cellWidth: 32}
                    },
                ]
            ],
            columnStyles: {
                text: {
                    cellWidth: "wrap",
                    overflow: "linebreak",
                    halign: "right",
                    valign: "middle"
                },

                CodigoUnicoOperacion: {
                    cellWidth: 35,
                    halign: "right",
                    valign: "middle",
                    overflow: "linebreak"
                },
                FechaEmision: {
                    cellWidth: 35,
                    halign: "right",
                    valign: "middle",
                    overflow: "linebreak"
                },
                // FechaVencimiento: {
                //   cellWidth: 35,
                //   halign: "right",
                //   valign: "middle",
                //   overflow: "linebreak"
                // },
                TipoComprobante: {
                    cellWidth: 20,
                    halign: "right"
                },
                Serie: {
                    cellWidth: 20,
                    halign: "right"
                },
                NumeroComprobante: {
                    cellWidth: 20,
                    halign: "right"
                },
                TipoDocumentoCliente: {
                    cellWidth: 40,
                    halign: "right"
                },
                NroTipoDocumentoCliente: {
                    cellWidth: 30,
                    halign: "right"
                },
                RazonSocial: {
                    cellWidth: 80,
                    halign: "right"
                },


                Estado: {
                    cellWidth: 32,
                    halign: "right",
                    valign: "middle",
                    overflow: "linebreak"
                },
                IdModalidadPago: {
                    cellWidth: 25,
                    halign: "right",
                    valign: "middle",
                    overflow: "linebreak"
                },
                CantidadDiasCredito: {
                    cellWidth: 25,
                    halign: "right",
                    valign: "middle",
                    overflow: "linebreak"
                },
                FechaPago: {
                    cellWidth: 25,
                    halign: "right",
                    valign: "middle",
                    overflow: "linebreak"
                },


                Exportacion: {
                    cellWidth: 30,
                    halign: "right"
                },
                Gravadas: {
                    cellWidth: 30,
                    halign: "right"
                },
                Exoneradas: {
                    cellWidth: 25,
                    halign: "right"
                },
                Inafectas: {
                    cellWidth: 25,
                    halign: "right"
                },
                ISC: {
                    cellWidth: 25,
                    halign: "right"
                },
                IGV: {
                    cellWidth: 25,
                    halign: "right"
                },
                ICBPER: {
                    cellWidth: 25,
                    halign: "right"
                },
                Total: {
                    cellWidth: 25,
                    halign: "right"
                },
                TipoCambio: {
                    cellWidth: 25,
                    halign: "right"
                },
                FechaComprobanteModificado: {
                    cellWidth: 30,
                    halign: "right"
                },
                CodigoTipoComprobanteModificado: {
                    cellWidth: 20,
                    halign: "right"
                },
                SerieComprobanteModificado: {
                    cellWidth: 20,
                    halign: "right"
                },
                NumeroComprobanteModificado: {
                    cellWidth: 30,
                    halign: "right"
                }
            },

            columns: [
                {header: "CUO", dataKey: "CodigoUnicoOperacion"},
                {header: "EMISIÓN", dataKey: "FechaEmision"},
                // { header: "VENCIMIENTO", dataKey: "FechaVencimiento" },
                {header: "TIPO COMPROB.", dataKey: "TipoComprobante"},
                {header: "SERIE", dataKey: "Serie"},
                {header: "NÚMERO", dataKey: "NumeroComprobante"},
                {header: "TIPO CLIENTE", dataKey: "TipoDocumentoCliente"},
                {header: "NRO. DOCUMENTO", dataKey: "NroTipoDocumentoCliente"},
                {header: "RAZÓN SOCIAL", dataKey: "RazonSocial"},

                {header: "ESTADO DEL COMPROBANTE", dataKey: "Estado"},
                {header: "MODALIDAD DE PAGO", dataKey: "IdModalidadPago"},
                {header: "DIAS DE CREDITO", dataKey: "CantidadDiasCredito"},
                {header: "FECHA DE PAGO DEL CREDITO", dataKey: "FechaPago"},

                {header: "EXPORTACIÓN", dataKey: "Exportacion"},
                {header: "GRAVADAS", dataKey: "Gravadas"},
                {header: "EXONERADAS", dataKey: "Exoneradas"},
                {header: "INAFECTAS", dataKey: "Inafectas"},
                {header: "ISC", dataKey: "ISC"},
                {header: "IGV", dataKey: "IGV"},
                {header: "ICBPER", dataKey: "ICBPER"},
                {header: "TOTAL", dataKey: "Total"},
                {header: "TIPO CAMBIO", dataKey: "TipoCambio"},
                {
                    header: "FECHA COMPROBANTE MODIFICADO",
                    dataKey: "FechaComprobanteModificado"
                },
                {
                    header: "CODIGO",
                    dataKey: "CodigoTipoComprobanteModificado"
                },
                {
                    header: "SERIE",
                    dataKey: "SerieComprobanteModificado"
                },
                {
                    header: "NUMERO",
                    dataKey: "NumeroComprobanteModificado"
                }
            ]
        });
        doc.setFontSize(10);
        doc.setFontType("italic");
        doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

        doc.save("reporte_ventas.pdf");
    }

    render() {
        return (
            <>
                {/* <Header
          parent_icon={parent_icon_header}
          child_icon={child_icon_header}
          parent="Ventas"
          child="Lista"
        /> */}
                <div>
                    <RegistrosVentasUI
                        {...this.state}
                        handleVerVenta={this.handleVerVenta}
                        pdfViewer={this.handleSavePDF}
                        txtPLE={this.exportTxtPLE}
                        handleMonthPickerChange={this.handleMonthPickerChange}
                        handleEnviarVenta={this.handleEnviarVenta}
                        handleMasReportesVentas={this.handleMasReportesVentas}
                    />
                </div>
            </>
        );
    }
}

export default RegistrosVentas;

export async function cfetch(config) {
    let res = await fetch(config.url, new ConfigBuilder(config).getConfig())
    if (res.ok || res.status === 400)
        return await res.json()
    return null;
}

export function pfetch(conf) {
    conf.before()
    fetch(conf.fetch.url, {...conf.fetch})
        .then(res => [res.json(), res.status])
        .then(res => {
            const [data, status] = res;
            if (status === 200)
                data.then(r => conf.then(r))
            if (status === 403)
                data.then(() => {
                    if (conf.forbidden)
                        conf.forbidden()
                }).catch(() => {
                    if (conf.forbidden)
                        conf.forbidden()
                })
            if (status === 422)
                data.then(r => conf.formErrors(r))
            if (status === 500)
                data.then(r => conf.error(r))
                    .catch((e) => conf.error(e))
        })
        .catch(err => conf.error(err))
        .finally(() => conf.finally())
}

export function defaultGetHeaders() {
    return {
        "Accept": "application/json",
    }
}

export function defaultPostHeaders() {
    return {
        "Accept": "application/json",
        "Content-Type": "application/json",
    }
}

class ConfigBuilder {

    constructor(data) {
        this.data = data;
        this.config = {}
    }

    _setHeaders() {
        if (this.data.headers)
            this.config['headers'] = this.data.headers;
        return this;
    }

    _setMethod() {
        if (this.data.method)
            this.config['method'] = this.data.method;
        return this;
    }

    _setBody() {
        if (this.data.body)
            this.config['body'] = this.data.body;
        return this;
    }

    getConfig() {
        this._setHeaders()
            ._setBody()
            ._setMethod()
        return this.config;
    }
}

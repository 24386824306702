import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import {BotonEditar, BotonLetrasRojo, BotonRemovePequeño} from "../Plantillas/Botones.js";
import swal from "sweetalert";
import {ACTION, ENTITY} from "contatrib-utils";

class PreventasList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datapreventas: {
                preventas: []
            },
            loading: true,
            error: null,
        };
        this.conf = JSON.parse(window.localStorage.getItem('extraConf'))
    }

    onGoToEdit(id) {
        if (this.conf.useAccessCode) {
            const data = JSON.stringify({
                action: ACTION.UPDATE,
                entity: ENTITY.PREVENTA,
                entityId: id,
                redirect: `/preVentas/edit/${id}`,
            })
            this.props.history.push(`/check-access/?data=${data}`)
        } else {
            this.props.history.push(`/preVentas/edit/${id}`)
        }
    }

    componentDidMount = () => {
        this.getPreventas()
        this.intervalo = setInterval(this.getPreventas, 3000)
    }

    componentWillUnmount() {
        clearInterval(this.intervalo)
    }

    getPreventas = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/preVentas/preventasList`);
            const preventas = await response.json();
            this.setState({
                loading: false,
                datapreventas: preventas
            });
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    handleOnDeletePreventa(IdPreventa) {
        return async () => {
            const willDelete = await swal({
                title: "¿Está seguro?",
                text: "¿Eliminar esta Preventa?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                closeOnClickOutside: false
            });

            if (willDelete) {
                await this.getDetallePreventaACancelar(IdPreventa)
            }
        };
    }

    handleOnDeleteAllPreventas(preventas) {
        return async () => {
            const willDelete = await swal({
                title: "¿Está seguro?",
                text: "¿Eliminar esta Preventa?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                closeOnClickOutside: false
            });

            if (willDelete) {
                await this.CancelarTodo(preventas)
            }
        };
    }

    CancelarTodo = async (preventas) => {

        for (let i = 0; i < preventas.length; i++) {
            //console.log(preventas[i].IdPreventa,i);
            this.getDetallePreventaACancelar(preventas[i].IdPreventa)
        }

    }

    async getDetallePreventaACancelar(IdPreventa) {

        try {
            const response = await fetch(`/api/gestionPreventas/detallepreventa/${IdPreventa}`);
            const data = await response.json();
            console.log(data.respuesta)

            for (let i = 0; i < data.respuesta.length; i++) {
                this.ActualizarStockComprometidoPreventa(data.respuesta[i], 0 - data.respuesta[i].Cantidad, IdPreventa)
            }

        } catch (error) {
            this.setState({loading: false, error: error});
        }

    }

    ActualizarStockComprometidoPreventa = async (item, variacionStock, IdPreventa) => {

        try {
            await fetch(`/api/preVentas/editStockCompromPreVNoGenerada`, {
                method: "PUT",
                body: JSON.stringify({
                    variacionStock: variacionStock * item.TipoCambio,
                    IdProducto: item.IdProducto,
                    IdTipoStock: item.IdTipoStock,
                    IdAlmacen: item.IdAlmacen
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })

            this.DeletePreventa(IdPreventa)
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
            console.log(error)
        }
    }

    DeletePreventa = async (IdPreventa) => {
        if (this.props.location.from) {
            return this.props.history.push(this.props.location.from)
        }
        try {
            fetch(`/api/preVentas/deletePreVenta/${IdPreventa}`, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    render() {
        if (this.state.loading === true && this.state.datapreventas === undefined) {
            return (
                <React.Fragment>
                    <div className="d-flex justify-content-center">
                        <label>Cargando...</label>
                        <div className="spinner-border text-primary ml-3" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        if (this.state.datapreventas.preventas.length === 0) {
            return (
                <React.Fragment>
                    <div className="p-5">
                        <div>
                            Lista Vacía
                        </div>
                    </div>
                </React.Fragment>
            )
        } else {
            const filtrarPreventas = this.state.datapreventas.preventas

            if (this.state.error) {
                return `Error: ${this.state.error.message}`;
            }

            if (filtrarPreventas.length === 0) {
                return (
                    <React.Fragment>
                        <div className="p-5">
                            <div>
                                <h3>No se encontraron PreVentas sin Generar</h3>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }

            return (
                <React.Fragment>
                    <div className="p-5">
                        <BotonLetrasRojo
                            onClick={this.handleOnDeleteAllPreventas(this.state.datapreventas.preventas)}
                            className="mb-2"
                        >Cancelar Todo
                        </BotonLetrasRojo>
                        <div className="row">
                            <ReactTable
                                data={filtrarPreventas}
                                filterable
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value
                                }
                                columns={[
                                    {
                                        columns: [
                                            {
                                                Header: "",
                                                filterable: false,
                                                headerClassName: "",
                                                width: 120,
                                                Cell: original => {
                                                    return (
                                                        <React.Fragment>
                                                            <BotonRemovePequeño
                                                                onClick={this.handleOnDeletePreventa(original.original.IdPreventa)}
                                                                title="Eliminar PreVenta"
                                                            />
                                                            <BotonEditar
                                                                onClick={e => {
                                                                    e.preventDefault()
                                                                    this.onGoToEdit(original.original.IdPreventa)
                                                                }}
                                                                title="Ir a la PreVenta" className="ml-2"
                                                            />
                                                        </React.Fragment>
                                                    );
                                                }
                                            },
                                            {
                                                Header: "Cliente",
                                                id: "RazonSocial",
                                                headerClassName: "",
                                                width: 300,
                                                accessor: d => d.Alias.concat(" - ",
                                                    d.RazonSocial == null ? `${d.PrimerNombre} ${d.ApellidoPaterno}` : d.RazonSocial,
                                                ),
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["RazonSocial"]}),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Fecha de Emisión",
                                                id: "FechaEmision",
                                                headerClassName: "",
                                                width: 180,
                                                accessor: d => d.FechaEmision,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["FechaEmision"]}),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Monto Total",
                                                id: "Total",
                                                headerClassName: "",
                                                width: 150,
                                                accessor: d => d.Simbolo.concat(" ",
                                                    d.Total.toLocaleString('en-GB', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })),
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["Total"]}),
                                                filterAll: true,
                                                style: {textAlign: "right"}
                                            },
                                            {
                                                Header: "Pago",
                                                id: "ModalidadPagoNombre",
                                                headerClassName: "",
                                                width: 80,
                                                accessor: d => d.ModalidadPagoNombre,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["ModalidadPagoNombre"]}),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Documento",
                                                id: "Descripcion",
                                                headerClassName: "",
                                                width: 120,
                                                accessor: d => d.Descripcion,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["Descripcion"]}),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Usuario",
                                                id: "Usuario",
                                                headerClassName: "",
                                                width: 120,
                                                accessor: d => d.Usuario,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["Usuario"]}),
                                                filterAll: true
                                            }

                                        ]
                                    }
                                ]}
                                defaultPageSize={5}
                                className="-striped -highlight"
                                previousText="Anterior"
                                nextText="Siguiente"
                                loadingText="Cargando..."
                                noDataText="No se encontraron registros"
                                pageText="Página"
                                ofText="de"
                                rowsText="filas"
                                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                            />
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
}

export default PreventasList

import React from 'react'
import ReactDOM from 'react-dom'
import '../styles/Modal.css'
// import "../../componentes/styles/Modal.css";

function ClienteModal (props) {
    if(!props.isOpen){
        return null
    }
    return ReactDOM.createPortal(
        <div className="Modal" tabIndex='-1'>
                <div className="Modal__container">
                    <div className="modal-header">
                        <h5 className="modal-header-title">{props.title}</h5>
                        <button className="close" onClick={props.onClose}>X</button>
                    </div>
                    <div className="modal-body">
                        {props.children}
                    </div>
                    <div className="modal-footer">
                        {/* <button className="btn btn-secondary" onClick={props.onClose}>Cancelar</button>
                        <button className="btn btn-primary" onClick={props.onSubmit} >Aceptar</button> */}
                    </div>
                </div>
        </div>
        ,
        
        document.getElementById('modal')
    );
}

export default ClienteModal
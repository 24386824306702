import FiltroReportDocSunat from "./FiltroReportDocSunat";
import React from "react";
import {isValidDate} from "../../helpers/validators";
import {notificarError} from "../Almacenes/AlmacenNotify";
import {convertObjToQueryParams} from "../../helpers/utils";
import ReactExport from "react-data-export";
import {getConfExcelForDocReport} from "../../helpers/reports";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default class ReporteDocSunatScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {dataReporte: []}
        this.onFilterPressedHandler = this.onFilterPressedHandler.bind(this);
    }

    onFilterPressedHandler(payload) {
        if (isValidDate(payload.fechaInicio) && isValidDate(payload.fechaFin))
            payload._sucursales && payload._sucursales.length ? this.fetchDataReport(payload)
                : notificarError("Debe seleccionar al menos una sucursal.")
        else
            notificarError("La fechas ingresadas son incorrectas.")
    }

    async fetchDataReport(payload) {
        this.setState({isLoading: true, dataReporte: []})
        let res = await fetch(`/api/reportes/docs-sunat/v2?${convertObjToQueryParams(payload)}`)
        if (res.ok) {
            let data = await res.json();
            this.setState({dataReporte: data})
        } else
            notificarError("Se ha producido un error al momento de extraer los datos.")
        this.setState({isLoading: false})
    }

    getConfigReport(data) {
        return getConfExcelForDocReport(data)
    }

    render() {
        return (
            <div className="container">
                <div className="alert alert-warning">
                    La duración de descarga del reporte es directamente proporcional a la cantidad de días y registros
                    comprendidos entre la fecha de inicio y fin.
                </div>

                <FiltroReportDocSunat
                    customQueryParams={{isForVenta: "1"}}
                    isLoading={this.state.isLoading} onFilterPressed={this.onFilterPressedHandler}/>
                {this.state.isLoading ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status"/>
                    </div>
                ) : null}
                {this.state.dataReporte.length ?
                    <div className="row">
                        <div className="col-sm mt-2">
                            <ExcelFile
                                element={
                                    <button className="boton__verdeOscuro" style={{float: "right"}}>
                                        <i className="fas fa-file-excel"/>
                                    </button>
                                }
                                filename="reporte-docs"
                            >
                                <ExcelSheet
                                    dataSet={this.getConfigReport(this.state.dataReporte)}
                                    name="Productos"
                                />
                            </ExcelFile>
                        </div>
                    </div> :
                    <div className="alert alert-info mt-3">
                        No se han encontrado registros.
                    </div>
                }
            </div>
        );
    }

}

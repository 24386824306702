import {
  TelefonoRegex,
  CelularRegex,
  UltimaEntradaRegex,
  UltimaSalidaRegex,
  UltimaTransferenciaRegex,
  UltimoAjusteRegex
} from "./AlmacenesRegexValidator";

export function validarInputsAlmacen(Errores, name, value) {
  switch (name) {
    case "Telefono":
      Errores.Telefono = TelefonoRegex.test(value)
        ? ""
        : "*El número de Teléfono sólo debe contener números";
      break;
    case "Celular":
      Errores.Celular = CelularRegex.test(value) ? "" : "*Formato de celular inválido";
      break;
    case "UltimaEntrada":
      Errores.UltimaEntrada = UltimaEntradaRegex.test(value)
        ? ""
        : "*La cantidad debe ser entre 0 - 999999.999999";
      break;
      case "UltimaSalida":
      Errores.UltimaSalida = UltimaSalidaRegex.test(value)
        ? ""
        : "*La cantidad debe ser entre 0 - 999999.999999";
      break;
      case "UltimaTransferencia":
      Errores.UltimaTransferencia = UltimaTransferenciaRegex.test(value)
        ? ""
        : "*La cantidad debe ser entre 0 - 999999.999999";
      break;
      case "UltimoAjuste":
      Errores.UltimoAjuste = UltimoAjusteRegex.test(value)
        ? ""
        : "*La cantidad debe ser entre 0 - 999999.999999";
      break;
    default:
      break;
  }
}

import React from "react";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import _ from "lodash";

export default class PresentacionPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        }
        this.searchProds = this.searchProds.bind(this)
        this.onChangePresentacion = this.onChangePresentacion.bind(this)
    }

    async searchProds(search) {
        return new Promise((resolve, reject) => {
            this.setState({isLoading: true});
            fetch(`/api/presentaciones/query?search=${search}`)
                .then(r => r.json())
                .then(prod => {
                    resolve(prod.map(d => {
                        return {
                            ...d,
                            label: d.descripcion,
                            value: d.IdPresentacion,
                            afectacionFree: Number(d.afectacionFree)
                        }
                    }))
                }).catch(reject)
                .finally(() => this.setState({isLoading: false}))
        })
    }

    onChangePresentacion(e) {
        this.props.onChangePresentacion(_.isObject(e) ? [e] : e)
    }

    render() {
        return (
            <div className={this.props.className || "form-group"}>
                <label>Seleccione el producto {Boolean(this.props.required) ? "*" : ""}</label>
                <AsyncSelect
                    defaultOptions={true}
                    onChange={this.onChangePresentacion}
                    loadOptions={this.searchProds}
                    isClearable={true}
                    isLoading={this.state.isLoading}
                    placeholder={"Seleccione productos"}
                    isMulti={Boolean(this.props.isMulti)}
                />
            </div>
        );
    }

}

import React from 'react'
import ListItems from './ListItems'
import DatePicker from "react-datepicker";
import NumericInput from 'react-numeric-input';
import ProveedorNuevo from "../Proveedores/ProveedorNuevo";
import ProveedorSearch from "../Proveedores/ProveedoresListaSearch";
import ModalProveedor from "../clientes/ClienteModal";
import {
    BotonAddProveedor,
    BotonAddPequeño,
    BotonSearchProveedor,
} from '../Plantillas/Botones';
import AsyncSelect from "react-select/async/dist/react-select.esm";

class OrdenCompraForm extends React.Component {

    searchProducts(search) {
        return new Promise((resolve, reject) => {
            fetch(`/api/ordenescompra/listprod/382?search=${search}`)
                .then(r => r.json())
                .then(prods => resolve(prods))
                .catch(reject)
        })
    }

    render() {
        return (
            <div>
                <div className="form-row">
                    <div className="col-3">
                        <input type="text" name="NroOC" className="form-control" placeholder="# orden"
                               value={this.props.form.NroOC} disabled/>
                    </div>
                    <div className="col-9">
                        <input type="text" name="NroOC" className="form-control" placeholder="# orden"
                               value={this.props.form.Ruc + ' :: ' + this.props.form.NomProveedor} disabled/>
                    </div>
                </div>
                <hr width="1024px"></hr>
                <div className="d-flex flex-column">
                    <div className="p-1">
                        <form onSubmit={this.props.handleSubmit} className="grid">
                            <ul className="nav nav-tabs" id="pills-tab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="pills-proveedor-tab" data-toggle="pill"
                                       href="#pills-proveedor" role="tab" aria-controls="pills-proveedor"
                                       aria-selected="true">Datos Proveedor</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="pills-oc-tab" data-toggle="pill" href="#pills-oc"
                                       role="tab" aria-controls="pills-oc" aria-selected="false">Datos Orden de
                                        Compra</a>
                                </li>
                                <li className="nav-item">
                                    <a className={this.props.form.IdOrdenCompra ? "nav-link" : "nav-link disabled"}
                                       id="pills-items-tab" data-toggle="pill" href="#pills-items" role="tab"
                                       aria-controls="pills-items" aria-selected="false">Lista de Items</a>
                                </li>
                            </ul>

                            <div className="tab-content" id="pills-tabContent">
                                {/* Datos Proveedor */}
                                <div className="tab-pane fade show active p-3" id="pills-proveedor" role="tabpanel"
                                     aria-labelledby="pills-proveedor-tab">
                                    <div className="form-group row">
                                        <div className="col-3">
                                            <label> Nro RUC</label>
                                            <div className="input-group">
                                                <input name="Ruc"
                                                       className="form-control"
                                                       onChange={this.props.handleChangeForm}
                                                       type="text"
                                                       value={this.props.form.Ruc}
                                                       disabled={true}//{this.props.form.inactivo}
                                                       maxLength="11"
                                                />

                                            </div>
                                        </div>
                                        <div className="col-3 mt-4">
                                            <BotonAddProveedor
                                                type="button"
                                                name="BtnAddProveedor"
                                                className="mr-2 mt-2"
                                                onClick={this.props.handleClickNuevoProveedor}
                                                title="agregar proveedor"
                                            />
                                            <BotonSearchProveedor
                                                type="button"
                                                name="BtnAddProveedor"
                                                className="mt-2"
                                                onClick={this.props.handleClickBuscarProveedor}
                                                title="buscar proveedor"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <label> Proveedor</label>
                                            <input type="text"
                                                   name="NomProveedor"
                                                   className="form-control"
                                                   placeholder="razon social"
                                                   onChange={this.props.handleChangeForm}
                                                   value={this.props.form.NomProveedor}
                                                   disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <label> Contacto</label>
                                            <input type="text"
                                                   name="NomContacto"
                                                   className="form-control"
                                                   placeholder="persona de contacto"
                                                   onChange={this.props.handleChangeForm}
                                                   value={this.props.form.NomContacto}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Datos de Envío */}
                                <div className="tab-pane fade p-3" id="pills-oc" role="tabpanel"
                                     aria-labelledby="pills-oc-tab">
                                    <div className="form-group row">
                                        <div className="col-3">
                                            <label>Fecha de Emisión</label>
                                            <DatePicker
                                                name="FechaEmision"
                                                showPopperArrow={true}
                                                selected={this.props.form.FechaEmision}
                                                onChange={this.props.handleChangeFechaEmision}
                                                dateFormat="dd-MM-yyyy"
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <label>Fecha de Entrega</label>
                                            <DatePicker
                                                name="FechaEntrega"
                                                showPopperArrow={true}
                                                selected={this.props.form.FechaEntrega}
                                                onChange={this.props.handleChangeFechaEntrega}
                                                dateFormat="dd-MM-yyyy"
                                                minDate={this.props.form.FechaEmision}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <div className="form-check m-3">
                                                <input className="form-check-input"
                                                       type="checkbox"
                                                       name="IncluyeIGV"
                                                       checked={this.props.form.IncluyeIGV}
                                                       id="IncluyeIGV"
                                                       onChange={this.props.handleChangeCheckBox}
                                                />
                                                <label className="form-check-label" htmlFor="IncluyeIGV">Incluye
                                                    IGV</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <label>Almacén</label>
                                            <select
                                                className="form-control"
                                                aria-haspopup="true"
                                                name="IdAlmacen"
                                                value={this.props.form.IdAlmacen}
                                                onChange={this.props.handleChangeForm}
                                            >
                                                <option key={0} value={0}>seleccionar</option>
                                                {
                                                    this.props.dataAlmacenes.map((item) => {
                                                        return <option key={item.IdAlmacen}
                                                                       value={item.IdAlmacen}>{item.Nombre}</option>
                                                    })
                                                }

                                            </select>
                                            {/* <button>Buscar</button> */}
                                        </div>
                                    </div>

                                    <div className="form-group row ">
                                        <div className="col-3">
                                            <label>Tipo Moneda</label>
                                            <select
                                                className="form-control custom-select "
                                                name="IdMoneda"
                                                value={this.props.form.IdMoneda}
                                                onChange={this.props.handleChangeForm}
                                            >
                                                <option key={0} value={0}>seleccionar</option>
                                                {
                                                    this.props.dataMoneda.map((item) => {
                                                        return <option key={item.IdMoneda}
                                                                       value={item.IdMoneda}> {item.Nombre} </option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-check">
                                                <label> Tipo Cambio</label>
                                                <NumericInput
                                                    className="form-control form-control-sm"
                                                    name="TipoCambio"
                                                    min={0}
                                                    max={10000}
                                                    step={1}
                                                    precision={4}
                                                    onChange={this.props.handleChangeTipoCambio}
                                                    value={this.props.form.TipoCambio}
                                                    onClick={e => e.target.select()}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <label>Forma de Pago</label>
                                            <select onChange={this.props.handleChangeForm}
                                                    className="form-control custom-select"
                                                    name="FormaPago"
                                                    value={this.props.form.FormaPago}
                                            >
                                                <option value="Contado">Contado</option>
                                                <option value="Credito">Crédito</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row ">
                                        <div className="col">
                                            <label>Glosa</label>
                                            <textarea type="text"
                                                      name="Glosa"
                                                      className="form-control"
                                                      value={this.props.form.Glosa}
                                                      onChange={this.props.handleChangeForm}
                                            />
                                        </div>
                                    </div>

                                </div>

                                {/* Lista de Items */}
                                <div className="tab-pane fade p-3" id="pills-items" role="tabpanel"
                                     aria-labelledby="pills-items-tab">
                                    <div className="form-row mb-3">
                                        <div className="col-7">
                                            <label>Elejir Item</label>
                                            <AsyncSelect
                                                defaultOptions={true}
                                                onChange={this.props.handleChange_SelectSearch}
                                                value={this.props.prod}
                                                loadOptions={this.searchProducts}
                                            />
                                        </div>
                                        <div className="col-2">
                                            <label> Precio Compra</label>
                                            <NumericInput
                                                className="form-control form-control-sm"
                                                name="PrecioCosto"
                                                min={0}
                                                max={10000}
                                                step={1}
                                                precision={5}
                                                onChange={this.props.handleChangePrecioCosto}
                                                value={this.props.form.PrecioCosto}
                                                onClick={e => e.target.select()}
                                            />
                                        </div>
                                        <div className="col-2">
                                            <label> Cantidad</label>
                                            <NumericInput
                                                className="form-control form-control-sm"
                                                name="Cantidad"
                                                min={0}
                                                max={10000}
                                                step={1}
                                                precision={3}
                                                onChange={this.props.handleChangeCantidad}
                                                value={this.props.form.Cantidad}
                                                onClick={e => e.target.select()}
                                            />
                                        </div>
                                        <div className="col-1">
                                            <BotonAddPequeño
                                                title="agregar item"
                                                type="button"
                                                className="mt-4"
                                                onClick={this.props.handleAgregarItem}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                    </div>
                                    <div className="form-row">
                                        <ListItems
                                            dataDetalleOC={this.props.dataDetalleOC}
                                            handleEliminarItem={this.props.handleEliminarItem}
                                        />
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>

                    {/* Totales */}
                    <div className="p-2">
                        <div className="card border-info mb-3">
                            {/* <div className="card-header text-center">Totales</div> */}
                            <div className="d-flex justify-content-around p-2">
                                <p> {`Sub Total: ${this.props.form.SubTotal}`} </p>
                                <p> {`IGV: ${this.props.form.IGV}`} </p>
                                <p> {`Total: ${this.props.form.Total}`} </p>

                            </div>
                        </div>
                    </div>
                </div>

                {/* Botones de Acción */}
                <div className="form-row justify-content-center">
                    <button type="button" className="btn btn-secondary mr-2"
                            onClick={this.props.handleCloseModalOC}>Cancelar
                    </button>
                    <button type="button" className="btn btn-info"
                            onClick={this.props.handleSubmit}>{this.props.accion == 'Nuevo' ? 'Crear OC' : 'Actualizar OC'}</button>
                </div>
                {
                    this.props.form.accion === 'NuevoProveedor' ?
                        <ModalProveedor isOpen={this.props.form.modalProveedorIsOpen}
                                        onClose={this.props.handleCloseModalProv}
                                        title="NUEVO PROVEEDOR">
                            <ProveedorNuevo
                                onClose={this.props.handleCloseModalProv}
                                NumeroDocumento={this.props.form.Ruc}
                                AsignarValorDoc={this.props.AsignarValorDoc}
                                sw='oc_nuevo'
                            />
                        </ModalProveedor> :
                        <ModalProveedor isOpen={this.props.form.modalProveedorIsOpen}
                                        onClose={this.props.handleCloseModalProv}
                                        title="BUSCAR PROVEEDOR">
                            <ProveedorSearch
                                onClose={this.props.handleCloseModalProv}
                                form={this.props.form}
                            />
                        </ModalProveedor>
                }

            </div>

        )
    }
}

export default OrdenCompraForm;

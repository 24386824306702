import React from 'react';
import "react-moment";
import moment from "moment";
//Header
//Firebase
// import firebase from "firebase";
import {notificationError} from './empresasComponentes/EmpresasNotify.js'
import {BotonCancelar, BotonGuardarLetras} from "./Plantillas/Botones";
import {validarInputsEmpresa} from "./empresasComponentes/EmpresasValidator.js";
import EmpresasAddFormDatosGenerales from "./empresasComponentes/EmpresasAddFormDatosGenerales.js"
import PageLoading from './PageLoading.js';
import './styles/tabla.css';
// import { isThisQuarter } from 'date-fns';
import apiFetch from '../helpers/apiFetch';
import Notify from './Notify';

class EmpresasEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // user: null, //User de firebase para el logo
            // uploadValue: 0,
            // uploadValueFirma: 0,
            loading: false,
            error: null,
            mostrarCorreosOpcionales: true,
            mostrarTelefonosOpcionales: true,
            AddOrEdit: "Edit",
            IdEmpresa: '',
            Ruc: '',
            RazonSocial: null,
            Direccion: null,
            Telefono: null,
            Logo: null,
            Firma: null,
            TelefonoDos: null,
            TelefonoTres: null,
            IdUbigeo: null,
            NombreComercial: null,
            PaginaWeb: null,
            IdMoneda: null,
            CorreoElectronico: null,
            CorreoElectronicoDos: null,
            CorreoElectronicoTres: null,
            Sector: null,
            FechaDeConstitucion: null,
            RepresentanteLegal: null,
            Retenedor: null,
            Percepcion: null,
            EsRuss: null,
            IdPlanSunat: null,
            ContribuyenteCondicion: null,
            ContribuyenteEstado: null,
            FechaDeInscripcion: null,
            SistemaContabilidad: null,
            SistemaEmision: null,
            UsuarioSolPrincipal: null,
            ClaveSolPrincipal: null,


            Errores: {
                Ruc: "",
                RazonSocial: "",
                Direccion: "",
                Telefono: "",
                TelefonoDos: "",
                TelefonoTres: "",
                NombreComercial: "",
                CorreoElectronico: "",
                CorreoElectronicoDos: "",
                CorreoElectronicoTres: "",
                Sector: "",
                RepresentanteLegal: "",
                FechaDeInscripcion: "",
                FechaDeConstitucion: ""
            },
            dataP: {
                planesSunat: []
            },
            dataM: {
                results: []
            },
            data: {
                ubigeos: []
            },

            checkRetenedor: true,
            checkPercepcion: true,
            checkEsRuss: true,

            Moneda: [],
            planSun: [],
            ubigeoReg: [],
            ubigeoProv: [],
            ubigeoDis: [],
            valueRegion: null,
            valueProvincia: null,
            valueDistrito: null,

            pruebaLogo: new FormData(),
            pruebaFirma: new FormData(),

            formDataL: new FormData(),
            formDataF: new FormData()
        };
        this.handleChange = this.handleChange.bind(this);
        this.getEmpresaById = this.getEmpresaById.bind(this);
        this.getPlanesSunat = this.getPlanesSunat.bind(this);
        this.retenedorPercepcion = this.retenedorPercepcion.bind(this);
        this.EsRussChange = this.EsRussChange.bind(this);
        this.editEmpresa = this.editEmpresa.bind(this);
        this.getUbigeoById = this.getUbigeoById.bind(this);
        this.RegionChange = this.RegionChange.bind(this)
        this.ProvinciaChange = this.ProvinciaChange.bind(this)
        this.DistritoChange = this.DistritoChange.bind(this)
        this.traerUbigeoPorProvincia = this.traerUbigeoPorProvincia.bind(this)
        this.traerUbigeoPorDistrito = this.traerUbigeoPorDistrito.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getMoneda = this.getMoneda.bind(this);
        this.getLogos = this.getLogos.bind(this);
        this.SubirLogo = this.SubirLogo.bind(this);
        this.SubirFirma = this.SubirFirma.bind(this);
    }

    componentDidMount = () => {
        const IdEmpresa = this.props.match.params.id;
        this.getEmpresaById(IdEmpresa);
    }

    getLogos = async () => {

        this.setState({loading: true, error: null})
        try {
            let response = await fetch(`${process.env.REACT_APP_API_FACTURADOR_URL}/download/img/${this.state.Ruc}/logo`);
            let data = response
            this.setState({
                loading: false,
                Logo: data.url
            })
        } catch (error) {
            Notify('Hubo un problema con el Logo', 'warning', 1500)
            this.setState({loading: false, error: error})
        }
    }

    getFirmas = async () => {
        // debugger
        this.setState({loading: true, error: null})
        try {
            const response = await fetch(`${process.env.REACT_APP_API_FACTURADOR_URL}/download/img/${this.state.Ruc}/firma`);
            const data = response
            // console.log("getFirmas ",data)
            // if(data.statusText == "Not Found"){
            //   Notify("No se encontró la firma.","warning",1500)
            // }else{
            this.setState({
                Firma: data.url,
                loading: true,
                // loading: true
            })
            // }
        } catch (error) {
            Notify('Hubo un problema con la Firma', 'warning', 1500)
            this.setState({loading: false, error: error})
        }
    }

    SubirLogo = async (e) => {
        // debugger
        var logoInput = document.getElementById('logoInput');
        let file = logoInput.files[0];
        // let formData = new FormData();


        var archivoRuta = logoInput.value;
        var extPermitidas = /(.png|.jpg|.jpeg|.PNG|.JPG|.JPEG)$/i;
        if (!extPermitidas.exec(archivoRuta)) {
            Notify('Ingrese una imagen con alguno de los siguientes formatos: .jpeg/.jpg/.png.', "warning", 1500);
            logoInput.value = '';
            //document.getElementById('visorLogo').innerHTML = ''; //Visualizar el logo
            return false;
        } else if (logoInput.files && logoInput.files[0]) {
            var visor = new FileReader();
            visor.onload = function (e) {
                var image = new Image();
                image.src = e.target.result;
                image.onload = function () { //Mantener el function ya que con = () => se cae, hacer la prueba de bandera con function ()
                    // console.log("img ------ ",this.width);
                    //Acá se necesita hacer la validacion del tamaño de la imagen y evitar que se guarde. //si cumple el tamaño insertarlo en visorLogo

                    if (this.width >= 10 && this.width <= 5000) {

                        document.getElementById('visorLogo').innerHTML = '<img name="Logo" src="' + e.target.result + '" >';//El que permite visaulizar el logo subido

                    } else {
                        alert('La imagen debe ser tener un ancho entre 10 y 5000');
                        // logoInput.value='';
                        return false;
                    }
                };
            }//bandera para ver si se hace el readAsDataURL o no se ejecuta primero el readAsDataURl y el append y luego el onload. ver el orden
            visor.readAsDataURL(logoInput.files[0]);//console.log(visor.result);
        } //Bandera para saber si se hace el append o no
        // this.state.pruebaLogo.append("Logo",e.target.files[0],`${this.state.Ruc}.jpeg`); //El que guarda y esta listo para enviarse al back


        this.state.formDataL.append("file", file);
        // const respuesta = await fetch(`${process.env.REACT_APP_API_FACTURADOR_URL}/upload/img/${this.state.Ruc}/logo`, {method: "POST", body: formData});
        // const rpt = await respuesta.json();
        // console.log(rpt.success)// para controlar si se suve o no
    }

    SubirFirma = async (e) => {
        var FirmaInput = document.getElementById('FirmaInput');
        let file = FirmaInput.files[0];
        // let formData = new FormData();


        var archivoRuta = FirmaInput.value;
        var extPermitidas = /(.png|.jpg|.jpeg|.PNG|.JPG|.JPEG)$/i;
        if (!extPermitidas.exec(archivoRuta)) {
            alert('Ingrese una imagen con alguno de los siguientes formatos: .jpeg/.jpg/.png.');
            FirmaInput.value = '';
            //document.getElementById('visorFirma').innerHTML = ''; //Visualizar el Firma
            return false;
        } else if (FirmaInput.files && FirmaInput.files[0]) {
            var visor = new FileReader();
            visor.onload = (e) => {
                var image = new Image();
                image.src = e.target.result;
                image.onload = function () { //Mantener el function ya que con = () => se cae, hacer la prueba de bandera con function ()
                    // console.log("img ------ ",this.width);
                    if (this.width >= 10 && this.width <= 5000) {
                        document.getElementById('visorFirma').innerHTML = '<img name="Firma" src="' + e.target.result + '" >';
                    } else {
                        alert('La imagen debe ser tener un ancho entre 50 y 500');
                        FirmaInput.value = '';
                        return false;
                    }
                };
            }

            visor.readAsDataURL(FirmaInput.files[0]);
        }


        this.state.formDataF.append("file", file);
        // console.log("FirmaInput: ",FirmaInput.value)
        // const respuesta = await fetch(`${process.env.REACT_APP_API_FACTURADOR_URL}/upload/img/${this.state.Ruc}/firma`, {method: "POST", body: formData});
        // const rpt = await respuesta.json();

        // this.state.pruebaFirma.append("Firma",e.target.files[0],`${this.state.Ruc}.jpeg`);
    }
    //   componentWillMount = () => {
    //     firebase.auth().onAuthStateChanged(user => {
    //         this.setState({  user  })
    //     });
    // }

//   handleLogout = () => {
//     firebase.auth().signOut()
//     .then(result => console.log(`${result.user.email} ha salido`))
//     .catch(error => console.log(`Error ${error.code}: ${error.message}`));
// }

// handleAuth = () => { //Funcion para usar el auth de firebase
//     const provider = new firebase.auth.GoogleAuthProvider();
//     firebase.auth().signInWithPopup(provider)
//         .then(result => console.log(`${result.user.email} ha iniciado sesión`))
//         .catch(error => console.log(`Error ${error.code}: ${error.message}`));
// }

// handleUpload = (event) => {
//   let file = event.target.files[0]
//   console.log(this.state.RazonSocial)
//   const storageRef = firebase.storage().ref(`/Logos/${this.state.Ruc.concat(".Logo")}`);
//   const task = storageRef.put(file);

//   task.on('state_changed', snapshot => {
//       let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//       this.setState({
//           uploadValue: percentage
//       })
//   }, error => {
//       console.log(error.message)
//   }, () => {
//       // this.setState({
//       //     uploadValue: 100,
//       //     picture: task.snapshot.downloadURL
//       // });
//       storageRef.getDownloadURL().then(url => {
//           this.setState({
//               Logo: url //La url de la imagen subida
//           });
//           console.log("Logo "+this.state.Logo)
//       })

//   });

// }

// handleUploadFirma = (event) => {
//   let file = event.target.files[0]
//   const storageRef = firebase.storage().ref(`/Firmas/${this.state.Ruc.concat(".Firma")}`);
//   const task = storageRef.put(file);

//   task.on('state_changed', snapshot => {
//       let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//       this.setState({
//           uploadValueFirma: percentage
//       })
//   }, error => {
//       console.log(error.message)
//   }, () => {
//       // this.setState({
//       //     uploadValue: 100,
//       //     picture: task.snapshot.downloadURL
//       // });
//       storageRef.getDownloadURL().then(url => {
//           this.setState({
//               Firma: url //La url de la imagen subida
//           });
//           console.log("Firma "+this.state.Firma)
//       })

//   });

// }

    getMoneda = async () => {
        // this.setState({loading:true, error:null})
        try {
            const response = await fetch('/api/empresas/monedas/');
            const data = await response.json();
            // console.log(data)
            this.setState({
                loading: false,
                dataM: data
            })
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getEmpresaById = async (id) => {
        fetch(`/api/empresas/edit/${id}`)
            .then(res => res.json())
            .then(async data => {
                // console.log("Empresa: ",data)
                await this.getUbigeo(data[0].IdUbigeo)
                await this.getMoneda()
                await this.getPlanesSunat(data[0].IdPlanSunat)
                await this.retenedorPercepcion(data[0].Retenedor, data[0].Percepcion, data[0].EsRuss); //Se evalua tmb si es russ o no
                this.setState({
                    IdEmpresa: id,
                    Ruc: data[0].Ruc,
                    RazonSocial: data[0].RazonSocial,
                    Direccion: data[0].Direccion,
                    Telefono: data[0].Telefono,
                    TelefonoDos: data[0].TelefonoDos,
                    TelefonoTres: data[0].TelefonoTres,
                    NombreComercial: data[0].NombreComercial,
                    // Logo:data[0].Logo,
                    PaginaWeb: data[0].PaginaWeb,
                    IdMoneda: data[0].IdMoneda,
                    CorreoElectronico: data[0].CorreoElectronico,
                    CorreoElectronicoDos: data[0].CorreoElectronicoDos,
                    CorreoElectronicoTres: data[0].CorreoElectronicoTres,
                    Sector: data[0].Sector,
                    FechaDeConstitucion: this.fecha(data[0].FechaDeConstitucion),
                    RepresentanteLegal: data[0].RepresentanteLegal,
                    Estado: data[0].Estado,
                    // Firma: data[0].Firma,
                    Retenedor: data[0].Retenedor,
                    Percepcion: data[0].Percepcion,
                    IdPlanSunat: data[0].IdPlanSunat,
                    ContribuyenteCondicion: data[0].ContribuyenteCondicion,
                    ContribuyenteEstado: data[0].ContribuyenteEstado,
                    FechaDeInscripcion: data[0].FechaDeInscripcion,
                    SistemaContabilidad: data[0].SistemaContabilidad,
                    SistemaEmision: data[0].SistemaEmision,
                    UsuarioSolPrincipal: data[0].UsuarioSolPrincipal,
                    ClaveSolPrincipal: data[0].ClaveSolPrincipal

                })
                // await this.getLogos();
                // await this.getFirmas();
            });
    }

    retenedorPercepcion = (Retenedor, Percepcion, EsRuss) => {
        if (Retenedor === "Activo" && Percepcion === "Activo") {
            this.setState({
                checkRetenedor: true,
                checkPercepcion: true

            })
        } else if (Retenedor === "Activo" && Percepcion === "Inactivo") {
            this.setState({
                checkRetenedor: true,
                checkPercepcion: false
            })
        } else if (Retenedor === "Inactivo" && Percepcion === "Activo") {
            this.setState({
                checkRetenedor: false,
                checkPercepcion: true
            })
        } else if (Retenedor === "Inactivo" && Percepcion === "Inactivo") {
            this.setState({
                checkRetenedor: false,
                checkPercepcion: false
            })
        }
        if (EsRuss === "Activo") {
            this.setState({
                checkEsRuss: true,
                EsRuss: 'Activo'
            })
        } else {
            this.setState({
                checkEsRuss: false,
                EsRuss: 'Inactivo'
            })
        }
    }

    getUbigeo = async (IdUbigeo) => {
        // this.setState({loading:true, error:null})
        try {
            const response = await fetch('/api/ubigeos/ubigRegion');
            const data = await response.json();

            this.setState({
                loading: false,
                data: data,
                valueRegion: data.ubigeos[IdUbigeo - 1].CodRegion,
                valueProvincia: data.ubigeos[IdUbigeo - 1].CodProvincia,
                valueDistrito: data.ubigeos[IdUbigeo - 1].IdUbigeo,
                IdUbigeo: data.ubigeos[IdUbigeo - 1].IdUbigeo

            })

            await Promise.all([
                this.getUbigeoById(),
            ])
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }
    getPlanesSunat = async (IdPlanSunat) => {
        // this.setState({loading:true, error:null})
        try {
            const response = await fetch('/api/planesSunat/planes');
            const data = await response.json();
            this.setState({
                loading: false,
                dataP: data,
                planSun: data.planesSunat[IdPlanSunat - 1]

            })
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getUbigeoById = async () => { //Filtros

        this.state.ubigeoReg = this.state.data.ubigeos.filter((ubigeoR) => {
            return ubigeoR.CodProvincia === '00' && ubigeoR.CodDistrito === '00'
        })

        this.state.ubigeoProv = this.state.data.ubigeos.filter((ubigeoP) => {
            return ubigeoP.CodRegion === this.state.valueRegion && ubigeoP.CodProvincia !== '00' && ubigeoP.CodDistrito === '00'
        })

        this.state.ubigeoDis = this.state.data.ubigeos.filter((ubigeoD) => {
            return ubigeoD.CodRegion === this.state.valueRegion && ubigeoD.CodProvincia === this.state.valueProvincia && (ubigeoD.Ubigeo !== null)
        })
        this.setState({
            valueProvincia: this.state.valueProvincia,
            valueDistrito: this.state.valueDistrito,
            IdUbigeo: this.state.IdUbigeo
        })

    }
    traerUbigeoPorProvincia = async (idRegion) => {
        this.state.ubigeoProv = this.state.data.ubigeos.filter((ubigeoP) => {
            return ubigeoP.CodRegion === idRegion && ubigeoP.CodProvincia !== '00' && ubigeoP.CodDistrito === '00'
        })
        this.setState({valueProvincia: this.state.ubigeoProv[0].CodProvincia})
    }

    traerUbigeoPorDistrito = async (idRegion, idProvincia) => {
        this.state.ubigeoDis = this.state.data.ubigeos.filter((ubigeoD) => {
            return ubigeoD.CodRegion === idRegion && ubigeoD.CodProvincia === idProvincia && (ubigeoD.Ubigeo !== null)
        })
        this.setState({valueDistrito: this.state.ubigeoDis[0].CodDistrito, IdUbigeo: this.state.ubigeoDis[0].IdUbigeo})

    }

    RetenedorChange = () => {
        if (this.state.Retenedor === "Activo") {
            this.setState({Retenedor: "Inactivo", checkRetenedor: false})
        } else {
            this.setState({Retenedor: "Activo", checkRetenedor: true})
        }
        // console.log("retenedorChange: ",this.state.Retenedor)
    }

    PercepcionChange = () => {
        if (this.state.Percepcion === "Activo") {
            this.setState({Percepcion: "Inactivo", checkPercepcion: false})
        } else {
            this.setState({Percepcion: "Activo", checkPercepcion: true})
        }
    }

    EsRussChange = () => {
        if (this.state.EsRuss === "Activo") {
            this.setState({EsRuss: "Inactivo", checkEsRuss: false})
        } else {
            this.setState({EsRuss: "Activo", checkEsRuss: true})
        }
        // console.log("EsRussChange: ",this.state.EsRuss)
    }
    MonedaChange = (event) => {
        this.setState({IdMoneda: event.target.value})
        // console.log(event.target.value);
    }

    PlanChange = (event) => {
        this.setState({IdPlanSunat: event.target.value})
    }

    RegionChange = (event) => {
        this.setState({valueRegion: event.target.value})
        this.traerUbigeoPorProvincia(event.target.value);
        this.traerUbigeoPorDistrito(event.target.value, this.state.ubigeoProv[0].CodProvincia);
    }
    ProvinciaChange = (event) => {
        this.setState({valueProvincia: event.target.value})
        this.traerUbigeoPorDistrito(this.state.valueRegion, event.target.value);
    }
    DistritoChange = (event) => {
        this.setState({IdUbigeo: event.target.value, valueDistrito: event.target.value})
    }

    editEmpresa = async (e) => {

        let valido = true
        Object.values(this.state.Errores).forEach(val => {
            val.length > 1 && (valido = false)
        })

        if (valido) {
            apiFetch(`/api/empresas/${this.state.IdEmpresa}`, ["_estadoEditarEmpresa"], {
                method: 'PUT',
                body: JSON.stringify({
                    Ruc: this.state.Ruc,
                    RazonSocial: this.state.RazonSocial,
                    Direccion: this.state.Direccion,
                    Telefono: this.state.Telefono,
                    TelefonoDos: this.state.TelefonoDos,
                    TelefonoTres: this.state.TelefonoTres,
                    IdUbigeo: this.state.IdUbigeo,
                    NombreComercial: this.state.NombreComercial,
                    PaginaWeb: this.state.PaginaWeb,
                    IdMoneda: this.state.IdMoneda,
                    CorreoElectronico: this.state.CorreoElectronico,
                    CorreoElectronicoDos: this.state.CorreoElectronicoDos,
                    CorreoElectronicoTres: this.state.CorreoElectronicoTres,
                    Sector: this.state.Sector,
                    FechaDeConstitucion: this.state.FechaDeConstitucion,
                    FechaDeInscripcion: this.state.FechaDeInscripcion,
                    RepresentanteLegal: this.state.RepresentanteLegal,
                    Retenedor: this.state.Retenedor,
                    Percepcion: this.state.Percepcion,
                    EsRuss: this.state.EsRuss,
                    IdPlanSunat: this.state.IdPlanSunat,
                    ContribuyenteCondicion: this.state.ContribuyenteCondicion,
                    ContribuyenteEstado: this.state.ContribuyenteEstado,
                    SistemaContabilidad: this.state.SistemaContabilidad,
                    SistemaEmision: this.state.SistemaEmision,
                    UsuarioSolPrincipal: this.state.UsuarioSolPrincipal,
                    ClaveSolPrincipal: this.state.ClaveSolPrincipal
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(async ({data_tables, error}) => { //data tables arreglo de tablas
                    // console.log("error: ",error)
                    const [table_data] = data_tables;
                    if (!error) {
                        Notify("Se editó correctamente.", "success", 1500)
                        this.props.history.push("/empresas/");
                    } else {
                        Notify("Hubo un error.", "warning", 1500)
                    }
                })
                .catch(err => console.log(err))
        } else {
            notificationError()
        }
        e.preventDefault()
    }

    handleChange = (event) => {
        const {name, value} = event.target
        let Errores = this.state.Errores;
        validarInputsEmpresa(Errores, name, value);

        this.setState({
            [name]: value
        })
    }
    handleSubmit = (e) => {
        alert('Tu país es: ' + this.state.value);
        e.preventDefault();
    }
    fecha = (FechaDeConstitucion) => {
        return moment(FechaDeConstitucion).format('YYYY-MM-DD')
    }
    mostrarTelefonosOpcionales = (e) => {
        if (this.state.mostrarTelefonosOpcionales == true) {
            this.setState({
                mostrarTelefonosOpcionales: false
            });
        } else if (this.state.mostrarTelefonosOpcionales == false) {
            this.setState({
                mostrarTelefonosOpcionales: true
            });
        }
    }

    mostrarCorreosOpcionales = (e) => {
        if (this.state.mostrarCorreosOpcionales == true) {
            this.setState({
                mostrarCorreosOpcionales: false
            });
        } else if (this.state.mostrarCorreosOpcionales == false) {
            this.setState({
                mostrarCorreosOpcionales: true
            });
        }

    }

    handleChangeSistemaContabilidad = (e) => {
        this.setState({
            SistemaContabilidad: e.target.value
        })
        // console.log(e.target.value)
    }

    handleChangeSistemaEmision = (e) => {
        this.setState({
            SistemaEmision: e.target.value
        })
        // console.log(e.target.value)
    }

    MonedaChecked = (IdMoneda) => {
        if (this.state.IdMoneda == IdMoneda)
            return true
        else {
            return false
        }
    }

    ContabilidadChecked = (contabilidad) => {
        if (this.state.SistemaContabilidad == contabilidad)
            return true
        else {
            return false
        }
    }

    EmisionChecked = (emision) => {
        if (this.state.SistemaEmision == emision)
            return true
        else {
            return false
        }
    }

    render = () => {
        // debugger
        if (this.state.loading === false && this.state.Retenedor === null) {
            return <PageLoading/>;
        }
        return (
            <React.Fragment>
                <div className="ml-4 mt-4">
                    <BotonCancelar to="/empresas"/>
                </div>
                <form onSubmit={this.editEmpresa} className="d-flex justify-content-center">
                    <div className="almacenForm">
                        <div className="AlmacenBoton">
                            <BotonGuardarLetras type="submit"/>
                        </div>
                        <EmpresasAddFormDatosGenerales
                            onSubmit={this.editEmpresa}
                            state={this.state}
                            handleChange={this.handleChange}
                            handleSubmitRUC={this.handleSubmitRUC}
                            PlanChange={this.PlanChange}
                            RegionChange={this.RegionChange}
                            ProvinciaChange={this.ProvinciaChange}
                            DistritoChange={this.DistritoChange}
                            getPlanesSunat={this.getPlanesSunat}
                            getUbigeo={this.getUbigeo}
                            RetenedorChange={this.RetenedorChange}
                            PercepcionChange={this.PercepcionChange}
                            EsRussChange={this.EsRussChange}
                            MonedaChange={this.MonedaChange}
                            getMoneda={this.getMoneda}
                            mostrarTelefonosOpcionales={this.mostrarTelefonosOpcionales}
                            mostrarCorreosOpcionales={this.mostrarCorreosOpcionales}
                            handleChangeSistemaContabilidad={this.handleChangeSistemaContabilidad}
                            handleChangeSistemaEmision={this.handleChangeSistemaEmision}
                            MonedaChecked={this.MonedaChecked}
                            ContabilidadChecked={this.ContabilidadChecked}
                            EmisionChecked={this.EmisionChecked}
                        />

                        <div className="AlmacenBoton">
                            <BotonGuardarLetras type="submit"/>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}


export default EmpresasEdit;

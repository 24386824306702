import React from 'react';
import SelectSearch from "react-select-search";
import "../../pages/styles/SelectSearch.css";

class TransportistasForm extends React.Component{

    render(){
    return(
        <React.Fragment>
            {/* Empleado y Categoria */}
                <div className="almacenDiv">
                    <b className="ml-4"></b> 
                    <div className="input__Almacen col-9">
                        <SelectSearch
                            name="IdEmpleado"
                            value={this.props.state.IdEmpleado}
                            onChange={(e)=>this.props.handleEmpleadoChange(e)}
                            options={this.props.ListaEmpleados(this.props.state.dataEmpleados)}
                            className="select-search-box"
                            placeholder="Seleccionar Empleado"
                            required
                        />
                    </div>
                </div>
            {/* Numero Licencia y Estado */}
            <div className="almacenDiv">
                <div className="input__Almacen">
                    <b className="ml-4" >Numero Licencia</b> 
                    <input required
                        name="NumeroLicencia" 
                        className="input__linea col-3" 
                        type="text"
                        value={this.props.state.NumeroLicencia}
                        onChange={this.props.handleChangeUnicos}
                    />
                    <b className="ml-4" >Categoria</b> 
                    <select id="Categoria" required className="input__linea col-3" name="Categoria" onChange={this.props.handleChangeUnicos} value={this.props.state.Categoria} aria-haspopup="true" aria-expanded="false" >
                        <option value="A-I" defaultValue>A-I</option>
                        <option value="A-IIa" >A-IIa</option>
                        <option value="A-IIb">A-IIb</option>
                        <option value="A-IIIa">A-IIIa</option>
                        <option value="A-IIIb">A-IIIb</option>
                        <option value="A-IIIc">A-IIIc</option>
                        <option value="B-I">B-I</option>
                        <option value="B-IIa">B-IIa</option>
                        <option value="B-IIb">B-IIb</option>
                        <option value="B-IIc">B-IIc</option>
                    </select>
                </div>
            </div>          
        </React.Fragment>
    )
}

}

export default TransportistasForm;
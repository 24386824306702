import React from 'react';
import '../styles/Empleados.css';
import {notificationDarDeAlta,notificationDarDeBaja,notificationSuspender} from './EmpleadosNotify'
import { confirmAlert } from 'react-confirm-alert'; 
import { BotonLetrasAmarillo, BotonLetrasRojo, BotonLetrasVerdeOscuro } from '../Plantillas/Botones';

//cambios de estado
function SuspenderEmpleado(IdEmpleado,Nombres,Apellidos,history,volver){
    confirmAlert({
        title: `¿Suspender a: ${Nombres} ${Apellidos}?`,
        message: '(Luego se podrá darle de alta nuevamente o dar de baja definitivamente)',
        buttons: [
          {
            label: 'Sí',
            onClick:()=>{
                notificationSuspender()
                fetch(`/api/empleados/SuspenderEmpleado/${IdEmpleado}`)
                history.push(`${volver}`)
            }
          },
          {
            label: 'No'
          }
        ]
      })
}

function DarBajaEmpleado(IdEmpleado,Nombres,Apellidos,history,volver){
    confirmAlert({
        title: `¿Dar de baja definitivamente a: ${Nombres} ${Apellidos}?`,
        buttons: [
          {
            label: 'Sí',
            onClick:()=>{
                notificationDarDeBaja()
                fetch(`/api/empleados/BajaEmpleado/${IdEmpleado}`)
                history.push(`${volver}`)
            }
          },
          {
            label: 'No'
          }
        ]
      })
}

function DarAltaEmpleado(IdEmpleado,Nombres,Apellidos,history,volver){
    confirmAlert({
        title: `¿Dar de Alta a: ${Nombres} ${Apellidos}?`,
        buttons: [
          {
            label: 'Sí',
            onClick:()=>{
                notificationDarDeAlta()
                fetch(`/api/empleados/AltaEmpleado/${IdEmpleado}`)
                history.push(`${volver}`)
            }
          },
          {
            label: 'No'
          }
        ]
      })
}

//botones de cambio de estado
export function CambiarEstadoEmpleado(Estado,IdEmpleado,Nombres,Apellidos,history,volver) {
    switch(Estado){
        case 'suspendido': return(
            <React.Fragment>

                <BotonLetrasVerdeOscuro type="button" onClick={()=>
                    DarAltaEmpleado(IdEmpleado,
                        Nombres,Apellidos,history,volver)}
                >Dar de Alta
                </BotonLetrasVerdeOscuro>

                <BotonLetrasRojo type="button" onClick={()=>
                    DarBajaEmpleado(IdEmpleado,
                    Nombres,Apellidos,history,volver)}
                >Dar de Baja
                </BotonLetrasRojo>
 
            </React.Fragment>
        )
        case 'inactivo':return(
            <React.Fragment>

                <BotonLetrasVerdeOscuro type="button" onClick={()=>
                    DarAltaEmpleado(IdEmpleado,
                        Nombres,Apellidos,history,volver)}
                >Dar de Alta
                </BotonLetrasVerdeOscuro>

            </React.Fragment>
        )
        default:return(
            <React.Fragment>

                <BotonLetrasAmarillo
                  className="ml-4 mr-2"
                  type="button"
                  onClick={()=>
                  SuspenderEmpleado(IdEmpleado,
                      Nombres,Apellidos,history,volver)}
                >Suspender
                </BotonLetrasAmarillo>

                <BotonLetrasRojo 
                  type="button"
                  onClick={()=>
                    DarBajaEmpleado(IdEmpleado,
                    Nombres,Apellidos,history,volver)}
                >Dar de Baja
                </BotonLetrasRojo>

            </React.Fragment>
        )
    }
}
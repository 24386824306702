import React from "react";
import Moment from "react-moment";
import "../styles/Empleados.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import { BotonEditar } from "../Plantillas/Botones";

class EmpleadosList extends React.Component {
  render() {
    return (
      <React.Fragment>
        <ReactTable
          data={this.props.filtrarEmpleados}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              columns: [
                {
                  Header: "Editar",
                  filterable: false,
                  headerClassName: "",
                  width: 80,
                  Cell: original => {
                    return (
                      <BotonEditar
                        to={`${this.props.link}` + original.original.IdEmpleado}
                      />
                    );
                  }
                },
                {
                  Header: "Nombres y Apellidos",
                  id: "Nombres",
                  // accessor: "Nombre",
                  headerClassName: "",
                  width: 400,
                  accessor: d => d.Nombres.concat(" ", d.Apellidos),
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["Nombres"] }),
                  filterAll: true
                },
                {
                  Header: "DNI",
                  id: "DNI",
                  headerClassName: "",
                  width: 100,
                  accessor: d => d.DNI,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["DNI"] }),
                  filterAll: true
                },
                {
                  Header: "Celular",
                  id: "Celular",
                  headerClassName: "",
                  width: 160,
                  accessor: d => d.Celular,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["Celular"] }),
                  filterAll: true
                },
                {
                  Header: "Correo",
                  id: "Correo",
                  headerClassName: "",
                  width: 300,
                  accessor: d => d.Correo,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["Correo"] }),
                  filterAll: true
                },
                // {
                //   Header: "Fecha de Ingreso",
                //   id: "FechaIngreso",
                //   headerClassName: "",
                //   width: 100,
                //   Cell: original => {
                //     return (
                //       <Moment format="YYYY-MM-DD">
                //         {original.original.FechaIngreso}
                //       </Moment>
                //     );
                //   },
                //   accessor: d => d.FechaIngreso,
                //   filterMethod: (filter, rows) =>
                //     matchSorter(rows, filter.value, { keys: ["FechaIngreso"] }),
                //   filterAll: true
                // },
                
              ]
            }
          ]}
          defaultPageSize={5}
          //pageSize={10}
          className="-striped -highlight"
          // Text de paginación
          previousText="Anterior"
          nextText="Siguiente"
          loadingText="Cargando..."
          noDataText="No se encontraron registros"
          pageText="Página"
          ofText="de"
          rowsText="filas"
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        />
      </React.Fragment>
    );
  }
}

export default EmpleadosList;

import {NombresRegex,
  DNIRegex,
  ApellidosRegex,
  TelefonoRegex,
  CelularRegex,
  CorreoRegex} from "./EmpleadosRegexValidator"
import moment from "moment";

const hoy = moment(new Date()).format("YYYY-MM-DD")

export function validarInputsEmpleado(Errores,name,value) {

  //console.log(Errores,name,value)
  switch (name) {
    case 'DNI':
      Errores.DNI=DNIRegex.test(value)
      ? ''
      : '*El Dni debe tener 8 números'
      break;
    case 'Telefono':
      Errores.Telefono=TelefonoRegex.test(value)
      ? ''
      : '*El número de Teléfono sólo debe contener números'
      break;
    case 'Celular':
      Errores.Celular=CelularRegex.test(value)
      ? ''
      : '*Formato inválido'
      break;
    case 'Correo':
      Errores.Correo=CorreoRegex.test(value)
      ? ''
      : '*Formato de correo inválido'
      break;
    case 'FechaIngreso':
        Errores.FechaIngreso= value<=hoy
        ? ''
        : '*El día no puede pasar la fecha actual'
        break; 
    default:
      break;
  }
}

import _ from 'lodash';
import Noty from "noty";

export function isNumber(v) {
    return !_.isNaN(Number(v))
}

export function isValidDate(str) {
    let parsed = new Date(str);
    return parsed.toString() !== "Invalid Date";
}


export function showErrorMsg(msg, time = 2000) {
    new Noty({
        type: "warning",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: msg,
        timeout: time
    }).show();
}

export function isNumberValidator(args) {
    const [str, attr] = args;
    if (isNumber(str))
        return [true, '']
    return [false, `El campo ${attr} no es válido`]
}

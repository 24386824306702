import React from "react";
import moment from "moment";
import {decimalAdjust} from "../../Global";

export default class TablaPagosComponent extends React.Component {

    render() {
        const {pagos, onProcesar, onEliminar, readOnly, onChangeFields, isLoading, tiposPago} = this.props;

        return (
            <table className="table table-hover">
                <thead>
                <tr>
                    <th scope="col">Fecha</th>
                    <th scope="col">Cuota</th>
                    <th scope="col">Monto cuota</th>
                    <th scope="col">Total pagado</th>
                    <th scope="col">Método de pago</th>
                    <th scope="col">Núm. Movimiento</th>
                    <th scope="col">Total pendiente</th>
                    <th scope="col">Opciones</th>
                </tr>
                </thead>
                <tbody>


                {pagos.map((p, i) => {
                    if (p.Estado === "PENDIENTE" && !readOnly)
                        return (
                            <tr key={`pago-${p.IdPago}`}>
                                <td>
                                    {moment(p.FechaPago).format('DD-MM-YYYY')}
                                </td>
                                <td>{i + 1}</td>
                                <td>
                                    {decimalAdjust('floor', p.Monto, -2)}
                                </td>
                                <td>
                                    <input name="TotalPagado"
                                           min={0}
                                           onChange={(e) => onChangeFields(e, p)}
                                           type="number"
                                           value={p.TotalPagado}/>
                                </td>
                                <td>
                                    <select name="TipoPago"
                                            onChange={(e) => onChangeFields(e, p)}>
                                        {tiposPago.map((t, i) => <option key={`opt-pago-${i}`}
                                                                         value={t}>{t}</option>)}
                                    </select>
                                </td>
                                <td>
                                    <input name="NumeroMovimiento" type="text"
                                           onChange={(e) => onChangeFields(e, p)}/>
                                </td>
                                <td>
                                    {decimalAdjust('floor', p.capital, -2)}
                                </td>
                                <td>
                                    <button className="btn btn-link" disabled={isLoading}
                                            onClick={(e) => onProcesar(e, p)}>
                                        Procesar
                                    </button>
                                    {p.wasFromVenta ? null : (
                                        <button className="btn btn-link" disabled={isLoading}
                                                onClick={(e) => onEliminar(e, p)}>
                                            Eliminar
                                        </button>)}
                                </td>
                            </tr>
                        );
                    return (
                        <tr key={`pago-${p.IdPago}`}>
                            <td>{moment(p.FechaPago).format('DD/MM/YYYY')}</td>
                            <td>{i + 1}</td>
                            <td>{decimalAdjust('floor', p.Monto, -2)}</td>
                            <td>{p.TotalPagado}</td>
                            <td>{p.TipoPago}</td>
                            <td>{p.NumeroMovimiento === "null" || p.NumeroMovimiento === null ? "-" : p.NumeroMovimiento}</td>
                            <td>{decimalAdjust('floor', p.capital, -2)}</td>
                            <td>-</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }
}

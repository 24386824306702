import React from "react";
import AsyncSelect from "react-select/async/dist/react-select.esm";

export default class TipoMovPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
        this.searchTipoMov = this.searchTipoMov.bind(this);
        this.onChangeTipoMov = this.onChangeTipoMov.bind(this);
    }

    searchTipoMov(query) {
        return new Promise((resolve, reject) => {
            this.setState({isLoading: true})
            fetch('/api/movimientosalmacen/tipomovimiento/list')
                .then(res => res.json())
                .then(data => {
                    resolve(data[1].map(x => {
                        return {...x, label: x.DescripcionCorta, value: x.IdTipoMovimientoAlmacen}
                    }))
                }).catch(reject)
                .finally(() => this.setState({isLoading: false}))
        })
    }

    onChangeTipoMov(e) {
        let ids = e ? e.map(x => x.IdTipoMovimientoAlmacen) : [];
        this.props.onChangeTipoMov(ids);
    }

    render() {
        return (
            <div className="form-group">
                <label>Tipo de movimiento</label>
                <AsyncSelect
                    defaultOptions={true}
                    onChange={this.onChangeTipoMov}
                    loadOptions={this.searchTipoMov}
                    isClearable={true}
                    isLoading={this.state.isLoading}
                    placeholder={"Seleccione tipo de movimiento"}
                    isMulti
                />
            </div>
        );
    }
}

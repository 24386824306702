import React from "react";
import {  BotonRemovePequeño, BotonDown, BotonAddPequeño, BotonMenosPequeño } from "../../componentes/Plantillas/Botones";

class ProcesarValesListCarrito extends React.Component {
  render() {
    // console.log(this.props)
    return (
      <React.Fragment>
        <div id="tableDetProdPreventa" className="preventa__carrito">
          <table className="table table-hover" /*style={{border:"2px solid #C2C2C2"}}*/>
            <thead>
              <tr>
              <th className="tdDetallePreventa" style={{fontSize: '20px'}}>
                <div className='inputDet'>CANTIDAD</div>
                <div className='inputDet'>/ PRECIO</div>
                <div className='inputDet'>/ TOTAL</div>
                <div className='inputDet'></div>
              </th>
              </tr>
            </thead>
            <tbody>
              {this.props.dataProductosMostrar.productosVale.map(DetItem => {
                return (
                  <tr key={
                      ""+DetItem.IdStock+DetItem.IdTipoStock+DetItem.IdProducto+
                      DetItem.IdPresentacion+DetItem.IdPrecioPlantilla+DetItem.IdPrecio+
                      DetItem.IdAlmacen} 
                      // bgcolor="#CEF6CE"
                      className="trDetallePreventa"
                  >
                    <td style={{ padding: 0 }}>
                      <div >
                        <div style={{ fontSize: "14px" }} className="itemNombre">
                          <div>
                            {DetItem.NombreProducto} {DetItem.DescripcionUnidad} {DetItem.NombrePresentacion}
                          </div>
                            <div className="tdDivDetallePreventaPrecios">
                                  Precio Costo:
                                  {DetItem.PrecioCosto
                                  .toLocaleString("en-GB", {minimumFractionDigits: 2,maximumFractionDigits: 2})
                                  }
                            </div>
                          </div>
                        </div>
                      {/* <div className="tdDetallePreventa">
                        <div className="inputDet">
                            <button
                              type="button"
                              className="btn btn-outline-danger mr-2"
                              title="Disminuir en 1"
                              onClick={() =>
                                this.props.RestarCantidadDet(DetItem)
                              }
                            >
                              <i className="fa fa-minus" title="Disminuir en 1"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-success mr-2"
                              title="Aumentar en 1"
                              onClick={() =>
                                this.props.AgregarCantidadDet(DetItem)
                              }
                            >
                              <i className="fa fa-plus" title="Aumentar en 1"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              title="Quitar item"
                              onClick={() =>
                                this.props.RemoveProductoInList(DetItem)
                              }
                            >
                              <i className="fa fa-trash-alt" title="Quitar de Lista"></i>
                            </button>
                        </div>
                      </div> */}
                      <div className="tdDetallePreventa">
                        <div className="inputDet">
                              <input
                                type="number"
                                style={{ backgroundColor: "rgb(53,96,90,0.18)"}}
                                step="any"
                                min="0"
                                max="999999.999999"
                                name="Cantidad"
                                className="inputCantidadDet"
                                value={DetItem.Cantidad}
                                onChange={e => this.props.InputCantidadDetalleChange(e, DetItem)}
                                
                                // onFocus={() => this.props.setActiveInput(DetItem, "Cantidad")} //cuando esta focus
                              />
                              </div>
                              
                              <div className="inputDet">
                              <input
                                type="number"
                                style={{ backgroundColor: "#01a59c", color: "white" }}
                                step="any"
                                min="0"
                                value={DetItem.PrecioVenta
                                }
                                onChange={e => this.props.InputPrecioVentaChange(e, DetItem)}
                                name="PrecioVenta"
                                className="inputPrecioVentaDet"
                                // onFocus={()=> this.props.setActiveInput(DetItem, "PrecioVenta")}
                                onBlur={()=>this.props.FindPrecioCosto(DetItem)}
                              />
                              </div>
                              
                              <div className="itemTotal">
                                {DetItem.Simbolo}
                                {(DetItem.Total)
                                .toLocaleString('en-GB', { minimumFractionDigits:2, maximumFractionDigits: 2 })}
                                {/* <button
                                  className="btn btn-outline-info btn-sm ml-1"
                                  onClick={()=>this.props.handleOpenModalDescuento(DetItem)}
                                  title="Descuento y Gratuito"
                                >
                                  <i
                                  className="fa fa-arrow-down"
                                  title="Descuento y Gratuito"
                                  ></i>  
                                </button> */}
                              </div>
                              <div>
                                  <div className="tdDivDetallePreventa" >
                                    <BotonMenosPequeño
                                      type="button"
                                      title="Disminuir en 1"
                                      onClick={() =>
                                        this.props.RestarCantidadDet(DetItem)
                                      }
                                    />

                                    <BotonAddPequeño
                                      type="button"
                                      title="Aumentar en 1"
                                      onClick={(e) =>
                                        {e.stopPropagation()
                                          this.props.AgregarCantidadDet(DetItem)}
                                      }
                                    />

                                    <BotonRemovePequeño
                                      type="button"
                                      title="Quitar item"
                                      onClick={() =>
                                        this.props.RemoveProductoInList(DetItem)
                                      }
                                    />

                                    <BotonDown
                                      onClick={()=>this.props.handleOpenModalDescuento(DetItem)}
                                      title="Descuento"
                                    />

                                    </div>
                                  </div>
                      </div>
                    </td>
                    {/*<td style={{ padding: 0 }}>
                      <td style={{ padding: 0 }}>
                        {DetItem.Simbolo}
                      </td>
                      <td className="col-11" style={{ padding: 0 }}>
                         <div className="totalDetItem mr-1" style={{ textAlign: "end" }}>
                          {(DetItem.Total).toLocaleString('en-GB', { minimumFractionDigits:2, maximumFractionDigits: 2 })}
                        </div> 
                      </td>
                      
                    </td>*/}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default ProcesarValesListCarrito;
import React from "react";
import SelectSearch from "react-select-search";
import "./styles/SelectSearch.css";
import VerDetallesPresentacion from "../componentes/VerDetallesPresentacion";
import Modal from "../componentes/Modal";
import ReactExport from "react-data-export";
import {BotonLetras} from "../componentes/Plantillas/Botones";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function listPresentaciones(data, onClick, onDelete) {
    return data.map(presentacion => {
        return (
            <tr key={presentacion.IdPresentacion}>
                <td>{presentacion.NombrePres}</td>
                <td>{presentacion.Descripcion}</td>
                <td>{presentacion.DescripcionPresentacion}</td>
                <td>
                    <button
                        type="button"
                        className="boton__outline-pequeno--y  mr-3 pr-2"
                        onClick={onClick(presentacion.IdPresentacion)}
                    >
                        <i className="fa fa-edit"></i>
                    </button>
                    <button
                        type="button"
                        className="boton__outline-pequeno--r"
                        onClick={onDelete(presentacion.IdPresentacion)}
                    >
                        <i class="fas fa-trash-alt"></i>
                    </button>
                </td>
            </tr>
        );
    });
}

function GetNombreProducto(data) {
    return data.slice(0, 1).map(presentacion => {
        return presentacion.Codigo + " - " + presentacion.NombreProd;
    });
}

function listUnidades(unidades) {
    return unidades.map(unidad => {
        return {name: unidad.Descripcion, value: unidad.IdUnidad};
    });
}

function listLotes(lotes) {
    return lotes.map(lote => {
        return {
            name: lote.Codigo.concat("-", lote.Descripcion),
            value: lote.IdLote
        };
    });
}

const PresentacionesUI = props => {
    const data = props.data;
    return (
        <>
            <form onSubmit={props.envionewpresentacion}>
                <div className="container">
                    <div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6">
                                    <h4>{GetNombreProducto(data)}</h4>
                                </div>
                                {/* <div className="col-4" align="right">
                  <p>

                  </p>
                </div> */}
                                <div className="col-6" align="right">
                                    <button
                                        className="boton__verdeClaro mr-2"
                                        data-toggle="collapse"
                                        href="#multiCollapseExample1"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="multiCollapseExample1"
                                        title="Añadir Nueva Presentación"
                                        type="button"
                                    >
                                        <i class="fas fa-plus"></i>
                                    </button>
                                    <button
                                        type="button"
                                        className="boton__Rojo mr-2"
                                        title="Descargar PDF"
                                        onClick={props.pdfViewer(GetNombreProducto(data))}
                                    >
                                        {<i class="fas fa-file-pdf"></i>}
                                    </button>
                                    <ExcelFile
                                        element={
                                            <button
                                                type="button"
                                                className="boton__verdeOscuro"
                                                title="Descargar datos en EXCEL">
                                                <i class="fas fa-file-excel"></i>
                                            </button>
                                        }
                                        filename="ReportePresentaciones"
                                    >
                                        <ExcelSheet
                                            data={props.data}
                                            name="Presentaciones"
                                        >
                                            <ExcelColumn label="Presentación" value="NombrePres"/>
                                            <ExcelColumn
                                                label="UNIDAD DE MEDIDAS"
                                                value="Descripcion"
                                            />
                                        </ExcelSheet>
                                    </ExcelFile>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div
                                        className="collapse multi-collapse"
                                        id="multiCollapseExample1"
                                    >
                                        <div className="card-body">
                                            <div className="container ml-5 mr-5">
                                                <label>Nueva Presentación</label>
                                                <div className="form-group mr-5">
                                                    <input
                                                        name="Nombre"
                                                        autoFocus
                                                        value={props.Nombre}
                                                        onChange={props.escribirnombre}
                                                        className="input__linea"
                                                        type="text"
                                                        required
                                                        placeholder="Escribe el nombre de la Presentación"
                                                        aria-describedby="basic-addon3"
                                                    />
                                                </div>
                                                <div className="form-group mr-5">
                                                    <label>Código de Barras</label>
                                                    <input
                                                        name="CodigoBarras"
                                                        autoFocus
                                                        value={props.CodigoBarras}
                                                        onChange={props.escribirCodigoBarras}
                                                        className="input__linea"
                                                        type="text"
                                                        required
                                                        placeholder="Escribe el Codigo de Barras de la Presentación"
                                                        aria-describedby="basic-addon3"
                                                    />
                                                </div>
                                                <div className="form-group mr-5">
                                                    <label>Descripción</label>
                                                    <textarea
                                                        name="Descripcion"
                                                        value={props.Descripcion}
                                                        onChange={props.escribirdescripcion}
                                                        className="input__linea"
                                                        rows="3"
                                                        type="text"
                                                    />
                                                </div>

                                                <div className="row">
                                                    <div className="col-2">
                                                        <label>Lote de Ingreso</label>
                                                    </div>
                                                    <div className="col-10">
                                                        <SelectSearch
                                                            name="IdLote"
                                                            value={props.IdLote}
                                                            onChange={props.seleccionarlote}
                                                            options={listLotes(props.lotes)}
                                                            className="select-search-box"
                                                            placeholder="Selecciona"
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-2">
                                                        <label>Unidad de Medida</label>
                                                    </div>
                                                    <div className="col-10">
                                                        <SelectSearch
                                                            name="IdUnidad"
                                                            value={props.IdUnidad}
                                                            onChange={props.seleccionarunidad}
                                                            options={listUnidades(props.unidades)}
                                                            className="select-search-box"
                                                            placeholder="Selecciona"
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group mr-5">
                                                    <label>Tipo de Cambio</label>
                                                    <input
                                                        name="TipoCambio"
                                                        value={props.TipoCambio}
                                                        onChange={props.escribirtipodecambio}
                                                        className="input__linea"
                                                        placeholder="Ingresa el valor de conversión según la unidad de medida."
                                                        aria-describedby="basic-addon3"
                                                        required
                                                        type="number"
                                                    />
                                                </div>

                                                <div className="card mb-3">
                                                    <div className="card-body" align="right">
                                                        {props.mensajedenewpresentacion ? (
                                                            <div class="alert alert-success" role="alert">
                                                                {props.mensajedenewpresentacion}
                                                            </div>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        <BotonLetras type="submit">
                                                            Guardar
                                                        </BotonLetras>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table id="presentaciones-to-xls" className="table table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Presentación</th>
                            <th scope="col">Unidad de Medida</th>
                            <th scope="col">Descripción</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {listPresentaciones(
                            data,
                            props.handleOnClick,
                            props.handleOnDeletePresentacion
                        )}
                        </tbody>
                    </table>
                </div>
            </form>
            <Modal
                isOpen={props.statusModal}
                onClose={props.onCloseModal}
                handleKeyUp={props.handleKeyUp}
                title={"Presentación y Precios"}
            >
                <VerDetallesPresentacion
                    IdPresentacion={props.IdPresentacion}
                    IdProducto={props.IdProducto}
                    getPresentaciones={props.getPresentaciones()}
                    IdEmpresa={props.IdEmpresa}
                    nombreProducto={GetNombreProducto(data)}
                />
            </Modal>
        </>
    );
};

export default PresentacionesUI;

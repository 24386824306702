import React from "react";
import "react-moment";
import {validarInputsSucursal} from "./SucursalValidator";
import {notificationSuccess, notificationError} from './SucursalNotify'
import {CambiarEstadoSede} from './SucursalEstado';
import SucursalForm from "./SucursalForm";
import PageLoading from '../PageLoading.js';


class EditSucursal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            IdSucursal: "",
            IdEmpresa: "",
            IdEmpleado: "",
            Nombre: "",
            IdUbigeo: "",
            Direccion: "",
            Telefono: "",
            Celular: "",
            CorreoElectronico: "",
            EsPrincipal: "",
            Codigo: "",
            Estado: "",
            Errores: {
                Nombre: "",
                Telefono: "",
                Celular: "",
                CorreoElectronico: ""
            },
            data: {
                ubigeos: []
            },
            dataEmpleados: [],
            dataEmpresas: [],
            empleadosPorEmpresa: [],
            ubigeoReg: [],
            ubigeoProv: [],
            ubigeoDis: [],
            valueRegion: null,
            valueProvincia: null,
            valueDistrito: null,
            ubigeo: null
        };
        this.getSucursalById = this.getSucursalById.bind(this);
        this.getUbigeoById = this.getUbigeoById.bind(this);
        this.getEmpleados = this.getEmpleados.bind(this)
        this.getEmpleadoById = this.getEmpleadoById.bind(this)
        this.getEmpresas = this.getEmpresas.bind(this);
        this.RegionChange = this.RegionChange.bind(this);
        this.ProvinciaChange = this.ProvinciaChange.bind(this);
        this.DistritoChange = this.DistritoChange.bind(this);
        this.traerEmpleadoPorEmpresa = this.traerEmpleadoPorEmpresa.bind(this)
        this.traerUbigeoPorProvincia = this.traerUbigeoPorProvincia.bind(this);
        this.traerUbigeoPorDistrito = this.traerUbigeoPorDistrito.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.editSucursal = this.editSucursal.bind(this);
    }

    componentDidMount() {
        const IdSucursal = this.props.match.params.IdSucursal;
        this.getSucursalById(IdSucursal);
    }

    async getSucursalById(IdSucursal) {
        //console.log(IdSucursal);
        this.setState({loading: true, error: null});
        try {
            await fetch(`/api/sucursales/edit/${IdSucursal}`)
                .then(res => res.json())
                .then(data => {
                    this.getEmpresas()
                    this.getEmpleados(data[0].IdEmpresa)
                    //this.traerEmpleadoPorEmpresa(data[0].IdEmpresa)
                    this.getUbigeo(data[0].IdUbigeo);
                    this.setState({
                        IdSucursal: data[0].IdSucursal,
                        IdEmpresa: data[0].IdEmpresa,
                        IdEmpleado: data[0].IdEmpleado,
                        Nombre: data[0].Nombre,
                        IdUbigeo: data[0].IdUbigeo,
                        Direccion: data[0].Direccion,
                        Telefono: data[0].Telefono,
                        Celular: data[0].Celular,
                        CorreoElectronico: data[0].CorreoElectronico,
                        EsPrincipal: data[0].EsPrincipal,
                        Codigo: data[0].Codigo,
                        Estado: data[0].Estado
                    });
                });
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    getEmpresas = async () => {
        this.setState({loading: true, error: null})
        try {
            const response = await fetch('/api/empresas');
            const data = await response.json();
            const empresas = data.empresas.filter(act => {
                return act.Estado === 'Activo'
            });
            this.setState({
                loading: false,
                dataEmpresas: empresas,

            })
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getEmpleados = async (IdEmpresa) => {
        this.setState({loading: true, error: null})
        try {
            const response = await fetch('/api/empleados/master');
            const data = await response.json();
            const empleados = data.empleados.filter(act => {
                return act.Estado === 'activo'
            });
            this.setState({
                loading: false,
                dataEmpleados: empleados
            })
            await Promise.all([
                this.getEmpleadoById(IdEmpresa),
            ])

        } catch (error) {
            this.setState({loading: false, error: error})
        }

    }

    getEmpleadoById = async (IdEmpresa) => {

        /*this.state.empleadosPorEmpresa = this.state.dataEmpleados.empleados.filter((empleadosP) => {
          return empleadosP.IdEmpresa == IdEmpresa
        })*/
        this.setState({
            empleadosPorEmpresa: this.state.dataEmpleados.filter((empleadosP) => {
                return empleadosP.IdEmpresa === IdEmpresa
            })
        })

    }

    getUbigeo = async IdUbigeo => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch("/api/ubigeos/ubigRegion");
            const data = await response.json();

            this.setState({
                loading: false,
                data: data,
                valueRegion: data.ubigeos[IdUbigeo - 1].CodRegion,
                valueProvincia: data.ubigeos[IdUbigeo - 1].CodProvincia,
                valueDistrito: data.ubigeos[IdUbigeo - 1].IdUbigeo,
                IdUbigeo: data.ubigeos[IdUbigeo - 1].IdUbigeo,
                ubigeo: data.ubigeos[IdUbigeo - 1].Ubigeo
            });

            await Promise.all([this.getUbigeoById()]);
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    };

    getUbigeoById = async () => {
        //Filtros

        this.state.ubigeoReg = this.state.data.ubigeos.filter(ubigeoR => {
            return ubigeoR.CodProvincia === "00" && ubigeoR.CodDistrito === "00";
        });

        this.state.ubigeoProv = this.state.data.ubigeos.filter(ubigeoP => {
            return (
                ubigeoP.CodRegion === this.state.valueRegion &&
                ubigeoP.CodProvincia !== "00" &&
                ubigeoP.CodDistrito === "00"
            );
        });

        this.state.ubigeoDis = this.state.data.ubigeos.filter(ubigeoD => {
            return (
                ubigeoD.CodRegion === this.state.valueRegion &&
                ubigeoD.CodProvincia === this.state.valueProvincia &&
                ubigeoD.Ubigeo !== null
            );
        });
        this.setState({
            valueProvincia: this.state.valueProvincia,
            valueDistrito: this.state.valueDistrito,
            IdUbigeo: this.state.IdUbigeo
        });
    };

    traerUbigeoPorProvincia = async idRegion => {
        this.state.ubigeoProv = this.state.data.ubigeos.filter(ubigeoP => {
            return (
                ubigeoP.CodRegion === idRegion &&
                ubigeoP.CodProvincia !== "00" &&
                ubigeoP.CodDistrito === "00"
            );
        });
        this.setState({valueProvincia: this.state.ubigeoProv[0].CodProvincia});
    };

    traerUbigeoPorDistrito = async (idRegion, idProvincia) => {
        this.state.ubigeoDis = this.state.data.ubigeos.filter(ubigeoD => {
            return (
                ubigeoD.CodRegion === idRegion &&
                ubigeoD.CodProvincia === idProvincia &&
                ubigeoD.Ubigeo !== null
            );
        });
        this.setState({
            valueDistrito: this.state.ubigeoDis[0].CodDistrito,
            IdUbigeo: this.state.ubigeoDis[0].IdUbigeo,
            //para el label de ubigeo
            ubigeo: this.state.ubigeoDis[0].Ubigeo
        });
    };

    traerEmpleadoPorEmpresa = async (IdEmpresa) => {

        this.state.empleadosPorEmpresa = this.state.dataEmpleados.filter((empleadosP) => {
            return String(empleadosP.IdEmpresa) === String(IdEmpresa)
        })

        //si hay empleados hace el setstate
        if (this.state.empleadosPorEmpresa[0]) {
            this.setState({IdEmpleado: this.state.empleadosPorEmpresa[0].IdEmpleado})
        }
    }

    RegionChange = event => {
        this.setState({valueRegion: event.target.value});
        this.traerUbigeoPorProvincia(event.target.value);
        this.traerUbigeoPorDistrito(
            event.target.value,
            this.state.ubigeoProv[0].CodProvincia
        );
    };

    ProvinciaChange = event => {
        this.setState({valueProvincia: event.target.value});
        this.traerUbigeoPorDistrito(this.state.valueRegion, event.target.value);
    };

    DistritoChange = event => {
        this.setState({
            IdUbigeo: event.target.value,
            valueDistrito: event.target.value
        });
        this.getCodigoUbigeoById(event.target.value)
    };

    //para el label de ubigeo
    getCodigoUbigeoById(idUbigeo) {
        fetch(`/api/ubigeos/ubigId/${idUbigeo}`)
            .then(res => res.json())
            .then(data => {
                //console.log(data[0].Ubigeo);
                this.setState({
                    ubigeo: data[0].Ubigeo
                });
            })
    }

    editSucursal(e) {
        let valido = true
        Object.values(this.state.Errores).forEach(val => {
            val.length > 1 && (valido = false)
        })
        //si esta ok, muestra mensaje y edita
        if (valido) {
            try {
                notificationSuccess()
                fetch(`/api/sucursales/edit/${this.state.IdSucursal}`, {
                    method: "PUT",
                    body: JSON.stringify({
                        IdSucursal: this.state.IdSucursal,
                        IdEmpresa: this.state.IdEmpresa,
                        IdEmpleado: this.state.IdEmpleado,
                        Nombre: this.state.Nombre,
                        IdUbigeo: this.state.IdUbigeo,
                        Direccion: this.state.Direccion,
                        Telefono: this.state.Telefono,
                        Celular: this.state.Celular,
                        CorreoElectronico: this.state.CorreoElectronico,
                        EsPrincipal: this.state.EsPrincipal,
                        Codigo: this.state.Codigo,
                        Estado: this.state.Estado
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .then(data => console.log(data))
                    .catch(err => console.log(err));
                this.props.history.push("/sucursales");
            } catch (error) {
                this.setState({
                    loading: false,
                    error: error,
                });
                console.log(error)
            }

        } else {
            //notificacion de error
            notificationError()
        }

        e.preventDefault();
    }

    handleChange = event => {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        });
        let Errores = this.state.Errores;

        //mensajes de validacion
        validarInputsSucursal(Errores, name, value);

        this.setState({Errores, [name]: value});
    };

    EmpresaChange = (e) => {
        this.setState({IdEmpresa: e.target.value})
        //console.log(e.target.value);
        this.traerEmpleadoPorEmpresa(e.target.value)
    }

    EmpleadoChange = (e) => {

        this.setState({IdEmpleado: e.target.value})
        //console.log(e.target.value,"--");
    }

    render() {
        if (this.state.loading === true) {
            return <PageLoading/>;
        }
        return (
            <React.Fragment>

                <SucursalForm
                    onChange={this.handleChange}
                    onSubmit={this.editSucursal}
                    RegionChange={this.RegionChange}
                    ProvinciaChange={this.ProvinciaChange}
                    DistritoChange={this.DistritoChange}
                    EmpleadoChange={this.EmpleadoChange}
                    EmpresaChange={this.EmpresaChange}
                    state={this.state}
                    CambiarEstadoSede={CambiarEstadoSede(
                        this.state.Estado,
                        this.state.IdSucursal,
                        this.state.Nombre,
                        this.props.history
                    )}
                />
            </React.Fragment>
        )
    }
}

export default EditSucursal;

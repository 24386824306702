import React, {Component} from "react";
import {PRECIO_VALE, VALE} from "../../Global";
import ReactExport from "react-data-export";
import _ from "lodash";
import {defaultGetHeaders, pfetch} from "../../helpers/wrappers";
import {notificarError, notificarMsg} from "../../componentes/Almacenes/AlmacenNotify";
import PreInvoiceForm from "../../componentes/invoice/PreInvoiceForm";
import {getPayloadFromPreVenta} from "../../helpers/utils";
import Modal from "../../componentes/Modal";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class ProcesarVales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            conf: null,
            showModalPrint: false,
            idRegistroVenta: null,
            datosProdsExcel: [],
        }
        this.onGenerateVenta = this.onGenerateVenta.bind(this)
        this.fetchDataReporteExcel = this.fetchDataReporteExcel.bind(this)
    }

    componentDidMount() {
        this.fetchConfiguration()
    }

    fetchConfiguration() {
        pfetch({
            fetch: {
                url: "/api/pre-invoice/config",
                method: "GET",
                headers: defaultGetHeaders(),
            },
            before: () => this.setState({isLoading: true}),
            then: (data) => this.setState({conf: data}),
            finally: () => this.setState({isLoading: false}),
            error: () => notificarError("Ha ocurrido un error al momento de extraer la configuración")
        })
    }

    fetchDataReporteExcel(e) {
        const btn = e.target
        pfetch({
            fetch: {
                url: `/api/gestionPreProcesarVales/productos-procesar/${this.state.IdAlmacen}/${PRECIO_VALE}`,
                method: "GET",
                headers: defaultGetHeaders(),
            },
            before: () => {
                btn.disabled = true
                this.setState({datosProdsExcel: []})
            },
            then: (data) => this.setState({datosProdsExcel: this.getDataConfForReportExcel(data.respuesta)}),
            error: () => notificarError("Ah ocurrido un error al momento de realizar el reporte"),
            finally: () => btn.disabled = false
        })
    }

    getDataConfForReportExcel(dataSet) {
        return [
            {
                columns: [
                    {
                        title: "Nombre",
                        width: {wpx: 400}
                    },
                    {
                        title: "Stock",
                        width: {wpx: 90}
                    },
                ],

                data: dataSet.map(row => {
                    return [
                        {
                            value: row.Nombre,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.Stock,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        }
                    ];
                })
            }
        ];
    }

    onGenerateVenta(d) {
        notificarMsg("Se ha transformado a venta con éxito.", "success", "topRight")
        pfetch({
            fetch: {
                url: `/api/configFactElectronica/ce/${d.idRegistroVenta}`,
                headers: defaultGetHeaders(),
                method: "GET",
            },
            before: () => {
                notificarMsg("Enviando a SUNAT...", "info", "topRight")
                this.setState({isLoading: true})
            },
            then: (resEnvio) => {
                if (resEnvio.ok) {
                    notificarMsg("Se ha enviado a SUNAT con éxito", "success", "topRight")
                    this.setState({showModalPrint: true, idRegistroVenta: d.idRegistroVenta})
                } else {
                    notificarError("Error: " + resEnvio.error.mensaje)
                }
            },
            error: () => notificarError("No se pudo enviar a sunat"),
            finally: () => this.setState({isLoading: false})
        })
    }

    render() {
        if (this.state.isLoading || _.isNull(this.state.conf))
            return <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                </div>
            </div>
        return (
            <>
                <div className="row">
                    <div className="col-sm-9">

                    </div>
                    <div className="col-sm-auto mt-1">
                        <button onClick={this.fetchDataReporteExcel}
                                className="btn btn-outline-success">Generar reporte
                            productos
                        </button>
                    </div>
                    <div className="col-sm-auto mt-1">
                        {
                            this.state.datosProdsExcel.length ? (
                                <div>
                                    <ExcelFile
                                        element={
                                            <button className="boton__verdeOscuro">
                                                <i className="fas fa-file-excel"/>
                                            </button>
                                        }
                                        filename="ProductosProcesar"
                                    >
                                        <ExcelSheet
                                            dataSet={this.state.datosProdsExcel}
                                            name="Productos"
                                        />
                                    </ExcelFile>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
                <div className="preventa__container">
                    <PreInvoiceForm
                        hideDocKeys={[VALE]}
                        showDefaultColor={true}
                        stockKey={"stock"}
                        prodsUrl={"/api/vales/productosVale?search=$"}
                        endpointInvoice={"/api/vales/process-vale-v2"}
                        accessCode={null}
                        preventaId={null}
                        defaultBgInProductDetail={true}
                        showSwitchStock={false}
                        getNormalizedPayload={payload => {
                            const newPayload = getPayloadFromPreVenta(payload);
                            newPayload['totales'] = payload._totales
                            return newPayload
                        }}
                        conf={this.state.conf} onSuccessAdd={this.onGenerateVenta}/>
                </div>
                <Modal
                    isOpen={this.state.showModalPrint}
                    onClose={() => this.setState({showModalPrint: false})}
                    title="Vale procesado"
                >
                    <div className="container mb-3">
                        <div className="row mt-3">
                            <div className="col-sm">
                                <h4>¿Desea ir a la vista de impresión y detalle?</h4>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-sm-auto">
                                <button
                                    onClick={() => this.props.history.push(`/detalleregistroventas/${this.state.idRegistroVenta}`)}
                                    className="btn btn-outline-primary">
                                    Sí
                                </button>
                            </div>
                            <div className="col-sm-auto">
                                <button onClick={() => this.setState({showModalPrint: false})}
                                        className="btn btn-outline-secondary">
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default ProcesarVales;

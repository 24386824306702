import React from "react";
import "react-moment";
import {validarInputsEmpleado} from "./EmpleadoValidator";
import {notificationAdd, notificationError} from './EmpleadosNotify'
import "../styles/Empleados.css";
import EmpleadoForm from './EmpleadoForm'
import {BotonCancelar} from "../Plantillas/Botones";
import {getApiDniStrategies} from "../../Global";

class AddEmpleados extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            error: null,
            Nombres: "",
            Apellidos: "",
            DNI: "",
            Telefono: "",
            Celular: "",
            FechaIngreso: "",
            Correo: "",
            Direccion: "",

            Errores: {
                DNI: "",
                Nombres: "",
                Apellidos: "",
                Telefono: "",
                Celular: "",
                Correo: "",
                FechaIngreso: ""
            }
        };
        this.newEmpleado = this.newEmpleado.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    newEmpleado(e) {
        this.setState({loading: true, error: null});
        let valido = true
        Object.values(this.state.Errores).forEach(val => {
            val.length > 1 && (valido = false)
        })

        if (valido) {

            notificationAdd()
            fetch("/api/empleados/add", {
                method: "POST",
                body: JSON.stringify(this.state),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .then(data => console.log(data))
                .catch(err => console.log(err));

            this.props.history.push('/empleados')

        } else {
            notificationError()
        }

        e.preventDefault();
    }

    handleChange(e) {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState({
            [name]: value
        });

        let Errores = this.state.Errores;

        //mensajes de validacion
        validarInputsEmpleado(Errores, name, value);

        this.setState({Errores, [name]: value});
    }

    handleSubmitDNI = async (e) => {
        e.preventDefault();
        this.TraerPersonaPorDNI(this.state.DNI);

    };

    TraerPersonaPorDNI = async (dni) => {
        try {
            let dniObj = getApiDniStrategies()[1];
            let data = await dniObj.getData(dni, dniObj.url);
            this.setState({
                Apellidos: data.apPaterno + " " + data.apMaterno,
                Nombres: data.nombres.join(" ")
            });

        } catch (error) {
            console.log(`Error --- >${error}`);
        }
    };

    render() {

        return (
            <React.Fragment>
                {/*formulario de empleado*/}
                <div className="ml-4 mt-4">
                    <BotonCancelar to="/empleados"/>
                </div>
                <EmpleadoForm
                    onChange={this.handleChange}
                    onSubmit={this.newEmpleado}
                    onSubmitDNI={this.handleSubmitDNI}
                    state={this.state}
                    volver="/empleados"
                />
            </React.Fragment>
        );
    }
}

export default AddEmpleados;

import React from 'react';
import macaddress from 'macaddress';
import PuntoVentaForm from './PuntoVentaForm'
import swal from 'sweetalert';


class PuntoVentaEditar extends React.Component{
    constructor(){
        super()
        this.state={
            loading:false, 
            error:null,
            form:{
                IdSucursal:1,
                Nombre:'',
                MAC:'',
                Funcion:'Punto Venta',
                Estado:'Activo',
            }

        }
    }

    componentDidMount() {
        this.TraerPuntoVentaPorId();
        //this.TraerEmpresas();
        // this.TraerSucursales();
    }

    async ObtenerMacAddress(){
        await macaddress.one(function (err, mac) {
            // console.log("Mac address for this host: %s", mac);  
            alert("Mac address for this host: %s", mac);  
           
          });
    }

/* ******************************************************************
        FUNCION: TRAE UN PUNTO DE VENTA POR ID
* ******************************************************************/
    TraerPuntoVentaPorId = async () =>{
        this.setState({loading:true, error:null});
        try {
            // const response = await fetch(`/api/clientes/item/${this.props.match.params.idcliente}`);
            const response = await fetch(`/api/puntosVenta/item/${this.props.itemPV.IdPuntoVenta}`);
            const regPV = await response.json();
            console.log(regPV);
            this.setState({
                loading: false,
                form:{
                    IdSucursal: regPV[0][0].IdSucursal,
                    Nombre: regPV[0][0].Nombre,
                    MAC: regPV[0][0].MAC,
                    Funcion: regPV[0][0].Funcion,
                    Estado: regPV[0][0].Estado,
                }
            });

        }catch(error){
            this.setState({
                loading: false,
                error: error,
            });
        }
    };
/* ******************************************************************
        FUNCION: ACTUALIZA DATOS DE CLIENTE
* ******************************************************************/
    actualizarPuntoVenta = async ()=>{ 
        this.setState({loading:true, error:null});
        try{
            const config = {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Nombre: this.state.form.Nombre, 
                    MAC: this.state.form.MAC,
                    Funcion: this.state.form.Funcion,
                    Estado: this.state.form.Estado,
                })
            };
            // const response = await fetch(`/api/clientes/actualizar/${this.props.match.params.idcliente}`, config) ;
            await fetch(`/api/puntosVenta/actualizar/${this.props.itemPV.IdPuntoVenta}`, config) ;
            this.setState({loading:false});
            console.log(config);

            /* LUEGO DE REALIZAR LA ACTULIZACION REGRESAMOS A LA PAGINA DONDE LISTA LOS CLIENTES */
            /* ================================================================================= */
            this.props.onClose();
            this.props.listarPuntosVenta(this.props.idSucursal)
            swal("El item fue Actualizado con Éxito!", {
                icon: "success",
            });
        }catch (error){
            this.setState({
                loading: false,
                error: error,
            });
        }
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        // this.ObtenerMacAddress();
        this.actualizarPuntoVenta();
    };
    
    /* ******************************************************************
        FUNCION: CONTROLA EVENTOS CHANGE
    * ******************************************************************/
     handleChange = (e)=>{
        this.setState({
            form:{
                ... this.state.form,
                [e.target.name]: e.target.value,

            },
        });
    }   

    handleClickMAC = e =>{
        e.preventDefault();
        macaddress.one((err,mac)=>{
            swal("La MAC es: " + mac);
        });
    }
    
    render(){
        return(
            <PuntoVentaForm 
                handleChange={this.handleChange}
                onClose={this.props.onClose}
                handleSubmit={this.handleSubmit}
                onClickMAC={this.handleClickMAC}
                form={this.state.form}
                accion='Actualizar Punto Venta'
            />
        )
    }
}

export default PuntoVentaEditar;
import React from 'react'
import {pfetch} from "../../helpers/wrappers";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";
import ReactTable from "react-table";
import moment from "moment";

export class RequestsAccessScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            accessRequests: [],
            table: {
                pageSize: 50,
                sorted: false,
                filtered: false,
                isLoading: true,
                pages: -1,
            },
            filterParams: {},
            isLoading: false,
        }
        this.onResetAttempts = this.onResetAttempts.bind(this)
    }

    onFilterAccessRequests(state, instance) {
        const params = {
            page: state.page,
            pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: state.filtered
        }
        pfetch({
            fetch: {
                url: `/api/config/access-requests/filter`,
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(params)
            },
            before: () => {
                this.setTableState({isLoading: true});
                this.setState({accessRequests: [], filterParams: params})
            },
            then: (d) => {
                this.setState({accessRequests: d.accessRequests})
                this.setTableState({pages: d.pages})
            },
            error: () => notificarError("Ha ocurrido un error al momento de listar las peticiones de acceso"),
            finally: () => {
                this.setTableState({isLoading: false});
            }
        })
    }

    setTableState(newTableState) {
        this.setState((localState, props) => {
            return {
                table: {
                    ...localState.table,
                    ...newTableState,
                }
            }
        })
    }

    onResetAttempts(id) {
        pfetch({
            fetch: {
                url: "/api/config/refresh-attempts/" + id,
                method: "POST"
            },
            before: () => this.setState({isLoading: true}),
            then: () => {
                notificarMsg("Se ha realizado con éxito")
                this.onFilterAccessRequests(this.state.filterParams)
            },
            error: () => notificarError("Ha ocurrido un error"),
            finally: () => this.setState({isLoading: false}),
        })
    }

    render() {
        const {accessRequests, table, isLoading} = this.state
        return (
            <div>
                <div className="row">
                    <div className="col-sm">
                        <h3>Peticiones de acceso</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <div className="alert alert-info">
                            El código de acceso se reinicia cada ciertos minutos de ser usado. Esto se especifica en
                            la configuración
                        </div>
                    </div>
                </div>
                <div className="row ml-3">
                    <div className="col-sm">
                        <ReactTable
                            data={accessRequests}
                            pages={table.pages}
                            loading={table.isLoading}
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value
                            }
                            manual={true}
                            filterable={true}
                            sortable={true}
                            onFetchData={(state, instance) => this.onFilterAccessRequests(state, instance)}
                            columns={[
                                {
                                    columns: [
                                        {
                                            Header: "Opciones",
                                            id: "id",
                                            width: 88,
                                            accessor: d => d.id,
                                            filterable: false,
                                            Cell: f => {
                                                return (
                                                    <button disabled={isLoading} title="Reiniciar intentos"
                                                            onClick={() => this.onResetAttempts(f.original.id)}
                                                            className="btn btn-outline-warning">
                                                        <i className="fas fa-redo"/>
                                                    </button>
                                                )
                                            }
                                        },
                                        {
                                            Header: "Descripción",
                                            id: "description",
                                            width: 349,
                                            accessor: d => d.description,
                                            filterAll: true
                                        },
                                        {
                                            Header: "Usuario",
                                            id: "nombres",
                                            width: 204,
                                            accessor: d => d.nombres,
                                            filterAll: true
                                        },
                                        {
                                            Header: "Código de acceso",
                                            id: "accessCode",
                                            width: 152,
                                            accessor: d => d.accessCode,
                                            filterAll: true
                                        },
                                        {
                                            Header: "Intentos",
                                            id: "attempt",
                                            width: 84,
                                            accessor: d => d.attempt,
                                            filterAll: true
                                        },
                                        {
                                            Header: "¿Está bloqueado?",
                                            id: "isBlocked",
                                            width: 156,
                                            accessor: d => d.isBlocked,
                                            filterAll: true
                                        },
                                        {
                                            Header: "Fecha de creación",
                                            id: "createdAt",
                                            width: 186,
                                            filterAll: true,
                                            accessor: d => moment(d.createdAt).format("DD/MM/YYYY HH:mm")
                                        },
                                    ]
                                }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            previousText="Anterior"
                            nextText="Siguiente"
                            noDataText="No se encontraron registros"
                            loadingText="Cargando..."
                            ofText="de"
                            rowsText="filas"
                            pageText="Página"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                        />
                    </div>
                </div>
            </div>
        );
    }

}

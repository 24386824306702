import React from "react";
import {decimalAdjust} from "../../Global";
import TablaPagoMovComponent from "./TablaPagoMovComponent";

export default class DetallePagoMov extends React.Component {
    constructor(props) {
        super(props);
    }

    getDeuda() {
        let totalPagado = this.props.deuda.pagos
            .filter(p => p.estado === 'PAGADO').map(p => Number(p.totalPagado)).reduce((a, b) => a + b, 0);
        return Number(this.props.deuda.total) - Number(totalPagado);
    }

    render() {
        const totalRestante = this.getDeuda();
        const {pagos} = this.props.deuda;

        return (
            <div>
                <div className="row mt-1">
                    <div className="col-sm-auto">
                        Total restante: {decimalAdjust('floor', totalRestante, -2)}
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-12">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home"
                                   role="tab" aria-controls="pills-home" aria-selected="true">Pagos</a>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                                 aria-labelledby="pills-home-tab">
                                <TablaPagoMovComponent pagos={pagos} readOnly={true}/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}

import React from "react";
import FiltroReporteMovAlmacen from "../componentes/ReporteMovAlmacen/FiltroReporteMovAlmacen";
import ReactTable from "react-table";
import moment from "moment";
import matchSorter from "match-sorter";
import {notificarError} from "../componentes/Almacenes/AlmacenNotify";
import {convertObjToQueryParams} from "../helpers/utils";
import {BotonExcel} from "../componentes/Plantillas/Botones";
import {getConfExcelForMovReport} from "../helpers/reports";
import ReactExport from "react-data-export";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default class ReporteMovAlmacen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            movs: [],
            isLoading: false,
            dataExport: {}
        }
        this.onFiltrarPressedHandler = this.onFiltrarPressedHandler.bind(this);
        this.extractData = this.extractData.bind(this);
    }

    onFiltrarPressedHandler(payload) {
        if (payload.idProducto)
            this.extractData(payload);
        else
            notificarError("Debe seleccionar un producto")
    }

    async extractData(payload) {
        this.setState({isLoading: true})
        let res = await fetch(`/api/movimientosalmacen/reporte?${convertObjToQueryParams(payload)}`)
        if (res.ok) {
            const movs = await res.json()
            this.setState({movs: movs, dataExport: {...payload, movs: movs}});
        } else
            notificarError("No se pudieron cargar los datos.")
        this.setState({isLoading: false})
    }

    getSumatoriaDeItemsPorAttr(data, attr) {
        let sum = 0;
        data.forEach(i => sum += Number(i[attr]))
        return sum.toFixed(6);
    }

    getSumCantidades(data) {
        let sum = 0;
        data.forEach(d => sum += (d.equivalencia * d.Cantidad))
        return sum.toFixed(6);
    }

    render() {
        return (
            <div className="ml-5 mr-5 mt-5">
                <FiltroReporteMovAlmacen isLoading={this.state.isLoading}
                                         onFiltrarPressed={this.onFiltrarPressedHandler}/>
                <div className="row mt-2">
                    <div className="col-sm-auto">
                        Sumatoria de cantidades: {this.getSumCantidades(this.state.movs)}
                    </div>
                    <div className="col-sm-auto">
                        Sumatoria de total: {this.getSumatoriaDeItemsPorAttr(this.state.movs, 'Total')}
                    </div>
                    {this.state.movs.length ? (
                        <div className="col-sm">
                            <ExcelFile
                                element={
                                    <BotonExcel
                                        type="button"
                                        title="Guardar Excel"/>
                                }
                                filename={`MOVIMIENTOS`}
                            >
                                <ExcelSheet
                                    dataSet={getConfExcelForMovReport(this.state.dataExport)}
                                    name="MOVIMIENTOS"
                                >
                                </ExcelSheet>
                            </ExcelFile>
                        </div>
                    ) : null}
                </div>
                <div className="row mt-3">
                    <div className="col-sm-12">
                        <ReactTable
                            data={this.state.movs}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value
                            }
                            columns={[
                                {
                                    columns: [
                                        {
                                            Header: "Fecha Emisión",
                                            id: "FechaEmision",
                                            headerClassName: "",
                                            width: 100,
                                            accessor: d => moment(d.FechaEmision).format('DD-MM-YYYY'),
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["FechaEmision"]}),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Proveedor",
                                            id: "RazonSocial",
                                            headerClassName: "",
                                            width: 300,
                                            accessor: d => d.RazonSocial || "Sin proveedor",
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["RazonSocial"]}),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Número y serie",
                                            id: "NroDocumentoSunat",
                                            headerClassName: "",
                                            width: 80,
                                            accessor: d => d.NroDocumentoSunat,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["NroDocumentoSunat"]}),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Tipo de cambio",
                                            id: "tipoCambio",
                                            headerClassName: "",
                                            width: 55,
                                            accessor: d => d.tipoCambio,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["tipoCambio"]}),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Cantidad",
                                            id: "Cantidad",
                                            headerClassName: "",
                                            width: 55,
                                            accessor: d => d.Cantidad,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["Cantidad"]}),
                                            filterAll: true
                                        },
                                        {
                                            Header: "TipoDocumento",
                                            id: "TipoDocumento",
                                            headerClassName: "",
                                            width: 55,
                                            accessor: d => d.TipoDocumento,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["TipoDocumento"]}),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Unidad de Medida",
                                            id: "unidad",
                                            headerClassName: "",
                                            width: 55,
                                            accessor: d => d.unidad,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["unidad"]}),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Precio",
                                            id: "Precio",
                                            headerClassName: "",
                                            width: 100,
                                            accessor: d => d.Precio,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["Precio"]}),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Total",
                                            id: "Total",
                                            headerClassName: "",
                                            width: 100,
                                            accessor: d => d.Total,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["Total"]}),
                                            filterAll: true
                                        },
                                    ]
                                }
                            ]}
                            defaultPageSize={20}
                            className="-striped -highlight"
                            previousText="Anterior"
                            nextText="Siguiente"
                            loadingText="Cargando..."
                            noDataText="No se encontraron registros"
                            pageText="Página"
                            ofText="de"
                            rowsText="filas"
                            pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

import React from "react";
import "./Estilos.css";
import LinkButton from "../LinkButton";

const botonGenerator = (defaultClasses, defaultChildren=undefined, title=undefined) => {

  return ({children, className: extraClasses, ...props}) => {

    // classes
    const classes = [];
    if (extraClasses)   classes.push(extraClasses);
    if (defaultClasses) classes.push(defaultClasses);
    const className = classes.join(' ');

    // button props
    const buttonProps = {className, title, ...props};

    return (
      (props.to)
        ? (
            <LinkButton {...buttonProps} >
              {defaultChildren} {children}
            </LinkButton>
          )
        : (
            <button {...buttonProps}>
              {defaultChildren} {children}
            </button>
          )
    )
  }
}

export const BotonGuardar = botonGenerator(
                                            "boton__verdeclaro",
                                            (<i className="far fa-save" />),
                                            "Guardar"
                                          );
export const BotonGuardarLetras = botonGenerator(
                                                  "boton__verdeClaro--letras",
                                                  (<span>Guardar</span>),
                                                  "Guardar"
                                                );

export const BotonLetras = botonGenerator("boton__verdeClaro--letras");

export const BotonLetrasVerdeOscuro = botonGenerator("boton__verdeOscuro--letras");

export const BotonLetrasAmarillo = botonGenerator("boton__amarillo--letras");

export const BotonLetrasRojo = botonGenerator("boton__Rojo--letras");

export const BotonCancelar = botonGenerator (
                                              "boton__pequeno--y",
                                              (<i className="fas fa-arrow-left"/>),
                                              "Cancelar"
                                            );
export const BotonCancelar2 = botonGenerator (
                                              "boton__pequeno--y2",
                                              (<i className="fas fa-arrow-left"/>),
                                              "Cancelar"
                                            );
export const BotonCancelarLetras = botonGenerator (
                                                    "boton__amarillo--letras",
                                                    (<span>Cancelar</span>),
                                                    "Cancelar"
                                                  );

export const BotonCancelarSinLetras = botonGenerator (
                                                    "boton__amarillo--letras",
                                                    (<span></span>),

                                                  );

export const BotonCerrarCajaLetras = botonGenerator (
                                                    "boton__Rojo--letras",
                                                    (<span>Cerrar Caja</span>),
                                                    "Cancelar"
                                                  );

export const BotonBuscar = botonGenerator (
                                            "boton__pequeno--g",
                                            (<i className="fas fa-download"/>),
                                            "Buscar"
                                          );

export const BotonEditar = botonGenerator (
                                            "boton__outline-pequeno--y",
                                            (<i className="fas fa-edit"/>),
                                            "Editar"
                                          );
export const BotonPDFList = botonGenerator (
                                            "boton__Rojo--Pequeño",
                                            (<i className="fas fa-file-pdf"/>)
                                          );

export const BotonPDF = botonGenerator("boton__Rojo",(<i className="fas fa-file-pdf"/>));

export const BotonExcel = botonGenerator("boton__verdeOscuro",(<i className="fas fa-file-excel"/>));

export const BotonAdd = botonGenerator("boton__verdeClaro",(<i className="fas fa-plus"/>));

export const BotonAddCliente = botonGenerator("boton__verdeClaro",(<i className="fas fa-user-plus"/>));

export const BotonDelete = botonGenerator("boton__outline-pequeno--r",(<i className="fas fa-trash-alt"/>));

export const BotonMenos = botonGenerator("boton__Rojo",(<i className="fas fa-minus"/>));

export const BotonRemove = botonGenerator("boton__Rojo",(<i className="fas fa-trash-alt"/>));

export const BotonDown = botonGenerator("boton__verdeClaro--Pequeño",(<i className="fas fa-arrow-down"/>));

export const BotonUp = botonGenerator("boton__verdeClaro--Pequeño",(<i className="fas fa-arrow-up"/>));

export const BotonAddPequeño = botonGenerator("boton__verdeClaro--Pequeño",(<i className="fas fa-plus"/>));

export const BotonMenosPequeño = botonGenerator("boton__Rojo--Pequeño",(<i className="fas fa-minus"/>));

export const BotonRemovePequeño = botonGenerator("boton__Rojo--Pequeño",(<i className="fas fa-trash-alt"/>));

export const BotonAddProveedor = botonGenerator("boton__verdeClaro--Pequeño",(<i className="fas fa-user-plus"/>));

export const BotonDeletePequeno = botonGenerator("boton__outline-maspequeno--r",(<i className="fas fa-trash-alt"/>));

export const BotonProcesarPequeno = botonGenerator("boton__outline-pequeno--c",(<i className="fas fa-check-square"/>));

export const BotonPDFpequeno = botonGenerator("boton__outline-pequeno--r",(<i className="fas fa-file-pdf"/>));
export const BotonCashRegister = botonGenerator("boton__outline-pequeno--r",(<i className="fas fa-cash-register"></i>));
export const BotonCashRegisterAperturar = botonGenerator("boton__outline-pequeno--c",(<i className="fas fa-cash-register"></i>));
export const BotonAperturar = botonGenerator("boton__outline-pequeno--c",(<i className="fas fa-lock-open"></i>))

export const BotonInformacion = botonGenerator("boton__outline-pequeno--y",(<i className="fas fa-info"></i>))

export const BotonMonedas = botonGenerator("boton__Amarillo--SinLetras",(<i className="fas fa-tags"></i>));
export const BotonVehiculos = botonGenerator("boton__Negro--SinLetras",(<i className="fas fa-car"></i>));
export const BotonConductores = botonGenerator("boton__verdeOscuro",(<i className="fas fa-truck"></i>));
export const BotonCheck = botonGenerator("boton__verdeClaro--Pequeño",(<i className="fas fa-check-square"></i>));
export const BotonConfiguracion = botonGenerator("boton__outline-pequeno--yConf",(<i className="fas fa-cogs"></i>));

export const BotonSearchCliente = botonGenerator("boton__verdeClaro",(<i className="fas fa-search"/>));

export const BotonSearchProveedor = botonGenerator("boton__verdeClaro--Pequeño",(<i className="fas fa-search"/>));

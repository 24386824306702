import React from "react";
import '../styles/tabla.css';
import "react-datepicker/dist/react-datepicker.css";
import {BotonAdd} from "../Plantillas/Botones";

class EmpresasAddFormDatosGenerales extends React.Component {

    render() {
        if (this.props.state.AddOrEdit == "Add") {
            return (
                <React.Fragment>
                    <div className="almacenDiv">
                        <div className="input__Almacen">
                            <b className="ml-1">*Representante Legal </b>
                            <input name="RepresentanteLegal"
                                   className="input__linea col-3"
                                   placeholder="Representante Legal..."
                                   defaultValue={this.props.state.RepresentanteLegal}
                                   onChange={this.props.handleChange}
                                   type="text"/>
                            <b className="ml-4">*Sector </b>
                            <select id="Sector" className="input__linea col-3" name="Sector"
                                    onChange={this.props.handleChange} value={this.props.state.Sector}
                                    aria-haspopup="true" aria-expanded="false">
                                <option value="Comercial" defaultValue>Comercial</option>
                                <option value="Servicios">Servicios</option>
                                <option value="Industrial">Industrial</option>
                                <option value="Otros">Otros</option>
                            </select>
                        </div>
                        <div className="labelValidAlmacen">
                            {this.props.state.Errores.RepresentanteLegal.length > 0 && (
                                <span>{this.props.state.Errores.RepresentanteLegal}</span>)}
                        </div>
                    </div>

                    <div className="almacenDiv">
                        <div className="input__Almacen">
                            <b className="ml-1">*Teléfono de Encargado </b>
                            <input
                                className="input__linea col-6"
                                id="Telefono"
                                name="Telefono"
                                placeholder="Teléfono..."
                                onChange={this.props.handleChange}
                                value={this.props.state.Telefono}
                                type="text"/>
                            <BotonAdd
                                type="button"
                                onClick={this.props.mostrarTelefonosOpcionales}
                            />
                        </div>
                        <div className="labelValidAlmacen col-3">
                            {this.props.state.Errores.Telefono.length > 0 && (
                                <span>{this.props.state.Errores.Telefono}</span>)}
                        </div>
                    </div>
                    <div hidden={this.props.state.mostrarTelefonosOpcionales} className="almacenDiv">
                        <div className="input__Almacen">
                            <b className="ml-1">Teléfono Dos </b>
                            <input
                                name="TelefonoDos"
                                className="input__linea col-3"
                                onChange={this.props.handleChange}
                                value={this.props.state.TelefonoDos}
                                type="text"
                                placeholder="Teléfono 2 - Opcional..."/>

                            <b className="ml-4">Teléfono Tres</b>
                            <input
                                name="TelefonoTres"
                                className="input__linea col-3"
                                onChange={this.props.handleChange}
                                value={this.props.state.TelefonoTres}
                                type="text"
                                placeholder="Teléfono 3 - Opcional..."/>
                        </div>
                        <div className="row form-group col-12 ">
                            <div className="labelValidAlmacen col-3">
                                {this.props.state.Errores.TelefonoDos.length > 0 && (
                                    <span>{this.props.state.Errores.TelefonoDos}</span>)}
                            </div>
                            <div className="labelValidAlmacen col-3">
                                {this.props.state.Errores.TelefonoTres.length > 0 && (
                                    <span>{this.props.state.Errores.TelefonoTres}</span>)}
                            </div>
                        </div>
                    </div>

                    <div className="almacenDiv">
                        <div className="input__Almacen">
                            <b className="ml-1">*Correo Electrónico </b>
                            <input name="CorreoElectronico"
                                   className="input__linea col-6"
                                   onChange={this.props.handleChange}
                                   value={this.props.state.CorreoElectronico}
                                   placeholder="Correo electrónico..."
                                   type="email"/>
                            <BotonAdd
                                type="button"
                                onClick={this.props.mostrarCorreosOpcionales}
                            />
                        </div>
                        <div className="labelValidAlmacen col-3">
                            {this.props.state.Errores.CorreoElectronico.length > 0 && (
                                <span>{this.props.state.Errores.CorreoElectronico}</span>)}
                        </div>
                    </div>
                    <div hidden={this.props.state.mostrarCorreosOpcionales} className="almacenDiv">
                        <div className="input__Almacen">
                            <b className="ml-1">Correo Elect. Dos </b>
                            <input name="CorreoElectronicoDos"
                                   className="input__linea col-3 "
                                   onChange={this.props.handleChange}
                                   value={this.props.state.CorreoElectronicoDos}
                                   type="email"
                                   placeholder="Segundo correo - opcional..."/>
                            <b className="ml-4">Correo Elect. Tres </b>
                            <input name="CorreoElectronicoTres"
                                   className="input__linea col-3"
                                   onChange={this.props.handleChange}
                                   value={this.props.state.CorreoElectronicoTres}
                                   type="email"
                                   placeholder="Tercer correo - Opcional..."/>
                        </div>
                        <div className="labelValidAlmacen col-3">
                            {this.props.state.Errores.CorreoElectronicoDos.length > 0 && (
                                <span>{this.props.state.Errores.CorreoElectronicoDos}</span>)}
                        </div>
                        <div className="labelValidAlmacen col-3">
                            {this.props.state.Errores.CorreoElectronicoTres.length > 0 && (
                                <span>{this.props.state.Errores.CorreoElectronicoTres}</span>)}
                        </div>
                    </div>

                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="almacenDiv">
                        <b className="col-3">Ruc</b>
                        <div className="input__Almacen">
                            <input
                                name="Ruc"
                                className="input__linea"
                                defaultValue={this.props.state.Ruc}
                                type="text"
                                disabled/>
                        </div>
                    </div>

                    <div className="almacenDiv">
                        <b className="col-3">Razón Social </b>
                        <div className="input__Almacen">
                            <input
                                name="RazonSocial"
                                className="input__linea"
                                onChange={this.props.handleChange}
                                value={this.props.state.RazonSocial}
                                type="text"/>
                        </div>
                        <div className="labelValidAlmacen">
                            {this.props.state.Errores.RazonSocial.length > 0 && (
                                <span>{this.props.state.Errores.RazonSocial}</span>
                            )}
                        </div>
                    </div>
                    <div className="almacenDiv">
                        <div className="input__Almacen">
                            <b className="ml-1">*Representante Legal </b>
                            <input name="RepresentanteLegal"
                                   className="input__linea col-3"
                                   placeholder="Representante Legal..."
                                   defaultValue={this.props.state.RepresentanteLegal}
                                   onChange={this.props.handleChange}
                                   type="text"/>
                            <b className="ml-4">*Sector </b>
                            <select id="Sector" className="input__linea col-3" name="Sector"
                                    onChange={this.props.handleChange} value={this.props.state.Sector}
                                    aria-haspopup="true" aria-expanded="false">
                                <option value="Comercial" defaultValue>Comercial</option>
                                <option value="Servicios">Servicios</option>
                                <option value="Industrial">Industrial</option>
                                <option value="Otros">Otros</option>
                            </select>
                        </div>
                        <div className="labelValidAlmacen">
                            {this.props.state.Errores.RepresentanteLegal.length > 0 && (
                                <span>{this.props.state.Errores.RepresentanteLegal}</span>)}
                        </div>
                    </div>

                    <div className="almacenDiv">
                        <div className="input__Almacen">
                            <b className="ml-1">*Teléfono de Encargado </b>
                            <input
                                className="input__linea col-6"
                                id="Telefono"
                                name="Telefono"
                                placeholder="Teléfono..."
                                onChange={this.props.handleChange}
                                value={this.props.state.Telefono}
                                type="text"/>
                            <BotonAdd
                                type="button"
                                onClick={this.props.mostrarTelefonosOpcionales}
                            />
                        </div>
                        <div className="labelValidAlmacen col-3">
                            {this.props.state.Errores.Telefono.length > 0 && (
                                <span>{this.props.state.Errores.Telefono}</span>)}
                        </div>
                    </div>
                    <div hidden={this.props.state.mostrarTelefonosOpcionales} className="almacenDiv">
                        <div className="input__Almacen">
                            <b className="ml-1">Teléfono Dos </b>
                            <input
                                name="TelefonoDos"
                                className="input__linea col-3"
                                onChange={this.props.handleChange}
                                value={this.props.state.TelefonoDos}
                                type="text"
                                placeholder="Teléfono 2 - Opcional..."/>

                            <b className="ml-4">Teléfono Tres</b>
                            <input
                                name="TelefonoTres"
                                className="input__linea col-3"
                                onChange={this.props.handleChange}
                                value={this.props.state.TelefonoTres}
                                type="text"
                                placeholder="Teléfono 3 - Opcional..."/>
                        </div>
                        <div className="row form-group col-12 ">
                            <div className="labelValidAlmacen col-3">
                                {this.props.state.Errores.TelefonoDos.length > 0 && (
                                    <span>{this.props.state.Errores.TelefonoDos}</span>)}
                            </div>
                            <div className="labelValidAlmacen col-3">
                                {this.props.state.Errores.TelefonoTres.length > 0 && (
                                    <span>{this.props.state.Errores.TelefonoTres}</span>)}
                            </div>
                        </div>
                    </div>

                    <div className="almacenDiv">
                        <div className="input__Almacen">
                            <b className="ml-1">*Correo Electrónico </b>
                            <input name="CorreoElectronico"
                                   className="input__linea col-6"
                                   onChange={this.props.handleChange}
                                   value={this.props.state.CorreoElectronico}
                                   placeholder="Correo electrónico..."
                                   type="email"/>
                            <BotonAdd
                                type="button"
                                onClick={this.props.mostrarCorreosOpcionales}
                            />
                        </div>
                        <div className="labelValidAlmacen col-3">
                            {this.props.state.Errores.CorreoElectronico.length > 0 && (
                                <span>{this.props.state.Errores.CorreoElectronico}</span>)}
                        </div>
                    </div>
                    <div hidden={this.props.state.mostrarCorreosOpcionales} className="almacenDiv">
                        <div className="input__Almacen">
                            <b className="ml-1">Correo Elect. Dos </b>
                            <input name="CorreoElectronicoDos"
                                   className="input__linea col-3 "
                                   onChange={this.props.handleChange}
                                   value={this.props.state.CorreoElectronicoDos}
                                   type="email"
                                   placeholder="Segundo correo - opcional..."/>
                            <b className="ml-4">Correo Elect. Tres </b>
                            <input name="CorreoElectronicoTres"
                                   className="input__linea col-3"
                                   onChange={this.props.handleChange}
                                   value={this.props.state.CorreoElectronicoTres}
                                   type="email"
                                   placeholder="Tercer correo - Opcional..."/>
                        </div>
                        <div className="labelValidAlmacen col-3">
                            {this.props.state.Errores.CorreoElectronicoDos.length > 0 && (
                                <span>{this.props.state.Errores.CorreoElectronicoDos}</span>)}
                        </div>
                        <div className="labelValidAlmacen col-3">
                            {this.props.state.Errores.CorreoElectronicoTres.length > 0 && (
                                <span>{this.props.state.Errores.CorreoElectronicoTres}</span>)}
                        </div>
                    </div>

                </React.Fragment>
            );
        }
    }
}

export default EmpresasAddFormDatosGenerales;

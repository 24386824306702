import React from 'react';


function PageLoading(){
    return (
        <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    );
}
export default PageLoading;
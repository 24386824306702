import React from 'react';

class PreProcesarValesModalDescuentos extends React.Component{
    render(){
        return(
            <React.Fragment>
                <div className="row form-group col-12">
                    {this.props.ItemDescuento.NombreProducto}
                </div>    
                {this.props.ItemDescuento.NombrePresentacion == "PRINCIPAL"? null :
                <div className="row form-group col-12">
                    {this.props.ItemDescuento.NombrePresentacion}
                </div>
                }   
                {/* <div className="row form-group col-12">
                    
                    <label className="mr-1">Descuento</label>
                    <input
                        type="number"
                        //style={{ backgroundColor: "#2E60FA", color: "white" }}
                        step="any"
                        min="0"
                        max="999999.999999"
                        value={this.props.ItemDescuento.Descuento}
                        onChange={e => this.props.DescuentoChange(e, this.props.ItemDescuento)}
                        autoFocus
                    />
                </div> */}
                <div className="row form-group col-12">
                <label className="mr-2 mt-1" htmlFor="customCheckDescuento">Gratuito</label>
                    <input
                        id="customCheckDescuento"
                        type="checkbox"
                        name="gratis"
                        className="gratis mt-2 mr-2"
                        onChange={e => this.props.onChangeCheckBoxGratuito(e, this.props.ItemDescuento)}
                        value={this.props.ItemDescuento.Gratuito}
                        checked={this.props.ItemDescuento.checked}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default PreProcesarValesModalDescuentos
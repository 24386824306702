import { GUARDAR_SESION, BORRAR_SESION } from "../actionTypes";

const sinSesion = {
  isLoggedIn: null,
  loading: false,
};

/**
 * Guardar los datos de sesion del usuario
 */
export default (sesionState = sinSesion, action) => {
  switch (action.type) {
    case GUARDAR_SESION:    return {...sesionState, ...action.payload}
    case BORRAR_SESION:     return sinSesion
    default:                return sesionState
  }
}
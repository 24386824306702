import {validacionesRegexr} from "./EmpresasRegexValidator"
import moment from "moment";
const hoy = moment(new Date()).format("YYYY-MM-DD")
  
  export function validarInputsEmpresa(Errores,name,value) {
    switch (name) {
      case 'Ruc':
        Errores.Ruc = validacionesRegexr.RucRegex.test(value) ? '' : '*El Ruc debe tener 11 dígitos. No debe contener letras o signos'
        break;
      case 'RazonSocial':
        Errores.RazonSocial = validacionesRegexr.RazonSocialRegex.test(value) ? '' : '*Razón Social no válida'
        break;
      case 'Direccion':
        Errores.Direccion = validacionesRegexr.DireccionRegex.test(value) ? '' : '*Dirección no válida'
        break;
      case 'Telefono':
        Errores.Telefono = validacionesRegexr.TelefonoRegex.test(value) ? '' : '*Teléfono no válido'
      break;
      case 'TelefonoDos':
        Errores.TelefonoDos = validacionesRegexr.TelefonoOpcionalRegex.test(value) ? '' : '*Teléfono opcional no válido'
      break;
      case 'TelefonoTres':
        Errores.TelefonoTres = validacionesRegexr.TelefonoOpcionalRegex.test(value) ? '' : '*Teléfono opcional no válido'
      break;
      case 'NombreComercial':
        Errores.NombreComercial = validacionesRegexr.NombreComercialRegex.test(value) ? '' : '*Nombre Comercial no válido'
      break;
      case 'PaginaWeb':
        Errores.PaginaWeb = validacionesRegexr.PaginaWebRegex.test(value) ? '' : '*Pagina Web no válido'
      break;
      case 'CorreoElectronico':
        Errores.CorreoElectronico = validacionesRegexr.CorreoElectronicoRegex.test(value) ? '' : '*Correo Electrónico no válido'
      break;
      case 'CorreoElectronicoDos':
        Errores.CorreoElectronicoDos = validacionesRegexr.CorreoElectronicoRegex.test(value) ? '' : '*Correo Electrónico Dos no válido'
      break;
      case 'CorreoElectronicoTres':
        Errores.CorreoElectronicoTres = validacionesRegexr.CorreoElectronicoRegex.test(value) ? '' : '*Correo Electrónico Tres no válido'
      break;
      case 'RepresentanteLegal':
        Errores.RepresentanteLegal = validacionesRegexr.RepresentanteLegalRegex.test(value) ? '' : '*Representante Legal no válido'
      break;
      case 'FechaDeInscripcion':
        Errores.FechaDeInscripcion= value<=hoy
        ? ''
        : '*El día no puede pasar la fecha actual'
        break; 
        case 'FechaDeConstitucion':
          Errores.FechaDeConstitucion= value<=hoy
          ? ''
          : '*El día no puede pasar la fecha actual'
          break; 
      default:
        break;
    }
  }
  
import moment from "moment";
import React from "react";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import {convertObjToQueryParams} from "../../helpers/utils";
import _ from 'lodash';

export default class FiltroReportDocSunat extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            payload: {},
            isLoading: false,
        }
        this.searchDocs = this.searchDocs.bind(this);
        this.onChangeTipoDoc = this.onChangeTipoDoc.bind(this);
        this.onChangeSucursales = this.onChangeSucursales.bind(this);
        this.searchSucursales = this.searchSucursales.bind(this);
    }

    searchDocs(query) {
        return new Promise((resolve, reject) => {
            this.setState({isLoading: true});
            const customQueryParams = this.props.customQueryParams;
            const customParams = customQueryParams && Object.keys(customQueryParams).length ?
                convertObjToQueryParams(customQueryParams) : '';
            fetch(`/api/planesSunat/tipo-docs/?query=${query}${customParams.length ? `&${customParams}` : ''}`)
                .then(r => r.json())
                .then(docs => {
                    resolve(docs.map(d => {
                        return {...d, value: d.IdTipoDocumentoSunat, label: d.Descripcion}
                    }))
                }).catch(reject)
                .finally(() => this.setState({isLoading: false}))
        });
    }

    searchSucursales(query) {
        return new Promise((resolve, reject) => {
            this.setState({isLoading: true});
            fetch(`/api/reportes/sucursales?search=${query}`)
                .then(r => r.json())
                .then(docs => {
                    resolve(docs.map(d => {
                        return {...d, value: d.IdSucursal, label: `${d.Nombre}`}
                    }))
                }).catch(reject)
                .finally(() => this.setState({isLoading: false}))
        });
    }

    onChangeTipoDoc(e) {
        this.setState({
            payload: {
                ...this.state.payload,
                idsTipoDoc: e ? e.map(x => x.IdTipoDocumentoSunat) : [],
                _tipoDocs: e ? e : [],
            }
        })
    }

    onChangeSucursales(e) {
        this.setState({
            payload: {
                ...this.state.payload,
                idSucursales: e ? e.map(x => x.IdSucursal) : [],
                _sucursales: e ? e : [],
            }
        })
    }

    render() {
        const {payload} = this.state;

        const showDocs = !_.isUndefined(this.props.showDocs) ? Boolean(this.props.showDocs) : true;
        const showSucursales = !_.isUndefined(this.props.showSucursales) ? Boolean(this.props.showSucursales) : true;

        return (
            <>
                <div className="row">
                    <div className="col-sm-auto">
                        Fecha de inicio
                    </div>
                    <div className="col-sm">
                        <input type="date" className="form-control"
                               onChange={e => this.setState({
                                   payload: {
                                       ...this.state.payload,
                                       fechaInicio: e.target.value
                                   }
                               })}
                               value={payload.fechaInicio ? moment(payload.fechaInicio).format('YYYY-MM-DD') : ""}/>
                    </div>
                    <div className="col-sm-auto">
                        Fecha de fin
                    </div>
                    <div className="col-sm">
                        <input type="date" className="form-control"
                               onChange={e => this.setState({
                                   payload: {
                                       ...this.state.payload,
                                       fechaFin: e.target.value
                                   }
                               })}
                               value={payload.fechaFin ? moment(payload.fechaFin).format('YYYY-MM-DD') : ""}/>
                    </div>
                    <div className="col-sm-auto">
                        <a href="#" onClick={e => {
                            e.preventDefault();
                            this.setState({
                                payload: {
                                    ...this.state.payload,
                                    fechaInicio: moment(new Date()).format('YYYY-MM-DD'),
                                    fechaFin: moment(new Date()).format('YYYY-MM-DD'),
                                }
                            })
                        }}>Hoy</a>
                    </div>
                </div>
                <div className="row mt-3">
                    {showDocs ? (
                        <>
                            <div className="col-sm-auto">
                                Seleccione documentos
                            </div>
                            <div className="col-sm">
                                <AsyncSelect
                                    defaultOptions={true}
                                    onChange={this.onChangeTipoDoc}
                                    loadOptions={this.searchDocs}
                                    isClearable={true}
                                    isLoading={this.state.isLoading}
                                    isMulti
                                />
                            </div>
                        </>
                    ) : null}

                    {showSucursales ?
                        <>
                            <div className="col-sm-auto">
                                Sucursales
                            </div>
                            <div className="col-sm">
                                <AsyncSelect
                                    defaultOptions={true}
                                    onChange={this.onChangeSucursales}
                                    loadOptions={this.searchSucursales}
                                    isClearable={true}
                                    isLoading={this.state.isLoading}
                                    isMulti
                                />
                            </div>
                        </> : "Sucursal: " + (this.props.sucursal ? this.props.sucursal.Nombre : "Sin sucursal")
                    }


                    <div className="col-sm-auto">
                        <button onClick={() => {
                            let tmpPayload = {...payload}
                            tmpPayload._sucursales = showSucursales ? payload._sucursales : [this.props.sucursal]
                            tmpPayload.idSucursales = showSucursales ? payload.idSucursales : [this.props.sucursal.IdSucursal]
                            this.props.onFilterPressed(tmpPayload)
                        }}
                                className="btn btn-outline-primary" disabled={this.props.isLoading}>
                            Filtrar
                        </button>
                    </div>
                </div>
            </>
        );
    }
}

import React from 'react';
import PageLoading from '../PageLoading';
// import ClientesLista from './ClientesLista';
import {Link} from 'react-router-dom';
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter';
import Modal from '../clientes/ClienteModal';
// import Modal from '../Modal';
import ProveedorEditar from './ProveedorEditar';
import ProveedorNuevo from './ProveedorNuevo';
import { BotonAdd, BotonEditar } from '../Plantillas/Botones';


class ProveedoresLista extends React.Component{

    constructor(){
        super()
        this.state={
            buscar:"",
            modalIsOpen:false,

            loading: true,
            error:null,
            data:[],
            itemproveedor:{
                idproveedor:null,
                nomproveedor:'',
            },
        }
    }

    componentDidMount(){
        // console.log("cambio");
        this.listaProveedores();
        this.intervalId = setInterval(this.listaProveedores,5000);
    }

    componentWillUnmount(){
        clearInterval(this.intervalId);
    }
    
    listaProveedores = async () =>{
            this.setState({loading:true, error:null});
            try {
                // const response = await fetch(`/api/clientes/${this.state.buscar}`);
                const response = await fetch(`/api/proveedores/list/`,[]);
                const data = await response.json();
                //console.log(response.headers);
                this.setState({
                    loading: false,
                    data: data,
                });
            }catch(error){
                this.setState({
                    loading: false,
                    error: error,
                });
            }
    }

    handleInput=(e)=>{ 
        this.setState({
            buscar: e.target.value,
            // [e.target.name]: e.target.value,
        });
    }

    handleOpenModal = (reg) => {
        console.log("Nombre Proveedor: ", reg.RazonSocial);
        this.setState({
            modalIsOpen:true,
            itemproveedor:{
                ... this.state.itemproveedor,
                nomproveedor: reg.RazonSocial,
                idproveedor: reg.IdProveedor,
            }
        })
    }
  
    handleCloseModal = (e) => {
        this.setState({modalIsOpen:false})
    }

    handleClickNuevo = (e)=>{
        console.log('ingreso')
        this.setState({
            modalIsOpen:true,
            swPrincipal:1,
            itemproveedor:{
                ... this.state.itemproveedor,
                idproveedor: null,
            }
        })

    }

    render(){

        /* SI HUBO ERROR AL CARGAR LA DATA MOSTRAMOS EL ERROR */
        /* ================================================== */
        if(this.state.error){
            return `Error: ${this.state.error.message}`;
        }

        /* MOSTRAMOS UN PRE-LOAD AL CARGAAR LA DATA */
        /* ================================================== */
        if(this.state.loading === true && this.state.data[1] === undefined){
            return <PageLoading/>;
        }

        /* SI LA DATA AUN ESTA VACIA */
        /* ================================================== */
        if(this.state.data[1].length === 0 || this.state.data[1]=== undefined){
            return (
                    <div className="card text-center">
                        <div className="body p-4">
                            <p className="card-text">¡Aún no has creado tu primer proveedor...!</p>
                            <button className="btn btn-primary" onClick={this.handleClickNuevo}>+ Nuevo Proveedor</button>
                        </div>
                        <div className="card-footer text-muted">
                            <p className="card-text">Notas de Ayuda...</p>
                        </div>
                        <Modal  isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal}
                                        title="NUEVO PROVEEDOR" >
                                        <ProveedorNuevo onClose={this.handleCloseModal} listaProveedores={this.listaProveedores} sw="1"/>
                        </Modal>
                    </div>
            );
        }else{

            return (
                    <div className="m-5">
                            <div align ="right" className="mr-4 mb-2">
                                {/* <Link className="btn btn-primary " to= "/nuevocliente">+ Nuevo Cliente</Link> */}
                                <BotonAdd 
                                    title="Nuevo Proveedor"
                                    type="button"
                                    onClick={this.handleClickNuevo}>
                                </BotonAdd>
                                {/* <button 
                                className="btn btn-outline-primary" 
                                title="Nuevo Proveedor"
                                onClick={this.handleClickNuevo}>
                                    <i className="fa fa-user-plus"></i>    
                                </button> */}
                            </div>
                            <div>
                                <ReactTable
                                //  width='100%'   
                                data={this.state.data[1]}
                                filterable
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value}
                                columns={[
                                    {
                                    columns: [
                                        {
                                            Header: "Acciones",
                                            accessor: "IdProveedor",
                                            // headerClassName:'card-header bg-dark text-white',
                                            width:'90',
                                            filterable: false,
                                            Cell: (f) => {
                                                
                                                return (
                                                    <div align="center">
                                                        {/* <a href={`/cliente/${f.value}/editar`} className="badge badge-light">Ver Detalle</a> */}
                                                        <BotonEditar 
                                                            id={f.value} 
                                                            value={f.original.RazonSocial} 
                                                            onClick={
                                                                ()=>{this.handleOpenModal(f.original)}
                                                            } 
                                                            title="Ver"
                                                        />
                                                        {/* <button 
                                                        id={f.value} 
                                                        value={f.original.RazonSocial} 
                                                        onClick={this.handleOpenModal} 
                                                        className="btn btn-outline-primary fas fa-eye"
                                                        title="Ver"
                                                        >
                                                        </button> */}
                                                    </div>
                                                );
                                            },
                                        },

                                            {
                                                Header: "RUC",
                                                id:"Ruc",
                                                // accessor: "NroTipoDocumento",
                                                width:'150',
                                                accessor: d => d.Ruc,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["Ruc"] }),
                                                filterAll: true,
                                                getProps: (state, rowInfo, column) => {
                                                    return {
                                                        style: {
                                                            background: rowInfo && rowInfo.row.Estado === 'Inactivo' ? '#FEEBEB' : null,
                                                        },
                                                    };
                                                },
                                            },
                                            
                                            {
                                                Header: "Proveedor",
                                                id:"RazonSocial",
                                                // accessor: "NombreCliente",
                                                width:'700',
                                                accessor: d => d.RazonSocial,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["RazonSocial"] }),
                                                filterAll: true,
                                                getProps: (state, rowInfo, column) => {
                                                    return {
                                                        style: {
                                                            background: rowInfo && rowInfo.row.Estado === 'Inactivo' ? '#FEEBEB' : null,
                                                        },
                                                    };
                                                },
                                            },

                                            {
                                                Header: "Dirección",
                                                id:"Direccion",
                                                width:'500',
                                                accessor: d => d.Direccion,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["Direccion"] }),
                                                filterAll: true,
                                                getProps: (state, rowInfo, column) => {
                                                    return {
                                                        style: {
                                                            background: rowInfo && rowInfo.row.Estado === 'Inactivo' ? '#FEEBEB' : null,
                                                        },
                                                    };
                                                }
                                            },
                                            {
                                                Header: "Estado",
                                                id:"Estado",
                                                accessor: "Estado",
                                                // headerClassName:'card-header bg-dark text-white',
                                                width:'150',
                                                getProps: (state, rowInfo, column) => {
                                                    return {
                                                        style: {
                                                            background: rowInfo && rowInfo.row.Estado === 'Inactivo' ? '#FEEBEB' : null,
                                                        },
                                                    };
                                                },
                                                filterMethod: (filter, row) =>{
                                                    if(filter.value==='Todos'){
                                                        return true;
                                                    }
                                                    if(filter.value==="Activos"){
                                                        return row[filter.id]==='Activo';
                                                    }
                                                    if(filter.value==="Inactivos"){
                                                        return row[filter.id]==='Inactivo';
                                                    }
                                                },
                                                Filter: ({ filter, onChange }) =>
                                                    <select
                                                    onChange={event => onChange(event.target.value)}
                                                    style={{ width: "100%" }}
                                                    value={filter ? filter.value : "Todos"}
                                                    >
                                                        <option value="Todos">Todos</option>
                                                        <option value="Activos">Activos</option>
                                                        <option value="Inactivos">Inactivos</option>
                                                    </select>
                                                    
                                                // filterAll: true
                                            },
                                            
                                        ]
                                    },
                                ]}
                                 defaultPageSize={10}
                                //pageSize={10}
                                className="-striped -highlight"
                                // Text de paginación
                                previousText= 'Anterior'
                                nextText= 'Siguiente'
                                loadingText= 'Cargando...'
                                noDataText= 'No se encontraron registros'
                                pageText= 'Página'
                                ofText= 'de'
                                rowsText= 'filas'    
                                style={{
                                    width: "100%",
                                    // height: '100%',
                                    fontSize: "15px"
                                }}                    
                                pageSizeOptions= {[5, 10, 20, 25, 50, 100]}
                                
                                />
                                {
                                    this.state.modalIsOpen && this.state.itemproveedor.idproveedor ?
                                    <Modal   isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal}
                                        title={"MODIFICAR PROVEEDOR: " + this.state.itemproveedor.nomproveedor}  >
                                        <ProveedorEditar idproveedor={this.state.itemproveedor.idproveedor} onClose={this.handleCloseModal}
                                        listaProveedores={this.listaProveedores}/>
                                    </Modal>
                                    :
                                    <Modal  isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal}
                                        title="NUEVO PROVEEDOR" >
                                        <ProveedorNuevo 
                                            onClose={this.handleCloseModal} 
                                            listaProveedores={this.listaProveedores}
                                            sw = 'ls_nuevo'
                                        />
                                    </Modal>

                                }
                            </div>
                        
                               
                    </div>
                );
        }
            
    }
}

export default ProveedoresLista;
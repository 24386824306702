import React from "react";
import "react-moment";
import "../styles/Almacen.css";
import { validarInputsAlmacen } from "./AlmacenValidator";
import { notificationAdd, notificationError } from "./AlmacenNotify";
import AlmacenForm from "./AlmacenForm";
import PageLoading from "../PageLoading.js";

class AlmacenAdd extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      error: null,
      IdEmpresa: "",
      IdSucursal: "",
      IdEmpleado: "",
      Nombre: "",
      IdUbigeo: "",
      Direccion: "",
      Telefono: "",
      Celular: "",
      Valorizacion: false,
      ContMon: false,
      UltimaEntrada: 0,
      UltimaSalida: 0,
      UltimaTransferencia: 0,
      UltimoAjuste: 0,
      Clase: "PT",
      Errores: {
        Nombre: "",
        Telefono: "",
        Celular: "",
        UltimaEntrada: "",
        UltimaSalida: "",
        UltimaTransferencia: "",
        UltimoAjuste: ""
      },
      data: {
        ubigeos: []
      },

      dataEmpresas: [],

      dataEmpleados: [],
      dataSucursales: {
        sucursales: []
      },
      empleadosPorEmpresa: [],
      sucursalesPorEmpresa: [],
      ubigeoReg: [],
      ubigeoProv: [],
      ubigeoDis: [],
      valueRegion: "01",
      valueProvincia: "01",
      valueDistrito: "01",
      ubigeo: "------"
    };
    this.getEmpleados = this.getEmpleados.bind(this);
    this.getEmpresas = this.getEmpresas.bind(this);
    this.getSucursales = this.getSucursales.bind(this);
    this.RegionChange = this.RegionChange.bind(this);
    this.ProvinciaChange = this.ProvinciaChange.bind(this);
    this.DistritoChange = this.DistritoChange.bind(this);
    this.traerEmpleadoPorEmpresa = this.traerEmpleadoPorEmpresa.bind(this);
    this.traerSucursalesPorEmpresa = this.traerSucursalesPorEmpresa.bind(this);
    this.traerUbigeoPorProvincia = this.traerUbigeoPorProvincia.bind(this);
    this.traerUbigeoPorDistrito = this.traerUbigeoPorDistrito.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.newAlmacen = this.newAlmacen.bind(this);
  }

  componentDidMount = () => {
    this.getEmpresas();
    this.getEmpleados();
    this.getUbigeo();
    this.getSucursales();
  };

  getEmpresas = async () => {
    this.setState({ loading: true, error: null });
    try {
      const response = await fetch("/api/empresas");
      const data = await response.json();
      //console.log(data)
      const empresas = data.empresas.filter(act => {
        return act.Estado == "Activo";
      });
      this.setState({
        loading: false,
        dataEmpresas: empresas
      });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  getEmpleados = async () => {
    this.setState({ loading: true, error: null });
    try {
      const response = await fetch("/api/empleados/master");
      const data = await response.json();
      const empleados = data.empleados.filter(act => {
        return act.Estado == "activo";
      });
      this.setState({
        loading: false,
        dataEmpleados: empleados
      });
      await Promise.all([this.traerEmpleadoPorEmpresa(this.state.IdEmpresa)]);
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  getSucursales = async () => {
    this.setState({ loading: true, error: null });
    try {
      const response = await fetch("/api/sucursales");
      const data = await response.json();
      this.setState({
        loading: false,
        dataSucursales: data
      });
      await Promise.all([this.traerSucursalesPorEmpresa(this.state.IdEmpresa)]);
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
    //console.log(this.state.dataEmpleados)
  };

  getUbigeo = async () => {
    this.setState({ loading: true, error: null });
    try {
      const response = await fetch("/api/ubigeos/ubigRegion");
      const data = await response.json();
      this.setState({
        loading: false,
        data: data,
        valueRegion: data.ubigeos[0].CodRegion,
        valueProvincia: data.ubigeos[0].CodProvincia,
        IdUbigeo: data.ubigeos[0].IdUbigeo,
        valueDistrito: data.ubigeos[0].IdUbigeo
      });
      await Promise.all([
        this.traerUbigeoPorRegion(),
        this.traerUbigeoPorProvincia(data[0].CodRegion),
        this.traerUbigeoPorDistrito(data[0].CodRegion, data[0].CodProvincia)
      ]);
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  RegionChange = event => {
    this.setState({ valueRegion: event.target.value });
    this.traerUbigeoPorProvincia(event.target.value);
    this.traerUbigeoPorDistrito(
      event.target.value,
      this.state.ubigeoProv[0].CodProvincia
    );
  };

  ProvinciaChange = event => {
    this.setState({
      valueProvincia: event.target.value,
      IdUbigeo: event.target.value,
      valueDistrito: event.target.value
    });
    this.traerUbigeoPorDistrito(this.state.valueRegion, event.target.value);
    //this.getUbigeoById(event.target.value)
  };

  DistritoChange = event => {
    this.setState({
      IdUbigeo: event.target.value,
      valueDistrito: event.target.value
    });
    this.getUbigeoById(event.target.value);
  };

  getUbigeoById(idUbigeo) {
    fetch(`/api/ubigeos/ubigId/${idUbigeo}`)
      .then(res => res.json())
      .then(data => {
        //console.log(data[0].Ubigeo);
        this.setState({
          ubigeo: data[0].Ubigeo
        });
      });
  }

  traerUbigeoPorRegion = () => {
    this.state.ubigeoReg = this.state.data.ubigeos.filter(ubigeoR => {
      return ubigeoR.CodProvincia === "00" && ubigeoR.CodDistrito === "00";
    });
  };

  traerUbigeoPorProvincia = idRegion => {
    this.state.ubigeoProv = this.state.data.ubigeos.filter(ubigeoP => {
      return (
        ubigeoP.CodRegion === idRegion &&
        ubigeoP.CodProvincia != "00" &&
        ubigeoP.CodDistrito === "00"
      );
    });

    this.setState({ valueProvincia: this.state.ubigeoProv[0].CodProvincia });
    //console.log(this.state.ubigeoProv)
  };

  traerUbigeoPorDistrito = (idRegion, idProvincia) => {
    this.state.ubigeoDis = this.state.data.ubigeos.filter(ubigeoD => {
      return (
        ubigeoD.CodRegion === idRegion &&
        ubigeoD.CodProvincia === idProvincia &&
        ubigeoD.Ubigeo != null
      );
    });

    this.setState({
      valueDistrito: this.state.ubigeoDis[0].IdUbigeo,
      IdUbigeo: this.state.ubigeoDis[0].IdUbigeo,
      //para el label de ubigeo
      ubigeo: this.state.ubigeoDis[0].Ubigeo
    });
  };

  traerEmpleadoPorEmpresa = async IdEmpresa => {
    this.state.empleadosPorEmpresa = this.state.dataEmpleados.filter(
      empleadosP => {
        return empleadosP.IdEmpresa == IdEmpresa;
      }
    );
    //si hay empleados hace el setstate
    if (this.state.empleadosPorEmpresa[0]) {
      this.setState({
        IdEmpleado: this.state.empleadosPorEmpresa[0].IdEmpleado
      });
    }
  };

  traerSucursalesPorEmpresa = async IdEmpresa => {
    this.state.sucursalesPorEmpresa = this.state.dataSucursales.sucursales.filter(
      sucursalesP => {
        return sucursalesP.IdEmpresa == IdEmpresa;
      }
    );

    //si hay empleados hace el setstate
    if (this.state.sucursalesPorEmpresa[0]) {
      this.setState({
        IdSucursal: this.state.sucursalesPorEmpresa[0].IdSucursal
      });
    }
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
    let Errores = this.state.Errores;

    //mensajes de validacion
    validarInputsAlmacen(Errores, name, value);

    this.setState({ Errores, [name]: value });
    //console.log(this.state)
  };

  EmpresaChange = e => {
    this.setState({ IdEmpresa: e.target.value });
    //console.log(e.target.value);
    this.traerEmpleadoPorEmpresa(e.target.value);
    this.traerSucursalesPorEmpresa(e.target.value);
  };

  EmpleadoChange = e => {
    this.setState({ IdEmpleado: e.target.value });
    //this.setState({ IdEmpleado: e.value });
    //console.log(e.target.value,"--");
  };

  SucursalChange = e => {
    this.setState({ IdSucursal: e.target.value });
    //console.log(e.target.value,"--");
  };

  ClaseChange = e => {
    this.setState({ Clase: e.target.value });
    //console.log(this.state.Clase);
  };

  onChangeCheckBox = e => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked
    });

    //console.log(this.state)
  };

  newAlmacen(e) {
    this.setState({ loading: true, error: null });
    let valido = true;
    Object.values(this.state.Errores).forEach(val => {
      val.length > 1 && (valido = false);
    });
    if (valido) {
      try {
        notificationAdd();
        fetch("/api/almacenes/add", {
          method: "POST",
          body: JSON.stringify({
            IdSucursal: this.state.IdSucursal,
            IdEmpleado: this.state.IdEmpleado,
            Nombre: this.state.Nombre,
            IdUbigeo: this.state.IdUbigeo,
            Direccion: this.state.Direccion,
            Telefono: this.state.Telefono,
            Celular: this.state.Celular,
            Valorizacion: this.state.Valorizacion,
            ContMon: this.state.ContMon,
            UltimaEntrada: this.state.UltimaEntrada,
            UltimaSalida: this.state.UltimaSalida,
            UltimaTransferencia: this.state.UltimaTransferencia,
            UltimoAjuste: this.state.UltimoAjuste,
            Clase: this.state.Clase
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        })
          .then(res => res.json())
          .then(data => console.log(data))
          .catch(err => console.log(err));
        this.props.history.push("/almacenes");
      } catch (error) {
        this.setState({
          loading: false,
          error: error
        });
      }
    } else {
      //notificacion de error
      notificationError();
    }
    e.preventDefault();
    //console.log(this.state)
  }

  render() {
    if (this.state.loading === true) {
      return <PageLoading />;
    }
    return (
      <React.Fragment>
        
        <AlmacenForm
          handleChange={this.handleChange}
          onSubmit={this.newAlmacen}
          RegionChange={this.RegionChange}
          ProvinciaChange={this.ProvinciaChange}
          DistritoChange={this.DistritoChange}
          EmpleadoChange={this.EmpleadoChange}
          EmpresaChange={this.EmpresaChange}
          SucursalChange={this.SucursalChange}
          onChangeCheckBox={this.onChangeCheckBox}
          ClaseChange={this.ClaseChange}
          state={this.state}
        />
      </React.Fragment>
    );
  }
}
export default AlmacenAdd;

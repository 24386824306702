import React from "react";
import "react-moment";
import moment from "moment";
import {validarInputsEmpleado} from "./EmpleadoValidator";
import {notificationSuccess, notificationError} from "./EmpleadosNotify";
import {CambiarEstadoEmpleado} from './EstadoEmpleados';
import "../styles/Empleados.css";
import EmpleadoForm from "./EmpleadoForm";
import cheerio from "cheerio";
import {BotonCancelar} from "../Plantillas/Botones";
import {getApiDniStrategies} from "../../Global";

class EmpleadoEditMaster extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            error: null,
            IdEmpresa: "",
            Nombres: "",
            Apellidos: "",
            DNI: "",
            Telefono: "",
            Celular: "",
            FechaIngreso: "",
            Correo: "",
            Direccion: "",
            Errores: {
                DNI: "",
                Nombres: "",
                Apellidos: "",
                Telefono: "",
                Celular: "",
                Correo: "",
                FechaIngreso: ""
            },
            dataEmpresas: []
        };
        this.editEmpleado = this.editEmpleado.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getEmpresas = this.getEmpresas.bind(this);
    }

    componentDidMount() {
        const IdEmpleado = this.props.match.params.IdEmpleado;
        this.getEmpleadoById(IdEmpleado);
    }

    getEmpresas = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch("/api/empresas");
            const data = await response.json();
            const empresas = data.empresas.filter(act => {
                return act.Estado == "Activo";
            });

            this.setState({
                loading: false,
                dataEmpresas: empresas
            });
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    };

    getEmpleadoById(IdEmpleado) {
        this.setState({loading: true, error: null});
        //console.log(IdEmpleado);
        try {
            fetch(`/api/empleados/edit/${IdEmpleado}`)
                .then(res => res.json())
                .then(data => {
                    this.getEmpresas();
                    this.setState({
                        loading: false,
                        IdEmpleado: data[0].IdEmpleado,
                        IdEmpresa: data[0].IdEmpresa,
                        Nombres: data[0].Nombres,
                        Apellidos: data[0].Apellidos,
                        DNI: data[0].DNI,
                        Telefono: data[0].Telefono,
                        Celular: data[0].Celular,
                        Correo: data[0].Correo,
                        Direccion: data[0].Direccion,
                        FechaIngreso: this.fecha(data[0].FechaIngreso),
                        Estado: data[0].Estado
                    });
                });
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }

    }

    fecha(FechaIngreso) {
        return moment(FechaIngreso).format("YYYY-MM-DD");
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({
            [name]: value
        });

        let Errores = this.state.Errores;

        //mensajes de validacion
        validarInputsEmpleado(Errores, name, value);
        this.setState({Errores, [name]: value});

    }

    EmpresaChange = e => {
        this.setState({IdEmpresa: e.target.value});
    };

    editEmpleado(e) {
        let valido = true
        Object.values(this.state.Errores).forEach(val => {
            val.length > 1 && (valido = false)
        })
        //si esta ok, muestra mensaje y edita
        if (valido) {
            try {
                notificationSuccess()
                fetch(`/api/empleados/editMaster/${this.state.IdEmpleado}`, {
                    method: "PUT",
                    body: JSON.stringify({

                        Nombres: this.state.Nombres,
                        Apellidos: this.state.Apellidos,
                        DNI: this.state.DNI,
                        Telefono: this.state.Telefono,
                        Celular: this.state.Celular,
                        FechaIngreso: this.state.FechaIngreso,
                        Correo: this.state.Correo,
                        Direccion: this.state.Direccion,
                        IdEmpresa: this.state.IdEmpresa
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .then(data => console.log(data))
                    .catch(err => console.log(err));
                this.props.history.push('/empleados/master')
            } catch (error) {
                this.setState({
                    loading: false,
                    error: error,
                });
                console.log(error)
            }

        } else {
            notificationError()
        }

        e.preventDefault();

    }

    handleSubmitDNI = async e => {
        e.preventDefault();
        this.TraerPersonaPorDNI(this.state.DNI);
    };


    TraerPersonaPorDNI = async (dni) => {
        try {

            let dniObj = getApiDniStrategies()[1];
            let data = await dniObj.getData(dni, dniObj.url);

          this.setState({
              Apellidos: data.apPaterno + " " + data.apMaterno,
              Nombres: data.nombres.join(' ')
          });

        } catch (error) {
            console.log(`Error --- >${error}`);
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className="ml-4 mt-4">
                    <BotonCancelar to="/empleados/master"/>
                </div>
                <EmpleadoForm
                    onChange={this.handleChange}
                    onSubmit={this.editEmpleado}
                    onSubmitDNI={this.handleSubmitDNI}
                    state={this.state}
                    volver="/empleados/master"
                    CambiarEstadoEmpleado={CambiarEstadoEmpleado(
                        this.state.Estado,
                        this.state.IdEmpleado,
                        this.state.Nombres,
                        this.state.Apellidos,
                        this.props.history,
                        "/empleados/master"
                    )}
                    Empresa={
                        <div className="EmpleadoDocumento">
                            <b className="ml-4">*Empresa</b>
                            <div className="input__EmpleadoDiv">
                                <select
                                    required
                                    className="input__linea"
                                    aria-haspopup="true"
                                    name="empresa"
                                    value={this.state.IdEmpresa}
                                    onChange={this.EmpresaChange}
                                >
                                    <option value="">--Seleccionar Empresa--</option>
                                    {this.state.dataEmpresas.map(empresa => {
                                        return (
                                            <option key={empresa.ID} value={empresa.ID}>
                                                {empresa.RazonSocial}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    }
                />
            </React.Fragment>
        );
    }

}

export default EmpleadoEditMaster;

import React from 'react';
import VehiculosForm from './VehiculosForm.js';
import {Link} from 'react-router-dom';
import { BotonGuardarLetras, BotonCancelar,BotonLetrasRojo } from "../Plantillas/Botones";
import PageLoading from '../PageLoading.js';

class VehiculosEditPorEmpresa extends React.Component{

    constructor(){
        super()
        this.state={
            IdVehiculo:"",
            Placa:"",
            Modelo:"",
            FechaRevisionTecnica:"",
            FechaSOAT:"",
            EmpresaSOAT:"",
            NumeroSOAT:"",
            Estado:"",
            loading:false,
            error:null
        }
        this.getVehiculoById = this.getVehiculoById.bind(this)
    }

    componentDidMount() {
        const IdVehiculo = this.props.match.params.IdVehiculo;
        this.getVehiculoById(IdVehiculo);
        //setInterval(this.listaClientes,5000);
    }

    getVehiculoById = async(IdVehiculo) => {
        fetch(`/api/vehiculos/getById/${IdVehiculo}`)
        .then(res => res.json())
        .then(async data => {
          this.setState({
            IdVehiculo:data.Vehiculo[0].IdVehiculo,
            Placa:data.Vehiculo[0].Placa,
            Modelo:data.Vehiculo[0].Modelo,
            FechaRevisionTecnica:data.Vehiculo[0].FechaRevisionTecnica,
            FechaSOAT:data.Vehiculo[0].FechaSOAT,
            EmpresaSOAT:data.Vehiculo[0].EmpresaSOAT,
            NumeroSOAT:data.Vehiculo[0].NumeroSOAT,
          })
        });

    }

    handleChangeUnicos = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
        // console.log(e.target.name, e.target.value)
    };

    EditVehiculo = () => {
        fetch(`/api/vehiculos/edit/${this.state.IdVehiculo}`,{
                method:'PUT',
                body:JSON.stringify({
                    Placa: this.state.Placa,
                    Modelo: this.state.Modelo,
                    FechaRevisionTecnica: this.state.FechaRevisionTecnica,
                    FechaSOAT:this.state.FechaSOAT,
                    EmpresaSOAT:this.state.EmpresaSOAT,
                    NumeroSOAT:this.state.NumeroSOAT
                }),
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                }
            })
            .then(res => res.json())
            .then(data => console.log(data))
            .catch(err => console.log(err));
            this.props.history.push('/empresas/vehiculos')
    }

    RemoveVehiculo = () => {
        this.setState({ loading: true, error: null });
        try {
            fetch(`/api/vehiculos/delete/${this.state.IdVehiculo}`,{
                method:"DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
        this.props.history.push('/empresas/vehiculos')
    }

    render(){
        if(this.state.loading === false && this.state.IdVehiculo == ''){
            return <PageLoading/>;
        }
        return(
            <React.Fragment>
                <div className="ml-4 mt-4">
                    <BotonCancelar to="/empresas/vehiculos"/>
                </div> 
                    <form onSubmit={this.EditVehiculo} className="d-flex justify-content-center">
                        <div className="almacenForm">
                            <div className="AlmacenBoton">
                                <BotonLetrasRojo
                                    type="button"
                                    onClick={this.RemoveVehiculo}
                                    className="mr-3"
                                    >Dar de Baja
                                </BotonLetrasRojo>
                                <BotonGuardarLetras type="submit"/>
                            </div> 
                                    {/* <button 
                                        className="btn btn-outline-warning"
                                        onClick={this.RemoveVehiculo}>
                                        <i className="fas fa-trash-alt"></i>
                                    </button> */}
                                {/* Dinamico la Serie y número correlativo */}

                                {/* <div className="card-body"> */}
                                   
                                        <VehiculosForm
                                            state={this.state}
                                            handleChangeUnicos={this.handleChangeUnicos}
                                        />
                                        <div align="center">
                                            {/* <Link className="btn btn-secondary m-3" to="/empresas/vehiculos">
                                                Volver
                                            </Link> */}
                                            {/* <button className="btn btn-primary" type="submit">Guardar <i className="ml-1 fa fa-save"></i></button> */}
                                        </div>
                        </div>
                    </form>
                                {/* </div> */}
            </React.Fragment>
        )
        
    }

}

export default VehiculosEditPorEmpresa;
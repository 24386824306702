import React from "react";
import {defaultGetHeaders, pfetch} from "../../helpers/wrappers";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";
import PreInvoiceForm from "./PreInvoiceForm";
import _ from "lodash";
import {safe} from "../../helpers/utils";

export default class PreInvoiceScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            conf: null,
            preventaId: null,
            endpointInvoice: "",
            accessCode: ""
        }
        this.initialRoute = props.history.location
    }

    async componentDidMount() {
        const location = await safe(() => this.props.history.location.pathname, "")
        const accessCode = await safe(() => this.props.location.search.replace("?code=", ""), "")
        this.setState({
            preventaId: !_.isNaN(Number(this.props.match.params.id)) ? this.props.match.params.id : null,
            endpointInvoice: location.includes("/pre-invoice/edit/") ? "" : "/api/pre-invoice/add/v2",
            accessCode: accessCode
        })
        this.fetchConfiguration();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location !== this.initialRoute) {
            window.location.reload()
        }
    }

    fetchConfiguration() {
        pfetch({
            fetch: {
                url: "/api/pre-invoice/config",
                method: "GET",
                headers: defaultGetHeaders(),
            },
            before: () => this.setState({isLoading: true}),
            then: (data) => this.setState({conf: data}),
            finally: () => this.setState({isLoading: false}),
            error: () => notificarError("Ha ocurrido un error al momento de extraer la configuración")
        })
    }

    render() {
        if (this.state.isLoading || _.isNull(this.state.conf))
            return <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                </div>
            </div>

        return (
            <div className="preventa__container">
                <PreInvoiceForm endpointInvoice={this.state.endpointInvoice}
                                accessCode={this.state.accessCode}
                                preventaId={this.state.preventaId}
                                showSwitchStock={true}
                                conf={this.state.conf} onSuccessAdd={(v) => {
                    notificarMsg("Se ha realizado con éxito")
                    this.props.history.push(`/pagos/${v.IdPreventa}`)
                }}/>
            </div>
        );
    }
}

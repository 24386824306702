import React from "react";

export default class NotFound404 extends React.Component {

    render() {
        return (
            <div className="d-flex justify-content-center align-items-center" id="main">
                <h1 className="mr-3 pr-3 align-top border-right inline-block align-content-center">404</h1>
                <div className="inline-block align-middle">
                    <h2 className="font-weight-normal lead" id="desc">El recurso solicitado no se ha encontrado :( . <a
                        href="#" onClick={e => {
                        e.preventDefault()
                        this.props.history.push("/")
                    }}>Regresar</a>
                    </h2>
                </div>
            </div>
        )
    }
}

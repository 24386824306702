import React from "react";

export default class InvoiceInfoInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            text: ""
        }
        this.onChangeInfo = this.onChangeInfo.bind(this)
    }

    componentDidMount() {
        this.setState({text: this.props.defaultText || ""})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.defaultText !== this.props.defaultText)
            this.setState({text: this.props.defaultText || ""})
    }

    onChangeInfo(e) {
        const val = e.target.value
        this.setState({text: val})
        this.props.onChangeInfo(val)
    }

    render() {
        return (
            <>
                {this.props.aliasAsText ? (
                        <textarea
                            style={{width: "500px", borderColor: "#01a59c", borderWidth: "3px", borderTopColor: "#01a59c"}}
                            placeholder={this.props.placeholder}
                            name="Alias"
                            onChange={this.onChangeInfo}>{this.state.text}</textarea>
                    )
                    : (<input
                        hidden={Boolean(this.props.hideAlias)}
                        className={this.props.aliasClass || "inputAlias"}
                        name="Alias"
                        onChange={this.onChangeInfo}
                        type="text"
                        value={this.state.text}
                        style={this.props.aliasStyle || {}}
                        placeholder={this.props.placeholder || "Alias (opcional)"}
                    />)
                }
            </>
        );
    }

}

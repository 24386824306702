import React, { useState, useEffect } from 'react';
import "./Form.css"
import "../../pages/styles/SelectSearch.css";
import apiFetch from "../../helpers/apiFetch"
import convertMenuListToMenuTree from '../../helpers/convertMenuListToMenuTree';
import Notify from '../Notify';
import RolPermisosMenuItem from './RolPermisosMenuItem';
import CheckboxToggle from '../CheckboxToggle';
import useEmpresas from '../../helpers/useEmpresas';
import { BotonCancelar, BotonGuardarLetras } from '../Plantillas/Botones';
import { CampoInput } from '../Campos';


const getRolPermisos = async (IdRol, setMenu, setPermisos, setTree, signal=undefined) => {
	const {data_tables, error} = await apiFetch(`/api/roles/${IdRol || "new"}`,[], {signal});
	if (error) return;
	const [rolPermisosMenus] = data_tables

	// `Empresas` y `Permisos` hay que parsearlos.
	const rolPermisosMenusList = rolPermisosMenus.map( rolPermisosMenu => {
		rolPermisosMenu.Empresas = [...new Set(JSON.parse(rolPermisosMenu.Empresas))];
		rolPermisosMenu.Permisos = JSON.parse(rolPermisosMenu.Permisos);
		return rolPermisosMenu;
	})

	// Construyendo los objetos donde se guardarán los estados de los checkboxes
	const MenusState = {};
	const PermisosState = {};
	rolPermisosMenusList.forEach( rolPermisosMenu => {
		const {IdMenu, Permisos} = rolPermisosMenu;
		if (IdRol) {
			var {Estado, Insert, Update, List, Delete} = rolPermisosMenu;
		} else {
			Estado = Insert = Update = List = Delete = true;
		}


		Permisos.forEach( ({IdPermiso, Estado}) => {
			if (!IdRol) Estado = true;
			PermisosState[IdPermiso] = { state: !!Estado, newState: !!Estado }
		})

		/**
		 * Utilizo el operador `!!` para convertir en booleano.
		 * `0`->`false`
		 * `null`->`false`   // Este caso ocurre cuando el módulo está desactivado
		 * `1`->`true`
		 */
		MenusState[IdMenu] = {
			Estado: {state: !!Estado, newState: !!Estado},
			Insert: {state: !!Insert, newState: !!Insert},
			Update: {state: !!Update, newState: !!Update},
			List:   {state: !!List  , newState: !!List  },
			Delete: {state: !!Delete, newState: !!Delete},
		}
	})

	setMenu( MenusState );
	setPermisos( PermisosState );
	setTree( convertMenuListToMenuTree(rolPermisosMenusList) );
}



const Form = ({ Rol, setRol }) => {
	const [Nombre, setNombre] = useState(Rol.Nombre);

	const [RolEmpresasState, setRolEmpresasState] =         useState( Rol.Empresas.map(e=>Number(e.IdEmpresa)) );
	const [RolMenusStates, setRolMenusStates] =             useState(null);
	const [RolPermisosState, setRolPermisosState] =         useState(null);
	const [RolPermisosMenusTree, setRolPermisosMenusTree] = useState(null);

	const Empresas = useEmpresas([]);

	/****************************************************
	 * Obtener rolPermisosMenus al montar el componente *
	 ****************************************************/
	useEffect( () => {
		const controller = new AbortController();
		getRolPermisos(Rol.IdRol, setRolMenusStates, setRolPermisosState, setRolPermisosMenusTree, controller.signal);
		return () => controller.abort()
	}, [Rol.IdRol])



	/******************
	 *    HANDLERS    *
	 ******************/

	const handleNombre = event => setNombre(event.target.value)

	const handleEmpresaBuilder = IdEmpresa => {
		return event => {
			const isAdding = event.target.checked === true // agregar o remover.
			const newRolEmpresas = [...RolEmpresasState];
			if (isAdding) {
				newRolEmpresas.push(IdEmpresa);
			} else {
				const IdEmpresaIndex = newRolEmpresas.indexOf(IdEmpresa)
				newRolEmpresas.splice(IdEmpresaIndex, 1) // quitar IdEmpresa de la lista
			}
			setRolEmpresasState(newRolEmpresas);
		}
	}


	/********************************************
	 *    Calculadores de cambios de estados    *
	 ********************************************/

	/**
	 * El formato de `RolMenusStates` es:
	 * ```
	 * { [Idmenu]: { atributo1: estado1, ... }, ... }
	 * // Ej:
	 * {
	 *   1: {
	 *        Estado: {state: true, newState: true},
	 *        Insert: {state: false, newState: false},
	 *        Update: {state: false, newState: true},
	 *        List: {state: true, newState: false},
	 *        Delete: {state: true, newState: true},
	 *      },
	 *   2: ...
	 * }
	 * ```
	 *
	 * @returns {Array<Array>} el formato es:
	 * ```
	 * [ [Idmenu, Estado, Insert, list, Update, Delete] ]
	 * // Ej:
	 * [
	 *   [1, true, true, true, true, true],
	 *   [2, true, true, true, false, false],
	 *   [6, true, true, false, false, false],
	 *   [10, true, false, false, false, false],
	 *   ...
	 * ]
	 * ```
	 */
	const getRolMenusChanges = () => {
		const RolMenusChanges = []
		Object.entries(RolMenusStates).forEach( ([IdMenu, states]) => {
			let isChange = false
			const newStates = {}
			Object.entries(states).forEach( ([atributo, {state, newState}]) => {
				if (state !== newState) isChange = true;
				newStates[atributo] = newState
			})
			if (!isChange) return;		// No hay cambios, nada que hacer con este menú.
			RolMenusChanges.push([Number(IdMenu), newStates.Estado, newStates.Insert, newStates.List, newStates.Update, newStates.Delete])
		})
		return RolMenusChanges;
	}

	/**
	 * El formato de `RolPermisosState` es:
	 * ```
	 * { [Idmenu]: estado, ... }
	 * // Ej:
	 * {
	 *   1: {state: true, newState: true},
	 *   2: {state: false, newState: false},
	 *   3: {state: false, newState: true},
	 *   4: {state: true, newState: false},
	 * }
	 * ```
	 *
	 * @returns {Object<Array<Number>>} el formato es:
	 * ```
	 * {
	 *   addList: [IdPermiso1,IdPermiso3,...],
	 *   removeList: [IdPermiso2,IdPermiso4,...]
	 * }
	 * // Ej:
	 * {
	 *   addList: [1,3,6,...],
	 *   removeList: [2,4,9,...]
	 * }
	 * ```
	 */
	const getRolPermisosChanges = () => {
		const addList = [];
		const removeList = [];
		Object.entries(RolPermisosState).forEach( ([IdPermiso, {state, newState}]) => {
			if (state === newState) return	// No hay cambios, nada que hacer con este permiso.

			(newState===true)
				? addList.push(Number(IdPermiso))
				: removeList.push(Number(IdPermiso))
		})
		return {addList, removeList};
	}

	/**
	 * Se va a comparar el estado original y el estado cambiado, para resumir los cambios.
	 * Ambos estados (`RolEmpresasState` y `Rol.Empresas`) son una lista de IdEmpresas, por ejemplo:
	 * ```
	 * [1,2,5,8,9,...]
	 * ```
	 *
	 * @returns {Object<Array<Number>>} La lista de IdEmpresa que se van a agregar y los que se van a borrar. El formato es:
	 * ```
	 * {
	 *   addList: [IdEmpresa1,IdEmpresa3,...],
	 *   removeList: [IdEmpresa2,IdEmpresa4,...]
	 * }
	 * // Ej:
	 * {
	 *   addList: [1,3,6,...],
	 *   removeList: [2,4,9,...]
	 * }
	 * ```
	 */
	const getRolEmpresasChanges = () => {
		const addList = []
		const removeList = Rol.Empresas.map(e=>Number(e.IdEmpresa)) // Estado original sin los cambios hechos.
		RolEmpresasState.forEach( IdEmpresa => {
			const IdEmpresaIndex = removeList.indexOf(IdEmpresa);
			(IdEmpresaIndex === -1) // Si el `IdEmpresa` no estaba en el estado original
				? addList.push(Number(IdEmpresa))  // Agregar IdEmpresa al `addList`
				: removeList.splice(IdEmpresaIndex, 1)   // De lo contrario (no hubo cambio), Eliminar IdEmpresa del `removeList`
		})
		return {addList, removeList};
	}

	/**
	 * Se va a chequear si hubo cambios en los estados `Nombre`, `RolMenusStates`, `RolPermisosState` y `RolEmpresasState`
	 * Se ignorarán aquellos cuyos estados no han cambiado
	 * Y los que han cambiado se asignarán a las listas pertinentes
	 */
	const handleSaveChanges = async () => {

		const RolNombre = (Rol.Nombre !== Nombre) && Nombre
		const RolMenus = getRolMenusChanges();
		const RolPermisos = getRolPermisosChanges();
		const RolEmpresas = getRolEmpresasChanges();

		const estadosChequear = []
		if (Rol.IdRol){
			if (RolNombre)	estadosChequear.push("_estado_rolNombre_update");
		} else {
							estadosChequear.push("_estado_rol_insert");
		}
		if (RolMenus.length > 0)                estadosChequear.push("_estado_rolMenus_update");
		if (RolPermisos.addList.length > 0)     estadosChequear.push("_estado_rolPermisos_insert");
		if (RolPermisos.removeList.length > 0)  estadosChequear.push("_estado_rolPermisos_delete");
		if (RolEmpresas.addList.length > 0)     estadosChequear.push("_estado_rolEmpresas_insert");
		if (RolEmpresas.removeList.length > 0)  estadosChequear.push("_estado_rolEmpresas_delete");

		// Salir si no hay cambios que enviar
		if (estadosChequear.length === 0)
			return Notify("No hubo cambios", "warning", 1500);
		/**
		 * Una vez identificados y clasificados los cambios, se procede a enviarlos a la API
		 */
		const data = {RolNombre, RolPermisos, RolMenus, RolEmpresas}
		const {data_tables, error} = await apiFetch(`/api/roles/${Rol.IdRol || ''}`, estadosChequear, {
			method: "POST",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data)
		})
		let IdRol;
		if (Rol.IdRol) {
			IdRol = Rol.IdRol
		} else {
			if (error) return

			IdRol = data_tables[0][0].IdRol;
		}

		getRolPermisos(IdRol, setRolMenusStates, setRolPermisosState, setRolPermisosMenusTree);
		if (!error)
			Notify("Cambios guardados exitosamente");

		// Actualizar el Estado Rol
		const rol = {...Rol, Nombre, Empresas: RolEmpresasState.map(IdEmpresa=>({IdEmpresa})) };
		setRol(rol);
	}

	/**
	 * Esta función simplifica la estructura de `states`, manteniendo solamente el valor de `newState`, deshechando `state` por completo.
	 *
	 * Especificamente recibe un objeto con el formato:
	 * ```
	 * {
	 *   Estado: {state: true, newState: false},
	 *   Insert: {state: false, newState: true},
	 * }
	 * ```
	 * Y  devuelve un objeto con el formato:
	 * ```
	 * {
	 *   Estado: false,
	 *   Insert: true,
	 * }
	 * ```
	 * @param {Object} states
	 */
	const getNewStatesObj = states => {
		const newStates = {}
		Object.entries(states).forEach( ([stateName, stateValue]) => {
			newStates[stateName] = stateValue.newState
		})
		return newStates
	}

	/**
	 * Esta funcion devuelve cuales checkbox del actual menú deben estar deshabilitados
	 * Cuando digo **deshabilidatos** me refiero a cuales checkbox deben ponerseles el *keyword* `disabled`. Ej: `<input type="checkbox" disabled ... />`
	 * @param {Object} statesToDisable - Objeto que devuelve función `getStatesToDisableInChildren` (excepto en la primera iteracion de la funcion recursiva `renderMenuEmpresas`), e indica cuales checkbox deben deshabilitarse. Este objeto se calcula en la iteracion anterior de `renderMenuEmpresas` (el padre), excepto en el caso ya mencionado
	 * @param {Object} isMenuDisabled - Si el menú actual esta **deshabilitado** (proviene del estado actual del menú `!Estado.newState`)
	 */
	const getDisabledStates = (statesToDisable, isMenuDisabled) => {
		return {
			Estado:	(statesToDisable.Estado),
			Insert:	(statesToDisable.Insert	|| isMenuDisabled),
			Update:	(statesToDisable.Update	|| isMenuDisabled),
			List:	(statesToDisable.List	|| isMenuDisabled),
			Delete:	(statesToDisable.Delete	|| isMenuDisabled),
		}
	}

	/**
	 * Esta función recibe los estados y los estados-a-deshabilitar del menú actual y calcula cuales checkbox de los hijos se deben deshabilitar
	 *
	 * @param {Object} states - Objeto que devuelve función `getNewStatesObj`. Son los estados actuales de los valores del atributo `newState` de un Menú especifico dentro de `RolMenusStates`
	 * @param {Object} disabledStates Objeto devuelto
	 */
	const getStatesToDisableInChildren = (states, disabledStates) => {
		return {
			Estado:	(!states.Estado	|| disabledStates.Estado),
			Insert:	(!states.Insert	|| disabledStates.Insert),
			Update:	(!states.Update	|| disabledStates.Update),
			List:	(!states.List	|| disabledStates.List),
			Delete:	(!states.Delete	|| disabledStates.Delete),
		}
	}

	/**
	 * `false`: enabled / habilitado
	 * `true`: disabled / deshabilitado
	 * 
	 * No se va a deshabilitar ningún estado inicialmente
	 */
	const dontDisableStates = {
		Estado: false,
		Insert: false,
		Update: false,
		List: false,
		Delete: false,
	}
	/**
	 * Funcion recursiva que renderiza un `RolPermisosMenuItem` en cada instancia.
	 * 
	 * @param {Array<Object>} RolPermisosMenusSubTree array de objetos recursivo con la estructura jerárquica del menú
	 * @param {Object} statesToDisable Estados que se van a deshabilitar. Se calcula en el padre, excepto en la primera instancia que es `dontDisableStates`
	 */
	const renderMenuEmpresas = (RolPermisosMenusSubTree, statesToDisable=dontDisableStates) => (
		<ul className="pl-4 m-0">
			{RolPermisosMenusSubTree.map(RolPermisosMenu => {
				const {IdMenu, children} = RolPermisosMenu;
				const states = getNewStatesObj(RolMenusStates[IdMenu]);
				const isMenuDisabled = !states.Estado;
				const disabledStates = getDisabledStates(statesToDisable, isMenuDisabled);
				const statesToDisableInChildren = getStatesToDisableInChildren(states, disabledStates)
				return (
				<div className="ml-3 border-top" key={IdMenu}>
					<RolPermisosMenuItem {...{
						RolPermisosMenu,
						RolMenusStates,
						RolPermisosState,
						setRolMenusStates,
						setRolPermisosState,
						disabledStates,
					}} />
					<div id={`collapse${IdMenu}`} className="collapse show rolpermisosmenuitem_children">
						{ renderMenuEmpresas(children, statesToDisableInChildren) }
					</div>
				</div>
				)
			})}
		</ul>
	)

	return (
		<div id="rolesForm" className="d-flex justify-content-center">
			<div className="pt-2 d-flex flex-column align-items-center">
				{ (RolPermisosMenusTree && RolMenusStates && RolPermisosState)

					? <>
						<div className="container d-flex justify-content-between align-self-stretch align-items-center px-4">
							<BotonCancelar onClick={()=>setRol(null)}/>
							<BotonGuardarLetras onClick={handleSaveChanges}/>
						</div>
						<div className="m-3 d-flex flex-column">
							<CampoInput
								label="Nombre de Rol"
								value={Nombre}
								onChange={handleNombre}
								autoFocus
							/>
						</div>
						<div className="d-flex justify-content-center flex-wrap">
							<div id="empresas" className="m-3 d-flex flex-column" style={{overflow: "auto", maxHeight: "65vh"}}>
									{Empresas.map( Empresa => {
										const {ID: IdEmpresa, RazonSocial} = Empresa
										const checked = RolEmpresasState.some(_IdEmpresa => _IdEmpresa == IdEmpresa)
										return (
											<div key={IdEmpresa} className="d-flex align-items-center">
												<CheckboxToggle
														checked={checked}
														onChange={handleEmpresaBuilder(IdEmpresa)}
														labelText={RazonSocial}
												/>
											</div>
										)
									})}
							</div>
							<div id="rolPermisosMenus">
								<div className="d-flex justify-content-between mr-3">
									<div className="d-flex menu-nombre-width">
										<span className="w-100 text-center">Menu</span>
									</div>
									<div className="text-center d-flex justify-content-around menu-state-width">
										<div className="w-25 text-center">	Ver 		</div>
										<div className="w-25 text-center">	Insertar	</div>
										<div className="w-25 text-center">	Editar 		</div>
										<div className="w-25 text-center">	Eliminar	</div>
									</div>
									<div className="text-center permisos-width">
										Permisos
									</div>
								</div>
								<div className="border" style={{overflow: "auto", height: "calc(100vh - 100px)"}}>
									{ renderMenuEmpresas(RolPermisosMenusTree) }
								</div>
							</div>
						</div>
					</>

					: <BotonCancelar onClick={()=>setRol(null)}/>

				}
			</div>
		</div>
	);

}

export default Form;
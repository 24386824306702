import React from 'react';
import AsyncSelect from "react-select/async/dist/react-select.esm";
import {convertObjToQueryParams} from "../../helpers/utils";
import {notificarError} from "../Almacenes/AlmacenNotify";

export default class NoteReasonPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            reasons: [],
            isLoading: false
        }
        this.onChangeReason = this.onChangeReason.bind(this)
    }


    onChangeReason(e) {
        const val = this.state.reasons.filter(x => String(x.value) === String(e.target.value))
        this.props.onChangeReason(val ? val : [])
    }

    isMulti() {
        return Boolean(this.props.isMulti);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.queryParams.noteType !== this.props.queryParams.noteType)
            this.fetchReasons()
    }

    fetchReasons() {
        this.setState({isLoading: true})
        const queryParams = this.props.queryParams && Object.keys(this.props.queryParams).length ?
            convertObjToQueryParams(this.props.queryParams) : '';

        fetch(`/api/notascd/reasons?${queryParams}`)
            .then(r => r.json())
            .then(reasons => {
                this.setState({
                    reasons: reasons.map(r => {
                        return {...r, value: r.IdTipoNCD, label: r.DescripcionTipoNCD}
                    })
                })
            }).catch(() => notificarError("Hubo un error al momento de cargar los motivos"))
            .finally(() => this.setState({isLoading: false}))
    }

    render() {
        return (
            <>
                <select onChange={this.onChangeReason} multiple={this.isMulti()} className="form-control"
                        disabled={this.state.isLoading || this.props.readOnly}>
                    <option value="">{this.props.placeholder}</option>
                    {this.state.reasons.map(r => <option key={r.value} value={r.value}>{r.label}</option>)}
                </select>
            </>
        )
    }

}

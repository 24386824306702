import React from "react";
import {notificarError} from "../Almacenes/AlmacenNotify";

export default class DescuentoVales extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            descuento: 0,
        }
        this.realizarDescuento = this.realizarDescuento.bind(this);
    }

    realizarDescuento() {
        if (this.state.descuento > 0)
            this.props.onDescontar(this.props.detalles, this.state.descuento)
        else
            notificarError("El descuento no puede ser negativo y tiene que ser un número.")
    }

    render() {
        return (
            <div className="mx-3 mb-1">
                {this.props.detalles.length ? (
                    <div>
                        <div className="alert alert-info">
                            El descuento es calculado de la siguiente manera. Ej: si se ingresa 1 entonces
                            seria <strong>precio * 0.01</strong>.
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                Detalles seleccionados
                                <ul>
                                    {this.props.detalles.map(d => <li key={`det_i_${d.IdPresentacion}`}>
                                        {d.descripcion} {d.unidadMedida}
                                    </li>)}
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                Ingrese descuento <br/>
                                <input onChange={e => this.setState({descuento: e.target.value})} type="number"
                                       value={this.state.descuento} onClick={e => e.target.select()}/>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-sm-12">
                                <button onClick={this.realizarDescuento} className="btn btn-outline-primary">
                                    Aplicar descuento
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="alert alert-info">
                        Debe seleccionar detalles para realizar una acción
                    </div>
                )}
            </div>
        )
    }
}

import React, { useState } from 'react';
import { BrowserRouter, Switch, Route, useRouteMatch } from 'react-router-dom';

import SelectEmpresa from '../../componentes/SelectEmpresa';
import MenuEmpresas from '../../componentes/MenuEmpresas';


const EmpresaRoute = () => {
    const { path } = useRouteMatch();
    const [Empresa, setEmpresa] = useState(null);

    const RouteConEmpresa = ({component: Component, ...props}) =>
        <Route {...props} render={
            routeProps => <Component {...routeProps} Empresa={Empresa} />
        }/>

    return (
        <>
            <SelectEmpresa {...{Empresa, setEmpresa}} />
            { Empresa &&
                <BrowserRouter>
                    <Switch>
                        <RouteConEmpresa exact path={`${path}/modulos`} component={MenuEmpresas} />
                    </Switch>
                </BrowserRouter>
            }
        </>
    )
}

export default EmpresaRoute;
import {cfetch} from "../helpers/wrappers";
import {convertObjToQueryParams} from "../helpers/utils";
import {ACTION, ENTITY} from "contatrib-utils";

export default class VentaService {

    async restarStock(data) {
        const config = {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "entity": ENTITY.VENTA,
                "action": ACTION.DELETE,
                "entityId": data.IdRegistroVenta,
                "accessCode": data.accessCode,
            },
            body: JSON.stringify({
                idRegistroVenta: data.IdRegistroVenta,
                observacion: "Anulación del comprobante: " + data.Serie + " - " + data.NumeroComprobante
            }),
            method: "POST",
            url: "/api/configFactElectronica/restore-stock-money"
        }
        return await cfetch(config)
    }

    async filterVentasCe(bodyParams, queryParams = {}) {
        const config = {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            url: `/api/ventas/filter-fe?${convertObjToQueryParams(queryParams)}`,
            body: JSON.stringify(bodyParams),
            method: "POST"
        }
        return await cfetch(config);
    }
}

import Notify from '../componentes/Notify'
import validarStatusCode from './validarStatusCode';

/**
 * Fetch wrapper con catch de errores incluido para una fácil comunicacion con el **db.query()** de la API.
 * Usando Noty para mostrar los mensajes de _estado.
 * @param {String} url - Se le pasa directamente al fetch()
 * @param {Array<String>} estadosEsperados - **default: []**. Array de los *estados* a comprobar en orden si son true,
 * de lo contrario mostrar el error. Por ejemplo: ["_estado_usuarios_list"]
 * @param {Object} fetchOptions - Opciones para el fetch. Se le pasa directamente al fetch()
 */
const apiFetch = async (url, estadosEsperados=[], fetchOptions={}) => {

    let respuesta;
    try {
        respuesta = await fetch(url, fetchOptions);
    } catch (error) {
        if (error.name === "AbortError")
            return {error}
        console.error(error);
        Notify("Hubo un problema en la comunicación con el servidor", "error")
        return {error};
    }

    /********************************
     * COMPROBACION DE STATUS CODES *
     ********************************/
    if (! validarStatusCode(respuesta, fetchOptions)) return {error: true}

    let data_tables, headers;
    try {
        [data_tables, headers] = await respuesta.json();
    } catch (error) {
        console.error(error);
        console.error(respuesta);
        Notify("Respuesta mal formada:\n", "error")
        return {error}
    }

    /**************************************
     * COMPROBACION DE ERRORES EN ESTADOS *
     **************************************/

    let error = false;

    // Cuando falla el procedimiento almacenado o se envía un error con construirEstado(), arrojará un solo estado llamado "_estado"
    if (headers[0] && headers[0]._estado)
        error = headers[0]._estado
    else {
        // Advertir en consola cuando no concuerden la cantidad de estados recibidos vs esperados
        if (headers.length !== estadosEsperados.length) {
            console.warn(`La cantidad de _estados recibidos desde "${url}" no concuerda con lo esperado`)
            console.warn(headers, estadosEsperados)
        }

        /**
         * Comprobar que sean `true` todos los estados...
         * de lo contrario, guardar el estado en la variable `error`
         */
        estadosEsperados.some( (estadoEsperado, i) => {
            const header = headers[i]
            const valorEstadoRecibido = ( header && header[estadoEsperado] )
            if (!valorEstadoRecibido) {
                console.error(`Se esperaba recibir el index:estado -> ${i}:${estadoEsperado}`)
                error = "Hubo un error: no se recibió la información esperada";
                return true // Hubo error, salir del loop
            }
            const isError = valorEstadoRecibido !== true
            if (isError)
                error = valorEstadoRecibido;
            return isError;
        })
    }

    if (error)
        Notify(error, "error")

    return {data_tables, headers, error}
}

export default apiFetch;
import {cfetch} from "../helpers/wrappers";
import {convertObjToQueryParams} from "../helpers/utils";

export default class ProductoService {

    async filtrarProds(bodyParams, queryParams = {}) {
        const config = {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            url: `/api/productos/filter?${convertObjToQueryParams(queryParams)}`,
            body: JSON.stringify(bodyParams),
            method: "POST"
        }
        return await cfetch(config)
    }
}


import React from 'react';
import "react-moment";
import moment from "moment";
import {decimalAdjust} from "../../Global";

class TablaPDF extends React.Component {
    render() {
        const {dataKardex} = this.props

        return (
            <React.Fragment>
                <table id="tableKardex" className="table" hidden>
                    <thead>
                    <tr>
                        <th colSpan="4" rowSpan="2">
                            DOCUMENTO DE TRASLADO, COMPROBANTE DE PAGO,
                            DOCUMENTO INTERNO O SIMILAR
                        </th>

                        <th rowSpan="3">TIPO OPERACIÓN (TABLA 12)</th>

                        <th colSpan="3">
                            ENTRADAS
                        </th>
                        <th colSpan="3">
                            SALIDAS
                        </th>
                        <th colSpan="3">
                            SALDO
                        </th>
                    </tr>

                    <tr>
                        <th rowSpan="2">
                            CANTIDAD
                        </th>
                        <th rowSpan="2">
                            COSTO UNITARIO
                        </th>
                        <th rowSpan="2">
                            COSTO TOTAL
                        </th>

                        <th rowSpan="2">
                            CANTIDAD
                        </th>
                        <th rowSpan="2">
                            COSTO UNITARIO
                        </th>
                        <th rowSpan="2">
                            COSTO TOTAL
                        </th>

                        <th rowSpan="2">
                            CANTIDAD
                        </th>
                        <th rowSpan="2">
                            COSTO UNITARIO
                        </th>
                        <th rowSpan="2">
                            COSTO TOTAL
                        </th>
                    </tr>

                    <tr>
                        <th>Fecha</th>
                        <th>Tipo Documento</th>
                        <th>Serie</th>
                        <th>Número</th>
                    </tr>
                    </thead>

                    <tbody>
                    {dataKardex.items.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td>{moment(item.FechaEmision).format("DD/MM/YYYY")}</td>
                                <td>{item.TipoDocumento || ""}</td>
                                <td>{item.Serie || ""}</td>
                                <td>{item.NumeroComprobante || ""}</td>
                                <td>{item.TipoOperacion}</td>
                                <td>
                                    {item.isIn ? item.cantidad : 0}
                                </td>
                                <td>
                                    {item.isIn ? decimalAdjust('floor', item.costoUnitario, -6) : 0}
                                </td>
                                <td>
                                    {item.isIn ? decimalAdjust('floor', item.total, -6) : 0}
                                </td>
                                <td>
                                    {item.isIn ? 0 : item.cantidad}
                                </td>
                                <td>
                                    {item.isIn ? 0 : decimalAdjust('floor', item.costoUnitario, -6)}
                                </td>
                                <td>
                                    {item.isIn ? 0 : decimalAdjust('floor', item.total, -6)}
                                </td>
                                <td>
                                    {item.cantidadTotal}
                                </td>
                                <td>
                                    {decimalAdjust('floor', item.costoUnitarioTotal, -6)}
                                </td>
                                <td>
                                    {decimalAdjust('floor', item.costoTotal, -6)}
                                </td>
                            </tr>
                        )
                    })}
                    <tr>
                        <th colSpan="5">TOTALES</th>
                        <td>
                            {dataKardex.cantidadTotalEntradas}
                        </td>
                        <td></td>
                        <td>
                            {decimalAdjust('floor', dataKardex.costoTotalEntradas || "0", -6)}
                        </td>
                        <td>
                            {decimalAdjust('floor', dataKardex.cantidadTotalSalidas || "0", -6)}
                        </td>
                        <td></td>
                        <td>
                            {decimalAdjust('floor', dataKardex.costoTotalSalidas || "0", -6)}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}

export default TablaPDF

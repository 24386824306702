/**
 * Devuelve `true` si tiene permiso, y `false` si no tiene permiso
 *
 * `permisos` es una lista de permisos, y cada permiso es un Objeto que tiene 3 posibles formatos:
 * - Formato para validar un permiso CRUD
 * ```js
 *   {IdMenu: 1, Estado: "List"}
 * ```
 * `Estado` puede tener los valores: `"List"`, `"Update"`, `"Insert"`, `"Delete"`
 *
 * - Formato para validar un permiso personalizado
 * ```js
 *   {IdMenu: 1, IdPermiso: 1}
 * ```
 *
 * - Formato para solo validar acceso a un menú
 * ```js
 *   {IdMenu: 1}
 * ```
 *
 * @param {Array<Object>} permisos - Lista de permisos normalizados que se van a validar
 * @param {Array<Object>} roles - Lista de roles con todos sus permisos
 * @param {Number} IdEmpresa - La empresa sobre la cual se van a validar los permisos
 */
export const comprobarPermisos = (permisos, roles, IdEmpresa) => {
    return permisos.every(permiso => {  // Se necesita que *todos* los permisos pasen la validación
        return roles.some(rol => {  // Se necesita que al menos un rol pase la validación
            const {IdMenu, Estado, IdPermiso} = permiso;
            if (!rol.Menus[IdMenu] || !rol.Menus[IdMenu].Empresas.includes(IdEmpresa)) {
                // Si el rol o la empresa no tiene acceso al Menú
                return false
            }
            // Si llega hasta acá, la combinacion de rol y empresa tiene acceso al IdMenu.
            // Ahora, si hace falta, se va a validar el `Estado` o el `IdPermiso` (solo uno o ninguno, pero no ambos)
            if (Estado) {
                const estaAutorizado = (rol.Menus[IdMenu][Estado])
                return estaAutorizado;
            }
            if (IdPermiso) {
                const estaAutorizado = (rol.Permisos.includes(IdPermiso))
                return estaAutorizado;
            }
            return true // Cuando el permiso solo necesita validar si se tiene acceso al IdMenu, sin validar ningún Estado o IdPermiso
        })
    })
}

/*************************************
 *        PERMISOS CONSTANTES        *
 *************************************/

/******************************
 *      PERMISOS DE MENU      *
 ******************************/
/**
 * @param {Number} IdMenu
 */
const crearPermisosDeMenu = IdMenu => {
    return {
        Menu: {IdMenu},
        List: {IdMenu, Estado: "List"},
        Read: {IdMenu, Estado: "List"},     // Igual que `List`, pero este es compatible con terminología CRUD
        Insert: {IdMenu, Estado: "Insert"},
        Create: {IdMenu, Estado: "Insert"}, // Igual que `Insert`, pero este es compatible con terminología CRUD
        Update: {IdMenu, Estado: "Update"},
        Delete: {IdMenu, Estado: "Delete"}
    }
}
export const Configuracion_Master = crearPermisosDeMenu(1);
export const Empresa_Master = crearPermisosDeMenu(2);
export const Registro_Master = crearPermisosDeMenu(3);
export const Gestion_Master = crearPermisosDeMenu(4);
export const Sucursal_Master = crearPermisosDeMenu(22);
export const Almacenes_Master = crearPermisosDeMenu(23);
export const Almacenes = crearPermisosDeMenu(43);
export const Empleados_Master = crearPermisosDeMenu(24);
export const Modulos_Master = crearPermisosDeMenu(28);
export const Gestion_de_Usuarios_y_Permisos_Master = crearPermisosDeMenu(5);
export const Roles_Master = crearPermisosDeMenu(6);
export const Usuarios_Master = crearPermisosDeMenu(7);
export const Configuraciones = crearPermisosDeMenu(8);
export const Empresas = crearPermisosDeMenu(9);
export const Empleados = crearPermisosDeMenu(10);
export const Usuarios = crearPermisosDeMenu(11);
export const Clientes = crearPermisosDeMenu(12);
export const Productos = crearPermisosDeMenu(13);
export const Documentos = crearPermisosDeMenu(14);
export const Gestion_de_Turnos = crearPermisosDeMenu(25);
export const Puntos_de_Ventas = crearPermisosDeMenu(26);
export const Venta = crearPermisosDeMenu(15);
export const Venta_Rapida = crearPermisosDeMenu(16);
export const Preventa = crearPermisosDeMenu(17);
export const Facturacion = crearPermisosDeMenu(18);
export const Resumen = crearPermisosDeMenu(19);
export const Caja = crearPermisosDeMenu(21);
export const Gestion_de_Preventas = crearPermisosDeMenu(27);
export const Preventas_no_Generadas = crearPermisosDeMenu(29);
export const Registro_de_Ventas = crearPermisosDeMenu(20);
export const Vales = crearPermisosDeMenu(30);
export const Procesar_Vales = crearPermisosDeMenu(31);
export const Facturacion_Electronica = crearPermisosDeMenu(32);
export const Procesar_Vales_no_Generados = crearPermisosDeMenu(33);
export const VehiculosPer = crearPermisosDeMenu(34);
export const Guia_de_Remision = crearPermisosDeMenu(35);
export const Notas_de_credito = crearPermisosDeMenu(36);
export const Dashboard = crearPermisosDeMenu(37);
export const TransportistasPer = crearPermisosDeMenu(38);
export const CreditosPer = crearPermisosDeMenu(51);
export const AccessReqPer = crearPermisosDeMenu(51);


/*******************************
 *   PERMISOS PERSONALIZADOS   *
 *******************************/
    // export const Permiso_De_Ejemplo = {IdPermiso: 0};
export const AperturarCaja = {IdPermiso: 5};
export const ChangeSucursal = {IdPermiso: 6};
export const ImprimirA4 = {IdPermiso: 7};
export const ChangePriceProd = {IdPermiso: 8};
export const ChangeTotalDet = {IdPermiso: 9};
export const PermSobreControlAcceso = {IdPermiso: 10};


import React from "react";
import "../styles/Almacen.css";
import { notificationDarDeAlta, notificationDarDeBaja } from "./AlmacenNotify";
import { confirmAlert } from "react-confirm-alert";
import { BotonLetrasVerdeOscuro, BotonLetrasRojo } from "../Plantillas/Botones";

function DarBajaAlmacen(IdAlmacen, Nombre, history) {
  confirmAlert({
    title: `¿Dar de baja definitivamente al Almacén: "${Nombre}"?`,
    buttons: [
      {
        label: "Sí",
        onClick: () => {
          notificationDarDeBaja();
          fetch(`/api/almacenes/AlmacenDelete/${IdAlmacen}`);
          history.push("/almacenes");
        }
      },
      {
        label: "No"
      }
    ]
  });
}

function DarAltaAlmacen(IdAlmacen, Nombre, history) {
  confirmAlert({
    title: `¿Dar de Alta al Almacén: "${Nombre}"?`,
    buttons: [
      {
        label: "Sí",
        onClick: () => {
          notificationDarDeAlta();
          fetch(`/api/almacenes/AlmacenAlta/${IdAlmacen}`);
          history.push("/almacenes");
        }
      },
      {
        label: "No"
      }
    ]
  });
}

export function CambiarEstadoAlmacen(Estado, IdAlmacen, Nombre, history) {
  switch (Estado) {
    case "Inactivo":
      return (
        <React.Fragment>

          <BotonLetrasVerdeOscuro 
            type="button"
            onClick={() => DarAltaAlmacen(IdAlmacen, Nombre, history)}
          >Dar de Alta
          </BotonLetrasVerdeOscuro>

        </React.Fragment>
      );
    default:
      return (
        <React.Fragment>

          <BotonLetrasRojo
            type="button"
            onClick={() => DarBajaAlmacen(IdAlmacen, Nombre, history)}
          >Dar de Baja
          </BotonLetrasRojo>
          
        </React.Fragment>
      );
  }
}

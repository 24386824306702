import React from "react";
import $ from "jquery";
import {TIPO_STOCK} from "../../Global";
import {extractTwoDecimalsForNumber} from "../../helpers/calcularVenta";
import {BotonAddPequeño, BotonDown, BotonMenosPequeño, BotonRemovePequeño} from "../Plantillas/Botones";

export default class DetalleCotizaciones extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cantidadInput: 1
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let divCarrito = $("#tableDetProdPreventa");
        let rows = $("#IdDetProductoCarrito").find('tr');
        if (rows.length >= 6)
            divCarrito.scrollTop(divCarrito.prop('scrollHeight'))
    }

    render() {
        return (
            <div id="tableDetProdPreventa" className="preventa__carrito">
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th className="tdDetallePreventa" style={{fontSize: '20px'}}>
                            <div className='inputDet'>CANTIDAD</div>
                            <div className='inputDet'>/ PRECIO</div>
                            <div className='inputDet'>/ TOTAL</div>
                            <div className='inputDet'/>
                        </th>
                    </tr>
                    </thead>
                    <tbody id="IdDetProductoCarrito">
                    {
                        this.props.detalles.map(det => {
                            return (
                                <tr
                                    key={`det-${det.IdPresentacion}`}
                                    bgcolor={det.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE ? "" : "#f78b2d"}
                                    className="trDetallePreventa"
                                    onKeyUp={(e) => this.props.onSecretKeyPress(e, det)}
                                >
                                    <td style={{padding: 0}}>
                                        <div>
                                            <div style={{fontSize: "14px"}} className="itemNombre">
                                                <div>
                                                    <strong>
                                                        <p className="fw-bolder">
                                                            {det.descripcion} {det.unidadMedida}
                                                        </p>
                                                    </strong>
                                                </div>
                                                <div>

                                                    <select
                                                        required
                                                        disabled={this.props.load}
                                                        className="mt-1 mr-1"
                                                        value={det.Precio}
                                                        onChange={(e) => this.props.PrecioChange(e, det)}
                                                    >
                                                        <option value={det.PrecioMenor}>
                                                            Menor/Lista
                                                        </option>
                                                        <option value={det.PrecioFamiliar}>
                                                            Familiar
                                                        </option>
                                                        <option value={det.PrecioEspecial}>
                                                            Especial
                                                        </option>
                                                    </select>

                                                    Precio Costo:
                                                    {det.PrecioCosto
                                                        .toLocaleString("en-GB", {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        })}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tdDetallePreventa" key={`div-det-${det.id}`}>

                                            <div className="inputDet">
                                                <input
                                                    onClick={(e) => {
                                                        e.target.select()
                                                    }}
                                                    readOnly={this.props.load}
                                                    type="number"
                                                    style={{backgroundColor: "rgb(53,96,90,0.18)"}}
                                                    step="1"
                                                    min="1"
                                                    name="Cantidad"
                                                    value={det.cantidad}
                                                    className="inputCantidadDet"
                                                    onChange={e => this.props.InputCantidadDetalleChange(e, det)}
                                                    onBlur={e => this.props.onBlurInputCantidad(e, det)}
                                                />
                                            </div>

                                            <div className="inputDet">
                                                <input
                                                    onClick={(e) => {
                                                        e.target.select()
                                                    }}
                                                    readOnly={this.props.load}
                                                    type="number"
                                                    style={{backgroundColor: "#01a59c", color: "white"}}
                                                    step="1"
                                                    min="0"
                                                    value={extractTwoDecimalsForNumber(det.precioVenta)}
                                                    onChange={e => this.props.InputPrecioVentaChange(e, det)}
                                                    name="PrecioVenta"
                                                    className="inputPrecioVentaDet"
                                                    onBlur={e => this.props.onBlurPrecioVenta(e, det)}
                                                />
                                            </div>

                                            <div className="itemTotal">
                                                {det.Simbolo}
                                                {extractTwoDecimalsForNumber(det.total)}
                                            </div>

                                            <div>

                                                <div>
                                                    <BotonMenosPequeño
                                                        disabled={this.props.load}
                                                        className="ml-2"
                                                        type="button"
                                                        title="Disminuir en 1"
                                                        onClick={() =>
                                                            this.props.RestarCantidadDet(det)
                                                        }
                                                    />

                                                    <BotonAddPequeño
                                                        disabled={this.props.load}
                                                        className="ml-2"
                                                        type="button"
                                                        title="Aumentar en 1"
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            this.props.AgregarCantidadDet(det)
                                                        }}
                                                    />

                                                    <BotonRemovePequeño
                                                        disabled={this.props.load}
                                                        className="ml-2"
                                                        type="button"
                                                        title="Quitar item"
                                                        onClick={() =>
                                                            this.props.RemoveProductoInList(det)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        );
    }

}

import React from "react";
import "./styles/PageError.css";

class PageError extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <div className="alert alert-danger PageError">
          <i className="fa fa-bug"></i>
          <h4>Error:</h4>
          <h6>{this.props.error.message}</h6>
          <p>{this.props.error.stack}</p>
        </div>
      </>
    );
  }
}
export default PageError;

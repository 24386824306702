import React from "react";
import AsyncSelect from "react-select/async/dist/react-select.esm";

export default class ProductoPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        }
        this.handleProductoChange = this.handleProductoChange.bind(this);
        this.searchProductos = this.searchProductos.bind(this);
    }


    searchProductos(query) {
        const idSucursal = this.props.idSucursal ? `idSucursal=${this.props.idSucursal}` : "";
        return new Promise((resolve, reject) => {
            this.setState({isLoading: true})
            fetch(`/api/productos?search=${query}&${idSucursal}`)
                .then(response => response.json())
                .then(json => json.productos)
                .then(prods => {
                    const mappedProds = prods.map(p => {
                        return {
                            ...p,
                            label: `${p.nombre} ${p.unidad_de_medida}`,
                            value: p.id
                        }
                    })
                    this.setState({isLoading: false})
                    resolve(mappedProds)
                }).catch(reject);
        })
    }

    handleProductoChange(e) {
        this.props.onChangeProducto(e)
    }

    render() {
        return (
            <div className="form-group">
                <label className="">Busque los productos</label>
                <AsyncSelect
                    isClearable={true}
                    defaultOptions={this.props.initialLoad ? this.props.initialLoad : false}
                    onChange={this.handleProductoChange}
                    loadOptions={this.searchProductos}
                    isLoading={this.state.isLoading}
                    placeholder={"Buscar productos"}
                />
            </div>
        )
    }
}

import React from "react";
import "react-moment";
import "../styles/Sucursal.css";
import { validarInputsSucursal } from "./SucursalValidator";
import {notificationAdd,notificationError} from './SucursalNotify'
import SucursalForm from './SucursalForm'
import PageLoading from "../PageLoading.js";
import Header from "../../componentes/Header";
import parent_icon_header from "../../componentes/images/icons8-barcode-52.png";
import child_icon_header from "../../componentes/images/icons8-add-property-501white.png";

class AddSucursal extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      error: null,
      IdEmpresa: "",
      IdEmpleado: "",
      Nombre: "",
      IdUbigeo: "",
      Direccion: "",
      Telefono: "",
      Celular: "",
      CorreoElectronico: "",
      Codigo:"",
      Errores: {
        Nombre: "",
        Telefono: "",
        Celular: "",
        CorreoElectronico: ""
      },
      data: {
        ubigeos: []
      },
      dataEmpleados:[],
      dataEmpresas: [],
      empleadosPorEmpresa:[],
      ubigeoReg: [],
      ubigeoProv: [],
      ubigeoDis: [],
      valueRegion: "01",
      valueProvincia: "01",
      valueDistrito: "01",
      ubigeo: "------"
    };
    this.getUbigeo = this.getUbigeo.bind(this);
    this.getEmpleados=this.getEmpleados.bind(this)
    this.getEmpresas = this.getEmpresas.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.RegionChange = this.RegionChange.bind(this);
    this.newSucursal = this.newSucursal.bind(this);
    this.ProvinciaChange = this.ProvinciaChange.bind(this);
    this.DistritoChange = this.DistritoChange.bind(this);
    this.traerEmpleadoPorEmpresa=this.traerEmpleadoPorEmpresa.bind(this)
    this.traerUbigeoPorRegion=this.traerUbigeoPorRegion.bind(this)
    this.traerUbigeoPorProvincia=this.traerUbigeoPorProvincia.bind(this)
    this.traerUbigeoPorDistrito=this.traerUbigeoPorDistrito.bind(this)
  }

    componentDidMount = () =>{
      this.getEmpresas();
      this.getEmpleados();
      this.getUbigeo();
    }

    getEmpresas = async () =>{
      this.setState({loading:true, error:null})
      try {
          const response = await fetch('/api/empresas');
          const data = await response.json();
          const empresas=data.empresas.filter(act=>{
            return act.Estado=='Activo'
          });
          this.setState({
              loading: false,
              dataEmpresas: empresas
          })
      }catch(error){
          this.setState({loading: false, error: error})
      }
      //console.log(this.state.dataEmpresas)
    }

    getEmpleados = async () =>{
      this.setState({loading:true, error:null})
      try {
          const response = await fetch('/api/empleados/master');
          const data = await response.json();
          const empleados=data.empleados.filter(act=>{
            return act.Estado=='activo'
          });
          this.setState({
              loading: false,
              dataEmpleados: empleados
          })
          await Promise.all([
            this.traerEmpleadoPorEmpresa(this.state.IdEmpresa)
          ])
      }catch(error){
          this.setState({loading: false, error: error})
      }
      //console.log(this.state.dataEmpleados)
    }

    getUbigeo = async () => {
        this.setState({loading:true, error:null})
        try {
            const response = await fetch('/api/ubigeos/ubigRegion');
            const data = await response.json();
            this.setState({
                loading: false,
                data: data,
                valueRegion: data.ubigeos[0].CodRegion, 
                valueProvincia: data.ubigeos[0].CodProvincia, 
                IdUbigeo: data.ubigeos[0].IdUbigeo, 
                valueDistrito: data.ubigeos[0].IdUbigeo
            })
            await Promise.all([
                this.traerUbigeoPorRegion(), 
                this.traerUbigeoPorProvincia(data[0].CodRegion), 
                this.traerUbigeoPorDistrito(data[0].CodRegion,data[0].CodProvincia)
           ])
        }catch(error){
            this.setState({loading: false, error: error})
        }
        
    }

    RegionChange = (event) => {
      this.setState({valueRegion: event.target.value })
      this.traerUbigeoPorProvincia(event.target.value);
      this.traerUbigeoPorDistrito(event.target.value,this.state.ubigeoProv[0].CodProvincia);
  }

    ProvinciaChange = (event) => {
      this.setState({valueProvincia: event.target.value,
        IdUbigeo: event.target.value,
        valueDistrito: event.target.value  })
      this.traerUbigeoPorDistrito(this.state.valueRegion, event.target.value );
      //this.getUbigeoById(event.target.value)
    }

    DistritoChange = (event) =>{
      this.setState({IdUbigeo: event.target.value,
        valueDistrito: event.target.value
      })
      this.getUbigeoById(event.target.value)
    }

    //para el label de ubigeo
    getUbigeoById(idUbigeo){
      fetch(`/api/ubigeos/ubigId/${idUbigeo}`)
      .then(res => res.json())
      .then(data => {
        //console.log(data[0].Ubigeo);
        this.setState({
          ubigeo:data[0].Ubigeo
        });
      })
    }
   

    newSucursal(e){
      this.setState({ loading: true, error: null });
      let valido = true
      Object.values(this.state.Errores).forEach(val=>{
        val.length>1 && (valido=false)
      })
      if (valido) {
        try {
          notificationAdd()
          fetch("/api/sucursales/add", {
            method: "POST",
            body: JSON.stringify({
              IdEmpresa:this.state.IdEmpresa,
              IdEmpleado:this.state.IdEmpleado,
              Nombre:this.state.Nombre,
              IdUbigeo:this.state.IdUbigeo,
              Direccion:this.state.Direccion,
              Telefono:this.state.Telefono,
              Celular:this.state.Celular,
              CorreoElectronico:this.state.CorreoElectronico,
              Codigo:this.state.Codigo
            }),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          })
            .then(res => res.json())
            .then(data => console.log(data))
            .catch(err => console.log(err));
            this.props.history.push('/sucursales')
        } catch (error) {
          this.setState({
            loading: false,
            error: error
          });  
        }
        
      }else {
        //notificacion de error
        notificationError()
      }

      e.preventDefault();
    }
    
    traerUbigeoPorRegion = () =>{

        this.state.ubigeoReg = this.state.data.ubigeos.filter((ubigeoR) => {
            return ubigeoR.CodProvincia === '00' && ubigeoR.CodDistrito === '00'
        })
    
    }

    traerUbigeoPorProvincia = (idRegion) =>{

        this.state.ubigeoProv = this.state.data.ubigeos.filter((ubigeoP) => {
            return ubigeoP.CodRegion === idRegion && ubigeoP.CodProvincia != '00' && ubigeoP.CodDistrito === '00'
        })

        this.setState({valueProvincia: this.state.ubigeoProv[0].CodProvincia})
        //console.log(this.state.ubigeoProv)
    }
    
    traerUbigeoPorDistrito = (idRegion,idProvincia) => {

        this.state.ubigeoDis = this.state.data.ubigeos.filter((ubigeoD) => {
            return ubigeoD.CodRegion ===  idRegion && ubigeoD.CodProvincia === idProvincia && (ubigeoD.Ubigeo != null)
        })
        
        this.setState({
          valueDistrito: this.state.ubigeoDis[0].IdUbigeo, 
          IdUbigeo: this.state.ubigeoDis[0].IdUbigeo,
          //para el label de ubigeo
          ubigeo:this.state.ubigeoDis[0].Ubigeo
        })
    }

    traerEmpleadoPorEmpresa(IdEmpresa){

      this.state.empleadosPorEmpresa = this.state.dataEmpleados.filter((empleadosP) => {
        return empleadosP.IdEmpresa == IdEmpresa
      })
      //si hay empleados hace el setstate
      if (this.state.empleadosPorEmpresa[0]) {
        this.setState({IdEmpleado: this.state.empleadosPorEmpresa[0].IdEmpleado})
      }
    }
    
    handleChange(e) {
      e.preventDefault();
      const { name, value } = e.target;
      this.setState({
        [name]: value
      })

      let Errores = this.state.Errores;

      //mensajes de validacion
      validarInputsSucursal(Errores, name, value);

      this.setState({ Errores, [name]: value });
    }

    EmpleadoChange = (e) => {

      this.setState({IdEmpleado: e.target.value })
      //console.log(e.target.value);
    }
  
    EmpresaChange = (e) => {
      this.setState({IdEmpresa: e.target.value })
      //console.log(e.target.value);
      this.traerEmpleadoPorEmpresa(e.target.value)
    }

  render() {
    if (this.state.loading === true) {
      return <PageLoading />;
    }

    return (
      <React.Fragment>

        <SucursalForm 
          onChange={this.handleChange}
          onSubmit={this.newSucursal}
          RegionChange={this.RegionChange}
          ProvinciaChange={this.ProvinciaChange}
          DistritoChange={this.DistritoChange}
          EmpleadoChange={this.EmpleadoChange}
          EmpresaChange={this.EmpresaChange}
          state={this.state}
        />
      </React.Fragment>
    )
  }
}

export default AddSucursal;

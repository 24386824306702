import React from "react";
import moment from "moment";
import {decimalAdjust} from "../../Global";

export default class TablaPagoMovComponent extends React.Component {

    render() {
        const {pagos, onProcesar, onEliminar, readOnly, onChangeFields, isLoading, fechaEditable} = this.props;

        return (
            <table className="table table-hover">
                <thead>
                <tr>
                    <th scope="col">Fecha</th>
                    <th scope="col">Cuota</th>
                    <th scope="col">Monto cuota</th>
                    <th scope="col">Total pagado</th>
                    <th scope="col">Banco/Descripción</th>
                    <th scope="col">Núm. Movimiento</th>
                    <th scope="col">Total pendiente</th>
                    <th scope="col">Opciones</th>
                </tr>
                </thead>
                <tbody>

                {pagos.map((p, i) => {
                    if (p.estado === "PENDIENTE" && !readOnly)
                        return (
                            <tr key={`pago-${p.id}`}>
                                <td>
                                    {Boolean(fechaEditable) ?
                                        <input name="fechaPago" type="date"
                                               onChange={(e) => onChangeFields(e, p)}
                                               value={moment(p.fechaPago).format('YYYY-MM-DD')}/> :
                                        `${moment(p.fechaPago).format('DD/MM/YYYY')}`
                                    }
                                </td>
                                <td>{i + 1}</td>
                                <td>
                                    {decimalAdjust('floor', p.monto, -2)}
                                </td>
                                <td>
                                    <input name="totalPagado"
                                           min={0}
                                           onChange={(e) => onChangeFields(e, p)}
                                           type="number"
                                           value={p.totalPagado}/>
                                </td>
                                <td>
                                    <textarea name="observacion" cols="30"
                                              onChange={e => onChangeFields(e, p)}>{p.observacion}</textarea>
                                </td>
                                <td>
                                    <input name="numMov" type="text"
                                           onChange={(e) => onChangeFields(e, p)}/>
                                </td>
                                <td>
                                    {decimalAdjust('floor', p.saldo, -2)}
                                </td>
                                <td>
                                    <button className="btn btn-link" disabled={isLoading}
                                            onClick={(e) => onProcesar(e, p)}>
                                        Procesar
                                    </button>
                                    {p.wasFromMov ? null : (
                                        <button className="btn btn-link" disabled={isLoading}
                                                onClick={(e) => onEliminar(e, p)}>
                                            Eliminar
                                        </button>)}
                                </td>
                            </tr>
                        );
                    return (
                        <tr key={`pago-${p.id}`}>
                            <td>{moment(p.fechaPago).format('DD/MM/YYYY')}</td>
                            <td>{i + 1}</td>
                            <td>{decimalAdjust('floor', p.monto, -2)}</td>
                            <td>{p.totalPagado}</td>
                            <td>{p.observacion && p.observacion !== 'null' ? p.observacion : "-"}</td>
                            <td>{p.numMov && p.numMov !== 'null' ? p.numMov : "-"}</td>
                            <td>{decimalAdjust('floor', p.saldo, -2)}</td>
                            <td>-</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }
}

import React from "react";
import {notificarError} from "../Almacenes/AlmacenNotify";
import {isNumber} from "../../helpers/utils";
import {PAGO_CREDITO} from "../../Global";
import {isNumberValidator} from "../../helpers/validators";
import moment from "moment";

export default class CreditForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tiposPagos: [],
            payload: {cantDias: 0, cantCuotas: 0},
            isLoading: false,
            showCredit: false,
        }
        this.savePagos = this.savePagos.bind(this);
        this.addPayments = this.addPayments.bind(this);
        this.getPagosCreditos = this.getPagosCreditos.bind(this);
    }

    async componentDidMount() {
        await this.fetchTiposPagos();
    }

    async fetchTiposPagos() {
        this.setState({isLoading: true});
        const res = await fetch('/api/movimientosalmacen/tipos-pagos')
        if (res.ok)
            this.setState({tiposPagos: await res.json()})
        else
            notificarError("Ha ocurrido un error al momento de listar los tipos de pago.")
        this.setState({isLoading: false});
    }

    async savePagos() {
        const [isValid, msg] = this.validatePayload(this.state.payload);
        if (isValid) {
            this.setState({isLoading: true})
            await this.addPayments(this.props.selectedMov.IdMovimientoAlmacen)
            await this.props.onSubmit(this.props.selectedMov)
        } else
            notificarError(msg)
    }

    async addPayments(idMov) {
        let pagos = String(this.state.payload.idTipoPago) === String(PAGO_CREDITO) ? this.getPagosCreditos(idMov) : [
            [
                idMov,
                this.state.payload.idTipoPago,
                Number(this.props.selectedMov.Total),
                "PAGADO",
                '',
                moment(new Date()).format('YYYY-MM-DD'),
                true
            ]
        ]
        try {
            await this.createPago(pagos);
        } catch (e) {
            notificarError('Ha ocurrido un error al momento de registrar los pagos');
        }
    }

    getPagosCreditos(idMov) {
        let pagos = [];
        const monto = Number(this.props.selectedMov.Total) / this.state.payload.cantCuotas;
        let initialDate = moment(new Date()).add(this.state.payload.cantDias, 'days');
        for (let i = 0; i < this.state.payload.cantCuotas; i++) {
            pagos.push([
                idMov,
                this.state.payload.idTipoPago,
                monto,
                "PENDIENTE",
                '',
                initialDate.format('YYYY-MM-DD'),
                true
            ])
            initialDate = moment(initialDate).add(this.state.payload.cantDias, 'days');
        }
        return pagos;
    }

    async createPago(pago) {
        let res = await fetch("/api/movimientosalmacen/pagos", {
            method: "POST",
            body: JSON.stringify({
                DetallesPagos: [...pago]
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
        if (!res.ok)
            throw "error";
    }

    validatePayload(payload) {
        let validateRules = [
            {attr: 'idTipoPago', rules: [isNumberValidator], field: 'tipo de pago'},
            {attr: 'cantDias', rules: [isNumberValidator, this.isValidQuantity], field: 'cantidad de días'},
            {attr: 'cantCuotas', rules: [isNumberValidator, this.isValidQuantity], field: 'cantidad de cuotas'},
        ];
        for (let i = 0; i < validateRules.length; i++) {
            const rule = validateRules[i];
            const ruleFuncs = rule.rules;
            for (let j = 0; j < ruleFuncs.length; j++) {
                const [isValid, msg] = ruleFuncs[j]([payload[rule.attr], rule.field, payload])
                if (!isValid)
                    return [isValid, msg];
            }
        }
        return [true, ''];
    }

    isValidQuantity(args) {
        const [attr, field, payload] = args;
        if (String(payload.idTipoPago) === String(PAGO_CREDITO)) {
            const isValid = Number(payload.cantCuotas) > 0 && Number(payload.cantDias) > 0;
            return [isValid, isValid ? '' : 'Las cuotas y los días deben ser mayores que 0.']
        }
        return [true, ''];
    }

    render() {
        return (
            <div style={{width: "600px"}}>
                <div className="row">
                    <div className="col-sm-auto">
                        Total: {this.props.selectedMov.Total}
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col form-group">
                        <label>Tipo de pago</label>
                        <select value={this.state.payload.idTipoPago} className="form-control" onChange={e =>
                            this.setState({
                                showCredit: String(e.target.value) === String(PAGO_CREDITO),
                                payload: {
                                    ...this.state.payload,
                                    idTipoPago: e.target.value,
                                    cantDias: String(e.target.value) === String(PAGO_CREDITO) ? this.state.payload.cantDias : 0,
                                    cantCuotas: String(e.target.value) === String(PAGO_CREDITO) ? this.state.payload.cantCuotas : 0,
                                }
                            })
                        }>
                            <option value="">Seleccionar</option>
                            {this.state.tiposPagos.map(t =>
                                <option value={t.IdTipoPago} key={`pago_${t.IdTipoPago}`}>{t.Nombre}</option>)}
                        </select>
                    </div>
                    {this.state.showCredit ? (
                        <>
                            <div className="col form-group">
                                <label>Cantidad de días</label>
                                <input className="form-control" type="number" value={this.state.payload.cantDias}
                                       onClick={e => e.target.select()}
                                       onChange={e => {
                                           this.setState({
                                               payload: {
                                                   ...this.state.payload,
                                                   cantDias: isNumber(e.target.value) ? e.target.value : 0
                                               }
                                           })
                                       }}/>
                            </div>
                            <div className="col form-group">
                                <label>Cantidad de cuotas</label>
                                <input className="form-control" type="number" value={this.state.payload.cantCuotas}
                                       onClick={e => e.target.select()}
                                       onChange={e =>
                                           this.setState({
                                               payload: {
                                                   ...this.state.payload,
                                                   cantCuotas: isNumber(e.target.value) ? e.target.value : 0
                                               }
                                           })
                                       }/>
                            </div>
                        </>
                    ) : null}
                </div>
                <div className="row mt-3">
                    <div className="col-sm">
                        <button disabled={this.state.isLoading} onClick={this.savePagos}
                                className="btn btn-outline-primary">
                            {this.state.isLoading ? 'Cargando' : 'Procesar'}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

import React from 'react'
import SelectSearch from "react-select-search";
import "../../pages/styles/SelectSearch.css";
import ReactTable from "react-table";
import DetalleOrdenCompraItem from "./DetalleOrdenCompraItem"
import {BotonDeletePequeno} from '../Plantillas/Botones';


function ListItems(props) {
    return (
        <ReactTable
            data={props.dataDetalleOC}
            columns={[
                {
                    Header: "Producto",
                    accessor: "Nombre"
                },
                {
                    Header: "Precio",
                    accessor: "PrecioReferencial",
                    Cell: row => <div style={{textAlign: "right"}}>{row.value}</div>

                },
                {
                    Header: "Cantidad",
                    accessor: "Cantidad",
                    Cell: row => <div style={{textAlign: "right"}}>{row.value}</div>
                },
                {
                    Header: "Total",
                    accessor: "Total",
                    Cell: row => <div style={{textAlign: "right"}}>{row.value}</div>
                },
                {
                    Header: "Acciones",
                    accessor: "IdDetalleOC",
                    Cell: (f) => {
                        //
                        return (
                            <div align='center'>
                                <BotonDeletePequeno
                                    title="Eliminar"
                                    type='button'
                                    onClick={
                                        () => props.handleEliminarItem(
                                            f.original)
                                    }
                                />
                            </div>
                        )
                    },
                },
            ]}

            defaultPageSize={20}
            showPagination={false}
            style={{
                height: "250px",
                width: "100%",
                fontSize: "14px"
            }}
            className="-striped -highlight"
        />

    )
}

export default ListItems;

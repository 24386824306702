import React from 'react'
import "react-table/react-table.css";
import {notificarMsg} from "../Almacenes/AlmacenNotify";
import AsyncSelect from "react-select/async/dist/react-select.esm";


class NotasCDListRVSearch extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            venta: {},
        }
        this.onChangeVenta = this.onChangeVenta.bind(this);
    }

    onChangeVenta(venta) {
        this.setState({venta: venta})
    }

    searchComprobantes(query) {
        return new Promise((resolve, reject) => {
            fetch(`/api/notascd/listrv/1/?search=${query}`)
                .then(r => r.json())
                .then(ventas => {
                    const mappedVentas = ventas.map(v => {
                        return {
                            ...v,
                            label: `${v.Serie} - ${v.NumeroComprobante} - ${v.FechaEmision}`,
                            value: v.IdRegistroVenta
                        }
                    });
                    resolve(mappedVentas)
                }).catch(reject)
        })
    }

    ClickSeleccionar = () => {
        const {venta} = this.state;
        if (venta && Object.keys(venta).length) {
            this.props.form.SerieCE = venta.Serie;
            this.props.form.NumeroCE = venta.NumeroComprobante;
            this.props.form.IdTurnoCaja = venta.IdTurnoCaja;
            this.props.form.IdRegistroVenta = venta.IdRegistroVenta;
            this.props.form.FechaEmisionCE = venta.FechaEmision;
            this.props.onClose();
        } else
            notificarMsg('Debe seleccionar una venta.', 'warning')
    }

    render() {
        if (this.state.error)
            return `Error: ${this.state.error.message}`;

        if (this.state.loading === true && this.state.dataLista === undefined)
            return <p>cargando...</p>;

        return (
            <div style={{width: "500px", height: "300px"}}>
                <div className="form-row">
                    <div className="col">

                        <AsyncSelect
                            defaultOptions={true}
                            onChange={this.onChangeVenta}
                            value={this.state.venta}
                            isClearable={true}
                            loadOptions={this.searchComprobantes}
                        />

                        <button onClick={this.ClickSeleccionar}
                                className="mt-3 btn btn-outline-primary">Seleccionar
                        </button>
                    </div>
                </div>
            </div>
        )
    }

}

export default NotasCDListRVSearch

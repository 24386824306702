import {
  TelefonoRegex,
  CelularRegex,
  CorreoRegex
} from "./SucursalesRegexValidator";

export function validarInputsSucursal(Errores, name, value) {
  switch (name) {
    case 'Telefono':
      Errores.Telefono = TelefonoRegex.test(value)
      ? ''
      : '*El número de Teléfono sólo debe contener números';
      break;
    case 'Celular':
      Errores.Celular=CelularRegex.test(value)
      ? ''
      : '*Formato inválido'
      break;
    case 'CorreoElectronico':
      Errores.CorreoElectronico=CorreoRegex.test(value)
      ? ''
      : '*Formato de correo inválido'
      break;
    default:
      break;
  }
}

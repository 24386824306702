import React from 'react'
import ReactDOM from 'react-dom'
import './visor.css'

function ClienteModalPDF (props) {
    if(!props.isOpen){
        return null
    }
    return ReactDOM.createPortal(
        <div className="VisorPDFModal" tabIndex='-1'>
                <div className="VisorPDFModal__container">
                    <div className="VisorPDFModal__header">
                        <h5 className="VisorPDFModal__header-title">{props.title}</h5>
                        <button className="VisorPDFModal__close" onClick={props.onClose}>X</button>
                    </div>
                    <div className="VisorPDFModal__body">
                        {props.children}
                    </div>
                    <div className="VisorPDFModal__footer">
                        {/* <button className="btn btn-secondary" onClick={props.onClose}>Cancelar</button>
                        <button className="btn btn-primary" onClick={props.onSubmit} >Aceptar</button> */}
                    </div>
                </div>
        </div>
        ,
        
        document.getElementById('modal')
    );
}

export default ClienteModalPDF





// import React, { useEffect } from 'react'
// import ReactDOM from 'react-dom'
// import jsPDF from 'jspdf';  
// import html2canvas from 'html2canvas';

// window.html2canvas = html2canvas;

// function printDocument (nomFile) {  
//     const input = document.getElementById('pdfdiv');  
    
    
//     // var doc = new jsPDF('p', 'mm', 'a4');

//     // doc.html(input, {
//     //     callback: function (doc) {
//     //     doc.save(nomFile);
//     //     }
//     // });


//     // doc.fromHTML(
//     //     input, 
//     //     3, // x coord
//     //     3, 
//     //     {
//     //         'width': '1200'
//     //     },
//     //     function (dispose) {
//     //         doc.save(nomFile);
//     //     }
//     // );

//     // return doc;


//     return html2canvas(input,{
//         width: 1200,
//         height: 1200,
        
//     }).then((canvas) => {  
//         var imgWidth = 190;//190;  
//         // var pageHeight = 290; //290;  
//         var imgHeight = canvas.height * imgWidth / canvas.width;  
//         // var heightLeft = imgHeight;  
//         var context = canvas.getContext('2d')
//         const imgData = canvas.toDataURL('image/png');  
//         // const imgData = canvas.toDataURL('img/png')
//         // var imgWidth = canvas.width
//         // var imgHeight = canvas.height
//         const pdf = new jsPDF('p', 'mm', 'a4')  
//         var position = 10;//10;  
//         // var heightLeft = imgHeight;  
//         pdf.addImage(imgData, 'JPEG',10, position, imgWidth, imgHeight);  
//         pdf.save(nomFile); 
        
//         // // const imgData = canvas.toDataURL('image/png');

//         // const pdf = new jsPDF('p', 'mm', 'a4');
//         // pdf.html(input, {
//         //     html2canvas: {canvas: canvas},
//         //     callback: function (pdf) {
//         //         pdf.save(nomFile);
//         //     }
//         // });
//         // pdf.save(nomFile);



//       });        
// }


// function ModalPDF (props) {
    
//     useEffect( () => { printDocument(props.nombreFile) },  [] );
//     useEffect( props.onClose );
    
//     const comp = (
//                 <div className="container" id="pdfdiv">
//                     {props.children}
//                 </div>
//     );

//     return ReactDOM.createPortal(comp, document.getElementById('modal'));
// }

// export default ModalPDF
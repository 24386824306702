import React from 'react';
import TransportistasForm from './TransportistasForm.js';
import {Link} from 'react-router-dom';
import { BotonGuardarLetras, BotonCancelar } from "../Plantillas/Botones";

class TransportistasAdd extends React.Component{

    constructor(){
        super()
        this.state={
            IdEmpleado:"",
            dataEmpleados:[],
            Categoria:"",
            NumeroLicencia:"",
            Loading:true,
        }
        this.newTransportista = this.newTransportista.bind(this)
    }
    
    componentDidMount() {
        this.getEmpleados();
        //setInterval(this.listaClientes,5000);
    }

    async getEmpleados() {
        await fetch("/api/transportistas/empleados")
          .then(response => response.json())
          .then(data => {this.setState({ dataEmpleados: data.empleados, isLoading: false }); 
        //   console.log(data)
        })
          .catch(error => this.setState({ error, isLoading: false }));
        //console.log(this.state.data)
    }
    handleEmpleadoChange(e){
        //console.log(e.value) 
            this.state.IdEmpleado = e.value 
    }

    ListaEmpleados = (empleados) =>{
        return empleados.map(empleado => {
            //console.log(producto)
            return { name: empleado.Apellidos.concat(', ',empleado.Nombres), value: empleado.IdEmpleado };
        });
    }
    handleChangeUnicos = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
        // console.log(e.target.name, e.target.value)
    };

    newTransportista = () => {
        try{
        // console.log("Registrado")
        fetch('/api/transportistas/add',{
                method:'POST',
                body:JSON.stringify({
                    IdEmpleado: this.state.IdEmpleado,
                    Categoria: this.state.Categoria,
                    NumeroLicencia: this.state.NumeroLicencia,
                }),
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                }
            })
            .then(res => res.json())
            .then(data => console.log(data))
            .catch(err => console.log(err));
            this.props.history.push('/Transportistas')
        } catch (error) {
            this.setState({
              loading: false,
              error: error
            });
          }
    }

    render(){
        return(
            <React.Fragment>
                <div className="ml-4 mt-4">
                    <BotonCancelar to="/transportistas"/>
                </div>
                    <form onSubmit={this.newTransportista} className="d-flex justify-content-center">
                        <div className="almacenForm">
                            <div className="AlmacenBoton">
                                <BotonGuardarLetras type="submit"/>
                            </div>
                            <TransportistasForm
                                state={this.state}
                                handleChangeUnicos={this.handleChangeUnicos}
                                ListaEmpleados={this.ListaEmpleados}
                                handleEmpleadoChange={this.handleEmpleadoChange}
                            />
                            {/* <div align="center">
                                <button className="btn btn-primary" type="submit">Guardar <i className="ml-1 fa fa-save"></i></button>
                            </div> */}
                        </div>
                    </form>
            </React.Fragment>
        )
        
    }

}

export default TransportistasAdd;
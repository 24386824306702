import React from 'react';
import './RolPermisosMenuItem.css'
import CheckboxToggle from '../CheckboxToggle';

/**
 * Este es el componente que representa una fila por cada menú en el formulario de Roles.
 * Aquí es donde estan los checkbox para activar/desactivar los permisos,
 * por ejemplo el de acceso a un menú, los permisos **CRUD** y los permisos personalizados.
 */
const RolPermisosMenuItem = ({
                                RolPermisosMenu,
                                RolMenusStates,
                                RolPermisosState,
                                setRolMenusStates,
                                setRolPermisosState,
                                disabledStates,
                            }) => {

    const {IdMenu, Nombre, Permisos, children} = RolPermisosMenu;
    const {Estado, Insert, Update, List, Delete} = RolMenusStates[IdMenu];

	const handleRolMenuStateBuilder = campo => {
		return event => {
			const newRolMenuState = {...RolMenusStates};
			newRolMenuState[IdMenu][campo].newState = event.target.checked;
			setRolMenusStates(newRolMenuState);
		}
	}

	const handleRolPermisoStateBuilder = IdPermiso => {
		return event => {
			const newRolPermisosState = {...RolPermisosState};
			newRolPermisosState[IdPermiso].newState = event.target.checked;
			setRolPermisosState(newRolPermisosState);
		}
    }

    /**
     * Funcion recursiva que activa todos los `estadosActivar` en el `RolPermisosMenu` y en todos sus hijos, nietos, etc.
     * @param {Object} RolPermisosMenu - Formato: `{IdMenu, children}`
     * @param {Object} RolMenuStatesClon - Aquí se van acumulando los cambios. Inicialmente es un clon de `RolMenusStates`.
     * @param {Array<String>} estadosActivar - Ej: ["Estado", "List"]. Lista de nombres de los estados que se van a habilitar
     * @return {Object} clon de `RolMenusStates` pero con los cambios ya hechos.
     */
    const ActivarEstadosYSubEstados = (RolPermisosMenu, RolMenuStatesClon, estadosActivar) => {
        const {IdMenu, children} = RolPermisosMenu
        estadosActivar.forEach(campo => {
			RolMenuStatesClon[IdMenu][campo].newState = true;
        })
        children.forEach( RolPermisosMenuChild =>
            ActivarEstadosYSubEstados(RolPermisosMenuChild, RolMenuStatesClon, estadosActivar)
        )
    }

    const handleActivarTodosBuilder = (estadosActivar) => {
        return () => {
            const newRolMenuStates = {...RolMenusStates};
            ActivarEstadosYSubEstados(RolPermisosMenu, newRolMenuStates, estadosActivar);
            setRolMenusStates(newRolMenuStates);
        }
    }

    /**
     *
     * @param {Boolean} checked - Estado del checkbox
     * @param {Boolean} disabled - Deshabilitar o no el checkbox
     * @param {Function} onChange - Handler del evento onChange del checkbox
     * @param {Function} onClick - Handler del evento onClick del botón para "Activar todos"
     * @param {String} tooltipText - **default:null** Este string se usará para mostrar un tooltip en el boton de *Activar todos*
     */
    const renderCheckbox = (checked, disabled, onChange, onClick=()=>{}, tooltipText=null) => {
        const showOnHover = (!disabled && tooltipText) && "show-onhover"
        return  (
            <div className="checkbox d-flex align-items-stretch">
                <CheckboxToggle
                    {...{
                        checked,
                        onChange,
                        disabled,
                    }}
                />
                <div className="align-self-center" style={{position: "relative"}}>
                    <button title={tooltipText || undefined} onClick={onClick} className={`btn btn-sm ${showOnHover}`} >
                        <i className="fas fa-check-double"/>
                    </button>
                </div>
            </div>
        )
    }

    const isMenuDisabled = disabledStates.Estado || !Estado.newState
    const collapseAttributes = children.length>0 && {
                                                        role:"button",
                                                        href: `#collapse${IdMenu}`,
                                                        "data-toggle": "collapse",
                                                        "aria-controls":`collapse${IdMenu}`
                                                    }
    return (
        <div className={"d-flex justify-content-between p-1 rolpermisosmenuitem"}>
            <div className="d-flex menu-nombre-width">
                {renderCheckbox(
                    Estado.newState,
                    disabledStates.Estado,
                    handleRolMenuStateBuilder("Estado"),
                    handleActivarTodosBuilder(["Estado"]),
                    (children.length>0) && `Activar todos los menús dentro de "${Nombre}"`
                )}
                <a className="d-flex align-self-stretch align-items-center w-100 ml-1" {...collapseAttributes} >
                    <span className={isMenuDisabled ? "disabled" : ''} title={IdMenu}>{Nombre}</span>
                </a>
            </div>
            <div className="d-flex justify-content-around menu-state-width">
                {renderCheckbox(
                    List.newState,
                    disabledStates.List,
                    handleRolMenuStateBuilder("List"),
                    handleActivarTodosBuilder(["List"]),
                    (children.length>0) && `Activar todos los "Ver" dentro de "${Nombre}"`
                )}
                {renderCheckbox(
                    Insert.newState,
                    disabledStates.Insert,
                    handleRolMenuStateBuilder("Insert"),
                    handleActivarTodosBuilder(["Insert"]),
                    (children.length>0) && `Activar todos los "Insertar" dentro de "${Nombre}"`
                )}
                {renderCheckbox(
                    Update.newState,
                    disabledStates.Update,
                    handleRolMenuStateBuilder("Update"),
                    handleActivarTodosBuilder(["Update"]),
                    (children.length>0) && `Activar todos los "Editar" dentro de "${Nombre}"`
                )}
                {renderCheckbox(
                    Delete.newState,
                    disabledStates.Delete,
                    handleRolMenuStateBuilder("Delete"),
                    handleActivarTodosBuilder(["Delete"]),
                    (children.length>0) && `Activar todos los "Eliminar" dentro de "${Nombre}"`
                )}
            </div>
            <div className="permisos-width">
                {Permisos.map( ({IdPermiso, Nombre}, index) => {
                    const {newState} = RolPermisosState[IdPermiso]
                    return (
                        <div key={IdPermiso} className={`d-flex justify-content-between ${index===0 ? "" : "border-top" /* No aplicar borde en el primer permiso */}`} >
                            <span className={isMenuDisabled ? "disabled" : ''} title={IdPermiso}>{Nombre}</span>
                            {renderCheckbox(
                                newState,
                                isMenuDisabled,
                                handleRolPermisoStateBuilder(IdPermiso),
                            )}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default RolPermisosMenuItem
import React from 'react';
import PageLoading from "../PageLoading.js";
import ReactTable from "react-table";
import matchSorter from "match-sorter";
import {BotonBuscar} from '../Plantillas/Botones.js';

class ModalBuscarCliente extends React.Component {
    constructor() {
        super();
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.listaClientes();
    }

    listaClientes = async () => {
        try {
            // const response = await fetch(`/api/clientes/${this.state.buscar}`);
            const response = await fetch(`/api/clientes/emp`);
            const data = await response.json();
            //console.log(data)
            const clientes = data[1].filter(act => {
                return act.Estado == 'Activo'
            })
            //console.log(clientes)
            this.setState({
                data: clientes
            });
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        /* SI HUBO ERROR AL CARGAR LA DATA MOSTRAMOS EL ERROR */
        /* ================================================== */
        if (this.state.error) {
            return `Error: ${this.state.error.message}`;
        }

        /* MOSTRAMOS UN PRE-LOAD AL CARGAAR LA DATA */
        /* ================================================== */
        if (this.state.loading === true && this.state.data === undefined) {
            return <PageLoading/>;
        }
        return (
            <React.Fragment>
                <div className="p-5">
                    <ReactTable
                        //  width='100%'
                        data={this.state.data}
                        filterable
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value}
                        columns={[
                            {
                                columns: [
                                    {
                                        Header: "Código",
                                        id: "NroTipoDocumento",
                                        headerClassName: "",
                                        width: 150,
                                        accessor: d => d.NroTipoDocumento,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["NroTipoDocumento"]
                                            }),
                                        filterAll: true
                                    },

                                    {
                                        Header: "Cliente",
                                        id: "NombreCliente",
                                        // accessor: "NombreCliente",
                                        // headerClassName:'card-header bg-dark text-white',
                                        width: 300,
                                        accessor: d => d.NombreCliente,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["NombreCliente"]}),
                                        filterAll: true,
                                    },
                                    {
                                        Header: "Acción",
                                        filterable: false,
                                        headerClassName: "",
                                        width: 80,
                                        Cell: original => {
                                            return (
                                                <BotonBuscar
                                                    title="Escoger Cliente"
                                                    onClick={() => this.props.GetCliente(original.original)}
                                                />
                                            );
                                        }
                                    },

                                ]
                            },
                        ]}
                        defaultPageSize={5}
                        showPagination={false}
                        //pageSize={10}
                        className="-striped -highlight"
                        // Text de paginación
                        previousText='Anterior'
                        nextText='Siguiente'
                        loadingText='Cargando...'
                        noDataText='No se encontraron registros'
                        pageText='Página'
                        ofText='de'
                        rowsText='filas'
                        // width='100%'
                        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                        style={{
                            fontSize: "15px"
                        }}

                    />
                </div>

            </React.Fragment>
        )
    }
}

export default ModalBuscarCliente;

import React from "react";
import '../styles/tabla.css';
import EmpresaSolOseTabla from './EmpresaSolOseTabla.js';

class EmpresaSolOse extends React.Component {
    
    render(){
        return (
            <React.Fragment>
                <form onSubmit={this.props.addOrEditTipoDocumentoSunat}>
                    <div className="row form-group col-12 ">
                        <label className="col-2">Tipo documento</label>
                        <select required className="btn btn-outline-dark dropdown-toggle col-10" aria-haspopup="true" name="tipoDocumento" value={this.props.state.IdTipoDocumento} onChange={this.props.tipoDocumentoChange} >{
                            this.props.state.dataTD.tipoDocumento.map((obj) => {
                            return <option key={obj.IdTipoDocumentoSunat} value={obj.IdTipoDocumentoSunat}>{obj.Codigo + " - "}{obj.Descripcion}</option> 
                        })}</select>
                    </div>

                    <div className="row form-group col-12 ">
                        <label className="col-2">Proveedor </label>
                        <select required id="Proveedor" className="btn btn-outline-dark dropdown-toggle col-3" name="Proveedor" onChange={this.props.handleChangeProveedor} value={this.props.state.Proveedor} aria-haspopup="true" aria-expanded="false" >
                            <option value="Sunat" >Sunat</option>
                            <option value="OSE" >OSE</option>
                        </select>

                        <label className="col-1">User</label>
                        <input  name="UsuarioProveedor" 
                                className="empresa_input form-control col-2" 
                                onChange={this.props.handleChange} 
                                value={this.props.state.UsuarioProveedor} 
                                placeholder="Usuario" 
                                type="text" 
                                required/>

                        <label className="col-1">Clave</label>
                        <input  name="ClaveProveedor" 
                                className="empresa_input form-control col-2" 
                                onChange={this.props.handleChange} 
                                value={this.props.state.ClaveProveedor} 
                                placeholder="Clave " 
                                type={this.props.state.type}
                                required/>
                        <button className="col-1 bg-dark text-white text-center" onClick={this.props.showHide}>{this.props.state.type === 'text' ? 'Oculto' : 'Ver'}</button>
                    </div>
                    
                    <div className="row form-group col-12 ">
                        <label className="col-2">Ruta</label>
                        <input  name="Ruta" 
                                className="empresa_input form-control col-3" 
                                onChange={this.props.handleChange} 
                                value={this.props.state.Ruta} 
                                placeholder="Ruta" 
                                type="text" 
                                required/>
                        <button className="btn btn-primary m-2" type="submit">Añadir</button>
                    </div>
                    
                </form>
                <EmpresaSolOseTabla
                    state = {this.props.state}
                    filtrarInfoParaEditar = {this.props.filtrarInfoParaEditar}
                />
            </React.Fragment>
        );
    }
}
export default EmpresaSolOse;
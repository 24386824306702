import React from "react"
import DatePicker from "react-datepicker";
import MovimientoAlmacenDetalle from './MovimientoAlmacenDetalle'
import NumericInput from 'react-numeric-input';
import ListaOC from '../Compras/OrdenCompraListSearch'
import Modal from '../clientes/ClienteModal';
import ModalProveedor from "../clientes/ClienteModal";

import {
    BotonAddProveedor,
    BotonAddPequeño,
    BotonSearchProveedor
} from '../Plantillas/Botones'
import ProveedorNuevo from "../Proveedores/ProveedorNuevo";
import ProveedoresListaSearch from "../Proveedores/ProveedoresListaSearch";
import {decimalAdjust, ID_TIPO_MOV_ALMACEN} from "../../Global";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import moment from "moment";


class MovimientoAlmacenForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            modalLOCIsOpen: false,
            loading: true,
            error: null,
            EsCompraLocal: false,
            IdOrdenCompra: null,
        }
        this.searchProducts = this.searchProducts.bind(this);
    }

    handleCloseModalLOC = (e) => {
        this.setState({
            modalLOCIsOpen: false
        })
    }

    handleOpenModalLOC = (e) => {
        this.setState({
            modalLOCIsOpen: true
        })
    }

    searchProducts(search) {
        const tipoDoc = this.props.form.tipoDoc;
        return new Promise((resolve, reject) => {
            fetch(`/api/ordenescompra/listprod/382?tipoDoc=${tipoDoc.Codigo}&search=${search}`)
                .then(r => r.json())
                .then(prods => resolve(prods))
                .catch(reject)
        })
    }

    render() {
        let totalExoneradas = Number(this.props.form.totalExoneradas);
        let totalInafectas = Number(this.props.form.totalInafectas);
        let tmpTotal = this.props.form.vTotal - (totalExoneradas + totalInafectas)

        let base = tmpTotal / 1.18;
        let igv = base * 0.18;
        let total = this.props.form.Total;

        let precioCosto = this.props.form.Precio;
        let precioCostoIgv = precioCosto * 1.18;

        let precioUnidadDolar = this.props.form.precioUnidadDolar;
        let precioUnidadDolarIgv = this.props.form.precioIgvDolar;

        let tipoDoc = this.props.form.tipoDoc;

        let infoSerie = tipoDoc && tipoDoc.datosSerie ? tipoDoc.datosSerie.split(",") : null;

        let dataSerie = {
            serie: infoSerie && this.props.accion !== "Editar" && tipoDoc.canEditSerie ? infoSerie[1] : this.props.form.nroSerie,
            num: infoSerie && this.props.accion !== "Editar" && tipoDoc.canEditSerie ? Number(infoSerie[2]) + 1 : this.props.form.NroDocumentoSunat
        }

        const isEgreso = String(this.props.form.IdTipoMovimientoAlmacen) === String(ID_TIPO_MOV_ALMACEN.SALIDA)
            || String(this.props.form.IdTipoDocumentoSunat) === "52";

        return (
            <div>
                <hr width="1080px"/>
                <form>

                    <ul className="nav nav-tabs" id="pills-tab" role="tablist">
                        <li className="nav-item">
                            <a className={`nav-link ${this.props.activeItems ? '' : 'active'}`} id="pills-proveedor-tab"
                               data-toggle="pill"
                               href="#pills-proveedor" role="tab" aria-controls="pills-proveedor" aria-selected="true">
                                Datos
                                Movimiento Almacen</a>
                        </li>
                        <li className="nav-item">
                            <a className={this.props.accion === 'Editar' ? (this.props.activeItems ? "nav-link active" : "nav-link") : "nav-link disabled"}
                               id="pills-items-tab" data-toggle="pill" href="#pills-items" role="tab"
                               aria-controls="pills-items" aria-selected="false">Lista de Items</a>
                        </li>
                    </ul>

                    <div className="tab-content" id="pills-tabContent">
                        <div
                            className={this.props.activeItems ? 'tab-pane p-3' : 'tab-pane fade show active p-3'}
                            id="pills-proveedor" role="tabpanel"
                            aria-labelledby="pills-proveedor-tab">

                            <div className="form-row">
                                <div className="form-group col-md-2">
                                    <label htmlFor="FechaMovimiento">Fecha Movimiento</label>
                                    <input type="date"
                                           value={moment(this.props.form.FechaMovimiento).format("YYYY-MM-DD")}
                                           className="form-control"
                                           onChange={this.props.handleChangeFechaMovimiento}/>
                                </div>
                                <div className="form-group col-md-2">
                                    <label htmlFor="IdTipoMovimientoAlmacen">Tipo Movimiento</label>
                                    <select className="selectpicker form-control"
                                            id="IdTipoMovimientoAlmacen"
                                            value={this.props.form.IdTipoMovimientoAlmacen}
                                            onChange={this.props.handleChangeTipoMovimiento}
                                    >
                                        {this.props.dataTipoMovimiento.map(item => {
                                            return (
                                                <option key={item.IdTipoMovimientoAlmacen}
                                                        value={item.IdTipoMovimientoAlmacen}
                                                        title={item.Tipo}>{item.DescripcionCorta}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="form-group col-md-4">


                                    <label htmlFor="IdTipoDocumentoSunat">Documento Asociado</label>
                                    <select className="selectpicker form-control"
                                            id="IdTipoDocumentoSunat"
                                            value={this.props.form.IdTipoDocumentoSunat || ""}
                                            onChange={this.props.handleChangeTipoDocumentoSunat}
                                    >
                                        {this.props.dataTipoDocumentoSunat.map(item => {
                                            return (
                                                <option width="20px" key={item.IdTipoDocumentoSunat}
                                                        value={item.IdTipoDocumentoSunat}
                                                        title={item.Descripcion}>{`0${item.Codigo} ${item.Descripcion}`}</option>
                                            )
                                        })}
                                    </select>


                                </div>

                                <div className="form-group col-md-2">
                                    <label htmlFor="NroSerie">Serie</label>
                                    <input name="nroSerie"
                                           id="NroSerie"
                                           className="form-control"
                                           onChange={this.props.handleChangeForm}
                                           type="text"
                                           min="1"
                                           disabled={tipoDoc ? tipoDoc.canEditSerie : true}
                                           value={dataSerie.serie}
                                    />
                                </div>

                                <div className="form-group col-md-2">
                                    <label htmlFor="NroDocumentoSunat"> Nro Documento</label>
                                    <input name="NroDocumentoSunat"
                                           id="NroDocumentoSunat"
                                           className="form-control"
                                           onChange={this.props.handleChangeForm}
                                           type="text"
                                           disabled={tipoDoc ? tipoDoc.canEditSerie : true}
                                           value={dataSerie.num}
                                    />
                                </div>
                            </div>

                            <div className="form-row">

                                <div className="form-group col-md-2">
                                    <label htmlFor="tipoCambio">Tipo de cambio</label>
                                    <input name="tipoCambio"
                                           id="tipoCambio"
                                           className="form-control"
                                           onClick={e => e.target.select()}
                                           onChange={this.props.handleChangeTipoCambio}
                                           type="text"
                                           value={this.props.form.tipoCambio}
                                    />
                                </div>

                                {
                                    this.props.form.EsCompraLocal ?
                                        <div className="input-group col-md-3">
                                            <label> Nro Orden Compra</label>
                                            <div className="input-group">
                                                <input name="NroDocumentoSunat"
                                                       className="form-control"
                                                       onChange={this.props.handleChangeForm}
                                                       type="text"
                                                       value={this.props.form.NroOrdenCompra || ""}
                                                       disabled
                                                />
                                                <div className="form-group-append mb-3">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-secondary "
                                                        onClick={this.handleOpenModalLOC}
                                                        title='buscar orden de compra'
                                                    >
                                                        <i className="fas fa-search"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }

                            </div>

                            <div className="form-row">
                                <div className="form-group col-md">
                                    <label htmlFor="IdTipoTransaccionAlmacen">Proveedor</label>
                                    <select className="selectpicker form-control"
                                            id="IdProveedor"
                                            value={this.props.form.IdProveedor || ""}
                                            onChange={this.props.handleChangeProveedor}
                                    >
                                        <option
                                            key={this.props.form.IdProveedor}
                                            value={null}
                                        >SELECCIONAR
                                        </option>
                                        {this.props.dataListaProveedores.map(item => {
                                            return (
                                                <option
                                                    key={item.IdProveedor}
                                                    value={item.IdProveedor}
                                                    title={item.NombreComercial}
                                                >{item.NombreComercial}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>

                                <div className="form-group col-md">
                                    <BotonAddProveedor
                                        type="button"
                                        name="BtnAddProveedor"
                                        className="mr-2 mt-3"
                                        onClick={this.props.handleClickNuevoProveedor}
                                        title="agregar proveedor"
                                    />
                                    <BotonSearchProveedor
                                        type="button"
                                        name="BtnAddProveedor"
                                        className="mt-3"
                                        onClick={this.props.handleClickBuscarProveedor}
                                        title="buscar proveedor"
                                    />
                                </div>

                            </div>

                            <div className="form-row">
                                <div className="col">
                                    <label>Glosa</label>
                                    <textarea type="text"
                                              name="Glosa"
                                              className="form-control"
                                              value={this.props.form.Glosa || ""}
                                              onChange={this.props.handleChangeForm}
                                    />
                                </div>
                            </div>

                        </div>

                        <div
                            className={this.props.activeItems ? 'tab-pane fade show active p-3' : 'tab-pane p-3'}
                            id="pills-items" role="tabpanel"
                            aria-labelledby="pills-items-tab">
                            <div className="form-row">
                                <div className="col-sm-12">
                                    <label>Buscar producto</label>
                                    <AsyncSelect
                                        isClearable={true}
                                        onChange={this.props.handleChange_SelectSearch}
                                        value={this.props.prod}
                                        loadOptions={this.searchProducts}
                                    />
                                </div>
                            </div>

                            <div className="form-row mt-3">

                                <div className="col-sm">
                                    <label>Precio x UND ($) sin IGV</label>
                                    <input
                                        disabled={isEgreso}
                                        className="form-control form-control-sm"
                                        name="precioUnidadDolar"
                                        min={0}
                                        max={10000}
                                        step={1}
                                        type="number"
                                        onClick={e => e.target.select()}
                                        onChange={e => this.props.handleChangePrecioUnidadDolar(e.target.value)}
                                        value={decimalAdjust('floor', precioUnidadDolar, -6)}
                                    />
                                </div>

                                {this.props.showPrecioSolIgv ? (
                                    <div className="col-sm">
                                        <label>Precio x UND ($) con IGV</label>
                                        <input
                                            disabled={isEgreso}
                                            className="form-control form-control-sm"
                                            min={0}
                                            max={10000}
                                            step={1}
                                            type="number"
                                            onClick={e => e.target.select()}
                                            onChange={e => this.props.handleChangePrecioDolarIgv(e.target.value)}
                                            value={decimalAdjust('floor', precioUnidadDolarIgv, -6)}
                                        />
                                    </div>
                                ) : null}

                                <div className="col-sm">
                                    <label> Precio Costo sin IGV</label>
                                    <NumericInput
                                        disabled={isEgreso}
                                        className="form-control form-control-sm"
                                        name="Precio"
                                        min={0}
                                        max={10000}
                                        step={1}
                                        precision={6}
                                        onClick={e => e.target.select()}
                                        onChange={this.props.handleChangePrecio}
                                        value={precioCosto}
                                    />
                                </div>

                                {this.props.showPrecioSolIgv ? (
                                    <div className="col-sm">
                                        <label>Precio costo con IGV</label>
                                        <NumericInput
                                            disabled={isEgreso}
                                            className="form-control form-control-sm"
                                            min={0}
                                            max={10000}
                                            step={1}
                                            onChange={this.props.handleChangePrecioIgv}
                                            precision={6}
                                            onClick={e => e.target.select()}
                                            value={precioCostoIgv}
                                        />
                                    </div>
                                ) : null}

                                <div className="col-sm">
                                    <label> Cantidad</label>
                                    <NumericInput
                                        className="form-control form-control-sm"
                                        name="Cantidad"
                                        min={0}
                                        max={10000}
                                        step={1}
                                        precision={3}
                                        onChange={this.props.handleChangeCantidad}
                                        onClick={e => e.target.select()}
                                        value={this.props.form.Cantidad}
                                    />
                                </div>

                            </div>

                            <div className="form-row mt-3">
                                <div className="form-group col-md">

                                    <label htmlFor="IdTipoTransaccionAlmacen">Tipo Transacción</label>
                                    <select className="selectpicker form-control"
                                            id="IdTipoTransaccionAlmacen"
                                            value={this.props.form.IdTipoTransaccionAlmacen}
                                            onChange={this.props.handleChangeTipoTransaccion}
                                    >
                                        <option key={"opt_null_tipo_transaccion"} value="">Seleccionar</option>
                                        {this.props.dataTipoTransaccion.map(item => {
                                            return (
                                                <option key={item.IdTipoTransaccionAlmacen}
                                                        value={item.IdTipoTransaccionAlmacen}
                                                        title={item.Descripcion}>{item.DescripcionCorta}</option>
                                            )
                                        })}
                                    </select>

                                </div>

                                <div className="col-sm mt-4">
                                    <BotonAddPequeño
                                        title="agregar item"
                                        type="button"
                                        onClick={this.props.handleAgregarItem}
                                        disabled={!this.props.form.IdMovimientoAlmacen}
                                    />
                                </div>
                            </div>

                            <div className="form-row mt-3">
                                <MovimientoAlmacenDetalle
                                    data={this.props.dataDetalleMovimientoAlmacen}
                                    handleEliminarItem={this.props.handleEliminarItem}
                                />
                            </div>

                            <div className="form-row mt-1">
                                <div className="col">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-around p-2">
                                                <p> {`Base: ${decimalAdjust('floor', base, -1)}`} </p>
                                                <p> {`Total Exoneradas: ${totalExoneradas}`} </p>
                                                <p> {`Total inafectas: ${totalInafectas}`} </p>
                                                <p> {`IGV: ${decimalAdjust('floor', igv, -2)}`} </p>
                                                <p> {`Total: ${decimalAdjust('floor', total, -2)}`} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="form-row justify-content-center m-3">
                        <button type="button" className="btn btn-secondary mr-2"
                                onClick={this.props.handleCloseModalMA}>Cancelar
                        </button>
                        <button type="button" className="btn btn-info"
                                onClick={this.props.handleSubmit}>{this.props.accion == 'Nuevo' ? 'Crear MA' : 'Actualizar MA'}</button>
                    </div>

                </form>

                <Modal isOpen={this.state.modalLOCIsOpen} onClose={this.handleCloseModalLOC} width='900px'
                       title="Lista Ordenes de Compra">
                    <ListaOC
                        onClose={this.handleCloseModalLOC}
                        form={this.props.form}
                    />
                </Modal>

                {
                    this.props.form.accion === 'NuevoProveedor' ?
                        <ModalProveedor isOpen={this.props.form.modalProveedorIsOpen}
                                        onClose={this.props.handleCloseModalProv}
                                        title="NUEVO PROVEEDOR">
                            <ProveedorNuevo
                                onClose={this.props.handleCloseModalProv}
                                NumeroDocumento={this.props.form.Ruc}
                                AsignarValorDoc={this.props.AsignarValorDoc}
                                sw='oc_nuevo'
                            />
                        </ModalProveedor> :

                        <ModalProveedor isOpen={this.props.form.modalProveedorIsOpen}
                                        onClose={this.props.handleCloseModalProv}
                                        title="BUSCAR PROVEEDOR">
                            <ProveedoresListaSearch
                                onClose={this.props.handleCloseModalProv}
                                form={this.props.form}
                            />
                        </ModalProveedor>
                }

            </div>
        )
    }
}

export default MovimientoAlmacenForm

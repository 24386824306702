import React from "react";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";

export default class CuotaUpdater extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cuotas: props.cuotas || [],
            loading: false,
            total: props.cuotas ? props.cuotas.map(d => Number(d.monto)).reduce((a, b) => a + b, 0) : 0
        }
        this.onChangeCuota = this.onChangeCuota.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (String(this.props.idVenta) !== String(prevProps.idVenta))
            this.fetchCuotas(this.props.idVenta)
    }

    componentDidMount() {
        if (!Boolean(this.props.disabled))
            this.fetchCuotas(this.props.idVenta)
    }

    onChangeCuota(e) {
        const tmpCuotas = [...this.state.cuotas]
        const cuota = tmpCuotas.find(c => String(c.idPago) === String(e.target.getAttribute('data-attr-id')))
        cuota[e.target.getAttribute('name')] = e.target.value
        this.setState({cuotas: tmpCuotas}, () => this.props.onChangeCuotas(tmpCuotas))
    }

    fetchCuotas(idVenta) {
        this.setState({isLoading: false})
        fetch(`/api/ventas/cuotas/${idVenta && idVenta !== "undefined" ? idVenta : "0"}`)
            .then(async res => [await res.json(), res.status])
            .then(d => {
                const [data, status] = d
                if (status === 200)
                    this.setState({
                        cuotas: data,
                        total: data.map(d => Number(d.monto)).reduce((a, b) => a + b, 0)
                    }, () => this.props.onChangeCuotas(data))
                else
                    notificarMsg("Ah ocurrido otra respuesta al momento de extraer los pagos", 'warning')
            })
            .catch(() => notificarError("Ah ocurrido un error al momento de extraer los pagos"))
            .finally(() => this.setState({isLoading: false}))
    }

    render() {
        if (this.state.isLoading)
            return "Cargando cuotas"
        return (
            <div className={this.props.className || ""}>
                <label>Total de venta: {this.state.total}</label> <br/>
                <label>Total de cuotas: {this.state.cuotas.map(c => Number(c.monto)).reduce((a, b) => a + b, 0)}</label>
                <br/>
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Monto</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.cuotas.map((p, i) => {
                        return <tr key={p.idPago}>
                            <td>{i + 1}</td>
                            <td>
                                <input className="form-control"
                                       disabled={Boolean(this.props.disabled)}
                                       data-attr-id={p.idPago} name="fechaPago"
                                       type="date" value={p.fechaPago}
                                       onChange={this.onChangeCuota}/>
                            </td>
                            <td>
                                <input className="form-control" disabled={Boolean(this.props.disabled)}
                                       onChange={this.onChangeCuota}
                                       data-attr-id={p.idPago} name="monto" type="number"
                                       value={p.monto}/>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
}

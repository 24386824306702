import React from "react"
import "./botonesaccion.css"
import {convertStrToJson, doDownload} from "../../Global";

const BotonesAccion = (props) => {
    let data = props.rowsel;
    const tipoDoc = data.Tipo === "NC" ? "07" : "06";
    const oldXmlUrl = `${process.env.REACT_APP_FACT_BASE_URL}/datos/${data.Ruc}/xml/${tipoDoc}/${data.Ruc}-${tipoDoc}-${data.SerieCD}-${data.NumeroCE.replace(/^0+/g, '')}.xml`;
    const oldCdrUrl = `${process.env.REACT_APP_FACT_BASE_URL}/datos/${data.Ruc}/xml/${tipoDoc}/R-${data.Ruc}-${tipoDoc}-${data.SerieCD}-${data.NumeroCE.replace(/^0+/g, '')}.zip`;

    let resEnvioSunat = convertStrToJson(data.respuestaAnulacionSunat)
    let xml = resEnvioSunat.xmlFirmado && resEnvioSunat.xmlFirmado.length ? resEnvioSunat.xmlFirmado : oldXmlUrl;
    let cdrZip = resEnvioSunat.cdrZip && resEnvioSunat.cdrZip.length ? resEnvioSunat.cdrZip : oldCdrUrl;

    return (
        <div align="left">
            <button
                className="btn btn-outline-primary fas fa-cloud-upload-alt mr-2"

                title="enviar SUNAT"
                onClick={
                    () => {
                        props.onclickSendSUNAT(props.rowsel)
                    }
                }
                disabled={props.rowsel.Estado !== 'PENDIENTE'}

            />
            <a href="#"  hidden={props.rowsel.Estado !== 'PENDIENTE'} onClick={e => {
                e.preventDefault();
                props.history.push(`/note/${props.rowsel.idNc}`)
            }}>Editar</a>
            <a
                className="btn btn-outline-success fas fa-download mr-2 ml-1"
                title="descarga xml"
                target="_blank"
                href={xml}
                onClick={e => {
                    e.preventDefault()
                    doDownload(xml, `${data.Ruc}-${tipoDoc}-${data.SerieCD}-${data.NumeroCE.replace(/^0+/g, '')}.xml`)
                }}
            />
            <a
                className="btn btn-outline-success fas fa-file-archive mr-2"
                title="descarga cdr"
                target="_blank"
                href={cdrZip}
            />
            <button
                className="btn btn-outline-danger fas fa-file-pdf mr-2"
                title="descarga pdf"
                onClick={
                    () => {
                        props.downloadPDF(props.rowsel)
                    }
                }
            />
            <button
                className="btn btn-outline-info fas fa-envelope-open-text mr-2"
                title="enviar correo electrónico"
                onClick={
                    () => {
                        props.enviarCorreo(props.rowsel)
                    }
                }
                disabled={props.rowsel.Estado !== 'ACEPTADA'}
            />

        </div>
    )
}

export default BotonesAccion

import React from "react";
import "react-moment";
import { validarInputsEmpleado } from "./EmpleadoValidator";
import { notificationAdd, notificationError } from "./EmpleadosNotify";
import "../styles/Empleados.css";
import EmpleadoForm from "./EmpleadoForm";
import cheerio from "cheerio";
import { BotonCancelar } from "../Plantillas/Botones";
import {getApiDniStrategies} from "../../Global";

class EmpleadoAddMaster extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      error: null,
      IdEmpresa: "",
      Nombres: "",
      Apellidos: "",
      DNI: "",
      Telefono: "",
      Celular: "",
      FechaIngreso: "",
      Correo: "",
      Direccion: "",
      Errores: {
        DNI: "",
        Nombres: "",
        Apellidos: "",
        Telefono: "",
        Celular: "",
        Correo: "",
        FechaIngreso:""
      },
      dataEmpresas: []
    };
    this.newEmpleado = this.newEmpleado.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getEmpresas = this.getEmpresas.bind(this);
  }

  componentDidMount() {
    this.getEmpresas();
  }

  getEmpresas = async () => {
    this.setState({ loading: true, error: null });
    try {
      const response = await fetch("/api/empresas");
      const data = await response.json();
      const empresas = data.empresas.filter(act => {
        return act.Estado == "Activo";
      });

      this.setState({
        loading: false,
        dataEmpresas: empresas
      });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
    //console.log(this.state.dataEmpresas)
  };

  handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });

    let Errores = this.state.Errores;

    //mensajes de validacion
    validarInputsEmpleado(Errores, name, value);

    this.setState({ Errores, [name]: value });
    //console.log(this.state);
  }

  handleSubmitDNI = async e => {
    e.preventDefault();
    this.TraerPersonaPorDNI(this.state.DNI);
  };

  TraerPersonaPorDNI = async (dni) => {
    try {
      let dniObj = getApiDniStrategies()[1];
      let data = await dniObj.getData(dni, dniObj.url);

      this.setState({
        Apellidos: data.apPaterno + " " + data.apMaterno,
        Nombres: data.nombres.join(' ')
      });

    } catch (error) {
        console.log(`Error --- >${error}`);
    }
  };

  EmpresaChange = e => {
    this.setState({ IdEmpresa: e.target.value });
    //console.log(e.target.value);
  };

  newEmpleado(e) {
    this.setState({ loading: true, error: null });
    let valido = true;
    Object.values(this.state.Errores).forEach(val => {
      val.length > 1 && (valido = false);
    });

    if (valido) {
      try {
        notificationAdd();
        fetch("/api/empleados/masterAddEmpleado", {
          method: "POST",
          body: JSON.stringify(this.state),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        })
          .then(res => res.json())
          .then(data => console.log(data))
          .catch(err => console.log(err));
        this.props.history.push("/empleados/master");
      } catch (error) {
        this.setState({
          loading: false,
          error: error
        });
      }
    } else {
      notificationError();
    }

    e.preventDefault();
  }

  render() {
    return (
      <React.Fragment>
          <div className="ml-4 mt-4">
            <BotonCancelar to="/empleados/master"/>
          </div>
          {/*formulario de empleado*/}
          <EmpleadoForm
            onChange={this.handleChange}
            onSubmit={this.newEmpleado}
            onSubmitDNI={this.handleSubmitDNI}
            state={this.state}
            volver="/empleados/master"
            Empresa={
              <div className="EmpleadoDocumento">
                <b className="ml-4">*Empresa</b>
                <div className="input__EmpleadoDiv">
                  <select
                      required
                      className="input__linea"
                      aria-haspopup="true"
                      name="empresa"
                      value={this.state.IdEmpresa}
                      onChange={this.EmpresaChange}
                  >
                      <option value="">--Seleccionar Empresa--</option>
                      {this.state.dataEmpresas.map(empresa => {
                        return (
                          <option key={empresa.ID} value={empresa.ID}>
                            {empresa.RazonSocial}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            }
            />
      </React.Fragment>
    );
  }
}

export default EmpleadoAddMaster;

import React from 'react';

import LinkButton from "../../componentes/LinkButton";
import {Link} from 'react-router-dom';
import PageLoading from '../PageLoading.js';
import jsPDF from "jspdf";
import TransportistasDeEmpresaList from './TransportistasDeEmpresaList.js';
import {BotonGuardarLetras, BotonAdd, BotonCancelar, BotonPDF, BotonExcel} from "../Plantillas/Botones";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Transportistas extends React.Component {

    constructor() {
        super()
        this.state = {
            Estado: 'activo',
            checkEstado: true,
            dataTransportistas: {
                Transportistas: []
            },
            return: "/transportistas/edit/",
            loading: false,
            error: null,
        }
        this.handleSavePDF = this.handleSavePDF.bind(this);
    }

    componentDidMount() {
        this.obtenerTransportistas()
    }


    componentWillUnmount() {
        clearInterval(this.intervalo)
    }

    obtenerTransportistas = async () => {
        // this.setState({loading:true, error:null})
        try {
            const response = await fetch('/api/transportistas/');
            const data = await response.json();
            this.setState({

                dataTransportistas: data,
                loading: true,
            })
            // console.log(data)
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }
    EstadoChange = (e) => {
        if (this.state.Estado === 'activo') {
            this.setState({
                Estado: 'inactivo',
                checkEstado: false
            });
        } else if (this.state.Estado === 'inactivo') {
            this.setState({
                Estado: 'activo',
                checkEstado: true
            });
        }
    }

    handleSavePDF(e) {
        e.preventDefault();
        let doc = new jsPDF({
            orientation: "landscape"
        });

        var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

        doc.setFontSize(12);
        doc.text("RELACION DE CONDUCTORES", 110, 10);
        // console.log(this.state.data.GuiasRemision)
        doc.autoTable({
            body: this.state.dataTransportistas.Transportistas,
            columns: [
                {header: 'EMPLEADO', dataKey: 'Empleado'},
                {header: 'DNI', dataKey: 'DNI'},
                {header: 'CATEGORIA', dataKey: 'Categoria'},
                {header: 'NUMERO DE LICENCIA', dataKey: 'NumeroLicencia'},
                {header: 'CELULAR', dataKey: 'Celular'},
                {header: 'ESTADO', dataKey: 'Estado'},
            ],
            startY: 20,
            margin: 5,
            styles: {
                overflow: "linebreak",
                lineWidth: 0.1,
                tableWidth: "auto",
                columnWidth: "auto",
                halign: "center"
            },
            theme: "plain"
        }).setFontSize(11);
        doc.setFontSize(10);
        doc.setFontType("italic");
        doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

        doc.save("relación_Conductores.pdf");
    }

    getDataExportExcel(dataSet) {
        return [
            {
                columns: [
                    {
                        title: `RELACION DE CONDUCTORES`,
                        width: {wpx: 80}
                    }
                ],
                data: []
            },
            {
                columns: [
                    {
                        title: `EMPLEADO`,
                        width: {wpx: 150}
                    },
                    {
                        title: `CATEGORIA`,
                        width: {wpx: 150}
                    },
                    {
                        title: `NUMERO DE LICENCIA`,
                        width: {wpx: 150}
                    },
                    {
                        title: `ESTADO`,
                        width: {wpx: 150}
                    },
                    {
                        title: `DNI`,
                        width: {wpx: 80}
                    },
                    {
                        title: `CELULAR`,
                        width: {wpx: 150}
                    }
                ],
                data: dataSet.map(row => {
                    return [
                        {
                            value: row.Empleado,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.Categoria,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.NumeroLicencia,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.Estado,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.DNI,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.Celular,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        }
                    ];
                })
            }
        ];
    }


    render() {
        if (this.state.loading === false) {
            return <PageLoading/>;
        }

        const transportistasFiltrados = this.state.dataTransportistas.Transportistas.filter((transportista) => {
            return transportista.Estado.includes(this.state.Estado)
        })

        const dataExport = this.getDataExportExcel(transportistasFiltrados)

        if (this.state.dataTransportistas.error) {
            return `Error: ${this.state.error.message}`;
        }
        return (
            <React.Fragment>
                <form className="d-flex justify-content-center">
                    <div className="almacenForm">
                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                {/* <input id="buscar" type="text" placeholder="Buscar" value={this.state.buscar}
                                    onChange={this.handleChange}/> */}
                                <div className="mr-4 mb-2">
                                    {/* <label>AQUI FALTA REVISAR DONDE PONER EL ACCESO A ESTA VENTANA (QUE ES LA LISTA POR EMPRESA DEL USUARIO REGISTRADO)</label> */}

                                    {/* <LinkButton title="Nuevo Transportista" className="btn btn-outline-primary ml-1 mr-1" to= "/transportistas/add"><i className="ml-1 fa fa-plus-square" ></i></LinkButton> */}
                                    {/* <button title="Guardar PDF" className="btn btn-outline-danger ml-1 mr-1" onClick={this.handleSavePDF}> <i className="fas fa-file-pdf" ></i></button> */}
                                </div>
                            </div>
                            <div className="almacenDiv">

                                <div align="right"><b>Activos</b>
                                    <label className="checkbox-toggle">
                                        <div className="toggle">
                                            <input
                                                type="checkbox"
                                                name="Estado"
                                                className="toggle-state"
                                                onChange={this.EstadoChange}
                                                defaultChecked={this.state.Estado}
                                            />
                                            <div className="toggle-inner">
                                                <div className="indicator"/>
                                            </div>
                                            <div className="active-bg"/>
                                        </div>
                                    </label><BotonAdd
                                        title="Nuevo Transportista" className="ml-3" state={this.state}
                                        to={`/transportistas/add`}
                                    />
                                    <BotonPDF
                                        hidden={this.state.mostrarButtonAdd}
                                        title="Guardar PDF"
                                        onClick={this.handleSavePDF}
                                        className="ml-3"
                                    />
                                    <ExcelFile
                                        element={
                                            <BotonExcel
                                                hidden={this.state.mostrarButtonAdd}
                                                className="ml-3"
                                                type="button"
                                                title="Guardar Excel"
                                                style={{display: `${this.state.displayButton}`}}/>
                                        }
                                        filename={`Relacion_Conductores`}
                                    >
                                        <ExcelSheet

                                            dataSet={dataExport}
                                            name="ConductoresFile"
                                        >
                                        </ExcelSheet>
                                    </ExcelFile>
                                </div>
                            </div>
                        </div>
                        <div className="mt-1">
                            <TransportistasDeEmpresaList
                                state={this.state}
                                transportistasFiltrados={transportistasFiltrados}
                            /></div>
                        {/* <div align ="right" >
                            <label>Estado de Transportistas</label>
                                <select onChange={this.EstadoChange} className="btn btn-outline-dark dropdown-toggle m-5" aria-haspopup="true" aria-expanded="false">
                                    <option value="activo">Activos</option>
                                    <option value="inactivo">Inactivos</option>
                                </select>
                                </div> */}
                    </div>
                </form>
            </React.Fragment>
        )
    }

    // }

}

export default Transportistas;

import React from 'react';
import '../styles/Empleados.css';
import PageLoading from "../PageLoading.js";
import EmpleadosList from './EmpleadosList'
import jsPDF from "jspdf";
import ReactExport from "react-data-export";
import { BotonPDF,BotonExcel, BotonAdd, BotonCancelar } from '../Plantillas/Botones';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Empleados extends React.Component{

    constructor(){
        super()
        this.state={ 
            buscarEstado:'activo',         
            buscar:"",
            data:{
                empleados:[]
            },
            loading:true,
            error:null
        }
        this.getEmpleados=this.getEmpleados.bind(this)
        this.handleChange=this.handleChange.bind(this)
        this.EstadoChange=this.EstadoChange.bind(this)
    }

    componentDidMount(){
        this.getEmpleados();
        this.intervalo=setInterval(this.getEmpleados,3000)
    }

    componentWillUnmount(){
        clearInterval(this.intervalo)
    }

    getEmpleados = async () =>{
        this.setState({loading:true, error:null})
        try {
            const response = await fetch('/api/empleados');
            const data = await response.json();
            this.setState({
                loading: false,
                data: data
            })
        }catch(error){
            this.setState({loading: false, error: error})
        }
    }

    handleChange(e){        
        this.setState({
            buscar: e.target.value
        });
    }

    EstadoChange(estado){
      this.setState({
          buscarEstado: estado      
      });
    }

    handleSavePDF(e) {
        e.preventDefault();
        let doc = new jsPDF({
          orientation: "landscape"
        });
        
        var pageHeight =
          doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth =
          doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        doc.setFontSize(14);
        doc.text(`RELACION DE EMPLEADOS ${this.state.buscarEstado.toUpperCase()}S`, 100, 10);
        doc.autoTable({
          body: this.state.data.empleados.filter((empleados) => {
            return empleados.Nombres.concat(empleados.Apellidos).toLowerCase()
                .includes(this.state.buscar.toLowerCase()) && empleados.Estado===this.state.buscarEstado
          }),
          columns:[
            {header: 'DNI', dataKey: 'DNI'},
            {header: 'NOMBRES', dataKey: 'Nombres'},
            {header: 'APELLIDOS', dataKey: 'Apellidos'},
            {header: 'TELÉFONO', dataKey: 'Telefono'},
            {header: 'CELULAR', dataKey: 'Celular'},
            {header: 'DIRECCIÓN', dataKey: 'Direccion'},
            {header: 'CORREO ELECTRÓNICO', dataKey: 'Correo'},
            {header: 'FECHA DE INGRESO', dataKey: `FechaIngreso`}
          ],
          styles: {
            fontSize: 11,
        },
        }).setFontSize(11);
        doc.setFontSize(10);
        doc.setFontType("italic");
        doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");
    
        doc.save("relación_Empleados.pdf");
    }

    getDataExportExcel(dataSet) {
        return [
          {
            columns: [
              {
                title: `RELACION DE EMPLEADOS ${this.state.buscarEstado.toUpperCase()}S`,
                width: { wpx: 80 }
              }
            ],
            data:[]
          },
          {
            columns:[
              {
                title: `DNI`,
                width: { wpx: 150 }
              },
              {
                title: `NOMBRES`,
                width: { wpx: 150 }
              },
              {
                title: `APELLIDOS `,
                width: { wpx: 150 }
              },
              {
                title: `TELÉFONO `,
                width: { wpx: 80 }
              },
              {
                title: `CELULAR  `,
                width: { wpx: 150 }
              },
              {
                title: `DIRECCIÓN`,
                width: { wpx: 150 }
              },
              {
                title: `CORREO ELECTRÓNICO`,
                width: { wpx: 150 }
              },
              {
                title: `FECHA DE INGRESO`,
                width: { wpx: 150 }
              }
            ],
            data: dataSet.map(row => {
              return [
                {
                  value: row.DNI,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.Nombres,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.Apellidos,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.Telefono,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.Celular,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.Direccion,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.Correo,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                    value: row.FechaIngreso,
                    style: {
                      fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                    }
                }
              ];
            })
          }
        ];
    }

    render(){

        const empleados = this.state.data.empleados.filter((empleados) => {
            return empleados.Nombres.concat(empleados.Apellidos).toLowerCase()
                .includes(this.state.buscar.toLowerCase()) && empleados.Estado===this.state.buscarEstado
        })
        const dataExport = this.getDataExportExcel(empleados)
        
        if (this.state.loading === true && this.state.data === undefined) {
            return (
              <React.Fragment>
                <div className="d-flex justify-content-center">
                  <label>Cargando...</label>
                  <div className="spinner-border text-primary ml-3" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </React.Fragment>
            );
        }
      
        //mientras carga
        if (this.state.data.empleados.length===0) {
            return(
                <React.Fragment>
                    <div className="p-5">
                      <div className="ProcesarBoton mt-1">
                        <BotonCancelar
                          onClick={this.props.history.goBack}
                          className="ml-4"
                        />

                        <div className="ProcesarBotones">
                          <BotonAdd
                            className = "ml-2" 
                            to="/empleados/add/" 
                            title="Nuevo Empleado"
                          />
                          <BotonPDF
                            className = "ml-2 mr-2"  
                            title="Guardar PDF"
                            onClick={(e)=>this.handleSavePDF(e)}
                          />
                          <ExcelFile
                              element={
                                  <BotonExcel type="button" title="Guardar Excel"/>
                              }
                              filename={`relación_Empleados`}
                              >
                              <ExcelSheet
                                  dataSet={dataExport}
                                  name="EmpleadosFile"
                              >
                              </ExcelSheet>
                          </ExcelFile>
                        </div>
                      </div>

                      <div className="RadioEstadoEmpl">
                        <div className="custom-control custom-radio mr-2">
                            <input
                                type="radio"
                                className="custom-control-input"
                                value={this.state.buscarEstado}
                                name="groupOfDefaultRadios"
                                id="EmpActivo"
                                checked={this.state.buscarEstado==="activo"}
                                onClick={()=>this.EstadoChange("activo")}
                            />
                            <label
                                className="custom-control-label"
                                htmlFor={"EmpActivo"}
                                style={{fontWeight:"bold"}}
                            >
                                Activos
                            </label> 
                        </div>
                        
                        <div className="custom-control custom-radio mr-2">
                          <input
                              type="radio"
                              className="custom-control-input"
                              value={this.state.buscarEstado}
                              name="groupOfDefaultRadios"
                              id="EmpInactivo"
                              checked={this.state.buscarEstado==="inactivo"}
                              onClick={()=>this.EstadoChange("inactivo")}
                          />
                          <label
                              className="custom-control-label"
                              htmlFor={"EmpInactivo"}
                              style={{fontWeight:"bold"}}
                          >
                              Inactivos
                          </label> 
                        </div>

                        <div className="custom-control custom-radio">
                            <input
                                type="radio"
                                className="custom-control-input"
                                value={this.state.buscarEstado}
                                name="groupOfDefaultRadios"
                                id="EmpSuspendido"
                                checked={this.state.buscarEstado==="suspendido"}
                                onClick={()=>this.EstadoChange("suspendido")}
                            />
                            <label
                                className="custom-control-label"
                                htmlFor={"EmpSuspendido"}
                                style={{fontWeight:"bold"}}
                            >
                                Suspendidos
                            </label> 
                        </div>
                      </div>
                            
                      <div>
                          <PageLoading />
                      </div>
                    </div>
                </React.Fragment>
            )
        }else{
            const filtrarEmpleados = this.state.data.empleados.filter((empleados) => {
                return empleados.Nombres.concat(empleados.Apellidos).toLowerCase().includes(this.state.buscar.toLowerCase()) && empleados.Estado===this.state.buscarEstado
            })
            
            if(this.state.data.error){
                return `Error: ${this.state.error.message}`;
            }

            //no hay coincidencias con la busqueda
            if (filtrarEmpleados.length === 0) {
                return(
                    <React.Fragment>
                        <div className="p-5">
                          <div className="ProcesarBoton mt-1">
                            <BotonCancelar
                              onClick={this.props.history.goBack}
                              className="ml-4"
                            />

                            <div className="ProcesarBotones">
                              <BotonAdd
                                className = "ml-2" 
                                to="/empleados/add/" 
                                title="Nuevo Empleado"
                              />
                              <BotonPDF
                                className = "ml-2 mr-2"  
                                title="Guardar PDF"
                                onClick={(e)=>this.handleSavePDF(e)}
                              />
                              <ExcelFile
                                  element={
                                      <BotonExcel type="button" title="Guardar Excel"/>
                                  }
                                  filename={`relación_Empleados`}
                                  >
                                  <ExcelSheet
                                      dataSet={dataExport}
                                      name="EmpleadosFile"
                                  >
                                  </ExcelSheet>
                              </ExcelFile>
                            </div>
                          </div>

                          <div className="RadioEstadoEmpl">
                            <div className="custom-control custom-radio mr-2">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    value={this.state.buscarEstado}
                                    name="groupOfDefaultRadios"
                                    id="EmpActivo"
                                    checked={this.state.buscarEstado==="activo"}
                                    onClick={()=>this.EstadoChange("activo")}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor={"EmpActivo"}
                                    style={{fontWeight:"bold"}}
                                >
                                    Activos
                                </label> 
                            </div>
                            
                            <div className="custom-control custom-radio mr-2">
                              <input
                                  type="radio"
                                  className="custom-control-input"
                                  value={this.state.buscarEstado}
                                  name="groupOfDefaultRadios"
                                  id="EmpInactivo"
                                  checked={this.state.buscarEstado==="inactivo"}
                                  onClick={()=>this.EstadoChange("inactivo")}
                              />
                              <label
                                  className="custom-control-label"
                                  htmlFor={"EmpInactivo"}
                                  style={{fontWeight:"bold"}}
                              >
                                  Inactivos
                              </label> 
                            </div>

                            <div className="custom-control custom-radio">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    value={this.state.buscarEstado}
                                    name="groupOfDefaultRadios"
                                    id="EmpSuspendido"
                                    checked={this.state.buscarEstado==="suspendido"}
                                    onClick={()=>this.EstadoChange("suspendido")}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor={"EmpSuspendido"}
                                    style={{fontWeight:"bold"}}
                                >
                                    Suspendidos
                                </label> 
                            </div>
                          </div>

                          <div className="mt-2">
                              <h3>No hay Resultados</h3>
                          </div>
                        </div>
                    </React.Fragment>
                )
            }
            return(
            <React.Fragment>
                <div className="p-5">
                  <div className="ProcesarBoton mt-1">
                    <BotonCancelar
                      onClick={this.props.history.goBack}
                      className="ml-4"
                    />

                    <div className="ProcesarBotones">
                      <BotonAdd
                        className = "ml-2" 
                        to="/empleados/add/" 
                        title="Nuevo Empleado"
                      />
                      <BotonPDF
                        className = "ml-2 mr-2"  
                        title="Guardar PDF"
                        onClick={(e)=>this.handleSavePDF(e)}
                      />
                      <ExcelFile
                          element={
                              <BotonExcel type="button" title="Guardar Excel"/>
                          }
                          filename={`relación_Empleados`}
                          >
                          <ExcelSheet
                              dataSet={dataExport}
                              name="EmpleadosFile"
                          >
                          </ExcelSheet>
                      </ExcelFile>
                    </div>
                  </div>

                  <div className="RadioEstadoEmpl">
                    <div className="custom-control custom-radio mr-2">
                        <input
                            type="radio"
                            className="custom-control-input"
                            value={this.state.buscarEstado}
                            name="groupOfDefaultRadios"
                            id="EmpActivo"
                            checked={this.state.buscarEstado==="activo"}
                            onClick={()=>this.EstadoChange("activo")}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor={"EmpActivo"}
                            style={{fontWeight:"bold"}}
                        >
                            Activos
                        </label> 
                    </div>
                    
                    <div className="custom-control custom-radio mr-2">
                      <input
                          type="radio"
                          className="custom-control-input"
                          value={this.state.buscarEstado}
                          name="groupOfDefaultRadios"
                          id="EmpInactivo"
                          checked={this.state.buscarEstado==="inactivo"}
                          onClick={()=>this.EstadoChange("inactivo")}
                      />
                      <label
                          className="custom-control-label"
                          htmlFor={"EmpInactivo"}
                          style={{fontWeight:"bold"}}
                      >
                          Inactivos
                      </label> 
                    </div>

                    <div className="custom-control custom-radio">
                        <input
                            type="radio"
                            className="custom-control-input"
                            value={this.state.buscarEstado}
                            name="groupOfDefaultRadios"
                            id="EmpSuspendido"
                            checked={this.state.buscarEstado==="suspendido"}
                            onClick={()=>this.EstadoChange("suspendido")}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor={"EmpSuspendido"}
                            style={{fontWeight:"bold"}}
                        >
                            Suspendidos
                        </label> 
                    </div>
                  </div>
                  
                  <div className="mt-2">
                      {/* ******************************
                      *****TABLA LISTA DE EMPLEADOS*****
                      **********************************/}
                      <EmpleadosList
                          filtrarEmpleados={filtrarEmpleados}
                          link="/empleados/edit/"
                      />
                  </div>
                </div>
            </React.Fragment>
            )
        }
    }
}

export default Empleados;
import React from 'react'
import "./styles/CheckboxToggle.css"

export default ({labelText, ...props}) => (
    <label className="checkbox-toggle">
        <div className="toggle m-1">
            <input className="toggle-state" type="checkbox" {...props} />
            <div className="toggle-inner">
                <div className="indicator"/>
            </div>
            <div className="active-bg"/>
        </div>
        {labelText}
    </label>
)
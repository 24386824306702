import React from 'react'
import { PDFDownloadLink, PDFViewer} from "@react-pdf/renderer";
import { BotonLetrasRojo } from '../../Plantillas/Botones';


const  visor = (props) =>{

    return(
        <div align="center">
            <div >
                <PDFViewer width="800" height="500">
                    {props.children}
                </PDFViewer>
            </div>
            <div>
                <PDFDownloadLink document={props.children} fileName={props.fileName}>
                        {({ blob, url, loading, error }) => (loading ? <BotonLetrasRojo>Cargando...</BotonLetrasRojo> : <BotonLetrasRojo>Descargar PDF</BotonLetrasRojo>)}
                </PDFDownloadLink>  
            </div>
        </div>  
    )
};

export default visor
import React from 'react';
import MenuItem from './MenuItem';
import "./styles/Menu.css";

function Menu({menu}) {

  /**
   * Funcion recursiva que renderiza cada menú
   * @param {*} menuItem
   */
	const renderMenuItem = menuItem => {
		return (
      <MenuItem key={menuItem.IdMenu} Nombre={menuItem.Nombre} Ruta={menuItem.Ruta}>
        { menuItem.children.map( item => renderMenuItem(item) ) }
      </MenuItem>
    )
  }

  const menuListToRender = menu.map( menuItem => renderMenuItem(menuItem) );

	return (
    <ul className="p-0 m-0">
      { menuListToRender }
    </ul>
	);
}

export default Menu;
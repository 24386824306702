import React from 'react';
import '../styles/Sucursal.css';
import {notificationDarDeAlta,notificationDarDeBaja} from './SucursalNotify'
import { confirmAlert } from 'react-confirm-alert'; 
import { BotonLetrasVerdeOscuro, BotonLetrasRojo } from '../Plantillas/Botones';

function DarBajaSede(IdSucursal,Nombre,history){
    confirmAlert({
        title: `¿Dar de baja definitivamente a la sede: "${Nombre}"?`,
        buttons: [
          {
            label: 'Sí',
            onClick:()=>{
                notificationDarDeBaja()
                fetch(`/api/sucursales/SucursalDelete/${IdSucursal}`)
                history.push('/sucursales')
            }
          },
          {
            label: 'No'
          }
        ]
      })
}

function DarAltaSede(IdSucursal,Nombre,history){
    confirmAlert({
        title: `¿Dar de Alta a la sede: "${Nombre}"?`,
        buttons: [
          {
            label: 'Sí',
            onClick:()=>{
                notificationDarDeAlta()
                fetch(`/api/sucursales/SucursalAlta/${IdSucursal}`)
                history.push('/sucursales')
            }
          },
          {
            label: 'No'
          }
        ]
      })
}

export function CambiarEstadoSede(Estado,IdSucursal,Nombre,history) {
    switch(Estado){
        case 'Inactivo':return(
            <React.Fragment>

              <BotonLetrasVerdeOscuro  
                type="button"
                onClick={()=>DarAltaSede(IdSucursal, Nombre, history)}
              >Dar de Alta
              </BotonLetrasVerdeOscuro>

            </React.Fragment>
        )
        default:return(
            <React.Fragment>

              <BotonLetrasRojo
                type="button"
                onClick={()=>DarBajaSede(IdSucursal, Nombre, history)}
              >Dar de Baja
              </BotonLetrasRojo>

            </React.Fragment>
        )
    }
}
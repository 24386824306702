import Noty from "noty";
import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";

export function notificationKardexProductoNull() {
    new Noty({
      type: "warning",
      theme: "bootstrap-v4",
      layout: "topCenter",
      text: "SELECCIONAR UN PRODUCTO",
      timeout: 3000
    }).show();
}

export function notificationKardexFechas() {
    new Noty({
      type: "warning",
      theme: "bootstrap-v4",
      layout: "topCenter",
      text: "LA FECHA DE INICIO NO PUEDE SER MAYOR A LA FECHA DE FIN",
      timeout: 3000
    }).show();
}

export function notificationStockInicial() {
  new Noty({
    type: "success",
    theme: "bootstrap-v4",
    layout: "topCenter",
    text: "CANTIDAD AGREGADA",
    timeout: 3000
  }).show();
}
import {useSelector, useDispatch} from "react-redux";
import {guardar_sesion} from "../redux/actions"

const useGetSesion = async () => {

    const dispatch = useDispatch();

    // Obtener sesión de Redux
    const sesion = useSelector(state => state.sesion);

    // Salir si ya hay una sesion o si se está cargando
    if (sesion.isLoggedIn !== null || sesion.loading === true)
        return sesion

    // Avisar que se está cargando la sesión
    dispatch(guardar_sesion({loading: true}));

    // Obtener sesión
    let sesionReceived = {}
    let sessionStatus = 500
    try {
        const respuesta = await fetch("/api/auth/sesion");
        sesionReceived = await respuesta.json();
        sessionStatus = respuesta.status
    } catch (e) {
        console.error("error in auth session")
    }

    if (sessionStatus === 200) {
        const resConfig = await fetch("/api/config/emp")
        const config = await resConfig.json()
        window.localStorage.setItem('extraConf', JSON.stringify(config))
    }

    // Comprobar si la sesión está logueada
    const isLoggedIn = (sessionStatus === 200);
    if (!isLoggedIn)
        sesionReceived = {}

    // Guardar sesión en Redux
    const newSesion = {...sesionReceived, isLoggedIn, loading: false}
    dispatch(guardar_sesion(newSesion));

    return newSesion;

}

export default useGetSesion;

import React from "react";
import {BotonAdd, BotonCerrarCajaLetras} from "../Plantillas/Botones";
import ListMovimientos from "../movimientosCaja/ListaMovimientosPorTurno";
import Modal from "../Modal";
import {notificarError} from "../Almacenes/AlmacenNotify";
import TurnoCierre from "./TurnoCierre";
import _ from 'lodash';

export default class MovimientosCaja extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            movs: [],
            isLoading: false,
            doReload: false,
            TipoMovimiento: 'Entrada',
            Monto: 0,
            Observacion: '',
            modalIsOpen: false,
            modalIsOpenCierre: false,
        }
        this.handleCloseModalCierre = this.handleCloseModalCierre.bind(this);
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.handleChangeTipoMovimiento = this.handleChangeTipoMovimiento.bind(this);
        this.handleClickNuevoCierre = this.handleClickNuevoCierre.bind(this);
    }

    async componentDidMount() {
        this.setState({isLoading: true})
        await this.obtenerMovimientos();
        this.setState({isLoading: false})
    }

    async obtenerMovimientos() {
        try {
            const response = await fetch(`/api/cajas/`);
            const data = await response.json();
            this.setState({movs: data.movCajas})
        } catch (error) {
            notificarError("No se pudieron cargar los movimientos de caja.")
        }
    }

    async onSubmitHandler(e) {
        e.preventDefault()
        this.setState({isLoading: true})
        await this.addMovimientoCaja()
        await this.obtenerMovimientos();
        this.setState({isLoading: false})
    }

    async addMovimientoCaja() {
        try {
            const res = await fetch(`/api/cajas/addMov`, {
                method: 'POST',
                body: JSON.stringify({
                    TipoMovimiento: this.state.TipoMovimiento,
                    Monto: this.state.Monto,
                    Saldo: 0,
                    MontoFinalEfectivoSistema: 0,
                    Observacion: this.state.Observacion
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
        } catch (err) {
            notificarError('No se pudo agregar el movimiento de caja.')
        }
    }

    handleChange = (e) => {
        const {name, value} = e.target
        this.setState({
            [name]: e.target.getAttribute('type') === 'number' ? (_.isNumber(Number(value)) ? value : 0) : value
        })
    }

    handleCloseModalCierre() {
        this.setState({modalIsOpenCierre: false})
    }


    handleChangeTipoMovimiento(e) {
        this.setState({
            TipoMovimiento: e.target.value
        })
    }

    getBotonTipoComprobante(tipoMovimiento, NombreDocSunat) {
        if (tipoMovimiento === "Entrada")
            return (
                <button
                    type="button"
                    className={
                        this.state.TipoMovimiento === tipoMovimiento
                            ? "preventa__tipo--comprobante--item--active"
                            : "preventa__tipo--comprobante--item"
                    }
                    name="TipoMovimiento"
                    onClick={this.handleChangeTipoMovimiento}
                    value="Entrada"
                >
                    {NombreDocSunat}
                </button>
            )
        else
            return (
                <button
                    type="button"
                    className={
                        this.state.TipoMovimiento === tipoMovimiento
                            ? "preventa__tipo--comprobante--item--active"
                            : "preventa__tipo--comprobante--item"
                    }
                    name="TipoMovimiento"
                    onClick={this.handleChangeTipoMovimiento}
                    value="Salida"
                >
                    {NombreDocSunat}
                </button>
            )

    }

    handleClickNuevoCierre(e) {
        this.setState({
            modalIsOpenCierre: true
        })
    }


    render() {
        const {extImg, idTurno, idCaja} = this.props;

        return (
            <React.Fragment>
                <div className="preventa__container">
                    <div className="preventaaaa">
                        <div className="encabezado">
                            <div className="preventa__tipo--comprobante">
                                {this.getBotonTipoComprobante("Entrada", "Entrada")}
                                {this.getBotonTipoComprobante("Salida", "Salida")}
                                <BotonCerrarCajaLetras
                                    className="ml-2 mt-1" onClick={this.handleClickNuevoCierre}
                                    title="Cerrar Caja"/>
                            </div>
                            <div className="preventa__tipo--comprobante">
                                <form onSubmit={this.onSubmitHandler} className="d-flex justify-content-center">
                                    <div className="almacenDiv">
                                        <b className="ml-4">Monto:</b>
                                        <div className="input__Almacen">
                                            <input className="input__linea" name="Monto"
                                                   onChange={this.handleChange}
                                                   onFocus={(e) => e.target.select()}
                                                   type="number" step="any" min="0.01"
                                                   value={this.state.Monto}>
                                            </input>
                                        </div>
                                        <div className="input__Almacen">
                                            <b className="ml-4">Observación:</b>
                                            <div className="input__Almacen">
                                                <input className="input__linea" onChange={this.handleChange}
                                                       name="Observacion"
                                                       value={this.state.Observacion}/>
                                            </div>
                                            <div align="right">
                                                <BotonAdd className="mt-2 mb-2"/>
                                            </div>
                                        </div>
                                        {this.state.isLoading ? 'Cargando' : <ListMovimientos movs={this.state.movs}/>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.modalIsOpenCierre} onClose={this.handleCloseModalCierre}
                    title="CERRAR CAJA"
                >
                    <TurnoCierre extImg={extImg} idTurno={idTurno} idCaja={idCaja}
                                 movsCaja={this.state.movs} montoInicial={this.props.montoInicial}/>
                </Modal>
            </React.Fragment>
        )
    }
}

import React from "react";
import {BotonAddCliente, BotonSearchCliente, BotonCancelar} from "../Plantillas/Botones";
import _ from 'lodash';

class BuscarClientePorDoc extends React.Component {
    render() {
        return (

            <div className="preventa__cliente mt-1" style={this.props.style}>
                <BotonCancelar
                    hidden={_.isUndefined(this.props.hideGoBack) ? false : (this.props.hideGoBack)}
                    onClick={this.props.goBack}
                    className="mr-2"
                />

                <BotonAddCliente
                    hidden={_.isUndefined(this.props.hideAddCliente) ? false : (this.props.hideAddCliente)}
                    onClick={this.props.handleClickNuevo}
                    title="Nuevo Cliente"
                />
                <BotonSearchCliente
                    hidden={_.isUndefined(this.props.hideSearchCliente) ? false : (this.props.hideSearchCliente)}
                    className="ml-2 mr-2"
                    onClick={this.props.handleClickBuscar}
                    title="Buscar Cliente en la lista"
                />
                <input
                    className="DocClientePreventa mr-2"
                    style={{width: "30%"}}
                    name="Documento"
                    onChange={this.props.DocChange}
                    type="text"
                    value={this.props.Documento}
                    placeholder="DNI/RUC"
                    required
                    onFocus={(e) => this.props.setActiveInputDoc(e, "Documento")}
                />
                {this.props.aliasAsText ? (
                        <textarea
                            style={{width: "500px", borderColor: "#01a59c", borderWidth: "3px", borderTopColor: "#01a59c"}}
                            placeholder={this.props.placeholder}
                            name="Alias"
                            onChange={this.props.AliasChange}>{this.props.Alias}</textarea>
                    )
                    : (<input
                        hidden={_.isUndefined(this.props.hideAlias) ? false : (this.props.hideAlias)}
                        className={this.props.aliasClass || "inputAlias"}
                        name="Alias"
                        onChange={this.props.AliasChange}
                        type="text"
                        value={this.props.Alias}
                        style={this.props.aliasStyle || {}}
                        placeholder={this.props.placeholder || "Alias (opcional)"}
                    />)
                }
            </div>

        );
    }
}

export default BuscarClientePorDoc;

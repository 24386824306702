import React from "react";
import moment from "moment";
import SucursalPicker from "./SucursalPicker";
import ProductoPicker from "./ProductoPicker";
import TipoDocMovPicker from "./TipoDocMovPicker";
import TipoMovPicker from "./TipoMovPicker";

export default class FiltroReporteMovAlmacen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            payload: {
                fechaInicio: moment(new Date()).format('YYYY-MM-DD'),
                fechaFin: moment(new Date()).format('YYYY-MM-DD'),
            }
        }
    }

    render() {
        const {payload} = this.state;
        return (
            <>
                <div className="row">
                    <div className="col-sm-auto">
                        Fecha inicio
                    </div>
                    <div className="col-sm">
                        <input className="form-control" type="date" value={payload.fechaInicio}
                               onChange={e => this.setState({
                                   payload: {...payload, fechaInicio: e.target.value},
                               })}/>
                    </div>
                    <div className="col-sm-auto">
                        Fecha fin
                    </div>
                    <div className="col-sm">
                        <input className="form-control" type="date" value={payload.fechaFin}
                               onChange={e => this.setState({
                                   payload: {...payload, fechaFin: e.target.value}
                               })}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm">
                        <SucursalPicker onChangeSucursal={id => this.setState({
                            payload: {...payload, idSucursal: id}
                        })}/>
                    </div>
                    {payload.idSucursal ? (
                        <div className="col-sm">
                            <ProductoPicker initialLoad={false} idSucursal={payload.idSucursal}
                                            onChangeProducto={e => this.setState({
                                                payload: {...payload, idProducto: e.value, _prod: e}
                                            })}/>
                        </div>
                    ) : null}

                </div>
                <div className="row">
                    <div className="col-sm">
                        <TipoMovPicker onChangeTipoMov={e => this.setState({
                            payload: {...payload, idsTipoMov: e}
                        })}/>
                    </div>
                    <div className="col-sm">
                        <TipoDocMovPicker onChangeTipoDoc={e => this.setState({
                            payload: {...payload, idsTipoDoc: e}
                        })}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-auto">
                        <button disabled={this.props.isLoading} className="btn btn-outline-primary"
                                onClick={() => this.props.onFiltrarPressed(payload)}>
                            Filtrar
                        </button>
                    </div>
                </div>
            </>
        );
    }
}

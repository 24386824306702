import React, { useState, useEffect } from 'react';
import "./styles/UsuarioForm.css"
import SelectSearch from "react-select-search";
import "../pages/styles/SelectSearch.css";
import apiFetch from "../helpers/apiFetch"
import Notify from "./Notify"
import useRoles from '../helpers/useRoles';
import CheckboxToggle from './CheckboxToggle';
import { CampoInput } from './Campos';
import { BotonLetras } from './Plantillas/Botones';
import useFetch from '../helpers/useFetch';

const UsuarioForm = ({ Usuario, closeModal }) => {

	const [usuario, setUsuario] = 					useState( Usuario.Usuario || "");
	const [password, setPassword] = 				useState( Usuario.password || "");
	const [passwordConfirm, setPasswordConfirm] = 	useState( Usuario.passwordConfirm || "");
	const [roles, setRoles] = 						useState( Usuario.Roles || []);
	const [IdSucursal, setIdSucursal] = 			useState( Usuario.IdSucursal || null);
	const [IdEmpleado, setIdEmpleado] = 			useState( Usuario.IdEmpleado || null);

	const [sucursales, setSucursales] = useState(null);
	const [empleados, setEmpleados] = useState(null);
	const AllRoles = useRoles();


	/**********************************************
	 * Obtener sucursales al montar el componente *
	 **********************************************/
	const sucursalesRawData = useFetch("/api/sucursales");
	useEffect( () => {
		if (!sucursalesRawData) return

		const sucursalesList = sucursalesRawData.sucursales.map( sucursal => {
			return {
				name: `${sucursal.Nombre} ${sucursal.RazonSocial}`,
				value: sucursal.IdSucursal,
				IdSucursal: sucursal.IdSucursal,
				IdEmpresa: sucursal.IdEmpresa
			}
		})
		setSucursales(sucursalesList)
	}, [sucursalesRawData])


	/************************************************************
	 * Obtener empleados cuando esté seleccionada una surcursal *
	 ************************************************************/
	useEffect( () => {
		if ( sucursales === null || IdSucursal === null )
			return;
		const getEmpleados = async () => {
			const sucursal = sucursales.find( sucursal => sucursal.IdSucursal === IdSucursal);
			const {IdEmpresa} = sucursal;
			const {data_tables, error} = await apiFetch("/api/master/empleados", [], {
				method: "POST",
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({IdEmpresa})
			});
			if (error)
				return;
			const [empleadosList] = data_tables;
			const empleados = empleadosList.map( empleado => {
				return {
					name: `${empleado.Nombres} ${empleado.Apellidos}`,
					value: empleado.IdEmpleado
				}
			})
			setEmpleados(empleados)
		}
		getEmpleados();
	}, [sucursales, IdSucursal])

	const HandleRolBuilder = IdRol => {
		return event => {
			const isAdding = event.target.checked === true // agregar o remover.
			const newRoles = [...roles];
			if (isAdding) {
				newRoles.push(IdRol);
			} else {
				const IdRolIndex = newRoles.indexOf(IdRol)
				newRoles.splice(IdRolIndex, 1) // quitar IdRol de la lista
			}
			setRoles(newRoles);
		}
	}


	/**
	 * Se va a comparar el estado original y el estado cambiado, para resumir los cambios.
	 * Ambos estados (`roles` y `Usuario.Roles`) son una lista de IdRoles, por ejemplo:
	 * ```
	 * [1,2,5,8,9,...]
	 * ```
	 *
	 * @returns {Object<Array<Number>>} La lista de IdRol que se van a agregar y los que se van a borrar. El formato es:
	 * ```
	 * {
	 *   addList: [IdRol1,IdRol3,...],
	 *   removeList: [IdRol2,IdRol4,...]
	 * }
	 * // Ej:
	 * {
	 *   addList: [1,3,6,...],
	 *   removeList: [2,4,9,...]
	 * }
	 * ```
	 */
	const getRolesChanges = () => {
		const addList = []
		const removeList = Usuario.Roles // Estado original sin los cambios hechos.
		roles.forEach( IdRol => {
			const IdRolIndex = removeList.indexOf(IdRol);
			(IdRolIndex === -1) // Si el `IdRol` no estaba en el estado original
				? addList.push(Number(IdRol))  // Agregar IdRol al `addList`
				: removeList.splice(IdRolIndex, 1)   // De lo contrario (no hubo cambio), Eliminar IdRol del `removeList`
		})
		return {addList, removeList};
	}

	const handleSubmit = async e => {
		e.preventDefault();
		if (password !== passwordConfirm)
			return Notify("Contraseñas no concuerdan", "error", 1500 )

		const postUrl = Usuario.IdUsuario
						? `/api/usuarios/${Usuario.IdUsuario}`	// Editar usuario
						: "/api/usuarios"						// Nuevo usuario

		const Roles = getRolesChanges();
		const estadosChequear = [ Usuario.IdUsuario ? "_estado_usuario_update" : "_estado_usuario_insert" ];
		if (Roles.addList.length > 0)     estadosChequear.push("_estado_usuarioRoles_insert");
		if (Roles.removeList.length > 0)  estadosChequear.push("_estado_usuarioRoles_delete");

		const data = {usuario, password, IdSucursal, IdEmpleado, Roles};
		const {error} = await apiFetch(postUrl, estadosChequear, {
			method: "POST",
            headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data)
		})
		if (error)
			return
		Notify("Usuario ingresado exitosamente")
		closeModal()
	}

	return (
		<>
			<form id="usuarios_form" action="/api/usuarios" method="POST" onSubmit={handleSubmit}>
				{ sucursales && <SelectSearch value={IdSucursal} onChange={ e=>setIdSucursal(e.value) } options={sucursales} name="IdSucursal" placeholder="Sucursal" />}
				{ empleados  && <SelectSearch value={IdEmpleado} onChange={ e=>setIdEmpleado(e.value) } options={empleados}  name="IdEmpleado" placeholder="Empleado" />}

				<CampoInput 	label="Nombre de Usuario" 		value={usuario} 		onChange={ e=>setUsuario(e.target.value) } 			name="username"			type="text" 	autoComplete="username"/>
				<div className="contraseña">
					<CampoInput label="Contraseña" 				value={password} 		onChange={ e=>setPassword(e.target.value) } 		name="password" 		type="password"	autoComplete="new-password" className="mr-5"/>
					<CampoInput label="Confirmar contraseña"	value={passwordConfirm} onChange={ e=>setPasswordConfirm(e.target.value) }	name="passwordConfirm"	type="password"	autoComplete="new-password"/>
				</div>

				<div className="roles">
					{AllRoles && AllRoles.map( rol => {
						const {IdRol, Nombre} = rol
						const checked = roles.some(_IdRol => _IdRol === IdRol)
						return (
							<div key={IdRol} className="d-flex menu-nombre-width">
								<CheckboxToggle
									checked={checked}
									onChange={HandleRolBuilder(IdRol)}
									labelText={Nombre}
								/>
							</div>
						)
					})}
				</div>

				<BotonLetras className="mt-3" type="submit">{Usuario.IdUsuario ? "Actualizar" : "Guardar"}</BotonLetras>
			</form>
		</>
	);
}

export default UsuarioForm;
import React from "react";
import "react-moment";
import { validarInputsAlmacen } from "./AlmacenValidator";
import { notificationSuccess, notificationError } from "./AlmacenNotify";
import { CambiarEstadoAlmacen } from "./AlmacenEstado";
import PageLoading from '../PageLoading.js';
import AlmacenForm from "./AlmacenForm";

class AlmacenEdit extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      error: null,
      IdAlmacen: "",
      IdSucursal: "",
      IdEmpleado: "",
      IdEmpresa: "",
      Nombre: "",
      IdUbigeo: "",
      Direccion: "",
      Telefono: "",
      Celular: "",
      Estado: "",
      Valorizacion: "",
      ContMon: "",
      UltimaEntrada: "",
      UltimaSalida: "",
      UltimaTransferencia: "",
      UltimoAjuste: "",
      Clase: "",
      Errores: {
        Nombre: "",
        Telefono: "",
        Celular: "",
        UltimaEntrada: "",
        UltimaSalida: "",
        UltimaTransferencia: "",
        UltimoAjuste: ""
      },
      data: {
        ubigeos: []
      },

      dataEmpresas:[],

      dataEmpleados:[],
      dataSucursales: {
        sucursales: []
      },
      empleadosPorEmpresa: [],
      sucursalesPorEmpresa: [],
      ubigeoReg: [],
      ubigeoProv: [],
      ubigeoDis: [],
      valueRegion: null,
      valueProvincia: null,
      valueDistrito: null,
      ubigeo: null
    };
    this.getAlmacenById = this.getAlmacenById.bind(this);
    this.getUbigeoById = this.getUbigeoById.bind(this);
    this.getEmpleados = this.getEmpleados.bind(this);
    this.getEmpleadoById = this.getEmpleadoById.bind(this);
    this.getEmpresas = this.getEmpresas.bind(this);
    this.getSucursales = this.getSucursales.bind(this);
    this.getSucursalById = this.getSucursalById.bind(this);
    this.RegionChange = this.RegionChange.bind(this);
    this.ProvinciaChange = this.ProvinciaChange.bind(this);
    this.DistritoChange = this.DistritoChange.bind(this);
    this.traerEmpleadoPorEmpresa = this.traerEmpleadoPorEmpresa.bind(this);
    this.traerSucursalesPorEmpresa = this.traerSucursalesPorEmpresa.bind(this);
    this.traerUbigeoPorProvincia = this.traerUbigeoPorProvincia.bind(this);
    this.traerUbigeoPorDistrito = this.traerUbigeoPorDistrito.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.editAlmacen = this.editAlmacen.bind(this);
  }

  componentDidMount() {
    const IdAlmacen = this.props.match.params.IdAlmacen;
    this.getAlmacenById(IdAlmacen);
  }

  async getAlmacenById(IdAlmacen) {
    this.setState({ loading: true, error: null });
    //console.log(IdAlmacen);
    try {
      await fetch(`/api/almacenes/edit/${IdAlmacen}`)
      .then(res => res.json())
      .then(data => {
        console.log(data)
        this.getEmpresas();
        this.getEmpleados(data.results[0].IdEmpresa);
        this.getSucursales(data.results[0].IdEmpresa);
        this.getUbigeo(data.results[0].IdUbigeo);
        this.setState({
          loading:false,
          IdAlmacen: data.results[0].IdAlmacen,
          IdSucursal: data.results[0].IdSucursal,
          IdEmpleado: data.results[0].IdEmpleado,
          Nombre: data.results[0].Nombre,
          IdUbigeo: data.results[0].IdUbigeo,
          Direccion: data.results[0].Direccion,
          Telefono: data.results[0].Telefono,
          Celular: data.results[0].Celular,
          Valorizacion: data.results[0].Valorizacion,
          ContMon: data.results[0].ContMon,
          UltimaEntrada: data.results[0].UltimaEntrada,
          Estado: data.results[0].Estado,
          UltimaSalida: data.results[0].UltimaSalida,
          UltimaTransferencia: data.results[0].UltimaTransferencia,
          UltimoAjuste: data.results[0].UltimoAjuste,
          Clase: data.results[0].Clase,
          IdEmpresa: data.results[0].IdEmpresa
        });
      });
    } catch (error) {
      this.setState({
        loading: false,
        error: error,
      });
      console.log(error)
    }
    
  }

  getEmpresas = async () => {
    this.setState({ loading: true, error: null });
    try {
      const response = await fetch("/api/empresas");
      const data = await response.json();
      //console.log(data)
      const empresas=data.empresas.filter(act=>{
        return act.Estado=='Activo'
      });
      //console.log(empresas)
      this.setState({
        loading: false,
        dataEmpresas: empresas
      });//console.log(this.state.dataEmpresas.empresas)
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  getEmpleados = async IdEmpresa => {
    this.setState({ loading: true, error: null });
    try {
      const response = await fetch("/api/empleados/master");
      const data = await response.json();
      const empleados=data.empleados.filter(act=>{
        return act.Estado=='activo'
      });
      this.setState({
        loading: false,
        dataEmpleados: empleados
      });
      //console.log(data)
      await Promise.all([this.getEmpleadoById(IdEmpresa)]);
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  getSucursales = async IdEmpresa => {
    this.setState({ loading: true, error: null });
    try {
      const response = await fetch("/api/sucursales");
      const data = await response.json();
      this.setState({
        loading: false,
        dataSucursales: data
      });
      //console.log(data)
      await Promise.all([this.getSucursalById(IdEmpresa)]);
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  getEmpleadoById = async IdEmpresa => {
    /*this.state.empleadosPorEmpresa = this.state.dataEmpleados.empleados.filter((empleadosP) => {
          return empleadosP.IdEmpresa == IdEmpresa
        })*/
    this.setState({
      empleadosPorEmpresa: this.state.dataEmpleados.filter(
        empleadosP => {
          return empleadosP.IdEmpresa === IdEmpresa;
        }
      )
    });
  };

  getSucursalById = async IdEmpresa => {
    /*this.state.empleadosPorEmpresa = this.state.dataEmpleados.empleados.filter((empleadosP) => {
          return empleadosP.IdEmpresa == IdEmpresa
        })*/
    this.setState({
      sucursalesPorEmpresa: this.state.dataSucursales.sucursales.filter(
        sucursalesP => {
          return sucursalesP.IdEmpresa === IdEmpresa;
        }
      )
    });
  };

  getUbigeo = async IdUbigeo => {
    this.setState({ loading: true, error: null });
    try {
      const response = await fetch("/api/ubigeos/ubigRegion");
      const data = await response.json();

      this.setState({
        loading: false,
        data: data,
        valueRegion: data.ubigeos[IdUbigeo - 1].CodRegion,
        valueProvincia: data.ubigeos[IdUbigeo - 1].CodProvincia,
        valueDistrito: data.ubigeos[IdUbigeo - 1].IdUbigeo,
        IdUbigeo: data.ubigeos[IdUbigeo - 1].IdUbigeo,
        ubigeo: data.ubigeos[IdUbigeo - 1].Ubigeo
      });

      await Promise.all([this.getUbigeoById()]);
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  getUbigeoById = async () => {
    //Filtros

    this.state.ubigeoReg = this.state.data.ubigeos.filter(ubigeoR => {
      return ubigeoR.CodProvincia === "00" && ubigeoR.CodDistrito === "00";
    });

    this.state.ubigeoProv = this.state.data.ubigeos.filter(ubigeoP => {
      return (
        ubigeoP.CodRegion === this.state.valueRegion &&
        ubigeoP.CodProvincia !== "00" &&
        ubigeoP.CodDistrito === "00"
      );
    });

    this.state.ubigeoDis = this.state.data.ubigeos.filter(ubigeoD => {
      return (
        ubigeoD.CodRegion === this.state.valueRegion &&
        ubigeoD.CodProvincia === this.state.valueProvincia &&
        ubigeoD.Ubigeo !== null
      );
    });
    this.setState({
      valueProvincia: this.state.valueProvincia,
      valueDistrito: this.state.valueDistrito,
      IdUbigeo: this.state.IdUbigeo
    });
  };

  traerUbigeoPorProvincia = async idRegion => {
    this.state.ubigeoProv = this.state.data.ubigeos.filter(ubigeoP => {
      return (
        ubigeoP.CodRegion === idRegion &&
        ubigeoP.CodProvincia !== "00" &&
        ubigeoP.CodDistrito === "00"
      );
    });
    this.setState({ valueProvincia: this.state.ubigeoProv[0].CodProvincia });
  };

  traerUbigeoPorDistrito = async (idRegion, idProvincia) => {
    this.state.ubigeoDis = this.state.data.ubigeos.filter(ubigeoD => {
      return (
        ubigeoD.CodRegion === idRegion &&
        ubigeoD.CodProvincia === idProvincia &&
        ubigeoD.Ubigeo !== null
      );
    });
    this.setState({
      valueDistrito: this.state.ubigeoDis[0].CodDistrito,
      IdUbigeo: this.state.ubigeoDis[0].IdUbigeo,
      //para el label de ubigeo
      ubigeo: this.state.ubigeoDis[0].Ubigeo
    });
  };

  traerEmpleadoPorEmpresa = async IdEmpresa => {
    this.state.empleadosPorEmpresa = this.state.dataEmpleados.filter(
      empleadosP => {
        return empleadosP.IdEmpresa == IdEmpresa;
      }
    );

    //si hay empleados hace el setstate
    if (this.state.empleadosPorEmpresa[0]) {
      this.setState({
        IdEmpleado: this.state.empleadosPorEmpresa[0].IdEmpleado
      });
    }
  };

  traerSucursalesPorEmpresa = async IdEmpresa => {
    this.state.sucursalesPorEmpresa = this.state.dataSucursales.sucursales.filter(
      sucursalesP => {
        return sucursalesP.IdEmpresa == IdEmpresa;
      }
    );

    //si hay empleados hace el setstate
    if (this.state.sucursalesPorEmpresa[0]) {
      this.setState({
        IdSucursal: this.state.sucursalesPorEmpresa[0].IdSucursal
      });
    }
  };

  RegionChange = event => {
    this.setState({ valueRegion: event.target.value });
    this.traerUbigeoPorProvincia(event.target.value);
    this.traerUbigeoPorDistrito(
      event.target.value,
      this.state.ubigeoProv[0].CodProvincia
    );
  };

  ProvinciaChange = event => {
    this.setState({ valueProvincia: event.target.value });

    this.traerUbigeoPorDistrito(this.state.valueRegion, event.target.value);
  };

  DistritoChange = event => {
    this.setState({
      IdUbigeo: event.target.value,
      valueDistrito: event.target.value
    });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
    let Errores = this.state.Errores;

    //mensajes de validacion
    validarInputsAlmacen(Errores, name, value);

    this.setState({ Errores, [name]: value });
  };

  EmpresaChange = e => {
    this.setState({ IdEmpresa: e.target.value });
    //console.log(e.target.value);
    this.traerEmpleadoPorEmpresa(e.target.value);
    this.traerSucursalesPorEmpresa(e.target.value);
  };

  EmpleadoChange = e => {
    this.setState({ IdEmpleado: e.target.value });
    //this.setState({ IdEmpleado: e.value });
    //console.log(e.target.value,"--");
  };

  SucursalChange = e => {
    this.setState({ IdSucursal: e.target.value });
    //console.log(e.target.value,"--");
  };

  ClaseChange = e => {
    this.setState({ Clase: e.target.value });
    //console.log(this.state.Clase);
  };

  onChangeCheckBox = e => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked
    });
    //console.log(this.state)
  };

  editAlmacen(e) {
    let valido = true;
    Object.values(this.state.Errores).forEach(val => {
      val.length > 1 && (valido = false);
    });
    //si esta ok, muestra mensaje y edita
    if (valido) {
      try {
        notificationSuccess();
        fetch(`/api/almacenes/edit/${this.state.IdAlmacen}`, {
        method: "PUT",
        body: JSON.stringify({
          IdAlmacen: this.state.IdAlmacen,
          IdSucursal: this.state.IdSucursal,
          IdEmpleado: this.state.IdEmpleado,
          Nombre: this.state.Nombre,
          IdUbigeo: this.state.IdUbigeo,
          Direccion: this.state.Direccion,
          Telefono: this.state.Telefono,
          Celular: this.state.Celular,
          Valorizacion: this.state.Valorizacion,
          ContMon: this.state.ContMon,
          UltimaEntrada: this.state.UltimaEntrada,
          UltimaSalida: this.state.UltimaSalida,
          UltimaTransferencia: this.state.UltimaTransferencia,
          UltimoAjuste: this.state.UltimoAjuste,
          Clase: this.state.Clase
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(res => res.json())
        .then(data => console.log(data))
        .catch(err => console.log(err));
      this.props.history.push("/almacenes");
      } catch (error) {
        this.setState({
          loading: false,
          error: error,
        });
      }
      
    } else {
      //notificacion de error
      notificationError();
    }

    e.preventDefault();
  }

  render() {
    if(this.state.loading === true){
      return <PageLoading/>;
    }

    return (
      <React.Fragment>

                <AlmacenForm
                  handleChange={this.handleChange}
                  onSubmit={this.editAlmacen}
                  RegionChange={this.RegionChange}
                  ProvinciaChange={this.ProvinciaChange}
                  DistritoChange={this.DistritoChange}
                  EmpleadoChange={this.EmpleadoChange}
                  EmpresaChange={this.EmpresaChange}
                  SucursalChange={this.SucursalChange}
                  onChangeCheckBox={this.onChangeCheckBox}
                  ClaseChange={this.ClaseChange}
                  state={this.state}
                  CambiarEstadoAlmacen={CambiarEstadoAlmacen(
                    this.state.Estado,
                    this.state.IdAlmacen,
                    this.state.Nombre,
                    this.props.history
                  )}
                />
      </React.Fragment>
    );
  }
}
export default AlmacenEdit;

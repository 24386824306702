import React from "react";
import { BotonBuscar,BotonGuardarLetras } from "../Plantillas/Botones";

class EmpleadoForm extends React.Component {
  render() {
    return (
      <React.Fragment>
        
          <form onSubmit={this.props.onSubmit} className="d-flex justify-content-center">
            <div className="empleadosForm">

              <div className="EmpleadoBoton">
                {this.props.CambiarEstadoEmpleado?this.props.CambiarEstadoEmpleado:null}
                <BotonGuardarLetras type="submit" className="ml-2"/>
              </div>

              {this.props.Empresa?this.props.Empresa:null}

              <div className="EmpleadoDocumento">
                  <b className="ml-4">*DNI</b>
                <div className="input__EmpleadoDiv">
                  <input
                    className="input__linea mr-2"
                    name="DNI"
                    onChange={this.props.onChange}
                    type="text"
                    value={this.props.state.DNI}
                    placeholder="DNI"
                    required
                    autoFocus
                  />
                  <BotonBuscar 
                    type="button" 
                    onClick={this.props.onSubmitDNI}
                    title="Buscar datos en RENIEC (Puede demorar)"
                  ></BotonBuscar>
                </div>
                <div className="labelValidEmpleado ml-4">
                  {this.props.state.Errores.DNI.length > 0 && (
                    <span>{this.props.state.Errores.DNI}</span>
                  )}
                </div>
              </div>
              
              <div className="EmpleadoDocumento">
                <b className="ml-4">*Nombres</b>
                <div className="input__EmpleadoDiv">
                  <input
                    className="input__linea"
                    name="Nombres"
                    type="text"
                    value={this.props.state.Nombres}
                    required
                    onChange={this.props.onChange}
                    placeholder="Nombres"
                  />
                </div>
                <div className="labelValidEmpleado">
                    {this.props.state.Errores.Nombres.length > 0 && (
                      <span>{this.props.state.Errores.Nombres}</span>
                    )}
                </div>
              </div>
              
              <div className="EmpleadoDocumento">
                <b className="ml-4">*Apellidos</b>
                <div className="input__EmpleadoDiv">
                  <input
                    className="input__linea"
                    name="Apellidos"
                    onChange={this.props.onChange}
                    type="text"
                    value={this.props.state.Apellidos}
                    required
                    placeholder="Apellidos"
                  />
                </div>
                <div className="labelValidEmpleado">
                    {this.props.state.Errores.Apellidos.length > 0 && (
                      <span>{this.props.state.Errores.Apellidos}</span>
                    )}
                  </div>
              </div>

              <div className="EmpleadoDocumento">
                <b className="ml-4">Teléfono</b>
                <div className="input__EmpleadoDiv">
                  <input
                    className="input__linea"
                    name="Telefono"
                    onChange={this.props.onChange}
                    type="text"
                    value={this.props.state.Telefono}
                    placeholder="Teléfono"
                  />
                </div>
                <div className="labelValidEmpleado">
                  {this.props.state.Errores.Telefono.length > 0 && (
                    <span>{this.props.state.Errores.Telefono}</span>
                  )}
                </div>
              </div>

              <div className="EmpleadoDocumento">
                <b className="ml-4">*Celular</b>
                <div className="input__EmpleadoDiv">
                  <input
                    className="input__linea"
                    name="Celular"
                    onChange={this.props.onChange}
                    type="text"
                    value={this.props.state.Celular}
                    required
                    placeholder="Celular"
                  />
                </div>
                <div className="labelValidEmpleado">
                  {this.props.state.Errores.Celular.length > 0 && (
                    <span>{this.props.state.Errores.Celular}</span>
                  )}
                </div>
              </div>

              <div className="EmpleadoDocumento">
                  <b className="ml-4">*Correo</b>
                <div className="input__EmpleadoDiv">
                  <input
                    className="input__linea"
                    name="Correo"
                    onChange={this.props.onChange}
                    type="email"
                    value={this.props.state.Correo}
                    required
                    placeholder="Correo"
                  />
                </div>
                <div className="labelValidEmpleado">
                  {this.props.state.Errores.Correo.length > 0 && (
                    <span>{this.props.state.Errores.Correo}</span>
                  )}
                </div>
              </div>

              <div className="EmpleadoDocumento">
                <b className="ml-4">*Dirección</b>
                <div className="input__EmpleadoDiv">
                  <input
                    className="input__linea"
                    name="Direccion"
                    onChange={this.props.onChange}
                    type="text"
                    value={this.props.state.Direccion}
                    required
                    placeholder="Dirección"
                  />
                </div>
              </div>

              <div className="EmpleadoDocumento">
                  <b className="ml-4">*Fecha de Ingreso</b>
                <div className="input__EmpleadoDivFecha">
                  <input
                    className="input__linea"
                    name="FechaIngreso"
                    onChange={this.props.onChange}
                    type="date"
                    value={this.props.state.FechaIngreso}
                    required
                  />
                </div>
                <div className="labelValidEmpleado">
                    {this.props.state.Errores.FechaIngreso.length > 0 && (
                      <span>{this.props.state.Errores.FechaIngreso}</span>
                    )}
                </div>
              </div>

              <div className="EmpleadoBoton">
                {this.props.CambiarEstadoEmpleado?this.props.CambiarEstadoEmpleado:null}
                <BotonGuardarLetras type="submit" className="ml-2"/>
              </div>

            </div>
          </form>
        
        
      </React.Fragment>
    );
  }
}

export default EmpleadoForm;

import React from 'react';
import logo from "./images/mifacturaperu.png";
import {useSelector} from "react-redux";

export default function LogoAplicativo(props) {

    const {Nombres} = useSelector(state => state.sesion);
    
    return (
        <div className="login__principal">
            <div className="login__hijo__central">
                <div className="d-flex align-items-center flex-column">
                    <h1 className="mt-2">Bienvenido, {Nombres}</h1>
                    <img src={logo} height="20%" width="20%"/>
                </div>
            </div>
        </div>
    );
}

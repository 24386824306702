import {useState, useEffect} from 'react';
import Notify from '../componentes/Notify';
import validarStatusCode from './validarStatusCode';

/**
 * Hook que hace un fetch al `url` especificado.
 * 
 * Diferencias de un fetch normal:
 * - Automaticamente se parsea la respuesta a Json
 * - Se valida el **Status Code** con la función `validarStatusCode()` y se notifica en caso de error
 * - Si el fetch arroja error, se ataja y notifica, y se imprime `url` y `options` en consola
 * - El fetch automaticamente se cancela si el hook se desmonta
 * - No se devuelve una Promesa.
 *   Mientras el fetch se está cargando, se devuelve `initialState` como un estado de React.
 *   Y una vez ya cargado, se procede a validar el **statusCode**:
 *   1. Si pasa la validación, se parsea la respuesta como json y se actualiza el estado con el resultado
 *   2. Si no pasa validación, no se hace nada. // Quizas deba hacerse algo, pero aún no lo he necesitado
 *
 * @param {RequestInfo} url - url donde se hará el fetch
 * @param {RequestInit} [options={}] - opciones del fetch
 * @param {*} [initialState=null] - Estado inicial a devolver antes de que finalice el fetch
 * @param {Array<*>} [deps=[]] - Array de dependencias que se le pasan como 2do argumento a `useEffect`
 */
const useFetch = (url, options={}, initialState=null, deps=[]) => {
    const [data, setData] = useState(initialState);

    useEffect( () => {
        const controller = new AbortController();
        const {signal} = controller;
        (async () => {
            const respuesta = await fetch(url, {...options, signal})
            .catch( error => {
                if (error.name === "AbortError") return

                console.error(error);
                console.log(url, options);
                Notify("Hubo un problema en la comunicación con el servidor", "error")
                return
            })
            if (! validarStatusCode(respuesta, options)) return

            const data = await respuesta.json()
            setData(data)
        })()
        return () => controller.abort()
    }, deps)

    return data;
}

export default useFetch;
import React, {Component} from "react";
import Header from "../componentes/Header";
import parent_icon_header from "../componentes/images/icons8-barcode-52.png";
import child_icon_header from "../componentes/images/icons8-add-property-501white.png";
import NewProductUI from "./NewProductUI";
import PageError from "../componentes/PageError";
import PageLoading from "../componentes/PageLoading";
import swal from "sweetalert";
import "./styles/NewProduct.css";
import {TRIBUTO, round} from "../Global";

class NewProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unidades: [],
            tipoexistencia: [],
            afectacion: [],
            segmentos: [],
            familias: [],
            clases: [],
            commodities: [],
            osces: [],
            nombreproducto: "",
            descripcionproducto: "",
            codigobarra: "",
            isLoading: false,
            error: null,
            newproduct: [],
            respuestaIdProducto: [],
            producto: {
                IdProducto: null,
                Codigo: null,
                CodigoBarra: null,
                Descripcion: null,
                IdAfectacionIgv: null,
                IdClase: null,
                IdCommodity: null,
                IdFamilia: null,
                IdProductoOsce: null,
                IdSegmento: null,
                IdTipoExistencia: null,
                StockMinimo: null,
                IdUnidad: null,
                Nombre: null,
                TasaISC: null
            },
            clasificacionSunat: {
                Segmento: null,
                Familia: null,
                Clase: null,
                IdCommodity: null,
                Commodity: null,
                IdProductoOsce: null,
                ProductoOsce: null
            },
            busquedaPorCommodity: "",
            busquedaPorOsce: "",
            Tributos: [],
            tributoSeleccionado: null
        };

        this.handleSegmentoChange = this.handleSegmentoChange.bind(this);
        this.handleFamiliaChange = this.handleFamiliaChange.bind(this);
        this.handleClaseChange = this.handleClaseChange.bind(this);
        this.handleCommodityChange = this.handleCommodityChange.bind(this);
        this.handleOsceChange = this.handleOsceChange.bind(this);
        this.handleUnidadChange = this.handleUnidadChange.bind(this);
        this.handleTipoExistenciaChange = this.handleTipoExistenciaChange.bind(
            this
        );
        this.handleAfectacionChange = this.handleAfectacionChange.bind(this);
        this.handleNombreChange = this.handleNombreChange.bind(this);
        this.handleDescripcionChange = this.handleDescripcionChange.bind(this);
        this.handleCodigoBarrasChange = this.handleCodigoBarrasChange.bind(this);
        this.handleStockMinimo = this.handleStockMinimo.bind(this);
        this.buscarPorCommodity = this.buscarPorCommodity.bind(this);
        this.buscarPorOsce = this.buscarPorOsce.bind(this);
        this.handleOnChangeBusquedaCommodity = this.handleOnChangeBusquedaCommodity.bind(
            this
        );
        this.handleOnChangeBusquedaOsce = this.handleOnChangeBusquedaOsce.bind(
            this
        );
        this.saveTributoProducto = this.saveTributoProducto.bind(this);
        this.handleOptionTributoChange = this.handleOptionTributoChange.bind(this);
        this.handleICBPERChange = this.handleICBPERChange.bind(this);
        this.handleISCChange = this.handleISCChange.bind(this);
        this.handleOnChangeTasaISC = this.handleOnChangeTasaISC.bind(this);
        this.handleCancelar = this.handleCancelar.bind(this);
        this.codBarrasOnKeyPress = this.codBarrasOnKeyPress.bind(this);
    }

    async componentDidMount() {
        this.setState({isLoading: true});

        await Promise.all([
            this.getSegmentos(),
            this.getUnidades(),
            this.getTipoExistencia(),
            this.getTipoAfectacion()
        ]);

        if (this.props.match.params.IdProducto) {
            await this.getProducto();
            await this.getTributosPorProducto();
        }
        if (this.state.producto.IdCommodity) {
            await this.getClasificacionSunatByIdCommodity(
                this.state.producto.IdCommodity
            );
        }
        if (this.state.producto.IdProductoOsce) {
            await this.getClasificacionSunatByIdOsce(
                this.state.producto.IdProductoOsce
            );
        }
    }

    async handleOnChangeTasaISC(e) {
        let producto = {...this.state.producto};
        producto.TasaISC = round(Number(e.target.value) / 100, 2);
        this.setState({producto: producto});
    }

    async handleOptionTributoChange(e) {
        this.setState({
            tributoSeleccionado: e.target.value
        });
        if (this.props.match.params.IdProducto) {
            await this.deleteTributoProducto(this.props.match.params.IdProducto);
            this.setState({
                ICBPER: false,
                ISC: false
            });
            await this.saveTributoProducto(this.props.match.params.IdProducto);
        }
        this.setState({
            ICBPER: false,
            ISC: false
        });
    }

    async handleICBPERChange(e) {
        if (e.target.checked) {
            this.setState({
                ICBPER: true
            });
        } else {
            this.setState({
                ICBPER: false
            });
        }
    }

    async handleISCChange(e) {
        if (e.target.checked) {
            this.setState({
                ISC: true
            });
        } else {
            this.setState({
                ISC: false
            });
        }
    }

    async getTributosPorProducto() {
        if (this.props.match.params.IdProducto) {
            try {
                const response = await fetch(
                    `/api/productos/tributos/${this.props.match.params.IdProducto}`
                );
                const TributosGuardadosProducto = await response.json();

                console.log(TributosGuardadosProducto)
                if (
                    TributosGuardadosProducto.length > 0 ||
                    TributosGuardadosProducto.respuesta
                ) {

                    const esICBPER = TributosGuardadosProducto.respuesta.some(
                        tributo => tributo.IdTipoTributo === TRIBUTO.ICBPER
                    );

                    const esISC = TributosGuardadosProducto.respuesta.some(
                        tributo => tributo.IdTipoTributo === TRIBUTO.ISC
                    );

                    const tributoSeleccionado = TributosGuardadosProducto.respuesta.find(
                        tributo => (tributo.IdTipoTributo !== TRIBUTO.ISC) && (tributo.IdTipoTributo !== TRIBUTO.ICBPER)
                    );

                    this.setState({
                        TributosGuardadosProducto: TributosGuardadosProducto.respuesta,
                        tributoSeleccionado: String(tributoSeleccionado.IdTipoTributo),
                        ISC: esISC,
                        ICBPER: esICBPER,
                        isLoading: false
                    });
                }
                return TributosGuardadosProducto;
            } catch (error) {
                this.setState({error, isLoading: false});
            }
        }
    }

    async saveTributoProducto(IdProducto) {
        const Tributos = [{IdTributo: this.state.tributoSeleccionado}];
        if (this.state.ICBPER) Tributos.push({IdTributo: TRIBUTO.ICBPER});
        if (this.state.ISC) Tributos.push({IdTributo: TRIBUTO.ISC});

        const TributosProductos = Tributos.map(tributo => {
            return [IdProducto, Number(tributo.IdTributo)];
        });
        try {
            const response = await fetch(`/api/productos/tributos/new`, {
                method: "POST",
                body: JSON.stringify({
                    TributosProductos
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            const respuesta = await response.json();
            this.setState({
                respuestaTributoProducto: respuesta.respuesta,
                isLoading: false
            });
            return respuesta;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async deleteTributoProducto(IdProducto) {
        try {
            const response = await fetch(
                `/api/productos/tributos/delete/${IdProducto}`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        IdProducto: IdProducto
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                }
            );
            const respuestaDelete = await response.json();
            this.setState({
                respuestaDelete: respuestaDelete.respuesta,
                isLoading: false
            });
            return respuestaDelete;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    handleFamiliaChange(e) {
        let producto = {...this.state.producto};
        producto.IdFamilia = e.value;
        this.setState({producto: producto});
        this.getClases(this.state.producto.IdFamilia);
        this.setState({idclase: null, idosce: null, idcommodity: null});
    }

    handleSegmentoChange(e) {
        let producto = {...this.state.producto};
        producto.IdSegmento = e.value;
        this.setState({producto: producto});
        this.getFamilias(this.state.producto.IdSegmento);
        this.setState({
            idfamilia: null,
            idclase: null,
            idosce: null,
            idcommodity: null
        });
    }

    handleClaseChange(e) {
        let producto = {...this.state.producto};
        producto.IdClase = e.value;
        this.setState({producto: producto});
        this.getCommodities(this.state.producto.IdClase);
        this.setState({idosce: null, idcommodity: null});
    }

    handleCommodityChange(e) {
        let producto = {...this.state.producto};
        producto.IdCommodity = e.value;
        this.setState({producto: producto});
        this.getClasificacionSunatByIdCommodity(this.state.producto.IdCommodity);
    }

    async getClasificacionSunatByIdCommodity(IdCommodity) {
        await fetch(`/api/productos/clasificacionsunat/idcommodity/${IdCommodity}`)
            .then(response => response.json())
            .then(clasificacion =>
                this.setState({
                    clasificacionSunat: clasificacion.respuesta[0],
                    isLoading: false
                })
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    async handleOsceChange(e) {
        let producto = {...this.state.producto};
        producto.IdProductoOsce = e.value;
        this.setState({producto: producto});
        const IdCommodity = await this.getClasificacionSunatByIdOsce(
            this.state.producto.IdProductoOsce
        );
        producto.IdCommodity = IdCommodity;
        this.setState({
            producto: producto
        });
    }

    async getClasificacionSunatByIdOsce(IdProductoOsce) {
        try {
            const response = await fetch(
                `/api/productos/clasificacionsunat/idproductoosce/${IdProductoOsce}`
            );
            const clasificacion = await response.json();
            this.setState({
                clasificacionSunat: clasificacion.respuesta[0],
                isLoading: false
            });
            this.setState({
                loading: false
            });
            return clasificacion.respuesta[0].IdCommodity;
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    handleUnidadChange(e) {
        let producto = {...this.state.producto};
        producto.IdUnidad = e.value;
        this.setState({producto: producto});
    }

    handleTipoExistenciaChange(e) {
        let producto = {...this.state.producto};
        producto.IdTipoExistencia = e.value;
        this.setState({producto: producto});
    }

    handleAfectacionChange(e) {
        let producto = {...this.state.producto};
        producto.IdAfectacionIgv = e.value;
        this.setState({producto: producto});
    }

    handleNombreChange(e) {
        let producto = {...this.state.producto};
        producto.Nombre = e.target.value;
        this.setState({producto: producto});
    }

    handleDescripcionChange(e) {
        let producto = {...this.state.producto};
        producto.Descripcion = e.target.value;
        this.setState({producto: producto});
    }

    handleCodigoBarrasChange(e) {
        let producto = {...this.state.producto};
        producto.CodigoBarra = e.target.value;
        this.setState({producto: producto});
    }

    handleStockMinimo(e) {
        let producto = {...this.state.producto};
        producto.StockMinimo = e.target.value;
        this.setState({producto: producto});
    }

    async getSegmentos() {
        await fetch("/api/productos/segmentos")
            .then(response => response.json())
            .then(segmentos =>
                this.setState({segmentos: segmentos.respuesta, isLoading: false})
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    async getUnidades() {
        await fetch("/api/productos/unidades")
            .then(response => response.json())
            .then(unidades =>
                this.setState({unidades: unidades.respuesta, isLoading: false})
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    async getTipoExistencia() {
        await fetch("/api/productos/tipoexistencias")
            .then(response => response.json())
            .then(tipoexistencia =>
                this.setState({
                    tipoexistencia: tipoexistencia.respuesta,
                    isLoading: false
                })
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    async getTipoAfectacion() {
        await fetch("/api/productos/afectacion")
            .then(response => response.json())
            .then(afectacion =>
                this.setState({afectacion: afectacion.respuesta, isLoading: false})
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    handleOnChangeBusquedaCommodity(e) {
        this.setState({busquedaPorCommodity: e.target.value});
    }

    async buscarPorCommodity() {
        this.setState({
            loading: true,
            error: null
        });
        try {
            if (!this.state.busquedaPorCommodity.length) {
                swal(
                    "ERROR",
                    "La búsqueda es incorrecta y/o debe tener por lo menos 3 carácteres",
                    "error"
                );
            } else {
                await this.getClasificacionSunatByCommodity(
                    this.state.busquedaPorCommodity
                );
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    async getClasificacionSunatByCommodity(busquedaPorCommodity) {
        await fetch(
            `/api/productos/clasificacionsunat/commodity/${busquedaPorCommodity}`
        )
            .then(response => response.json())
            .then(clasificacion =>
                this.setState({
                    commodities: clasificacion.respuesta,
                    isLoading: false
                })
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    handleOnChangeBusquedaOsce(e) {
        this.setState({busquedaPorOsce: e.target.value});
    }

    async buscarPorOsce() {
        this.setState({
            loading: true,
            error: null
        });
        try {
            if (!this.state.busquedaPorOsce.length) {
                swal(
                    "ERROR",
                    "La búsqueda es incorrecta y/o debe tener por lo menos 3 carácteres",
                    "error"
                );
            } else {
                await this.getClasificacionSunatByOsce(this.state.busquedaPorOsce);
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    async getClasificacionSunatByOsce(busquedaPorOsce) {
        await fetch(`/api/productos/clasificacionsunat/osce/${busquedaPorOsce}`)
            .then(response => response.json())
            .then(clasificacion =>
                this.setState({
                    osces: clasificacion.respuesta,
                    isLoading: false
                })
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    async getFamilias() {
        await fetch(`/api/productos/familias`)
            .then(response => response.json())
            .then(familias =>
                this.setState({familias: familias.respuesta, isLoading: false})
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    async getClases() {
        await fetch(`/api/productos/clases`)
            .then(response => response.json())
            .then(clases =>
                this.setState({clases: clases.respuesta, isLoading: false})
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    async getCommodities() {
        await fetch(`/api/productos/commodities`)
            .then(response => response.json())
            .then(commodities =>
                this.setState({commodities: commodities.respuesta, isLoading: false})
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    async getOsces() {
        await fetch(`/api/productos/productososce`)
            .then(response => response.json())
            .then(osces =>
                this.setState({osces: osces.respuesta, isLoading: false})
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    async getProducto() {
        await fetch(`/api/productos/get/${this.props.match.params.IdProducto}`)
            .then(response => response.json())
            .then(async producto => {
                this.setState({
                    producto: producto.respuesta[0],
                    isLoading: false
                });
            }).catch(error => this.setState({error, isLoading: false}));
    }

    addNewProduct() {

        if (this.state.tributoSeleccionado !== String(TRIBUTO.IGV)) {
            this.state.producto.TasaISC = 0
        }

        fetch("/api/productos/new", {
            method: "POST",
            body: JSON.stringify({
                IdProducto: this.props.match.params.IdProducto,
                IdCommodity: this.state.producto.IdCommodity,
                IdProductoOsce: this.state.producto.IdProductoOsce,
                CodigoBarra: this.state.producto.CodigoBarra,
                IdUnidad: this.state.producto.IdUnidad,
                Nombre: this.state.producto.Nombre,
                Descripcion: this.state.producto.Descripcion,
                IdAfectacionIgv: this.state.producto.IdAfectacionIgv,
                IdTipoExistencia: this.state.producto.IdTipoExistencia,
                StockMinimo: this.state.producto.StockMinimo,
                TasaISC: this.state.producto.TasaISC && this.state.producto.TasaISC
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then(this.setState({isLoading: false}))
            .then(response => response.json())
            .then(responseData => {
                const [[table_data], [header]] = responseData;
                let error = false;
                if (header._estado) error = header._estado;
                else if (header._estadoInsertarProducto !== true)
                    error = header._estadoInsertarProducto;
                if (error) {
                    swal("ERROR", error, "error");
                } else {
                    const [{IdProducto}] = table_data;
                    this.setState({respuestaIdProducto: IdProducto});
                    this.saveTributoProducto(IdProducto);
                    this.props.history.push("/productos");
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({error, isLoading: false});
            });
    }

    handleCancelar() {
        this.props.history.push("/productos");
    }

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({
            loading: true,
            error: null
        });
        try {
            await this.addNewProduct();
            this.setState({
                loading: false
            });
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    };

    codBarrasOnKeyPress(e) {
        if (e.charCode === 13)
            e.preventDefault();
    }

    render() {
        const {isLoading, error} = this.state;
        if (isLoading) {
            return <PageLoading/>;
        }
        if (error) {
            return <PageError error={error}/>;
        }
        return (
            <div className="new__product__container">
                <div className="container">
                    <NewProductUI
                        {...this.state}
                        segmentos={this.state.segmentos}
                        unidades={this.state.unidades}
                        tipoexistencia={this.state.tipoexistencia}
                        tipoafectacion={this.state.afectacion}
                        seleccionarsegmento={this.handleSegmentoChange}
                        clases={this.state.clases}
                        IdSegmento={this.state.idsegmento}
                        seleccionarfamilia={this.handleFamiliaChange}
                        familias={this.state.familias}
                        IdFamilia={this.state.idfamilia}
                        IdClase={this.state.idclase}
                        seleccionarclase={this.handleClaseChange}
                        commodities={this.state.commodities}
                        IdCommodity={this.state.idcommodity}
                        seleccionarcommodity={this.handleCommodityChange}
                        osces={this.state.osces}
                        IdProductoOsce={this.state.idosce}
                        seleccionarosce={this.handleOsceChange}
                        IdUnidad={this.state.idunidad}
                        IdTipoExistencia={this.state.idtipoexistencia}
                        IdAfectacionIgv={this.state.idafectacion}
                        seleccionarunidad={this.handleUnidadChange}
                        seleccionartipoexistencia={this.handleTipoExistenciaChange}
                        seleccionarafectacion={this.handleAfectacionChange}
                        Nombre={this.state.nombreproducto}
                        escribirnombre={this.handleNombreChange}
                        Descripcion={this.state.descripcionproducto}
                        escribirdescripcion={this.handleDescripcionChange}
                        envionewproducto={this.handleSubmit}
                        CodigoBarra={this.state.codigobarra}
                        escribircodigobarras={this.handleCodigoBarrasChange}
                        producto={this.state.producto}
                        closeModal={this.props.handleOnCloseModal}
                        handleStockMinimo={this.handleStockMinimo}
                        busquedaPorCommodity={this.state.busquedaPorCommodity}
                        buscarPorCommodity={this.buscarPorCommodity}
                        handleOnChangeBusquedaCommodity={
                            this.handleOnChangeBusquedaCommodity
                        }
                        busquedaPorOsce={this.state.busquedaPorOsce}
                        buscarPorOsce={this.buscarPorOsce}
                        handleOnChangeBusquedaOsce={this.handleOnChangeBusquedaOsce}
                        clasificacionSunat={this.state.clasificacionSunat}
                        IdProducto={this.props.match.params.IdProducto}
                        handleAddtributo={this.handleAddtributo}
                        handleOptionTributoChange={this.handleOptionTributoChange}
                        handleICBPERChange={this.handleICBPERChange}
                        handleISCChange={this.handleISCChange}
                        handleOnChangeTasaISC={this.handleOnChangeTasaISC}
                        handleCancelar={this.handleCancelar}
                        codBarrasOnKeyPress={this.codBarrasOnKeyPress}
                    />
                </div>
            </div>
        );
    }
}

export default NewProduct;

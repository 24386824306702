import React from "react";
import '../styles/tabla.css';

class EmpresaSolOseTabla extends React.Component {
    
    render(){
        return (
            <table className="table table-hover text-center">
                <thead className="text-center bg-dark text-white">
                    <tr>
                        <th scope="col">Tipo de Documento</th>
                        <th scope="col">Proveedor</th>
                        <th scope="col">Usuario</th>
                        <th scope="col">Clave</th>
                        <th scope="col">Ruta</th>

                        <th scope="col">Acción</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.state.dataDet.Detalles.map((obj)=>{
                            return(
                                <tr key={obj.IdTabla}>
                                <td>{obj.Descripcion}</td>
                                <td>{obj.Proveedor}</td>
                                <td>{obj.Usuario}</td>
                                <td>{obj.Clave}</td>
                                <td>{obj.Ruta}</td>
                                <td>
                                    <button name={obj.Descripcion} value={obj.IdTabla} onClick={this.props.filtrarInfoParaEditar} className="badge badge-light">Editar <i className="fa fa-edit" title="Editar"></i></button>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
            );
    }
}
export default EmpresaSolOseTabla;
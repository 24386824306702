import React from 'react';

class PreventaAlmacenes extends React.Component{
    render(){
        return(
            <React.Fragment>
                <div className="row form-group col-12">
                    <label>Almacenes</label>
                    <select
                        required
                        className="btn btn-outline-dark dropdown-toggle col-8 ml-2"
                        aria-haspopup="true"
                        name="almacen"
                        value={this.props.IdAlmacen}
                        onChange={this.props.AlmacenChange}
                    >
                    {this.props.almacenes.map(almacen => {
                        return (
                            <option
                                key={almacen.IdAlmacen}
                                value={almacen.IdAlmacen}
                            >
                            {almacen.Nombre}
                            </option>
                        );
                    })}
                    </select>
                </div>
            </React.Fragment>
        )
    }
}
export default PreventaAlmacenes;
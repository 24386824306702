import React, {Component} from "react";
import printA4 from "../../helpers/A4";
import "./styles/Pagos.css";
import printTicket from "./Ticket";
import swal from "sweetalert";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";

import "react-moment";
import moment from "moment";
import {BotonCancelar2} from "../../componentes/Plantillas/Botones";

class DetalleRegistroVale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Vale: [],
            isLoading: null,
            error: null,
            Pagos: [],
            DetallesVale: [],
            respuestaDeleteRegistroVale: null,
            extImg: 'png'
        };
        this.getVale = this.getVale.bind(this);
        this.getValeJSX = this.getValeJSX.bind(this);
        this.getPagos = this.getPagos.bind(this);
        this.getDetalleVale = this.getDetalleVale.bind(this);
        this.handleSavePDF = this.handleSavePDF.bind(this);
        this.handlePrintTicket = this.handlePrintTicket.bind(this);
        this.handleOnDeleteVale = this.handleOnDeleteVale.bind(this);
    }

    componentDidMount() {
        this.getVale();
        this.getPagos();
        this.getDetalleVale();
    }

    handleOnDeleteVale(IdVale) {
        return async () => {
            const willDelete = await swal({
                title: "¿Está seguro?",
                text: "¿Desea anular ésta Vale?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                closeOnClickOutside: false
            });

            if (willDelete) {
                await this.deleteRegistroVale(IdVale);
                await this.getVale(IdVale);
            }
        };
    }

    async deleteRegistroVale(IdVale) {
        await fetch(`/api/vales/delete/${IdVale}`, {
            method: "POST",
            body: JSON.stringify({
                IdVale: IdVale
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then(data => {
                this.setState({isLoading: false});
                return data;
            })
            .then(response => response.json())
            .then(responseData => {
                this.setState({respuestaDeleteRegistroVale: responseData.respuesta});
                if (this.state.respuestaDeleteRegistroVale === true) {
                    swal(
                        "¡Anulado!",
                        "¡La Vale ha sido anulado satisfactoriamente!",
                        "success"
                    );
                }
            })
            .catch(error => this.setState({error, isLoading: false}));
    }

    async getVale(IdVale) {
        var IdVale = this.props.match.params.IdVale;

        await fetch(`/api/vales/get/${IdVale}`)
            .then(response => response.json())
            .then(Vale =>
                this.setState({
                    Vale: Vale.respuesta[0],
                    isLoading: false,
                    extImg: Vale.extImg
                })
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    getValeJSX(Vale) {
        if (Vale) {
            return (
                <div className="container mt-5 ml-5 mr-5">
                    <div
                        className="form-group ml-5 mr-5                                                                      ">
                        <label className="">Fecha</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={moment(Vale.FechaEmision).format("DD-MM-YYYY")}
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Moneda</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={Vale.Abreviatura}
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Cliente RUC</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={Vale.NroTipoDocumento}
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Razón Social</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={Vale.RazonSocial}
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Sucursal</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={Vale.Sucursal}
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>

                    <div className="form-group ml-5 mr-5">
                        <label className="">Total</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                Vale.Total &&
                                Vale.Total.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Descuento</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                Vale.DescuentoTotal &&
                                Vale.DescuentoTotal.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Vuelto</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                Vale.Vuelto &&
                                Vale.Vuelto.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>

                    <div className="form-group ml-5 mr-5">
                        <label className="">Gravadas</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                Vale.Gravadas &&
                                Vale.Gravadas.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Inafectas</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                Vale.Inafectas &&
                                Vale.Inafectas.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Exoneradas</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                Vale.Exoneradas &&
                                Vale.Exoneradas.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Gratuitas</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                Vale.Gratuitas &&
                                Vale.Gratuitas.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="col-2">IGV</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                Vale.IGV &&
                                Vale.IGV.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">ICBPER</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                Vale.ICBPER &&
                                Vale.ICBPER.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">ISC</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                Vale.ISC &&
                                Vale.ISC.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">IVAP</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                Vale.IVAP &&
                                Vale.IVAP.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                </div>
            );
        }
    }

    getPagosJSX(pagos) {
        if (pagos) {
            return (
                <div className="mt-5 ml-3 mr-3">
                    <ReactTable
                        id="table-pagos"
                        data={this.state.Pagos}
                        filterable
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value
                        }
                        columns={[
                            {
                                columns: [
                                    {
                                        Header: "Monto",
                                        id: "Monto",
                                        accessor: d =>
                                            d.Monto.toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }),
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["Monto"]}),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Tipo Pago",
                                        id: "TipoPago",
                                        accessor: d => d.TipoPago,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["TipoPago"]}),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Estado",
                                        id: "Estado",
                                        accessor: d =>
                                            d.Estado.toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }),
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["Estado"]}),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Fecha de Pago",
                                        id: "FechaPago",
                                        accessor: d => d.FechaPago,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["FechaPago"]
                                            }),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Referencia",
                                        id: "Referencia",
                                        accessor: d => d.Referencia,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["Referencia"]
                                            }),
                                        filterAll: true
                                    }
                                ]
                            }
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        previousText="Anterior"
                        nextText="Siguiente"
                        noDataText="No se encontraron registros"
                        loadingText="Cargando..."
                        ofText="de"
                        rowsText="filas"
                        pageText="Página"
                        pageSizeOptions={[10, 20, 25, 50, 100]}
                    />
                </div>
            );
        }
    }

    async getPagos() {
        const IdVale = this.props.match.params.IdVale;
        try {
            const response = await fetch(`/api/pagos/vale/get/${IdVale}`);
            const pagos = await response.json();
            this.setState({
                Pagos: pagos.respuesta,
                isLoading: false
            });
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    getDetalleVale(IdVale) {
        var IdVale = this.props.match.params.IdVale;

        fetch(`/api/vales/detalles/${IdVale}`)
            .then(response => response.json())
            .then(DetallesVale =>
                this.setState({
                    DetallesVale: DetallesVale.respuesta,
                    isLoading: false
                })
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    getDetalleValeJSX(items) {
        if (items) {
            return (
                <div className="mt-5 ml-3 mr-3">
                    <ReactTable
                        id="table-productos-Vale"
                        data={this.state.DetallesVale}
                        filterable
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value
                        }
                        columns={[
                            {
                                columns: [
                                    {
                                        Header: "Cantidad",
                                        id: "Cantidad",
                                        accessor: d => d.Cantidad,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["Cantidad"]}),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Nombre Producto",
                                        id: "NombreProducto",
                                        accessor: d => d.NombreProducto,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["NombreProducto"]
                                            }),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Precio",
                                        id: "PrecioVenta",
                                        accessor: d =>
                                            d.PrecioVenta &&
                                            d.PrecioVenta.toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }),
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["PrecioVenta"]
                                            }),
                                        style: {
                                            textAlign: "right"
                                        },
                                        filterAll: true
                                    },
                                    {
                                        Header: "Importe",
                                        id: "Importe",
                                        accessor: d =>
                                            d.Importe &&
                                            d.Importe.toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }),
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["Importe"]
                                            }),
                                        style: {
                                            textAlign: "right"
                                        },
                                        filterAll: true
                                    }
                                ]
                            }
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        previousText="Anterior"
                        nextText="Siguiente"
                        noDataText="No se encontraron registros"
                        loadingText="Cargando..."
                        ofText="de"
                        rowsText="filas"
                        pageText="Página"
                        pageSizeOptions={[10, 20, 25, 50, 100]}
                    />
                </div>
            );
        }
    }

    async handleSavePDF() {
        printA4(this.state.DetallesVale, this.state.Vale, this.state.extImg);
    }

    async handlePrintTicket() {
        printTicket(this.state.DetallesVale, this.state.Vale, this.state.extImg);
    }

    render() {
        return (
            <>
                <div className="detalles__venta">
                    <BotonCancelar2
                        to="/vales"
                        title="Lista de Ventas"
                        tabindex="0"
                    />
                    <div className="opciones__venta">
                        <button
                            className="boton__verdeOscuro--letras ml-1 mr-1"
                            onClick={this.handleSavePDF}
                            title="Haz click para imprimir"
                        >
                            Imprimir A4 {<i className="fas fa-print"></i>}
                        </button>
                        <button
                            className="boton__verdeClaro--letras ml-1 mr-1"
                            onClick={this.handlePrintTicket}
                            title="Haz click para imprimir"
                        >
                            Imprimir Ticket {<i className="fas fa-print"></i>}
                        </button>
                    </div>

                    <ul class="nav nav-tabs mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item">
                            <a
                                class="nav-link active font-weight-bold text-uppercase text-secondary"
                                id="pills-home-tab"
                                data-toggle="pill"
                                href="#pills-home"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                            >
                                {
                                    this.state.Vale.Serie +
                                    " - " +
                                    this.state.Vale.NumeroComprobante +
                                    " - " +
                                    "Estado: " +
                                    this.state.Vale.Estado}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link text-secondary"
                                id="pills-profile-tab"
                                data-toggle="pill"
                                href="#pills-profile"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                            >
                                Pagos
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link text-secondary"
                                id="pills-contact-tab"
                                data-toggle="pill"
                                href="#pills-contact"
                                role="tab"
                                aria-controls="pills-contact"
                                aria-selected="false"
                            >
                                Productos
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div
                            class="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                        >
                            <div>{this.getValeJSX(this.state.Vale)}</div>
                        </div>
                        <div
                            class="tab-pane fade"
                            id="pills-profile"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                        >
                            {this.getPagosJSX(this.state.Pagos)}
                        </div>
                        <div
                            class="tab-pane fade"
                            id="pills-contact"
                            role="tabpanel"
                            aria-labelledby="pills-contact-tab"
                        >
                            {this.getDetalleValeJSX(this.state.DetallesVale)}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default DetalleRegistroVale;

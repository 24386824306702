import React from 'react';
import SelectSearch from "react-select-search";
import "../../pages/styles/SelectSearch.css";
import "../styles/Caja.css";
import Notify from '../Notify';
import Modal from "../../componentes/Modal.js";
import RegistroVentaList from "./RegistrosVenta.js"
import "react-moment";
import {BotonGuardarLetras, BotonCancelar} from "../Plantillas/Botones";
import PageLoading from '../PageLoading';

class GuiaRemisionEdit extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataSucursal: [],
            dataMotivos: {
                Motivos: []
            },
            dataGuia: {
                GuiaRemision: []
            },
            AddOrEdit: "Edit",
            IdAlmacen: '',
            dataAlmacenes: {
                Almacenes: []
            },
            IdSucursal: "",
            ComentarioAdicional: "",
            IdEmpresa: "",
            Ruc: "",
            RazonSocial: "",
            Sucursal: "",
            Direccion: "",
            DireccionDeSalida: "",
            DireccionDeLlegada: "",
            FechaDeEmision: null,
            FechaDeInicioDeTraslado: "",
            IdMotivoDeTraslado: '',
            DescripcionMotivoTraslado: "",
            TipoDocDestinatario: "RUC",
            NumeroDocDestinatario: "",
            RazonSocialDestinatario: "",
            destinatarioDisabled: false,
            IdTransportista: null,
            IdCoTransportista: null,
            HabilitarCoPiloto: false,
            Serie: '',
            NumeroComprobante: '',
            numYCorrelativoRV: '',
            IdUbigeoSalida: "",
            RegionSalida: "",
            ProvinciaSalida: "",
            DistritoSalida: "",
            dataUbigeos: {
                results: []
            },
            dataregSalida: [],
            dataprovSalida: [],
            datadistSalida: [],
            IdUbigeoLlegada: "",
            IdUbiLlegada: "",
            RegionLlegada: "",
            ProvinciaLlegada: "",
            DistritoLlegada: "",
            dataregLlegada: [],
            dataprovLlegada: [],
            datadistLlegada: [],

            dataVehiculos: {
                Vehiculos: []
            },
            dataTransportistas: {
                Transportistas: []
            },
            dataCoTransportistas: [],

            statusModal: false,


            ventas: [],
            Venta: [],
            DetallesVenta: [],

            DetallesVentaNuevo: [],
            IdRegistroVentas: null,
            IdVales: null,
            Mes: null,
            Year: null,
            Peso: 0,
            IdGuiaRemision: '',
            datatipoDocumento: {
                tipoDocumento: []
            },
            IdTipoDocumentoSunat: '',
            ventasBF: [],
            loading: false,
            error: null,
        }
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleOnCloseModal = this.handleOnCloseModal.bind(this)
        this.gerRegistrosVentaPorTurnos = this.gerRegistrosVentaPorTurnos.bind(this)
        this.obtenerRV = this.obtenerRV.bind(this)
        this.obtenerV = this.obtenerV.bind(this)
    }

    componentDidMount = async () => {
        this.getInfoSucursal();
        const Fecha = new Date();
        this.setState({
            Mes: Fecha.getMonth(),
            Year: Fecha.getFullYear(),
            IdGuiaRemision: this.props.match.params.IdGuiaRemision
        });

        await Promise.all([
            this.listaUbigeos(),
            this.obtenerAlmacenes(),
            this.getTipoDocSunat(),
            this.getVehiculos(),
            this.getTransportistas(),
            this.getMotivosDeTraslado()
        ]).then(() => {
            this.obtenerGuiaRemisionPorId(this.props.match.params.IdGuiaRemision)
        })
        //setInterval(this.listaClientes,5000);
    }
    componentWillUnmount = () => {
        clearInterval(this.intervalo);
    }
    obtenerGuiaRemisionPorId = async (IdGuiaRemision) => {
        // this.setState({loading:true, error:null})
        try {
            const response = await fetch(`/api/guiaRemision/guiaPorId/${IdGuiaRemision}`);
            const data = await response.json();
            // console.log(data.GuiaRemision[0])
            if (data.GuiaRemision[0].IdTipoDocumentoSunat == 1) {
                this.setState({
                    IdVale: data.GuiaRemision[0].IdVale
                })
                this.obtenerV(data.GuiaRemision[0].IdVale)
            } else if (data.GuiaRemision[0].IdTipoDocumentoSunat == 2 || data.GuiaRemision[0].IdTipoDocumentoSunat == 4) {
                this.setState({
                    IdRegistroVenta: data.GuiaRemision[0].IdRegistroVenta
                })
                this.obtenerRV(data.GuiaRemision[0].IdRegistroVenta)
            }
            this.setState({
                // loading: true,
                dataGuia: data,
                DetallesVentaNuevo: data.GuiaRemision,
                AddOrEdit: "Edit",
                FechaDeEmision: data.GuiaRemision[0].FechaEmision,
                FechaDeInicioDeTraslado: data.GuiaRemision[0].FechaInicioTraslado,
                IdTipoDocumentoSunat: data.GuiaRemision[0].IdTipoDocumentoSunat,
                IdMotivoDeTraslado: data.GuiaRemision[0].IdMotivoDeTraslado,
                DescripcionMotivoTraslado: data.GuiaRemision[0].DescripcionMotivoTraslado,
                IdAlmacen: data.GuiaRemision[0].IdAlmacenSalida,
                IdUbigeoSalida: data.GuiaRemision[0].IdUbigeoSalida,
                Serie: data.GuiaRemision[0].SerieGR,
                NumeroComprobante: data.GuiaRemision[0].NumeroComprobanteGR,
                DireccionDeSalida: data.GuiaRemision[0].DireccionSalida,
                IdUbigeoLlegada: data.GuiaRemision[0].IdUbigeoLlegada,
                DistritoLlegada: data.GuiaRemision[0].IdUbigeoLlegada,
                DireccionDeLlegada: data.GuiaRemision[0].DireccionLlegada,
                IdVehiculo: data.GuiaRemision[0].IdVehiculo,
                RazonSocialDestinatario: data.GuiaRemision[0].Cliente,
                IdTransportista: data.GuiaRemision[0].IdTransportista,
                HabilitarCoPiloto: data.GuiaRemision[0].IdCoTransportista == null ? false : true,
                Peso: data.GuiaRemision[0].PesoTotal,
                ComentarioAdicional: data.GuiaRemision[0].ComentarioAdicional,
                numYCorrelativo: data.GuiaRemision[0].numYCorrelativoRV
            })
            await this.cargarUbigeoAlmacen(data.GuiaRemision[0].IdAlmacenSalida)
            await this.getUbigeoById(data.GuiaRemision[0].IdUbigeoLlegada)
            await this.getCoTransportista(data.GuiaRemision[0].IdCoTransportista);
            // console.log(data)
            // this.obtenerNombreUbigeoSalida(data.GuiaRemision[0].IdUbigeoSalida);
            // this.obtenerNombreUbigeoLlegada(data.GuiaRemision[0].IdUbigeoLlegada)
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getCoTransportista = async (IdCoTransportista) => {
        this.setState({loading: true, error: null})
        // console.log(this.state.HabilitarCoPiloto)
        const coTransportistas = this.state.dataTransportistas.Transportistas.filter(coTransportista => {
            return coTransportista.IdTransportista != this.state.IdTransportista
        })
        await Promise.all([
            this.ListaTransportistas(coTransportistas)
        ]).then(() => {
            if (this.state.HabilitarCoPiloto == true) {
                this.setState({
                    // loading: true,
                    dataCoTransportistas: coTransportistas,
                    IdCoTransportista: IdCoTransportista
                })
            }
        })


    }
    getUbigeoById = async (IdUbigeoLlegada) => { //Filtros
        const UbigeoLlegada = this.state.dataUbigeos.results.filter(obj => {
            return obj.IdUbigeo == IdUbigeoLlegada
        })
        // console.log(UbigeoLlegada)
        await Promise.all([ //Cargar provincias y distritos
            await this.CargarProvinciasLlegada(UbigeoLlegada[0].CodRegion),
            await this.CargarDistritosLlegada(UbigeoLlegada[0].CodProvincia)
        ]).then(() => {
            this.setState({
                IdUbiLlegada: IdUbigeoLlegada,
                RegionLlegada: UbigeoLlegada[0].CodRegion,
                ProvinciaLlegada: UbigeoLlegada[0].CodProvincia,
                DistritoLlegada: UbigeoLlegada[0].IdUbigeo,
            })

        })
    }

    CargarProvinciasLlegada = async (codreg) => {
        // console.log("codreg",codreg)
        const dataP = await this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === codreg && item.CodDistrito === "00" && item.CodProvincia != "00"
        });
        // console.log(dataP);
        this.setState({
            dataprovLlegada: dataP,
            RegionLlegada: codreg,
            ProvinciaLlegada: null,
            datadistLlegada: [],
        });
        //console.log(this.state);
    };

    CargarDistritosLlegada = async (codprov) => {
        // console.log("codprov",codprov)
        const dataD = await this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === this.state.RegionLlegada && item.CodProvincia === codprov && item.CodDistrito != "00"
        });

        // console.log(this.state.DistritoLlegada)
        this.setState({
            datadistLlegada: dataD,
            ProvinciaLlegada: codprov,
            DistritoLlegada: dataD.CodDistrito,
            IdUbigeoLlegada: this.state.IdUbigeoLlegada

        });
    };

    getTipoDocSunat = async () => {
        // this.setState({loading:true, error:null})
        try {
            const response = await fetch(`/api/guiaRemision/tipoDoc`);
            const data = await response.json();
            this.setState({
                // loading: false,
                datatipoDocumento: data
            })
            // console.log(data)
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    async handleTipoDocSunatChange(e) {
        // console.log(e.value == )
        this.setState({
            AddOrEdit: "nuevo",
            Venta: [],
            DetallesVenta: [],
            DetallesVentaNuevo: [],
            IdRegistroVentas: null,
            IdVale: null,
            TipoDocDestinatario: '',
            NumeroDocDestinatario: '',
            RazonSocialDestinatario: '',
            // loading: false,
            destinatarioDisabled: true,
            numYCorrelativoRV: ''
        })

        this.state.IdTipoDocumentoSunat = e.value
        if (e.value == 2 || e.value == 4) {
            await this.gerRegistrosVentaPorTurnos(this.state.Mes, this.state.Year);
        } else if (e.value == 1) {
            await this.gerValesPorTurnos(this.state.Mes, this.state.Year);
        }

        // console.log("IdCoTransportista ", e.value)
    }

    gerValesPorTurnos = async (Mes, Year) => {
        await fetch(`/api/guiaRemision/Vlista/${Mes + 1}/${Year}`)
            .then(response => response.json())
            .then(ventas => {
                    this.setState({
                        ventas: ventas.respuesta
                    })
                    // console.log(ventas)
                }
            )
            .catch(error => this.setState({error, loading: false}));
    }

    ListaTipoDocSunat = (TipoDocumentoSunat) => {
        return TipoDocumentoSunat.map(Documento => {
            //console.log(producto)
            return {name: Documento.NombreDoc, value: Documento.IdTipoDocumentoSunat};
        });
    }

    obtenerAlmacenes = async () => {
        // this.setState({loading:true, error:null})
        try {
            const response = await fetch(`/api/guiaRemision/AlmacenesEmpresa`);
            const data = await response.json();
            this.setState({
                // loading: false,
                dataAlmacenes: data
            })
            console.log(data)
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    obtenerRV = (IdRegistroVentas) => {
        // console.log(IdRegistroVentas)
        this.handleOnCloseModal()
        this.ObtenerVenta(IdRegistroVentas)
        if (this.state.AddOrEdit != "Edit") {
            this.ObtenerDetalleVenta(IdRegistroVentas)
        } else {
            this.CargarProductosDeDetalleGuia()
        }
    }

    obtenerV = (IdVales) => {
        // console.log(IdVales)
        this.handleOnCloseModal()
        this.ObtenerVale(IdVales)
        if (this.state.AddOrEdit != "Edit") {
            this.ObtenerDetalleVale(IdVales)
        } else {
            this.CargarProductosDeDetalleGuia()
        }
    }
    CargarProductosDeDetalleGuia = async () => {
        // console.log(this.state.dataGuia)
        this.setState({
            DetallesVentaNuevo: this.state.dataGuia.GuiaRemision,
            DetallesVenta: this.state.dataGuia.GuiaRemision,
            // isLoading: false
        })

    }

    ObtenerVale = async (IdVales) => {
        await fetch(`/api/vales/get/${IdVales}`)
            .then(response => response.json())
            .then(Venta => {
                    this.setState({
                        Venta: Venta.respuesta[0],
                        IdVales: Venta.respuesta[0].IdVale,
                        TipoDocDestinatario: Venta.respuesta[0],
                        NumeroDocDestinatario: Venta.respuesta[0].NroTipoDocumento,
                        // RazonSocialDestinatario:Venta.respuesta[0].RazonSocial,
                        // isLoading: false,
                        destinatarioDisabled: true,
                        numYCorrelativoRV: Venta.respuesta[0].Serie.concat(" - ", Venta.respuesta[0].NumeroComprobante)
                    })
                    // console.log(this.state.numYCorrelativoRV)
                }
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    ObtenerVenta = async (IdRegistroVentas) => {
        await fetch(`/api/ventas/get/${IdRegistroVentas}`)
            .then(response => response.json())
            .then(Venta => {
                    this.setState({
                        Venta: Venta.respuesta[0],
                        IdRegistroVentas: Venta.respuesta[0].IdRegistroVenta,
                        TipoDocDestinatario: Venta.respuesta[0],
                        NumeroDocDestinatario: Venta.respuesta[0].NroTipoDocumento,
                        // RazonSocialDestinatario:Venta.respuesta[0].RazonSocial,
                        // isLoading: false,
                        destinatarioDisabled: true,
                        numYCorrelativoRV: Venta.respuesta[0].Serie.concat(" - ", Venta.respuesta[0].NumeroComprobante)
                    })
                    // console.log(this.state.numYCorrelativoRV)
                }
            )
            .catch(error => this.setState({error, isLoading: false}));
    }


    ObtenerDetalleVenta = async (IdRegistroVentas) => {
        fetch(`/api/ventas/detalles/${IdRegistroVentas}`)
            .then(response => response.json())
            .then(DetallesVenta => {
                    // console.log(DetallesVenta)
                    this.setState({
                        DetallesVentaNuevo: [],
                        DetallesVenta: DetallesVenta.respuesta,
                        // isLoading: false
                    })

                    let detReg = DetallesVenta.respuesta.map(dp => ({
                        ...dp,
                        Peso: 0,
                        Observacion: ''
                    }))

                    this.setState({
                        DetallesVentaNuevo: detReg
                    })
                }
            )
            .catch(error => this.setState({error, isLoading: false}));

    }


    ObtenerDetalleVale = async (IdVale) => {
        fetch(`/api/vales/detalles/${IdVale}`)
            .then(response => response.json())
            .then(DetallesVenta => {
                    this.setState({
                        DetallesVentaNuevo: [],
                        DetallesVenta: DetallesVenta.respuesta,
                        // isLoading: false
                    })

                    let detReg = DetallesVenta.respuesta.map(dp => ({
                        ...dp,
                        Peso: 0,
                        Observacion: ''
                    }))

                    this.setState({
                        DetallesVentaNuevo: detReg
                    })
                }
            )
            .catch(error => this.setState({error, isLoading: false}));

    }


    handleMonthPickerChange = async (maskedValue, selectedYear, selectedMonth) => {
        this.setState({
            Year: selectedYear,
            Mes: selectedMonth
        });
        // console.log(selectedYear, selectedMonth)
        if (this.state.IdTipoDocumentoSunat == 2 || this.state.IdTipoDocumentoSunat == 4) {
            await this.gerRegistrosVentaPorTurnos(selectedMonth, selectedYear);
        } else if (this.state.IdTipoDocumentoSunat == 1) {
            await this.gerValesPorTurnos(selectedMonth, selectedYear);
        }

    }

    gerRegistrosVentaPorTurnos = async (Mes, Year) => {

        await fetch(`/api/guiaRemision/RVlista/${Mes + 1}/${Year}`)
            .then(response => response.json())
            .then(ventas => {
                    let ventasRF = []
                    if (this.state.IdTipoDocumentoSunat == 4) {
                        ventasRF = ventas.respuesta.filter((item) => {
                            return item.TipoComprobante == "Boleta de Venta"
                        })
                    } else {
                        ventasRF = ventas.respuesta.filter((item) => {
                            return item.TipoComprobante == "Factura de Venta"
                        })
                    }
                    this.setState({
                        ventas: ventasRF
                    })
                    // console.log(ventasRF)
                }
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    getCorrelativo = async () => {
        // this.setState({loading:true, error:null})
        try {
            const response = await fetch('/api/guiaRemision/Correlativo');
            const data = await response.json();
            // console.log(data)
            this.setState({
                loading: false,
                Serie: data.Correlativo[0].Serie,
                NumeroComprobante: data.Correlativo[0]._NumeroComprobante,
            })
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getVehiculos = async () => {
        // this.setState({loading:true, error:null})
        try {
            const response = await fetch('/api/vehiculos/');
            const data = await response.json();
            this.setState({
                // loading: false,
                dataVehiculos: data
            })
            // console.log(data)
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getTransportistas = async () => {
        // this.setState({loading:true, error:null})
        try {
            const response = await fetch('/api/transportistas/');
            const data = await response.json();
            this.setState({
                // loading: false,
                dataTransportistas: data
            })
            // console.log(data)
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getMotivosDeTraslado = async () => {
        // this.setState({ loading: true, error: null });
        try {
            const response = await fetch(`/api/guiaRemision/MotivosDeTraslado`);
            const data = await response.json();
            // console.log(data)
            this.setState({
                dataMotivos: data,
            });
            // console.log(data)
        } catch (error) {
            this.setState({
                error: error,
            });
        }
    }

    getInfoSucursal = async () => {
        // this.setState({ loading: true, error: null });
        try {
            const response = await fetch(`/api/guiaRemision/InfoEmpresa`);
            const data = await response.json();
            this.setState({
                loading: false,
                IdSucursal: data[0].IdSucursal,
                dataSucursal: data,
                Ruc: data[0].Ruc,
                RazonSocial: data[0].RazonSocial,
                Sucursal: data[0].Nombre,
                Direccion: data[0].Direccion,
                IdEmpresa: data[0].IdEmpresa
            });
            // console.log(this.state.dataSucursal[0])
            // this.obtenerAlmacenes(data[0].IdSucursal);
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }

    }

    listaUbigeos = async () => {
        // this.setState({ loading: true, error: null });
        try {
            const response = await fetch(`/api/clientes/ubigeos/`);
            const data = await response.json();
            this.setState({
                // loading: false,
                dataUbigeos: data,
            });
            const dataR = await this.state.dataUbigeos.results.filter((item) => {
                return item.CodProvincia === "00"
            });
            this.setState({
                dataregSalida: dataR,
                dataregLlegada: dataR
            });

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    };
    handleChangeRegionSalida = (e) => {
        const dataP = this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === e.target.value && item.CodDistrito === "00" && item.CodProvincia != "00"
        });
        // console.log(dataP);
        this.setState({
            dataprovSalida: dataP,
            IdUbigeoSalida: e.target.value,
            RegionSalida: e.target.value,
            ProvinciaSalida: null,
            datadistSalida: [],
        });
        //console.log(this.state);
    };
    handleChangeProvinciaSalida = (e) => {
        const dataD = this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === this.state.RegionSalida && item.CodProvincia === e.target.value && item.CodDistrito != "00"
        });
        this.setState({
            datadistSalida: dataD,
            IdUbigeoSalida: `${this.state.RegionSalida}${e.target.value}`,
            ProvinciaSalida: e.target.value,
            DistritoSalida: null,
        });
    };
    handleChangeDistritoSalida = (e) => {
        // console.log(e.target)
        this.setState({
            [e.target.name]: e.target.value,
            IdUbigeoSalida: `${this.state.RegionSalida}${this.state.ProvinciaSalida}${e.target.value}`,
        });
    };
    handleChangeRegionLlegada = (e) => {
        const dataP = this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === e.target.value && item.CodDistrito === "00" && item.CodProvincia != "00"
        });
        // console.log(dataP);
        this.setState({
            dataprovLlegada: dataP,
            IdUbigeoLlegada: e.target.value,
            RegionLlegada: e.target.value,
            ProvinciaLlegada: null,
            datadistLlegada: [],
        });
        //console.log(this.state);
    };
    handleChangeProvinciaLlegada = (e) => {
        const dataD = this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === this.state.RegionLlegada && item.CodProvincia === e.target.value && item.CodDistrito != "00"
        });
        this.setState({
            datadistLlegada: dataD,
            IdUbigeoLlegada: `${this.state.RegionLlegada}${e.target.value}`,
            ProvinciaLlegada: e.target.value,
            DistritoLlegada: null,
        });
    };
    handleChangeDistritoLlegada = (e) => {
        // console.log(e.target.name, e.target.value)
        this.setState({
            [e.target.name]: e.target.value,
            IdUbigeoLlegada: `${this.state.RegionLlegada}${this.state.ProvinciaLlegada}${e.target.value}`,
            IdUbiLlegada: e.target.value
        });
        // console.log(this.state.IdUbigeoLlegada)
    };

    handleChangeUnicos = (e) => {
        // console.log(this.state.DetallesVentaNuevo)
        this.setState({
            [e.target.name]: e.target.value,
        });
        // console.log(e.target.name, e.target.value)
    };

    handleVehiculoChange(e) {
        //console.log(e.value)
        this.state.IdVehiculo = e.value
        // console.log("IdVehiculo ", e.value)
    }

    ListaVehiculos = (Vehiculos) => {
        return Vehiculos.map(Vehiculo => {
            //console.log(producto)
            return {name: Vehiculo.Modelo.concat(', ', Vehiculo.Placa), value: Vehiculo.IdVehiculo};
        });
    }

    handleTransportistaChange(e) {
        //console.log(e.value)
        this.state.IdTransportista = e.value
        this.setState({
            HabilitarCoPiloto: false
        })
        // console.log("IdTransportista ", e.value)
    }

    handleCoTransportistaChange(e) {
        //console.log(e.value)
        this.state.IdCoTransportista = e.value
        // console.log("IdCoTransportista ", e.value)
    }

    ListaTransportistas = (Transportistas) => {
        return Transportistas.map(Transportista => {
            //console.log(producto)
            return {name: Transportista.Empleado, value: Transportista.IdTransportista};
        });
    }

    handleAlmacenChange(e) {
        // console.log(e.value)
        this.state.IdAlmacen = e.value
        this.cargarUbigeoAlmacen(e.value)
        // console.log("IdAlmacen ", e.value)
    }

    cargarUbigeoAlmacen = async (IdAlmacen) => {
        const Almacen = this.state.dataAlmacenes.Almacenes.filter(obj => {
            return obj.IdAlmacen == IdAlmacen && obj.IdSucursal == this.state.IdSucursal
        })
        // console.log(this.state.dataAlmacenes.Almacenes)
        const UbigeoSalida = this.state.dataUbigeos.results.filter(obj => {
            return obj.IdUbigeo == Almacen[0].IdUbigeo
        })

        await this.CargarProvincias(UbigeoSalida[0].CodRegion);
        await this.CargarDistritos(UbigeoSalida[0].CodProvincia);

        this.setState({
            IdUbigeoSalida: Almacen[0].IdUbigeo,
            RegionSalida: UbigeoSalida[0].CodRegion,
            ProvinciaSalida: UbigeoSalida[0].CodProvincia,
            DistritoSalida: UbigeoSalida[0].CodDistrito,
            DireccionDeSalida: Almacen[0].Direccion
        })

    }

    CargarProvincias = async (codreg) => {
        // console.log("codreg",codreg)
        const dataP = await this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === codreg && item.CodDistrito === "00" && item.CodProvincia != "00"
        });
        // console.log(dataP);
        this.setState({
            dataprovSalida: dataP,
            RegionSalida: codreg,
            ProvinciaSalida: null,
            datadistSalida: [],
        });
        //console.log(this.state);
    };

    CargarDistritos = async (codprov) => {
        // console.log("codprov",codprov)
        const dataD = await this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === this.state.RegionSalida && item.CodProvincia === codprov && item.CodDistrito != "00"
        });
        this.setState({
            datadistSalida: dataD,
            ProvinciaSalida: codprov,
            DistritoSalida: null

        });
    };


    ListaAlmacenes = (Almacenes) => {
        // console.log(Almacenes)
        return Almacenes.map(Almacen => {
            //console.log(producto)
            return {name: Almacen.Nombre, value: Almacen.IdAlmacen};
        });
    }

    handleChangeCheckBox = (e) => {
        const coTransportistas = this.state.dataTransportistas.Transportistas.filter(coTransportista => {
            return coTransportista.IdTransportista != this.state.IdTransportista
        })

        if (this.state.HabilitarCoPiloto == false) {
            // Aqui debe ir la llamada a crear la nueva lista sin el id del transportista seleccionado
            if (this.state.IdTransportista != null) {
                if (coTransportistas.length > 0) {
                    this.setState({
                        HabilitarCoPiloto: true,
                        dataCoTransportistas: coTransportistas
                    })
                } else {
                    return Notify("Registre otro Conductor", "warning", 1500);
                }
            } else {
                return Notify("Primero seleccione un Conductor", "warning", 1500);
            }
            // console.log(coTransportistas)
        } else {
            this.setState({
                HabilitarCoPiloto: false,
                dataCoTransportistas: [],
                IdCoTransportista: null
            })
            // Aqui debemos poner la lista a cero. incluyendo el IdCopiloto
        }
    }

    async handleOpenModal() {
        // await this.gerRegistrosVentaPorTurnos(this.state.Mes, this.state.Year);
        if (this.state.IdTipoDocumentoSunat === null || this.state.IdTipoDocumentoSunat === '') {
            return Notify("Debes seleccionar primero un tipo de documento de origen", "warning", 2000)
        } else {
            this.setState({
                statusModal: true
            });
        }
    }

    handleOnCloseModal() {
        this.setState({
            statusModal: false
        });
    }

    handleChangePeso = (e, item) => {
        // console.log(this.state.DetallesVentaNuevo)
        if (this.state.AddOrEdit != 'Edit') {
            if (this.state.IdTipoDocumentoSunat == 2 || this.state.IdTipoDocumentoSunat == 4) {
                this.state.DetallesVentaNuevo.map(obj => {
                    if (obj.IdDetalleVenta == item.IdDetalleVenta) {
                        obj.Peso = e.target.value
                    }
                })
            } else {
                this.state.DetallesVentaNuevo.map(obj => {
                    if (obj.IdDetalleVale == item.IdDetalleVale) {
                        obj.Peso = e.target.value
                    }
                })
            }
        } else {
            if (this.state.IdTipoDocumentoSunat == 2 || this.state.IdTipoDocumentoSunat == 4) {
                this.state.DetallesVentaNuevo.map(obj => {
                    if (obj.IdDetalleGuiaRemision == item.IdDetalleGuiaRemision) {
                        obj.Peso = e.target.value
                    }
                })
            } else {
                this.state.DetallesVentaNuevo.map(obj => {
                    if (obj.IdDetalleGuiaRemision == item.IdDetalleGuiaRemision) {
                        obj.Peso = e.target.value
                    }
                })
            }
        }


        this.calcularPesoTotal()
        this.setState(this.state.DetallesVentaNuevo)
        // console.log(this.state.DetallesVentaNuevo)
    }

    obBlurInputNumerIsEmpty = (e, item) => {

        this.state.DetallesVentaNuevo.map(obj => {
            if (obj.IdDetalleVenta == item.IdDetalleVenta) {
                if (e.target.value == '') {
                    obj.Peso = 0
                }
            }
        })
        this.setState(this.state.DetallesVentaNuevo)
    }

    calcularPesoTotal = () => {
        let total = 0
        this.state.DetallesVentaNuevo.map(obj => {
            if (obj.Peso == '') {
                total = total + 0
            } else {
                total = total + parseFloat(obj.Peso)
            }

        })
        this.setState({
            Peso: total
        })
        // console.log(this.state.Peso)
    }

    handleChangeObservacion = (e, item) => {

        this.state.DetallesVentaNuevo.map(obj => {
            if (obj.IdDetalleVenta == item.IdDetalleVenta) {
                obj.Observacion = e.target.value
            }
        })
        this.setState(this.state.DetallesVentaNuevo)
        // console.log(this.state.DetallesVentaNuevo)
    }

    NewDetalleGuiaRemision = async (IdGuiaRemision) => {
        // this.setState({loading:true, error:null});
        try {
            this.state.DetallesVentaNuevo.map(async (item) => {
                // console.log(item)
                await fetch("/api/guiaRemision/addDetalle", {
                    method: "POST",
                    body: JSON.stringify({
                        IdGuiaRemision: IdGuiaRemision,
                        Cantidad: item.Cantidad,
                        NombreProducto: item.NombreProducto,
                        IdStock: item.IdStock,
                        IdTipoStock: item.IdTipoStock,
                        IdAfectacionIGV: this.state.AddOrEdit = 'Edit' ? item.IdAfectacionIGV : item.IdAfectacionIgv,
                        PrecioVenta: item.PrecioVenta,
                        Importe: item.Importe,
                        Codigo: item.Codigo,
                        Indice: item.Indice,
                        Unidad: item.Unidad,
                        Descuento: item.Descuento,
                        Peso: item.Peso,
                        Observacion: item.Observacion,
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .then(data => console.log(data))
                    .catch(err => console.log(err));
            })
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
        this.props.history.push('/GuiaRemision/List')
    }

    editGuiaRemision = async (e) => {
        e.preventDefault();
        // this.setState({loading:true, error:null});
        if (this.state.Peso <= 0) {
            return Notify("Debe ingresar el peso total", "warning", 1500)
        } else {
            try {
                // console.log(this.state.IdGuiaRemision)
                fetch(`/api/guiaRemision/edit/${this.state.IdGuiaRemision}`, {
                    method: "PUT",
                    body: JSON.stringify({
                        IdTipoDocumentoSunat: this.state.IdTipoDocumentoSunat,
                        IdSucursal: this.state.IdSucursal,
                        FechaEmision: this.state.FechaDeEmision,
                        FechaInicioTraslado: this.state.FechaDeInicioDeTraslado,
                        IdRegistroVenta: this.state.IdRegistroVentas,
                        IdVale: this.state.IdVale,
                        IdMotivoDeTraslado: this.state.IdMotivoDeTraslado,
                        DescripcionMotivoTraslado: this.state.DescripcionMotivoTraslado,
                        IdUbigeoSalida: this.state.IdUbigeoSalida,
                        DireccionSalida: this.state.DireccionDeSalida,
                        IdUbigeoLlegada: this.state.IdUbiLlegada,
                        DireccionLLegada: this.state.DireccionDeLlegada,
                        IdVehiculo: this.state.IdVehiculo,
                        Serie: this.state.Serie,
                        NumeroComprobante: this.state.NumeroComprobante,
                        IdTransportista: this.state.IdTransportista,
                        IdCoTransportista: this.state.IdCoTransportista,
                        Peso: this.state.Peso,
                        ComentarioAdicional: this.state.ComentarioAdicional,
                        numYCorrelativo: this.state.numYCorrelativoRV,
                        IdAlmacenSalida: this.state.IdAlmacen,
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => res.json())
                    .then(async (data) => {
                        await this.NewDetalleGuiaRemision(this.state.IdGuiaRemision);
                    })
                    .then(data => console.log(data))
                    .catch(err => console.log(err));
            } catch (error) {
                this.setState({
                    loading: false,
                    error: error
                });
            }
        }
    }

    render() {
        if (this.state.loading === false && this.state.IdUbiLlegada == '') {
            return <PageLoading/>;
        }
        return (
            <React.Fragment>
                <div className="ml-4 mt-4">
                    <BotonCancelar to="/GuiaRemision/List"/>
                </div>
                <form onSubmit={this.newGuiaRemision} className="d-flex justify-content-center">
                    <div className="almacenForm">
                        <div className="AlmacenBoton">
                            Guía de Remisión - Remitente {this.state.Serie} - {this.state.NumeroComprobante}
                            <BotonGuardarLetras className="ml-3" type="submit" onClick={this.editGuiaRemision}/>
                        </div>
                        {/*  */}
                        {/* Ruc y Razon Social */}
                        <div className="almacenDiv">
                            <b className="ml-4">Ruc</b>
                            <div className="input__Almacen">
                                <input required
                                       name="Ruc"
                                       className="input__linea"
                                       disabled
                                       type="text"
                                       value={this.state.Ruc}
                                />
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">Razón Social</b>
                            <div className="input__Almacen">
                                <input required
                                       name="RazonSocial"
                                       className="input__linea "
                                       disabled
                                       type="text"
                                       value={this.state.RazonSocial}
                                />
                            </div>
                        </div>
                        {/* Info de la empresa */}
                        <div className="almacenDiv">
                            <b className="ml-4">Sucursal</b>
                            <div className="input__Almacen">
                                <input required
                                       name="Sucursal"
                                       className="input__linea"
                                       disabled
                                       type="text"
                                       value={this.state.Sucursal}
                                />
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">Dirección</b>
                            <div className="input__Almacen">
                                <input required
                                       name="Direccion"
                                       className="input__linea"
                                       disabled
                                       type="text"
                                       value={this.state.Direccion}
                                />
                            </div>
                        </div>
                        {/* Fechas (Emision e Inicio de traslado) */}
                        <div className="almacenDiv">
                            <b className="ml-4">Fecha de Emisión </b>
                            <div className="input__Almacen">
                                <input
                                    required
                                    disabled
                                    name="FechaDeEmision"
                                    onChange={this.handleChangeUnicos}
                                    value={this.state.FechaDeEmision}
                                    className="input__linea"
                                    type="date"
                                />
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">*Fecha de Inicio de Traslado</b>
                            <div className="input__Almacen">
                                <input
                                    required
                                    name="FechaDeInicioDeTraslado"
                                    onChange={this.handleChangeUnicos}
                                    className="input__linea"
                                    value={this.state.FechaDeInicioDeTraslado}
                                    min={this.state.Hoy}
                                    type="date"/>
                            </div>
                        </div>
                        {/* Documento */}
                        <div className="almacenDiv">
                            <b className="ml-4">Tipo de Documento de Origen</b>
                            <div className="input__Almacen">
                                <select disabled className="input__linea col-6" aria-haspopup="true"
                                        name="IdTipoDocumentoSunat" value={this.state.IdTipoDocumentoSunat}
                                        onChange={(e) => this.handleTipoDocSunatChange(e)}>{
                                    this.state.datatipoDocumento.tipoDocumento.map((obj) => {
                                        return <option key={obj.IdTipoDocumentoSunat}
                                                       value={obj.IdTipoDocumentoSunat}>{obj.NombreDoc}</option>
                                    })}
                                </select>
                                {/* <SelectSearch
                                                    name="IdTipoDocumentoSunat"
                                                    value={this.state.IdTipoDocumentoSunat}
                                                    onChange={(e)=>this.handleTipoDocSunatChange(e)}
                                                    options={this.ListaTipoDocSunat(this.state.datatipoDocumento.tipoDocumento)}
                                                    className="select-search-box"
                                                    placeholder="Seleccionar Tipo de Documento"
                                                /> */}
                                <button id="docOrigen"
                                        disabled
                                        type="button"
                                        className="btn btn-outline-dark"
                                        name="docOrigen"
                                        onClick={this.handleOpenModal}>
                                    <i className="fas fa-search"></i>
                                </button>
                                <input type="text" className="input__linea col-2" disabled
                                       value={this.state.numYCorrelativoRV}/>
                            </div>
                        </div>
                        {/* Motivo de translado */}
                        <div className="almacenDiv">
                            <b className="ml-4">*Motivo de Traslado</b>
                            <div className="input__Almacen">
                                <select required
                                        name="IdMotivoDeTraslado"
                                        className="input__linea"
                                        value={this.state.IdMotivoDeTraslado}
                                        onChange={this.handleChangeUnicos}>{
                                    this.state.dataMotivos.Motivos.map((obj) => {
                                        return <option key={obj.IdMotivoDeTraslado}
                                                       value={obj.IdMotivoDeTraslado}>{obj.Nombre}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        {/* Descripcion del Motivo de translado */}
                        <div className="almacenDiv">
                            <b className="ml-4">Descipción del Motivo de Traslado *</b>
                            <div className="input__Almacen">
                                <input required
                                       name="DescripcionMotivoTraslado"
                                       className="input__linea"
                                       type="text"
                                       value={this.state.DescripcionMotivoTraslado}
                                       onChange={this.handleChangeUnicos}
                                />
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">*Punto de Partida</b>
                            <div className="input__Almacen col-9">
                                <SelectSearch
                                    name="IdAlmacen"
                                    value={this.state.IdAlmacen}
                                    onChange={(e) => this.handleAlmacenChange(e)}
                                    options={this.ListaAlmacenes(this.state.dataAlmacenes.Almacenes)}
                                    className="select-search-box"
                                    placeholder="Seleccionar Almacen"
                                />
                            </div>
                        </div>

                        {/* Descripcion del Motivo de translado */}
                        <div className="almacenDiv">
                            <b className="ml-4">Ubigeo de Salida</b>
                            <div className="input__Almacen">
                                <select
                                    required
                                    disabled
                                    // onChange ={this.handleChangeRegionSalida}
                                    className="input__linea col-3"
                                    name="RegionSalida"
                                    value={this.state.RegionSalida}>
                                    <option value={null}>Selecccione región</option>
                                    {
                                        this.state.dataregSalida.map((item) => {
                                            return <option key={item.IdUbigeo}
                                                           value={item.CodRegion}> {item.NombreUbigeo} </option>
                                        })
                                    }
                                </select>
                                <select
                                    required
                                    disabled
                                    // onChange={this.handleChangeProvinciaSalida}
                                    className="input__linea col-3"
                                    name="ProvinciaSalida"
                                    value={this.state.ProvinciaSalida}>
                                    <option value={null}>Selecccione provincia</option>
                                    {
                                        this.state.dataprovSalida.map((item) => {
                                            return <option key={item.IdUbigeo}
                                                           value={item.CodProvincia}> {item.NombreUbigeo} </option>
                                        })
                                    }
                                </select>
                                <select
                                    required
                                    disabled
                                    // onChange={this.handleChangeDistritoSalida}
                                    className="input__linea col-3"
                                    name="DistritoSalida"
                                    value={this.state.DistritoSalida}>
                                    <option value={null}>Selecccione distrito</option>
                                    {
                                        this.state.datadistSalida.map((item) => {
                                            return <option key={item.IdUbigeo}
                                                           value={item.CodDistrito}> {item.NombreUbigeo} </option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        {/* Descripcion del Motivo de translado */}
                        <div className="almacenDiv">
                            <b className="ml-4">Dirección de Salida</b>
                            <div className="input__Almacen">
                                <input required
                                       name="DireccionDeSalida"
                                       className="input__linea"
                                       type="text"
                                       value={this.state.DireccionDeSalida}
                                       onChange={this.handleChangeUnicos}
                                       disabled
                                />
                            </div>
                        </div>
                        {/* DESTINATARIO */}
                        <div className="row form-group col-12">
                            <b className="ml-4">DESTINATARIO</b>
                        </div>
                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                {/* <label className="col-2" >Tipo de Documento</label>
                                                <select className="btn btn-outline-dark dropdown-toggle col-3" name="TipoDocDestinatario" onChange={this.handleChangeUnicos} aria-haspopup="true" aria-expanded="false" >
                                                    <option value="RUC" defaultValue>RUC</option>
                                                    <option value="DNI">DNI</option>
                                                </select> */}
                                <b className="ml-1">Num. Documento</b>
                                <input required
                                       disabled={this.state.destinatarioDisabled}
                                       name="NumeroDocDestinatario"
                                       className="input__linea col-3"
                                       type="text"
                                       value={this.state.NumeroDocDestinatario}
                                       onChange={this.handleChangeUnicos}
                                />
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">Apellidos y nombres o razón social</b>
                            <div className="input__Almacen">
                                <input required
                                       disabled={this.state.destinatarioDisabled}
                                       name="RazonSocialDestinatario"
                                       className="input__linea"
                                       type="text"
                                       value={this.state.RazonSocialDestinatario}
                                       onChange={this.handleChangeUnicos}
                                />
                            </div>
                        </div>

                        {/* Ubigeo de llegada */}
                        <div className="almacenDiv">
                            <b className="ml-4">*Ubigeo de Llegada</b>
                            <div className="input__Almacen">
                                <select required onChange={this.handleChangeRegionLlegada}
                                        className="input__linea col-3" name="RegionLlegada"
                                        value={this.state.RegionLlegada}>
                                    <option value={null}>Selecccione región</option>
                                    {
                                        this.state.dataregLlegada.map((item) => {
                                            return <option key={item.IdUbigeo}
                                                           value={item.CodRegion}> {item.NombreUbigeo} </option>
                                        })
                                    }
                                </select>
                                <select required onChange={this.handleChangeProvinciaLlegada}
                                        className="input__linea col-3" name="ProvinciaLlegada"
                                        value={this.state.ProvinciaLlegada}>
                                    <option value={null}>Selecccione provincia</option>
                                    {
                                        this.state.dataprovLlegada.map((item) => {
                                            return <option key={item.IdUbigeo}
                                                           value={item.CodProvincia}> {item.NombreUbigeo} </option>
                                        })
                                    }
                                </select>
                                <select required onChange={this.handleChangeDistritoLlegada}
                                        className="input__linea col-3" name="DistritoLlegada"
                                        value={this.state.DistritoLlegada}>
                                    <option value={null}>Selecccione distrito</option>
                                    {
                                        this.state.datadistLlegada.map((item) => {
                                            return <option key={item.IdUbigeo} id={item.IdUbigeo}
                                                           value={item.IdUbigeo}> {item.NombreUbigeo} </option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        {/* Direccipon de llegada */}
                        <div className="almacenDiv">
                            <b className="ml-4">*Dirección de Llegada</b>
                            <div className="input__Almacen">
                                <input required
                                       name="DireccionDeLlegada"
                                       className="input__linea"
                                       type="text"
                                       value={this.state.DireccionDeLlegada}
                                       onChange={this.handleChangeUnicos}
                                />
                            </div>
                        </div>
                        {/* TRANSPORTE */}
                        <div className="row form-group col-12">
                            <b className="ml-4">TRANSPORTE</b>
                        </div>

                        {/* Tipo de transporte e info de vehículos */}
                        <div className="almacenDiv">
                            <b className="ml-4">Vehículo: </b>
                            <div className="input__Almacen col-9">
                                <SelectSearch
                                    isDisabled
                                    name="IdVehiculo"
                                    value={this.state.IdVehiculo}
                                    onChange={(e) => this.handleVehiculoChange(e)}
                                    options={this.ListaVehiculos(this.state.dataVehiculos.Vehiculos)}
                                    className="select-search-box"
                                    placeholder="Seleccionar Vehiculo"
                                    required
                                />
                            </div>
                        </div>
                        {/* Info Transportista */}
                        <div className="almacenDiv">
                            <b className="col-3">Conductor: </b>
                            <div className="input__Almacen col-9">
                                <SelectSearch
                                    name="IdTransportista"
                                    value={this.state.IdTransportista}
                                    onChange={(e) => this.handleTransportistaChange(e)}
                                    options={this.ListaTransportistas(this.state.dataTransportistas.Transportistas)}
                                    className="select-search-box"
                                    placeholder="Seleccionar Piloto"
                                    required
                                />
                            </div>
                        </div>
                        {/* Tabla con la lista de transportistas */}
                        <div className="row form-group col-12">
                            <div className="input__Almacen col-9">
                                <b className="col-3">
                                    <label align="left" className="checkbox-toggle">
                                        <div className="toggle">
                                            <input
                                                type="checkbox"
                                                name="Valorizacion"
                                                className="toggle-state"
                                                value={this.state.HabilitarCoPiloto}
                                                onChange={this.handleChangeCheckBox}
                                                checked={this.state.HabilitarCoPiloto}
                                            />
                                            <div className="toggle-inner">
                                                <div className="indicator"/>
                                            </div>
                                            <div className="active-bg"/>
                                        </div>
                                    </label>
                                    {/* <input
                                                        type="checkbox"
                                                        onChange={this.handleChangeCheckBox}
                                                        name="HabilitarCoPiloto"
                                                        checked={this.state.HabilitarCoPiloto}
                                                        value={this.state.HabilitarCoPiloto}
                                                        /> */}
                                    Co - Conductor:
                                </b>
                                <div hidden={!this.state.HabilitarCoPiloto} className="col-9">
                                    <SelectSearch
                                        name="IdCoTransportista"
                                        value={this.state.IdCoTransportista}
                                        onChange={(e) => this.handleCoTransportistaChange(e)}
                                        options={this.ListaTransportistas(this.state.dataCoTransportistas)}
                                        className="select-search-box"
                                        placeholder="Seleccionar Copiloto"
                                    />
                                </div>
                            </div>
                        </div>
                        {<Modal
                            isOpen={this.state.statusModal}
                            onClose={this.handleOnCloseModal}
                        >
                            <RegistroVentaList
                                state={this.state}
                                handleMonthPickerChange={this.handleMonthPickerChange}
                                obtenerRV={this.obtenerRV}
                                obtenerV={this.obtenerV}
                            />
                        </Modal>}
                        <div className="row form-group col-12">
                            <b className="col-2">PRODUCTOS</b>
                        </div>
                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                <b className="ml-1">*Peso aprox. de carga (Kg):</b>
                                <input type="text"
                                       onChange={this.handleChangeUnicos}
                                       name="Peso"
                                       className="input__linea col-2"
                                       value={this.state.Peso}
                                       required
                                       disabled
                                />
                            </div>
                        </div>
                        <div className="row form-group col-12">
                            <div className="col-8">
                                <table className="table table-hover text-center col-12">
                                    <thead className="text-center bg-dark text-white">
                                    <tr>
                                        <th scope="col-1">Cantidad</th>
                                        <th scope="col-4">Item</th>
                                        <th scope="col-1">Precio</th>
                                        <th scope="col-1">Importe</th>
                                        <th scope="col-2">Peso(Kg)</th>
                                        <th scope="col-2">Observación</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.DetallesVentaNuevo.map(item => {
                                        return (
                                            <tr key={item.IdDetalleVenta}>
                                                <td>{item.Cantidad}</td>
                                                <td>{item.NombreProducto}</td>
                                                <td>{item.PrecioVenta}</td>
                                                <td>{item.Importe}</td>
                                                <td className="input__Almacen">
                                                    <input className="input__lineaaaa"
                                                           name="Peso"
                                                           value={item.Peso}
                                                           onFocus={(e) => e.target.select()}
                                                           onChange={(e) => this.handleChangePeso(e, item)}
                                                           onBlur={(e) => this.obBlurInputNumerIsEmpty(e, item)}
                                                           type="number" min={0}/>
                                                </td>
                                                <td>
                                                    <input className="input__linea" name={item.Observacion}
                                                           onChange={(e) => this.handleChangeObservacion(e, item)}
                                                           value={item.Observacion} type="text"/>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                                {/* <ReactTable
                                                    id="table-productos-venta"
                                                    data={this.state.DetallesVentaNuevo}
                                                    filterable
                                                    defaultFilterMethod={(filter, row) =>
                                                    String(row[filter.id]) === filter.value
                                                    }
                                                    columns={[
                                                    {
                                                        columns: [
                                                        {
                                                            Header: "Cantidad",
                                                            id: "Cantidad",
                                                            accessor: d => d.Cantidad,
                                                            filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, { keys: ["Cantidad"] }),
                                                            filterAll: true
                                                        },
                                                        {
                                                            Header: "Nombre Producto",
                                                            id: "NombreProducto",
                                                            accessor: d => d.NombreProducto,
                                                            filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {
                                                                keys: ["NombreProducto"]
                                                            }),
                                                            filterAll: true
                                                        },
                                                        {
                                                            Header: "Precio",
                                                            id: "PrecioVenta",
                                                            accessor: d =>
                                                            d.PrecioVenta.toLocaleString("en", {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            }),
                                                            filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {
                                                                keys: ["PrecioVenta"]
                                                            }),
                                                            style: {
                                                            textAlign: "right"
                                                            },
                                                            filterAll: true
                                                        },
                                                        {
                                                            Header: "Importe",
                                                            id: "Importe",
                                                            accessor: d =>
                                                            d.Importe.toLocaleString("en", {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            }),
                                                            filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {
                                                                keys: ["Importe"]
                                                            }),
                                                            style: {
                                                            textAlign: "right"
                                                            },
                                                            filterAll: true
                                                        },
                                                        {
                                                            Header: "Peso",
                                                            id: "Peso",
                                                            accessor: d => d.Peso,
                                                            filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {
                                                                keys: ["Peso"]
                                                            }),
                                                            Cell: f => {
                                                                return (
                                                                  <div align="center">
                                                                    <input
                                                                        type="text"
                                                                        name="Peso"
                                                                        value={f.original.Peso}
                                                                        onChange={(e) => this.handleChangePeso(
                                                                            e,f
                                                                      )}

                                                                    >

                                                                    </input>
                                                                  </div>
                                                                );
                                                            },
                                                            style: {
                                                            textAlign: "right"
                                                            },
                                                            filterAll: true
                                                        },
                                                        {
                                                            Header: "Observacion",
                                                            id: "Observacion",
                                                            accessor: d =>
                                                            d.Observacion.toLocaleString("en", {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            }),
                                                            filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, {
                                                                keys: ["Observacion"]
                                                            }),
                                                            style: {
                                                            textAlign: "right"
                                                            },
                                                            filterAll: true
                                                        }
                                                        ]
                                                    }
                                                    ]}
                                                    defaultPageSize={5}
                                                    className="-striped -highlight"
                                                    previousText="Anterior"
                                                    nextText="Siguiente"
                                                    noDataText="No se encontraron registros"
                                                    loadingText="Cargando..."
                                                    ofText="de"
                                                    rowsText="filas"
                                                    pageText="Página"
                                                    pageSizeOptions={[5,10, 20, 25, 50, 100]}
                                                /> */}
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">Comentarios</b>
                            <div className="input__Almacen">
                                <input required
                                       name="ComentarioAdicional"
                                       className="input__linea col-10"
                                       type="text"
                                       value={this.state.ComentarioAdicional}
                                       onChange={this.handleChangeUnicos}
                                />
                            </div>
                        </div>
                        {/* <div className="row form-group col-12">
                                            <Link className="btn btn-secondary m-3" to="/GuiaRemision/List">
                                                Volver
                                            </Link>
                                            <button
                                                onClick={this.editGuiaRemision}
                                                name="Guardar"
                                                className="btn btn-outline-primary col-2"
                                            >
                                                Guardar
                                            </button>


                                    </div> */}
                    </div>
                </form>
            </React.Fragment>
        )

    }

}

export default GuiaRemisionEdit;

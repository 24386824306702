import React, { Component } from 'react';
import DetallePagoUI from './DetallePagoUI';

class DetallePago extends Component {
    constructor(props) {
        super(props);

      }
    render() {
        return (
            <div>
                <DetallePagoUI
                    IdTipoPago={this.props.IdTipoPago}
                    Monto={this.props.Monto}
                    Referencia={this.props.Referencia}
                    handleMontoPago={this.props.handleMontoPago}
                    handleReferenciaPago={this.props.handleReferenciaPago}
                    handleFocus={this.props.handleFocus}
                    handleSelectPlazosCredito={this.props.handleSelectPlazosCredito}
                    PlazoCredito={this.props.PlazoCredito}
                    plazosCredito={this.props.plazosCredito}
                    handlePagoEnterKeyUp={this.props.handlePagoEnterKeyUp}
                    handleRemovePago={this.props.handleRemovePago}
                    fechaPago={this.props.fechaPago}
                />
            </div>
        );
    }
}

export default DetallePago;

import React from "react";
import EfectivoLogo from "../images/TarjetasLogo/LogoEfectivo.png";
import MasterCardLogo from "../images/TarjetasLogo/MasterCardLogoPequeño.png";
import VisaLogo from "../images/TarjetasLogo/Visa-LogoPequeño.png";
import AmericanLogo from "../images/TarjetasLogo/AmericanExpressLogoPequeño.png";
import otrasTarjetas from "../images/TarjetasLogo/otrasTarjetas.png";
import credito from "../../pages/RegistroVentas/images/credito.png";
import {
    AMERICAN,
    decimalAdjust,
    EFECTIVO,
    ESTADO_CE,
    ESTADO_VALE,
    ESTADO_VENTA,
    MASTER,
    OTROS,
    PAGO_CREDITO,
    VISA
} from "../../Global";
import {BotonCerrarCajaLetras} from "../Plantillas/Botones";
import _ from 'lodash';
import Notify from "../Notify";
import {handleSavePDF} from "./printDataCaja";
import {calcularMovsCaja, getTotalRedondeo} from "../../helpers/utils";

export default class TurnoCierre extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            MontoFinalEfectivoFisico: 0,
            MontoFinalEfectivoSistema: 0,
            MontoFinalTarjetaMasterCardFisico: 0,
            MontoFinalTarjetaVisaFisico: 0,
            MontoFinalTarjetaAmericanFisico: 0,
            MontoFinalOtraTarjetaFisico: 0,
            redondeoRVAceptados: 0,
            redondeoRVAnulados: 0,
            dataPdf: {},
            MontoFinalTarjetaMasterCardSistema: 0,
            MontoFinalTarjetaVisaSistema: 0,
            MontoFinalTarjetaAmericanSistema: 0,
            MontoFinalOtraTarjetaSistema: 0,
            MontoFinalCreditoSistema: 0,
            Saldo: 0,
            totales: {},
            isLoading: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.CerrarTurno = this.CerrarTurno.bind(this);
    }

    async getTotalByComprobante(idsTipoPago, estadoVenta, estadoVale) {
        let queryParams =
            `idsTipoPago=${idsTipoPago}&
            idTurnoCaja=${this.props.idTurno}&
            estadoVenta=${estadoVenta}&
            estadoVale=${estadoVale}            
            `.replace(/\s/g, "");
        let response = await fetch(`/api/turnos/total-ventas?${queryParams}`);
        let resJson = await response.json();
        return Number(resJson.total);
    }

    async componentDidMount() {
        this.setState({isLoading: true})
        await this.calcularTotales();
        this.setState({isLoading: false})
    }

    async CerrarTurno(e) {
        e.preventDefault();

        let total = Number(this.props.montoInicial) + Number(this.state.MontoFinalEfectivoSistema) +
            Number(this.state.MontoFinalTarjetaMasterCardSistema) +
            Number(this.state.MontoFinalTarjetaVisaSistema) +
            Number(this.state.MontoFinalTarjetaAmericanSistema) +
            Number(this.state.MontoFinalOtraTarjetaSistema);


        if (this.state.MontoFinalEfectivoFisico === 0 && total > 0)
            return Notify("El monto físico no puede ser cero, por favor ingresar la cantidad solicitada", "warning", 3000)
        this.setState({isLoading: true})
        await handleSavePDF({
            ...this.state,
            movCajas: this.props.movsCaja,
            MontoInicial: this.props.montoInicial,
            extImg: this.props.extImg
        });
        try {
            const res = await fetch(`/api/cajas/cierre/turno`, {
                method: 'PUT',
                body: JSON.stringify({
                    MontoFinalEfectivoSistema: this.state.MontoFinalEfectivoSistema,
                    MontoFinalTarjetaMasterCardSistema: this.state.MontoFinalTarjetaMasterCardSistema,
                    MontoFinalTarjetaVisaSistema: this.state.MontoFinalTarjetaVisaSistema,
                    MontoFinalTarjetaAmericanSistema: this.state.MontoFinalTarjetaAmericanSistema,
                    MontoFinalOtraTarjetaSistema: this.state.MontoFinalOtraTarjetaSistema,

                    MontoFinalEfectivoFisico: this.state.MontoFinalEfectivoFisico,
                    MontoFinalTarjetaMasterCardFisico: this.state.MontoFinalTarjetaMasterCardFisico,
                    MontoFinalTarjetaVisaFisico: this.state.MontoFinalTarjetaVisaFisico,
                    MontoFinalTarjetaAmericanFisico: this.state.MontoFinalTarjetaAmericanFisico,
                    MontoFinalOtraTarjetaFisico: this.state.MontoFinalOtraTarjetaFisico,
                    MontoFinalCreditoSistema: this.state.MontoFinalCreditoSistema,

                    billetes200: 0,
                    billetes100: 0,
                    billetes50: 0,
                    billetes20: 0,
                    billetes10: 0,
                    monedas5: 0,
                    monedas2: 0,
                    monedas1: 0,
                    monedas05: 0,
                    monedas02: 0,
                    monedas01: 0,

                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
        } catch (err) {
            console.log(err)
        }
        this.setState({isLoading: false})
        window.location.reload();
    }

    async calcularTotales() {
        let [entradas, salidas, salidasComprobante, salidasNormales] = calcularMovsCaja(this.props.movsCaja);
        let [totalOkMaster, totalBadMaster] = await this.calcularTotalMaster();
        let [totalOkVisa, totalBadVisa] = await this.calcularTotalVisa();
        let [totalOkAmerican, totalBadAmerican] = await this.calcularTotalAmerican();
        let [totalOkOtros, totalBadOtros] = await this.calcularTotalOtros();
        let [totalOkCredito, totalBadCredito] = await this.calcularCreditos();

        let totalAnuladosTarjetas = totalBadMaster + totalBadVisa + totalBadAmerican;
        let [totalEfectivo, totalEfectOk, totalEfectBad] = await this.calularTotalEfectivo(totalAnuladosTarjetas, entradas, salidasNormales);

        let redondeoOk = await getTotalRedondeo(`${ESTADO_CE.ACEPTADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.PENDIENTE}`)
        let redondeoBad = await getTotalRedondeo(`${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`)

        this.setState({
            MontoFinalEfectivoSistema: Number(decimalAdjust('floor', totalEfectivo, -2)),
            MontoFinalEfectivoSistemaTemp: Number(decimalAdjust('floor', totalEfectivo, -2)),
            MontoFinalTarjetaMasterCardSistema: Number(decimalAdjust('floor', totalOkMaster, -2)),
            MontoFinalTarjetaVisaSistema: Number(decimalAdjust('floor', totalOkVisa, -2)),
            MontoFinalTarjetaAmericanSistema: Number(decimalAdjust('floor', totalOkAmerican, -2)),
            MontoFinalOtraTarjetaSistema: Number(decimalAdjust('floor', totalOkOtros - totalBadOtros, -2)),
            MontoFinalCreditoSistema: Number(decimalAdjust('floor', totalOkCredito - totalBadCredito, -2)),
            Saldo: Number(decimalAdjust('floor', totalEfectivo, -2)),
            redondeoRVAceptados: redondeoOk,
            redondeoRVAnulados: redondeoBad,
            totales: {
                entradas: decimalAdjust('floor', entradas, -2),
                salidas: decimalAdjust('floor', salidas, -2),
                totalOkMaster: decimalAdjust('floor', totalOkMaster, -2),
                totalBadMaster: decimalAdjust('floor', totalBadMaster, -2),
                totalOkVisa: decimalAdjust('floor', totalOkVisa, -2),
                totalBadVisa: decimalAdjust('floor', totalBadVisa, -2),
                totalOkAmerican: decimalAdjust('floor', totalOkAmerican, -2),
                totalBadAmerican: decimalAdjust('floor', totalBadAmerican, -2),
                totalOkOtros: decimalAdjust('floor', totalOkOtros, -2),
                totalBadOtros: decimalAdjust('floor', totalBadOtros, -2),
                totalOkCredito: decimalAdjust('floor', totalOkCredito, -2),
                totalBadCredito: decimalAdjust('floor', totalBadCredito, -2),
                totalEfectOk: decimalAdjust('floor', totalEfectOk, -2),
                totalEfectBad: decimalAdjust('floor', totalEfectBad, -2),
                salidasComprobante: salidasComprobante,
                salidasNormales: salidasNormales
            }
        })
    }

    async calularTotalEfectivo(anuladosTarjetas, totalIngresos, salidasNormales) {
        const totalEfectOk = await this.getTotalByComprobante(EFECTIVO, `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO}`, `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`);
        const totalEfectBad = await this.getTotalByComprobante(EFECTIVO, `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`);
        return [(totalEfectOk - totalEfectBad) - anuladosTarjetas + totalIngresos - salidasNormales, totalEfectOk, totalEfectBad];
    }

    async calcularTotalMaster() {
        const totalMasterOk = await this.getTotalByComprobante(MASTER, `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO}`, `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`);
        const totalMasterBad = await this.getTotalByComprobante(MASTER, `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`);
        return [totalMasterOk, totalMasterBad];
    }

    async calcularTotalVisa() {
        const totalVisaOk = await this.getTotalByComprobante(VISA, `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO}`, `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`);
        const totalVisaBad = await this.getTotalByComprobante(VISA, `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`);
        return [totalVisaOk, totalVisaBad];
    }

    async calcularTotalAmerican() {
        const totalAmericanOk = await this.getTotalByComprobante(AMERICAN, `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO}`, `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`);
        const totalAmericanBad = await this.getTotalByComprobante(AMERICAN, `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`);
        return [totalAmericanOk, totalAmericanBad];
    }

    async calcularTotalOtros() {
        const totalOtrosOk = await this.getTotalByComprobante(OTROS, `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO}`, `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`);
        const totalOtrosBad = await this.getTotalByComprobante(OTROS, `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`);
        return [totalOtrosOk, totalOtrosBad];
    }

    async calcularCreditos() {
        const totalCreditosOk = await this.getTotalByComprobante(PAGO_CREDITO, `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO}`, `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`);
        const totalCreditosBad = await this.getTotalByComprobante(PAGO_CREDITO, `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`);
        return [totalCreditosOk, totalCreditosBad];
    }


    handleChange(e) {
        const {name, value} = e.target
        this.setState({
            [name]: e.target.getAttribute('type') === 'number' ? (_.isNumber(Number(value)) ? value : 0) : value
        })
    }


    render() {

        let totales = {
            mtoTotalEfecSis: this.state.MontoFinalEfectivoSistema,
            mtoTotalMasterSis: this.state.MontoFinalTarjetaMasterCardSistema,
            mtoTotalVisaSis: this.state.MontoFinalTarjetaVisaSistema,
            mtoTotalAmericanSis: this.state.MontoFinalTarjetaAmericanSistema,
            mtoTotalOtroSis: this.state.MontoFinalOtraTarjetaSistema,
            mtoTotalCreditoSis: this.state.MontoFinalCreditoSistema,
            saldo: this.state.Saldo,
            salidas: this.state.totales.salidas,
            salidasComprobante: this.state.totales.salidasComprobante
        };

        const totalSistema = totales.mtoTotalEfecSis + totales.mtoTotalMasterSis + totales.mtoTotalVisaSis +
            totales.mtoTotalAmericanSis + totales.mtoTotalOtroSis + totales.mtoTotalCreditoSis;

        const data = this.state.totales;
        const totalAnulados = data.totalBadOtros + data.totalBadAmerican + data.totalBadVisa + data.totalBadMaster
            + data.totalBadCredito + data.totalEfectBad;

        const totalFisico = Number(this.state.MontoFinalEfectivoFisico) +
            Number(this.state.MontoFinalTarjetaMasterCardFisico) +
            Number(this.state.MontoFinalTarjetaVisaFisico) +
            Number(this.state.MontoFinalTarjetaAmericanFisico) +
            Number(this.state.MontoFinalOtraTarjetaFisico);

        const totalEntregar = totales.mtoTotalEfecSis + this.props.montoInicial

        if (this.state.isLoading)
            return (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status"/>
                </div>
            )
        return (
            <React.Fragment>
                <form onSubmit={this.CerrarTurno}>
                    <ul className="nav nav-tabs mt-1" id="pills-tab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active text-secondary" id="pills-Montos-tab" data-toggle="pill"
                               href="#pills-montos" role="tab" aria-controls="pills-montos" aria-selected="true">
                                Ingreso de montos
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-montos" role="tabpanel"
                             aria-labelledby="pills-Montos-tab">

                            <div align="center">
                                <b>Monto Apertura: {this.props.montoInicial} </b>
                            </div>
                            <table className="table table-hover">
                                <thead className="text-center">
                                <tr>
                                    <th text="center" align="center" scope="col-6">Tipo de monto</th>
                                    <th scope="col-2" align="center">Monto Sistema</th>
                                    <th scope="col-1"> Monto Físico</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td><img src={EfectivoLogo}/>Efectivo</td>
                                    <td align="center">S/. {totales.mtoTotalEfecSis}</td>
                                    <td>
                                        <input onFocus={(e) => e.target.select()} className="input__linea"
                                               onChange={this.handleChange} type="number" step="0.1"
                                               placeholder="Monto Físico" name="MontoFinalEfectivoFisico"
                                               value={this.state.MontoFinalEfectivoFisico}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><img src={MasterCardLogo}/>Tarjeta MasterCard</td>
                                    <td align="center">S/. {totales.mtoTotalMasterSis}</td>
                                    <td>
                                        <input onFocus={(e) => e.target.select()} className="input__linea"
                                               onChange={this.handleChange} type="number" step="0.01"
                                               placeholder="MasterCard" name="MontoFinalTarjetaMasterCardFisico"
                                               defaultValue={this.state.MontoFinalTarjetaMasterCardFisico}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><img src={VisaLogo}/>Tarjeta Visa</td>
                                    <td align="center">S/. {totales.mtoTotalVisaSis}</td>
                                    <td>
                                        <input onFocus={(e) => e.target.select()} className="input__linea"
                                               onChange={this.handleChange} type="number" step="0.01"
                                               placeholder="Visa" name="MontoFinalTarjetaVisaFisico"
                                               defaultValue={this.state.MontoFinalTarjetaVisaFisico}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><img src={AmericanLogo}/>Tarjeta American Express</td>
                                    <td align="center">S/. {totales.mtoTotalAmericanSis}</td>
                                    <td>
                                        <input onFocus={(e) => e.target.select()} className="input__linea"
                                               onChange={this.handleChange} type="number" step="0.01"
                                               placeholder="American Express" name="MontoFinalTarjetaAmericanFisico"
                                               defaultValue={this.state.MontoFinalTarjetaAmericanFisico}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><img src={otrasTarjetas}/>Otra Tarjeta</td>
                                    <td align="center">S/. {totales.mtoTotalOtroSis}</td>
                                    <td>
                                        <input onFocus={(e) => e.target.select()} className="input__linea"
                                               onChange={this.handleChange} type="number" step="0.01"
                                               placeholder="Otra Tarjeta" name="MontoFinalOtraTarjetaFisico"
                                               defaultValue={this.state.MontoFinalOtraTarjetaFisico}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td><img src={credito}/>Ventas a Crèdito</td>
                                    <td align="center">S/. {totales.mtoTotalCreditoSis}</td>
                                </tr>
                                <tr>
                                    <td><i className="fas fa-percent"/> Redondeo Comprobante Aceptados</td>
                                    <td align="center">S/. {decimalAdjust('floor', this.state.redondeoRVAceptados, -3)}</td>
                                </tr>
                                <tr>
                                    <td><i className="fas fa-percent"/> Redondeo Comprobante Anulados</td>
                                    <td align="center">S/. {decimalAdjust('floor', this.state.redondeoRVAnulados, -3)}</td>
                                </tr>
                                <tr>
                                    <td>Total Anulados</td>
                                    <td align="center">S/. {decimalAdjust('floor', totalAnulados, -6)}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div align="center">
                                <label>Monto Final Total
                                    Sistema: {decimalAdjust('floor', totalSistema, -2)} </label>
                            </div>
                            <div align="center">
                                <label>Monto Final Total Físico: {totalFisico}
                                </label>
                            </div>
                            <div align="center">
                                <label style={{color: "#01a59c"}}>
                                    Total a entregar efectivo: {decimalAdjust('floor', totalEntregar, -6)}
                                </label>
                            </div>
                            <div className="col-3"/>
                            <div align="center" className="mb-4">
                                <BotonCerrarCajaLetras type="submit"/>
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    }
}

import { useDispatch } from "react-redux";
import { guardar_sesion } from "../redux/actions"

/**
 * Devuelve una funcion con las siguientes carácteristicas:
 * @return true si es exitoso, de lo contrario un objeto con los atributos: **alertType**, **header**, **message**
 */
const useLogin = () => {

    const dispatch = useDispatch();

    return async (username, password) => {

        const login_data = { username, password };
        const respuesta = await fetch("/api/auth/login", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(login_data)
        })

        switch (respuesta.status) {
            case 401: {
                const {motivo} = await respuesta.json();
                return {alertType: "error", header:"Denegado", message: motivo};
            }
            default: {
                console.log(respuesta)
                return {alertType: "error", header:"Error inesperado", message: `status: ${respuesta.status}`};
            }
            case 200: break;  // continuar, login exitoso
        }

        const sesion = await respuesta.json();

        dispatch(
            guardar_sesion(
                {...sesion, isLoggedIn: true}
            )
        );
        return true
    }

}

export default useLogin;
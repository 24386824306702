import React from "react";
import NoteReasonPicker from "../shared/NoteReasonPicker";
import Modal from "../Modal";
import InvoicePicker from "../shared/InvoicePicker";
import InvoiceDetailChanger from "./InvoiceDetailChanger";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";
import swal from "sweetalert";
import CuotaUpdater from "./CuotaUpdater";
import moment from "moment";

let _isMounted = false

export default class CreateNote extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            payload: {},
            showInvoiceModal: false,
            isLoading: false,
        }
        this.isUpdate = Boolean(this.props.match.params.noteId)
        this.readOnly = Boolean(this.props.match.params.readOnly)
        this.noteId = this.props.match.params.noteId
        Object.defineProperty(this, 'isMounted', {
            get: () => _isMounted,
            set: v => {
                _isMounted = v
            }
        })
        this.saveNote = this.saveNote.bind(this)
        this.sendSunat = this.sendSunat.bind(this)
    }

    componentWillUnmount() {
        this.isMounted = false;
    }

    componentDidMount() {
        this.isMounted = true;
        if (this.isUpdate)
            this.fetchNote(this.noteId)
    }

    fetchNote(id) {
        this.setState({isLoading: true})
        fetch(`/api/notascd/note/?noteId=${id}`)
            .then(async res => [await res.json(), res.status])
            .then(data => {
                const [res, status] = data;
                if (status === 404)
                    this.props.history.push("/not-found")
                if (status === 200)
                    this.setState({payload: res})
            })
            .catch(() => notificarError("No se pudo obtener el registro"))
            .finally(() => this.setState({isLoading: false}))
    }

    saveNote() {

        const tmpPayload = JSON.parse(JSON.stringify(this.state.payload))
        Object.keys(tmpPayload).forEach(k => {
            if (k.startsWith("_"))
                delete tmpPayload[k]
        })

        this.setState({isLoading: true})
        fetch("/api/notascd/add/v2", {
            method: "post",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(tmpPayload)
        })
            .then(async res => [await res.json(), res.status])
            .then(data => {
                const [res, status] = data;
                if (status === 200) {
                    notificarMsg("Se ha realizado con éxito")
                    return [true, res]
                }
                if (status === 422)
                    notificarError(res.msg)

                return [false, res]
            })
            .then((res) => {
                const [ok, data] = res
                if (ok) {
                    let a = Boolean(tmpPayload.sendOp) ? this.setState((state, props) => {
                        return {
                            payload: {
                                ...state.payload,
                                IdNotasCD: data.IdNotasCD
                            }
                        }
                    }, this.sendSunat) : this.props.history.push("/ListaNotasCD")
                }
            })
            .catch((err) => notificarError(`Ha ocurrido un error ${err}`))
            .finally(() => {
                if (this.isMounted)
                    this.setState({isLoading: false})
            })
    }

    sendSunat() {
        const data = this.state.payload
        this.setState({isLoading: true})
        fetch(`/api/configFactElectronica/ce/enviarnc/${data.IdRegistroVenta}?idNc=${data.IdNotasCD}`)
            .then(async res => [await res.json(), res.status])
            .then(res => {
                const [data, status] = res
                if (data.ok && status === 200) {
                    let a = data.observaciones && data.observaciones.length ? swal({
                        title: 'Se envió con errores',
                        text: `El Operador indica Nota de Crédito con errores, verifique el código`,
                        icon: 'warning'
                    }) : swal({
                        title: 'Envío Correcto',
                        text: 'La Nota de Crédito fué enviado al Operador, verifique el Código de Respuesta',
                        icon: 'success'
                    })
                } else {
                    swal({
                        title: 'Error de Envío',
                        text: `La Nota de Crédito no fué enviada al Operador`,
                        icon: 'warning'
                    })
                }
                this.props.history.push("/ListaNotasCD")
            }).catch(e => notificarError(`Error ${e}`))

    }

    render() {
        const {payload, showInvoiceModal} = this.state;
        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-auto">
                            <a href="#" onClick={e => {
                                e.preventDefault();
                                this.props.history.goBack()
                            }}>Regresar</a>
                        </div>
                        <div className="col-sm">
                            <h3>{this.readOnly ? "Detalle de nota" : (this.isUpdate ? "Editar nota" : "Crear nota")}</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group mt-3">
                                <label>Tipo de nota</label>
                                <select disabled={this.readOnly} className="form-control"
                                        value={payload.idTipoDoc || ""}
                                        onChange={e => {
                                            const value = e.target.value;
                                            this.setState((state, props) => {
                                                return {
                                                    payload: {
                                                        ...state.payload,
                                                        idTipoDoc: value,
                                                        Tipo: value.length ? (value === "8" ? 'NC' : 'ND') : ""
                                                    }
                                                }
                                            })
                                        }}>
                                    <option value="">Seleccionar</option>
                                    <option value="8">01 - Nota de Crédito</option>
                                    <option value="9">02 - Nota de Débito</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label>
                                    {payload._reason ? `Motivo seleccionado: ${payload._reason.DescripcionTipoNCD}` : "Motivo"}
                                </label>
                                <NoteReasonPicker
                                    readOnly={this.readOnly}
                                    placeholder={payload._reason ? "Seleccionar otro" : "Seleccionar"}
                                    queryParams={{noteType: payload.Tipo ? (payload.Tipo === 'NC' ? '1' : '2') : ""}}
                                    onChangeReason={r => {
                                        this.setState((state, props) => {
                                            return {
                                                payload: {
                                                    ...state.payload,
                                                    IdTipoNCD: r.length ? r[0].IdTipoNCD : null,
                                                    _reason: r.length ? r[0] : null
                                                }
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {payload._venta ? (
                            <>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label>Serie</label>
                                        <input className="form-control" type="text" value={payload._venta.Serie}
                                               readOnly/>
                                    </div>
                                </div>

                                <div className="col-sm">
                                    <div className="form-group">
                                        <label>Correlativo</label>
                                        <input className="form-control" type="text"
                                               value={payload._venta.NumeroComprobante} readOnly/>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label>Fecha emisión</label>
                                        <input className="form-control" type="text"
                                               value={moment(payload._venta.FechaEmision).format("DD/MM/YYYY")}
                                               readOnly/>
                                    </div>
                                </div>
                            </>
                        ) : null}

                        <div className="col-sm">
                            <div className="form-group mt-4">
                                <label>{payload._venta ? "" : "->"}</label>
                                <button disabled={this.readOnly} className="btn btn-link" onClick={() => {
                                    this.setState({
                                        showInvoiceModal: true
                                    })
                                }}>
                                    {payload._venta ? "Seleccionar otro documento" : "Seleccionar documento a afectar"}
                                </button>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <label>Descripción</label>
                                <textarea
                                    disabled={this.readOnly}
                                    onChange={e => {
                                        const value = e.target.value;
                                        this.setState((state, props) => {
                                            return {
                                                payload: {
                                                    ...state.payload,
                                                    Descripcion: value
                                                }
                                            }
                                        })
                                    }}
                                    cols="30" rows="5" className="form-control"
                                    value={payload.Descripcion}>{payload.Descripcion}</textarea>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm">
                            {payload._reason ? this.getComponent(payload) : null}
                        </div>
                    </div>

                    {this.readOnly || this.isUpdate ? null : (
                        <div className="row">
                            <div className="col-sm">
                                <div className="form-check">
                                    <input className="form-check-input" onChange={e => {
                                        const checked = e.target.checked;
                                        this.setState((state, props) => {
                                            return {
                                                payload: {
                                                    ...state.payload,
                                                    sendOp: Boolean(checked)
                                                }
                                            }
                                        })
                                    }} type="checkbox" checked={Boolean(payload.sendOp)} id="flexCheckDefault"/>
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        ¿Enviar a operador?
                                    </label>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="row mt-3">
                        <div className="col-sm">
                            {this.readOnly ?
                                <button disabled={this.state.isLoading} className="btn btn-outline-primary"
                                        onClick={this.sendSunat}>
                                    Enviar a operador
                                </button>
                                : <button disabled={this.state.isLoading} onClick={this.saveNote}
                                          className="btn btn-outline-primary">
                                    {this.isUpdate ? "Actualizar" : "Guardar"}
                                </button>}
                        </div>
                    </div>

                </div>

                <Modal title="Buscar venta" isOpen={showInvoiceModal}
                       onClose={() => this.setState({showInvoiceModal: false})}>
                    <div style={{width: "550px", height: "400px"}} className="mx-3 mt-3">
                        <div className="row">
                            <div className="col-sm-10">
                                <InvoicePicker onChangeInvoice={e => {
                                    this.setState((state, props) => {
                                        return {
                                            payload: {
                                                ...state.payload,
                                                IdRegistroVenta: e.length ? e[0].IdRegistroVenta : null,
                                                _venta: e.length ? e[0] : null
                                            }
                                        }
                                    })
                                }}/>
                            </div>
                            <div className="col-sm-2">
                                <button className="btn btn-outline-primary"
                                        onClick={() => this.setState({showInvoiceModal: false})}>
                                    Ok
                                </button>
                            </div>
                        </div>

                    </div>

                </Modal>
            </>
        )
    }

    getComponent(payload) {
        let reason = payload._reason
        const isGoodForNc = ["03", "05", "07"].includes(reason.CodigoTipoNCD) && payload.Tipo === "NC";
        const isGoodForNb = ["02"].includes(reason.CodigoTipoNCD) && payload.Tipo === "ND";
        if (isGoodForNc || isGoodForNb)
            return <InvoiceDetailChanger placeholder={"Detalles"} items={payload.items} readOnly={this.readOnly}
                                         invoiceId={payload.IdRegistroVenta}
                                         onChangeItems={items =>
                                             this.setState((state, props) => {
                                                 return {
                                                     payload: {
                                                         ...state.payload,
                                                         items: items
                                                     }
                                                 }
                                             })
                                         }/>
        if (payload.Tipo === "ND" && ["01", "03"].includes(reason.CodigoTipoNCD))
            return (
                <div className="form-group">
                    <label>Penalidad/interés</label>
                    <input type="number" className="form-control" value={payload.interes} disabled={this.readOnly}
                           onChange={e => {
                               const {value} = e.target;
                               this.setState((state, props) => {
                                   return {
                                       payload: {
                                           ...state.payload,
                                           interes: value
                                       }
                                   }
                               })
                           }}/>
                </div>
            )
        if (payload.Tipo === "NC" && ["02"].includes(reason.CodigoTipoNCD))
            return (
                <div className="form-group">
                    {payload.relDocs && payload.relDocs.length ? (
                        `Venta modificada seleccionada: ${payload.relDocs[0].numDoc}`
                    ) : "Seleccione venta modificada"}
                    <label>
                    </label>
                    <InvoicePicker readOnly={this.readOnly}
                                   placeholder="Seleccionar otra venta"
                                   onChangeInvoice={e => {
                                       this.setState((state, props) => {
                                           return {
                                               payload: {
                                                   ...state.payload,
                                                   relDocs: e.map(r => {
                                                       return {
                                                           tipoDoc: r.Codigo,
                                                           numDoc: `${r.Serie}-${Number(r.NumeroComprobante)}`,
                                                       }
                                                   })
                                               }
                                           }
                                       })
                                   }}/>
                </div>
            )
        if (payload.Tipo === "NC" && ["04"].includes(reason.CodigoTipoNCD))
            return (
                <div className="form-group">
                    <label>Descuento global</label>
                    <input type="number" className="form-control" value={payload.descuento} disabled={this.readOnly}
                           onChange={e => {
                               const {value} = e.target;
                               this.setState((state, props) => {
                                   return {
                                       payload: {
                                           ...state.payload,
                                           descuento: value
                                       }
                                   }
                               })
                           }}/>
                </div>
            )

        if (payload.Tipo === "NC" && reason.CodigoTipoNCD === "13")
            return <CuotaUpdater cuotas={payload.cuotas}
                                 onChangeCuotas={c => {
                                     this.setState((state, props) => {
                                         return {
                                             payload: {
                                                 ...state.payload,
                                                 cuotas: c
                                             }
                                         }
                                     })
                                 }}
                                 idVenta={payload.IdRegistroVenta}
                                 disabled={this.readOnly}/>

        return null
    }
}

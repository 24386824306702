import React from 'react'

function EmpresasLista(props){
    return(
        <div>
            <select
                  required
                  className="form-control"
                  aria-haspopup="true"
                  name="empresa"
                  onChange={props.EmpresaChange}
                  id={props.id}
                >
                  <option>Seleccionar Empresa...</option>
                  {props.dataEmpresas.map(empresa => {
                    return (
                      <option
                        key={empresa.RazonSocial}
                        value={empresa.ID}
                        title={empresa.RazonSocial}
                      >
                        {empresa.RazonSocial}
                      </option>
                    );
                  })}
            </select>
        </div>

    )
}
export default EmpresasLista
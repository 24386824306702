import React from "react";

// import firebase from 'firebase';



class EmpresasAddFormDatosLogoFirmas extends React.Component {
    
    // renderLoginButton = () => { //Funcion para saber si se está logeado en firebase o no
    //     if(this.props.state.user){
    //         return (
    //             <div>
    //                 {/* <img src={this.state.user.photoURL} width="170px" height="170px" alt={this.state.user.displayName}/>
    //                 <p>Hola {this.state.user.displayName}!</p>
    //                 <button onClick={this.handleLogout}>Salir</button> */}
    //                 <div>
    //                     <div className="row form-group col-12 ml-1">
    //                     <div className="input-group-prepend">
    //                         <span className="input-group-text" id="inputGroupFileAddon01">Logo</span>
    //                     </div>
    //                     <div className="custom-file col-10">
    //                         <input 
    //                         type="file" 
    //                         className="custom-file-input" 
    //                         aria-describedby="inputGroupFileAddon01" 
    //                         id="logoInput"  
    //                         onChange={this.props.SubirLogo}>
    //                         </input>
    //                         <label className="custom-file-label" align="left">Buscar Logo...</label>
    //                     </div>
    //                 </div>
    //                 <div className="row form-group col-12 ">
    //                 {/* <progress value={this.props.state.uploadValue} max="100"></progress> 
    //                 <br/>*/}
    //                     <img src={this.props.state.Logo}/>
    //                 </div>
    //                                     {/* #### FIRMA #### */}
    //                 <div className="input-group col-12">
    //                     <div className="input-group-prepend">
    //                         <span className="input-group-text" id="inputGroupFileAddon02">Firma</span>
    //                     </div>
    //                         <div className="custom-file col-10">
    //                             {/*Logo <FileUploader ref="uploader" name="Logo"/>*/}
    //                             <input 
    //                                 type="file" 
    //                                 className="custom-file-input" 
    //                                 aria-describedby="inputGroupFileAddon02" 
    //                                 id="FirmaInput" name="Firma" 
    //                                 onChange={this.props.SubirFirma}>
    //                             </input>
    //                             <label className="custom-file-label" align="left">Buscar Firma...</label>
    //                             <div className="invalid-feedback">Subir una imagen por favor</div>
                                
    //                         </div>
    //                     </div>
    //                     <div className="row form-group col-12 ">
    //                         {/* <progress value={this.props.state.uploadValueFirma} max="100"></progress>
    //                         <br/> */}
    //                         <img src={this.props.state.Firma}/>
    //                     </div>
    //                 </div>
    //             {/* Prueba el guardado del logo */}
    //             <div>
    //                 <input type="file" onChange={this.props.SubirLogo}></input>
    //                 <img src={this.props.state.pruebaImagen}/>
    //             </div>
    //             </div>
                
    //         )
    //     } else {
    //         return ( <button onClick={this.props.handleAuth}>Logéate con Google para subir tus imágenes</button> )
    //     }
    // }

    render(){
        return (
                        <React.Fragment>
                            {/* <img src={this.state.user.photoURL} width="170px" height="170px" alt={this.state.user.displayName}/>
                            <p>Hola {this.state.user.displayName}!</p>
                            <button onClick={this.handleLogout}>Salir</button> */}
                                <div className="row form-group col-12 ml-1">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupFileAddon01">Logo</span>
                                </div>
                                <div className="custom-file col-10">
                                    <input 
                                    type="file" 
                                    className="custom-file-input" 
                                    aria-describedby="inputGroupFileAddon01" 
                                    id="logoInput"  
                                    onChange={this.props.SubirLogo}>
                                    </input>
                                    <label className="custom-file-label" align="left">Buscar Logo...</label>
                                </div>
                            </div>
                            <div className="row form-group col-12 ">
                            {/* <progress value={this.props.state.uploadValue} max="100"></progress> 
                            <br/>*/}
                                
                                <div id="visorLogo"/>
                                    <img name="Logo" src={this.props.state.Logo}></img>
                                </div>
                                                {/* #### FIRMA #### */}
                            <div className="input-group col-12">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupFileAddon02">Firma</span>
                                </div>
                                    <div className="custom-file col-10">
                                        {/*Logo <FileUploader ref="uploader" name="Logo"/>*/}
                                        <input 
                                        
                                            type="file" 
                                            className="custom-file-input" 
                                            aria-describedby="inputGroupFileAddon02" 
                                            id="FirmaInput" name="Firma" 
                                            onChange={this.props.SubirFirma}>
                                        </input>
                                        <label className="custom-file-label" align="left">Buscar Firma...</label>
                                        <div className="invalid-feedback">Subir una imagen por favor</div>
                                        
                                    </div>
                                </div>
                                <div className="row form-group col-12 ">
                                    {/* <progress value={this.props.state.uploadValueFirma} max="100"></progress>
                                    <br/> */}
                                <div id="visorFirma"/>
                                    <img name="Firma" src={this.props.state.Firma}></img>
                                </div>
                        </React.Fragment>
        );
    }
}
export default EmpresasAddFormDatosLogoFirmas;
import React from "react";
import {notificarError} from "../Almacenes/AlmacenNotify";

export default class EmpresaPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            empresas: [],
            isLoading: false,
        }
        this.onSelectEmpresa = this.onSelectEmpresa.bind(this)
    }

    componentDidMount() {
        this.fetchEmpresas()
    }

    fetchEmpresas() {
        this.setState({isLoading: false})
        fetch("/api/empresas")
            .then(async res => [await res.json(), res.status])
            .then(d => {
                const [data, status] = d;
                if (status === 200)
                    this.setState({empresas: data.empresas})
            })
            .catch(() => notificarError("Ha ocurrido un error al momento de extraer las empresas"))
            .finally(() => this.setState({isLoading: false}))
    }

    onSelectEmpresa(e) {
        const value = e.target.value;
        const empresa = this.state.empresas.find(e => String(e.ID) === String(value))
        this.props.onSelectEmpresa(empresa)
    }

    render() {
        if (this.state.isLoading)
            return <label>Cargando empresas</label>
        return (
            <div className={this.props.divClass || "form-group"}>
                <label>Seleccione empresa</label>
                <select className="form-control" onChange={this.onSelectEmpresa}>
                    <option value="">Selecciona una empresa</option>
                    {this.state.empresas.map(e => <option key={e.ID}
                                                          value={e.ID}>{e.RazonSocial}</option>)}
                </select>
            </div>
        );
    }

}

import React from "react";
import SelectSearch from "react-select-search";
import "../pages/styles/SelectSearch.css";
import {Link} from "react-router-dom";
import "./styles/NewProduct.css";
import LinkButton from "../componentes/LinkButton";
import Presentaciones from "./Presentaciones";
import {TRIBUTO, round} from "../Global";
import {
    BotonGuardarLetras,
    BotonCancelar,
    BotonBuscar,
} from "../componentes/Plantillas/Botones";

function listCommodities(commodities) {
    return commodities.map((commodity) => {
        return {name: commodity.Descripcion, value: commodity.IdCommodity};
    });
}

function listProductosOsce(osces) {
    return osces.map((osce) => {
        return {
            name: osce.Descripcion.concat("-", osce.Codigo),
            value: osce.IdProductoOsce,
        };
    });
}

function listUnidades(unidades) {
    return unidades.map((unidad) => {
        return {name: unidad.Descripcion, value: unidad.IdUnidad};
    });
}

function listTipoExistencias(tipoexistencias) {
    return tipoexistencias.map((tipoexistencia) => {
        return {
            name: tipoexistencia.Descripcion,
            value: tipoexistencia.IdTipoExistencia,
        };
    });
}

function filtroAfectacionesIGV(afectaciones) {
    let afectacionesFiltradas = afectaciones.filter(
        (afectacion) =>
            afectacion.IdAfectacionIgv <= 7 && afectacion.IdAfectacionIgv !== 3
    );

    return afectacionesFiltradas;
}

function filtroAfectacionesIVAP(afectaciones) {
    let afectacionesFiltradas = afectaciones.filter(
        (afectacion) => afectacion.IdAfectacionIgv === 8
    );

    return afectacionesFiltradas;
}

function filtroAfectacionesEXO(afectaciones) {
    let afectacionesFiltradas = afectaciones.filter(
        (afectacion) => afectacion.IdAfectacionIgv === 9
    );

    return afectacionesFiltradas;
}

function filtroAfectacionesINA(afectaciones) {
    let afectacionesFiltradas = afectaciones.filter(
        (afectacion) =>
            afectacion.IdAfectacionIgv >= 11 &&
            afectacion.IdAfectacionIgv <= 17 &&
            afectacion.IdAfectacionIgv !== 12
    );

    return afectacionesFiltradas;
}

function filtroAfectacionesEXP(afectaciones) {
    let afectacionesFiltradas = afectaciones.filter(
        (afectacion) => afectacion.IdAfectacionIgv === 18
    );

    return afectacionesFiltradas;
}

function filtroAfectacionesISC(afectaciones) {
    let afectacionesFiltradas = afectaciones.filter(
        (afectacion) => afectacion.IdAfectacionIgv === 1
    );

    return afectacionesFiltradas;
}

function filtroAfectacionesOTROS(afectaciones) {
    let afectacionesFiltradas = afectaciones.filter(
        (afectacion) =>
            afectacion.IdAfectacionIgv !== 3 &&
            afectacion.IdAfectacionIgv !== 10 &&
            afectacion.IdAfectacionIgv !== 12
    );

    return afectacionesFiltradas;
}

function listTipoAfectaciones(afectaciones) {
    return afectaciones.map((afectacion) => {
        return {
            name: afectacion.Codigo.concat("-", afectacion.Descripcion),
            value: afectacion.IdAfectacionIgv,
        };
    });
}

const NewProductUI = (props) => {
    return (
        <>
            <div className="container">
                <BotonCancelar
                    type="button"
                    onClick={props.handleCancelar}
                    className="mb-3"
                ></BotonCancelar>
                <ul className="nav nav-tabs mt-1" id="pills-tab" role="tablist">
                    <li className="nav-item">
                        <a
                            className="nav-link active text-secondary"
                            id="pills-producto-tab"
                            data-toggle="pill"
                            href="#pills-producto"
                            role="tab"
                            aria-controls="pills-producto"
                            aria-selected="true"
                        >
                            Detalles del Producto
                        </a>
                    </li>

                    {props.IdProducto && (
                        <li className="nav-item">
                            <a
                                className="nav-link text-secondary"
                                id="pills-presentaciones-tab"
                                data-toggle="pill"
                                href="#pills-presentaciones"
                                role="tab"
                                aria-controls="pills-presentaciones"
                                aria-selected="false"
                            >
                                Presentaciones y Precios
                            </a>
                        </li>
                    )}
                </ul>

                <div className="tab-content" id="pills-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="pills-producto"
                        role="tabpanel"
                        aria-labelledby="pills-producto-tab"
                    >
                        <form>
                            <div className="mr-5 ml-5">
                                <div className="mr-5 ml-5">
                                    <div className="form-group">
                                        <label className="">Nombre</label>
                                        <input
                                            type="text"
                                            name="Nombre"
                                            autoFocus
                                            value={props.producto.Nombre}
                                            onChange={props.escribirnombre}
                                            className="input__linea"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="">Código de Barras</label>
                                        <input
                                            type="text"
                                            name="CodigoBarra"
                                            value={props.producto.CodigoBarra}
                                            onChange={props.escribircodigobarras}
                                            onKeyPress={props.codBarrasOnKeyPress}
                                            className="input__linea"
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-2">
                                            <label>Unidad de Medida</label>
                                        </div>
                                        <div className="col-10">
                                            <SelectSearch
                                                name="IdUnidad"
                                                value={props.producto.IdUnidad}
                                                onChange={props.seleccionarunidad}
                                                options={listUnidades(props.unidades)}
                                                className="select-search-box"
                                                placeholder="Selecciona"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="">Stock Mínimo</label>
                                        <input
                                            type="text"
                                            name="StockMinimo"
                                            value={props.producto.StockMinimo}
                                            onChange={props.handleStockMinimo}
                                            className="input__linea"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="">Descripción, palabras claves (separadas por comas)</label>
                                        <textarea
                                            name="Descripcion"
                                            value={props.producto.Descripcion}
                                            onChange={props.escribirdescripcion}
                                            className="input__linea"
                                            rows="3"
                                        />
                                    </div>
                                </div>
                                <div className="mr-5 ml-5">
                                    <div className="form-group">
                                        <div>
                                            <div className="row">
                                                <div className="col-2">
                                                    <label>Tipo de Existencia</label>
                                                </div>
                                                <div className="col-10">
                                                    <SelectSearch
                                                        name="IdTipoExistencia"
                                                        value={props.producto.IdTipoExistencia}
                                                        onChange={props.seleccionartipoexistencia}
                                                        options={listTipoExistencias(props.tipoexistencia)}
                                                        className="select-search-box"
                                                        placeholder="Selecciona"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2">
                                                    <label>Tipo de Tributos</label>
                                                </div>
                                                <div className="input-group col-6">
                                                    <div className="custom-control custom-radio  mr-3">
                                                        <input
                                                            type="radio"
                                                            className="custom-control-input"
                                                            value={TRIBUTO.IGV}
                                                            id={"tributo" + TRIBUTO.IGV}
                                                            name="groupOfDefaultRadios"
                                                            checked={
                                                                props.tributoSeleccionado ===
                                                                String(TRIBUTO.IGV)
                                                            }
                                                            onClick={props.handleOptionTributoChange}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor={"tributo" + TRIBUTO.IGV}
                                                        >
                                                            IGV
                                                        </label>
                                                    </div>

                                                    <div className="custom-control custom-radio mr-3">
                                                        <input
                                                            type="radio"
                                                            className="custom-control-input"
                                                            value={TRIBUTO.IVAP}
                                                            id={"tributo" + TRIBUTO.IVAP}
                                                            name="groupOfDefaultRadios"
                                                            checked={
                                                                props.tributoSeleccionado ===
                                                                String(TRIBUTO.IVAP)
                                                            }
                                                            onClick={props.handleOptionTributoChange}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor={"tributo" + TRIBUTO.IVAP}
                                                        >
                                                            IVAP
                                                        </label>
                                                    </div>
                                                    <div className="custom-control custom-radio mr-3">
                                                        <input
                                                            type="radio"
                                                            className="custom-control-input"
                                                            value={TRIBUTO.EXONERADO}
                                                            id={"tributo" + TRIBUTO.EXONERADO}
                                                            name="groupOfDefaultRadios"
                                                            checked={
                                                                props.tributoSeleccionado ===
                                                                String(TRIBUTO.EXONERADO)
                                                            }
                                                            onClick={props.handleOptionTributoChange}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor={"tributo" + TRIBUTO.EXONERADO}
                                                        >
                                                            EXO
                                                        </label>
                                                    </div>
                                                    <div className="custom-control custom-radio mr-3">
                                                        <input
                                                            type="radio"
                                                            className="custom-control-input"
                                                            value={TRIBUTO.INAFECTO}
                                                            id={"tributo" + TRIBUTO.INAFECTO}
                                                            name="groupOfDefaultRadios"
                                                            checked={
                                                                props.tributoSeleccionado ===
                                                                String(TRIBUTO.INAFECTO)
                                                            }
                                                            onClick={props.handleOptionTributoChange}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor={"tributo" + TRIBUTO.INAFECTO}
                                                        >
                                                            INA
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {props.tributoSeleccionado === String(TRIBUTO.IGV) && (
                                                <div className="row">
                                                    <div className="col-2">
                                                        <label>Otros Tributos</label>
                                                    </div>
                                                    <div className="input-group col-6">
                                                        <div className="custom-control custom-checkbox  mr-3">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                value={TRIBUTO.ICBPER}
                                                                id={"tributo" + TRIBUTO.ICBPER}
                                                                name="groupOfDefaultRadios"
                                                                checked={props.ICBPER === true}
                                                                onClick={props.handleICBPERChange}
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor={"tributo" + TRIBUTO.ICBPER}
                                                            >
                                                                ICBPER
                                                            </label>
                                                        </div>
                                                        <div className="custom-control custom-checkbox mr-3">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                value={TRIBUTO.ISC}
                                                                id={"tributo" + TRIBUTO.ISC}
                                                                name="groupOfDefaultRadios"
                                                                checked={props.ISC === true}
                                                                onClick={props.handleISCChange}
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor={"tributo" + TRIBUTO.ISC}
                                                            >
                                                                ISC
                                                            </label>
                                                        </div>
                                                        {props.ISC === true && (
                                                            <input
                                                                type="number"
                                                                value={round(props.producto.TasaISC * 100, 0)}
                                                                onChange={props.handleOnChangeTasaISC}
                                                                className=""
                                                                placeholder="% Tasa ISC"
                                                                required
                                                            ></input>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row">
                                                <div className="col-10">
                                                    {props.tributoSeleccionado === String(TRIBUTO.IGV) &&
                                                    props.ISC === false &&
                                                    props.tributoSeleccionado === String(TRIBUTO.IGV) &&
                                                    props.ICBPER === false && (
                                                        <>
                                                            <div className="col-2">
                                                                <label>Tipo de Afectación</label>
                                                            </div>
                                                            <SelectSearch
                                                                name="IdAfectacionIgv"
                                                                value={props.producto.IdAfectacionIgv}
                                                                onChange={props.seleccionarafectacion}
                                                                options={listTipoAfectaciones(
                                                                    filtroAfectacionesIGV(props.tipoafectacion)
                                                                )}
                                                                className="select-search-box"
                                                                placeholder="Selecciona"
                                                                required
                                                            />
                                                        </>
                                                    )}

                                                    {props.tributoSeleccionado ===
                                                    String(TRIBUTO.IVAP) && (
                                                        <SelectSearch
                                                            name="IdAfectacionIgv"
                                                            value={props.producto.IdAfectacionIgv}
                                                            onChange={props.seleccionarafectacion}
                                                            options={listTipoAfectaciones(
                                                                filtroAfectacionesIVAP(props.tipoafectacion)
                                                            )}
                                                            className="select-search-box"
                                                            placeholder="Selecciona"
                                                            required
                                                        />
                                                    )}

                                                    {((props.tributoSeleccionado ===
                                                        String(TRIBUTO.IGV) &&
                                                        props.ISC === true) ||
                                                        (props.tributoSeleccionado ===
                                                            String(TRIBUTO.IGV) &&
                                                            props.ICBPER === true)) && (
                                                        <SelectSearch
                                                            name="IdAfectacionIgv"
                                                            value={props.producto.IdAfectacionIgv}
                                                            onChange={props.seleccionarafectacion}
                                                            options={listTipoAfectaciones(
                                                                filtroAfectacionesISC(props.tipoafectacion)
                                                            )}
                                                            className="select-search-box"
                                                            placeholder="Selecciona"
                                                            required
                                                        />
                                                    )}

                                                    {props.tributoSeleccionado ===
                                                    String(TRIBUTO.EXPORTACION) && (
                                                        <SelectSearch
                                                            name="IdAfectacionIgv"
                                                            value={props.producto.IdAfectacionIgv}
                                                            onChange={props.seleccionarafectacion}
                                                            options={listTipoAfectaciones(
                                                                filtroAfectacionesEXP(props.tipoafectacion)
                                                            )}
                                                            className="select-search-box"
                                                            placeholder="Selecciona"
                                                            required
                                                        />
                                                    )}

                                                    {props.tributoSeleccionado ===
                                                    String(TRIBUTO.EXONERADO) && (
                                                        <SelectSearch
                                                            name="IdAfectacionIgv"
                                                            value={props.producto.IdAfectacionIgv}
                                                            onChange={props.seleccionarafectacion}
                                                            options={listTipoAfectaciones(
                                                                filtroAfectacionesEXO(props.tipoafectacion)
                                                            )}
                                                            className="select-search-box"
                                                            placeholder="Selecciona"
                                                            required
                                                        />
                                                    )}

                                                    {props.tributoSeleccionado ===
                                                    String(TRIBUTO.INAFECTO) && (
                                                        <SelectSearch
                                                            name="IdAfectacionIgv"
                                                            value={props.producto.IdAfectacionIgv}
                                                            onChange={props.seleccionarafectacion}
                                                            options={listTipoAfectaciones(
                                                                filtroAfectacionesINA(props.tipoafectacion)
                                                            )}
                                                            className="select-search-box"
                                                            placeholder="Selecciona"
                                                            required
                                                        />
                                                    )}

                                                    {props.tributoSeleccionado ===
                                                    String(TRIBUTO.OTROS) && (
                                                        <SelectSearch
                                                            name="IdAfectacionIgv"
                                                            value={props.producto.IdAfectacionIgv}
                                                            onChange={props.seleccionarafectacion}
                                                            options={listTipoAfectaciones(
                                                                filtroAfectacionesOTROS(props.tipoafectacion)
                                                            )}
                                                            className="select-search-box"
                                                            placeholder="Selecciona"
                                                            required
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mr-5 ml-5">
                                    <div className="form-group mr-5">
                                        <div className="row">
                                            <div className="col-10">
                                                {/* <div className="col-2"> */}
                                                <label>Commodity</label>
                                                {/* </div> */}
                                                <input
                                                    type="text"
                                                    value={props.busquedaPorCommodity}
                                                    onChange={props.handleOnChangeBusquedaCommodity}
                                                    onKeyDown={function (event) {
                                                        if (event.key === "Enter") {
                                                            event.preventDefault();
                                                            props.buscarPorCommodity();
                                                        }
                                                    }}
                                                    className="input__linea mb-3"
                                                    placeholder="Escriba la descripción del para buscar"
                                                />
                                            </div>
                                            <div className="col-2" align="left">
                                                <BotonBuscar
                                                    type="button"
                                                    className="mt-3"
                                                    onClick={props.buscarPorCommodity}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="">
                                                <SelectSearch
                                                    name="IdCommodity"
                                                    value={props.producto.IdCommodity}
                                                    onChange={props.seleccionarcommodity}
                                                    options={listCommodities(props.commodities)}
                                                    className="select-search-box"
                                                    placeholder="Selecciona"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10">
                                                <label>Osce</label>

                                                <input
                                                    type="text"
                                                    value={props.busquedaOsce}
                                                    onChange={props.handleOnChangeBusquedaOsce}
                                                    onKeyDown={function (event) {
                                                        if (event.key === "Enter") {
                                                            event.preventDefault();
                                                            props.buscarPorOsce();
                                                        }
                                                    }}
                                                    className="input__linea mb-3"
                                                    placeholder="Escriba la descripción del para buscar"
                                                />
                                            </div>
                                            <div className="col-2" align="left">
                                                <BotonBuscar
                                                    type="button"
                                                    className="mt-3"
                                                    onClick={props.buscarPorOsce}
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <SelectSearch
                                                name="IdProductoOsce"
                                                value={props.producto.IdProductoOsce}
                                                onChange={props.seleccionarosce}
                                                options={listProductosOsce(props.osces)}
                                                className="select-search-box"
                                                placeholder="Selecciona"
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-2">
                                                <label>Segmento</label>
                                            </div>
                                            <div className="col-10">
                                                <label
                                                    type="text"
                                                    value={props.producto.IdSegmento}
                                                    readOnly
                                                    className="mb-3"
                                                >
                                                    {props.clasificacionSunat.Segmento}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-2">
                                                <label>Familias</label>
                                            </div>
                                            <div className="col-10">
                                                <label
                                                    type="text"
                                                    value={props.clasificacionSunat.IdFamilia}
                                                    readOnly
                                                    className="mb-3"
                                                >
                                                    {props.clasificacionSunat.Familia}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-2">
                                                <label>Clase</label>
                                            </div>
                                            <div className="col-10">
                                                <label
                                                    type="text"
                                                    value={props.clasificacionSunat.IdClase}
                                                    readOnly
                                                    className="mb-3"
                                                >
                                                    {props.clasificacionSunat.Clase}
                                                </label>
                                            </div>
                                        </div>
                                        {" "}
                                        <div className="row">
                                            <div className="col-2">
                                                <label>Commodity</label>
                                            </div>
                                            <div className="col-10">
                                                <label
                                                    type="text"
                                                    value={props.clasificacionSunat.IdCommodity}
                                                    readOnly
                                                    className="mb-3"
                                                >
                                                    {props.clasificacionSunat.Commodity}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-2">
                                                <label>Osce</label>
                                            </div>
                                            <div className="col-10">
                                                <label
                                                    type="text"
                                                    value={props.clasificacionSunat.IdProductoOsce}
                                                    readOnly
                                                    className="mb-3"
                                                >
                                                    {props.clasificacionSunat.ProductoOsce}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div align="right">
                                <BotonGuardarLetras
                                    onClick={props.envionewproducto}
                                    className="mb-3 mr-3"
                                ></BotonGuardarLetras>
                            </div>
                        </form>
                    </div>

                    {props.IdProducto && (
                        <div
                            className="tab-pane fade"
                            id="pills-presentaciones"
                            role="tabpanel"
                            aria-labelledby="pills-presentaciones-tab"
                        >
                            <div>
                                <Presentaciones IdProducto={props.IdProducto}/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default NewProductUI;

import React from 'react';
import Modal from "../../componentes/Modal.js";
import Notify from '../Notify';
import {
    BotonLetrasVerdeOscuro
} from "../Plantillas/Botones";
import MovimientosCaja from "../Caja/MovimientosCaja";
import TurnoApertura from "../Caja/TurnoApertura";


class AperturarTurnosMovcaja extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modalIsOpen: false,
            IdCaja: 0,
            MontoInicial: 0,
            loading: true,
            error: null,
            extImg: 'png',
            IdTurno: 0,
            hasTurno: false,
        }
        this.verificarTurno = this.verificarTurno.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleClickNuevo = this.handleClickNuevo.bind(this);
    }

    componentDidMount = async () => {
        this.setState({loading: true})
        await this.verificarTurno();
        this.setState({loading: false})
    }

    async verificarTurno() {
        try {
            const response = await fetch(`/api/cajas/verificar/Turno`);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    extImg: data.extImg,
                    IdCaja: data.Turno[0][0].IdCaja,
                    MontoInicial: Number(data.Turno[0][0].MontoInicial),
                    IdTurno: data.Turno[0][0].IdTurnoCaja,
                    hasTurno: data.Turno[0].length > 0,
                })
            }
        } catch (error) {
            this.setState({error: error})
        }
    }

    handleCloseModal = () => {
        this.setState({
            modalIsOpen: false,
            Confirmacion: false
        })
    }

    handleClickNuevo = async () => {
        try {
            const response = await fetch(`/api/cajas/user/validar`);
            const data = await response.json();
            this.setState({
                modalIsOpen: true
            })
        } catch (error) {
            this.setState({loading: false, error: error})
            return Notify("No tienes permiso para aperturar caja. Revisar la configuración de tu rol", "warning", 2500)
        }
    }

    render() {
        if (this.state.hasTurno)
            return <MovimientosCaja montoInicial={this.state.MontoInicial} extImg={this.state.extImg}
                                    idTurno={this.state.IdTurno}
                                    idCaja={this.state.IdCaja}/>
        return (
            <div>
                <div className="preventa__container">
                    <div className="preventaaaa">
                        <BotonLetrasVerdeOscuro className="mt-2"
                                                onClick={this.handleClickNuevo}>Aperturar</BotonLetrasVerdeOscuro>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal} title="APERTURAR CAJA">
                    <TurnoApertura/>
                </Modal>
            </div>
        )
    }
}

export default AperturarTurnosMovcaja;

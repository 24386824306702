import React from 'react';
import SelectSearch from "react-select-search";
import "../../pages/styles/SelectSearch.css";
import "../styles/Caja.css";
import Notify from '../Notify';
import Modal from "../../componentes/Modal.js";
import RegistroVentaList from "./RegistrosVenta.js"
import "react-moment";
import moment from "moment";
import {BotonGuardarLetras, BotonAdd, BotonCancelar} from "../Plantillas/Botones";
import PageLoading from '../PageLoading';
import VehiculoAddForm from "../Vehiculos/VehiculoAddForm";
import AddTransportistaForm from "../Transportistas/AddTransportistaForm";
import {BOLETA, DNI, FACTURA, NOTA_EGRESO, RUC} from "../../Global";
import {notificarError} from "../Almacenes/AlmacenNotify";

class GuiaRemision extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            destinatario: {},
            Hoy: '',
            dataSucursal: [],
            dataMotivos: {
                Motivos: []
            },
            IdAlmacen: '',
            dataAlmacenes: {
                Almacenes: []
            },
            IdSucursal: "",
            ComentarioAdicional: "",
            IdEmpresa: "",
            Ruc: "",
            RazonSocial: "",
            Sucursal: "",
            Direccion: "",
            DireccionDeSalida: "",
            DireccionDeLlegada: "",
            FechaDeEmision: null,
            FechaDeInicioDeTraslado: "",
            IdMotivoDeTraslado: 1,
            DescripcionMotivoTraslado: "",
            TipoDocDestinatario: "RUC",
            NumeroDocDestinatario: "",
            RazonSocialDestinatario: "",
            destinatarioDisabled: false,
            IdTransportista: null,
            IdCoTransportista: null,
            HabilitarCoPiloto: false,
            Serie: '',
            NumeroComprobante: '',
            numYCorrelativoRV: '',
            IdUbigeoSalida: "",
            RegionSalida: "",
            ProvinciaSalida: "",
            DistritoSalida: "",
            dataUbigeos: {
                results: []
            },
            dataregSalida: [],
            dataprovSalida: [],
            datadistSalida: [],
            IdUbiLlegada: "",
            RegionLlegada: "",
            ProvinciaLlegada: "",
            DistritoLlegada: "",
            dataregLlegada: [],
            dataprovLlegada: [],
            datadistLlegada: [],

            dataVehiculos: {
                Vehiculos: []
            },
            dataTransportistas: {
                Transportistas: []
            },
            IdUbigeoLlegada: "",
            dataCoTransportistas: [],

            statusModal: false,


            ventas: [],
            Venta: [],
            DetallesVenta: [],

            DetallesVentaNuevo: [],
            IdRegistroVentas: null,
            IdVales: null,
            Mes: null,
            Year: null,
            Peso: 0,
            IdGuiaRemision: '',
            datatipoDocumento: {
                tipoDocumento: []
            },
            IdTipoDocumentoSunat: '',
            ventasBF: [],
            loading: false,
            error: null,
            numContenedor: null,
            isOpenModalVehiculo: false,
            isEmpleado: false,
            isOpenModalTrans: false,
        }
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleOnCloseModal = this.handleOnCloseModal.bind(this)
        this.gerRegistrosVentaPorTurnos = this.gerRegistrosVentaPorTurnos.bind(this)
        this.obtenerRV = this.obtenerRV.bind(this)
        this.getUbigeoByCodigoUbigeo = this.getUbigeoByCodigoUbigeo.bind(this)
        this.cargarUbigeoAlmacen = this.cargarUbigeoAlmacen.bind(this)
        this.onSaveVehiculo = this.onSaveVehiculo.bind(this)
        this.onSaveTransportista = this.onSaveTransportista.bind(this)
    }

    componentDidMount = async () => {
        this.getInfoSucursal();
        const Fecha = new Date();
        this.setState({
            Mes: Fecha.getMonth(),
            Year: Fecha.getFullYear(),
            FechaDeEmision: moment(Fecha).format('YYYY-MM-DD'),
            FechaDeInicioDeTraslado: moment(Fecha).format('YYYY-MM-DD'),
            Hoy: moment(Fecha).format('YYYY-MM-DD')
        });
        await Promise.all([
            this.listaUbigeos(),
            this.obtenerAlmacenes(),
            this.getTipoDocSunat(),
            this.getVehiculos(),
            this.getTransportistas(),
            this.getMotivosDeTraslado()
        ])
    }

    getTipoDocSunat = async () => {
        // this.setState({loading:true, error:null})
        try {
            const response = await fetch(`/api/guiaRemision/tipoDoc`);
            const data = await response.json();
            this.setState({
                // loading: false,
                datatipoDocumento: data
            })
            // console.log(data)
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    handleTipoDocSunatChange = async (e) => {

        this.setState({
            Venta: [],
            DetallesVenta: [],
            DetallesVentaNuevo: [],
            IdRegistroVentas: '',
            IdVale: '',
            TipoDocDestinatario: '',
            DireccionDeLlegada: '',
            NumeroDocDestinatario: '',
            RazonSocialDestinatario: '',
            destinatarioDisabled: true,
            numYCorrelativoRV: '',
            IdTipoDocumentoSunat: e.value,
        })

        if (String(e.value) === String(BOLETA) || String(e.value) === String(FACTURA))
            await this.gerRegistrosVentaPorTurnos(this.state.Mes, this.state.Year);
        else if (String(e.value) === String(NOTA_EGRESO))
            await this.getNotasEgreso(this.state.Mes, this.state.Year, e.value);

    }

    async getNotasEgreso(month, year, tipoDoc) {
        const res = await fetch(`/api/guiaRemision/notas?month=${month}&year=${year}&tipoDoc=${tipoDoc}`);
        const dataRes = await res.json();
        this.setState({
            ventas: dataRes
        })
    }

    ListaTipoDocSunat = (TipoDocumentoSunat) => {
        return TipoDocumentoSunat.map(Documento => {
            //console.log(producto)
            return {
                title: Documento.NombreDoc,
                name: Documento.DescripcionCorta,
                value: Documento.IdTipoDocumentoSunat
            };
        });
    }

    obtenerAlmacenes = async () => {
        // this.setState({loading:true, error:null})
        try {
            const response = await fetch(`/api/guiaRemision/AlmacenesEmpresa`);
            const data = await response.json();
            this.setState({
                // loading: false,
                dataAlmacenes: data
            })
            // console.log(data)
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    obtenerRV = (IdRegistroVentas, data) => {
        // console.log(IdRegistroVentas)
        this.handleOnCloseModal()
        this.ObtenerVenta(IdRegistroVentas, data)
        this.ObtenerDetalleVenta(IdRegistroVentas, data)
    }

    ObtenerVenta = async (IdRegistroVentas, data) => {
        if (String(this.state.IdTipoDocumentoSunat) === String(NOTA_EGRESO)) {
            await this.setState({
                Venta: data,
                IdRegistroVentas: data.IdRegistroVenta,
                isLoading: false,
                destinatarioDisabled: false,
                numYCorrelativoRV: data.numSerie
            })
        } else if (String(this.state.IdTipoDocumentoSunat) === String(BOLETA) || String(this.state.IdTipoDocumentoSunat) === String(FACTURA)) {
            await fetch(`/api/ventas/get/${IdRegistroVentas}`)
                .then(response => response.json())
                .then(async Venta => {
                        this.setState({
                            Venta: Venta.respuesta[0],
                            IdRegistroVentas: Venta.respuesta[0].IdRegistroVenta,
                            TipoDocDestinatario: Venta.respuesta[0].CodigoDocumentoCliente,
                            NumeroDocDestinatario: Venta.respuesta[0].NroTipoDocumento,
                            RazonSocialDestinatario: Venta.respuesta[0].RazonSocial,
                            isLoading: false,
                            destinatarioDisabled: true,
                            numYCorrelativoRV: Venta.respuesta[0].Serie.concat(" - ", Venta.respuesta[0].NumeroComprobante),
                            DireccionDeLlegada: Venta.respuesta[0].DireccionCliente,
                        })
                        // console.log(Venta)
                        await this.getUbigeoByCodigoUbigeo(Venta.respuesta[0].Ubigeo)
                    }
                )
                .catch(error => this.setState({error, isLoading: false}));
        }
    }

    getUbigeoByCodigoUbigeo = async (Ubigeo) => {
        const UbigeoLlegada = this.state.dataUbigeos.results.filter((item) => {
            return item.Ubigeo === Ubigeo
        });
        // console.log(UbigeoLlegada[0].IdUbigeo)
        // console.log(UbigeoLlegada)
        await Promise.all([ //Cargar provincias y distritos
            await this.CargarProvinciasLlegada(UbigeoLlegada[0].CodRegion),
            await this.CargarDistritosLlegada(UbigeoLlegada[0].CodProvincia)
        ]).then(() => {
            this.setState({
                IdUbiLlegada: UbigeoLlegada[0].IdUbigeo,
                RegionLlegada: UbigeoLlegada[0].CodRegion,
                ProvinciaLlegada: UbigeoLlegada[0].CodProvincia,
                DistritoLlegada: UbigeoLlegada[0].IdUbigeo,
            })

        })
    }

    CargarProvinciasLlegada = async (codreg) => {
        // console.log("codreg",codreg)
        const dataP = await this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === codreg && item.CodDistrito === "00" && item.CodProvincia !== "00"
        });
        // console.log(dataP);
        this.setState({
            dataprovLlegada: dataP,
            RegionLlegada: codreg,
            ProvinciaLlegada: null,
            datadistLlegada: [],
        });
        //console.log(this.state);
    };

    CargarDistritosLlegada = async (codprov) => {
        // console.log("codprov",codprov)
        const dataD = await this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === this.state.RegionLlegada && item.CodProvincia === codprov && item.CodDistrito != "00"
        });

        // console.log(this.state.DistritoLlegada)
        this.setState({
            datadistLlegada: dataD,
            ProvinciaLlegada: codprov,
            DistritoLlegada: dataD.CodDistrito,
            IdUbigeoLlegada: this.state.IdUbigeoLlegada

        });
    };

    ObtenerDetalleVenta = async (IdRegistroVentas, data) => {

        if (String(this.state.IdTipoDocumentoSunat) === String(NOTA_EGRESO)) {
            const res = await fetch(`/api/movimientosAlmacen/detallemovimiento/list/${IdRegistroVentas}`)
            const resData = await res.json();
            const movs = resData[1];

            await this.cargarUbigeoAlmacen(data.IdAlmacen)

            this.setState({
                DetallesVentaNuevo: movs.map(m => {
                    return {...m, Peso: 0, Observacion: ''}
                }),
                IdAlmacen: data.IdAlmacen
            })

        } else if (String(this.state.IdTipoDocumentoSunat) === String(BOLETA) || String(this.state.IdTipoDocumentoSunat) === String(FACTURA)) {
            fetch(`/api/ventas/detalles/${IdRegistroVentas}`)
                .then(response => response.json())
                .then(async DetallesVenta => {

                        await this.ObtenerIdAlmacenPorIdStock(DetallesVenta.respuesta[0].IdStock)

                        this.setState({
                            DetallesVentaNuevo: [],
                            DetallesVenta: DetallesVenta.respuesta,
                            isLoading: false
                        })

                        let detReg = DetallesVenta.respuesta.map(dp => ({
                            ...dp,
                            Peso: 0,
                            Observacion: ''
                        }))

                        this.setState({
                            DetallesVentaNuevo: detReg
                        })
                    }
                )
                .catch(error => this.setState({error, isLoading: false}));
        }


    }

    ObtenerIdAlmacenPorIdStock = async (IdStock) => {
        try {
            const response = await fetch(`/api/guiaRemision/AlmacenPorStock/${IdStock}`);
            const data = await response.json();

            this.setState({
                // loading: false,
                IdAlmacen: data.Almacen[0].IdAlmacen
            })
            await this.cargarUbigeoAlmacen(data.Almacen[0].IdAlmacen)
            // console.log(data)
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    handleMonthPickerChange = async (maskedValue, selectedYear, selectedMonth) => {
        this.setState({
            Year: selectedYear,
            Mes: selectedMonth
        });
        if (String(this.state.IdTipoDocumentoSunat) === String(FACTURA) ||
            String(this.state.IdTipoDocumentoSunat) === String(BOLETA)) {
            await this.gerRegistrosVentaPorTurnos(selectedMonth, selectedYear);
        } else if (String(this.state.IdTipoDocumentoSunat) === String(NOTA_EGRESO)) {
            await this.getNotasEgreso(selectedMonth, selectedYear, NOTA_EGRESO);
        }
    }

    gerRegistrosVentaPorTurnos = async (Mes, Year) => {

        await fetch(`/api/guiaRemision/RVlista/${Mes + 1}/${Year}?idTipoDoc=${this.state.IdTipoDocumentoSunat}`)
            .then(response => response.json())
            .then(ventas => {
                    let ventasRF = []
                    if (this.state.IdTipoDocumentoSunat === 4) {
                        ventasRF = ventas.respuesta.filter((item) => {
                            return item.TipoComprobante === "Boleta Electronica"
                        })
                    } else {
                        ventasRF = ventas.respuesta.filter((item) => {
                            return item.TipoComprobante === "Factura Electronica"
                        })
                    }
                    this.setState({
                        ventas: ventasRF
                    })
                }
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    getCorrelativo = async () => {
        // this.setState({loading:true, error:null})
        try {
            const response = await fetch('/api/guiaRemision/Correlativo');
            const data = await response.json();
            // console.log(data)
            this.setState({
                Serie: data.Correlativo[0].Serie,
                NumeroComprobante: data.Correlativo[0]._NumeroComprobante,
                loading: true,
            })
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getVehiculos = async () => {
        try {
            const response = await fetch('/api/vehiculos/');
            const data = await response.json();
            this.setState({
                dataVehiculos: data
            })
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getTransportistas = async () => {
        // this.setState({loading:true, error:null})
        try {
            const response = await fetch('/api/transportistas/');
            const data = await response.json();
            this.setState({
                // loading: false,
                dataTransportistas: data
            })
            // console.log(data)
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getMotivosDeTraslado = async () => {
        // this.setState({ loading: true, error: null });
        try {
            const response = await fetch(`/api/guiaRemision/MotivosDeTraslado`);
            const data = await response.json();
            // console.log(data)
            this.setState({
                dataMotivos: data,
            });
            // console.log(data)
        } catch (error) {
            this.setState({
                error: error,
            });
        }
    }

    getInfoSucursal = async () => {
        // this.setState({ loading: true, error: null });
        try {
            const response = await fetch(`/api/guiaRemision/InfoEmpresa`);
            const data = await response.json();
            if (data[0].IdAlmacen != null) {
                this.setState({
                    // loading: true,
                    IdSucursal: data[0].IdSucursal,
                    dataSucursal: data,
                    Ruc: data[0].Ruc,
                    RazonSocial: data[0].RazonSocial,
                    Sucursal: data[0].Nombre,
                    Direccion: data[0].Direccion,
                    IdEmpresa: data[0].IdEmpresa,
                    // IdAlmacen:data[0].IdAlmacen
                });
            } else {
                this.setState({
                    // loading: true,
                    IdSucursal: data[0].IdSucursal,
                    dataSucursal: data,
                    Ruc: data[0].Ruc,
                    RazonSocial: data[0].RazonSocial,
                    Sucursal: data[0].Nombre,
                    Direccion: data[0].Direccion,
                    IdEmpresa: data[0].IdEmpresa,
                    // IdAlmacen:0
                });
            }

            this.getCorrelativo();
            // console.log(this.state.dataSucursal[0])
            // this.obtenerAlmacenes(data[0].IdSucursal);
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }

    }

    listaUbigeos = async () => {
        // this.setState({ loading: true, error: null });
        try {
            const response = await fetch(`/api/clientes/ubigeos/`);
            const data = await response.json();
            // console.log(data)
            this.setState({
                // loading: false,
                dataUbigeos: data,
            });
            const dataR = await this.state.dataUbigeos.results.filter((item) => {
                return item.CodProvincia === "00"
            });
            this.setState({
                dataregSalida: dataR,
                dataregLlegada: dataR
            });

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    };

    handleChangeRegionSalida = (e) => {
        const dataP = this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === e.target.value && item.CodDistrito === "00" && item.CodProvincia != "00"
        });
        // console.log(dataP);
        this.setState({
            dataprovSalida: dataP,
            IdUbigeoSalida: e.target.value,
            RegionSalida: e.target.value,
            ProvinciaSalida: null,
            datadistSalida: [],
        });
        //console.log(this.state);
    };

    handleChangeRegionLlegada = (e) => {
        const dataP = this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === e.target.value && item.CodDistrito === "00" && item.CodProvincia != "00"
        });
        // console.log(dataP);
        this.setState({
            dataprovLlegada: dataP,
            IdUbigeoLlegada: e.target.value,
            RegionLlegada: e.target.value,
            ProvinciaLlegada: null,
            datadistLlegada: [],
        });
        //console.log(this.state);
    };

    handleChangeProvinciaLlegada = (e) => {
        const dataD = this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === this.state.RegionLlegada && item.CodProvincia === e.target.value && item.CodDistrito != "00"
        });
        this.setState({
            datadistLlegada: dataD,
            IdUbigeoLlegada: `${this.state.RegionLlegada}${e.target.value}`,
            ProvinciaLlegada: e.target.value,
            DistritoLlegada: null,
        });
    };

    handleChangeDistritoLlegada = (e) => {
        // console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value,
            IdUbigeoLlegada: `${this.state.RegionLlegada}${this.state.ProvinciaLlegada}${e.target.value}`,
            IdUbiLlegada: e.target.value
        });
        // console.log(this.state.IdUbigeoLlegada)
    };

    handleChangeUnicos = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleVehiculoChange(e) {
        //console.log(e.value)
        this.state.IdVehiculo = e.value
        // console.log("IdVehiculo ", e.value)
    }

    ListaVehiculos = (Vehiculos) => {
        return Vehiculos.map(Vehiculo => {
            //console.log(producto)
            return {name: Vehiculo.Modelo.concat(', ', Vehiculo.Placa), value: Vehiculo.IdVehiculo};
        });
    }

    handleTransportistaChange(e) {
        //console.log(e.value)
        this.state.IdTransportista = e.value
        this.setState({
            HabilitarCoPiloto: false
        })
        // console.log("IdTransportista ", e.value)
    }

    handleCoTransportistaChange(e) {
        //console.log(e.value)
        this.state.IdCoTransportista = e.value
        // console.log("IdCoTransportista ", e.value)
    }

    ListaTransportistas = (Transportistas) => {
        return Transportistas.map(Transportista => {
            //console.log(producto)
            return {name: Transportista.Empleado, value: Transportista.IdTransportista};
        });
    }

    handleAlmacenChange = async (e) => {
        console.log(e.value)
        this.state.IdAlmacen = e.value

        await this.cargarUbigeoAlmacen(e.value)
        // console.log("IdAlmacen ", e.value)
    }

    cargarUbigeoAlmacen = async (IdAlmacen) => {
        const Almacen = this.state.dataAlmacenes.Almacenes.filter(obj => {
            return obj.IdAlmacen == IdAlmacen //&& obj.IdSucursal == this.state.IdSucursal no recuerdo para que fue esta condicional
        })
        console.log(Almacen)
        const UbigeoSalida = this.state.dataUbigeos.results.filter(obj => {
            return obj.IdUbigeo == Almacen[0].IdUbigeo
        })


        await this.CargarProvincias(UbigeoSalida[0].CodRegion);
        await this.CargarDistritos(UbigeoSalida[0].CodProvincia);

        this.setState({
            IdUbigeoSalida: Almacen[0].IdUbigeo,
            RegionSalida: UbigeoSalida[0].CodRegion,
            ProvinciaSalida: UbigeoSalida[0].CodProvincia,
            DistritoSalida: UbigeoSalida[0].CodDistrito,
            DireccionDeSalida: Almacen[0].Direccion
        })

    }

    CargarProvincias = async (codreg) => {
        // console.log("codreg",codreg)
        const dataP = await this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === codreg && item.CodDistrito === "00" && item.CodProvincia != "00"
        });
        // console.log(dataP);
        this.setState({
            dataprovSalida: dataP,
            RegionSalida: codreg,
            ProvinciaSalida: null,
            datadistSalida: [],
        });
        //console.log(this.state);
    };

    CargarDistritos = async (codprov) => {
        // console.log("codprov",codprov)
        const dataD = await this.state.dataUbigeos.results.filter((item) => {
            return item.CodRegion === this.state.RegionSalida && item.CodProvincia === codprov && item.CodDistrito != "00"
        });
        this.setState({
            datadistSalida: dataD,
            ProvinciaSalida: codprov,
            DistritoSalida: null

        });
    };

    ListaAlmacenes = (Almacenes) => {
        // console.log(Almacenes)
        return Almacenes.map(Almacen => {
            //console.log(producto)
            return {name: Almacen.Nombre, value: Almacen.IdAlmacen};
        });
    }

    handleChangeCheckBox = (e) => {
        const coTransportistas = this.state.dataTransportistas.Transportistas.filter(coTransportista => {
            return coTransportista.IdTransportista != this.state.IdTransportista
        })

        if (this.state.HabilitarCoPiloto == false) {
            // Aqui debe ir la llamada a crear la nueva lista sin el id del transportista seleccionado
            if (this.state.IdTransportista != null) {
                if (coTransportistas.length > 0) {
                    this.setState({
                        HabilitarCoPiloto: true,
                        dataCoTransportistas: coTransportistas
                    })
                } else {
                    return Notify("Registre otro Conductor", "warning", 1500);
                }
            } else {
                return Notify("Primero seleccione un Conductor", "warning", 1500);
            }
            // console.log(coTransportistas)
        } else {
            this.setState({
                HabilitarCoPiloto: false,
                dataCoTransportistas: [],
                IdCoTransportista: null
            })
            // Aqui debemos poner la lista a cero. incluyendo el IdCopiloto
        }
    }

    async handleOpenModal() {
        if (this.state.IdTipoDocumentoSunat === null || this.state.IdTipoDocumentoSunat === '') {
            return Notify("Debes seleccionar primero un tipo de documento de origen", "warning", 2000)
        } else {

            this.setState({
                statusModal: true
            });
        }

    }

    handleOnCloseModal() {
        this.setState({
            statusModal: false
        });
    }

    handleChangePeso = (e, item) => {
        // console.log(e.target, " - ", item)
        if (this.state.IdTipoDocumentoSunat == 2 || this.state.IdTipoDocumentoSunat == 4) {
            this.state.DetallesVentaNuevo.map(obj => {
                if (obj.IdDetalleVenta == item.IdDetalleVenta) {
                    obj.Peso = e.target.value
                }
            })
        } else {
            this.state.DetallesVentaNuevo.map(obj => {
                if (obj.IdDetalleVale == item.IdDetalleVale) {
                    obj.Peso = e.target.value
                }
            })
        }

        this.calcularPesoTotal()
        this.setState(this.state.DetallesVentaNuevo)
        // console.log(this.state.DetallesVentaNuevo)
    }

    obBlurInputNumerIsEmpty = (e, item) => {

        this.state.DetallesVentaNuevo.map(obj => {
            if (obj.IdDetalleVenta == item.IdDetalleVenta) {
                if (e.target.value == '') {
                    obj.Peso = 0
                }
            }
        })
        this.setState(this.state.DetallesVentaNuevo)
    }

    calcularPesoTotal = () => {
        let total = 0
        this.state.DetallesVentaNuevo.map(obj => {
            if (obj.Peso == '') {
                total = total + 0
            } else {
                total = total + parseFloat(obj.Peso)
            }

        })
        this.setState({
            Peso: total
        })
        // console.log(this.state.Peso)
    }

    handleChangeObservacion = (e, item) => {

        this.state.DetallesVentaNuevo.map(obj => {
            if (obj.IdDetalleVenta == item.IdDetalleVenta) {
                obj.Observacion = e.target.value
            }
        })
        this.setState(this.state.DetallesVentaNuevo)
        // console.log(this.state.DetallesVentaNuevo)
    }

    newGuiaRemision = async (e) => {
        e.preventDefault();

        const res = await fetch("/api/guiaRemision/add", {
            method: "POST",
            body: JSON.stringify({
                IdTipoDocumentoSunat: this.state.IdTipoDocumentoSunat,
                IdSucursal: this.state.IdSucursal,
                FechaEmision: this.state.FechaDeEmision,
                FechaInicioTraslado: this.state.FechaDeInicioDeTraslado,
                IdRegistroVenta: this.state.IdRegistroVentas,
                IdMotivoDeTraslado: this.state.IdMotivoDeTraslado,
                DescripcionMotivoTraslado: this.state.DescripcionMotivoTraslado,
                IdUbigeoSalida: this.state.IdUbigeoSalida,
                Serie: this.state.Serie,
                NumeroComprobante: this.state.NumeroComprobante,
                DireccionSalida: this.state.DireccionDeSalida,
                IdUbigeoLlegada: this.state.IdUbiLlegada,
                DireccionLLegada: this.state.DireccionDeLlegada,
                IdVehiculo: this.state.IdVehiculo,
                IdTransportista: this.state.IdTransportista,
                IdCoTransportista: this.state.IdCoTransportista,
                Peso: this.state.Peso,
                ComentarioAdicional: this.state.ComentarioAdicional,
                numYCorrelativo: this.state.numYCorrelativoRV,
                IdAlmacenSalida: this.state.IdAlmacen,
                numContenedor: this.state.numContenedor || "",
                referenceDoc: this.getDocReference(this.state.IdTipoDocumentoSunat),
                TipoDocDestinatario: this.state.TipoDocDestinatario && this.state.NumeroDocDestinatario.length === 11 ? RUC : DNI,
                NumeroDocDestinatario: this.state.NumeroDocDestinatario,
                RazonSocialDestinatario: this.state.RazonSocialDestinatario,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })

        const data = await res.json();

        if (res.ok)
            await this.NewDetalleGuiaRemision(data.IdGuiaRemision);
        else
            notificarError(data.msg)

    }

    NewDetalleGuiaRemision = async (IdGuiaRemision) => {
        this.setState({loading: true, error: null});
        try {
            this.state.DetallesVentaNuevo.map(async (item) => {
                // console.log(item)
                await fetch("/api/guiaRemision/addDetalle", {
                    method: "POST",
                    body: JSON.stringify({
                        IdGuiaRemision: IdGuiaRemision,
                        Cantidad: item.Cantidad,
                        NombreProducto: item.NombreProducto,
                        IdStock: item.IdStock,
                        IdTipoStock: item.IdTipoStock,
                        IdAfectacionIGV: item.IdAfectacionIgv,
                        PrecioVenta: item.PrecioVenta,
                        Importe: item.Importe,
                        Codigo: item.Codigo,
                        Indice: item.Indice,
                        Unidad: item.Unidad,
                        Descuento: item.Descuento,
                        Peso: item.Peso,
                        Observacion: item.Observacion,
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                }).then(res => res.json())
            })
            Notify("Guía de Remisión registrada.", "success", 1500)
            this.props.history.push('/GuiaRemision/List')

        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    getDocReference(tipoDoc) {
        if (this.isVenta(tipoDoc))
            return {
                table: "RegistroVentas",
                pk: "IdRegistroVenta",
                value: this.state.IdRegistroVentas,
                tipoDocAttr: "IdTipoComprobante"
            }
        if (String(tipoDoc) === String(NOTA_EGRESO))
            return {
                table: "MovimientosAlmacen",
                pk: "IdMovimientoAlmacen",
                value: this.state.IdRegistroVentas,
                tipoDocAttr: "IdTipoDocumentoSunat"
            }
        return {};
    }

    isVenta(tipoDoc) {
        return String(tipoDoc) === String(BOLETA) || String(tipoDoc) === String(FACTURA);
    }

    async onSaveVehiculo(data) {
        await this.getVehiculos()
        this.setState({IdVehiculo: data.data.IdVehiculo, isOpenModalVehiculo: false})
    }

    async onSaveTransportista(data) {
        await this.getTransportistas();
        this.setState({IdTransportista: data.IdTransportista, isOpenModalTrans: false})
    }

    render() {
        if (this.state.loading === false && this.state.Serie === '') {
            return <PageLoading/>;
        }
        return (
            <React.Fragment>
                <div className="ml-4 mt-4">
                    <BotonCancelar to="/GuiaRemision/List"/>
                </div>
                <form onSubmit={this.newGuiaRemision} className="d-flex justify-content-center">
                    <div className="almacenForm">
                        <div className="AlmacenBoton">
                            Guía de Remisión - Remitente {this.state.Serie} - {this.state.NumeroComprobante}
                            <BotonGuardarLetras className="ml-3" type="submit" onClick={this.newGuiaRemision}/>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">Ruc</b>
                            <div className="input__Almacen">
                                <input
                                    name="Ruc"
                                    className="input__linea"
                                    disabled
                                    type="text"
                                    value={this.state.Ruc}
                                />
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">Razón Social</b>
                            <div className="input__Almacen">
                                <input
                                    name="RazonSocial"
                                    className="input__linea "
                                    disabled
                                    type="text"
                                    value={this.state.RazonSocial}
                                />
                            </div>
                        </div>

                        <div className="almacenDiv">
                            <b className="ml-4">Sucursal</b>
                            <div className="input__Almacen">
                                <input
                                    name="Sucursal"
                                    className="input__linea"
                                    disabled
                                    type="text"
                                    value={this.state.Sucursal}
                                />
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">Dirección</b>
                            <div className="input__Almacen">
                                <input
                                    name="Direccion"
                                    className="input__linea"
                                    disabled
                                    type="text"
                                    value={this.state.Direccion}
                                />
                            </div>
                        </div>

                        <div className="almacenDiv">
                            <b className="ml-4">Fecha de Emisión </b>
                            <div className="input__Almacen">
                                <input

                                    disabled
                                    name="FechaDeEmision"
                                    onChange={this.handleChangeUnicos}
                                    value={this.state.FechaDeEmision}
                                    className="input__linea"
                                    type="date"
                                />
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">*Fecha de Inicio de Traslado</b>
                            <div className="input__Almacen">
                                <input

                                    name="FechaDeInicioDeTraslado"
                                    onChange={this.handleChangeUnicos}
                                    className="input__linea"
                                    defaultValue={this.state.FechaDeInicioDeTraslado}
                                    min={this.state.Hoy}
                                    type="date"/>
                            </div>
                        </div>

                        <div className="almacenDiv">
                            <b className="ml-4">Tipo de Documento de Origen</b>
                            <div className="input__Almacen col-8">
                                <SelectSearch
                                    name="IdTipoDocumentoSunat"
                                    value={this.state.IdTipoDocumentoSunat}
                                    onChange={(e) => this.handleTipoDocSunatChange(e)}
                                    options={this.ListaTipoDocSunat(this.state.datatipoDocumento.tipoDocumento)}
                                    className="select-search-box"
                                    placeholder="Seleccionar Tipo de Documento"
                                />
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                <b className="ml-1">Seleccionar Documento de Origen</b>
                                <BotonAdd
                                    className="ml-2 mr-2"
                                    name="docOrigen"
                                    type="button"
                                    onClick={this.handleOpenModal}
                                />
                                <input type="text"
                                       className="input__linea col-5"
                                       disabled
                                       value={this.state.numYCorrelativoRV}/>
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">*Motivo de Traslado</b>
                            <div className="input__Almacen">
                                <select
                                    name="IdMotivoDeTraslado"
                                    className="input__linea"
                                    value={this.state.IdMotivoDeTraslado}
                                    onChange={this.handleChangeUnicos}>{
                                    this.state.dataMotivos.Motivos.map((obj) => {
                                        return <option key={obj.IdMotivoDeTraslado}
                                                       value={obj.IdMotivoDeTraslado}>{obj.Nombre}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">* Descipción del Motivo de Traslado</b>
                            <div className="input__Almacen">
                                <input
                                    name="DescripcionMotivoTraslado"
                                    className="input__linea"
                                    type="text"
                                    value={this.state.DescripcionMotivoTraslado}
                                    onChange={this.handleChangeUnicos}
                                />
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">*Punto de Partida</b>
                            <div className="input__Almacen col-8">
                                <SelectSearch
                                    name="IdAlmacen"
                                    value={this.state.IdAlmacen}
                                    onChange={(e) => this.handleAlmacenChange(e)}
                                    options={this.ListaAlmacenes(this.state.dataAlmacenes.Almacenes)}
                                    className="select-search-box"
                                    placeholder="Seleccionar Almacen"
                                />
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">Ubigeo de Salida</b>
                            <div className="input__Almacen col-8">
                                <select

                                    disabled
                                    className="input__linea col-4"
                                    name="RegionSalida"
                                    value={this.state.RegionSalida || ""}>
                                    <option value={""}>Selecccione región</option>
                                    {
                                        this.state.dataregSalida.map((item) => {
                                            return <option key={item.IdUbigeo}
                                                           value={item.CodRegion}> {item.NombreUbigeo} </option>
                                        })
                                    }
                                </select>
                                <select

                                    disabled
                                    className="input__linea col-4"
                                    name="ProvinciaSalida"
                                    value={this.state.ProvinciaSalida || ""}>
                                    <option value={""}>Selecccione provincia</option>
                                    {
                                        this.state.dataprovSalida.map((item) => {
                                            return <option key={item.IdUbigeo}
                                                           value={item.CodProvincia}> {item.NombreUbigeo} </option>
                                        })
                                    }
                                </select>
                                <select
                                    disabled
                                    className="input__linea col-4"
                                    name="DistritoSalida"
                                    value={this.state.DistritoSalida || ""}>
                                    <option value={""}>Selecccione distrito</option>
                                    {
                                        this.state.datadistSalida.map((item) => {
                                            return <option key={item.IdUbigeo}
                                                           value={item.CodDistrito}> {item.NombreUbigeo} </option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">Dirección de Salida</b>
                            <div className="input__Almacen">
                                <input
                                    name="DireccionDeSalida"
                                    className="input__linea"
                                    type="text"
                                    value={this.state.DireccionDeSalida}
                                    onChange={this.handleChangeUnicos}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="row form-group col-12">
                            <b className="ml-4">*DESTINATARIO</b>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">*Número de Documento</b>
                            <div className="input__Almacen">

                                <input
                                    disabled={this.state.destinatarioDisabled}
                                    name="NumeroDocDestinatario"
                                    className="input__linea"
                                    type="text"
                                    value={this.state.NumeroDocDestinatario}
                                    onChange={this.handleChangeUnicos}
                                />
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">*Apellidos y nombres o razón social</b>
                            <div className="input__Almacen">
                                <input
                                    disabled={this.state.destinatarioDisabled}
                                    name="RazonSocialDestinatario"
                                    className="input__linea"
                                    type="text"
                                    value={this.state.RazonSocialDestinatario}
                                    onChange={this.handleChangeUnicos}
                                />
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">*Ubigeo de Llegada</b>
                            <div className="input__Almacen col-8">
                                <select onChange={this.handleChangeRegionLlegada}
                                        className="input__linea col-4" name="RegionLlegada"
                                        value={this.state.RegionLlegada}>
                                    <option value={null}>Selecccione región</option>
                                    {
                                        this.state.dataregLlegada.map((item) => {
                                            return <option key={item.IdUbigeo}
                                                           value={item.CodRegion}> {item.NombreUbigeo} </option>
                                        })
                                    }
                                </select>
                                <select onChange={this.handleChangeProvinciaLlegada}
                                        className="input__linea col-4" name="ProvinciaLlegada"
                                        value={this.state.ProvinciaLlegada || ""}>
                                    <option value={""}>Selecccione provincia</option>
                                    {
                                        this.state.dataprovLlegada.map((item) => {
                                            return <option key={item.IdUbigeo}
                                                           value={item.CodProvincia}> {item.NombreUbigeo} </option>
                                        })
                                    }
                                </select>
                                <select onChange={this.handleChangeDistritoLlegada}
                                        className="input__linea col-4" name="DistritoLlegada"
                                        value={this.state.DistritoLlegada}>
                                    <option value={null}>Selecccione distrito</option>
                                    {
                                        this.state.datadistLlegada.map((item) => {
                                            return <option key={item.IdUbigeo} id={item.IdUbigeo}
                                                           value={item.IdUbigeo}> {item.NombreUbigeo} </option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">*Dirección de Llegada</b>
                            <div className="input__Almacen">
                                <input
                                    name="DireccionDeLlegada"
                                    className="input__linea"
                                    type="text"
                                    value={this.state.DireccionDeLlegada}
                                    onChange={this.handleChangeUnicos}
                                />
                            </div>
                        </div>
                        <div className="row form-group col-12">
                            <b className="ml-4">TRANSPORTE</b>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">Vehículo: </b>
                            <div className="input__Almacen col-8">
                                <SelectSearch
                                    isDisabled
                                    name="IdVehiculo"
                                    value={this.state.IdVehiculo}
                                    onChange={(e) => this.handleVehiculoChange(e)}
                                    options={this.ListaVehiculos(this.state.dataVehiculos.Vehiculos)}
                                    className="select-search-box"
                                    placeholder="Seleccionar Vehiculo"

                                />
                                <button onClick={() => this.setState({isOpenModalVehiculo: true})}
                                        style={{height: "50px"}} className="ml-2 mr-2 boton__verdeClaro"
                                        name="docOrigen" type="button">
                                    <i className="fas fa-plus"/>
                                </button>
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="col-3">Conductor </b> <br/>

                            <div className="input__Almacen col-9">
                                <SelectSearch
                                    name="IdTransportista"
                                    value={this.state.IdTransportista}
                                    onChange={(e) => this.handleTransportistaChange(e)}
                                    options={this.ListaTransportistas(this.state.dataTransportistas.Transportistas)}
                                    className="select-search-box"
                                    placeholder="Seleccionar Piloto"

                                />

                                <button onClick={() => this.setState({isOpenModalTrans: true})}
                                        style={{height: "50px"}} className="ml-2 mr-2 boton__verdeClaro"
                                        name="docOrigen" type="button">
                                    <i className="fas fa-plus"/>
                                </button>

                            </div>

                        </div>
                        <div className="row form-group col-12">
                            <div className="input__Almacen col-9">
                                <b className="col-3">
                                    <label align="left" className="checkbox-toggle">
                                        <div className="toggle">
                                            <input
                                                type="checkbox"
                                                name="Valorizacion"
                                                className="toggle-state"
                                                value={this.state.HabilitarCoPiloto}
                                                onChange={this.handleChangeCheckBox}
                                                checked={this.state.HabilitarCoPiloto}
                                            />
                                            <div className="toggle-inner">
                                                <div className="indicator"/>
                                            </div>
                                            <div className="active-bg"/>
                                        </div>
                                    </label>
                                    Co - Conductor:
                                </b>
                                <div hidden={!this.state.HabilitarCoPiloto} className="col-9">
                                    <SelectSearch
                                        name="IdCoTransportista"
                                        value={this.state.IdCoTransportista}
                                        onChange={(e) => this.handleCoTransportistaChange(e)}
                                        options={this.ListaTransportistas(this.state.dataCoTransportistas)}
                                        className="select-search-box"
                                        placeholder="Seleccionar Copiloto"
                                    />
                                </div>
                            </div>
                        </div>

                        <Modal
                            isOpen={this.state.statusModal}
                            onClose={this.handleOnCloseModal}
                            title="Datos"
                        >
                            <RegistroVentaList
                                state={this.state}
                                handleMonthPickerChange={this.handleMonthPickerChange}
                                obtenerRV={this.obtenerRV}
                            />

                        </Modal>
                        <div className="row form-group col-12">
                            <b className="col-2">PRODUCTOS</b>
                        </div>
                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                <b className="ml-1">*Peso aprox. de carga (Kg):</b>
                                <input type="text"
                                       onChange={this.handleChangeUnicos}
                                       name="Peso"
                                       className="input__linea col-2"
                                       value={this.state.Peso}

                                       disabled
                                />
                            </div>
                        </div>
                        <div className="row form-group col-12">
                            <div className="col-11">
                                <table className="table table-hover text-center col-12">
                                    <thead className="text-center bg-dark text-white">
                                    <tr>
                                        <th scope="col-1">Cantidad</th>
                                        <th scope="col-4">Item</th>
                                        <th scope="col-1">Precio</th>
                                        <th scope="col-1">Importe</th>
                                        <th scope="col-1">Peso(Kg)</th>
                                        <th scope="col-3">Observación</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.DetallesVentaNuevo.map(item => {
                                        return (
                                            <tr key={item.IdDetalleVenta}>
                                                <td>{item.Cantidad}</td>
                                                <td>{item.NombreProducto}</td>
                                                <td>{item.PrecioVenta}</td>
                                                <td>{item.Importe}</td>
                                                <td className="input__Almacen">
                                                    <input className="input__lineaaaa" style={{padding: 0}}
                                                           name="Peso"
                                                           value={item.Peso}
                                                           onFocus={(e) => e.target.select()}
                                                           onChange={(e) => this.handleChangePeso(e, item)}
                                                           onBlur={(e) => this.obBlurInputNumerIsEmpty(e, item)}
                                                           type="number" min={0}/>
                                                </td>
                                                <td>
                                                    <input className="input__linea" name={item.Observacion}
                                                           onChange={(e) => this.handleChangeObservacion(e, item)}
                                                           value={item.Observacion} type="text"/>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">Comentarios</b>
                            <div className="input__Almacen">
                                <input
                                    name="ComentarioAdicional"
                                    className="input__linea col-10"
                                    type="text"
                                    value={this.state.ComentarioAdicional}
                                    onChange={this.handleChangeUnicos}
                                />
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <b className="ml-4">Número de contenedor (solo si es importación)</b>
                            <div className="input__Almacen">
                                <input
                                    name="ComentarioAdicional"
                                    className="input__linea col-10"
                                    type="text"
                                    value={this.state.numContenedor || ""}
                                    onChange={e => this.setState({numContenedor: e.target.value})}
                                />
                            </div>
                        </div>
                        <div className="AlmacenBoton">
                            <BotonGuardarLetras className="ml-3" type="submit" onClick={this.newGuiaRemision}/>
                        </div>
                    </div>
                </form>

                <Modal title="Agregar vehículo" onClose={() => this.setState({isOpenModalVehiculo: false})}
                       isOpen={this.state.isOpenModalVehiculo}>
                    <VehiculoAddForm onSaveVehiculo={this.onSaveVehiculo}/>
                </Modal>

                <Modal title="Agregar transportista" onClose={() => this.setState({isOpenModalTrans: false})}
                       isOpen={this.state.isOpenModalTrans}>
                    <AddTransportistaForm onSaveTransportista={this.onSaveTransportista}/>
                </Modal>
            </React.Fragment>
        )

    }

}

export default GuiaRemision;

import React from 'react';
import VehiculosForm from './VehiculosForm.js';
import {Link} from 'react-router-dom';
import {BotonGuardarLetras, BotonCancelar} from "../Plantillas/Botones";

class VehiculosAdd extends React.Component {

    constructor() {
        super()
        this.state = {
            Placa: "",
            Modelo: "",
            FechaRevisionTecnica: "PUBLICO",
            FechaSOAT: "",
            EmpresaSOAT: "",
            NumeroSOAT: "",
            Estado: ""
        }
        this.newVehiculo = this.newVehiculo.bind(this)
    }

    componentDidMount() {
        //setInterval(this.listaClientes,5000);

    }

    handleChangeUnicos = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
        // console.log(e.target.name, e.target.value)
    };

    newVehiculo(e) {
        e.preventDefault();
        try {
            fetch('/api/vehiculos/add', {
                method: 'POST',
                body: JSON.stringify({
                    Placa: this.state.Placa,
                    Modelo: this.state.Modelo,
                    FechaRevisionTecnica: this.state.FechaRevisionTecnica,
                    FechaSOAT: this.state.FechaSOAT,
                    EmpresaSOAT: this.state.EmpresaSOAT,
                    NumeroSOAT: this.state.NumeroSOAT
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(data => console.log(data))
                .catch(err => console.log(err));
            this.props.history.goBack()
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="ml-4 mt-4">
                    <BotonCancelar to="/vehiculos"/>
                </div>
                <form onSubmit={this.newVehiculo} className="d-flex justify-content-center">
                    <div className="almacenForm">
                        <div className="AlmacenBoton">
                            <BotonGuardarLetras type="submit"/>
                        </div>

                        <VehiculosForm
                            state={this.state}
                            handleChangeUnicos={this.handleChangeUnicos}
                        />
                    </div>
                </form>
            </React.Fragment>
        )

    }

}

export default VehiculosAdd;

import Notify from "../componentes/Notify";

/**
 * Validar el **Status Code** de un *Response* y notificar error si es mayor o igual a 400
 * 
 * @param {Response} respuesta - objeto Response
 * @param {Array<Object>} printOnError - Cualquier objeto que se quiera imprimir en la consola en caso de error
 */
const validarStatusCode = (respuesta, printOnError=null) => {
    if (respuesta.status >= 400) {
        switch (respuesta.status) {
            case 401: Notify("Debe iniciar sesión", "error"); break;
            case 403: Notify("No tiene permiso", "error"); break;
            case 500: Notify("Hubo un error en el servidor", "error"); break;
            default:  Notify(`Error ${respuesta.status}: ${respuesta.statusText}`, "error"); break;
        }
        console.error(`${respuesta.status}: ${respuesta.statusText}`, respuesta.url);
        if (printOnError) console.error(printOnError);
        return false
    }
    return true;
}

export default validarStatusCode;
import React from "react";
import { Link } from "react-router-dom";
// import PageLoading from "../PageLoading.js";

import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import { BotonEditar, BotonLetrasRojo } from "../../componentes/Plantillas/Botones";
import swal from "sweetalert";
import Notify from '../../componentes/Notify';

class PreProcesarValesList extends React.Component{
    constructor() {
        super();
        this.state = {
          datapreProcesarVales: {
            preProcesarVales: []
          },

          loading: true,
          error: null
        };
    }

    componentDidMount = () => {
        this.getPreProcesarVales()
        this.intervalo=setInterval(this.getPreProcesarVales,3000) //Si deseo que me cargue a cada rato la lista se descomenta esta y la del componentWillUnmount
    }

    componentWillUnmount(){
        clearInterval(this.intervalo)
    }

    getPreProcesarVales = async () => {
        this.setState({ loading: true, error: null });
        try {
            const response = await fetch(`/api/vales/preProcesarValesList`);
            const preProcesarVales = await response.json();
            this.setState({
                loading: false,
                datapreProcesarVales: preProcesarVales
            });
            // console.log(preProcesarVales)
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    }

    handleOnDeleteAllPreProcesarVales(preProcesarVales) {
      return async () => {
        const willDelete = await swal({
          title: "¿Está seguro?",
          text: "¿Eliminar los registros?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
          closeOnClickOutside: false
        });

        if (willDelete) {
          await this.CancelarTodo(preProcesarVales)
        }
      };
    }

    CancelarTodo = async (preProcesarVales) => {

      for (let i = 0; i < preProcesarVales.length; i++) {
        // console.log(preProcesarVales[i],i);
        this.getDetallePreProcesarValesACancelar(preProcesarVales[i].IdPreProcesarVale)
      }
    }

    async getDetallePreProcesarValesACancelar(IdPreProcesarVale) {

      try {
        const response = await fetch(`/api/gestionPreProcesarVales/detallepreProcesarVale/${IdPreProcesarVale}`);
        const data = await response.json();
        // console.log(data.respuesta)

        for (let i = 0; i < data.respuesta.length; i++) {
          this.ActualizarStockValePreProcesarVales(data.respuesta[i], 0+data.respuesta[i].Cantidad,IdPreProcesarVale)
        }

      } catch (error) {
        this.setState({ loading: false, error: error });
      }
    }
    ActualizarStockValePreProcesarVales = async( item,variacionStock,IdPreProcesarVale ) => {
      try {
        await fetch(`/api/vales/editStockValePreProcesarValesNoGenerada`,{
              method:"PUT",
              body: JSON.stringify({
                  variacionStock:variacionStock*item.TipoCambio,
                  IdStock:item.IdStock
              }),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
              }
          })

          this.DeletePreProcesarVale(IdPreProcesarVale)
      } catch (error) {
          this.setState({
              loading: false,
              error: error,
          });
      }
    }

    DeletePreProcesarVale = async (IdPreProcesarVale) => {
      // if (this.props.location.from) {
      //     return this.props.history.push(this.props.location.from)
      // }
      Notify("Eliminando registros, por favor espere","success",1500)
      try {
          fetch(`/api/vales/deletePreProcesarVales/${IdPreProcesarVale}`,{
              method:"DELETE",
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json"
              }
          })
          .then(()=>this.props.history.push(`/procesarvales/PreProcesarValesList`))
      } catch (error) {
          this.setState({
              loading: false,
              error: error
          });
      }
    }

    render(){
        if (this.state.loading === true && this.state.datapreProcesarVales === undefined) {
            return (
              <React.Fragment>
                <div className="d-flex justify-content-center">
                  <label>Cargando...</label>
                  <div className="spinner-border text-primary ml-3" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </React.Fragment>
            );
        }
        // if (this.state.datapreProcesarVales.preProcesarVales.length === 0) {
        //     return (
        //         <React.Fragment>
        //           <div className="p-5">
        //             <div>
        //               Lista Vacía
        //             </div>
        //           </div>
        //         </React.Fragment>
        //     )
        // }else{
            const filtrarPreProcesarVales = this.state.datapreProcesarVales.preProcesarVales

            if (this.state.error) {
                return `Error: ${this.state.error.message}`;
            }

            if (filtrarPreProcesarVales.length === 0) {
                return(
                    <React.Fragment>
                        <div className="p-5">
                            <div>
                                <h3>No se encontraron Pre Procesar Vales sin Generar</h3>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }

            return(
                <React.Fragment>
                    <div className="p-5">
                        <BotonLetrasRojo
                          onClick={this.handleOnDeleteAllPreProcesarVales(this.state.datapreProcesarVales.preProcesarVales)}
                        >Cancelar Todo
                        </BotonLetrasRojo>
                        <div className="row">
                            <ReactTable
                                data={filtrarPreProcesarVales}
                                filterable
                                defaultFilterMethod={(filter, row) =>
                                  String(row[filter.id]) === filter.value
                                }
                                columns={[
                                    {
                                        columns:[
                                          {
                                            Header: "Acción",
                                            filterable:false,
                                            headerClassName: "card-header ",
                                            width:80,
                                            Cell: original => {
                                              return (
                                                <BotonEditar
                                                  to={
                                                    "/procesarvales/edit/" + original.original.IdPreProcesarVale
                                                  }
                                                  title="Ir al Vale"
                                                />
                                                // <Link className="btn btn-outline-info ml-1 mr-1"
                                                // to={
                                                //   "/procesarvales/edit/" + original.original.IdPreProcesarVale
                                                // }>

                                                //   <i class="fas fa-eye " title="Editar Vale para Procesar"></i>

                                                // </Link>
                                              );
                                            }
                                        },
                                            {
                                                Header: "Cliente",
                                                id: "RazonSocial",
                                                headerClassName: "",
                                                width:300,
                                                accessor: d => d.Alias.concat(" - ",
                                                d.RazonSocial==null?`${d.PrimerNombre} ${d.ApellidoPaterno}`:d.RazonSocial,
                                                ),
                                                filterMethod: (filter, rows) =>
                                                  matchSorter(rows, filter.value, { keys: ["RazonSocial"] }),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Fecha de Emisión",
                                                id: "FechaEmision",
                                                headerClassName: "",
                                                width:180,
                                                accessor: d => d.FechaEmision,
                                                filterMethod: (filter, rows) =>
                                                  matchSorter(rows, filter.value, { keys: ["FechaEmision"] }),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Monto Total",
                                                id: "Total",
                                                headerClassName: "",
                                                width:150,
                                                accessor: d => d.Simbolo.concat(" ",
                                                  d.Total.toLocaleString('en-GB', { minimumFractionDigits:2, maximumFractionDigits: 2 })),
                                                filterMethod: (filter, rows) =>
                                                  matchSorter(rows, filter.value, { keys: ["Total"] }),
                                                filterAll: true,
                                                style:{textAlign:"right"}
                                            },
                                            {
                                                Header: "Pago",
                                                id: "ModalidadPagoNombre",
                                                headerClassName: "",
                                                width:80,
                                                accessor: d => d.ModalidadPagoNombre,
                                                filterMethod: (filter, rows) =>
                                                  matchSorter(rows, filter.value, { keys: ["ModalidadPagoNombre"] }),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Documento",
                                                id: "Descripcion",
                                                headerClassName: "",
                                                width:120,
                                                accessor: d => d.Descripcion,
                                                filterMethod: (filter, rows) =>
                                                  matchSorter(rows, filter.value, { keys: ["Descripcion"] }),
                                                filterAll: true
                                            },
                                            {
                                              Header: "Usuario",
                                              id: "Usuario",
                                              headerClassName: "",
                                              width:120,
                                              accessor: d => d.Usuario,
                                              filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, { keys: ["Usuario"] }),
                                              filterAll: true
                                            },

                                        ]
                                    }
                                ]}
                                defaultPageSize={5}
                                className="-striped -highlight"
                                previousText="Anterior"
                                nextText="Siguiente"
                                loadingText="Cargando..."
                                noDataText="No se encontraron registros"
                                pageText="Página"
                                ofText="de"
                                rowsText="filas"
                                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                            />
                        </div>
                    </div>
                </React.Fragment>
            )
        // }
    }
}

export default PreProcesarValesList

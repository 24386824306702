import React from "react";
import "react-moment";
import _ from 'lodash';
import '../styles/PreVenta.css';
import ModalCliente from '../clientes/ClienteModal';
import Modal from "../../componentes/Modal.js";
import ClienteNuevo from '../clientes/ClientesNuevo';
import $ from 'jquery';
import {calcTotal, calcularTotales, decimalAdjust, ICBPER, IGV, NUMBER_KEYS, TIPO_STOCK,} from '../../Global'
import BuscarClientePorDoc from './BuscarClientePorDoc'
import {
    notificationBoletaVarios,
    notificationCantidadNegativa,
    notificationClienteNoExiste,
    notificationConfigBoletaRUC,
    notificationDescuentoGratuito,
    notificationDescuentoMayorTotal,
    notificationFacturaRUC,
    notificationNoHayProducto,
    notificationPreventaVacia,
    notificationProdValeInDetalle,
    notificationStock0,
    notificationStockSinComprobante
} from './PreVentaNotify'
import {
    FindPrecioEspecial,
    FindPrecioFamiliar,
    FindPrecioMenor,
    FindPrecioPorMayor,
    GetPrecioCosto,
    getPrecioPorMayor
} from './PreciosPreventa'
import swal from "sweetalert";
import PreventaAlmacenes from "./PreventaAlmacenes"
import {BOLETA, FACTURA, RUC, VALE} from "../../Global.js";
import PreventaModalDescuentos from "./PreventaModalDescuentos.js";
import {setPreciosYDescuento, setValorUnitario} from "../../helpers/calcularVenta.js";
import ModalBuscarCliente from "./ModalBuscarCliente.js";
import ListaProductosPreventa from "./ListaProductosPreventa";
import DetallesProductoPreventa from "./DetallesProductoPreventa";
import Noty from "noty";
import {notificarError} from "../Almacenes/AlmacenNotify";
import {isNumber, safe} from "../../helpers/utils";


class PreVentaAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inputName: "",
            inputTeclado: {},
            item: {},
            loading: true,
            error: null,
            buscar: "",
            IdCliente: "",
            Estado: "GENERANDO",
            dataProductos: {
                productos: []
            },
            Documento: "99999999",
            filtroTipoStock: 1,
            checkfiltroTipoStock: true,
            dataDetalle: {
                item: []
            },
            detallePreventa: {
                detalleItem: []
            },
            dataCliente: [],
            modalClienteIsOpen: false,
            totalMonto: 0,
            Gravado: 0,
            Inafecto: 0,
            ICBPERPreventa: 0,
            Exonerado: 0,
            IGVPreventa: 0,
            Gratuitas: 0,
            IVAP: 0,
            ISC: 0,
            newPreventaId: "",
            IdAlmacen: null,
            almacenes: {
                resuts: []
            },
            Codigo: null,
            IdMoneda: 1,
            TipoCambio: 1,
            TasaIGV: IGV,
            TasaICBPER: ICBPER,
            Descuento: 0,
            Total: 1,
            IdModalidadPago: 1,
            IdTipoDocumentoSunat: 4,
            Alias: "",
            VenderConStock: "",
            BoletaRuc: "",
            venderConListaPrecios: "",
            RUSS: "",
            StockMasVendidos: [],
            StockPorCliente: [],
            statusModal: false,
            tipoLista: 1,
            statusModalDescuento: false,
            statusModalSearchCliente: false,
            ItemDescuento: "",
            IdTurno: null,
            detalles: [],
            searchDisabled: false,
            load: false,
            redondeo: 0,
            afectsIgv: [],
            aliasPlaceholder: 'Alias (Opcional)',
            canUpdatePrice: true,
            canUpdateTotal: false,
            cleanSearch: false,
        }
        this.getProductos = this.getProductos.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.DocChange = this.DocChange.bind(this)
        this.NewPreVenta = this.NewPreVenta.bind(this)
        this.handleTipoComprobante = this.handleTipoComprobante.bind(this)
        this.AddProductoInList = this.AddProductoInList.bind(this)
        this.AgregarCantidadDet = this.AgregarCantidadDet.bind(this)
        this.RestarCantidadDet = this.RestarCantidadDet.bind(this)
        this.RemoveProductoInList = this.RemoveProductoInList.bind(this)
        this.PrecioChange = this.PrecioChange.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleOnCloseModal = this.handleOnCloseModal.bind(this)
        this.handleOpenModalDescuento = this.handleOpenModalDescuento.bind(this)
        this.handleOnCloseModalDescuento = this.handleOnCloseModalDescuento.bind(this)
        this.handleClickBuscar = this.handleClickBuscar.bind(this)
        this.handleCloseBuscar = this.handleCloseBuscar.bind(this)
        this.handleKeyUp = this.handleKeyUp.bind(this)
        this.AlmacenChange = this.AlmacenChange.bind(this)
        this.onChangeAfectGratuita = this.onChangeAfectGratuita.bind(this)
        this.AsignarValorDoc = this.AsignarValorDoc.bind(this)
        this.verificarTurno = this.verificarTurno.bind(this);
        this.handleEnterKeyUp = this.handleEnterKeyUp.bind(this);
        this.onSecretKeyPress = this.onSecretKeyPress.bind(this);
        this.onBlurDescuento = this.onBlurDescuento.bind(this);
        this.fetchTipoAfectsGratuitas = this.fetchTipoAfectsGratuitas.bind(this);
        this.fetchConfigAlias = this.fetchConfigAlias.bind(this);
        this.removeDetVenta = this.removeDetVenta.bind(this);
        this.checkCanUpdatePrice = this.checkCanUpdatePrice.bind(this);
        this.checkCanUpdateTotal = this.checkCanUpdateTotal.bind(this);
        this.onChangeTotal = this.onChangeTotal.bind(this);
        this.onBlurTotal = this.onBlurTotal.bind(this);
        this.setAfectsIgv = this.setAfectsIgv.bind(this);
        this.addDet = this.addDet.bind(this);
        this.onBlurCantidad = this.onBlurCantidad.bind(this)
        this.onBlurPrecioVenta = this.onBlurPrecioVenta.bind(this)
    }

    async componentDidMount() {
        await this.verificarTurno();
        await this.checkCanUpdatePrice();
        await this.checkCanUpdateTotal();
        await this.fetchTipoAfectsGratuitas(); //component
        await this.getRUSS();
        await this.getAlmacenes();
        await this.getConfigVendeConStock(); //internal
        await this.getConfigBoletasRuc(); //internal
        await this.getConfigVendeConListaPrecios();
        await this.fetchConfigAlias();
        await this.checkConfSearchProds();
        await Promise.all([
            await this.TraerClientePorDoc(this.state.Documento),
        ]).then(() => this.NewPreVenta())
        this.getProductos();
    };

    async checkConfSearchProds() {
        this.setState({load: true})
        const res = await fetch(`/api/preVentas/getConfEmpresa/${52}`);
        if (res.ok) {
            let data = await res.json();
            this.setState({cleanSearch: String(data[0].Estado) === "1"})
        } else
            notificarError('No se pudo cargar la conf para alias');
        this.setState({load: false})
    }

    async checkCanUpdatePrice() {
        let res = await fetch('/api/preVentas/check-can-update-price');
        if (!res.ok)
            this.setState({canUpdatePrice: false})
    }

    async checkCanUpdateTotal() {
        let res = await fetch('/api/preVentas/check-can-update-total');
        if (res.ok)
            this.setState({canUpdateTotal: true})
    }

    async fetchConfigAlias() {
        this.setState({load: true})
        const res = await fetch(`/api/preVentas/getConfEmpresa/${46}`);
        if (res.ok) {
            let data = await res.json();
            let vendeConPlaca = String(data[0].Estado) === "1";
            this.setState({aliasPlaceholder: vendeConPlaca ? 'Placa (opcional)' : this.state.aliasPlaceholder})
        } else
            notificarError('No se pudo cargar la conf para alias');
        this.setState({load: false})
    }

    async fetchTipoAfectsGratuitas() {
        try {
            let res = await fetch('/api/preVentas/afect-igv?query=')
            let json = await res.json();
            this.setState({
                afectsIgv: json
            })
        } catch (e) {
            this.setState({error: e})
        }
    }

    verificarTurno = async () => {
        this.setState({error: null});
        try {
            const response = await fetch(`/api/cajas/verificar/Turno`);
            const data = await response.json();
            this.setState({
                loading: false,
                IdTurno: data.Turno ? data.Turno[0][0].IdTurnoCaja : null,
            });
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    };

    BotonTipoLista = (tipoLista, NombreTipoLista) => {
        return (
            <React.Fragment>
                <button
                    type="button"
                    className={
                        this.state.tipoLista === tipoLista
                            ? "preventa__tipo--comprobante--item--active"
                            : "preventa__tipo--comprobante--item"
                    }
                    value={this.state.tipoLista}
                    onClick={() => this.handleTipoLista(tipoLista)}
                >
                    {NombreTipoLista}
                </button>
            </React.Fragment>
        )

    }

    getBotonTipoComprobante = (IdTipoDocumentoSunat, NombreDocSunat) => {
        /********LOS BOTONES DE BOLETA Y FACTURA SE MUESTRAN NORMAL**** */
        if (IdTipoDocumentoSunat !== 2) {
            return (
                <React.Fragment>
                    <button
                        type="button"
                        className={
                            this.state.IdTipoDocumentoSunat === IdTipoDocumentoSunat
                                ? "preventa__tipo--comprobante--item--active"
                                : "preventa__tipo--comprobante--item"
                        }
                        value={this.state.IdTipoDocumentoSunat}
                        onClick={() => this.handleTipoComprobante(IdTipoDocumentoSunat)}
                    >
                        {NombreDocSunat}
                    </button>
                </React.Fragment>
            )
        } else {
            /******PARA EL BOTON FACTURA, SI NO ES RUSS LO MUESTRA, SINO NO MUESTRA NADA**** */
            if (this.state.RUSS === "Inactivo") {
                return (
                    <React.Fragment>
                        <button
                            type="button"
                            className={
                                this.state.IdTipoDocumentoSunat === IdTipoDocumentoSunat
                                    ? "preventa__tipo--comprobante--item--active"
                                    : "preventa__tipo--comprobante--item"
                            }
                            value={this.state.IdTipoDocumentoSunat}
                            onClick={() => this.handleTipoComprobante(IdTipoDocumentoSunat)}
                        >
                            {NombreDocSunat}
                        </button>
                    </React.Fragment>
                )
            }
        }
    }

    async getAlmacenes() {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch("/api/almacenes/AlmacenBySucursal");
            const data = await response.json();
            this.setState({
                loading: false,
                almacenes: data,
                IdAlmacen: data.results[0].IdAlmacen
            });
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    handleOpenModal() {
        this.setState({
            statusModal: true
        });
    }

    async handleOpenModalDescuento(item) {
        this.setState({
            load: true,
            ItemDescuento: item
        })

        await this.setAfectsIgv(item.IdGrupoClasificacionIgv)

        this.setState({
            load: false,
            statusModalDescuento: true,
        })
    }

    async setAfectsIgv(idAfect) {
        let res = await fetch(`/api/preVentas/free-afects?idGrupoIgv=${idAfect}`)
        if (res.ok)
            this.setState({afectsIgv: await res.json()})
    }

    handleOnCloseModal() {
        this.setState({
            statusModal: false
        });
    }

    handleOnCloseModalDescuento() {
        this.setState({
            statusModalDescuento: false
        })
    }

    handleKeyUp(event) {
        if (event.key === "Escape" || event.key === "Enter") {
            this.setState({
                statusModal: false,
                statusModalDescuento: false
            });
        }
    }

    AlmacenChange(e) {
        this.setState({
            IdAlmacen: e.target.value
        });
    }

    handleClickNuevo = () => {
        this.setState({
            modalClienteIsOpen: true,
            itemcliente: {
                ...this.state.itemcliente,
                idcliente: null,
            }
        })
    }

    handleCloseModal = () => {
        this.setState({modalClienteIsOpen: false})
    }

    handleClickBuscar = () => {
        this.setState({
            statusModalSearchCliente: true
        })
    }

    GetCliente = (cliente) => {
        this.handleCloseBuscar()
        this.setState({
            Documento: cliente.NroTipoDocumento
        })
        this.TraerClientePorDoc(cliente.NroTipoDocumento)
    }

    handleCloseBuscar = () => {
        this.setState({
            statusModalSearchCliente: false
        })
    }

    getConfigVendeConStock = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/preVentas/getConfEmpresa/${9}`);
            const data = await response.json();
            this.setState({VenderConStock: String(data[0].Estado) === "0"})
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    getConfigBoletasRuc = async () => {
        this.setState({loading: true, error: null});
        try {
            /************* 45 = CONFIG. PERMITIR VENDER BOLETA CON RUC ************* */
            const response = await fetch(`/api/preVentas/getConfEmpresa/${45}`);
            const data = await response.json();
            this.setState({BoletaRuc: data[0].Estado})
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    getConfigVendeConListaPrecios = async () => {
        this.setState({loading: true, error: null});
        try {
            /************* 2 = CONFIG. PERMITE VER LA LISTA DE PRECIOS EN EL DETALLE (HOVER) ************* */
            const response = await fetch(`/api/preVentas/getConfEmpresa/${2}`);
            const data = await response.json();

            this.setState({venderConListaPrecios: data[0].Estado})
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    getRUSS = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/preVentas/getEmpresaByUser`);
            const data = await response.json();
            this.setState({RUSS: data[0].EsRuss})
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    getProductos(idTipoStock = TIPO_STOCK.CON_COMPROBANTE, query = '') {
        fetch(`/api/preVentas/?search=${query}&idTipoStock=${idTipoStock}`)
            .then(res => res.json())
            .then(productos => {
                this.TributosInProductos(productos.productos)
                return productos
            })
            .then((prods) => {
                this.setState({
                    dataProductos: prods,
                });
            })
            .catch(err => {
                this.setState({error: err});
                console.log("getProductos error: ", err)
            })
    }

    TributosInProductos = (prods) => {
        for (let i = 0; i < prods.length; i++) {
            const itemCalculado = setPreciosYDescuento(prods[i])
            prods[i].Precio = itemCalculado.PrecioVenta
            prods[i].Gratuito = parseInt(prods[i].Gratuito)
        }
    }

    handleChange(e) {
        this.setState({
            buscar: e.target.value
        });
    }

    handleTipoLista(tipoLista) {
        this.setState({
            tipoLista: tipoLista
        })
    }

    async handleTipoComprobante(IdTipoDocumentoSunat) {
        let prodVale = this.state.detalles.filter((detalle) => {
            return (
                detalle.IdTipoStock === 2
            )
        })

        switch (IdTipoDocumentoSunat) {
            case 2:
                if (prodVale.length > 0) {
                    notificationProdValeInDetalle()
                } else {
                    if (this.state.Documento.length === 11 && this.state.IdCliente !== 0) {
                        let ok = await this.updateTipoDoc(IdTipoDocumentoSunat);
                        await this.setState({
                            IdTipoDocumentoSunat: ok ? IdTipoDocumentoSunat : this.state.IdTipoDocumentoSunat,
                            filtroTipoStock: 1,
                            checkfiltroTipoStock: true
                        });
                        this.getProductos(TIPO_STOCK.CON_COMPROBANTE, this.state.buscar)
                    } else {
                        notificationFacturaRUC()
                    }
                }
                break;
            case 4:
                if (prodVale.length > 0) {
                    notificationProdValeInDetalle()
                } else {
                    let ok = await this.updateTipoDoc(IdTipoDocumentoSunat);
                    await this.setState({
                        IdTipoDocumentoSunat: ok ? IdTipoDocumentoSunat : this.state.IdTipoDocumentoSunat,
                        filtroTipoStock: 1,
                        checkfiltroTipoStock: true
                    });
                    this.getProductos(TIPO_STOCK.CON_COMPROBANTE, this.state.buscar)
                }
                break;
            default:
                let ok = await this.updateTipoDoc(IdTipoDocumentoSunat);
                this.setState({
                    IdTipoDocumentoSunat: ok ? IdTipoDocumentoSunat : this.state.IdTipoDocumentoSunat,
                });
                break;
        }
    }

    async updateTipoDoc(idTipoDoc) {
        this.setState({load: true})
        let res = await fetch(`/api/preVentas/update-tipo-doc?idTipoDoc=${idTipoDoc}&idVenta=${this.state.newPreventaId}`, {method: "PUT"})
        if (!res.ok)
            notificarError("No se pudo actualizar el tipo de doc")
        this.setState({load: false})
        return res.ok;
    }

    onChangeCheckBox = async e => {
        const {name, checked} = e.target;
        if (this.state.IdTipoDocumentoSunat === VALE) {

            let tmpDets = [...this.state.detalles];
            this.setState({load: true})
            for (let i = 0; i < tmpDets.length; i++)
                await this.removeDetVenta(tmpDets[i])


            this.setState({[name]: 2, checkfiltroTipoStock: checked, load: false})
            if (checked)
                this.getProductos(TIPO_STOCK.CON_COMPROBANTE, this.state.buscar)
            else
                this.getProductos(TIPO_STOCK.SIN_COMPROBANTE, this.state.buscar)
        } else {
            this.setState({[name]: 1})
            notificationStockSinComprobante()
        }
    };

    onChangeAfectGratuita = async (e, item) => {
        this.updateGratuitoWrapper(e.target.value, item);
    }

    async updateGratuitoWrapper(value, item) {
        this.setState({load: true});
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;

        let items = [...this.state.detalles];
        let i = items.indexOf(item);

        const checked = String(value) !== "-1";
        item.checked = checked
        item.Gratuito = checked ? 1 : 0

        if (checked) {
            item.Descuento = 0
            item.IdAfectacionIgv = value
        } else
            item.IdAfectacionIgv = item.initialAfectGrat

        items[i] = item;
        this.setState({detalles: items})
        await this.ActualizarDetallePreventa(item)
        await this.getTotales(items)
        await this.ActualizarPreventaMontoInDB();
        this.getProductos(tipoStock, this.state.buscar);
        this.setState({load: false});
    }

    DocChange = (e) => {
        this.setState({
            Documento: e.target.value
        }, () => {
            if (this.state.Documento.length === 8 ||
                this.state.Documento.length === 11 ||
                this.state.Documento.length === 9) {
                this.TraerClientePorDoc(this.state.Documento)
                if (this.state.Documento.length === 8 || this.state.Documento.length === 9) {
                    this.handleTipoComprobante(4)
                }
            }
        });
    }

    AsignarValorDoc(dataCliente) {
        this.setState({
            Documento: dataCliente.NroTipoDocumento
        })
        this.TraerClientePorDoc(dataCliente.NroTipoDocumento)
    }

    AliasChange = e => {
        this.setState({
            Alias: e.target.value
        });
    }

    setActiveInputDoc = (e, inputName) => {
        e.target.select()
        this.setState({
            inputName: inputName
        });
    }

    async AddProductoInList(item) {
        let {dets, targetItem} = this.addDetInList(item);
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        const totales = this.getTotales(dets);
        this.setState({load: true})
        const res = await this.commit(targetItem, 1, totales)
        this.setState({load: false})
        if (res.isOk && !res.needReset) {
            targetItem.IdDetallePreventa = res.det.IdDetallePreventa
            this.refresh(dets, totales, tipoStock)
        } else if (res.needReset)
            await this.generarOtraVenta(this.state.newPreventaId)
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("updated")
    }

    refresh(dets, totales, tipoStock, additionalState = {}) {
        this._updateState(dets, totales, additionalState)
        this.getProductos(tipoStock, this.state.buscar)
    }

    addDetInList(item) {
        $("#InputBuscarProd").focus();
        let detalles = [...this.state.detalles];
        let tmpDetails = [];
        let itemFounded = detalles.find(i => (i.IdPresentacion === item.IdPresentacion));

        if (itemFounded) {
            itemFounded.Cantidad++;
            FindPrecioPorMayor([], itemFounded)
            itemFounded.Total = calcTotal(itemFounded);
            itemFounded.oldCants.push(itemFounded.Cantidad)
        } else {
            tmpDetails = detalles.map(dp => ({
                ...dp,
                Cantidad: dp.Cantidad,
                PrecioVenta: dp.PrecioVenta,
                Total: dp.Total,
                IdDetallePreventa: dp.IdDetallePreventa,
                Tributos: dp.Tributos,
                PrecioEspecial: FindPrecioEspecial([], dp),
                PrecioFamiliar: FindPrecioFamiliar([], dp),
                PrecioCosto: GetPrecioCosto([], dp),
                PrecioMenor: FindPrecioMenor([], dp),
                precioMayor: getPrecioPorMayor([], dp),
                checked: _.isNumber(dp.checked) ? dp.checked : 0,
                oldPrecios: dp.oldPrecios,
                initialAfectGrat: item.IdAfectacionIgv
            }))
            FindPrecioPorMayor([], item)
            tmpDetails.push({
                ...item,
                Cantidad: 1,
                PrecioVenta: item.PrecioVenta,
                Total: item.PrecioVenta,
                IdDetallePreventa: "",
                Tributos: item.Tributos,
                PrecioEspecial: FindPrecioEspecial([], item),
                PrecioFamiliar: FindPrecioFamiliar([], item),
                PrecioCosto: GetPrecioCosto([], item),
                PrecioMenor: FindPrecioMenor([], item),
                precioMayor: getPrecioPorMayor([], item),
                checked: _.isNumber(item.checked) ? item.checked : 0,
                oldPrecios: [item.PrecioVenta],
                initialAfectGrat: item.IdAfectacionIgv,
                oldCants: [1]
            })
        }

        let dets = itemFounded ? detalles : tmpDetails;
        let targetItem = itemFounded ? itemFounded : tmpDetails[tmpDetails.length - 1];
        return {dets, targetItem};
    }

    async commit(targetItem, variacion, totales) {
        return await this.addDet(targetItem, variacion, false, totales);
    }

    _updateState(dets, totales, aditional = {}) {
        this.setState({
            Gravado: totales.gravados,
            Inafecto: decimalAdjust('floor', totales.inafectos, -2),
            Exonerado: decimalAdjust('floor', totales.exonerados, -2),
            IGVPreventa: totales.gravados * 0.18,
            Gratuitas: decimalAdjust('floor', totales.gratuitos, -2),
            totalMonto: totales.totalRedondeado,
            ICBPERPreventa: decimalAdjust('floor', totales.icbper, -2),
            redondeo: totales.redondeo,
            detalles: dets,
            buscar: this.state.cleanSearch ? '' : this.state.buscar,
            ...aditional
        })
    }

    async addDet(item, variacion, setAll, totales) {
        const idStock = item.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE ?
            item.stocks.find(i => i.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE_PREVENTA).IdStock :
            item.stocks.find(i => i.IdTipoStock === TIPO_STOCK.SIN_COMPROBANTE_PREVENTA).IdStock;

        const payload = {
            detVenta: {
                IdDetallePreventa: item.IdDetallePreventa,
                Cantidad: item.Cantidad,
                Total: item.Total,
                IdStock: idStock,
                IdPresentacion: item.IdPresentacion,
                IdAfectacionIgv: item.IdAfectacionIgv,
                Descuento: item.Descuento || "0",
                PrecioReferencial: item.Precio,
                PrecioVenta: item.PrecioVenta,
                TipoCambio: item.TipoCambio,
                IdAlmacen: item.IdAlmacen,
                ValorUnitario: item.ValorUnitario,
                idTipoStock: item.IdTipoStock,
                variacion: variacion * item.TipoCambio,
                Gratuito: Boolean(item.Gratuito),
                idProd: item.IdProducto,
                setAll: setAll
            },
            venta: {
                IdPreventa: this.state.newPreventaId,
                Total: totales.totalRedondeado,
            }
        }

        const res = await fetch('/api/pre-invoice/commit', {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        })

        const data = await safe(async () => await res.json());

        if (res.ok)
            return {isOk: true, det: data.det}
        else if (res.status === 422) {
            let needReset = false;
            data.msgs.forEach(m => {
                if (m.includes("404"))
                    needReset = true
                const a = m && m.length ? notificarError(m) : ""
            })
            return {isOk: false, needReset: needReset}
        } else {
            notificarError("OOOps ha ocurrido un error")
            return {isOk: false}
        }
    }

    async RemoveProductoInList(item) {
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        this.setState({load: true});
        await this.removeDetVenta(item)
        this.setState({load: false});
        this.getProductos(tipoStock, this.state.buscar);
    }

    async removeDetVenta(item) {
        let detsVenta = [...this.state.detalles];
        detsVenta.splice(detsVenta.indexOf(item), 1)
        this.setState({detalles: detsVenta})

        await this.getTotales(detsVenta)
        await this.ActualizarPreventaMontoInDB();
        await this.RemoveDetallePreVenta(item)
        await this.ActualizarStockComprometidoPreventa(item, 0 - item.Cantidad)
    }

    async AgregarCantidadDet(item) {
        const items = [...this.state.detalles]
        const i = items.indexOf(item)
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        items[i].Cantidad++
        items[i].oldCants.push(items[i].Cantidad)
        FindPrecioPorMayor([], items[i])
        items[i].Total = calcTotal(items[i]);

        const totales = this.getTotales(items);
        this.setState({load: true})
        const res = await this.commit(items[i], 1, totales)
        if (res.isOk && !res.needReset)
            this.refresh(items, totales, tipoStock)
        else if (res.needReset)
            await this.generarOtraVenta(this.state.newPreventaId)
    }


    async RestarCantidadDet(item) {
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        const items = [...this.state.detalles]
        const i = items.indexOf(item)

        if (items[i].Cantidad > 1) {
            items[i].Cantidad--
            items[i].oldCants.push(items[i].Cantidad)
            FindPrecioPorMayor([], items[i])
            items[i].Total = calcTotal(items[i])
            const totales = this.getTotales(items);

            this.setState({load: true})
            const res = await this.commit(items[i], -1, totales)
            if (res.isOk && !res.needReset)
                this.refresh(items, totales, tipoStock)
            else if (res.needReset)
                await this.generarOtraVenta(this.state.newPreventaId)
            else
                this.setState({load: false})
        }
    }

    TraerClientePorDoc = async (DNI) => {
        try {
            await fetch(`/api/preVentas/traerCliente/${DNI}`)
                .then(res => res.json())
                .then(async data => {

                    this.setState({
                        loading: false,
                        dataCliente: data[0] ? data[0] : 0,
                        IdCliente: data[0] ? data[0].IdCliente : 0,
                        StockPorCliente: []
                    });
                    if (data[0]) {
                        if (this.state.RUSS === "Inactivo") {
                            if (this.state.IdTipoDocumentoSunat !== VALE) {
                                if (data[0].IdTipoDocumento === 1 || data[0].IdTipoDocumento === 7) {
                                    let ok = await this.updateTipoDoc(BOLETA)
                                    this.setState({
                                        IdTipoDocumentoSunat: ok ? BOLETA : this.state.IdTipoDocumentoSunat,
                                        filtroTipoStock: 1,
                                        checkfiltroTipoStock: true
                                    })
                                } else {
                                    let ok = await this.updateTipoDoc(FACTURA)
                                    this.setState({
                                        IdTipoDocumentoSunat: ok ? FACTURA : this.state.IdTipoDocumentoSunat,
                                        filtroTipoStock: 1,
                                        checkfiltroTipoStock: true
                                    })
                                }
                            }
                        }
                    }
                });

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    InputCantidadDetalleChange = async (event, item) => {
        const items = [...this.state.detalles]
        const i = items.indexOf(item)
        const {value} = event.target;
        if (value >= 0) {
            items[i].Cantidad = items[i].IdUnidad === 58 || items[i].IdUnidad === 59 ? parseInt(value) : value;
            FindPrecioPorMayor([], items[i])
            items[i].Total = calcTotal(items[i]) - items[i].Descuento
            this._updateState(items, this.getTotales(items))
        } else
            notificationCantidadNegativa()
    }

    ActualizarDetallePreventa = async (item) => {
        try {
            let res = await fetch(`/api/preVentas/editDet/${item.IdDetallePreventa}`, {
                method: "PUT",
                body: JSON.stringify({
                    IdPreventa: this.state.newPreventaId,
                    IdStock: item.IdStock,
                    IdPresentacion: item.IdPresentacion,
                    Cantidad: item.Cantidad,
                    Total: item.Total,
                    PrecioVenta: item.PrecioVenta,
                    Precio: item.Precio,
                    ValorUnitario: item.ValorUnitario,
                    Descuento: item.Descuento,
                    Gratuito: item.Gratuito,
                    IdAfectacionIgv: item.IdAfectacionIgv
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })

            if (res.status >= 400)
                await this.generarOtraVenta(this.state.newPreventaId);
            else {
                let json = await res.json();
                if (!json.status) await this.generarOtraVenta(this.state.newPreventaId);
            }

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    ActualizarStockComprometidoPreventa = async (item, variacionStock, setAll = false) => {
        const idStock = item.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE ?
            item.stocks.find(i => i.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE_PREVENTA).IdStock :
            item.stocks.find(i => i.IdTipoStock === TIPO_STOCK.SIN_COMPROBANTE_PREVENTA).IdStock;

        try {
            let res = await fetch(`/api/preVentas/editStockCompromPreV/${idStock}?idPreventa=${this.state.newPreventaId}&${setAll ? "setAll=1" : ""}`, {
                method: "PUT",
                body: JSON.stringify({
                    variacionStock: variacionStock * item.TipoCambio
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            if (res.status >= 400)
                await this.generarOtraVenta(this.state.newPreventaId);
            else {
                let json = await res.json();
                if (!json.status) await this.generarOtraVenta(this.state.newPreventaId);
            }
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    PrecioChange = (e, item) => {
        this.findPrecioUnitario(item, e.target.value, e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text)
    }

    async findPrecioUnitario(item, value, precioSelectedText) {
        this.setState({load: true});
        await this.selectPrecioChange(item, value, precioSelectedText)
        this.setState({load: false});
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        this.getProductos(tipoStock, this.state.buscar);
    }

    async selectPrecioChange(item, value, precioSelectedText) {

        let listaItems = [...this.state.detalles]
        const key = listaItems.indexOf(item)
        let newItem = listaItems[key];

        const precio = item.precios.find(p => p.NombrePrecio.includes(precioSelectedText));

        newItem.Precio = precio ? precio.Precio : 0
        newItem.PrecioVenta = precio ? precio.Precio : 0
        newItem.ValorUnitario = precio ? precio.ValorUnitario : 0;
        newItem.Total = calcTotal({Cantidad: listaItems[key].Cantidad, PrecioVenta: newItem.PrecioVenta})
        newItem.PrecioReferencial = parseFloat(newItem.PrecioVenta)

        listaItems[key] = newItem;

        this.setState({
            detalles: listaItems
        })

        await this.getTotales(listaItems)
        await this.ActualizarPreventaMontoInDB();
        await this.ActualizarDetallePreventa(listaItems[key])
    }

    InputPrecioVentaChange = (event, item) => {
        this.precioVentaChange(event.target.value, item)
    }

    precioVentaChange(val, item) {
        const listaItems = [...this.state.detalles]
        const key = listaItems.indexOf(item)
        const value = val;
        const itemCalculo = this.getItemCalculo(item);

        listaItems[key].oldPrecios.push(listaItems[key].PrecioVenta);
        listaItems[key].PrecioVenta = value
        listaItems[key].ValorUnitario = setValorUnitario(itemCalculo)
        listaItems[key].Total = calcTotal(listaItems[key]) - listaItems[key].Descuento

        this._updateState(listaItems, this.getTotales(listaItems))
    }

    getItemCalculo(item) {
        return {
            "PrecioVenta": item.PrecioVenta,
            "Tributos": item.Tributos,
            "TasaISC": item.TasaISC,
            "Cantidad": item.Cantidad,
            "Gratuito": item.Gratuito,
            "Descuento": item.Descuento
        }
    }

    DescuentoChange = async (event, item) => {
        const listaItems = [...this.state.detalles]
        const key = listaItems.indexOf(item)
        const value = isNumber(event.target.value) ? event.target.value : 0;

        if (Number(listaItems[key].Gratuito) === 0) {
            if (value <= listaItems[key].Total) {
                listaItems[key].Descuento = value
                listaItems[key].Total = calcTotal(listaItems[key]) - listaItems[key].Descuento
                this.setState({detalles: listaItems, ItemDescuento: listaItems[key]})
                await this.getTotales(listaItems)
            } else
                notificationDescuentoMayorTotal()
        } else
            notificationDescuentoGratuito()

    }

    async onBlurDescuento(e, item) {
        this.setState({load: true});
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        await this.getTotales(this.state.detalles)
        await this.ActualizarPreventaMontoInDB();
        await this.ActualizarDetallePreventa(item)
        this.getProductos(tipoStock, this.state.buscar);
        this.setState({load: false});
    }

    getTotales = (items) => {
        const itemsTmp = [...items];
        const totales = calcularTotales(itemsTmp);
        console.log("totales ", totales)
        return totales;
    }

    async ActualizarPreventaMontoInDB() {
        try {
            let res = await fetch(`/api/preVentas/PreventaUpdateMonto/${this.state.newPreventaId}`, {
                method: "PUT",
                body: JSON.stringify({
                    Total: this.state.totalMonto
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })

            if (res.status >= 400)
                await this.generarOtraVenta(this.state.newPreventaId);
            else {
                let json = await res.json();
                if (!json.status) await this.generarOtraVenta(this.state.newPreventaId);
            }

        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    NewPreVenta = async () => {
        this.setState({loading: true, error: null});
        let preventaId = window.localStorage.getItem('idPreventa')
        if (preventaId) {
            const response = await fetch(`/api/gestionPreventas/preventa/${preventaId}`);
            const dataJson = await response.json();
            const preventa = dataJson.respuesta[0];
            try {
                const response = await fetch(`/api/gestionPreventas/detallepreventa/${preventaId}`);
                const data = await response.json();
                let detsProd = data.respuesta.map(dp => ({
                    ...dp,
                    mensaje: "",
                    PrecioVenta: dp.PrecioVenta,
                    IdTipoStock: dp.IdTipoStock,
                    IdPrecioPlantilla: dp.IdPrecioPlantilla,
                    Simbolo: dp.Simbolo,
                    Tributos: dp.Tributos,
                    TasaISC: dp.TasaISC,
                    PrecioEspecial: FindPrecioEspecial([], dp),
                    PrecioFamiliar: FindPrecioFamiliar([], dp),
                    PrecioCosto: GetPrecioCosto([], dp),
                    PrecioMenor: FindPrecioMenor([], dp),
                    checked: dp.Gratuito === 1,
                    IdUnidad: dp.IdUnidad,
                    oldPrecios: [],
                    oldCants: [dp.Cantidad],
                }))
                this.setState({
                    detalles: detsProd, newPreventaId: preventaId,
                    IdTipoDocumentoSunat: preventa.IdTipoDocumentoSunat
                })
            } catch (error) {
                this.setState({loading: false, error: error});
            }
        } else {
            await this.createPreventa();
        }
    }

    async createPreventa() {
        fetch("/api/preVentas/add", {
            method: "POST",
            body: JSON.stringify({
                IdCliente: this.state.IdCliente,
                Estado: this.state.Estado,
                Codigo: this.state.Codigo,
                IdMoneda: this.state.IdMoneda,
                TipoCambio: this.state.TipoCambio,
                TasaIGV: this.state.TasaIGV,
                TasaICBPER: this.state.TasaICBPER,
                Descuento: this.state.Descuento,
                Total: this.state.totalMonto,
                IdModalidadPago: this.state.IdModalidadPago,
                IdTipoDocumentoSunat: this.state.IdTipoDocumentoSunat,
                Alias: this.state.Alias
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        }).then(res => res.json())
            .then(data => {
                window.localStorage.setItem('idPreventa', data);
                this.setState({
                    newPreventaId: data
                })
            })
            .catch(err => this.setState({error: err}));

    }

    AddDetallePreVenta = async (item) => {
        try {
            let res = await fetch("/api/preVentas/addDetalle", {
                method: "POST",
                body: JSON.stringify({
                    IdPreventa: this.state.newPreventaId,
                    Cantidad: item.Cantidad,
                    Total: item.Total,
                    IdStock: item.IdStock,
                    IdPresentacion: item.IdPresentacion,
                    IdAfectacionIgv: item.IdAfectacionIgv,
                    Descuento: 0,
                    PrecioReferencial: item.Precio,
                    PrecioVenta: item.PrecioVenta,
                    TipoCambio: item.TipoCambio,
                    IdAlmacen: item.IdAlmacen,
                    ValorUnitario: item.ValorUnitario
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })

            if (res.status >= 400)
                await this.generarOtraVenta(this.state.newPreventaId);
            else {
                let json = await res.json();
                if (json.status) {
                    item.IdDetallePreventa = json.respuesta;
                    let detsItem = [...this.state.detalles];
                    let index = detsItem.indexOf(detsItem);
                    detsItem[index] = item
                    this.setState({detalles: detsItem})
                    await this.ActualizarStockComprometidoPreventa(item, 1)
                } else
                    await this.generarOtraVenta(this.state.newPreventaId);
            }
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    RemoveDetallePreVenta = async item => {
        this.setState({loading: true, error: null});
        try {
            let res = await fetch(`/api/preVentas/deleteDetPreVenta/${item.IdDetallePreventa}?idPreventa=${this.state.newPreventaId}`, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            if (res.status >= 400)
                await this.generarOtraVenta(this.state.newPreventaId)
            else {
                let json = await res.json();
                if (!json.status)
                    await this.generarOtraVenta(this.state.newPreventaId)
            }
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    async handleGenerarPreventa(IdPreventa) {
        let tieneProductosNoFacturables = this.tieneProductosNoFacturables(this.state.detalles);
        let tieneDetallesConCantidadCero = this.tieneDetallesConCantidadCero(this.state.detalles);
        let tipoDoc = this.state.IdTipoDocumentoSunat;
        let estadoVenta = await this.estadoVenta(IdPreventa);
        if (tipoDoc > 1 && tieneProductosNoFacturables) {
            swal({
                title: "Alerta de venta",
                text: "Revise sus productos",
                icon: "warning",
                buttons: "Ok"
            });
        } else if (tieneDetallesConCantidadCero) {
            swal({
                title: "Alerta de venta",
                text: "Tiene ventas con cantidad 0",
                icon: "warning",
                buttons: "Ok"
            });
        } else if (estadoVenta === "CANCELADA")
            await this.generarOtraVenta(IdPreventa);
        else
            await this.continueSell(IdPreventa);
    }

    async generarOtraVenta(IdPreventa) {
        const generarOtra = await swal({
            title: "Ooops!",
            text: "Al parecer se ha eliminado la preventa, ¿Desea generar una con los mismo datos?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            closeOnClickOutside: false
        });
        window.localStorage.removeItem('idPreventa');
        if (generarOtra)
            await this.clonePreventa(IdPreventa);
        else
            window.location.reload()
    }

    async clonePreventa(idPreventa) {
        this.setState({load: true});
        let res = await fetch(`/api/preVentas/clone-preventa/?idPreventa=${idPreventa}`);
        let json = await res.json();
        if (json.idPreventa)
            window.localStorage.setItem('idPreventa', json.idPreventa);
        window.location.reload();
    }

    async estadoVenta(idPreventa) {
        let res = await fetch(`/api/preVentas/status-preventa?idPreventa=${idPreventa}`);
        if (res.status === 404) {
            window.localStorage.removeItem('idPreventa');
            window.location.reload();
        }
        let json = await res.json();
        return json.Estado;
    }

    tieneDetallesConCantidadCero(dets) {
        return dets.filter(d => Number(d.Cantidad) === 0 || Number(d.Total) === 0).length;
    }

    async continueSell(IdPreventa) {
        if (this.state.BoletaRuc === 1) {
            if (this.state.dataCliente.IdTipoDocumento === RUC && this.state.IdTipoDocumentoSunat === BOLETA) {
                const willGenerar = await swal({
                    title: "¿Está seguro?",
                    text: "¿Generar una Boleta con RUC?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    closeOnClickOutside: false
                });
                if (willGenerar) {
                    this.GenerarPreVenta(IdPreventa)
                }
            } else {
                this.GenerarPreVenta(IdPreventa)
            }
        } else {
            if (this.state.dataCliente.IdTipoDocumento === RUC && this.state.IdTipoDocumentoSunat === BOLETA) {
                notificationConfigBoletaRUC()
            } else {
                this.GenerarPreVenta(IdPreventa)
            }
        }
    }

    tieneProductosNoFacturables(dets) {
        for (let i = 0; i < dets.length; i++)
            if (!dets[i].EsFacturable)
                return true;
        return false;
    }

    GenerarPreVenta = async (IdPreventa) => {
        this.setState({loading: true, error: null});
        if (this.state.detalles.length === 0) {
            notificationPreventaVacia()
        } else {
            if (this.state.IdCliente !== 0) {
                if (this.state.dataCliente.NroTipoDocumento === "99999999"
                    && this.state.IdTipoDocumentoSunat === BOLETA && this.state.totalMonto >= 700) {
                    notificationBoletaVarios()
                } else {

                    try {
                        fetch(`/api/preVentas/GenerarPreVenta/${IdPreventa}`, {
                            method: "PUT",
                            body: JSON.stringify({
                                Estado: "GENERADO",
                                IdCliente: this.state.IdCliente,
                                Total: this.state.totalMonto,
                                Alias: this.state.Alias,
                                IdTipoDocumentoSunat: this.state.IdTipoDocumentoSunat
                            }),
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json"
                            }
                        })
                            .then(res => res.json())
                            .then(() => {
                                window.localStorage.removeItem('idPreventa');
                                if (this.state.IdTurno != null) {
                                    this.props.history.push(`/pagos/${IdPreventa}`)
                                } else {
                                    this.props.history.push(`/gestionpreventas`)
                                }
                            })
                            .catch(err => this.setState({
                                loading: false,
                                error: err
                            }))
                    } catch (error) {
                        this.setState({
                            loading: false,
                            error: error
                        });
                    }
                }
            } else {
                notificationClienteNoExiste()
            }
        }
    }

    async handleEnterKeyUp(e) {
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        if (e.key === "Enter") {
            await this.getProductos(tipoStock, this.state.buscar);
            const productos = this.state.dataProductos.productos;
            if (productos.length === 1) {
                this.AddProductoInList(productos[0])
                this.setState({buscar: this.state.cleanSearch ? "" : this.state.buscar,})
            } else {
                this.setState({buscar: this.state.cleanSearch ? "" : this.state.buscar,})
                notificationNoHayProducto()
            }
            this.getProductos(tipoStock, this.state.buscar);
        } else {
            this.getProductos(tipoStock, this.state.buscar);
        }
    }

    onSecretKeyPress(event, detItem) {
        if (event.ctrlKey && event.shiftKey && event.which === NUMBER_KEYS.THREE_NUMBER_KEY)
            this.findPrecioUnitario(detItem, detItem.precioMayor.Precio, "Mayor")
    }

    async onBlurCantidad(e, item) {
        this.setState({load: true});

        let items = [...this.state.detalles]
        const i = items.indexOf(item)
        const value = isNumber(e.target.value) ? e.target.value : 1;
        let variacionStock = item.IdUnidad === 58 || item.IdUnidad === 59 ? parseInt(value) : value;
        const Stock = await this.getStock(item, variacionStock);
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;

        variacionStock = this.state.VenderConStock ? (Stock >= 0 ? variacionStock : 1) : variacionStock;

        if (Stock < 0 && this.state.VenderConStock) {
            notificationStock0(item)
            items[i].Cantidad = 1;
            items[i].Total = calcTotal(items[i]) - items[i].Descuento
        } else {
            items[i].Cantidad = variacionStock;
            items[i].Total = calcTotal(items[i]) - items[i].Descuento
        }

        items[i].oldCants.push(items[i].Cantidad)
        const oldCants = items[i].oldCants;
        const cantExtraccion = oldCants[oldCants.length - 1] - (oldCants[oldCants.length - 2] ?? 0)

        await this.montoTotalDetalle(items)
        await this.ActualizarPreventaMontoInDB();
        await this.ActualizarDetallePreventa(items[i])
        await this.ActualizarStockComprometidoPreventa(items[i], Number(cantExtraccion))
        this.setState({load: false});
        this.getProductos(tipoStock, this.state.buscar);
    }

    async getStock(item, variacion) {
        const query = `idPres=${item.IdPresentacion}&idTipoStock=${item.IdTipoStock}&variacion=${variacion}`
        let stockRes = await fetch(`/api/preVentas/check-stock?${query}`)
        let stock = await stockRes.json();
        return stock.stock;
    }

    onChangeTotal(e, item) {
        const listaItems = [...this.state.detalles]
        const key = listaItems.indexOf(item)
        const value = isNumber(e.target.value) ? e.target.value : 0;

        listaItems[key].Cantidad = value / listaItems[key].PrecioVenta
        listaItems[key].Total = value
        listaItems[key].Descuento = 0
        this.setState({detalles: listaItems})
    }

    async onBlurTotal(e, item) {
        this.setState({load: true});
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        await this.getTotales(this.state.detalles)
        await this.ActualizarPreventaMontoInDB();
        await this.ActualizarDetallePreventa(item);
        await this.ActualizarStockComprometidoPreventa(item, Number(2))
        this.setState({load: false});
        this.getProductos(tipoStock, this.state.buscar);
    }

    async onBlurPrecioVenta(e, item) {
        const {value} = e.target;
        if (value < item.PrecioCosto) {
            new Noty({
                type: "warning",
                theme: "bootstrap-v4",
                layout: "topCenter",
                text: `El precio ingresado es menor al precio de costo`,
                timeout: 1500
            }).show()
            let precio = item.oldPrecios.reverse().find(g => Number(g) >= Number(item.PrecioCosto));
            this.precioVentaChange(precio || item.PrecioCosto, item)
        }
    }

    render() {
        const productos = this.state.dataProductos.productos;

        if (this.state.error)
            return `Error: ${this.state.error.message}!!`;


        return (
            <React.Fragment>
                <div className="preventa__container">
                    <div className="preventa">

                        <div className="encabezado">
                            <div className="preventa__tipo--comprobante">
                                {this.getBotonTipoComprobante(FACTURA, "Factura")}
                                {this.getBotonTipoComprobante(BOLETA, "Boleta")}
                                {this.getBotonTipoComprobante(VALE, "Vale")}
                            </div>

                            <BuscarClientePorDoc
                                goBack={this.props.history.goBack}
                                DocChange={this.DocChange}
                                Documento={this.state.Documento}
                                setActiveInputDoc={this.setActiveInputDoc}
                                TraerClientePorDoc={this.TraerClientePorDoc}
                                dataCliente={this.state.dataCliente}
                                handleClickNuevo={this.handleClickNuevo}
                                handleClickBuscar={this.handleClickBuscar}
                                handleCloseBuscar={this.handleCloseBuscar}
                                Alias={this.state.Alias}
                                placeholder={this.state.aliasPlaceholder}
                                AliasChange={this.AliasChange}
                            />

                            <div className="PreVBuscarProducto">
                                <input
                                    type="text"
                                    id="InputBuscarProd"
                                    placeholder="Buscar Producto"
                                    className="input__linea mr-2"
                                    onKeyUp={this.handleEnterKeyUp}
                                    value={this.state.buscar}
                                    onChange={this.handleChange}
                                    autoFocus/>

                                <label className="mr-2 mt-1">Stock con Comprobante</label>
                                <label className="checkbox-togglePreventa">
                                    <div className="togglePreventa">
                                        <input
                                            type="checkbox"
                                            name="filtroTipoStock"
                                            className="toggle-state"
                                            onChange={this.onChangeCheckBox}
                                            checked={this.state.checkfiltroTipoStock}
                                        />
                                        <div className="togglePreventa-inner">
                                            <div className="indicator"/>
                                        </div>
                                        <div className="active-bg"/>
                                    </div>
                                </label>
                            </div>

                            <div className="form-group encabezadoPV">

                                <div className="preventa__tipo--comprobante">
                                    {this.BotonTipoLista(1, `General${this.state.load ? " - cargando" : ""}`)}
                                </div>

                                <ListaProductosPreventa
                                    load={this.state.load}
                                    productos={productos}
                                    AddProductoInList={this.AddProductoInList}
                                    checkedTipoStock={this.state.checkfiltroTipoStock}
                                />

                            </div>

                        </div>


                        <div className="preventa__container_data">

                            <div className="preventa__cliente2">
                                {
                                    (this.state.dataCliente.IdTipoDocumento) ?
                                        (
                                            (this.state.dataCliente.IdTipoDocumento === 1 || this.state.dataCliente.IdTipoDocumento === 7) ?
                                                this.state.dataCliente.ApellidoPaterno
                                                    .concat(` ${this.state.dataCliente.ApellidoMaterno} ${this.state.dataCliente.PrimerNombre}`)
                                                : this.state.dataCliente.RazonSocial
                                        )
                                        : "CLIENTE NO REGISTRADO"
                                }
                            </div>

                            <DetallesProductoPreventa
                                load={this.state.load}
                                conStockChecked={this.state.checkfiltroTipoStock}
                                detalles={this.state.detalles}
                                onSecretKeyPress={this.onSecretKeyPress}
                                venderConListaPrecios={this.state.venderConListaPrecios}
                                PrecioChange={this.PrecioChange}
                                InputCantidadDetalleChange={this.InputCantidadDetalleChange}
                                InputPrecioVentaChange={this.InputPrecioVentaChange}
                                RestarCantidadDet={this.RestarCantidadDet}
                                AgregarCantidadDet={this.AgregarCantidadDet}
                                RemoveProductoInList={this.RemoveProductoInList}
                                handleOpenModalDescuento={this.handleOpenModalDescuento}
                                onBlurInputCantidad={this.onBlurCantidad}
                                onBlurPrecioVenta={this.onBlurPrecioVenta}
                                canUpdatePrice={this.state.canUpdatePrice}
                                canUpdateTotal={this.state.canUpdateTotal}
                                onChangeTotal={this.onChangeTotal}
                                onBlurTotal={this.onBlurTotal}
                            />
                        </div>

                        <ModalCliente isOpen={this.state.modalClienteIsOpen} onClose={this.handleCloseModal}
                                      title="NUEVO CLIENTE">
                            <ClienteNuevo onClose={this.handleCloseModal}
                                          NumeroDocumento={this.state.Documento}
                                          AsignarValorDoc={this.AsignarValorDoc}
                            />
                        </ModalCliente>

                        {<Modal
                            isOpen={this.state.statusModalDescuento}
                            onClose={this.handleOnCloseModalDescuento}
                            handleKeyUp={this.handleKeyUp}
                        >
                            <PreventaModalDescuentos
                                load={this.state.load}
                                ItemDescuento={this.state.ItemDescuento}
                                DescuentoChange={this.DescuentoChange}
                                onBlurDescuento={this.onBlurDescuento}
                                afectsIgv={this.state.afectsIgv}
                                onChangeCheckBoxGratuito={this.onChangeAfectGratuita}
                            />
                        </Modal>}

                        {<Modal
                            isOpen={this.state.statusModalSearchCliente}
                            onClose={this.handleCloseBuscar}
                            handleKeyUp={this.handleKeyUp}
                        >
                            <ModalBuscarCliente
                                GetCliente={this.GetCliente}
                            />
                        </Modal>}
                    </div>


                    <div className="totals__container">
                        <div className="totals__container--item">
                            {parseFloat(this.state.Gravado) > 0 ?
                                <p>
                                    Gravado{" "}
                                    <strong>
                                        {Number(this.state.Gravado).toFixed(2)}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.Inafecto) > 0 ?
                                <p>
                                    Inafecto{" "}
                                    <strong>
                                        {this.state.Inafecto}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.Exonerado) > 0 ?
                                <p>
                                    Exonerado{" "}
                                    <strong>
                                        {this.state.Exonerado}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.Gratuitas) > 0 ?
                                <p>
                                    Gratuitas{" "}
                                    <strong>
                                        {this.state.Gratuitas}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.ICBPERPreventa) > 0 ?
                                <p>
                                    ICBPER{" "}
                                    <strong>
                                        {this.state.ICBPERPreventa}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.IGVPreventa) > 0 ?
                                <p>
                                    IGV{" "}
                                    <strong>
                                        {Number(this.state.IGVPreventa).toFixed(2)}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.redondeo) > 0 ?
                                <p>
                                    Redondeo <strong>
                                    {decimalAdjust('floor', this.state.redondeo, -2)}
                                </strong>
                                </p>
                                : null
                            }
                        </div>
                        <div className="totals__container--item-total">
                            <span>{this.state.totalMonto}
                            </span>
                        </div>
                        <div className="totals__container--item">
                            {this.state.load ? null : (
                                <button
                                    disabled={!(_.isNull(this.state.error) || _.isEmpty(this.state.error) || _.isUndefined(this.state.error))}
                                    className="generar__boton btn btn-success"
                                    type="submit"
                                    onClick={() => this.handleGenerarPreventa(this.state.newPreventaId)}
                                >
                                    Generar
                                    {_.isNull(this.state.error) || _.isEmpty(this.state.error) || _.isUndefined(this.state.error) ? "" : "Ah ocurrido un error"}
                                </button>
                            )}
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default PreVentaAdd;

import React from "react";
import '../styles/tabla.css';
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter';
import {Link} from 'react-router-dom';
import { BotonEditar, BotonCancelar } from "../Plantillas/Botones";

class VehiculosDeEmpresaList extends React.Component {

    render(){
        return (
                <ReactTable
                // width='100%'  
                data={this.props.vehiculosFiltrados}
                filterable
                defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value}
                columns={[
                    {
                    columns: [
                        {
                            Header: "Editar",
                            filterable: false,
                            // headerClassName: "",
                            width:90,
                            Cell: original => {
                              return (
                                //   <div align="center">
                                    <BotonEditar
                                        to={`${this.props.state.return}` + original.original.IdVehiculo}
                                        className="btn btn-outline-primary"
                                        title="Editar"
                                    />
                                // </div>
                            );
                        },
                    },
                    {
                    Header: "Placa",
                    id:"Placa",
                    // accessor: "Placa",
                    width:120,
                    // headerClassName:'',
                    accessor: d => d.Placa,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["Placa"] }),
                    filterAll: true
                },
                {
                    Header: "Modelo",
                    id:"Modelo",
                    // accessor: "Modelo",
                    width:120,
                    // headerClassName:'',
                    accessor: d => d.Modelo,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["Modelo"] }),
                    filterAll: true
                },
                {
                    Header: "FechaRevisionTecnica",
                    id:"FechaRevisionTecnica",
                    // accessor: "FechaRevisionTecnica",
                    width:120,
                    // headerClassName:'',
                    accessor: d => d.FechaRevisionTecnica,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["FechaRevisionTecnica"] }),
                    filterAll: true
                },
                {
                    Header: "FechaSOAT",
                    id:"FechaSOAT",
                    // accessor: "FechaSOAT",
                    width:120,
                    // headerClassName:'',
                    accessor: d => d.FechaSOAT,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["FechaSOAT"] }),
                    filterAll: true
                },
                {
                    Header: "EmpresaSOAT",
                    id:"EmpresaSOAT",
                    // accessor: "EmpresaSOAT",
                    width:120,
                    // headerClassName:'',
                    accessor: d => d.EmpresaSOAT,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["EmpresaSOAT"] }),
                    filterAll: true
                },
                {
                    Header: "NumeroSOAT",
                    id:"NumeroSOAT",
                    // accessor: "NumeroSOAT",
                    width:120,
                    // headerClassName:'',
                    accessor: d => d.NumeroSOAT,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["NumeroSOAT"] }),
                    filterAll: true
                },
                
                            ]
                        },
                    ]}
                    defaultPageSize={5}
                    //pageSize={10}
                    className="-striped -highlight"
                    // Text de paginación
                    previousText= 'Anterior'
                    nextText= 'Siguiente'
                    loadingText= 'Cargando...'
                    noDataText= 'No se encontraron registros'
                    pageText= 'Página'
                    ofText= 'de'
                    rowsText= 'filas'                        
                    pageSizeOptions= {[5, 10, 20, 25, 50, 100]}
                                                        
                />
            );
        }
}
export default VehiculosDeEmpresaList;
import React from "react";
import '../styles/tabla.css';
import { isNull } from "util";

class EmpresasFuncionalidadTabla extends React.Component {
    
    render(){
        return (
            <React.Fragment>
                <div id="tableProdPreventa" className="lista_productos">

                    <input 
                        hidden={this.props.state.clasificacion=='- Seleccionar una clasificación -'?true:false}
                        type="text" placeholder="Buscar Configuración" 
                        name="buscar"
                        className="input__linea mr-2" 
                        value={this.props.state.buscar}
                        onChange={this.props.handleChange} 
                        />
                    <div className="lista_productos__item">
                <table className="table table-hover" style={{overflow: "auto"}}
                // 
                >
                <thead >
                    <tr>
                        <th> Configuracion</th>
                        <th> Estado</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.state.configFiltrada.map((obj)=>{
                            // Identificar si es precio o configuracion
                            if(obj.IdPrecioEmpresa == null ){
                                if (obj.Configuracion != "Precio de Costo")
                                    return(
                                    <tr key={obj.IdDetalleConfiguracionEmpresa} className="prodPreventaTr">
                                        <td style={{ fontSize: "14px" }} title={obj.Descripcion}>{obj.Configuracion}</td>
                                        <td style={{ fontSize: "14px" }}><input type="checkbox" defaultChecked={obj.Estado} onChange={this.props.handleChangeCheckBox} name={obj.Configuracion} value={obj.IdDetalleConfiguracionEmpresa}/></td>
                                    </tr>
                                    )
                                else   
                                    return(
                                    <tr key={obj.IdDetalleConfiguracionEmpresa}>
                                        <td>{obj.Configuracion}</td>
                                        <td align="center">
                                            <select required id={obj.IdDetalleConfiguracionEmpresa} className="btn btn-outline-dark dropdown-toggle" name={obj.Configuracion} onChange={this.props.handleChangeList} defaultValue={obj.Estado} aria-haspopup="true" aria-expanded="false" >
                                                <option value="0" defaultValue>Ponderado</option>
                                                <option value="1" >Ultimo Precio</option>
                                                <option value="2">Promedio</option>
                                                <option value="3">Precio Mayor</option>
                                            </select>
                                        </td>
                                    </tr>
                                )
                            } else {
                                if (obj.IdPrecioPlantilla == 1 || obj.IdPrecioPlantilla == 2 || obj.IdPrecioPlantilla == 3){
                                    return(
                                        <tr key={obj.IdPrecioEmpresa}>
                                            <td>{obj.Nombre}</td>
                                            <td align="center"><input disabled type="checkbox" defaultChecked={obj.Estado} onChange={this.props.handleChangeCheckBoxPrecio} name={obj.Nombre} value={obj.IdPrecioEmpresa}/></td>
                                        </tr>
                                        )
                                }else
                                    return(
                                        <tr key={obj.IdPrecioEmpresa}>
                                            <td>{obj.Nombre}</td>
                                            <td align="center"><input type="checkbox" defaultChecked={obj.Estado} onChange={this.props.handleChangeCheckBoxPrecio} name={obj.Nombre} value={obj.IdPrecioEmpresa}/></td>
                                        </tr>
                                        )
                            }
                        }
                        )
                    }
                        </tbody>
                    </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default EmpresasFuncionalidadTabla;
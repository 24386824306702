import React from "react";
import moment from "moment";

export default class TablaNotasCreditoComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {notasCredito} = this.props;

        return (
            <table className="table table-hover">
                <thead>
                <tr>
                    <th scope="col">Fecha</th>
                    <th scope="col">Número CD</th>
                    <th scope="col">Serie</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Descuento</th>
                    <th scope="col">Motivo</th>
                    <th scope="col">Descripcion</th>
                </tr>
                </thead>
                <tbody>
                {notasCredito.map(nc => {
                    return (
                        <tr key={`nc-${nc.IdNotasCD}`}>
                            <td>
                                {moment(nc.FechaEmision).format('DD/MM/YYYY')}
                            </td>
                            <td>
                                {nc.NumeroCD}
                            </td>
                            <td>
                                {nc.SerieCD}
                            </td>
                            <td>
                                {nc.Estado}
                            </td>
                            <td>
                                {nc.Descuento}
                            </td>
                            <td>
                                {nc.motivo}
                            </td>
                            <td>
                                {nc.Descripcion}
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        );
    }
}

import React from "react"
import ModalNCD from "../clientes/ClienteModal";
import ListaRVSearch from "./NotasCDListRVSearch"
import Select from "react-select";

class NotasCDForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDescuento: false,
            item: {},
            items: [],
            idVenta: props.form.IdRegistroVenta,
        }
        this.onChangeItem = this.onChangeItem.bind(this);
    }

    componentDidMount() {
        this.fetchItems(this.props.form.IdRegistroVenta);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.form.IdRegistroVenta !== this.state.idVenta) {
            this.setState({idVenta: prevProps.form.IdRegistroVenta})
            this.fetchItems(prevProps.form.IdRegistroVenta)
        }
    }

    fetchItems(idVenta) {
        fetch(`/api/preVentas/get-items?idVenta=${idVenta}`)
            .then(res => res.json())
            .then(items => this.setState({items: items}))
            .catch(err => console.log(err))
    }

    onChangeItem(item) {
        this.setState({item: item})
        this.props.onChangeItems(item);
    }

    getItems() {
        return this.state.items.map(i => {
            return {label: i.nombre, value: i.idDetVenta, descuento: 0}
        })
    }

    render() {
        const props = this.props;
        return (
            <div className="container align-content-center">
                <div className="row">
                    <div className="col ">
                        <div className="card ">
                            <div className="card-header">
                                Registro de Notas de Crédito y Débito
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                    <label htmlFor="TipoNCD">Tipo de Comprobante</label>
                                    <select
                                        id="TipoNCD"
                                        className="form-control"
                                        value={props.form.TipoNCD}
                                        onChange={props.onChangeTipoNCD}
                                    >
                                        <option value="1">01 - Nota de Crédito</option>
                                        <option value="2">02 - Nota de Débito</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="IdTipoNCD">Tipo Afectación</label>
                                    <select
                                        id="IdTipoNCD"
                                        className="form-control"
                                        value={props.form.IdTipoNCD}
                                        onChange={props.onChangeTipoNCDdetalle}
                                    >
                                        {props.dataTipoNCDFilter.map(item => {
                                            return (
                                                <option
                                                    key={item.IdTipoNCD}
                                                    value={item.IdTipoNCD}
                                                > {item.CodigoTipoNCD} - {item.DescripcionTipoNCD} </option>
                                            )
                                        })
                                        }
                                    </select>
                                </div>

                                <label>Comprobante que Modifica </label>
                                <div className="form-row align-items-end justify-content-lg-center">
                                    <div className="form-group col-md-3">
                                        <label htmlFor="SerieCE">Serie</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="SerieCE"
                                            name="SerieCE"
                                            disabled={!!props.form.IdRegistroVenta}
                                            value={props.form.SerieCE}
                                            onChange={props.onChangeInput}
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="NumeroCE">Número</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="NumeroCE"
                                            name="NumeroCE"
                                            disabled={!!props.form.IdRegistroVenta}
                                            value={props.form.NumeroCE}
                                            onChange={props.onChangeInput}
                                        />
                                    </div>
                                    <div className="form-group col-md-1">
                                        <label
                                            className="btn btn-outline-secondary fas fa-search"
                                            id="buscar"
                                            onClick={props.handleClickBuscarRV}
                                            title="buscar comprobante"
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label
                                            className="btn btn-outline-secondary fas fa-hand-pointer"
                                            id="seleccionar"
                                            onClick={props.handleClickSeleccionarRV}
                                            title="seleccionar comprobante"
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Descripcion">Descripción o Motivo</label>
                                    <textarea
                                        className="form-control"
                                        id="Descripcion"
                                        rows="3"
                                        value={props.form.Descripcion}
                                        onChange={props.onChangeDescripcion}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>
                                        Descuento/Monto
                                    </label>
                                    <input type="number" min="1" className="form-control"
                                           onChange={props.onChangeDescuento}
                                           value={props.form.descuento}/>
                                </div>

                                <div className="form-group">
                                    <label>Seleccione items</label>
                                    <Select onChange={this.onChangeItem} isSearchable={true} options={this.getItems()}/>
                                </div>

                                <div className="mt-3">
                                    <table className="table table-hover">
                                        <thead>
                                        <tr>
                                            <td>Nombre</td>
                                            <td>Descuento</td>
                                            <td>Opciones</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {props.form.items.map(i => (
                                            <tr key={`tr-item-${i.value}`}>
                                                <td>{i.label}</td>
                                                <td>
                                                    <input onClick={e => e.target.select()}
                                                           onChange={e => props.onChangeItemDescuento(e, i)}
                                                           type="number" value={i.descuento}/>
                                                </td>
                                                <td>
                                                    <button onClick={() => this.props.onRemoveItem(i)}
                                                            className="btn btn-outline-danger">
                                                        Quitar
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>

                                <button
                                    className="btn btn-info btn-lg btn-block"
                                    onClick={props.handleSubmit}>
                                    Registrar
                                </button>
                            </div>
                            <div className="card-footer">

                            </div>
                        </div>

                        <ModalNCD isOpen={props.form.modalListRVIsOpen} onClose={props.handleCloseModalRV}
                                  title="BUSCAR REGISTRO DE VENTA">
                            <ListaRVSearch
                                onClose={props.handleCloseModalRV}
                                form={props.form}
                            />
                        </ModalNCD>

                    </div>
                </div>
            </div>
        )
    }
}

export default NotasCDForm

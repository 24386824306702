import React from 'react';
import swal from "sweetalert";

class ProveedorForm extends React.Component {
    render() {
        return (
            <div>
                <hr width="900px"></hr>

                <form onSubmit={this.props.onSubmit} className="grid">
                    <ul className="nav nav-tabs" id="pills-tab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home"
                               role="tab" aria-controls="pills-home" aria-selected="true">Datos Generales</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="pills-ubigeo-tab" data-toggle="pill" href="#pills-ubigeo"
                               role="tab" aria-controls="pills-profile" aria-selected="false">Ubicación y Contacto</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile"
                               role="tab" aria-controls="pills-profile" aria-selected="false">Condiciones de
                                Contribuyente</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="pills-estado-tab" data-toggle="pill" href="#pills-estado"
                               role="tab" aria-controls="pills-estado" aria-selected="false">Estados</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active p-3" id="pills-home" role="tabpanel"
                             aria-labelledby="pills-home-tab">
                            <div className="form-group row">
                                <div className="col-7">
                                    <label>Tipo Proveedor</label>
                                    <select onChange={this.props.onChange} className="form-control"
                                            name="IdTipoProveedor"
                                            value={this.props.formValues.IdTipoProveedor || 1}
                                            disabled={this.props.accion === "Actualizar Cliente" ? true : false}
                                    >
                                        <option>seleccionar</option>
                                        <option value="1">Nacional</option>
                                        <option value="2">Extranjero</option>
                                    </select>
                                </div>

                                <div className="col-5">
                                    <label> Nro RUC</label>
                                    <div className="input-group mb-3">
                                        <input onChange={this.props.onChange} className="form-control" type="text"
                                               name="Ruc"
                                               value={this.props.formValues.Ruc}
                                               disabled={this.props.accion === 'Actualizar Proveedor' ? true : false}
                                        />
                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-outline-secondary"
                                                    onClick={this.props.onSubmitRUC}
                                                    disabled={this.props.accion === 'Actualizar Cliente' ? true : false}>
                                                RUC
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Razon Social / Nombre</label>
                                        <input onChange={this.props.onChange} className="form-control" type="text"
                                               name="RazonSocial"
                                               value={this.props.formValues.RazonSocial}
                                               disabled={this.props.accion === 'Actualizar Cliente' ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Nombre Comercial</label>
                                        <input onChange={this.props.onChange} className="form-control text-uppercase"
                                               type="text" name="NombreComercial"
                                               value={this.props.formValues.NombreComercial}/>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="tab-pane fade p-3" id="pills-ubigeo" role="tabpanel"
                             aria-labelledby="pills-ubigeo-tab">
                            <div className="form-group row">
                                <div className="col-4">
                                    <label>Región</label>
                                    <select onChange={this.props.onChangeRegion} className="form-control custom-select "
                                            name="Region"
                                            value={this.props.formValues.Region}>
                                        <option value={null}>selecccione región</option>
                                        {
                                            this.props.datareg.map((item) => {
                                                return <option key={item.IdUbigeo}
                                                               value={item.CodRegion}> {item.NombreUbigeo} </option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-4">
                                    <label>Provincia</label>
                                    <select onChange={this.props.onChangeProvincia}
                                            className="form-control custom-select " name="Provincia"
                                            value={this.props.formValues.Provincia}>
                                        <option value={null}>selecccione provincia</option>
                                        {
                                            this.props.dataprov.map((item) => {
                                                return <option key={item.IdUbigeo}
                                                               value={item.CodProvincia}> {item.NombreUbigeo} </option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-4">
                                    <label>Distrito</label>
                                    <select onChange={this.props.onChangeDistrito}
                                            className="form-control custom-select " name="Distrito"
                                            value={this.props.formValues.Distrito}>
                                        <option value={null}>selecccione distrito</option>
                                        {
                                            this.props.datadist.map((item) => {
                                                return <option key={item.IdUbigeo}
                                                               value={item.CodDistrito}> {item.NombreUbigeo} </option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-12">
                                    <label>Dirección</label>
                                    <textarea onChange={this.props.onChange} className="form-control text-uppercase"
                                              type="text" name="Direccion"
                                              value={this.props.formValues.Direccion}/>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-3">
                                    <label>Telefono</label>
                                    <input onChange={this.props.onChange} className="form-control" type="text"
                                           name="Telefono"
                                           value={this.props.formValues.Telefono}/>
                                </div>
                                <div className="col-3">
                                    <label>Celular</label>
                                    <input onChange={this.props.onChange} className="form-control" type="text"
                                           name="Celular"
                                           value={this.props.formValues.Celular}/>
                                </div>
                                <div className="col-6">
                                    <label>Correo Electrónico</label>

                                    <input onChange={this.props.onChange} className="form-control" type="text"
                                           name="Correo" placeholder="nombre@ejemplo.com"
                                           value={this.props.formValues.Correo}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-12">
                                    <label>Persona Contacto</label>
                                    <input onChange={this.props.onChange} className="form-control text-uppercase"
                                           type="text" name="Contacto"
                                           value={this.props.formValues.Contacto}/>
                                </div>
                            </div>

                        </div>
                        <div className="tab-pane fade p-3" id="pills-profile" role="tabpanel"
                             aria-labelledby="pills-profile-tab">
                            <div className="form-group col-center">
                                <div className="form-check m-3">
                                    <input onChange={this.props.onChangeCheckBox} className="form-check-input"
                                           type="checkbox" name="AgentePercepcion"
                                           checked={this.props.formValues.AgentePercepcion} id="AgentePercepcion"/>
                                    <label className="form-check-label" htmlFor="AgentePercepcion">Agente
                                        Percepción</label>
                                </div>
                                <div className="form-check m-3">
                                    <input onChange={this.props.onChangeCheckBox} className="form-check-input"
                                           type="checkbox" name="AgenteRetencion"
                                           checked={this.props.formValues.AgenteRetencion} id="AgenteRetencion"/>
                                    <label className="form-check-label" htmlFor="AgenteRetencion">Agente
                                        Retención</label>
                                </div>
                            </div>

                        </div>

                        <div className="tab-pane fade p-3" id="pills-estado" role="tabpanel"
                             aria-labelledby="pills-estado-tab">
                            <div className="form-group row">
                                <div className="col-4">
                                    <label>Estado SUNAT</label>
                                    <select onChange={this.props.onChange} className="form-control custom-select "
                                            name="EstadoSunat"
                                            value={this.props.formValues.EstadoSunat}>
                                        <option value={null}>seleccionar</option>
                                        <option value="Activo">Activo</option>
                                        <option value="Inactivo">Inactivo</option>
                                    </select>
                                </div>
                                <div className="col-4">
                                    <label>Condición Domicilio SUNAT</label>
                                    <select onChange={this.props.onChange} className="form-control custom-select "
                                            name="Situacion"
                                            value={this.props.formValues.Situacion}>
                                        <option value={null}>seleccionar</option>
                                        <option value="Habido">Habido</option>
                                        <option value="No Habido">No Habido</option>
                                    </select>
                                </div>
                                <div className="col-4">
                                    <label>Estado Sistema</label>
                                    <select onChange={this.props.onChange} className="form-control custom-select "
                                            name="Estado"
                                            value={this.props.formValues.Estado}>
                                        <option value="Activo" selected>Activo</option>
                                        <option value="Inactivo">Inactivo</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="m-2" align="center">
                        <button type="button" onClick={this.props.onClose} className="btn btn-secondary mr-2">Cancelar
                        </button>
                        <button type="button" onClick={this.props.onSubmit}
                                className="btn btn-primary">{this.props.accion}</button>
                        {this.props.respuestaInsert == -1 && <p className="text-danger">Proveedor Duplicado !!!</p>}
                    </div>
                    {this.props.error && <p className="text-danger">{this.props.error.message}</p>}
                    {this.props.msjerror != '' && <p className="text-danger">{this.props.msjerror}</p>}


                </form>

            </div>
        )
    }
}

export default ProveedorForm;

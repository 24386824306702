import React from 'react';
import ProveedorForm from './ProveedorForm';
import swal from "sweetalert";

class ProveedorNuevo extends React.Component {

    constructor() {
        super();
        this.state = {
            loading: false,
            error: null,
            form: {
                IdProveedor: '',
                IdTipoProveedor: 1,
                Ruc: '',
                RazonSocial: '',
                NombreComercial: '',
                Ubigeo: '',
                Direccion: '',
                Telefono: '',
                Celular: '',
                Correo: '',
                PaginaWeb: '',
                Contacto: '',
                AgentePercepcion: null,
                AgenteRetencion: null,
                EstadoSunat: null,
                Situacion: null,
                IdEmpresa: '',
                Estado: 'Activo',
                Region: '',
                Provincia: '',
                Distrito: '',
            },
            data: [],
            datareg: [],
            dataprov: [],
            datadist: [],
            respuestaInsert: undefined,
        }
    }

    componentDidMount() {
        this.listaUbigeos();
        if (this.props.NumeroDocumento) {
            this.state.form.Ruc = this.props.NumeroDocumento
        }
    }

    /* ******************************************************************
            FUNCION: OBTIENE LA LISTA DE UBIGEOS
    * ******************************************************************/
    listaUbigeos = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/clientes/ubigeos/`);
            const data = await response.json();
            this.setState({loading: false, data: data});

            const dataR = await this.state.data.results.filter((item) => {
                return item.CodProvincia === "00"
            });
            this.setState({datareg: dataR});

        } catch (error) {
            this.setState({loading: false, error: error});
        }
    };
    /* ******************************************************************
            FUNCION: CONTROLA EVENTOS CHANGE
    * ******************************************************************/
    handleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,

            },
        });
    }

    /* ******************************************************************
             FUNCION: CONTROLA EVENTOS CHECKED
     * ******************************************************************/
    handleChangeCheckBox = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.checked

            },
        });
    }

    /* ******************************************************************
             FUNCION: CONTROLA EVENTO CHANGE DE REGIONES
     * ******************************************************************/
    handleChangeRegion = (e) => {
        const dataP = this.state.data.results.filter((item) => {
            return item.CodRegion === e.target.value && item.CodDistrito === "00" && item.CodProvincia != "00"
        });
        // console.log(dataP);
        this.setState({
            dataprov: dataP,
            form: {
                ...this.state.form,
                Ubigeo: e.target.value,
                Region: e.target.value,
                Provincia: null,
            },
            datadist: [],
        });
        //console.log(this.state);
    }

    /* ******************************************************************
            FUNCION: CONTROLA EVENTO CHANGE DE PROVINCIAS
    * ******************************************************************/
    handleChangeProvincia = (e) => {
        const dataD = this.state.data.results.filter((item) => {
            return item.CodRegion === this.state.form.Region && item.CodProvincia === e.target.value && item.CodDistrito != "00"
        });
        this.setState({
            datadist: dataD,
            form: {
                ...this.state.form,
                Ubigeo: `${this.state.form.Region}${e.target.value}`,
                Provincia: e.target.value,
                Distrito: null,
            },

        });
    }

    /* ******************************************************************
            FUNCION: CONTROLA EVENTO CHANGE DE DISTRITOS
    * ******************************************************************/
    handleChangeDistrito = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
                Ubigeo: `${this.state.form.Region}${this.state.form.Provincia}${e.target.value}`,

            },
        });

    }


    handleSubmit = async () => {
        // e.preventDefault();

        this.crearProveedor();
    }


    /* ******************************************************************
            FUNCION: CREA UN NUEVO PROVEEDOR
    * ******************************************************************/
    crearProveedor = async () => {
        this.setState({loading: true, error: null});
        try {
            const config = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IdProveedor: 1,
                    IdTipoProveedor: this.state.form.IdTipoProveedor,
                    Ruc: this.state.form.Ruc,
                    RazonSocial: this.state.form.RazonSocial.toString().toUpperCase(),
                    NombreComercial: this.state.form.NombreComercial,
                    Ubigeo: this.state.form.Ubigeo,
                    Direccion: this.state.form.Direccion,
                    Telefono: this.state.form.Telefono,
                    Celular: this.state.form.Celular,
                    Correo: this.state.form.Correo,
                    Contacto: this.state.form.Contacto.toString().toUpperCase(),
                    AgentePercepcion: this.state.form.AgentePercepcion,
                    AgenteRetencion: this.state.form.AgenteRetencion,
                    EstadoSunat: this.state.form.EstadoSunat ? this.state.form.EstadoSunat : null,
                    Situacion: this.state.form.Situacion ? this.state.form.Situacion : null,
                    IdEmpresa: 1,
                    Estado: this.state.form.Estado
                })
            };

            const response = await fetch(`/api/proveedores/add/`, config);
            const rpta = await response.json();


            this.setState({
                loading: false,
                respuestaInsert: parseInt(rpta[0][0][0].estado),
            });
            /* LUEGO DE REALIZAR EL REGISTRO REGRESAMOS A LA PAGINA DONDE LISTA LOS CLIENTES */
            // console.log(this.state.respuestaInsert);
            if (this.state.respuestaInsert != -1) // no es duplicado
            {
                this.setState({
                    form: {
                        ...this.state.form,
                        IdProveedor: parseInt(rpta[0][0][0].estado),
                    }
                })
                const obtForm = this.state.form
                if (this.props.sw === 'ls_nuevo') {
                    await this.props.listaProveedores();
                    this.props.onClose();
                    swal({
                        title: 'Atención.!',
                        text: `Item creado exitosamente...`,
                        icon: 'success'
                    })
                } else if (this.props.sw === 'oc_nuevo') {
                    this.props.AsignarValorDoc(obtForm);
                    this.props.onClose();
                }
            }


        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    /* ******************************************************************
            FUNCION: API TRAE LOS DATOS DE UN CONTRIBUYENTE POR RUC O DNI
    * ******************************************************************/
    TraerClientePorRUC_API = async (ruc) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_CONSULTA_RUC}/${ruc}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API_RUC}`
                }
            });
            const prov = await response.json();

            if (prov) {

                this.setState({
                    form: {
                        ...this.state.form,
                        Ruc: prov.ruc,
                        RazonSocial: prov.razon_social,
                        NombreComercial: prov.razon_social,
                        EstadoSunat: prov.estado === 'ACTIVO' ? 'Activo' : '',
                        Situacion: prov.condicion_domicilio === 'HABIDO' ? 'Habido' : '',
                        Direccion: prov.direccion,
                        Ubigeo: prov.ubigeo

                    },
                });

                await this.CargarProvincias(prov.ubigeo.toString().substr(0, 2));
                await this.CargarDistritos(prov.ubigeo.toString().substr(2, 2));
                this.setState({
                    form: {
                        ...this.state.form,
                        Region: this.state.form.Ubigeo.toString().substr(0, 2),
                        Provincia: this.state.form.Ubigeo.toString().substr(2, 2),
                        Distrito: this.state.form.Ubigeo.toString().substr(4, 2),
                    },
                });
            } else {
                this.setState({
                    msjerror: "Contribuyente No Existe en BD SUNAT"
                })
            }

        } catch (error) {
            console.log(`Error --- >${error}`);
        }
    }

    handleSubmitRUC = async (e) => {
        e.preventDefault();
        // this.TraerClientePorRUC(this.state.form.NroTipoDocumento);
        this.setState({
            error: null,
            msjerror: ""
        });
        this.TraerClientePorRUC_API(this.state.form.Ruc);
    }

    /* ******************************************************************
            FUNCION: CONTROLA EVENTO CHANGE DE REGIONES
    * ******************************************************************/
    handleChangeRegion = (e) => {
        const dataP = this.state.data.results.filter((item) => {
            return item.CodRegion === e.target.value && item.CodDistrito === "00" && item.CodProvincia != "00"
        });
        // console.log(dataP);
        this.setState({
            dataprov: dataP,
            form: {
                ...this.state.form,
                Ubigeo: e.target.value,
                Region: e.target.value,
                Provincia: null,
            },
            datadist: [],
        });
        //console.log(this.state);
    };

    /* ******************************************************************
            FUNCION: CONTROLA EVENTO CHANGE DE PROVINCIAS
    * ******************************************************************/
    handleChangeProvincia = (e) => {
        const dataD = this.state.data.results.filter((item) => {
            return item.CodRegion === this.state.form.Region && item.CodProvincia === e.target.value && item.CodDistrito != "00"
        });
        this.setState({
            datadist: dataD,
            form: {
                ...this.state.form,
                Ubigeo: `${this.state.form.Region}${e.target.value}`,
                Provincia: e.target.value,
                Distrito: null,
            },

        });

    };

    /* ******************************************************************
            FUNCION: CONTROLA EVENTO CHANGE DE DISTRITOS
    * ******************************************************************/
    handleChangeDistrito = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
                Ubigeo: `${this.state.form.Region}${this.state.form.Provincia}${e.target.value}`,

            },
        });

    };

    CargarProvincias = async (codreg) => {
        const dataP = await this.state.data.results.filter((item) => {
            return item.CodRegion === codreg && item.CodDistrito === "00" && item.CodProvincia != "00"
        });
        // console.log(dataP);
        this.setState({
            dataprov: dataP,
            form: {
                ...this.state.form,
                Region: codreg,
                Provincia: null
            },
            datadist: [],
        });
        //console.log(this.state);
    }

    CargarDistritos = async (codprov) => {
        const dataD = await this.state.data.results.filter((item) => {
            return item.CodRegion === this.state.form.Region && item.CodProvincia === codprov && item.CodDistrito != "00"
        });
        this.setState({
            datadist: dataD,
            form: {
                ...this.state.form,
                Provincia: codprov,
                Distrito: null
            },

        });
    }

    render() {
        return (
            <ProveedorForm
                onClose={this.props.onClose}
                onChange={this.handleChange}
                onChangeCheckBox={this.handleChangeCheckBox}
                onChangeRegion={this.handleChangeRegion}
                onChangeProvincia={this.handleChangeProvincia}
                onChangeDistrito={this.handleChangeDistrito}
                onSubmit={this.handleSubmit}
                onSubmitRUC={this.handleSubmitRUC}
                formValues={this.state.form}
                error={this.state.error}
                datareg={this.state.datareg}
                dataprov={this.state.dataprov}
                datadist={this.state.datadist}
                accion="Registrar Proveedor"
                msjerror={this.state.msjerror}
                respuestaInsert={this.state.respuestaInsert}
            />
        )
    }
}

export default ProveedorNuevo;

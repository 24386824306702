import "react-moment";
import moment from "moment";
import {decimalAdjust} from "../../Global";

export function getDataExportExcel(data, titulo) {
    const {dataKardex} = data

    return [
        {
            columns: [
                {
                    title: titulo
                }
            ],
            data: [
                [],
                [
                    {
                        value: "PERIODO:",
                        style: {font: {bold: true}}
                    },
                    `${moment(data.FechaInicio).format("DD/MM/YYYY")} - ${moment(data.FechaFin).format("DD/MM/YYYY")}`
                ],
                [
                    {
                        value: "RUC:",
                        style: {
                            font: {bold: true}
                        }
                    },
                    `${data.RUC}`
                ],
                [
                    {
                        value: "APELLIDOS Y NOMBRES, DENOMINACIÓN O RAZÓN SOCIAL:",
                        style: {
                            font: {bold: true}
                        }
                    },
                    null, null, null,
                    {
                        value: `${data.RazonSocial}`,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "FFFFFF"}}
                        }
                        //font:{color:{ rgb: "2E2EFE" }} color de  letra
                    }
                ],
                [
                    {
                        value: "ESTABLECIMIENTO:",
                        style: {
                            font: {bold: true}
                        }
                    },
                    null, null,
                    {
                        value: `${data.Establecimiento}`
                    }
                ],
                [
                    {
                        value: "CÓDIGO DE LA EXISTENCIA:",
                        style: {
                            font: {bold: true}
                        }
                    },
                    null, null,
                    {
                        value: `${data.CodigoProducto}`
                    }
                ],
                [
                    {
                        value: "TIPO (TABLA 5):",
                        style: {font: {bold: true}}
                    }, null, null,
                    {
                        value: `${data.CodigoExistencia} ${data.Descripcion}`
                    }
                ],
                [
                    {
                        value: "DESCRIPCIÓN:",
                        style: {
                            font: {bold: true}
                        }
                    },
                    null, null,
                    {
                        value: `${data.nombreProducto}`
                    }
                ],
                [
                    {
                        value: "CÓDIGO DE LA UNIDAD DE MEDIDA:",
                        style: {
                            font: {bold: true}
                        }
                    },
                    null, null,
                    {
                        value: `${data.CodigoUnidad}`
                    }
                ],
                [
                    {
                        value: "MÉTODO DE EVALUACIÓN:",
                        style: {
                            font: {bold: true}
                        }
                    }
                ]
            ]
        },
        {//ySteps: -1,
            columns:
                [
                    {title: ""},
                    {
                        title: "",
                        width: {wpx: 150}
                    },
                    {title: ""},
                    {
                        title: "",
                        width: {wpx: 100}
                    },
                    {
                        title: "",
                        width: {wpx: 100}
                    },
                    {
                        title: "",
                        width: {wpx: 120}
                    },
                    {
                        title: "",
                        width: {wpx: 120}
                    },
                    {
                        title: "",
                        width: {wpx: 120}
                    },
                    {
                        title: "",
                        width: {wpx: 120}
                    },
                    {
                        title: "",
                        width: {wpx: 120}
                    },
                    {
                        title: "",
                        width: {wpx: 120}
                    },
                    {
                        title: "",
                        width: {wpx: 120}
                    },
                    {
                        title: "",
                        width: {wpx: 120}
                    },
                    {
                        title: "",
                        width: {wpx: 120}
                    }
                ],
            data:
                [
                    [
                        {
                            value: "               DOCUMENTO DE TRASLADO, COMPROBANTE DE PAGO, ",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        }, null, null, null,
                        {
                            value: "          TIPO DE ",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "                                          ENTRADAS",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        }, null,
                        {
                            value: "",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {left: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "                                     SALIDAS",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        }, null,
                        {
                            value: "",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {left: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "                             SALDO FINAL",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        }, null,
                        {
                            value: "",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {left: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        }
                    ],
                    [
                        {
                            value: "                                DOCUMENTO INTERNO O SIMILAR",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        }, null, null, null,
                        {
                            value: "      OPERACIÓN",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "       CANTIDAD",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "  COSTO UNITARIO",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "   COSTO TOTAL",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "       CANTIDAD",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "  COSTO UNITARIO",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "   COSTO TOTAL",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "       CANTIDAD",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "  COSTO UNITARIO",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "   COSTO TOTAL",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        }
                    ],
                    [
                        {
                            value: "       FECHA",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        },
                        {
                            value: "      TIPO (TABLA 10)",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        },
                        {
                            value: "         SERIE",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        },
                        {
                            value: "       NÚMERO",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        },
                        {
                            value: "       (TABLA 12)",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        }
                    ]
                ]
        },
        {
            ySteps: -1,
            columns: [],
            data: dataKardex.items.map(row => {
                return [
                    {
                        value: moment(row.FechaEmision).format("DD/MM/YYYY"),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.TipoDocumento || "-",
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "center"}
                        }
                    },
                    {
                        value: row.Serie || "",
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.NumeroComprobante || "",
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.TipoOperacion,
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "center"}

                        }
                    },
                    {
                        value: row.isIn ? row.cantidad : 0,
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                    {
                        value: row.isIn ? decimalAdjust('floor', row.costoUnitario, -6) : 0,
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                    {
                        value: row.isIn ? decimalAdjust('floor', row.total, -6) : 0,
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                    {
                        value: row.isIn ? 0 : row.cantidad,
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                    {
                        value: row.isIn ? 0 : decimalAdjust('floor', row.costoUnitario, -6),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                    {
                        value: row.isIn ? 0 : decimalAdjust('floor', row.total, -6),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                    {
                        value: row.cantidadTotal,
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                    {
                        value: decimalAdjust('floor', row.costoUnitarioTotal, -6),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                    {
                        value: decimalAdjust('floor', row.costoTotal, -6),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                ]
            })
        },
        {
            xSteps: 4,
            ySteps: -1,
            columns: [],
            data: [
                [
                    {
                        value: "        TOTALES",
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                            font: {bold: true}
                        }
                    },
                    {
                        value: dataKardex.cantidadTotalEntradas,
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    }, null,
                    {
                        value: decimalAdjust('floor', dataKardex.costoTotalEntradas, -6),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                    {
                        value: decimalAdjust('floor', dataKardex.cantidadTotalSalidas, -6),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    }, null,
                    {
                        value: decimalAdjust('floor', dataKardex.costoTotalSalidas, -6),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    }, null, null, null,
                ]
            ]
        },
        {
            columns: [],
            data: [
                [
                    {
                        value: "(1) Dirección del Establecimiento o Código según el Registro Único de Contribuyentes.",
                        style: {
                            font: {bold: true}
                        }
                    }
                ]
            ]
        }
    ]
}

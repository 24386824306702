import Notify from "../componentes/Notify";

/**
 * Función **recursiva** que busca el padre de `menuItemToAdd` dentro del `partialMenuTree`.
 * Y una vez conseguido el padre, agrega a `menuItemToAdd` a la lista `children` que es un atributo dentro del padre.
 *
 * @param {Object} menuItemToAdd - el objeto menú que se quiere agregar al MenuTree
 * @param {Object[]} partialMenuTree - lista de objetos menú anidados, donde se va a buscar el padre del `menuItemToAdd`
 *
 * @return {Boolean}
 */
const insertMenuItemToMenuTree = (menuItemToAdd, partialMenuTree) => {
    return partialMenuTree.some( menuItem => {
        /**
         * Si este `menuItem` es el padre, se agregará el `menuItemToAdd` a su lista en el atributo `children`.
         * Y se dentendrá todos loops y las llamadas recursivas a esta función, ya que `some()` se detiene apenas su callback devuelve `true`.
         * Y este `true` se devuelve en cascada a través de todos los `some()` padres, hasta llegar a quién llamó originalmente a esta función.
         * dentro de la función `convertMenuListToMenuTree()` donde termina almacenado en la variable booleana `wasAdded`.
         */
        if (menuItem.IdMenu === menuItemToAdd.IdMenuPadre) {
            menuItem.children.push(menuItemToAdd);
            return true;
        }
        /**
         * Si el `menuItem` no es el padre, entonces aquí se intenta buscar el padre dentro de sus hijos (`children`).
         * La recursividad se termina cuando un `menuItem` no tiene hijos (un array vacío) y por lo tanto
         * el método `[].some(...)` al principio de esta función, automaticamente devuelve `false`
         */
        return insertMenuItemToMenuTree(menuItemToAdd, menuItem.children);
        /**
         * Si el return anterior devuelve `false` (lo cual señala que no se consiguió el padre en ninguno de los hijos, nietos, tataranietos, etc de `menuItem`),
         * entonces `some()` descarta este `menuItem` y continúa con el siguiente en la lista (si es que hay un siguiente).
         */
    })
}
/**
 * Recibe una lista de objetos del formato:
 * ```
 * [
 *   {IdMenu: 1, IdMenuPadre: null, ...},
 *   {IdMenu: 2, IdMenuPadre: null, ...},
 *   {IdMenu: 3, IdMenuPadre: 1, ...},
 *   {IdMenu: 4, IdMenuPadre: 3, ...},
 *   {IdMenu: 5, IdMenuPadre: 3, ...},
 *   {IdMenu: 6, IdMenuPadre: 3, ...},
 *   {IdMenu: 7, IdMenuPadre: 3, ...},
 * ]
 * ```
 *
 * y la devuelve convertida en una lista jerárquica del formato:
 *
 * ```
 * [
 *   {IdMenu: 1, IdMenuPadre: null, children: [
 *     {IdMenu: 3, IdMenuPadre: 1, children: [
 *       {IdMenu: 4, IdMenuPadre: 3, children: [], ...},
 *       {IdMenu: 5, IdMenuPadre: 3, children: [], ...},
 *       {IdMenu: 6, IdMenuPadre: 3, children: [], ...},
 *       {IdMenu: 7, IdMenuPadre: 3, children: [], ...},
 *     ], ...},
 *   ], ...},
 *   {IdMenu: 2, IdMenuPadre: null, children: [], ...},
 * ]
 * ```
 * @param {Object[]} menuList - lista de objetos que deben tener los atributos IdMenu y IdMenuPadre
 * @param {Function} callbackfn - **default: item=>item** Función que realiza transformación al menuItem antes de procesarlo.
 * Por defecto no realiza ninguna transformación.
 * @return {Object[]}
 */
const convertMenuListToMenuTree = (menuList, callbackfn = item=>item) => {
    /**
     * Aqui estoy creando la estructura inicial del MenuTree que es un MenuItem con IdMenu nulo
     * que va a servir como contenedor para todos los MenuItems que tengan el IdMenuPadre nulo
     * dentro de la función insertMenuItemToMenuTree
     */
    let menuTree = [ { IdMenu: null, children: [] } ];

    menuList.forEach( menuItemRaw => {
        const menuItem = callbackfn(menuItemRaw);
        menuItem.children = [];
        const wasAdded = insertMenuItemToMenuTree(menuItem, menuTree)
        if (!wasAdded) {
            Notify(`No se encontró el padre del menú "${menuItem.Nombre}"`, "warning", 1500);
            console.error(menuItem);
            console.error(menuTree);
        }
    });
    return menuTree[0].children;
}

export default convertMenuListToMenuTree;
import React from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import "react-moment";
import moment from "moment";
import MonthPickerInput from "react-month-picker-input";

import("react-month-picker-input/dist/react-month-picker-input.css");

class RegistrosVenta extends React.Component {
    render() {

        return (
            <div style={{width: "800px"}}>
                <div align="right">
                    Selecciona un Mes
                    <MonthPickerInput
                        year={this.props.state.Year}
                        month={this.props.state.Mes}
                        onChange={this.props.handleMonthPickerChange}
                        closeOnSelect="true"
                        lang="es"
                        mode="calendarOnly"
                    />
                </div>

                <ReactTable
                    id="ventas"
                    data={this.props.state.ventas}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value
                    }
                    columns={[
                        {
                            columns: [
                                {
                                    Header: "Seleccionar",
                                    id: "IdRegistroVenta",
                                    accessor: d => d.IdRegistroVenta,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["IdRegistroVenta"]
                                        }),
                                    Cell: f => {
                                        return (
                                            <div align="center">
                                                <button
                                                    className="boton__outline-pequeno--c"
                                                    onClick={() => this.props.obtenerRV(f.original.IdRegistroVenta, f.original)}
                                                >
                                                    {<i className="fas fa-download"/>}
                                                </button>

                                            </div>
                                        );
                                    }
                                },
                                {
                                    Header: "Cliente",
                                    id: "RazonSocial",
                                    accessor: d => d.RazonSocial,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["RazonSocial"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Fecha",
                                    id: "FechaEmision",
                                    accessor: d => moment(d.FechaEmision).format("DD-MM-YYYY"),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["FechaEmision"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Tipo Comprobante",
                                    id: "TipoComprobante",
                                    accessor: d => d.TipoComprobante,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["TipoComprobante"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Serie",
                                    id: "Serie",
                                    accessor: d => d.Serie,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Serie"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Numero Comprobante",
                                    id: "NumeroComprobante",
                                    accessor: d => d.NumeroComprobante,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["NumeroComprobante"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Total",
                                    id: "Total",
                                    accessor: d =>
                                        d.Total.toLocaleString("en", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Total"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                }
                            ]
                        }
                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    previousText="Anterior"
                    nextText="Siguiente"
                    noDataText="No se encontraron registros"
                    loadingText="Cargando..."
                    ofText="de"
                    rowsText="filas"
                    pageText="Página"
                    pageSizeOptions={[10, 20, 25, 50, 100]}
                />
            </div>
        )
    }
}

export default RegistrosVenta;

import matchSorter from "match-sorter";


export const enviaremail = async (data, docpdf) => {
    try {
        var blob = await docpdf.output('blob');

        // adjuntar blob al formdata
        let form = new FormData();
        form.append('pdf', blob, 'mi.pdf');
        form.append('data', JSON.stringify(data));
        let config = {
            method: 'POST',
            body: form
        }
        const res = await fetch(`/api/email/send-venta`, config);
        const rpta = res.ok;

        if (rpta) {
            return true;
        }

    } catch (error) {
        console.error(error.message)
        this.setState({error, isLoading: false});
        return false;
    }
}

export const loadLogoByRUC = async (RUC, tipoImagen, extImg = 'png') => {
    //
    return new Promise(resolve => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.crossOrigin = "anonymous";
        img.src = `${process.env.REACT_APP_FACT_BASE_URL}/datos/${RUC}/Img/logo_${RUC}.${extImg}`;
    });
}


export const loadImagePublic = (url) =>
    fetch(url)
        .then((response) => response.blob())
        .then(
            (blob) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                })
        );


export const getLoadImage = (url) => {
    return new Promise((resolve) => {
        let img = new Image();
        img.onload = () => resolve(img);
        //img.crossOrigin = "anonymous";
        img.src = url;
    });
};

/**
 * Devuelve un simple objeto de configuracion de columna para React Table
 *
 * @param {String} key - Nombre exacto del atributo del objeto
 * @param {String} NombreMostrar - Nombre que se va a mostrar en el header de la tabla
 * @param {Object} style - Estilos a aplicar a columna
 */
export const simpleReactTableColumn = (
    key,
    NombreMostrar = key,
    style = {alignSelf: "center", paddingInline: "20px"}
) => {
    return {
        Header: NombreMostrar,
        accessor: key,
        filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {keys: [key]}),
        filterAll: true,
        style: style,
    };
};

export const numberFormat = (num, n, x, s, c) => {
    num = num ? num : 0
    /**
     * Number.prototype.format(n, x, s, c)
     *
     * @param decimal num: length of decimal
     * @param integer n: length of decimal
     * @param integer x: length of whole part
     * @param mixed   s: sections delimiter
     * @param mixed   c: decimal delimiter
     */

    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = num.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
}

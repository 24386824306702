import _ from "lodash";

export function calculateKardex(items) {
    let newItems = []
    const exists = (x, attr) => !_.isUndefined(x) && !_.isUndefined(x[attr]);
    for (let i = 0; i < items.length; i++) {
        const isIn = Boolean(items[i].isIn);
        const lastItem = newItems[i - 1];
        const costoUnitario = isIn ? items[i].costoUnitario
            : (exists(lastItem, 'costoUnitarioTotal') ? lastItem.costoUnitarioTotal : items[i].costoUnitario);

        const total = costoUnitario * items[i].cantidad
        const _total = isIn ? total : total * -1
        const _cantidad = isIn ? items[i].cantidad : items[i].cantidad * -1;

        const costoTotal = exists(lastItem, 'costoTotal') ? lastItem.costoTotal + _total : _total
        const cantidadTotal = exists(lastItem, 'cantidadTotal') ? lastItem.cantidadTotal + _cantidad : _cantidad

        newItems.push({
            ...items[i],
            isIn,
            total,
            costoTotal,
            cantidadTotal,
            costoUnitario,
            costoUnitarioTotal: costoTotal / cantidadTotal
        })
    }

    const entradas = newItems.filter(i => i.isIn);
    const salidas = newItems.filter(i => !i.isIn);

    return {
        items: newItems,
        cantidadTotalEntradas: entradas.map(i => i.cantidad).reduce((a, b) => a + b, 0),
        costoTotalEntradas: entradas.map(i => i.total).reduce((a, b) => a + b, 0),
        cantidadTotalSalidas: salidas.map(i => i.cantidad).reduce((a, b) => a + b, 0),
        costoTotalSalidas: salidas.map(i => i.total).reduce((a, b) => a + b, 0),
    };
}

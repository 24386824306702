import React from "react";
import PreInvoiceSubForm from "./PreInvoiceSubForm";
import {calcularTotales, decimalAdjust} from "../../Global";
import {defaultPostHeaders, pfetch} from "../../helpers/wrappers";
import {notificarError} from "../Almacenes/AlmacenNotify";
import _ from "lodash";
import $ from 'jquery';
import {getPayloadFromPreVenta} from "../../helpers/utils";

let _isMounted = false;

export default class PreInvoiceForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            payload: {},
            totales: {},
        }
        Object.defineProperty(this, 'isMounted', {
            get: () => _isMounted,
            set: v => {
                _isMounted = v
            }
        })
        this.onChangeSubForm = this.onChangeSubForm.bind(this)
        this.onAddPreInvoice = this.onAddPreInvoice.bind(this)
    }

    componentDidMount() {
        this.isMounted = true
    }

    componentWillUnmount() {
        this.isMounted = false
    }

    onChangeSubForm(data) {
        const totales = calcularTotales(data.detalles)

        this.setState((state, props) => {
            return {
                payload: {
                    ...state.payload,
                    ...data,
                    _totales: {...totales}
                },
                totales: {
                    Gravado: totales.gravados,
                    Inafecto: decimalAdjust('floor', totales.inafectos, -2),
                    Exonerado: decimalAdjust('floor', totales.exonerados, -2),
                    IGVPreventa: totales.gravados * 0.18,
                    Gratuitas: decimalAdjust('floor', totales.gratuitos, -2),
                    totalMonto: totales.totalRedondeado,
                    ICBPERPreventa: decimalAdjust('floor', totales.icbper, -2),
                    redondeo: totales.redondeo,
                },
            }
        })
    }

    onAddPreInvoice() {

        let tmpPayload = this.props.getNormalizedPayload ? this.props.getNormalizedPayload(this.state.payload) :
            this.getNormalizedPayload(this.state.payload);

        $(".trDetallePreventa").removeClass("alert alert-danger")

        pfetch({
            fetch: {
                url: this.props.endpointInvoice,
                headers: {...defaultPostHeaders(), accessCode: this.props.accessCode},
                method: "POST",
                body: JSON.stringify(tmpPayload)
            },
            before: () => this.setState({isLoading: true}),
            then: (v) => this.props.onSuccessAdd(v),
            formErrors: (d) => this.showErrors(d),
            error: () => notificarError("No se pudo agregar la venta"),
            finally: () => {
                if (this.isMounted)
                    this.setState({isLoading: false})
            }
        })
    }

    getNormalizedPayload(payload) {
        return getPayloadFromPreVenta(payload);
    }

    showErrors(res) {
        const error = _.isObject(res.data) ? res.data : {msg: res.data, pos: -1}
        notificarError(error.msg)
        if (error.pos !== -1)
            $(`tr#det-${error.pos}`)
                .addClass("alert alert-danger")
                .focus()

        if (error.msg.includes("stock"))
            $("#BtnReloadProds").click();
    }

    render() {
        const {conf} = this.props;
        const {totales, isLoading} = this.state;

        return (
            <>
                <div className="preventa">
                    <PreInvoiceSubForm
                        showSwitchStock={this.props.showSwitchStock}
                        defaultBgInProductDetail={this.props.defaultBgInProductDetail}
                        hideDocKeys={this.props.hideDocKeys}
                        showDefaultColor={this.props.showDefaultColor}
                        stockKey={this.props.stockKey}
                        prodsUrl={this.props.prodsUrl} preventaId={this.props.preventaId}
                        onChange={this.onChangeSubForm} conf={conf}/>
                </div>

                <div className="totals__container">
                    <div className="totals__container--item">
                        {parseFloat(totales.Gravado) > 0 ?
                            <p>
                                Gravado{" "}
                                <strong>
                                    {Number(totales.Gravado).toFixed(2)}
                                </strong>
                            </p>
                            : null
                        }
                        {parseFloat(totales.Inafecto) > 0 ?
                            <p>
                                Inafecto{" "}
                                <strong>
                                    {totales.Inafecto}
                                </strong>
                            </p>
                            : null
                        }
                        {parseFloat(totales.Exonerado) > 0 ?
                            <p>
                                Exonerado{" "}
                                <strong>
                                    {totales.Exonerado}
                                </strong>
                            </p>
                            : null
                        }
                        {parseFloat(totales.Gratuitas) > 0 ?
                            <p>
                                Gratuitas{" "}
                                <strong>
                                    {totales.Gratuitas}
                                </strong>
                            </p>
                            : null
                        }
                        {parseFloat(totales.ICBPERPreventa) > 0 ?
                            <p>
                                ICBPER{" "}
                                <strong>
                                    {totales.ICBPERPreventa}
                                </strong>
                            </p>
                            : null
                        }
                        {parseFloat(totales.IGVPreventa) > 0 ?
                            <p>
                                IGV{" "}
                                <strong>
                                    {Number(totales.IGVPreventa).toFixed(2)}
                                </strong>
                            </p>
                            : null
                        }
                        {parseFloat(totales.redondeo) > 0 ?
                            <p>
                                Redondeo <strong>
                                {decimalAdjust('floor', totales.redondeo, -2)}
                            </strong>
                            </p>
                            : null
                        }
                    </div>
                    <div className="totals__container--item-total">
                            <span>{totales.totalMonto}
                            </span>
                    </div>
                    <div className="totals__container--item">

                        <button
                            disabled={isLoading}
                            onClick={this.onAddPreInvoice}
                            className="generar__boton btn btn-success"
                        >
                            {isLoading ? 'Cargando' : 'Generar'}
                        </button>

                    </div>
                </div>
            </>
        );
    }

}

import React from 'react';

import LinkButton from "../../componentes/LinkButton";
import {Link} from 'react-router-dom';
import PageLoading from '../PageLoading.js';
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter';
import jsPDF from "jspdf";
import VehiculosDeEmpresaList from '../Vehiculos/VehiculosDeEmpresaList';
import { BotonGuardarLetras,BotonAdd, BotonCancelar, BotonPDF, BotonExcel } from "../Plantillas/Botones";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Vehiculos extends React.Component{

    constructor(){
        super()
        this.state={
            Estado:'activo',
            checkEstado:true,
            mostrarButtonAdd:true,
            dataVehiculos: {
                Vehiculos:[]
            },
            IdEmpresa:'',
            dataEmpresas: [],
            loading: false,
            error:null,
            return:"/vehiculos/editPorEmpresa/"
        }
        this.handleSavePDF = this.handleSavePDF.bind(this);

    }

    componentDidMount(){
        this.getEmpresas();
        // this.intervalo=setInterval(this.obtenerVehiculos,1000)
    } 

    componentWillUnmount(){
        clearInterval(this.intervalo)
    }

    getEmpresas = async () => {
        // this.setState({ loading: true, error: null });
        try {
          const response = await fetch("/api/empresas");
          const data = await response.json();
          const empresas = data.empresas.filter(act => {
            return act.Estado == "Activo";
          });
          this.setState({
            loading: true,
            dataEmpresas: empresas
          });
        } catch (error) {
          this.setState({ loading: false, error: error });
        }
    };

    obtenerVehiculos = async (IdEmpresa) =>{
        this.setState({loading:true, error:null})
        try {
            const response = await fetch(`/api/vehiculos/porEmpresa/${IdEmpresa}`);
            const data = await response.json();
            this.setState({
                // loading: false,
                dataVehiculos: data
            })
            // console.log(data)
        }catch(error){
            this.setState({ loading: false, error: error})
        }
    }
    EstadoChange = (e) => {
        if(this.state.Estado == 'activo'){
            this.setState({
                Estado: 'inactivo',
                checkEstado: false
            });
        } else if (this.state.Estado == 'inactivo'){
            this.setState({
                Estado: 'activo',
                checkEstado: true
            });
        }
       
    }
    EmpresaChange = async(e) => {
        if(e.target.value != "Seleccionar Empresa"){
            this.setState({
                mostrarButtonAdd: false,
                [e.target.name]: e.target.value,
            })
            await this.obtenerVehiculos(e.target.value);
        } else {
            this.setState({
                mostrarButtonAdd: true,
            })
            await this.obtenerVehiculos(0);
        }
    }

    handleSavePDF(e) {
        e.preventDefault();
        let doc = new jsPDF({
          orientation: "landscape"
        });
        
        var pageHeight =
          doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth =
          doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

          

        doc.setFontSize(12);
        doc.text("RELACION DE VEHÍCULOS", 110, 10);
        // console.log(this.state.data.GuiasRemision)
        
        doc.autoTable({
          body: this.state.dataVehiculos.Vehiculos,
          columns:[
            {header: 'MODELO', dataKey: 'Modelo'},
            {header: 'PLACA', dataKey: 'Placa'},
            {header: 'FECHA REVISIÓN TECNICA', dataKey: 'FechaRevisionTecnica'},
            {header: 'FECHA SOAT', dataKey: 'FechaSOAT'},
            {header: 'EMPRESA SOAT', dataKey: 'EmpresaSOAT'},
            {header: 'NÚMERO SOAT', dataKey: 'NumeroSOAT'},
            {header: 'ESTADO', dataKey: 'Estado'},
          ],
          startY: 20,
          margin: 5,
          styles: {
            overflow: "linebreak",
            lineWidth: 0.1,
            tableWidth: "auto",
            columnWidth: "auto",
            halign: "center"
            },
            theme: "plain"
        }).setFontSize(11);
        doc.setFontSize(10);
        doc.setFontType("italic");
        doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");
    
        doc.save("relación_Vehiculos.pdf");
      }

      getDataExportExcel(dataSet) {
        return [
          {
            columns: [
              {
                title: `RELACION DE VRHICULOS`,
                width: { wpx: 80 }
              }
            ],
            data:[]
          },
          {
            columns:[
              {
                title: `PLACA`,
                width: { wpx: 150 }
              },
              {
                title: `MODELO`,
                width: { wpx: 150 }
              },
              {
                title: `FECHA DE REVISION`,
                width: { wpx: 150 }
              },
              {
                title: `FECHA SOAT`,
                width: { wpx: 150 }
              },
              {
                title: `EMPRESA SOAT`,
                width: { wpx: 80 }
              },
              {
                title: `NUMERO SOAT`,
                width: { wpx: 150 }
              }
            ],
            data: dataSet.map(row => {
              return [
                {
                  value: row.Placa,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.Modelo,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.FechaRevisionTecnica,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.FechaSOAT,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.EmpresaSOAT,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.NumeroSOAT,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                }
              ];
            })
          }
        ];
      }

    render(){
        if(this.state.loading == false){
            return <PageLoading/>;
        }

        // if(this.state.dataVehiculos.Vehiculos.length == 0){
        //     return (
        //         <div>
        //             <h4>No se encontraron registros...</h4>
        //             <Link className="btn btn-primary" to= "/vehiculos/add">Crear Nuevo Vehiculo</Link>
        //         </div>
        //     );
        // }else{
            const vehiculosFiltrados = this.state.dataVehiculos.Vehiculos.filter((vehiculo) => {
                return vehiculo.Estado.includes(this.state.Estado)
            })
            const dataExport = this.getDataExportExcel(vehiculosFiltrados)
            
            if(this.state.dataVehiculos.error){
                return `Error: ${this.state.error.message}`;
            }
            return(
                <React.Fragment>
                    <div className="ml-4 mt-4">
                        <BotonCancelar to="/empresas"/>
                    </div>

                    <form className="d-flex justify-content-center">
                        <div className="almacenForm">

                            <div className="almacenDiv">
                                <b className="ml-1">Empresa</b>
                                <div className="input__Almacen">
                                    <select
                                        required
                                        className="input__linea"
                                        aria-haspopup="true"
                                        name="IdEmpresa"
                                        value={this.state.buscarEmpresa}
                                        onChange={this.EmpresaChange}
                                    >
                                        <option>Seleccionar Empresa</option>
                                        {this.state.dataEmpresas.map(empresa => {
                                        return (
                                            <option
                                            key={empresa.ID}
                                            value={empresa.ID}
                                            >
                                            {empresa.RazonSocial}
                                            </option>
                                        );
                                        })}
                                    </select>
                                    <div align ="right" className="mr-4 mb-2"  >
                                        {/* <label>FALTA SELECCIONAR LA EMPRESA Y QUE SEAN ACTIVOS (PARECIDO A EMPLEADOS - REVISAR SI ES NECESARIO CAMBIAR EL BACK Y EN LUGAR DE MANDARLO DEL USER, MANDARLO DEL SELECCIONADO  )</label> */}
                                        <BotonAdd
                                            hidden={this.state.mostrarButtonAdd} title="Nuevo Vehiculo" className="ml-3" state={this.state} to={`/vehiculos/addPorEmpresa/${this.state.IdEmpresa}`}
                                        />
                                        <BotonPDF
                                            hidden={this.state.mostrarButtonAdd} 
                                            title="Guardar PDF"
                                            onClick={this.handleSavePDF}
                                            className="ml-3"
                                        />
                                        <ExcelFile
                                            element={
                                                <BotonExcel 
                                                hidden={this.state.mostrarButtonAdd} 
                                                className="ml-3"    
                                                    type="button" 
                                                    title="Guardar Excel" 
                                                    style={{display:`${this.state.displayButton}`}}/>
                                            }
                                            filename={`Relacion_Vehiculos`}
                                        >
                                            <ExcelSheet
                                            
                                                dataSet={dataExport}
                                                name="VehiculosFile"
                                            >
                                            </ExcelSheet>
                                        </ExcelFile>
                                        {/* <LinkButton hidden={this.state.mostrarButtonAdd} title="Nuevo Vehiculo" className="btn btn-outline-primary ml-1 mr-1" state={this.state} to={`/vehiculos/addPorEmpresa/${this.state.IdEmpresa}`}><i className="ml-1 fa fa-plus-square" ></i></LinkButton> */}
                                        {/* <button title="Guardar PDF" className="btn btn-outline-danger ml-1 mr-1" onClick={this.handleSavePDF}> <i className="fas fa-file-pdf" ></i></button> */}
                                    </div>
                                </div>
                            <div className="almacenDiv">
                                        <b>Activos</b>
                                        <div className="input__Almacen">
                                            <label className="checkbox-toggle">
                                                <div className="toggle">
                                                    <input
                                                    type="checkbox"
                                                    name="Estado"
                                                    className="toggle-state"
                                                    onChange={this.EstadoChange}
                                                    defaultChecked={this.state.Estado}
                                                    />
                                                    <div className="toggle-inner">
                                                    <div className="indicator"/>
                                                    </div>
                                                    <div className="active-bg"/>
                                                </div>
                                            </label>
                                            {/* <select onChange={this.EstadoChange} className="btn btn-outline-dark dropdown-toggle m-5" aria-haspopup="true" aria-expanded="false">
                                                <option value="activo">Activos</option>
                                                <option value="inactivo">Inactivos</option>
                                            </select> */}
                                        </div>
                                </div>
                                {/* <div className="almacenDiv"> */}
                                    
                                {/* </div> */}
                            </div>
                            <VehiculosDeEmpresaList
                                state = {this.state}
                                vehiculosFiltrados = {vehiculosFiltrados}
                            />
                            {/* <input id="buscar" type="text" placeholder="Buscar" value={this.state.buscar}
                            onChange={this.handleChange}/> */}
                        </div>
                    </form>
                </React.Fragment>
            )
        // }
    }

}

export default Vehiculos;
import React from "react"
import NotasCDList from './NotasCDList'
import swal from "sweetalert"
import moment from "moment";
import {convertObjToQueryParams} from "../../helpers/utils";
import {isValidDate} from "../../helpers/validators";

class NotasCDListController extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            error: null,

            IdTipoNCD: "1",
            dataTipoNCD: [],
            IdEmpresa: 1,
            dataNotasCD: [],

            Venta: [],
            DetallesVenta: [],
            extImg: 'png',
            fechaInicio: moment(new Date()).format('YYYY-MM-DD'),
            fechaFin: moment(new Date()).format('YYYY-MM-DD')
        }
        this.sendxml = this.sendxml.bind(this);
        this.onChangeFecha = this.onChangeFecha.bind(this);
    }

    async componentDidMount() {
        await this.getTipos(1)
        await this.getListaNotasCD(1)

    }

    getTipos = async (tipo) => {
        tipo = -1
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/notascd/listtipo/${tipo}`);
            const data = await response.json();
            this.setState({
                dataTipoNCD: data.tipos,
                extImg: data.extImg
            });
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    getListaNotasCD = async (idemp) => {

        this.setState({loading: true, error: null});
        try {
            const queryParams = {fechaInicio: this.state.fechaInicio, fechaFin: this.state.fechaFin}
            const response = await fetch(`/api/notascd/list/${idemp}?${convertObjToQueryParams(queryParams)}`);
            const data = await response.json();
            this.setState({
                loading: false,
                dataNotasCD: data
            });
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    async sendxml(IdRegistroVentas, idNc) {
        try {
            const response = await fetch(`/api/configFactElectronica/ce/enviarnc/${IdRegistroVentas}?idNc=${idNc}`);
            const rpta = await response.json();

            if (rpta.ok) {
                await this.getListaNotasCD(IdRegistroVentas)
                if (rpta.observaciones.length === 0) {
                    swal({
                        title: 'Envío Correcto',
                        text: 'La Nota de Crédito fué enviado al Operador, verifique el Código de Respuesta',
                        icon: 'success'
                    })
                    this.getListaNotasCD();
                } else {
                    swal({
                        title: 'Se envió con errores',
                        text: `El Operador indica Nota de Crédito con errores, verifique el código`,
                        icon: 'warning'
                    })
                    this.getListaNotasCD();
                }
            } else {
                swal({
                    title: 'Error de Envío',
                    text: `La Nota de Crédito no fué enviada al Operador`,
                    icon: 'warning'
                })
            }
        } catch (error) {
            this.setState({isLoading: false});
        }
    }

    async getVenta(IdRegistroVenta) {
        await fetch(`/api/ventas/get/${IdRegistroVenta}`)
            .then(response => response.json())
            .then(Venta => {
                    //
                    this.setState({
                        Venta: Venta.respuesta[0],
                        isLoading: false
                    })
                }
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    async getDetalleVenta(IdRegistroVenta) {
        await fetch(`/api/ventas/detalles/${IdRegistroVenta}`)
            .then(response => response.json())
            .then(DetallesVenta =>
                this.setState({
                    DetallesVenta: DetallesVenta.respuesta,
                    isLoading: false
                })
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    async onChangeFecha(e) {
        await this.setState({
            [e.target.getAttribute('name')]: isValidDate(e.target.value) ? e.target.value
                : this.state[e.target.getAttribute('name')]
        })

        await this.getListaNotasCD(1)
    }

    render() {

        return (
            <div className="container mt-3 w-80">
                <NotasCDList
                    history={this.props.history}
                    IdTipoNCD={this.state.IdTipoNCD}
                    dataTipoNCD={this.state.dataTipoNCD}
                    dataNotasCD={this.state.dataNotasCD}
                    getListaNotasCD={this.getListaNotasCD}
                    sendxml={this.sendxml}
                    loading={this.state.loading}
                    error={this.state.error}
                    extImg={this.state.extImg}
                    fechaInicio={this.state.fechaInicio}
                    fechaFin={this.state.fechaFin}
                    onChangeFecha={this.onChangeFecha}
                />
            </div>
        )

    }
}

export default NotasCDListController

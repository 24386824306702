/******************************************************
 * Las siguientes 3 funciones fueron copiadas desde: https://developer.mozilla.org/en-US/docs/Web/Guide/Printing#Print_an_external_page_without_opening_it
 *
 * Las modifiqué para que al fallar la impresión desde la misma página arrojara error la función principal,
 * para así poder atajar el error y como plan b abrir la URL en una pestaña nueva.
 ******************************************************/

// const printInPage = async sURL => {
//     // No funciona en Firefox 73.0 for Ubuntu
//     var oHiddFrame = document.createElement("iframe");
//     const printPromise = new Promise((resolve, reject) => {
//         oHiddFrame.onload = ()=>{
//             try {
//                 oHiddFrame.contentWindow.focus(); // Required for IE
//                 /**
//                  * El siguiente comando está comentado, ya que por defecto se imprime con el `autoPrint` de jsPDF
//                  * setTimeout(()=>oHiddFrame.contentWindow.print(),2000)
//                  */
//                 resolve();
//             } catch (error) {
//                 reject(error)
//             }
//         }
//     })
//     oHiddFrame.style.position = "fixed";
//     oHiddFrame.style.right = "0";
//     oHiddFrame.style.bottom = "0";
//     oHiddFrame.style.visibility = "hidden";
//     oHiddFrame.style.width = "100%";
//     oHiddFrame.style.height = "100%";
//     oHiddFrame.style.border = "0";
//     oHiddFrame.src = sURL;
//     document.body.appendChild(oHiddFrame);
//     await printPromise; // Esperar a que se cargue el diálogo de impresión
//     setTimeout( ()=>document.body.removeChild(oHiddFrame), 60000) // Eliminar `iframe` luego de 1m, para liberar RAM
// }

// const printInNewTab = sURL => {

//     // const htmlForNewWindow =
//     // "<html>" +
//     //     "<head>" +
//     //         "<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>" +
//     //     "</head>" +
//     //     '<body>' +
//     //         `<iframe src="${sUrl}"/>` +
//     //     "</body>" +
//     // "</html>";
//     const newWindow = window.open();
//     if (newWindow !== null) {
//         var oHiddFrame = document.createElement("iframe");
//         newWindow.document.body.style.padding = "0";
//         newWindow.document.body.style.margin = "0";
//         oHiddFrame.style.width = "100%";
//         oHiddFrame.style.height = "100%";
//         oHiddFrame.style.border = "0";
//         oHiddFrame.src = sURL;
//         newWindow.document.body.appendChild(oHiddFrame);
//     // newWindow.document.write(htmlForNewWindow);
//         /**
//          * El código en este comentario es como realmente deberia funcionar la impresión.
//          *
//          * Al abrirse la pestaña, automaticamente se abre el diálogo de impresion.
//          * Cuando el usuario manda a imprimir, automaticamente se cierra la pestaña.
//          *
//          * El problema es que este código es inestable,
//          * si el documento se tarda mas de 1 segundo en cargar, el documento se imprimirá vacío,
//          * porque se abre el diaĺogo de impresión antes de que haya cargado el documento.
//          *
//          * No logré hacer que el padacito de código corriera cuando el `iframe` terminara de cargarse.
//          *
//          * -------------------------------------------------------------------------------------------
//          * El que actualmente abre el diálogo de impresion es el `autoPrint()` de **jsPDF**.
//          * -------------------------------------------------------------------------------------------
//          *
//          * ```js
//          *     newWindow.onload = function() {
//          *         setTimeout(()=>{
//          *             newWindow.print()
//          *             newWindow.close()
//          *         }, 1000)
//          *     }
//          *     newWindow.document.close(); // Si no ejecuto esto, no se ejecuta el `onload` --_(:/)_--
//          * ```
//          */
//     }
// }

// const print = async (sUrl, sUrlNewWindow = sUrl) => {
//     // Intentar imprimir desde la misma página
//     await printInPage(sUrl)
//       .catch ( error => {
//         console.warn(error)
//         console.log("No se pudo imprimir desde la misma página, se intentará imprimir en una pestaña nueva")
//         // Imprimir abriendo una pestaña
//         printInNewTab(sUrlNewWindow);
//       })
// }

/**************************************************************************************
 * Las funciones de arriba pueden llegar a ser útiles para imprimir en general.
 *
 * Al final encontré la manera de que funcionara bien con la funcion que está debajo
 * pero depende del `autoPrint()` de **jsPDF**, el cual hace que el PDF generado
 * ya venga con la instruccion de imprimir al cargar (https://rawgit.com/MrRio/jsPDF/master/docs/src_modules_autoprint.js.html#line44)
 *
 **************************************************************************************/


/**
 * Carga el Url en un nuevo `<iframe/>` y se inserta al final del body
 *
 * Esta función no imprime por sí sola, depende de que se active `autoPrint()` en el **jsPDF**
 * antes de exportarlo como `bloburl`.
 *
 * @param {URL} sURL - lo que resulta de `doc.output("bloburl")` (**jsPDF**)
 */
const printBlob = sURL => {
    const oHiddFrame = document.createElement("iframe");
    oHiddFrame.style.position = "fixed";
    oHiddFrame.style.visibility = "hidden";
    oHiddFrame.src = sURL;
    document.body.appendChild(oHiddFrame);
    setTimeout(() => document.body.removeChild(oHiddFrame), 60000) // Eliminar `iframe` luego de 1m, para liberar RAM
}

export default printBlob;

import React from 'react';
import PageLoading from '../PageLoading';
import ClientesLista from './ClientesLista';
import {Link} from 'react-router-dom';
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter';
import Modal from './ClienteModal';
// import Modal from '../Modal';
import ClienteEditar from './ClientesEditar';
import ClienteNuevo from './ClientesNuevo';
import {BotonEditar, BotonAdd} from '../Plantillas/Botones';
import {defaultPostHeaders, pfetch} from "../../helpers/wrappers";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";

class ClientesListaContainer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            buscar: "",
            modalIsOpen: false,

            loading: true,
            error: null,
            data: [],
            itemcliente: {
                idcliente: null,
                nomcliente: '',
            },
            isLoading: false,
        }
        this.onDeleteClient = this.onDeleteClient.bind(this)
    }

    componentDidMount() {
        // console.log("cambio");
        this.listaClientes();
        // this.intervalId = setInterval(this.listaClientes, 5000);
    }

    componentWillUnmount() {
        // clearInterval(this.intervalId);
    }

    listaClientes = async () => {
        this.setState({loading: true, error: null});
        try {
            //debugger;
            // const response = await fetch(`/api/clientes/${this.state.buscar}`);
            const response = await fetch(`/api/clientes/emp/`, []);
            const data = await response.json();
            //console.log(data);
            this.setState({
                loading: false,
                data: data,
            });
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    handleInput = (e) => {
        this.setState({
            buscar: e.target.value,
            // [e.target.name]: e.target.value,
        });
    }

    handleOpenModal = (reg) => {
        console.log("Nombre Cliente: ", reg.NombreCliente);
        this.setState({
            modalIsOpen: true,
            itemcliente: {
                ...this.state.itemcliente,
                nomcliente: reg.NombreCliente,
                idcliente: reg.IdCliente,
            }
        })
    }

    onDeleteClient(c) {
        pfetch({
            fetch: {
                url: "/api/clientes/enable-disable",
                method: "POST",
                headers: defaultPostHeaders(),
                body: JSON.stringify({status: c.Estado === 'Activo', id: c.IdCliente})
            },
            before: () => this.setState({isLoading: true}),
            then: () => notificarMsg("Se ha realizado con éxito"),
            catch: () => notificarError("Ha ocurrido un error al momento de realizar la acción"),
            finally: () => {
                this.setState({isLoading: false})
                this.listaClientes()
            }
        })
    }

    handleCloseModal = (e) => {
        this.setState({modalIsOpen: false})
    }

    handleClickNuevo = (e) => {
        this.setState({
            modalIsOpen: true,
            swPrincipal: 1,
            itemcliente: {
                ...this.state.itemcliente,
                idcliente: null,
            }
        })

    }

    render() {

        /* SI HUBO ERROR AL CARGAR LA DATA MOSTRAMOS EL ERROR */
        /* ================================================== */
        if (this.state.error) {
            return `Error: ${this.state.error.message}`;
        }

        /* MOSTRAMOS UN PRE-LOAD AL CARGAAR LA DATA */
        /* ================================================== */
        if (this.state.loading === true && this.state.data[1] === undefined) {
            return <PageLoading/>;
        }

        /* SI LA DATA AUN ESTA VACIA */
        /* ================================================== */
        if (this.state.data[1].length === 0 || this.state.data[1] === undefined) {
            return (
                <div className="card text-center">
                    <div className="card-header bg-dark text-white">Lista de Clientes</div>
                    <div className="body p-4">
                        <p className="card-text">¡Aún no has creado tu primer cliente...!</p>
                        <button className="btn btn-primary" onClick={this.handleClickNuevo}>+ Nuevo Cliente</button>
                    </div>
                    <div className="card-footer text-muted">
                        <p className="card-text">Notas de Ayuda...</p>
                    </div>
                    <Modal isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal}
                           title="NUEVO CLIENTE">
                        <ClienteNuevo onClose={this.handleCloseModal} listaClientes={this.listaClientes} sw="1"/>
                    </Modal>
                </div>
            );
        } else {

            return (
                <div className="m-5">
                    {/* <div className="card-header bg-dark text-white text-center">Lista de Clientes</div> */}
                    {/* <div className="card-body m-1"> */}
                    <div align="right" className="mr-4 mb-2">
                        {/* <Link className="btn btn-primary " to= "/nuevocliente">+ Nuevo Cliente</Link> */}
                        <BotonAdd
                            title="Nuevo Cliente"
                            type="button"
                            onClick={this.handleClickNuevo}>
                        </BotonAdd>
                        {/* <button
                                className="btn btn-outline-primary"
                                title="Nuevo Cliente"
                                onClick={this.handleClickNuevo}>
                                    <i className="fa fa-user-plus"></i>
                                </button> */}
                    </div>
                    <div>
                        <ReactTable
                            //  width='100%'
                            data={this.state.data[1]}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                            columns={[
                                {
                                    columns: [
                                        {
                                            Header: "Acciones",
                                            accessor: "IdCliente",
                                            // headerClassName:'card-header bg-dark text-white',
                                            width: 200,
                                            filterable: false,
                                            Cell: (f) => {
                                                if (this.state.isLoading)
                                                    return "Cargando"
                                                return (
                                                    <div align="center">
                                                        <BotonEditar
                                                            id={f.value}
                                                            type="button"
                                                            value={f.original.NombreCliente}
                                                            hidden={f.original.Estado == 'Atendida' || f.original.Estado == 'Parcial' || f.original.Estado == 'Anulada' ? true : false}
                                                            onClick={
                                                                () => {
                                                                    this.handleOpenModal(f.original)
                                                                }
                                                            }
                                                            title="Editar"
                                                        />


                                                        <button onClick={() => this.onDeleteClient(f.original)}
                                                                className={`ml-1 btn ${f.original.Estado === 'Activo' ? 'btn-outline-danger' : 'btn-outline-success'}`}>
                                                            {f.original.Estado === 'Activo' ? 'Desactivar' : 'Activar'}
                                                        </button>
                                                    </div>
                                                );
                                            },
                                        },

                                        {
                                            Header: "Código",
                                            id: "NroTipoDocumento",
                                            // accessor: "NroTipoDocumento",
                                            width: '150',
                                            // headerClassName:'card-header bg-dark text-white',
                                            accessor: d => d.NroTipoDocumento,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["NroTipoDocumento"]}),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Inactivo' ? '#FEEBEB' : null,
                                                    },
                                                };
                                            },
                                        },

                                        {
                                            Header: "Cliente",
                                            id: "NombreCliente",
                                            // accessor: "NombreCliente",
                                            // headerClassName:'card-header bg-dark text-white',
                                            width: '700',
                                            accessor: d => d.NombreCliente,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["NombreCliente"]}),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Inactivo' ? '#FEEBEB' : null,
                                                    },
                                                };
                                            },
                                        },

                                        {
                                            Header: "Dirección",
                                            id: "Direccion",
                                            width: '600',
                                            // headerClassName:'card-header bg-dark text-white',
                                            accessor: d => d.Direccion,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["Direccion"]}),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Inactivo' ? '#FEEBEB' : null,
                                                    },
                                                };
                                            }
                                        },
                                        {
                                            Header: "Estado",
                                            id: "Estado",
                                            accessor: "Estado",
                                            // headerClassName:'card-header bg-dark text-white',
                                            width: '150',
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Inactivo' ? '#FEEBEB' : null,
                                                    },
                                                };
                                            },
                                            filterMethod: (filter, row) => {
                                                if (filter.value === 'Todos') {
                                                    return true;
                                                }
                                                if (filter.value === "Activos") {
                                                    return row[filter.id] === 'Activo';
                                                }
                                                if (filter.value === "Inactivos") {
                                                    return row[filter.id] === 'Inactivo';
                                                }
                                            },
                                            Filter: ({filter, onChange}) =>
                                                <select
                                                    onChange={event => onChange(event.target.value)}
                                                    style={{width: "100%"}}
                                                    value={filter ? filter.value : "Todos"}
                                                >
                                                    <option value="Todos">Todos</option>
                                                    <option value="Activos">Activos</option>
                                                    <option value="Inactivos">Inactivos</option>
                                                </select>

                                            // filterAll: true
                                        },

                                    ]
                                },
                            ]}
                            defaultPageSize={10}
                            //pageSize={10}
                            className="-striped -highlight"
                            // Text de paginación
                            previousText='Anterior'
                            nextText='Siguiente'
                            loadingText='Cargando...'
                            noDataText='No se encontraron registros'
                            pageText='Página'
                            ofText='de'
                            rowsText='filas'
                            style={{
                                width: "100%",
                                fontSize: "15px"
                            }}
                            pageSizeOptions={[5, 10, 20, 25, 50, 100]}

                        />
                        {
                            this.state.modalIsOpen && this.state.itemcliente.idcliente ?
                                <Modal isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal}
                                       title={"MODIFICAR CLIENTE: " + this.state.itemcliente.nomcliente}>
                                    <ClienteEditar idcliente={this.state.itemcliente.idcliente}
                                                   onClose={this.handleCloseModal}
                                                   listaClientes={this.listaClientes}/>
                                </Modal>
                                :
                                <Modal isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal}
                                       title="NUEVO CLIENTE">
                                    <ClienteNuevo onClose={this.handleCloseModal} listaClientes={this.listaClientes}
                                                  sw="1"/>
                                </Modal>

                        }
                    </div>


                </div>
            );
        }

    }
}

export default ClientesListaContainer;

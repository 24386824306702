import React from "react";
import {defaultGetHeaders, pfetch} from "../../helpers/wrappers";
import {notificarError} from "../Almacenes/AlmacenNotify";

export default class SucursalPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            sucursales: [],
            disabled: true,
        }
        this.onSelectSucursal = this.onSelectSucursal.bind(this);
        this.fetchSucursales = this.fetchSucursales.bind(this);
    }

    componentDidMount() {
        if (this.props.checkPermission)
            this.checkPermissionList()
        else
            this.setState({disabled: false})
        this.fetchSucursales();
    }

    checkPermissionList() {
        pfetch({
            fetch: {
                url: "/api/configFactElectronica/ChangeSucursal",
                method: "GET",
                headers: defaultGetHeaders()
            },
            before: () => this.setState({disabled: true}),
            then: () => this.setState({disabled: false}),
            forbidden: () => this.setState({disabled: true}),
            error: () => notificarError("Ha ocurrido un error al momento de listar el permiso"),
            finally: () => null
        })
    }

    fetchSucursales() {
        this.setState({isLoading: true});
        fetch('/api/sucursales/SucursalEmpresa')
            .then(res => res.json())
            .then(data => {
                this.setState(
                    {sucursales: data},
                    () => {
                        if (Boolean(this.props.selectAll))
                            this.onSelectSucursal({target: document.getElementById("SelectSucursales")})
                    }
                )
            })
            .finally(() => this.setState({isLoading: false}))
    }

    onSelectSucursal(e) {
        const element = e.target
        if (Boolean(this.props.multiple)) {
            const selectedOpts = []
            for (let i = 0; i < element.options.length; i++) {
                if (element.options[i].selected)
                    selectedOpts.push(element.options[i].value)
            }
            this.props.onChangeSucursal(selectedOpts)
        } else {
            this.props.onChangeSucursal(element.value)
        }
    }

    render() {
        const showDefaultOpt = this.props.showDefaultOption === undefined ? true : Boolean(this.props.showDefaultOption)
        const selectAll = Boolean(this.props.selectAll)
        if (this.state.disabled)
            return "Sucursal: Sucursal del usuario en sesión"
        if (this.props.colStyle)
            return (
                <>
                    <div className="col-sm-auto">
                        {Boolean(this.props.multiple) ? 'Seleccione las sucursales' : 'Seleccione la sucursal'}
                    </div>
                    <div className="col-sm">
                        <select className="form-control"
                                id={"SelectSucursales"} multiple={Boolean(this.props.multiple)}
                                disabled={this.state.isLoading}
                                onChange={this.onSelectSucursal}>
                            {showDefaultOpt ? <option key={`opt_none`} value="">Seleccionar</option> : null}
                            {this.state.sucursales.map(s => <option selected={selectAll} key={`opt_${s.IdSucursal}`}
                                                                    value={s.IdSucursal}>{s.Nombre}</option>
                            )}
                        </select>
                    </div>
                </>
            )
        return (
            <div className="form-group">
                <label>{Boolean(this.props.multiple) ? 'Seleccione las sucursales' : 'Seleccione la sucursal'}</label>
                <select className="form-control"
                        id={"SelectSucursales"} multiple={Boolean(this.props.multiple)}
                        disabled={this.state.isLoading}
                        onChange={this.onSelectSucursal}>
                    {showDefaultOpt ? <option key={`opt_none`} value="">Seleccionar</option> : null}
                    {this.state.sucursales.map(s => <option selected={selectAll} key={`opt_${s.IdSucursal}`}
                                                            value={s.IdSucursal}>{s.Nombre}</option>
                    )}
                </select>
            </div>
        );
    }
}

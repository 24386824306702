import React from 'react'
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image

} from "@react-pdf/renderer";
import defaultTemplate from "../../NotasCD/pdf";
import {decimalAdjust} from "../../../Global";

const Estilos = StyleSheet.create({
    page: {
        fontSize: 10,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 30,
        paddingRight: 30,
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 5,
        borderColor: "#bfbfbf",
        borderWidth: 1,
        height: "10%"

    },
    emp_logo: {
        display: "flex",
        flexDirection: "column",
        borderColor: "#bfbfbf",
        borderWidth: 0,
        width: "20%",
        padding: 5,
        // alignItems:"center"
    },
    emp_info: {
        display: "flex",
        width: "79%",
        padding: 10,
        flexDirection: "col",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        textAlign: "center",
    },
    emp_info_title: {
        fontSize: "14",
        fontWeight: "800",
        borderWidth: 0,
        margin: 5
    },
    emp_info_detalle: {
        fontSize: "7",
        margin: 1
        // fontWeight:"800",
    },
    body: {
        padding: 5,
        borderColor: "#bfbfbf",
        borderWidth: 0,
        marginTop: 5,
        height: "70%"
    },
    body_prov: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderColor: "#bfbfbf",
        // width:"100%"
    },
    body_prov_info: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 3,
        width: "80%",
        borderWidth: 1,
        borderColor: "#bfbfbf",
    },
    body_prov_info_c1: {
        display: "flex",
        flexDirection: "column",
        width: "30%",
    },
    body_prov_info_c2: {
        display: "flex",
        flexDirection: "column",
        width: "70%",
    },
    body_prov_info_c3: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    body_prov_oc: {
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        padding: 3,
        width: "20%",
        borderWidth: 1,
        borderColor: "#bfbfbf",
    },
    cell_etiqueta: {
        fontSize: 9,
        marginBottom: 2,
        fontWeight: "900",
        color: "#a8a9ad",
    },
    cell: {
        marginBottom: 2,
        color: "#58595d",
        fontSize: 9
    },

    body_oc: {
        // display: "flex",
        // flexDirection: "row",
        justifyContent: "space-between",
        borderColor: "#bfbfbf",
        // width:"100%"
    },
    body_oc_info: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 3,
        width: "100%",
        borderWidth: 1,
        borderColor: "#bfbfbf",
        marginTop: 5
    },
    body_oc_info_c1: {
        width: "25%",
    },
    body_oc_info_c2: {
        width: "75%",
    },
    body_oc_info_c3: {
        width: "100%",
    },

    body_detalle: {
        justifyContent: "space-between",
        borderColor: "#bfbfbf",
        borderWidth: 0,
        marginTop: 5
    },


    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,

    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableColHeader: {
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: "#f0f0f0"
    },
    tableCol: {
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCellHeader: {
        margin: "auto",
        margin: 5,
        color: "#58595d",
        // fontSize: 12,
        fontWeight: 500,
    },
    tableCell: {
        margin: "auto",
        margin: 5,
        color: "#58595d",
        fontSize: 9,
    },


    footer: {
        display: "flex",
        flexDirection: "column",
        padding: 5,
        borderColor: "#bfbfbf",
        borderWidth: 1,
        marginTop: 5,
        height: "20%"

    },

    footer_seccion_1: {
        display: "flex",
        flexDirection: "column",

    },
    footer_seccion_2: {
        display: "flex",
        flexDirection: "row",
        // alignContent:"space-between",
        justifyContent: "space-between",
        // alignItems: "center",
        // border: 1,
        // borderBottom: 1,
        padding: 5
    },
    footer_seccion_2_seccion: {
        textAlign: "center",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        fontSize: 8,
        width: "32%",
        height: 90,
        padding: 3
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },

});


function RptNC(props) {
    const note = JSON.parse(props.cabnc.payload)
    const moneda = props.cabnc.SimboloMoneda;
    const logoUrl = `${process.env.REACT_APP_FACT_BASE_URL}/datos/${props.cabnc.RucEmpresa}/Img/logo_${props.cabnc.RucEmpresa}.${props.extImg}`;
    const relDocs = JSON.parse(props.cabnc.relDocs)
    const tipo = props.cabnc.Tipo === "ND" ? "Nota de Débito" : "Nota de Crédito"

    if (Object.keys(note).length)
        return (
            <Document>
                <Page style={Estilos.page}>
                    <View style={Estilos.header}>
                        <View style={Estilos.emp_logo}>
                            <Image
                                source={logoUrl}/>
                        </View>
                        <View style={Estilos.emp_info}>
                            <Text
                                style={Estilos.emp_info_title}>{`${tipo} N° ${props.cabnc.SerieCD}-${props.cabnc.NumeroCD}`}</Text>
                            <Text
                                style={Estilos.emp_info_detalle}>{`Principal:  ${props.cabnc.NombreEmpresa} :: ${props.cabnc.DireccionEmpresa}`}</Text>
                            <Text
                                style={Estilos.emp_info_detalle}>{`Sucursal:  ${props.cabnc.NombreSucursal} :: ${props.cabnc.DireccionSucursal}`}</Text>
                        </View>
                    </View>
                    <View style={Estilos.body}>
                        <View style={Estilos.body_prov}>
                            <View style={Estilos.body_prov_info}>
                                <View style={Estilos.body_prov_info_c1}>
                                    <Text style={Estilos.cell_etiqueta}>Fecha de Emisión:</Text>
                                    <Text style={Estilos.cell}>{props.cabnc.FechaEmision}</Text>
                                    <Text style={Estilos.cell_etiqueta}>Tipo {tipo}:</Text>
                                    <Text style={Estilos.cell}>{props.cabnc.DescripcionTipoNCD}</Text>
                                </View>
                                <View style={Estilos.body_prov_info_c2}>
                                    <Text style={Estilos.cell_etiqueta}>Motivo o Sustento:</Text>
                                    <Text style={Estilos.cell}>{props.cabnc.Descripcion || '-'}</Text>
                                </View>
                            </View>
                            <View style={Estilos.body_prov_oc}>
                                <Text style={[Estilos.cell, {alignContent: 'center', fontSize: 11}]}>{`RUC`}</Text>
                                <Text style={[Estilos.cell, {
                                    alignContent: 'center',
                                    fontSize: 11
                                }]}>{`${props.cabnc.RucEmpresa}`}</Text>
                                <Text style={[Estilos.cell, {alignContent: 'center'}]}>{`${tipo}:`}</Text>
                                <Text
                                    style={[Estilos.cell, {alignContent: 'center'}]}>{`${props.cabnc.SerieCD}-${props.cabnc.NumeroCD}`}</Text>
                            </View>
                        </View>
                        <View style={{marginTop: 5}}><Text>Documento que modifica:</Text></View>
                        <View style={Estilos.body_oc}>
                            <View style={Estilos.body_oc_info}>
                                <View style={Estilos.body_oc_info_c1}>
                                    <Text style={Estilos.cell_etiqueta}>Nro Comprobante Electrónico:</Text>
                                    <Text
                                        style={Estilos.cell}>{`${props.cabnc.SerieCE}-${Number(props.cabnc.NumeroCE)} ${relDocs.length ? ` con ${relDocs[0].numDoc}` : ""}`}</Text>
                                    <Text style={Estilos.cell_etiqueta}>Fecha Emisión:</Text>
                                    <Text style={Estilos.cell}>{`${props.cabnc.FechaEmisionRV}`}</Text>
                                </View>
                                <View style={Estilos.body_oc_info_c2}>
                                    <Text style={Estilos.cell_etiqueta}>RUC / DNI:</Text>
                                    <Text style={Estilos.cell}>{props.cabnc.RucCliente}</Text>
                                    <Text style={Estilos.cell_etiqueta}>Señor(es):</Text>
                                    <Text style={Estilos.cell}>{props.cabnc.NombreCliente}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={{marginTop: 5}}><Text>Detalle de la {tipo}</Text></View>
                        <View style={Estilos.body_detalle}>
                            <View style={Estilos.table}>
                                <View style={Estilos.tableRow}>
                                    <View style={[Estilos.tableColHeader, {width: "6%", textAlign: "center"}]}>
                                        <Text style={Estilos.tableCellHeader}>Item</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "center"}]}>
                                        <Text style={Estilos.tableCellHeader}>Cantidad</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "62%", textAlign: "left"}]}>
                                        <Text style={Estilos.tableCellHeader}>Descripción</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "right"}]}>
                                        <Text style={Estilos.tableCellHeader}>{`Precio ${moneda}`}</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                        <Text style={Estilos.tableCellHeader}>{`Total  ${moneda}`}</Text>
                                    </View>
                                </View>
                                {
                                    note.detVentas.map((item, i) => {
                                        return (
                                            <View key={i} style={Estilos.tableRow}>
                                                <View style={[Estilos.tableCol, {width: "6%"}]}>
                                                    <Text
                                                        style={[Estilos.tableCell, {textAlign: "center"}]}>{i + 1}</Text>
                                                </View>
                                                <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                    <Text
                                                        style={[Estilos.tableCell, {textAlign: "center"}]}>{decimalAdjust('floor', item.cantidad, -2)}</Text>
                                                </View>
                                                <View style={[Estilos.tableCol, {width: "62%"}]}>
                                                    <Text
                                                        style={[Estilos.tableCell, {textAlign: "left"}]}>{item.descripcion}</Text>
                                                </View>
                                                <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                    <Text
                                                        style={[Estilos.tableCell, {textAlign: "right"}]}>{decimalAdjust('floor', item.montoPrecioUnitario, -2)}</Text>
                                                </View>
                                                <View style={[Estilos.tableCol, {width: "12%"}]}>
                                                    <Text
                                                        style={[Estilos.tableCell, {textAlign: "right"}]}>{decimalAdjust('floor', item.montoValorVenta, -2)}</Text>
                                                </View>
                                            </View>
                                        )
                                    })}
                                {Number(note.nota.totalOpGravadas) > 0 &&
                                <View style={Estilos.tableRow}>
                                    <View style={[Estilos.tableColHeader, {
                                        width: "88%",
                                        textAlign: "right",
                                        borderBottom: 0
                                    }]}>
                                        <Text style={Estilos.tableCellHeader}>{`Gravado ${moneda}`}</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                        <Text
                                            style={Estilos.tableCellHeader}>{decimalAdjust('floor', note.nota.totalOpGravadas, -2)}</Text>
                                    </View>
                                </View>
                                }

                                {Number(note.nota.totalOpExoneradas) > 0 &&
                                <View style={Estilos.tableRow}>
                                    <View style={[Estilos.tableColHeader, {
                                        width: "88%",
                                        textAlign: "right",
                                        borderBottom: 0
                                    }]}>
                                        <Text style={Estilos.tableCellHeader}>{`Exonerado ${moneda}`}</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                        <Text
                                            style={Estilos.tableCellHeader}>{decimalAdjust('floor', note.nota.totalOpExoneradas, -2)}</Text>
                                    </View>
                                </View>
                                }

                                {Number(note.nota.totalGratuitas) > 0 &&
                                <View style={Estilos.tableRow}>
                                    <View style={[Estilos.tableColHeader, {
                                        width: "88%",
                                        textAlign: "right",
                                        borderBottom: 0
                                    }]}>
                                        <Text style={Estilos.tableCellHeader}>{`Gratuito ${moneda}`}</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                        <Text
                                            style={Estilos.tableCellHeader}>{decimalAdjust('floor', note.nota.totalGratuitas, -2)}</Text>
                                    </View>
                                </View>
                                }

                                {Number(note.nota.totalInafectas) > 0 &&
                                <View style={Estilos.tableRow}>
                                    <View style={[Estilos.tableColHeader, {
                                        width: "88%",
                                        textAlign: "right",
                                        borderBottom: 0
                                    }]}>
                                        <Text style={Estilos.tableCellHeader}>{`Inafecto ${moneda}`}</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                        <Text
                                            style={Estilos.tableCellHeader}>{decimalAdjust('floor', note.nota.totalInafectas, -2)}</Text>
                                    </View>
                                </View>
                                }

                                {Number(note.nota.mtoIgv) > 0 &&
                                <View style={Estilos.tableRow}>
                                    <View style={[Estilos.tableColHeader, {
                                        width: "88%",
                                        textAlign: "right",
                                        borderBottom: 0
                                    }]}>
                                        <Text style={Estilos.tableCellHeader}>{`IGV ${moneda}`}</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                        <Text
                                            style={Estilos.tableCellHeader}>{decimalAdjust('floor', note.nota.mtoIgv, -2)}</Text>
                                    </View>
                                </View>
                                }

                                {Number(note.nota.mtoIcbper) > 0 &&
                                <View style={Estilos.tableRow}>
                                    <View style={[Estilos.tableColHeader, {
                                        width: "88%",
                                        textAlign: "right",
                                        borderBottom: 0
                                    }]}>
                                        <Text style={Estilos.tableCellHeader}>{`ICBPER ${moneda}`}</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                        <Text
                                            style={Estilos.tableCellHeader}>{decimalAdjust('floor', note.nota.mtoIcbper, -2)}</Text>
                                    </View>
                                </View>
                                }

                                {Number(note.nota.montoImporteVenta) > 0 &&
                                <View style={Estilos.tableRow}>
                                    <View style={[Estilos.tableColHeader, {width: "88%", textAlign: "right"}]}>
                                        <Text style={Estilos.tableCellHeader}>{`Total ${moneda}`}</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                        <Text
                                            style={Estilos.tableCellHeader}>{decimalAdjust('floor', note.nota.montoImporteVenta, -2)}</Text>
                                    </View>
                                </View>
                                }

                            </View>
                        </View>
                    </View>

                    <Text style={Estilos.pageNumber} render={({pageNumber, totalPages}) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed/>
                </Page>

            </Document>

        )
    return defaultTemplate(Estilos, props, {logoUrl, relDocs})
}

export default RptNC;

import React from 'react';
// import macaddress from 'getmac';
import PuntoVentaForm from './PuntoVentaForm'
import swal from 'sweetalert';


class PuntoVentaNuevo extends React.Component{
    constructor(){
        super()
        this.state={
            loading:false, 
            error:null,
            form:{
                IdSucursal:1,
                Nombre:'',
                MAC:'',
                Funcion:'Punto Venta',
                Estado:'Activo',
            }

        }
    }

    componentDidMount() {
        // this.ObtenerMacAddress();
        //this.TraerEmpresas();
        // this.TraerSucursales();
    }

    

/* ******************************************************************
        FUNCION: CREA UN NUEVO PUNTO DE VENTA
* ******************************************************************/
    crearPuntoVenta = async ()=>{ 
        this.setState({loading:true, error:null});
        try{
            const config = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IdSucursal: this.props.idSucursal, 
                    Nombre: this.state.form.Nombre, 
                    MAC: this.state.form.MAC, 
                    Funcion: this.state.form.Funcion, 
                    Estado: this.state.form.Estado, 
                })
            };
            // console.log(config);
            const response = await fetch(`/api/puntosVenta`, config) ;
            const rpta = await response.json();
            this.setState({
                loading: false,
            });

            // CERRAR EL MODAL Y ACTUALIZAR LISTA
            this.props.onClose();
            this.props.listarPuntosVenta(this.props.idSucursal)
            swal("El item fue Creado con Éxito!", {
                icon: "success",
            });
            
        }catch (error){
            this.setState({
                loading: false,
                error: error,
            });
        }
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        // this.ObtenerMacAddress();
        this.crearPuntoVenta();
    };
    
    /* ******************************************************************
        FUNCION: CONTROLA EVENTOS CHANGE
    * ******************************************************************/
     handleChange = (e)=>{
        this.setState({
            form:{
                ... this.state.form,
                [e.target.name]: e.target.value,

            },
        });
    }   

    handleClickMAC = e =>{
        e.preventDefault();
          swal('Aqui va la mac');
        
    
    }
    
    render(){
        return(
            <PuntoVentaForm 
                handleChange={this.handleChange}
                onClose={this.props.onClose}
                handleSubmit={this.handleSubmit}
                onClickMAC={this.handleClickMAC}
                form={this.state.form}
                accion='Crear Punto Venta'
            />
        )
    }
}

export default PuntoVentaNuevo;
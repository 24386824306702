import React from 'react'
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter';
import { BotonAddPequeño } from '../Plantillas/Botones';


class ProveedoresListaSearch extends React.Component {
    
    constructor(){
        super()
        this.state={
            loading: true,
            error: null,

            rowEdit: null,
            selectedRowIndex: [],
            selectionChanged: false,

            dataLista: undefined
        }
    }

    componentDidMount(){
        this.inicio()
    }

    inicio = async () =>{
        await this.listar()
    }

    listar = async () => {
        try {
            this.setState({ loading: true, error: null });

            const response = await fetch(`/api/proveedores/list/`);
            const data = await response.json();

            this.setState({
                dataLista: data[1]
            })

        }catch(error){
            this.setState({ loading: false, error: error });
        }
    }

    ClickSeleccionar = (reg) => {
        this.props.form.IdProveedor = reg.IdProveedor;
        this.props.form.Ruc = reg.Ruc;
        this.props.form.NomProveedor = reg.RazonSocial;
        this.props.form.NomContacto = reg.Contacto;
        this.props.onClose();
    }

    render(){
        if(this.state.error){
            return `Error: ${this.state.error.message}`;
        }

        if(this.state.loading === true && this.state.dataLista === undefined){
            return <p>cargando...</p>;
        }
        
        return (
                <div>
                    <div className="form-row">
                        <div className="col">
                            <ReactTable
                                //  width='100%'   
                                data={this.state.dataLista}
                                filterable
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value}
                                columns={[
                                    {
                                    columns: [
                                        {
                                            Header: "Ruc",
                                            id:"Ruc",
                                            // accessor: "NombreCliente",
                                            width:'100',
                                            accessor: d => d.Ruc,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, { keys: ["Ruc"] }),
                                            filterAll: true,
                                        },
                                        {
                                            Header: "Proveedor",
                                            id:"RazonSocial",
                                            // accessor: "NroTipoDocumento",
                                            width:'320',
                                            accessor: d => d.RazonSocial,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, { keys: ["RazonSocial"] }),
                                            filterAll: true,
                                            // getProps: (state, rowInfo, column) => {
                                            //     return {
                                            //         style: {
                                            //             background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                            //             textAlign: "center"
                                            //         },
                                            //     };
                                            // },
                                        },
                                            
                                        {
                                            Header: "Acciones",
                                            accessor: "IdMovimientoAlmacen",
                                            // headerClassName:'card-header bg-dark text-white',
                                            width:'50',
                                            filterable: false,
                                            Cell: (f) => {
                                                if(!f){
                                                    return null
                                                }
                                                return(
                                                    <div align='rigth'>
                                                            <BotonAddPequeño 
                                                                className="mr-2"
                                                                id={f.value} 
                                                                onClick={
                                                                    ()=>{
                                                                        this.ClickSeleccionar(f.original)
                                                                    }
                                                                } 
                                                                title="Seleccionar" 
                                                            />
                                                    </div>
                                                )                                            
                                            },
                                        },
                                    ]
                                    },
                                ]}
                                
                                defaultPageSize={10}
                                showPagination={false}
                                className="-striped -highlight"
                                style={{
                                    width: "500px",
                                    height: "250px",
                                    fontSize: "12px"
                                }}    
                                
                                
                                
                            />
                        </div>

                    </div>
                    
                </div>
            )
    }
    
}

export default ProveedoresListaSearch
import React from 'react';
import '../styles/Empleados.css';
import PageLoading from "../PageLoading.js";
import EmpleadosList from './EmpleadosList'
import { BotonAdd, BotonCancelar } from '../Plantillas/Botones';

class EmpleadosMaster extends React.Component{
    constructor(){
        super()
        this.state={ 
            buscarEstado:'activo',         
            buscar:"",
            data:{
                empleados:[]
            },
            buscarEmpresa: "",
            dataEmpresas: [],
            loading:true,
            error:null
        }
        this.getEmpleados=this.getEmpleados.bind(this)
        this.handleChange=this.handleChange.bind(this)
        this.EstadoChange=this.EstadoChange.bind(this)
    }

    componentDidMount(){
        this.getEmpleados();
        this.getEmpresas();
        this.intervalo=setInterval(this.getEmpleados,3000)
    }

    componentWillUnmount(){
        clearInterval(this.intervalo)
    }

    getEmpleados = async () =>{
        this.setState({loading:true, error:null})
        try {
            const response = await fetch('/api/empleados/master');
            const data = await response.json();
            this.setState({
                loading: false,
                data: data
            })
        }catch(error){
            this.setState({loading: false, error: error})
        }
    }

    getEmpresas = async () => {
        this.setState({ loading: true, error: null });
        try {
          const response = await fetch("/api/empresas");
          const data = await response.json();
          const empresas = data.empresas.filter(act => {
            return act.Estado == "Activo";
          });
    
          this.setState({
            loading: false,
            dataEmpresas: empresas
          });
        } catch (error) {
          this.setState({ loading: false, error: error });
        }
    };

    handleChange(e){        
        this.setState({
            buscar: e.target.value
        });
    }

    EstadoChange(estado){
        //console.log(estado)
        this.setState({
            buscarEstado: estado   
        });
    }

    EmpresaChange = e => {
        this.setState({ buscarEmpresa: e.target.value });
    }

    render(){

        if (this.state.data.empleados.length===0) {
            return(
                <React.Fragment>
                    <div className="p-5">
                        <div className="buscadorEmpresa ">
                            <BotonCancelar
                                onClick={this.props.history.goBack}
                                className="mr-2"
                            />
                            <select
                                required
                                className="dropdown-toggle input__linea mb-2"
                                aria-haspopup="true"
                                name="empresa"
                                value={this.state.buscarEmpresa}
                                onChange={this.EmpresaChange}
                            >
                                <option>Buscar Empleados por Empresa</option>
                                {this.state.dataEmpresas.map(empresa => {
                                return (
                                    <option
                                    key={empresa.RazonSocial}
                                    value={empresa.RazonSocial}
                                    >
                                    {empresa.RazonSocial}
                                    </option>
                                );
                                })}
                            </select>
                            <BotonAdd 
                                to="/empleados/addMaster/" 
                                title="Nuevo Empleado"
                                className="ml-2"
                            />
                        </div>   
                            
                        <div className="RadioEstadoEmpl">
                            <div className="custom-control custom-radio mr-2">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    value={this.state.buscarEstado}
                                    name="groupOfDefaultRadios"
                                    id="EmpActivo"
                                    checked={this.state.buscarEstado==="activo"}
                                    onClick={()=>this.EstadoChange("activo")}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor={"EmpActivo"}
                                    style={{fontWeight:"bold"}}
                                >
                                    Activos
                                </label> 
                            </div>
                            
                            <div className="custom-control custom-radio mr-2">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    value={this.state.buscarEstado}
                                    name="groupOfDefaultRadios"
                                    id="EmpInactivo"
                                    checked={this.state.buscarEstado==="inactivo"}
                                    onClick={()=>this.EstadoChange("inactivo")}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor={"EmpInactivo"}
                                    style={{fontWeight:"bold"}}
                                >
                                    Inactivos
                                </label> 
                            </div>

                            <div className="custom-control custom-radio">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    value={this.state.buscarEstado}
                                    name="groupOfDefaultRadios"
                                    id="EmpSuspendido"
                                    checked={this.state.buscarEstado==="suspendido"}
                                    onClick={()=>this.EstadoChange("suspendido")}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor={"EmpSuspendido"}
                                    style={{fontWeight:"bold"}}
                                >
                                    Suspendidos
                                </label> 
                            </div>
                        </div>
                        <div>
                            <PageLoading />
                        </div>
                    </div>
                </React.Fragment>
            )
        }else{
            const filtrarEmpleados = this.state.data.empleados.filter((empleados) => {
                return empleados.Nombres.concat(empleados.Apellidos)
                .toLowerCase().includes(this.state.buscar.toLowerCase()) && 
                empleados.Estado===this.state.buscarEstado 
                && empleados.RazonSocial === this.state.buscarEmpresa
            })
            
            if(this.state.data.error){
                return `Error: ${this.state.error.message}`;
            }

            //no hay coincidencias con la busqueda
            if (filtrarEmpleados.length === 0) {
                return(
                    <React.Fragment>
                        <div className="p-5">
                        
                        <div className="buscadorEmpresa ">
                            <BotonCancelar
                                onClick={this.props.history.goBack}
                                className="mr-2"
                            />
                            <select
                                required
                                className="dropdown-toggle input__linea mb-2"
                                aria-haspopup="true"
                                name="empresa"
                                value={this.state.buscarEmpresa}
                                onChange={this.EmpresaChange}
                            >
                                <option>Buscar Empleados por Empresa</option>
                                {this.state.dataEmpresas.map(empresa => {
                                return (
                                    <option
                                    key={empresa.RazonSocial}
                                    value={empresa.RazonSocial}
                                    >
                                    {empresa.RazonSocial}
                                    </option>
                                );
                                })}
                            </select>
                            <BotonAdd 
                                to="/empleados/addMaster/" 
                                title="Nuevo Empleado"
                                className="ml-2"
                            />
                        </div>   

                        <div className="RadioEstadoEmpl">
                            <div className="custom-control custom-radio mr-2">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    value={this.state.buscarEstado}
                                    name="groupOfDefaultRadios"
                                    id="EmpActivo"
                                    checked={this.state.buscarEstado==="activo"}
                                    onClick={()=>this.EstadoChange("activo")}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor={"EmpActivo"}
                                    style={{fontWeight:"bold"}}
                                >
                                    Activos
                                </label> 
                            </div>
                            
                            <div className="custom-control custom-radio mr-2">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    value={this.state.buscarEstado}
                                    name="groupOfDefaultRadios"
                                    id="EmpInactivo"
                                    checked={this.state.buscarEstado==="inactivo"}
                                    onClick={()=>this.EstadoChange("inactivo")}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor={"EmpInactivo"}
                                    style={{fontWeight:"bold"}}
                                >
                                    Inactivos
                                </label> 
                            </div>

                            <div className="custom-control custom-radio">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    value={this.state.buscarEstado}
                                    name="groupOfDefaultRadios"
                                    id="EmpSuspendido"
                                    checked={this.state.buscarEstado==="suspendido"}
                                    onClick={()=>this.EstadoChange("suspendido")}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor={"EmpSuspendido"}
                                    style={{fontWeight:"bold"}}
                                >
                                    Suspendidos
                                </label> 
                            </div>
                        </div>

                        <div className="mt-2">
                            <h3>No hay Resultados</h3>
                        </div>
                        </div>
                    </React.Fragment>
                )
            }
            return(
            <React.Fragment>
                <div className="p-5">
                    
                    <div className="buscadorEmpresa ">
                        <BotonCancelar
                            onClick={this.props.history.goBack}
                            className="mr-2"
                        />
                        <select
                            required
                            className="dropdown-toggle input__linea mb-2"
                            aria-haspopup="true"
                            name="empresa"
                            value={this.state.buscarEmpresa}
                            onChange={this.EmpresaChange}
                        >
                            <option>Buscar Empleados por Empresa</option>
                            {this.state.dataEmpresas.map(empresa => {
                            return (
                                <option
                                key={empresa.RazonSocial}
                                value={empresa.RazonSocial}
                                >
                                {empresa.RazonSocial}
                                </option>
                            );
                            })}
                        </select>
                        <BotonAdd 
                            to="/empleados/addMaster/" 
                            title="Nuevo Empleado"
                            className="ml-2"
                        />
                    </div>   

                    <div className="RadioEstadoEmpl">
                        <div className="custom-control custom-radio mr-2">
                            <input
                                type="radio"
                                className="custom-control-input"
                                value={this.state.buscarEstado}
                                name="groupOfDefaultRadios"
                                id="EmpActivo"
                                checked={this.state.buscarEstado==="activo"}
                                onClick={()=>this.EstadoChange("activo")}
                            />
                            <label
                                className="custom-control-label"
                                htmlFor={"EmpActivo"}
                                style={{fontWeight:"bold"}}
                            >
                                Activos
                            </label> 
                        </div>
                        
                        <div className="custom-control custom-radio mr-2">
                            <input
                                type="radio"
                                className="custom-control-input"
                                value={this.state.buscarEstado}
                                name="groupOfDefaultRadios"
                                id="EmpInactivo"
                                checked={this.state.buscarEstado==="inactivo"}
                                onClick={()=>this.EstadoChange("inactivo")}
                            />
                            <label
                                className="custom-control-label"
                                htmlFor={"EmpInactivo"}
                                style={{fontWeight:"bold"}}
                            >
                                Inactivos
                            </label> 
                        </div>

                        <div className="custom-control custom-radio">
                            <input
                                type="radio"
                                className="custom-control-input"
                                value={this.state.buscarEstado}
                                name="groupOfDefaultRadios"
                                id="EmpSuspendido"
                                checked={this.state.buscarEstado==="suspendido"}
                                onClick={()=>this.EstadoChange("suspendido")}
                            />
                            <label
                                className="custom-control-label"
                                htmlFor={"EmpSuspendido"}
                                style={{fontWeight:"bold"}}
                            >
                                Suspendidos
                            </label> 
                        </div>
                    </div>
                    

                    <div className="mt-2">
                        {/* ******************************
                        *****TABLA LISTA DE EMPLEADOS*****
                        **********************************/}
                        <EmpleadosList
                            filtrarEmpleados={filtrarEmpleados}
                            link="/empleados/editMaster/"
                        />
                    </div>
                </div>
            </React.Fragment>
            )
        }
    }
}

export default EmpleadosMaster;
import React from "react";
import '../styles/tabla.css';
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter';
import {Link} from 'react-router-dom';
import { BotonEditar, BotonCancelar } from "../Plantillas/Botones";

class TransportistasDeEmpresaList extends React.Component {

    render(){
        return (
                <ReactTable
                // width='100%'  
                data={this.props.transportistasFiltrados}
                filterable
                defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value}
                columns={[
                    {
                    columns: [
                        {
                            Header: "Editar",
                            filterable: false,
                            // headerClassName: "card-header bg-dark text-white",
                            width: 150,
                            Cell: original => {
                              return (
                                    <BotonEditar
                                        to={`${this.props.state.return}` + original.original.IdTransportista}
                                        className="btn btn-outline-primary"
                                        title="Editar"
                                    />
                            );
                        },
                    },
                    {
                        Header: "Editar",
                        filterable: false,
                        // headerClassName: "card-header bg-dark text-white",
                        width: 150,
                        Cell: original => {
                            return (
                                <BotonEditar
                                    to={`${this.props.state.return}` + original.original.IdTransportista}
                                    className="btn btn-outline-primary"
                                    title="Editar"
                                />
                            );
                        },
                    },
                    {
                    Header: "Empleado",
                    id:"Empleado",
                    // accessor: "Empleado",
                    width:300,
                    // headerClassName:'card-header bg-dark text-white',
                    accessor: d => d.Empleado,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["Empleado"] }),
                    filterAll: true
                },
                {
                    Header: "Categoria",
                    id:"Categoria",
                    // accessor: "Categoria",
                    width:120,
                    // headerClassName:'card-header bg-dark text-white',
                    accessor: d => d.Categoria,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["Categoria"] }),
                    filterAll: true
                },
                {
                    Header: "Numero Licencia",
                    id:"NumeroLicencia",
                    // accessor: "NumeroLicencia",
                    width:240,
                    // headerClassName:'card-header bg-dark text-white',
                    accessor: d => d.NumeroLicencia,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["NumeroLicencia"] }),
                    filterAll: true
                },
                {
                    Header: "Estado",
                    id:"Estado",
                    // accessor: "Estado",
                    width:120,
                    // headerClassName:'card-header bg-dark text-white',
                    accessor: d => d.Estado,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["Estado"] }),
                    filterAll: true
                },
                {
                    Header: "DNI",
                    id:"DNI",
                    // accessor: "DNI",
                    width:120,
                    // headerClassName:'card-header bg-dark text-white',
                    accessor: d => d.DNI,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["DNI"] }),
                    filterAll: true
                },
                {
                    Header: "Celular",
                    id:"Celular",
                    // accessor: "Celular",
                    width:150,
                    // headerClassName:'card-header bg-dark text-white',
                    accessor: d => d.Celular,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["Celular"] }),
                    filterAll: true
                },
                
                            ]
                        },
                    ]}
                    defaultPageSize={5}
                    //pageSize={10}
                    className="-striped -highlight"
                    // Text de paginación
                    previousText= 'Anterior'
                    nextText= 'Siguiente'
                    loadingText= 'Cargando...'
                    noDataText= 'No se encontraron registros'
                    pageText= 'Página'
                    ofText= 'de'
                    rowsText= 'filas'                        
                    pageSizeOptions= {[5, 10, 20, 25, 50, 100]}
                                                        
                />
            );
        }
}
export default TransportistasDeEmpresaList;
import React from "react";
import '../styles/Sucursal.css';
import { BotonCancelar, BotonGuardarLetras } from "../Plantillas/Botones";

class SucursalForm extends React.Component {
  render() {
    return (
      <React.Fragment>

        <div className="ml-4 mt-4">
          <BotonCancelar to="/sucursales"/>
        </div>

        <form onSubmit={this.props.onSubmit} className="d-flex justify-content-center">
          <div className="sucursalForm">

            <div className="sucursalBoton">
              {this.props.CambiarEstadoSede?this.props.CambiarEstadoSede:null}
              <BotonGuardarLetras type="submit" className = "ml-2" />
            </div>

            <div className="sucursalDiv">

              <b className="ml-4">*Empresa</b>
              <div className="input__sucursal">
                <select
                  required
                  className="input__linea "
                  aria-haspopup="true"
                  name="empresa"
                  value={this.props.state.IdEmpresa}
                  onChange={this.props.EmpresaChange}
                >
                  <option value="">--Seleccionar Empresa--</option>
                  {this.props.state.dataEmpresas.map(empresa => {
                    return (
                      <option key={empresa.ID} value={empresa.ID}>
                        {empresa.RazonSocial}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
              
            <div className="sucursalDiv">

              <b className="ml-4">Responsable</b>
              <div className="input__sucursal">
                <select
                  className="input__linea "
                  aria-haspopup="true"
                  name="empleado"
                  value={this.props.state.IdEmpleado}
                  onChange={this.props.EmpleadoChange}
                >
                  {this.props.state.empleadosPorEmpresa.map(empleado => {
                    return (
                      <option
                        key={empleado.IdEmpleado}
                        value={empleado.IdEmpleado}
                      >
                        {empleado.Nombres} {empleado.Apellidos}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="sucursalDiv">

              <b className="ml-4">*Ubicación</b>
              <div className="input__sucursal">
                <select
                  required
                  className=""
                  aria-haspopup="true"
                  aria-expanded="false"
                  name="region"
                  value={this.props.state.valueRegion}
                  onChange={this.props.RegionChange}
                >
                  {this.props.state.ubigeoReg.map(obj => {
                    return (
                      <option key={obj.IdUbigeo} value={obj.CodRegion}>
                        {obj.NombreUbigeo}
                      </option>
                    );
                  })}
                </select>

                <select
                  required
                  className=""
                  aria-haspopup="true"
                  aria-expanded="false"
                  name="provincia"
                  value={this.props.state.valueProvincia}
                  onChange={this.props.ProvinciaChange}
                >
                  {this.props.state.ubigeoProv.map(obj => {
                    return (
                      <option key={obj.IdUbigeo} value={obj.CodProvincia}>
                        {obj.NombreUbigeo}
                      </option>
                    );
                  })}
                </select>

                <select
                  required
                  className=""
                  aria-haspopup="true"
                  aria-expanded="false"
                  id="distrito"
                  value={this.props.state.valueDistrito}
                  name="IdUbigeo"
                  onChange={this.props.DistritoChange}
                >
                  {this.props.state.ubigeoDis.map(obj => {
                    return (
                      <option key={obj.IdUbigeo} value={obj.IdUbigeo}>
                        {obj.NombreUbigeo}
                      </option>
                    );
                  })}
                </select>

              </div>
            </div>
            
            <div className="sucursalDiv">
              <b className="ml-4">*Nombre Localidad</b>
              <div className="input__sucursal">
                <input
                  className="input__linea"
                  name="Nombre"
                  onChange={this.props.onChange}
                  type="text"
                  value={this.props.state.Nombre}
                  placeholder="Nombre..."
                  required
                />
              </div>
            </div>

            <div className="sucursalDiv">
              <b className="ml-4">*Dirección</b>
              <div className="input__sucursal">
                <input
                  className="input__linea"
                  name="Direccion"
                  onChange={this.props.onChange}
                  type="text"
                  value={this.props.state.Direccion}
                  placeholder="Dirección..."
                  required
                />
              </div>
            </div>

            <div className="sucursalDiv">
              <b className="ml-4">*Teléfono</b>
              <div className="input__sucursal">
                <input
                  className="input__linea"
                  name="Telefono"
                  onChange={this.props.onChange}
                  type="text"
                  value={this.props.state.Telefono}
                  placeholder="Teléfono..."
                  required
                />
                
              </div>
              <div className="labelValidSucursal">
                  {this.props.state.Errores.Telefono.length > 0 && (
                    <span>{this.props.state.Errores.Telefono}</span>
                  )}
              </div>
            </div>

            <div className="sucursalDiv">
              <b className="ml-4">*Celular</b>
              <div className="input__sucursal">
                <input
                  className="input__linea"
                  name="Celular"
                  onChange={this.props.onChange}
                  type="text"
                  value={this.props.state.Celular}
                  placeholder="Celular..."
                  required
                />
              </div>
              <div className="labelValidSucursal">
                {this.props.state.Errores.Celular.length > 0 && (
                  <span>{this.props.state.Errores.Celular}</span>
                )}
              </div>
            </div>

            <div className="sucursalDiv">
              <b className="ml-4">*Correo Electrónico</b>
              <div className="input__sucursal">
                <input
                  className="input__linea"
                  name="CorreoElectronico"
                  onChange={this.props.onChange}
                  type="email"
                  value={this.props.state.CorreoElectronico}
                  placeholder="Correo Electronico..."
                  required
                />
              </div>
              <div className="labelValidSucursal">
                {this.props.state.Errores.CorreoElectronico.length > 0 && (
                  <span>{this.props.state.Errores.CorreoElectronico}</span>
                )}
              </div>
            </div>

            <div className="sucursalDiv">
              <b className="ml-4">Ubigeo:</b>
              <div className="input__sucursal">
              <b className="ml-4">{this.props.state.ubigeo}</b>
              </div>
            </div>

            <div className="sucursalDiv">
              <b className="ml-4">*Código Establecimiento</b>
              <div className="input__sucursal">
                <input
                  className="input__linea"
                  name="Codigo"
                  onChange={this.props.onChange}
                  type="text"
                  value={this.props.state.Codigo}
                  placeholder="Codigo..."
                  required
                />
              </div>
            </div>

            <div className="sucursalBoton">
              {this.props.CambiarEstadoSede?this.props.CambiarEstadoSede:null}
              <BotonGuardarLetras type="submit" className = "ml-2" />
            </div>

          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default SucursalForm;

import React from 'react';
import "react-moment"; 
//Header
import Header from "../../componentes/Header.js";
import parent_icon_header from "../../componentes/images/icons8-barcode-52.png";
import child_icon_header from "../../componentes/images/icons8-clipboard-52.png";

import { BotonGuardarLetras,BotonEditar, BotonAddPequeño, BotonCancelar } from "../Plantillas/Botones";

class PreciosMaster extends React.Component{

    constructor(){
    
        super()
            this.state={
                PrecioNombre:'',
                IdPrecioEdit:0,
                addOrEdit:1,
                botonText:"Añadir",
                plantillaPrecios:{
                    plantillaPrecios:[]
                },
            }
            this.obtenerPreciosPlantilla = this.obtenerPreciosPlantilla.bind(this)
            this.añadirOrEditarPrecio = this.añadirOrEditarPrecio.bind(this)
            this.añadirPrecio = this.añadirPrecio.bind(this)
            this.editarNombrePrecio = this.editarNombrePrecio.bind(this)
        }

        componentDidMount = () =>{
            this.obtenerPreciosPlantilla()
        }

        handleChange = (event)=>{
            const {name,value}= event.target
            this.setState({
                [name]:value
            })
        }

        añadirOrEditarPrecio = () => {
            // console.log(this.state.addOrEdit)
            if(this.state.addOrEdit == 1){
                this.añadirPrecio()
            } else if (this.state.addOrEdit == 2){
                this.editarPrecio()
            }
        }

        añadirPrecio = async() => {
            this.setState({loading:true, error:null})
            try{
                const res = await fetch(`/api/detalleConfiguraciones/preciosEmpresaConfig/add`,{
                    method:'POST',
                    body: JSON.stringify({
                        Nombre: this.state.PrecioNombre          
                    }),
                    headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    }
                })        
                // console.log(res.json())    
                this.setState({
                    addOrEdit:1
                })
            }
            catch (err) {console.log(err)}
        }
        editarPrecio = async() => {
            this.setState({loading:true, error:null})
            try{
                const res = await fetch(`/api/detalleConfiguraciones/preciosEmpresaConfig/editP/plantilla`,{
                    method:'PUT',
                    body: JSON.stringify({
                        IdPrecioPlantilla: this.state.IdPrecioEdit,
                        Nombre: this.state.PrecioNombre          
                    }),
                    headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    }
                })        
                // console.log(res.json())
            }
            catch (err) {console.log(err)}
        }

        obtenerPreciosPlantilla = async () =>{
            this.setState({loading:true, error:null})
            try {
                const response = await fetch(`/api/detalleConfiguraciones/preciosEmpresaConfig/precios/plantilla`);
                const data = await response.json();
                // console.log(data)
                this.setState({
                    loading: false,
                    plantillaPrecios: data
                })
            }catch(error){
                this.setState({loading: false, error: error})
            }
        }

        editarNombrePrecio = async(e) => {
            // console.log(e.target.value," - ",e.target.name)
            await this.setState({ 
                IdPrecioEdit: e.target.value,
                PrecioNombre: e.target.name,               
                addOrEdit: 2,
                botonText: "Editar"
            })
        }

    render = () =>{
        var b = 0
        return(
            <React.Fragment>
                <div className="ml-4 mt-4">
                    <BotonCancelar to="/empresas"/>
                </div>
                
                <form onSubmit={this.añadirOrEditarPrecio} className="d-flex justify-content-center">
                    <div className="almacenForm">
                        <div className="almacenDiv">
                            <b className="ml-4">Precio:  </b>
                            <div className="input__Almacen">
                                <input name="PrecioNombre" 
                                    className="input__linea" 
                                    onChange={this.handleChange}
                                    value={this.state.PrecioNombre}
                                    placeholder="Nuevo precio..."
                                    type="text" 
                                    required/>
                                <BotonAddPequeño
                                    type="submit"
                                />
                            {/* <button className="btn btn-outline-primary ml-1 mr-1 col-2" type="submit"> {this.state.botonText} </button> */}
                            </div>
                        </div>
                        <div id="tableProdPreventa" >
                            <div >
                                <table align="center" className="table table-hover" >
                                    <thead>
                                        <tr>
                                            <th align="center"> Precio</th>
                                            <th align="center">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.plantillaPrecios.plantillaPrecios.map((obj)=>{
                                            b=b+1;
                                            if(obj.IdPrecioPlantilla < 5){
                                                return(
                                                    <tr  key={obj.IdPrecioPlantilla}>
                                                        <td>{b}. {obj.Nombre}</td>
                                                    </tr>
                                                )
                                            }else {
                                                return(                  
                                                    <tr  key={obj.IdPrecioPlantilla}>
                                                        <td>{b}. {obj.Nombre}</td>
                                                        <td align="center">
                                                            <BotonEditar
                                                                type="button" 
                                                                title="Editar Precio"
                                                                onClick={this.editarNombrePrecio} 
                                                                name={obj.Nombre} 
                                                                value={obj.IdPrecioPlantilla}
                                                                />
                                                            {/* <button  className="btn btn-outline-primary ml-1 mr-1" ><i className="fas fa-edit"></i></button> */}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        }
                                            )
                                        }
                                    </tbody>
                                </table> 
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    }
}

export default PreciosMaster
import React from "react";
import AsyncSelect from "react-select/async/dist/react-select.esm";

export default class InvoicePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        }
        this.searchInvoices = this.searchInvoices.bind(this)
        this.onChangeInvoice = this.onChangeInvoice.bind(this)
    }

    onChangeInvoice(e) {
        this.props.onChangeInvoice(e ? (this.isMulti() ? e : [e]) : []);
    }

    isMulti() {
        return Boolean(this.props.isMulti);
    }

    searchInvoices(query) {
        return new Promise((resolve, reject) => {
            this.setState({isLoading: true})

            fetch(`/api/notascd/listrv/1/?search=${query}`)
                .then(r => r.json())
                .then(ventas => {
                    const mappedVentas = ventas.map(v => {
                        return {
                            ...v,
                            label: `${v.Serie} - ${v.NumeroComprobante} - ${v.FechaEmision}`,
                            value: v.IdRegistroVenta
                        }
                    });
                    resolve(mappedVentas)
                }).catch(reject)
                .finally(() => this.setState({isLoading: false}))
        })
    }

    render() {
        return (
            <>
                <AsyncSelect
                    isDisabled={Boolean(this.props.readOnly)}
                    defaultOptions={true}
                    onChange={this.onChangeInvoice}
                    isClearable={true}
                    isLoading={this.state.isLoading}
                    loadOptions={this.searchInvoices}
                    isMulti={this.isMulti()}
                    placeholder={this.props.placeholder || "seleccionar"}
                />
            </>
        );
    }

}

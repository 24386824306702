const validacionesRegexr = { 
    RucRegex: RegExp(/^[0-9]{11,11}$/),
    RazonSocialRegex: RegExp(/^([A-ZÁÉÍÓÚÑa-záéíóú'ñ\s]{3,200}[0-9]*)$/),
    DireccionRegex: RegExp(/^([A-ZÁÉÍÓÚÑa-záéíóú'ñ0-9#.\s]){5,200}$/),
    TelefonoRegex: RegExp(/^(\+?[0-9]{2}\s?)?[0-9]{3}[\s\-]?[0-9]{3}\s?[0-9]{3,4}$/),
    TelefonoOpcionalRegex: RegExp(/^((\+?[0-9]{2}\s?)?[0-9]{3}[\s\-]?[0-9]{3}\s?[0-9]{3,4})?$/),
    NombreComercialRegex: RegExp(/^([A-ZÁÉÍÓÚÑa-záéíóú'ñ0-9\s]){3,200}$/),
    PaginaWebRegex: RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/),
    CorreoElectronicoRegex: RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{3,100}@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,100}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,100}[a-zA-Z0-9])?)*$/),
    CorreoElectronicoOpcionalRegex: RegExp(/^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{3,12}@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*)?$/),
    SectorRegex: RegExp(/^([A-ZÁÉÍÓÚÑa-záéíóú'ñ0-9\s]){3,200}$/),
    RepresentanteLegalRegex: RegExp(/^([A-ZÁÉÍÓÚÑa-záéíóú'ñ0-9\s]){3,200}$/),
}

export {validacionesRegexr}
import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import ReactExport from "react-data-export";
import "react-moment";
import SelectSucursal from '../PuntoVenta/SucursalesLista';
import {convertStrToJson, decimalAdjust, doDownload} from "../../Global";

import("react-month-picker-input/dist/react-month-picker-input.css");


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function getDataExportExcel(dataSet) {
    return [
        {
            columns: [
                {
                    title: "CodigoUnicoOperacion",
                    width: {wpx: 90}
                },
                {
                    title: "FechaEmision",
                    width: {wpx: 100}
                },
                {
                    title: "FechaVencimiento",
                    width: {wpx: 100}
                },
                {
                    title: "TipoComprobante",
                    width: {wpx: 100}
                },
                {
                    title: "Serie",
                    width: {wpx: 60}
                },
                {
                    title: "Numero Comprobante",
                    width: {wpx: 100}
                },
                {
                    title: "Modalidad de Pago",
                    width: {wpx: 100}
                },
                {
                    title: "Cantidad de Días",
                    width: {wpx: 100}
                },
                {
                    title: "Fecha Pago Crédito",
                    width: {wpx: 100}
                },
                {
                    title: "Estado del Comprobante",
                    width: {wpx: 100}
                },
                {
                    title: "NroTipoDocumentoCliente",
                    width: {wpx: 120}
                },
                {
                    title: "RazonSocial",
                    width: {wpx: 200}
                },
                {
                    title: "Exportacion",
                    width: {wpx: 100}
                },
                {
                    title: "Gravadas",
                    width: {wpx: 100}
                },
                {
                    title: "Exoneradas",
                    width: {wpx: 100}
                },
                {
                    title: "Inafectas",
                    width: {wpx: 100}
                },
                {
                    title: "ISC",
                    width: {wpx: 100}
                },
                {
                    title: "IGV",
                    width: {wpx: 100}
                },
                {
                    title: "ICBPER",
                    width: {wpx: 100}
                },
                {
                    title: "DESC. ITEMS.",
                    width: {wpx: 100}
                },
                {
                    title: "Total",
                    width: {wpx: 100}
                },
                {
                    title: "Redondeo",
                    width: {wpx: 100}
                },
                {
                    title: "TotalRedondeo",
                    width: {wpx: 100}
                },
                {
                    title: "TipoCambio",
                    width: {wpx: 100}
                },
                {
                    title: "FechaComprobanteModificado",
                    width: {wpx: 100}
                },
                {
                    title: "CodigoTipoComprobanteModificado",
                    width: {wpx: 100}
                },
                {
                    title: "SerieComprobanteModificado",
                    width: {wpx: 100}
                },
                {
                    title: "NumeroComprobanteModificado",
                    width: {wpx: 100}
                }
            ],

            data: dataSet.map(row => {
                return [
                    {
                        value: row.CodigoUnicoOperacion,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.FechaEmision,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.FechaVencimiento,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.TipoComprobante,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Serie,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.NumeroComprobante,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.IdModalidadPago,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.CantidadDiasCredito,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.FechaPago,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Estado,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.NroTipoDocumentoCliente,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.RazonSocial,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Exportacion || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Gravadas || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Exoneradas || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Inafectas || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.ISC || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.IGV || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.ICBPER || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.descItems,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Total,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Redondeo || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.TotalRedondeo || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.TipoCambio,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.FechaComprobanteModificado,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.CodigoTipoComprobanteModificado,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.SerieComprobanteModificado,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.NumeroComprobanteModificado,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    }
                ];
            })
        }
    ];
}

function getCEAnulacion(row) {
    return {
        cabecera:
            {
                IdRegistroVenta: row.IdRegistroVenta,
                NombreArchivo: "",
                FechaGeneracionDocumento: "",
                FechaComunicacion: "",
                RUC: "",
                RazonSocial: "",
                TipoDocumento: "",
            },
        items: [
            {
                TipoComprobante: row.CodTipoComprobante,
                FechaEmision: row.FechaEmision,
                Serie: row.Serie,
                NroComprobante: row.NumeroComprobante,
                Motivo: "CANCELACION",
            }
        ]
    }
}

const RegistrosVentasUI = props => {
    let ventas = [...props.ventas];
    const table = props.tableInfo;
    const isActiveTurn = props.isActiveTurn;

    if (props.reportePLE) {
        var dataExport = getDataExportExcel(props.reportePLE);
    }

    return (
        <>
            <div
                style={{
                    alignContent: 'center',
                    marginTop: 20,
                    marginRight: 30,
                    marginLeft: 30,
                    marginBottom: 20
                }}
            >
                <div className="d-flex justify-content-end">
                    <div className="p-1">
                    </div>
                    <div className="p-1">
                        <button
                            className="btn btn-outline-danger"
                            onClick={e => props.pdfViewer(props.reportePLE)}
                            title="Exportar a PDF"
                            hidden={!props.mostrarBotonesImpresion} disabled={!props.mostrarBotonesImpresion}
                        >
                            {<i className="fas fa-file-pdf"></i>}
                        </button>
                    </div>

                    <div className="p-1">

                        <ExcelFile
                            element={
                                <button
                                    className="btn btn-outline-success"
                                    title="Exportar a Excel"
                                    hidden={!props.mostrarBotonesImpresion} disabled={!props.mostrarBotonesImpresion}
                                >
                                    <i className="fas fa-file-excel"></i>
                                </button>
                            }
                            filename="ReporteVentas"

                        >
                            <ExcelSheet dataSet={dataExport} name="Ventas"/>
                        </ExcelFile>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        Sucursal:
                        <SelectSucursal
                            id={props.IdSucursal}
                            dataSucursales={props.dataSucursales}
                            SucursalChange={props.SucursalChange}
                            disabled={props.FiltrarSucursal}
                        />
                    </div>
                    <div className="col-3 mt-3">
                        <input
                            name="fechaBusqueda"
                            disabled={table.isLoading}
                            className="form-control"
                            onChange={props.handlefechaBusquedaChange}
                            value={props.fechaBusqueda}
                            type="date"
                        />
                    </div>
                    <div className="col-3 mt-3">
                        <button disabled={props.IsLoading} onClick={props.onReportHandler}
                                className="btn btn-outline-primary">
                            {props.IsLoading ? 'Cargando' : 'Realizar reporte'}
                        </button>
                    </div>

                </div>

                <ReactTable
                    id="ventas"
                    data={ventas}
                    loading={table.isLoading}
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value
                    }
                    onFetchData={(state, instance) => props.onFilterVentas(state, instance)}
                    columns={[
                        {
                            columns: [
                                {
                                    Header: "Opciones",
                                    id: "IdRegistroVenta",
                                    width: 350,
                                    accessor: d => d.IdRegistroVenta,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["IdRegistroVenta"]
                                        }),
                                    Cell: f => {
                                        let resEnvioSunat = convertStrToJson(f.original.respuestaEnvioSunat);
                                        const rv = f.original;

                                        const oldXmlUrl = `${process.env.REACT_APP_FACT_BASE_URL}/datos/${rv.Ruc}/xml/${rv.CodigoTipoComprobante}/${rv.Ruc}-${rv.CodigoTipoComprobante}-${rv.Serie}-${rv.NumeroComprobante}.xml`;
                                        const oldCdrUrl = `${process.env.REACT_APP_FACT_BASE_URL}/datos/${rv.Ruc}/xml/${rv.CodigoTipoComprobante}/R-${rv.Ruc}-${rv.CodigoTipoComprobante}-${rv.Serie}-${rv.NumeroComprobante}.zip`;

                                        let xml = resEnvioSunat.xmlFirmado && resEnvioSunat.xmlFirmado.length ? resEnvioSunat.xmlFirmado : oldXmlUrl;
                                        let cdrZip = resEnvioSunat.cdrZip && resEnvioSunat.cdrZip.length ? resEnvioSunat.cdrZip : oldCdrUrl;
                                        let resAnulacionSunat = convertStrToJson(f.original.respuestaAnulacionSunat)

                                        return (
                                            <div align="left">
                                                {props.isLoading ? null : (
                                                    <button
                                                        className="btn btn-primary fas fa-file-import"
                                                        title="Enviar a Operador"
                                                        disabled={f.original.EstadoCE === "Aceptado" || f.original.EstadoCE === "Anulado" && "true"}
                                                        onClick={props.handleEnviarVenta(
                                                            f.original.IdRegistroVenta
                                                        )}
                                                    >
                                                    </button>
                                                )}

                                                <a
                                                    className="btn btn-outline-success fas fa-download ml-1 "
                                                    target="blank"
                                                    href={xml}
                                                    title={`Descargar XML`}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        doDownload(xml, `${rv.Ruc}-${rv.CodigoTipoComprobante}-${rv.Serie}-${rv.NumeroComprobante}.xml`);
                                                    }}
                                                >
                                                </a>
                                                <a
                                                    className="btn btn-outline-success fas fa-file-archive ml-1"
                                                    target="blank"
                                                    href={cdrZip}
                                                    title="Descargar CDR"
                                                >
                                                </a>

                                                {props.isLoading ? null : (isActiveTurn ? (
                                                    <button
                                                        className="btn btn-outline-dark far fa-minus-square ml-1"
                                                        disabled={f.original.regresoStock || resEnvioSunat.ok}
                                                        onClick={e => props.onRegresarStock(f.original)}
                                                    >
                                                    </button>
                                                ) : null)}

                                                {props.isLoading ? null : (
                                                    <button
                                                        className="btn btn-outline-danger fas fa-file-pdf ml-1"
                                                        title="Imprimir PDF"
                                                        onClick={e => {
                                                            props.onDownloadPdf(f.original.IdRegistroVenta, true)
                                                        }}
                                                    >
                                                    </button>
                                                )}

                                                {props.isLoading ? null : (
                                                    <button
                                                        className="btn btn-info fas fa-envelope-open-text ml-1"
                                                        disabled={f.original.EstadoCE === "Anulado" && "true"}
                                                        title="Enviar Correo"
                                                        onClick={
                                                            () => {
                                                                props.handleEnviarCorreo(f.original)
                                                            }
                                                        }
                                                    >
                                                    </button>
                                                )}
                                                {props.isLoading ? null : (isActiveTurn ?
                                                        <button
                                                            className="btn btn-danger fas fa-folder-minus ml-1"
                                                            title="Anular Comprobante"
                                                            disabled={f.original.EstadoCE === "Anulado" || props.disableAnularBtn}
                                                            hidden={f.original.EstadoCE !== "Aceptado"}
                                                            onClick={
                                                                () => {
                                                                    props.handleEnviarAnulacion(getCEAnulacion(f.original))
                                                                }
                                                            }
                                                        >
                                                        </button> : null
                                                )}
                                                {
                                                    resAnulacionSunat.xmlFirmado ? (<a
                                                        className="btn btn-outline-info fas fa-file-archive ml-1"
                                                        target="blank"
                                                        href={resAnulacionSunat.xmlFirmado}
                                                        title="XML Baja"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            doDownload(xml, `${rv.Ruc}-${rv.CodigoTipoComprobante}-${rv.Serie}-${rv.NumeroComprobante}.xml`);
                                                        }}
                                                    >
                                                    </a>) : null
                                                }
                                            </div>
                                        );
                                    }
                                },
                                {
                                    Header: "EstadoCE",
                                    id: "EstadoCE",
                                    accessor: d => d.EstadoCE,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["EstadoCE"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Cliente",
                                    id: "RazonSocial",
                                    accessor: d => d.RazonSocial,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["RazonSocial"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Fecha",
                                    id: "FechaEmision",
                                    accessor: d => d.FechaEmision,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["FechaEmision"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "RUC",
                                    id: "Ruc",
                                    accessor: d => d.Ruc,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["Ruc"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Tipo Comprobante",
                                    id: "TipoComprobante",
                                    accessor: d => d.TipoComprobante,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["TipoComprobante"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Serie",
                                    id: "Serie",
                                    accessor: d => d.Serie,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Serie"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Nro Comprobante",
                                    id: "NumeroComprobante",
                                    accessor: d => d.NumeroComprobante,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["NumeroComprobante"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Desc. Items.",
                                    id: "descItems",
                                    accessor: d => decimalAdjust('floor', d.descItems, -2),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["descItems"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Total",
                                    id: "Total",
                                    accessor: d => d.Total.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }) ? d.Total.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }) : 0.0,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Total"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Redondeo",
                                    id: "Redondeo",
                                    accessor: d => d.Redondeo == null ? '0.00' : d.Redondeo.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Redondeo"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "TotalRedondeo",
                                    id: "TotalRedondeo",
                                    accessor: d => d.TotalRedondeo.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }) ? d.TotalRedondeo.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }) : 0.0,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["TotalRedondeo"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Gravadas",
                                    id: "Gravadas",
                                    accessor: d => d.Gravadas.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Gravadas"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Exoneradas",
                                    id: "Exoneradas",
                                    accessor: d => d.Exoneradas.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Exoneradas"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Inafectas",
                                    id: "Inafectas",
                                    accessor: d => d.Inafectas.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Inafectas"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "IGV",
                                    id: "IGV",
                                    accessor: d => d.IGV.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["IGV"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "ICBPER",
                                    id: "ICBPER",
                                    accessor: d => d.ICBPER.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["ICBPER"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                            ]
                        },
                    ]}
                    manual={true}
                    filterable={true}
                    sortable={true}
                    pages={table.pages}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    previousText="Anterior"
                    nextText="Siguiente"
                    noDataText="Lista vacía"
                    loadingText="Cargando..."
                    ofText="de"
                    rowsText="filas"
                    pageText="Página"
                    pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                    SubComponent={row => {
                        return (
                            <>
                                <div
                                    className={row.original.EstadoCE === 'Aceptado' ? "alert alert-success p-3" : "alert alert-danger p-3"}
                                    width="70%">
                                    {row.original.EstadoCE ? `Código: ${row.original.CodigoCE || "-1"} :::::: Descripción: ${row.original.DescripcionCE || ""} ` : ``}
                                </div>
                                <a href="#" onClick={e => {
                                    e.preventDefault();
                                    props.onDownloadPdf(row.original.IdRegistroVenta, false)
                                }}>Descargar PDF</a>
                            </>
                        )
                    }}
                />
            </div>
        </>
    );
};

export default RegistrosVentasUI;

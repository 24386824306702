import React from "react";
import DetallePago from "./DetallePago";
import printA4 from "../../helpers/A4";
import "./styles/Pagos.css";
import {
    FACTURA, BOLETA, VALE, round, EFECTIVO, MASTER, VISA, AMERICAN, OTROS, PAGO_CREDITO, decimalAdjust
} from "../../Global";
import LinkButton from "../../componentes/LinkButton";
import EfectivoImg from "./images/efectivo.png";
import Mastercard from "./images/mastercard.png";
import Visa from "./images/visa.png";
import American from "./images/american.png";
import OtrosPagos from "./images/otros.png";
import Credito from "./images/credito.png";
import Cliente from "./images/cliente.png";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import {BotonCancelar} from "../../componentes/Plantillas/Botones";

function getDetallesPago(
    Pagos,
    updateMontoPago,
    updateReferenciaPago,
    handleFocus,
    updateFechaPago,
    IdPlazoCredito,
    plazosCredito,
    handlePagoEnterKeyUp,
    removePago
) {
    return Pagos.map((pago, index) => {
        return (
            <DetallePago
                key={`pay_${index}`}
                IdTipoPago={pago.IdTipoPago}
                Monto={pago.Monto}
                Referencia={pago.Referencia}
                handleFocus={handleFocus}
                handleMontoPago={async function (event) {
                    await updateMontoPago(index, event.target.value);
                }}
                handleReferenciaPago={function (event) {
                    updateReferenciaPago(index, event.target.value);
                }}
                handleSelectPlazosCredito={async function (event) {
                    await updateFechaPago(index, event.target.value);
                }}
                PlazoCredito={pago.PlazoCredito}
                handlePagoEnterKeyUp={handlePagoEnterKeyUp}
                handleRemovePago={function () {
                    removePago(index);
                }}
                fechaPago={pago.FechaPago}
            />
        );
    });
}

const PagosUI = props => {

    let disableCredit = props.Pagos.length ? props.Pagos.find(p => p.IdTipoPago !== PAGO_CREDITO) : false;

    return (
        <>
            <div className="pagos__container" tabIndex="-1">
                <div className="pagos">
                    <div className="preventa__container_data">
                        <div className="mb-3 d-flex justify-content-between align-items-center">
                            <BotonCancelar
                                to="/gestionpreventas"
                                title="Lista de Preventas Pendientes"
                                tabIndex="0"
                            />
                            {props.isLoading ? null :
                                props.hasPayed ? null : <button
                                    type="button"
                                    className="boton__verdeClaro ml-1"
                                    title="Editar Preventa"
                                    onClick={() =>
                                        props.setRedirect("/preVentas/edit/" + props.IdPreventa)
                                    }
                                >
                                    <i className="fa fa-edit"/>
                                </button>
                            }
                        </div>
                        <div className="preventa__cliente">
                            <input
                                type="image"
                                src={Cliente}
                                data-toggle="tooltip"
                                title="Datos del Cliente"
                                width="40px"
                                height="50px"
                                tabIndex="0"
                            />
                            <div className="preventa__cliente--contenido">
                                <p>{props.preventa.Alias}</p>
                                <p>
                                    <strong>{props.preventa.NroTipoDocumento}</strong>
                                </p>
                                <p>{props.preventa.RazonSocial}</p>
                            </div>
                        </div>
                        <div className="preventa__carrito">
                            <ReactTable
                                tabIndex="0"
                                id="table-productos-to-xls"
                                data={props.detallePreventa}
                                filterable
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value
                                }
                                columns={[
                                    {
                                        columns: [
                                            {
                                                Header: "Descripción",
                                                id: "NombreProductoConPresentacion",
                                                accessor: d => d.NombreProductoConPresentacion,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {
                                                        keys: ["NombreProductoConPresentacion"]
                                                    }),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Cantidad",
                                                id: "Cantidad",
                                                accessor: d => d.Cantidad,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {
                                                        keys: ["Cantidad"]
                                                    }),
                                                filterAll: true,
                                                getProps: (state, rowInfo, column) => {
                                                    return {
                                                        style: {
                                                            textAlign: "right"
                                                        }
                                                    };
                                                }
                                            },
                                            {
                                                Header: "Precio",
                                                id: "PrecioVenta",
                                                accessor: d => `S./ ${decimalAdjust('floor', d.PrecioVenta, -2)}`,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {
                                                        keys: ["PrecioVenta"]
                                                    }),
                                                filterAll: true,
                                                getProps: (state, rowInfo, column) => {
                                                    return {
                                                        style: {
                                                            textAlign: "right"
                                                        }
                                                    };
                                                }
                                            },
                                            {
                                                Header: "Descuento",
                                                id: "Descuento",
                                                accessor: d => `S./ ${decimalAdjust('floor', d.Descuento, -2)}`,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {
                                                        keys: ["Descuento"]
                                                    }),
                                                filterAll: true,
                                            },
                                            {
                                                Header: "Total",
                                                id: "Total",
                                                accessor: d => `S./ ${decimalAdjust('floor', d.Total, -2)}`,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {
                                                        keys: ["Total"]
                                                    }),
                                                filterAll: true,
                                            }
                                        ]
                                    }
                                ]}
                                defaultPageSize={8}
                                className="-striped -highlight"
                                previousText="Anterior"
                                nextText="Siguiente"
                                noDataText="No se encontraron registros"
                                loadingText="Cargando..."
                                ofText="de"
                                rowsText="filas"
                                pageText="Página"
                                pageSizeOptions={[8, 20, 25, 50, 100]}
                            />
                        </div>
                    </div>

                    <div className="encabezado">
                        <div className="mt-1">PAGOS - {props.TipoModalidadPago}</div>
                        <div className="pagos__tipo--comprobante">
                            <button
                                type="button"
                                tabIndex="0"
                                className={
                                    props.TipoComprobante === FACTURA
                                        ? "pagos__tipo--comprobante--item--active"
                                        : "pagos__tipo--comprobante--item"
                                }
                                value={props.TipoComprobante}
                                onClick={function () {
                                    props.handleTipoComprobante(2);
                                }}
                            >
                                Factura
                            </button>
                            <button
                                type="button"
                                tabIndex="0"
                                className={
                                    props.TipoComprobante === BOLETA
                                        ? "pagos__tipo--comprobante--item--active"
                                        : "pagos__tipo--comprobante--item"
                                }
                                value={props.TipoComprobante}
                                onClick={function () {
                                    props.handleTipoComprobante(4);
                                }}
                            >
                                Boleta
                            </button>
                            <button
                                type="button"
                                tabIndex="0"
                                className={
                                    props.TipoComprobante === VALE
                                        ? "pagos__tipo--comprobante--item--active"
                                        : "pagos__tipo--comprobante--item"
                                }
                                value={props.TipoComprobante}
                                onClick={function () {
                                    props.handleTipoComprobante(1);
                                }}
                            >
                                Vale
                            </button>
                        </div>
                        <div className="detalles__pago">
                            <div className="detalles__pago__item">
                                {getDetallesPago(
                                    props.Pagos,
                                    props.updateMontoPago,
                                    props.updateReferenciaPago,
                                    props.handleFocus,
                                    props.updateFechaPago,
                                    props.IdPlazoCredito,
                                    props.plazosCredito,
                                    props.handlePagoEnterKeyUp,
                                    props.removePago,
                                )}
                            </div>
                        </div>
                        <div className="pagos__methods--container">
                            <div className="pagos__methods">
                                <input
                                    type="image"
                                    src={EfectivoImg}
                                    className="btn btn-outline-success"
                                    onClick={props.handleTipoPago(EFECTIVO)}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Efectivo"
                                    tabIndex="1"
                                    disabled={props.PagoEfectivoUnico || props.creditoSelected}
                                    autoFocus
                                />
                            </div>
                            <div className="pagos__methods">
                                <input
                                    type="image"
                                    src={Mastercard}
                                    className="btn btn-outline-danger"
                                    onClick={props.handleTipoPago(MASTER)}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Mastercard"
                                    disabled={props.creditoSelected}
                                    tabIndex="1"
                                />
                            </div>
                            <div className="pagos__methods">
                                <input
                                    type="image"
                                    src={Visa}
                                    className="btn btn-outline-primary"
                                    onClick={props.handleTipoPago(VISA)}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    disabled={props.creditoSelected}
                                    title="Visa"
                                    tabIndex="1"
                                />
                            </div>
                            <div className="pagos__methods">
                                <input
                                    type="image"
                                    src={American}
                                    className="btn btn-outline-info"
                                    onClick={props.handleTipoPago(AMERICAN)}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="American Express"
                                    disabled={props.creditoSelected}
                                    tabIndex="1"
                                />
                            </div>
                            <div className="pagos__methods">
                                <input
                                    type="image"
                                    src={OtrosPagos}
                                    className="btn btn-outline-warning"
                                    onClick={props.handleTipoPago(OTROS)}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Otros métodos de pago"
                                    disabled={props.creditoSelected}
                                    tabIndex="1"
                                />
                            </div>
                            <div className="pagos__methods">
                                <input
                                    type="image"
                                    src={Credito}
                                    className="btn btn-outline-danger"
                                    onClick={props.handleTipoPago(PAGO_CREDITO)}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Crédito"
                                    disabled={disableCredit}
                                    tabIndex="1"
                                />
                            </div>
                        </div>
                        <div className="info__input__container">
                            {/*<div className="input__container">*/}
                            {/*    <label>Descuento</label>*/}
                            {/*    <input*/}
                            {/*        className="input"*/}
                            {/*        aria-label="Soles"*/}
                            {/*        placeholder="0.00"*/}
                            {/*        value={props.Descuento || "0"}*/}
                            {/*        onChange={props.handleDescuento}*/}
                            {/*        type="number"*/}
                            {/*        onFocus={e => e.target.select()}*/}
                            {/*        tabIndex="0"*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <div className="input__container">
                                <label>Vuelto</label>
                                <input
                                    className="input"
                                    tabIndex="0"
                                    aria-label="Soles"
                                    placeholder="0.00"
                                    readOnly
                                    value={
                                        props.Vuelto &&
                                        props.Vuelto.toLocaleString("en", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })
                                    }
                                />
                            </div>
                            <div className="input__container">
                                <label>Observaciones</label>
                                <input
                                    className="input"
                                    aria-label="Observaciones"
                                    placeholder=""
                                    value={props.Observaciones}
                                    onChange={props.handleObservaciones}
                                    tabIndex="0"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="totals__container">
                    <div className="totals__container--item">
                        <p value={props.TotalGravadas}>
                            <strong>
                                {props.TotalGravadas > 0 &&
                                "Gravado S/ " +
                                Number(props.TotalGravadas).toFixed(2)}
                            </strong>
                        </p>
                        <p value={props.TotalInafectas}>
                            <strong>
                                {props.TotalInafectas > 0 &&
                                "Inafecto S/ " +
                                props.TotalInafectas}
                            </strong>
                        </p>
                        <p value={props.TotalExoneradas}>
                            <strong>
                                {props.TotalExoneradas > 0 &&
                                "Exonerado S/ " +
                                props.TotalExoneradas}
                            </strong>
                        </p>
                        <p value={props.Gratuitas}>
                            <strong>
                                {props.Gratuitas > 0 &&
                                "Gratuito S/ " +
                                props.Gratuitas}
                            </strong>
                        </p>

                        <p value={props.TotalICBPER}>
                            <strong>
                                {props.TotalICBPER > 0 &&
                                "ICBPER S/ " +
                                props.TotalICBPER}
                            </strong>
                        </p>
                        <p value={props.TotalIGV}>
                            <strong>
                                {props.TotalIGV > 0 &&
                                "IGV S/ " +
                                Number(props.TotalIGV).toFixed(2)}
                            </strong>
                        </p>
                        <p>
                            <strong>
                                {props.redondeo > 0 &&
                                "Redondeo S/ " +
                                decimalAdjust('floor', props.redondeo, -2)}
                            </strong>
                        </p>
                    </div>

                    <div className="totals__container--item-total">
            <span>
              {"S/" + props.Total.toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              })}
            </span>
                    </div>

                    <div className="totals__container--item">
                        <p>
                            <strong>
                                {round(props.Total - props.TotalPagado, 2) > 0 &&
                                "Pendiente S/" +
                                round(props.Total - props.TotalPagado, 2).toLocaleString(
                                    "en",
                                    {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }
                                )}
                            </strong>
                        </p>

                        {props.isLoading ? "Cargando" : props.error ? "Ha ocurrido un error" : (
                            props.hasPayed ? <BotonCancelar
                                to="/gestionpreventas"
                                title="Lista de Preventas Pendientes"
                                tabIndex="0"
                            /> : <button
                                className="pagos__boton"
                                onClick={props.handlePagar}
                                tabIndex="3"
                                hidden={
                                    !props.detallePreventa.length
                                }
                                disabled={
                                    !(props.TotalPagado >= props.Total && props.ClickPagar)
                                }
                            >
                                PAGAR
                            </button>
                        )}

                        {props.Venta && props.hasPayed ? (
                            <>
                                <button
                                    className="boton__verdeClaro--letras ml-1 ml-5"
                                    onClick={() =>
                                        props.printTicketVenta(props.Venta, props.DetallesVenta)
                                    }
                                    tabIndex="4"
                                >
                                    Ticket <i className="fas fa-print"/>
                                </button>
                                <button
                                    onClick={() => printA4(props.DetallesVenta, props.Venta, props.extImg)}
                                    className="boton__verdeOscuro--letras ml-1"
                                    tabIndex="5"
                                >
                                    A4 <i className="fas fa-print"/>
                                </button>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PagosUI;

import React from 'react';
import ReactTable from 'react-table';
import SelectSearch from "react-select-search";
import "../../pages/styles/SelectSearch.css";
import swal from 'sweetalert';
import JSONPretty from 'react-json-pretty';


class ConfigComprobantePuntoVenta extends React.Component {
    constructor() {
        super()
        this.state = {
            loading: true,
            error: null,
            dataDocSunat: [],
            dataDocSunatPV: [],

            itemCajaComprobante: {
                IdPuntoVenta: 0,
                IdTipoDocumentoSunat: 0,
                Serie: '',
                UltimoCorrelativo: 0,
            }
        }
    }

    componentDidMount() {
        this.TraerDocumentosSunat();

    }

    TraerDocumentosSunat = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/puntosVenta/docsunat`);
            const docsunat = await response.json();
            //console.log('DS',docsunat[0]);
            this.setState({
                loading: false,
                dataDocSunat: docsunat[0],
            });
            // console.log("IDCAJA",this.props.itemPV.IdCaja);
            await this.TraerDocumentosSunatPV(this.props.itemPV.IdPuntoVenta);

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
        // console.log('Sucursales: ',this.state.dataSucursales);
    };

    TraerDocumentosSunatPV = async (idpv) => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/puntosVenta/docsunat/pv/${idpv}`);
            const docsunatpv = await response.json();
            // console.log('DSC',docsunatpv[0]);
            this.setState({
                loading: false,
                dataDocSunatPV: docsunatpv[0],
            });

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
        console.log('PVs: ', this.state.dataDocSunatPV);
    };

    agregarCajaComprobanteElectronico = async () => {

        if (this.state.itemCajaComprobante.IdTipoDocumentoSunat && this.state.itemCajaComprobante.Serie && this.state.itemCajaComprobante.UltimoCorrelativo) {
            // console.log('INSERTAR');
            await this.crearCajaComprobanteElectronico();
        } else {
            swal("Falta Información!");
        }
    }

    handleOnClickDelete = (e) => {
        const v = e.target.value;
        swal({
            title: "Cuidado...!",
            text: "Desea quitar el registro?",
            icon: "warning",
            buttons: [
                'No',
                'Si'
            ],
        })
            .then((isConfirm) => {
                if (isConfirm) {
                    if (v) {
                        this.quitarCajaComprobanteElectronico(v);
                    }
                }
            });
    }

    crearCajaComprobanteElectronico = async () => {

        this.setState({loading: true, error: null});
        try {
            const config = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IdPuntoVenta: this.props.itemPV.IdPuntoVenta,
                    IdTipoDocumentoSunat: this.state.itemCajaComprobante.IdTipoDocumentoSunat,
                    Serie: this.state.itemCajaComprobante.Serie,
                    UltimoCorrelativo: this.state.itemCajaComprobante.UltimoCorrelativo,
                    metadata: JSON.stringify(JSON.parse(this.state.itemCajaComprobante.metadata || "[]"))
                })
            };

            const response = await fetch(`/api/puntosVenta/docsunat`, config);
            const rpta = await response.json();
            if (rpta[0][0].Estado === 'Duplicado') {
                swal("El item Duplicado!", {
                    icon: "error",
                });
            } else {
                this.setState({
                    loading: false,
                });
                swal("El item fue Insertado con Éxito!", {
                    icon: "success",
                });
            }

            // ACTUALIZAR LISTA
            await this.TraerDocumentosSunatPV(this.props.itemPV.IdPuntoVenta);

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    };

    quitarCajaComprobanteElectronico = async (idcce) => {
        this.setState({loading: true, error: null});
        try {
            const config = {
                method: 'DELETE',
            };
            // console.log(config);

            const response = await fetch(`/api/puntosVenta/docsunat/delete/${idcce}`, config);
            const rpta = await response.json();

            if (rpta[0][0][0].Estado === 'TieneTurno') {
                // await this.TraerDocumentosSunatPV(parseInt(this.props.itemPV.IdPuntoVenta));
                swal("El item está en Uso!", {
                    icon: "error",
                });
            } else {
                if (rpta[0][0][0].Estado === 'Eliminado') {
                    await this.TraerDocumentosSunatPV(parseInt(this.props.itemPV.IdPuntoVenta));
                    this.setState({
                        loading: false,
                    });
                    swal("El item fue Retirado!", {
                        icon: "success",
                    });
                }
            }

        } catch (error) {
            console.log("error");
            this.setState({
                loading: false,
                error: error,
            });
        }
    };

    handleChange = (e) => {
        this.setState({
            itemCajaComprobante: {
                ...this.state.itemCajaComprobante,
                [e.target.name]: e.target.value,

            },
        });
    }

    handleChangeDocSunat = (e) => {

        console.log(e.value);
        this.setState({
            itemCajaComprobante: {
                ...this.state.itemCajaComprobante,
                IdTipoDocumentoSunat: e.value,
            },
        });
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-7">
                        <SelectSearch
                            options={this.state.dataDocSunat}
                            className='select-search-box'
                            placeholder="seleccionar tipo de comprobante..."
                            name="IdTipoDocumentoSunat"
                            onChange={this.handleChangeDocSunat}
                            value={this.state.itemCajaComprobante.IdTipoDocumentoSunat}
                        />
                    </div>
                    <div className="col">
                        <input type="text" placeholder="nro serie"
                               name="Serie"
                               onChange={this.handleChange}/>
                    </div>
                    <div className="col">
                        <input type="number" min="0" max="100000000" step="1" placeholder="correlativo"
                               name="UltimoCorrelativo"
                               onChange={this.handleChange}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <textarea className="form-control" name="metadata" id="metadata" cols="10" rows="3"
                                  placeholder="Metadata"
                                  onChange={this.handleChange}/>
                    </div>
                    <div className="col">
                        <button className="btn btn-outline-success fas fa-plus-square mr-1"
                                title="Agregar Tipo Comprobante"
                                onClick={this.agregarCajaComprobanteElectronico}/>
                    </div>
                </div>
                <div className="row mt-4">
                    <ReactTable
                        data={this.state.dataDocSunatPV}
                        columns={[
                            {
                                columns: [
                                    {
                                        Header: "Codigo",
                                        accessor: "CodigoDoc",
                                        width: 80,
                                    },
                                    {
                                        Header: "Tipo Documento",
                                        accessor: "NombreDoc",
                                        width: 660,
                                    },
                                    {
                                        Header: "Nro Serie",
                                        accessor: "Serie",
                                        width: 100,
                                    },
                                    {
                                        Header: "Correlativo",
                                        accessor: "UltimoCorrelativo",
                                        width: 100,

                                    },
                                    {
                                        Header: "Acciones",
                                        accessor: "IdCCE",
                                        width: 100,
                                        Cell: (f) => {

                                            return (
                                                <div align="center">
                                                    <div>
                                                        <button
                                                            id={f.value}
                                                            className="btn btn-outline-danger fas fa-minus-square mr-1"
                                                            title="Quitar"
                                                            value={f.original.IdCCE}
                                                            onClick={this.handleOnClickDelete}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        Header: "Metadata",
                                        accessor: "metadata",
                                        width: 400,
                                        Cell: (f) => {
                                            return (
                                                <JSONPretty data={JSON.parse(f.original.metadata)}/>
                                            );
                                        },
                                    },
                                ]
                            }
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        previousText='<<'
                        nextText='>>'
                        loadingText='Cargando...'
                        noDataText='No se encontraron registros'
                        pageText='Pág.'
                        ofText='de'
                        rowsText='filas'
                        width='100%'
                        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                    />
                </div>
            </div>
        )
    }
}

export default ConfigComprobantePuntoVenta;

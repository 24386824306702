import React from "react"
import NotasCDForm from "./NotasCDForm"
import PageLoading from '../PageLoading'
import swal from "sweetalert"
import {formatDate} from "../../Global"
import _ from 'lodash';

class NotasCDFormController extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            error: null,

            form: {
                TipoNCD: "1",
                IdTipoNCD: "1",
                SerieCE: "",
                NumeroCE: "",
                SerieCD: "",
                NumeroCD: "",
                FechaEmision: new Date(),
                FechaEmisionCE: null,
                IdRegistroVenta: null,
                IdSucursal: null,
                IdTurnoCaja: null,
                Descripcion: "",

                modalListRVIsOpen: false,
                accion: "",
                descuento: 0,
                items: []
            },
            dataTipoNCDFilter: [],

        }
        this.onChangeDescuento = this.onChangeDescuento.bind(this);
        this.onChangeItems = this.onChangeItems.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.onChangeItemDescuento = this.onChangeItemDescuento.bind(this);
    }

    async componentDidMount() {
        await this.getTiposFilter(1);
    }

    getTiposFilter = async (tipo) => {

        this.setState({loading: true, error: null});
        try {

            const result = this.props.dataTipoNCD.filter(item => parseInt(item.TipoNCD) === parseInt(tipo));

            this.setState({
                loading: false,
                dataTipoNCDFilter: result
            });
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    onChangeTipoNCD = async (e) => {

        this.setState({
            // dataTipoNCDFilter: [],
            form: {
                ...this.state.form,
                TipoNCD: e.target.value,
            }
        })
        await this.getTiposFilter(e.target.value);
    }

    onChangeDescripcion = async (e) => {
        this.setState({
            form: {
                ...this.state.form,
                Descripcion: e.target.value,
            }
        })
    }

    onChangeInput = async (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            }
        })
    }

    onChangeTipoNCDdetalle = async (e) => {
        this.setState({
            form: {
                ...this.state.form,
                IdTipoNCD: e.target.value,
            }
        })
    }

    handleClickBuscarRV = async () => {
        try {
            //verificamos comprobante por serie y numero
            const {SerieCE, NumeroCE} = this.state.form

            if (SerieCE === "" || NumeroCE === "") {
                swal({
                    title: 'Atención.!',
                    text: `Ingrese Serie y Número del Comprobante Electrónico...`,
                    icon: 'warning'
                })
            } else {
                const res = await fetch(`/api/notascd/itemrv/${1}/${SerieCE.toUpperCase()}/${parseInt(NumeroCE)}`);
                const datares = await res.json();
                if (datares.IdRegistroVenta === -1) {
                    swal({
                        title: 'Atención.!',
                        text: `Comprobante Electrónico: ${SerieCE.toUpperCase()}-${NumeroCE} no encontrado...`,
                        icon: 'warning'
                    })
                } else {
                    this.setState({
                        form: {
                            ...this.state.form,
                            IdRegistroVenta: datares.IdRegistroVenta,
                            SerieCE: datares.SerieCE,
                            NumeroCE: datares.NumeroCE
                        }
                    })
                }
            }

        } catch (error) {

        }
    }

    handleClickSeleccionarRV = () => {
        this.setState({
            form: {
                ...this.state.form,
                modalListRVIsOpen: true,
                accion: 'BuscarRV'
            }
        })
    }

    handleCloseModalRV = () => {
        this.setState({
            form: {
                ...this.state.form,
                modalListRVIsOpen: false,
            }
        })
    }

    handleSubmit = async (e) => {
        if (this.state.form.SerieCE !== '' && this.state.form.NumeroCE !== ''
            && this.state.form.IdRegistroVenta != null && _.isNumber(Number(this.state.form.descuento))) {
            await this.crearNotaCD();
        } else {
            const r = this.props.accion === 'Nuevo' ? "Crear" : "Actualizar";
            swal({
                title: 'Atención.!',
                text: `No se puede ${r} la Nota de Crédito, información incompleta o inválida...`,
                icon: 'error'
            })
        }
    }

    crearNotaCD = async () => {
        try {
            const res = await fetch(`/api/cajas/verificar/Turno`);
            const dataTurno = await res.json();
            this.setState({
                form: {
                    ...this.state.form,
                    IdTurnoCaja: dataTurno.Turno ? dataTurno.Turno[0][0].IdTurnoCaja : null,
                }
            })

            const config = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IdNotasCD: '1',
                    IdRegistroVenta: parseInt(this.state.form.IdRegistroVenta),
                    FechaEmision: formatDate(this.state.form.FechaEmision, 'yyyy-mm-dd'),
                    IdTurnoCaja: parseInt(this.state.form.IdTurnoCaja),
                    NumeroCE: this.state.form.NumeroCE,
                    SerieCE: this.state.form.SerieCE,
                    Estado: 'PENDIENTE',
                    Tipo: String(this.state.form.TipoNCD) === "1" ? 'NC' : 'ND',
                    IdTipoNCD: parseInt(this.state.form.IdTipoNCD),
                    IdSucursal: parseInt(this.state.form.IdSucursal),
                    Descripcion: this.state.form.Descripcion,
                    descuento: this.state.form.descuento,
                    items: JSON.stringify(this.state.form.items.map(l => {
                        return {id: l.value, descuento: l.descuento}
                    }))
                })
            };

            const response = await fetch(`/api/notascd/add/`, config);
            const rpta = await response.json();

            if (rpta.respuesta !== -1) {
                swal({
                    title: 'Atención...! ',
                    text: `Nota de Crédito Creada`,
                    icon: 'success'
                })
                await this.props.sendxml(parseInt(this.state.form.IdRegistroVenta), rpta.respuesta)

            } else {
                swal({
                    title: 'Atención..!',
                    text: `Error al Crear la Nota de Crédito...`,
                    icon: 'error'
                })
            }

            await this.props.onClose(true)
        } catch (error) {
            console.log(error.message)
            this.setState({
                loading: false,
                error: error,
            });
        }
    };

    onChangeDescuento(e) {
        this.setState({
            form: {
                ...this.state.form,
                descuento: e.target.value
            }
        })
    }

    onChangeItems(item) {
        let tmpItems = [...this.state.form.items];
        let finded = tmpItems.find(i => i.value === item.value)
        if (!finded)
            tmpItems.push(item);
        this.setState({
            form: {
                ...this.state.form,
                items: tmpItems
            }
        })
    }

    onRemoveItem(item) {
        let tmpItems = [...this.state.form.items];
        const i = tmpItems.indexOf(item);
        tmpItems.splice(i, 1);
        this.setState({
            form: {
                ...this.state.form,
                items: tmpItems
            }
        })
    }

    onChangeItemDescuento(e, item) {
        let tmpItems = [...this.state.form.items];
        const i = tmpItems.indexOf(item);
        tmpItems[i].descuento = _.isNumber(Number(e.target.value)) ? e.target.value : 0;
        this.setState({
            form: {
                ...this.state.form,
                items: tmpItems
            }
        })
    }

    render() {

        if (this.state.dataTipoNCDFilter.length === 0) {
            return <PageLoading/>
        }
        return (
            <NotasCDForm
                dataTipoNCDFilter={this.state.dataTipoNCDFilter}
                onChangeTipoNCD={this.onChangeTipoNCD}
                onChangeTipoNCDdetalle={this.onChangeTipoNCDdetalle}
                onChangeDescripcion={this.onChangeDescripcion}
                onChangeInput={this.onChangeInput}
                onChangeDescuento={this.onChangeDescuento}
                onChangeItems={this.onChangeItems}
                onRemoveItem={this.onRemoveItem}
                form={this.state.form}
                handleClickBuscarRV={this.handleClickBuscarRV}
                handleClickSeleccionarRV={this.handleClickSeleccionarRV}
                handleCloseModalRV={this.handleCloseModalRV}
                handleSubmit={this.handleSubmit}
                onChangeItemDescuento={this.onChangeItemDescuento}
            />
        )
    }
}

export default NotasCDFormController

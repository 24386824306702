import Noty from "noty";

/**
 * Crea un Objeto de Noty a partir de los siguientes parámetros:
 * @param {string} message
 * @param {string} [type=success] default: "success" / alert, error, warning, information, notification
 * @param {number} [timeout=3000] default: 3000
 */
const Notify = (message, type="success", timeout=3000) => {
  new Noty({
    text: message,
    type: type,
    timeout: timeout,
    theme: "bootstrap-v4",
    layout: 'topCenter'
  }).show();
}

export default Notify;
// More options
// https://ned.im/noty/v2/options.html
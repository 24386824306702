import React from "react";
import PageLoading from "../PageLoading.js";
import "../../pages/styles/SelectSearch.css";
import "react-moment";
import moment from "moment";
import '../styles/VentasPorProducto.css';
import ReactExport from "react-data-export";
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";

import {BotonLetras, BotonExcel, BotonPDF, BotonCancelar} from "../Plantillas/Botones.js";
import {notificationKardexProductoNull, notificationKardexFechas} from "../Kardex/KardexNotify.js";
import AsyncSelect from "react-select/async/dist/react-select.esm";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class VentasPorProducto extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataProductos: [],
            nombreProducto: "",
            isLoading: false,
            error: null,
            IdProducto: null,
            FechaInicio: moment(new Date()).format('YYYY-MM-DD'),
            FechaFin: moment(new Date()).format('YYYY-MM-DD'),
            dataVentas: {
                items: []
            },
            StockConComprobante: true,
            displayButton: "none",
            idSucursal: 0,
            sucursales: [],
            prod: {},
            idsTipoDoc: [],
            load: false,
            totales: {
                cantidades: 0,
                total: 0,
                utilidad: 0
            }
        }
        this.FechaChange = this.FechaChange.bind(this)
        this.handleProductoChange = this.handleProductoChange.bind(this)
        this.handleSavePDF = this.handleSavePDF.bind(this)
        this.onIdSucursalChange = this.onIdSucursalChange.bind(this);
        this.searchProductos = this.searchProductos.bind(this);
        this.onChangeTipoDoc = this.onChangeTipoDoc.bind(this);
        this.searchDocs = this.searchDocs.bind(this);
        this.updateTotales = this.updateTotales.bind(this);
    }

    componentDidMount() {
        this.fetchSucursales()
    }

    async fetchSucursales() {
        this.setState({isLoading: true});
        fetch('/api/sucursales/SucursalEmpresa')
            .then(res => res.json())
            .then(data => this.setState({sucursales: data, isLoading: false}))
    }

    searchProductos(query) {
        const idSucursal = this.state.idSucursal;
        return new Promise((resolve, reject) => {
            fetch(`/api/productos?search=${query}&idSucursal=${idSucursal}`)
                .then(response => response.json())
                .then(json => json.productos)
                .then(prods => {
                    const mappedProds = prods.map(p => {
                        return {
                            ...p,
                            label: p.nombre,
                            value: p.id
                        }
                    })
                    resolve(mappedProds)
                }).catch(reject);
        })
    }

    onIdSucursalChange(e) {
        this.setState({
            idSucursal: e.target.value
        })
    }

    handleProductoChange(e) {
        this.setState({
            IdProducto: e.value,
            nombreProducto: e.label,
            prod: e
        });
    }

    FechaChange(e) {
        const {name, value} = e.target;
        //console.log(name,value)
        this.setState({
            [name]: value
        });
    }

    onChangeCheckBox = e => {
        const {name, checked} = e.target;

        this.setState({
            [name]: checked
        })

    };

    async GetVentas() {
        if (this.state.IdProducto == null) {
            notificationKardexProductoNull()
        } else {
            if (this.state.FechaInicio > this.state.FechaFin) {
                notificationKardexFechas()
            } else {
                try {
                    this.setState({load: true, error: null});
                    let params = `IdProducto=${this.state.IdProducto}&FechaInicio=${this.state.FechaInicio}&FechaFin=${this.state.FechaFin}&StockConComprobante=${this.state.StockConComprobante}`;
                    let queryParams = `idSucursal=${this.state.idSucursal}&idsTipoDoc=${this.state.idsTipoDoc}`
                    const response = await fetch(
                        `/api/ventas/getVentasPorProductos/${params}?${queryParams}`
                    )
                    const items = await response.json();
                    const data = {
                        items: items.items.map(i => {
                            i['PrecioVenta'] = i.Precio
                            i['PrecioCostoConIgv'] = Boolean(i.afectacionFree) ? i.precioCosto : i.precioCosto * 1.18;
                            i['totalUtilidad'] = Boolean(i.esFree) ? 0 : Number((i.Cantidad * (i.PrecioVenta - i.PrecioCostoConIgv)) - i.descItems).toFixed(6)
                            i['Total'] = Number((i.Cantidad * i.PrecioVenta) - i.descItems).toFixed(6)
                            return i;
                        })
                    }
                    this.updateTotales(data.items)

                    this.setState({
                        load: false,
                        dataVentas: data,
                        displayButton: "inline"
                    })
                } catch (error) {
                    this.setState({
                        load: false,
                        error: error,
                    });
                    console.log(error)
                }
            }
        }
    }

    async handleSavePDF() {
        let doc = new jsPDF({
            orientation: "landscape"
        });

        let items = [...this.state.dataVentas.items]

        doc.setFontSize(14);
        doc.text(`REPORTE DE VENTAS ${this.state.StockConComprobante == true ? "SUNAT" : ""} DE:`, 10, 10);
        doc.text(`${this.state.nombreProducto}`, 10, 15);
        doc.text(`DEL ${this.state.FechaInicio} AL ${this.state.FechaFin}`, 10, 20)
        doc.autoTable({
            body: items,
            columns: [
                {header: 'FECHA EMISIÓN', dataKey: 'FechaEmision'},
                {header: 'CLIENTE', dataKey: 'RazonSocial'},
                {header: 'NUM DOC', dataKey: 'NroTipoDocumento'},
                {header: 'DOCUMENTO', dataKey: 'TipoDocumento'},
                {header: 'SERIE', dataKey: 'Serie'},
                {header: 'NUM. COMPROBANTE', dataKey: 'NumeroComprobante'},
                {header: 'CANTIDAD', dataKey: 'Cantidad'},
                {header: 'PRECIO', dataKey: `Precio`},
                {header: 'TOTAL', dataKey: `Total`},
                {header: 'PRECIO COSTO', dataKey: `PrecioCostoConIgv`},
                {header: 'UTILIDAD', dataKey: `totalUtilidad`}
            ],
            styles: {
                fontSize: 11,
            },
            startY: 25
        })
        doc.save(`${this.state.nombreProducto} ${moment(this.state.FechaInicio).format("DD/MM/YYYY")} - ${moment(this.state.FechaFin).format("DD/MM/YYYY")}.pdf`);

    }

    getDataExportExcel(dataSet) {
        return [
            {
                columns: [
                    {
                        title: `VENTAS DE ${this.state.nombreProducto} -`,
                        width: {wpx: 80}
                    }
                ],
                data: []
            },
            {
                columns: [
                    {
                        title: `DEL ${moment(this.state.FechaInicio).format("DD/MM/YYYY")} AL ${moment(this.state.FechaFin).format("DD/MM/YYYY")}`
                    }
                ],
                data: []
            },
            {
                columns: [
                    {
                        title: `FECHA EMISIÓN`,
                        width: {wpx: 100}
                    },
                    {
                        title: `CLIENTE`,
                        width: {wpx: 300}
                    },
                    {
                        title: `NUM DOC`,
                        width: {wpx: 150}
                    },
                    {
                        title: `DOCUMENTO `,
                        width: {wpx: 80}
                    },
                    {
                        title: `SERIE`,
                        width: {wpx: 80}
                    },
                    {
                        title: `NUM. COMPROBANTE `,
                        width: {wpx: 100}
                    },
                    {
                        title: `CANTIDAD  `,
                        width: {wpx: 55}
                    },
                    {
                        title: `PRECIO`,
                        width: {wpx: 100}
                    },
                    {
                        title: `TOTAL`,
                        width: {wpx: 100}
                    },
                    {
                        title: `PRECIO COSTO`,
                        width: {wpx: 100}
                    },
                    {
                        title: `UTILIDAD`,
                        width: {wpx: 100}
                    },
                    {
                        title: `DESC. ITEM.`,
                        width: {wpx: 100}
                    }
                ],
                data: dataSet.map(row => {
                    return [
                        {
                            value: moment(row.FechaEmision).format("DD/MM/YYYY"),
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.RazonSocial,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.NroTipoDocumento,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.TipoDocumento,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.Serie,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.NumeroComprobante,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.Cantidad,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.Precio,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.Total,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.PrecioCostoConIgv,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.totalUtilidad,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.descItems,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        }
                    ];
                })
            }
        ]
    }

    getSumatoriaDeItemsPorAttr(data, attr) {
        let sum = 0;
        data.forEach(i => sum += Number(i[attr]))
        return sum.toFixed(6);
    }

    onChangeTipoDoc(e) {
        this.setState({
            idsTipoDoc: e ? e.map(x => x.IdTipoDocumentoSunat) : []
        })
    }

    getSumCantidades(data) {
        let sum = 0;
        data.forEach(d => sum += (d.TipoCambio * d.Cantidad))
        return sum.toFixed(6);
    }

    searchDocs(query) {
        return new Promise((resolve, reject) => {
            this.setState({load: true})
            fetch(`/api/planesSunat/tipo-docs/?query=${query}`)
                .then(r => r.json())
                .then(docs => {
                    resolve(docs.filter(x => !x.Descripcion.includes("Nota") && x.Codigo !== "-1").map(d => {
                        return {...d, value: d.IdTipoDocumentoSunat, label: d.Descripcion}
                    }))
                }).catch(reject)
                .finally(() => this.setState({load: false}))
        });
    }

    updateTotales(data) {
        this.setState({
            totales: {
                cantidades: this.getSumCantidades(data),
                total: this.getSumatoriaDeItemsPorAttr(data, 'Total'),
                utilidad: this.getSumatoriaDeItemsPorAttr(data, 'totalUtilidad')
            }
        })
    }

    render() {
        let data = this.state.dataVentas.items;

        const dataExport = this.getDataExportExcel(data)
        if (this.state.isLoading === true) {
            return (
                <React.Fragment>
                    <div>
                        <PageLoading/>
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>

                    <div className="ProcesarBoton mt-1">
                        <BotonCancelar
                            onClick={this.props.history.goBack}
                            className="ml-4"
                        />

                        <div className="ProcesarBotones">

                            <BotonPDF
                                className="ml-2 mr-2"
                                title="Guardar PDF"
                                onClick={() => this.handleSavePDF()}
                                style={{display: `${this.state.displayButton}`}}
                            />

                            <ExcelFile
                                element={
                                    <BotonExcel
                                        type="button"
                                        title="Guardar Excel"
                                        style={{display: `${this.state.displayButton}`}}/>
                                }
                                filename={`VENTAS ${this.state.nombreProducto} / ${moment(this.state.FechaInicio).format("DD/MM/YYYY")} - ${moment(this.state.FechaFin).format("DD/MM/YYYY")}`}
                            >
                                <ExcelSheet
                                    dataSet={dataExport}
                                    name="kardexFile"
                                >
                                </ExcelSheet>
                            </ExcelFile>

                            <BotonLetras
                                className="mr-2 ml-2"
                                type="button"
                                disabled={this.state.load}
                                onClick={() => this.GetVentas()}
                            >
                                Cargar
                            </BotonLetras>
                        </div>

                    </div>

                    <div className="ProcesarBotones">
                        <label className="checkbox-togglePreventa">
                            <label className="mr-2 mt-1">Stock con Comprobante</label>
                            <div className="togglePreventa mr-1">
                                <input
                                    type="checkbox"
                                    name="StockConComprobante"
                                    className="toggle-state"
                                    onChange={this.onChangeCheckBox}
                                    checked={this.state.StockConComprobante}
                                />
                                <div className="togglePreventa-inner">
                                    <div className="indicator"/>
                                </div>
                                <div className="active-bg"/>
                            </div>
                        </label>
                    </div>

                    <div className="kardex__container">
                        <div className="kardex">
                            <div className="form-group reporteVentasProd">
                                <div className="mb-4">
                                    <b className="ml-4">*Fecha Inicio</b>
                                    <div className="KardexDocumento ">
                                        <div className="input__KardexDivSmall">
                                            <input
                                                className="FechaInicio input__linea"
                                                name="FechaInicio"
                                                onChange={this.FechaChange}
                                                type="date"
                                                disabled={this.state.load}
                                                value={this.state.FechaInicio}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <b className="ml-4">*Fecha Fin</b>
                                    <div className="KardexDocumento ">
                                        <div className="input__KardexDivSmall">
                                            <input
                                                className="FechaFin input__linea"
                                                name="FechaFin"
                                                onChange={this.FechaChange}
                                                disabled={this.state.load}
                                                type="date"
                                                value={this.state.FechaFin}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.sucursales.length ? (
                                    <div>
                                        <label htmlFor="idSucursal">Sucursal</label>
                                        <select name="idSucursal" id="idSucursal"
                                                className="form-control"
                                                value={this.state.idSucursal}
                                                onChange={this.onIdSucursalChange}
                                                required
                                                style={{borderStyle: "solid"}}
                                                placeholder="Seleccione una sucursal">
                                            <option value="0" key={"opt_0"}>Seleccione sucursal</option>
                                            {[...this.state.sucursales].map(s => (
                                                <option key={`opt_${s.IdSucursal}`}
                                                        value={s.IdSucursal}>{s.Nombre}</option>))}
                                        </select>
                                    </div>
                                ) : null
                            }

                            <div className="mt-3">
                                <label className="">Busque los productos</label>
                                <AsyncSelect
                                    defaultOptions={true}
                                    onChange={this.handleProductoChange}
                                    loadOptions={this.searchProductos}
                                    isLoading={this.state.load}
                                    placeholder={"Buscar productos"}
                                />
                            </div>

                            <div className="mt-3">
                                <label className="">Seleccione documentos (Si no se selecciona, son todos)</label>
                                <AsyncSelect
                                    defaultOptions={true}
                                    onChange={this.onChangeTipoDoc}
                                    loadOptions={this.searchDocs}
                                    isClearable={true}
                                    isLoading={this.state.load}
                                    placeholder={"Seleccione documentos"}
                                    isMulti
                                />
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-auto">
                                    Sumatoria de cantidades
                                    vendidas: {this.state.totales.cantidades}
                                </div>
                                <div className="col-sm-auto">
                                    Sumatoria de total: {this.state.totales.total}
                                </div>
                                <div className="col-sm-auto">
                                    Sumatoria de utilidad: {this.state.totales.utilidad}
                                </div>
                            </div>

                            <ReactTable
                                data={data}
                                filterable
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value
                                }
                                onFilteredChange={(filters) => {
                                    let tmpData = [...data]
                                    for (let i = 0; i < filters.length; i++) {
                                        if (filters[i].id === "Serie") {
                                            let query = String(filters[i].value).split(',').map(x => x.toLowerCase())
                                            tmpData = tmpData.filter(x => query.includes(String(x[filters[i].id]).toLowerCase()))
                                        } else
                                            tmpData = tmpData.filter(x => String(x[filters[i].id]).toLowerCase().includes(String(filters[i].value).toLowerCase()))
                                    }

                                    this.updateTotales(tmpData)
                                }}
                                columns={[
                                    {
                                        columns: [
                                            {
                                                Header: "Fecha Emisión",
                                                id: "FechaEmision",
                                                headerClassName: "",
                                                width: 100,
                                                accessor: d => moment(d.FechaEmision).format('DD-MM-YYYY'),
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["FechaEmision"]}),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Cliente",
                                                id: "RazonSocial",
                                                headerClassName: "",
                                                width: 100,
                                                accessor: d => d.RazonSocial,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["RazonSocial"]}),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Num Documento",
                                                id: "NroTipoDocumento",
                                                headerClassName: "",
                                                width: 150,
                                                accessor: d => d.NroTipoDocumento,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["NroTipoDocumento"]}),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Documento",
                                                id: "TipoDocumento",
                                                headerClassName: "",
                                                width: 80,
                                                accessor: d => d.TipoDocumento,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["TipoDocumento"]}),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Serie",
                                                id: "Serie",
                                                headerClassName: "",
                                                width: 80,
                                                accessor: d => d.Serie,
                                                filterMethod: (filter, rows) => {
                                                    let query = String(filter.value).split(',').map(x => x.toLowerCase())
                                                    return rows.filter(x => query.includes(String(x.Serie).toLowerCase()))
                                                },
                                                filterAll: true
                                            },
                                            {
                                                Header: "Num Comprobante",
                                                id: "NumeroComprobante",
                                                headerClassName: "",
                                                width: 100,
                                                accessor: d => d.NumeroComprobante,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["NumeroComprobante"]}),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Cantidad",
                                                id: "Cantidad",
                                                headerClassName: "",
                                                width: 55,
                                                accessor: d => (d.Cantidad * d.TipoCambio),
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["Cantidad"]}),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Precio Venta",
                                                id: "Precio",
                                                headerClassName: "",
                                                width: 100,
                                                accessor: d => d.Precio,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["Precio"]}),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Total",
                                                id: "Total",
                                                headerClassName: "",
                                                width: 100,
                                                accessor: d => d.Total,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["Total"]}),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Precio costo con IGV",
                                                id: "PrecioCostoConIgv",
                                                headerClassName: "",
                                                width: 100,
                                                accessor: d => d.PrecioCostoConIgv,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["PrecioCostoConIgv"]}),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Utilidad con IGV",
                                                id: "totalUtilidad",
                                                headerClassName: "",
                                                width: 100,
                                                accessor: d => d.totalUtilidad,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["totalUtilidad"]}),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Desc. Item.",
                                                id: "descItems",
                                                headerClassName: "",
                                                width: 80,
                                                accessor: d => d.descItems,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["descItems"]}),
                                                filterAll: true
                                            },
                                            {
                                                Header: "¿Es Gratuito?",
                                                id: "esFree",
                                                width: 80,
                                                filterable: false,
                                                accessor: d => d.esFree ? "Sí" : "No",
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["esFree"]}),
                                                filterAll: false
                                            },
                                        ]
                                    }
                                ]}
                                defaultPageSize={20}
                                className="-striped -highlight"
                                previousText="Anterior"
                                nextText="Siguiente"
                                loadingText="Cargando..."
                                noDataText="No se encontraron registros"
                                pageText="Página"
                                ofText="de"
                                rowsText="filas"
                                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                            />

                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
}

export default VentasPorProducto;

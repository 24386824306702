import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";


import OrdenCompraForm from "./OrdenCompraForm";

import {calcularVenta, setPreciosYDescuento, setValorUnitario} from "../../helpers/calcularVenta";


class OrdenCompraEditar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,

            data: [],
            dataAlmacenes: [],
            dataMoneda: [],
            objProveedor: [],
            dataDetalleOC: [],
            prod: {},
            form: {
                accion: null,
                modalProveedorIsOpen: false,
                FechaActual: new Date(),
                //datos item seleccionado
                id_SelectSearch: null,
                PrecioCosto: 0.00,
                Cantidad: 0.00,
                IdUnidad: null,
                IdPresentacion: null,
                IdProducto: null,
                respuestaInsert: null,

                inactivo: false,

                IdOrdenCompra: null,
                NroOC: '',
                IdProveedor: null,
                Ruc: '',
                NomProveedor: '',
                NomContacto: '',
                FechaRegistro: new Date(),
                FechaEmision: new Date(),
                FechaEntrega: new Date(),
                IdAlmacen: '0',
                IdMoneda: '1',
                IncluyeIGV: true,
                TipoCambio: 1.0000,
                FormaPago: 'Contado',
                Estado: 'Sin Generar',
                Glosa: '',

                Total: 0.00,
                SubTotal: 0.00,
                IGV: 0.00,

                vTotal: 0.00,
                vSubTotal: 0.00,
                vIGV: 0.00
            }
        }
        this.handleEliminarItem = this.handleEliminarItem.bind(this);
    }

    componentDidMount() {
        this.inicio();
    }

    inicio = async () => {
        if (this.props.accion === 'Editar')
            await this.traerOrdenCompraPorId();
        await this.listaAlmacenes();
        await this.listaMonedas();
        await this.listaDetalleOC();
    }

    calcularTotales(items, incIGV) {
        let lista = []
        if (incIGV) {
            lista = items.map(item => {
                const {
                    Cantidad,
                    PrecioReferencial,
                } = item
                return ({
                    PrecioVenta: parseFloat(PrecioReferencial),
                    Tributos: [1],
                    TasaISC: 0,
                    Cantidad: parseFloat(Cantidad),
                    Gratuito: 0,
                    Descuento: 0,
                    IdAfectacionIgv: 1
                })
            })
        } else {
            lista = items.map(item => {
                const {
                    Cantidad,
                    PrecioReferencial,
                } = item
                return ({
                    ValorUnitario: parseFloat(PrecioReferencial),
                    Tributos: [1],
                    TasaISC: 0,
                    Cantidad: parseFloat(Cantidad),
                    Gratuito: 0,
                    Descuento: 0,
                    IdAfectacionIgv: 1
                })
            })
        }

        const detalle = {
            Items: lista
        }

        const totalesOC = calcularVenta(detalle);

        this.setState({
            form: {
                ...this.state.form,
                vSubTotal: totalesOC.Gravadas,
                vIGV: totalesOC.IGV,
                vTotal: totalesOC.Total,

                SubTotal: totalesOC.Gravadas.toLocaleString("en", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }
                ),
                IGV: totalesOC.IGV.toLocaleString("en", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }
                ),
                Total: totalesOC.Total.toLocaleString("en", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }
                ),

            }
        })
    }

    handleSubmit = async (e) => {
        // e.preventDefault();
        //
        // 1. validar datos de oc (IdProveedor, IdAlmacen, FechaEmision, FechaEntrega)
        if (this.state.form.IdProveedor && this.state.form.IdAlmacen != '0') {
            if (this.props.accion === 'Nuevo') {
                await this.crearOrdenCompra();
                await this.props.listaOrdenesCompraPorSucursal();
                //
            } else {
                await this.actualizarOrdenCompra();
            }


        } else {
            // 3. alerta de información incompleta
            const r = this.props.accion === 'Nuevo' ? "Crear" : "Actualizar";
            swal({
                title: 'Atención.!',
                text: `No se puede ${r} la Orden de Compra, información incompleta...`,
                icon: 'error'
            })
        }

    };

    traerOrdenCompraPorId = async () => {
        //
        this.setState({loading: true, error: null});
        try {
            const idOrdenCompra = this.props.itemordencompra.idordencompra ?
                this.props.itemordencompra.idordencompra : this.state.form.IdOrdenCompra;

            const response = await fetch(`/api/ordenescompra/item/${idOrdenCompra}`);
            const regoc = await response.json();
            this.setState({
                loading: false,
                form: {
                    ...this.state.form,

                    IdOrdenCompra: regoc[0][0].IdOrdenCompra,
                    NroOC: regoc[0][0].Numero,
                    IdProveedor: regoc[0][0].IdProveedor,
                    Ruc: regoc[0][0].RucProveedor,
                    NomProveedor: regoc[0][0].NombreProveedor,
                    NomContacto: regoc[0][0].Contacto,
                    FechaEmision: new Date(regoc[0][0].FechaEmision + "T00:00:00.000-05:00"),
                    FechaEntrega: new Date(regoc[0][0].FechaEntrega + "T00:00:00.000-05:00"),
                    IdAlmacen: regoc[0][0].IdAlmacen,
                    IdMoneda: regoc[0][0].IdMoneda,
                    IncluyeIGV: regoc[0][0].IncluyeIGV == 'Si' ? true : false,
                    TipoCambio: regoc[0][0].TipoCambio,
                    FormaPago: regoc[0][0].FormaPago,
                    Estado: regoc[0][0].Estado,
                    Glosa: regoc[0][0].Glosa,
                    SubTotal: regoc[0][0].SubTotal,
                    IGV: regoc[0][0].IGV,
                    Total: regoc[0][0].Total,
                }
            });


        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    crearOrdenCompra = async () => {
        try {
            //
            const config = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IdOrdenCompra: '1',
                    FechaRegistro: this.formatDate(this.state.form.FechaRegistro, 'yyyy-mm-dd'),
                    FechaEmision: this.formatDate(this.state.form.FechaEmision, 'yyyy-mm-dd'),
                    FechaEntrega: this.formatDate(this.state.form.FechaEntrega, 'yyyy-mm-dd'),
                    Serie: this.state.form.Serie,
                    Numero: this.state.form.NroOC,
                    IdMoneda: this.state.form.IdMoneda,
                    TipoCambio: this.state.form.TipoCambio,
                    IdUsuario: 1, // se controla en el back
                    IdSucursal: 1, // se controla en el back
                    Estado: 'Generada',
                    IdAlmacen: this.state.form.IdAlmacen,
                    Glosa: this.state.form.Glosa,
                    IdProveedor: this.state.form.IdProveedor,
                    FormaPago: this.state.form.FormaPago,
                    IncluyeIGV: this.state.form.IncluyeIGV ? 'Si' : 'No',
                    Contacto: this.state.form.NomContacto,
                    SubTotal: this.state.form.vSubTotal,
                    IGV: this.state.form.vIGV,
                    Total: this.state.form.vTotal,
                })
            };
            //
            const response = await fetch(`/api/ordenescompra/add/`, config);
            const rpta = await response.json();

            if (parseInt(rpta.respuesta) !== -1) // creó correctamente
            {
                this.setState({
                    form: {
                        ...this.state.form,
                        IdOrdenCompra: rpta.respuesta,
                    }
                })
                await this.props.cambiarAccion('Editar', rpta.respuesta);
                await this.props.listaOrdenesCompraPorSucursal();
                //
                swal({
                    title: 'Atención..!',
                    text: `Se Creó correctamente la Orden de Compra...`,
                    icon: 'success'
                })

            } else {
                swal({
                    title: 'Atención..!',
                    text: `Error al Crear la Orden de Compra...`,
                    icon: 'error'
                })
            }


        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    };

    actualizarOrdenCompra = async () => {
        try {
            //
            const config = {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IdOrdenCompra: this.state.form.IdOrdenCompra,
                    FechaEmision: this.formatDate(this.state.form.FechaEmision, 'yyyy-mm-dd'),
                    FechaEntrega: this.formatDate(this.state.form.FechaEntrega, 'yyyy-mm-dd'),
                    Serie: this.state.form.Serie,
                    Numero: this.state.form.NroOC,
                    IdMoneda: this.state.form.IdMoneda,
                    TipoCambio: this.state.form.TipoCambio,
                    IdUsuario: 1, // se controla en el back
                    IdSucursal: 1, // se controla en el back
                    Estado: this.state.form.Estado,
                    IdAlmacen: this.state.form.IdAlmacen,
                    Glosa: this.state.form.Glosa,
                    IdProveedor: this.state.form.IdProveedor,
                    FormaPago: this.state.form.FormaPago,
                    IncluyeIGV: this.state.form.IncluyeIGV ? 'Si' : 'No',
                    Contacto: this.state.form.NomContacto,
                    SubTotal: this.state.form.vSubTotal,
                    IGV: this.state.form.vIGV,
                    Total: this.state.form.vTotal,
                })
            };
            // console.log(config)
            //
            const res = await fetch(`/api/ordenescompra/update/${this.state.form.IdOrdenCompra}`, config);
            const rpta = await res.json()


            if (parseInt(rpta.respuesta) !== -1) // actualizó correctamente
            {
                await this.props.listaOrdenesCompraPorSucursal();
                swal({
                    title: 'Atención..!',
                    text: `Se Actualizó correctamente la Orden de Compra...`,
                    icon: 'success'
                })
            } else {
                swal({
                    title: 'Atención..!',
                    text: `Error al Actualizar la Orden de Compra...`,
                    icon: 'error'
                })
            }
            this.props.onClose();


        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }

    }

    eliminarOrdenCompra = async (id) => {
        try {
            if (id) {
                const response = await fetch(`/api/ordenescompra/delete/${id}`, {
                    method: "DELETE",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                });
                const rpta = await response.json();

                if (rpta) {
                    swal({
                        title: 'Atención...! ',
                        text: `Orden de Compra cancelado correctamente...`,
                        icon: 'info'
                    })
                    // this.listaDetalleOC();
                    await this.props.listaOrdenesCompraPorSucursal();
                }
                //
                console.log(rpta);
            }
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    handleChangeForm = (e) => {
        // //
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,

            },
        });
    }

    handleChangeFechaEmision = (date) => {
        this.setState({
            form: {
                ...this.state.form,
                FechaEmision: date,

            },
        });
    }

    handleChangeFechaEntrega = (date) => {
        this.setState({
            form: {
                ...this.state.form,
                FechaEntrega: date,
            },
        });
    }

    formatDate = (date, formatstring) => {
        //
        switch (formatstring) {
            case 'dd-mm-yyyy':
                return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
                break;
            case 'yyyy-mm-dd':
                return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                break;
            default:
                return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`
            // code block
        }
    }

    handleChange_SelectSearch = (selectedOption) => {
        this.setState({
            form: {
                ...this.state.form,
                id_SelectSearch: selectedOption.value,
                PrecioCosto: selectedOption.PrecioCosto,
                IdUnidad: selectedOption.IdUnidad,
                IdPresentacion: selectedOption.IdPresentacion,
                IdProducto: selectedOption.IdProducto
            },
            prod: selectedOption
        });
    }

    handleChangeAlmacenes = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
        // console.log(e.target.name, e.target.value)
    };

    handleChangeCheckBox = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.checked

            },
        });
    }

    handleChangeTipoCambio = (e) => {
        // //
        this.setState({
            form: {
                ...this.state.form,
                TipoCambio: e

            },
        });
    }

    handleChangePrecioCosto = (e) => {
        // //
        this.setState({
            form: {
                ...this.state.form,
                PrecioCosto: e
            }
        });
    }

    handleChangeCantidad = (e) => {
        // //
        this.setState({
            form: {
                ...this.state.form,
                Cantidad: e
            }
        });
    }

    handleClickRuc = async (e) => {
        e.preventDefault();
        if (this.state.form.Ruc.toString().length != 11) {
            swal({
                title: 'Atención...! ',
                text: `Ingrese un nro de RUC válido de 11 dígitos a buscar...`,
                icon: 'error'
            })
        } else {
            //
            await this.traerProveedor(this.state.form.Ruc)
            if (this.state.objProveedor.length === 0) {
                swal({
                    title: 'Atención...! ',
                    text: `El proveedor no existe desea crear uno nuevo?`,
                    icon: 'error',
                    buttons: [
                        'No',
                        'Si'
                    ],
                }).then((isConfirm) => {
                    if (isConfirm) {
                        this.handleClickNuevoProveedor()
                        // swal({
                        //     title:'Llamar nuevo proveedor'
                        // })
                    } else {
                    }
                })
            }
        }
    }

    handleClickNuevoProveedor = () => {
        // e.preventDefault();
        // console.log('ingreso')
        this.setState({
            form: {
                ...this.state.form,
                modalProveedorIsOpen: true,
                accion: 'NuevoProveedor'
            }
        })

    }

    handleClickBuscarProveedor = () => {
        // console.log('ingreso')
        this.setState({
            form: {
                ...this.state.form,
                modalProveedorIsOpen: true,
                accion: 'BuscarProveedor'
            }
        })
    }

    AsignarValorDoc = (dataProveedor) => {
        console.log(dataProveedor)
        //
        this.setState({
            form: {
                ...this.state.form,
                IdProveedor: dataProveedor.IdProveedor,
                Ruc: dataProveedor.Ruc,
                NomProveedor: dataProveedor.RazonSocial,
                NomContacto: dataProveedor.Contacto.toString().toUpperCase(),
            },
        });

    }

    listaAlmacenes = async () => {
        this.setState({loading: true, error: null});
        try {
            // const response = await fetch(`/api/clientes/${this.state.buscar}`);
            // //;
            const response = await fetch(`/api/almacenes/AlmacenBySucursal`);
            const data = await response.json();
            // console.log(data);
            this.setState({
                loading: false,
                dataAlmacenes: data.results,
            });
            // console.log(this.state.dataAlmacenes);
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    listaDetalleOC = async () => {
        this.setState({loading: true, error: null});
        const idOrden = this.props.itemordencompra.idordencompra ? this.props.itemordencompra.idordencompra
            : this.state.form.IdOrdenCompra;

        try {
            const response = await fetch(`/api/ordenescompra/detalleoc/${idOrden}`);
            const data = await response.json();
            this.setState({
                loading: false,
                dataDetalleOC: data[1],
            });
            this.calcularTotales(data[1], this.state.form.IncluyeIGV)
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    listaMonedas = async () => {
        this.setState({loading: true, error: null});
        try {
            // //
            const response = await fetch(`/api/clientes/monedas/`);
            const data = await response.json();
            // console.log(data)
            this.setState({
                loading: false,
                dataMoneda: data.results,

            });
            // console.log(this.state.dataMoneda)
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    };

    traerProveedor = async (Ruc) => {
        this.setState({loading: true, error: null});
        try {
            // //
            const response = await fetch(`/api/proveedores/ruc/${Ruc}`);
            const data = await response.json();
            console.log(data[1][0])
            this.setState({
                loading: false,
                objProveedor: data[1],
                form: {
                    ...this.state.form,
                    IdProveedor: data[1][0].IdProveedor,
                    NomProveedor: data[1][0].RazonSocial,
                    NomContacto: data[1][0].Contacto
                },

            });
            // console.log(this.state.dataMoneda)
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    handleEliminarItem(reg) {
        // reg.preventDefault();
        swal({
            title: 'Atención...! ',
            text: `Desea Eliminar el item ${reg.NomProducto}?`,
            icon: 'error',
            buttons: [
                'No',
                'Si'
            ],
        }).then((isConfirm) => {
            if (isConfirm) {
                this.eliminarItem(reg);
            }
        })

    }

    eliminarItem = async (reg) => {
        var id = reg.IdDetalleOC
        console.log(reg);
        try {
            if (id) {
                const response = await fetch(`/api/ordenescompra/detalleoc/delete/${id}`, {
                    method: "DELETE",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                });
                const rpta = await response.json();

                if (rpta) {
                    swal({
                        title: 'Atención...! ',
                        text: `Item eliminado correctamente...`,
                        icon: 'success'
                    })
                    this.listaDetalleOC();
                }
                //
                console.log(rpta);
            }
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }

    }

    handleAgregarItem = async () => {
        if (!this.state.form.IdProducto) {
            swal({
                title: 'Atención...! ',
                text: `Elija un Producto...`,
                icon: 'error'
            })

        } else if (parseFloat(this.state.form.Cantidad) <= 0 || parseFloat(this.state.form.Precio) <= 0) {
            swal({
                title: 'Atención...! ',
                text: `Verifique valores de Precio y Cantidad...`,
                icon: 'error'
            })
        } else {
            try {
                const config = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        IdDetalleOC: "1",
                        IdOrdenCompra: this.state.form.IdOrdenCompra,
                        IdProducto: this.state.form.IdProducto,
                        PrecioReferencial: this.state.form.PrecioCosto,
                        Cantidad: this.state.form.Cantidad,
                        IdPresentacion: this.state.form.IdPresentacion,
                        Total: parseFloat(this.state.form.PrecioCosto) * parseFloat(this.state.form.Cantidad),
                        IdUnidad: this.state.form.IdUnidad
                    })
                };
                //
                const response = await fetch(`/api/ordenescompra/detalleoc/add/`, config);
                const rpta = await response.json();
                if (rpta[0][0][0].estado == 'Insertado') {
                    await this.listaDetalleOC();
                    swal({
                        title: 'Atención...! ',
                        text: `Item agregado con éxito...`,
                        icon: 'success'
                    })
                } else if (rpta[0][0][0].estado == 'Duplicado') {
                    swal({
                        title: 'Atención...! ',
                        text: `El item elegido ya está en lista...`,
                        icon: 'error'
                    })
                }

            } catch (error) {
                this.setState({
                    loading: false,
                    error: error,
                });
            }

        }
    };

    handleCloseModalProv = () => {
        this.setState({
            form: {
                ...this.state.form,
                modalProveedorIsOpen: false,
            }
        })
    }

    render() {
        return (
            <OrdenCompraForm
                // accion={this.state.form.accion}
                accion={this.props.accion}

                handleCloseModalOC={this.props.onClose}
                handleCloseModalProv={this.handleCloseModalProv}

                form={this.state.form}
                data={this.state.data}
                dataAlmacenes={this.state.dataAlmacenes}
                dataMoneda={this.state.dataMoneda}
                objProveedor={this.state.objProveedor}
                dataDetalleOC={this.state.dataDetalleOC}

                prod={this.state.prod}

                handleChangeForm={this.handleChangeForm}
                handleChangeFechaEmision={this.handleChangeFechaEmision}
                handleChangeFechaEntrega={this.handleChangeFechaEntrega}
                handleChange_SelectSearch={this.handleChange_SelectSearch}
                handleChangeAlmacenes={this.handleChangeAlmacenes}
                handleChangeCheckBox={this.handleChangeCheckBox}
                handleChangeTipoCambio={this.handleChangeTipoCambio}
                handleChangePrecioCosto={this.handleChangePrecioCosto}
                handleChangeCantidad={this.handleChangeCantidad}
                handleSubmit={this.handleSubmit}

                handleClickRuc={this.handleClickRuc}
                handleClickNuevoProveedor={this.handleClickNuevoProveedor}
                handleClickBuscarProveedor={this.handleClickBuscarProveedor}
                AsignarValorDoc={this.AsignarValorDoc}
                listaAlmacenes={this.listaAlmacenes}
                listaDetalleOC={this.listaDetalleOC}
                listaMonedas={this.listaMonedas}
                traerProveedor={this.traerProveedor}
                handleEliminarItem={this.handleEliminarItem}
                handleAgregarItem={this.handleAgregarItem}

            />
        )
    }
}

export default OrdenCompraEditar;

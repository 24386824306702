import React from "react";
import TableToExcel from "@linways/table-to-excel";
import FiltroReportDocSunat from "../ReporteDocSunat/FiltroReportDocSunat";
import {notificarError} from "../Almacenes/AlmacenNotify";
import moment from "moment";
import {convertObjToQueryParams} from "../../helpers/utils";
import {isValidDate} from "../../helpers/validators";
import {decimalAdjust, ESTADO_CE} from "../../Global";

export default class ReporteVentasCont extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            dataReport: {ventas: []},
            sucursal: null,
            showSucursales: false,
        }
        this.onFilterPressedHandler = this.onFilterPressedHandler.bind(this);
        this.exportData = this.exportData.bind(this);
        this.setCanChangeSucursal = this.setCanChangeSucursal.bind(this);
    }

    componentDidMount() {
        this.setCanChangeSucursal();
    }

    async setCanChangeSucursal() {
        let res = await fetch('/api/configFactElectronica/ChangeSucursal')
        this.setState({isLoading: true})
        if (res.ok)
            this.setState({showSucursales: true})
        else {
            const data = await this.getSessionSucursal()
            this.setState({showSucursales: false, sucursal: data ? data.sucursal : data})
        }
        this.setState({isLoading: false})
    }

    async getSessionSucursal() {
        let res = await fetch('/api/sucursales/get-session-sucursal')
        if (res.ok)
            return await res.json()
        notificarError("No se pudo obtener la sucursal en sesión")
        return null
    }

    async onFilterPressedHandler(params) {

        if (isValidDate(params.fechaInicio) && isValidDate(params.fechaFin))
            params._sucursales && params._sucursales.length ? await this.doReport(params)
                : notificarError("Debe seleccionar al menos una sucursal.")

        else
            notificarError("Las fechas ingresadas son incorrectas.")

    }

    async doReport(params) {
        const ventas = await this.fetchVentas(params);

        await this.setState({
            dataReport: {
                fechaInicio: params.fechaInicio,
                sucursales: params._sucursales.map(s => s.Nombre).join(','),
                ruc: params._sucursales[0].Ruc,
                periodo: moment(params.fechaInicio).format('MMM-YY'),
                ventas: ventas ? ventas : [],
                fileName: `reporte_ventas_${params.fechaInicio || ""}_${params.fechaFin || ""}.xlsx`
            },
            isLoading: true,
        })

        this.setState({isLoading: false})
    }

    async exportData() {
        const tablaVentas = document.getElementById('tabla-ventas')

        TableToExcel.convert(tablaVentas, {
            name: this.state.dataReport.fileName, sheet: {
                name: "REPORTE_VENTAS"
            }
        })
    }

    async fetchVentas(params) {
        let res = await fetch(`/api/contable/reporte-ventas?${convertObjToQueryParams(params)}`)
        if (res.ok)
            return await res.json()
        notificarError("No se pudo obtener los datos")
        return null;
    }

    render() {
        const data = this.state.dataReport;

        return (
            <div className="container mt-3">

                <div className="alert alert-warning">
                    La duración de descarga del reporte es directamente proporcional a la cantidad de días y registros
                    comprendidos entre la fecha de inicio y fin.
                </div>

                <FiltroReportDocSunat showSucursales={this.state.showSucursales} sucursal={this.state.sucursal}
                                      customQueryParams={{isForVenta: "1"}} isLoading={this.state.isLoading}
                                      onFilterPressed={this.onFilterPressedHandler}/>

                {this.state.isLoading ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status"/>
                    </div>
                ) : null}


                {data.ventas.length ?
                    <button onClick={this.exportData} className="boton__verdeOscuro mt-3" style={{float: "right"}}>
                        <i className="fas fa-file-excel"/>
                    </button> :
                    <div className="alert alert-info mt-3">
                        No se han encontrado registros.
                    </div>}


                <table id="tabla-ventas" hidden={true}
                       data-cols-width="14,14,14,15,15,15,16,16,16,20,20,20,20,20,20,20,20,20,15,15,15,20">
                    <tr>
                        <td data-f-bold={true}>FORMATO 14.1: REGISTRO DE VENTA E INGRESOS</td>
                    </tr>
                    <tr></tr>
                    <tr>
                        <td data-f-bold={true}>PERIODO:</td>
                        <td>{data.periodo}</td>
                    </tr>
                    <tr>
                        <td data-f-bold={true}>RUC:</td>
                        <td>{data.ruc}</td>
                    </tr>
                    <tr>
                        <td data-f-bold={true}>APELLIDOS Y NOMBRES, DENOMINACIÓN O RAZÓN SOCIAL:</td>
                        <td>{data.sucursales}</td>
                    </tr>
                    <tr></tr>
                    <tr data-height="114">
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={3} data-a-wrap={true}>
                            NÚMERO CORRELATIVO DEL REGISTRO O CÓDIGO ÚNICO DE LA OPERACIÓN
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={3} data-a-wrap={true}>
                            FECHA DE EMISIÓN DEL COMPROBANTE DE PAGO O DOCUMENTO
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={3} data-a-wrap={true}>
                            FECHA DE VENCIMIENTO O FECHA DE PAGO
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} colSpan={3}>
                            COMPROBANTE DE PAGO O DOCUMENTO
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} colSpan={3}>
                            INFORMACIÓN DEL CLIENTE
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={3} data-a-wrap={true}>
                            VALOR FACTURADO DE LA EXPORTACIÓN
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={3} data-a-wrap={true}>
                            BASE IMPONIBLE DE LA OPERACIÓN GRAVADA
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} colSpan={2} data-a-wrap={true}>
                            IMPORTE TOTAL DE LA OPERACIÓN EXONERADA O INAFECTA
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={3}>
                            ISC
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={3}>
                            IGV Y/O IPM
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={3} data-a-wrap={true}>
                            OTROS TRIBUTOS Y CARGOS QUE NO FORMAN PARTE DE LA BASE IMPONIBLE
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={3} data-a-wrap={true}>
                            IMPORTE TOTAL DEL COMPROBANTE DE PAGO
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={3}>
                            TIPO DE CAMBIO
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} colSpan={4} data-a-wrap={true}>
                            REFERENCIA DEL COMPROBANTE DE PAGO O DOCUMENTO ORIGINAL QUE SE MODIFICA
                        </td>
                    </tr>
                    <tr data-height="35">
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={2} data-a-wrap={true}>
                            TIPO (TABLA 10)
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={2} data-a-wrap={true}>
                            N° SERIE O N° DE SERIE DE LA MAQUINA REGISTRADORA
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={2}>
                            NÚMERO
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} colSpan={2}>
                            DOCUMENTO DE IDENTIDAD
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={2} data-a-wrap={true}>
                            APELLIDOS Y NOMBRES, DENOMINACIÓN O RAZÓN SOCIAL
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={2}>
                            EXONERADA
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={2}>
                            INAFECTA
                        </td>
                        <td data-f-bold={true} rowSpan={2} data-a-h="center" data-a-v="middle">
                            FECHA
                        </td>
                        <td data-f-bold={true} rowSpan={2} data-a-h="center" data-a-v="middle" data-a-wrap={true}>
                            TIPO (TABLA(10))
                        </td>
                        <td data-f-bold={true} rowSpan={2} data-a-h="center" data-a-v="middle">
                            SERIE
                        </td>
                        <td data-f-bold={true} rowSpan={2} data-a-h="center" data-a-v="middle" data-a-wrap={true}>
                            N° DEL COMPROBANTE DE PAGO O DOCUMENTO
                        </td>
                    </tr>
                    <tr data-height="35">
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true}>
                            TIPO (TABLA 2)
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true}>
                            NÚMERO
                        </td>
                    </tr>

                    {data.ventas.map((v, i) =>
                        <tr key={`row_data_${i}`}>
                            <td>
                                {`00${i + 1}-${moment(data.fechaInicio).format('MM-YYYY')}`}
                            </td>
                            <td>
                                {moment(v.fechaEmision).format("DD/MM/YYYY")}
                            </td>
                            <td>
                                {v.fechaPago ? moment(v.fechaPago).format("DD/MM/YYYY") : ""}
                            </td>
                            <td>
                                {v.codigo}
                            </td>
                            <td>
                                {v.serie}
                            </td>
                            <td>
                                {v.numero}
                            </td>
                            <td>
                                {Number(v.codDocCliente) > 10 ? v.codDocCliente : `0${v.codDocCliente}`}
                            </td>
                            <td>
                                {v.numDocCliente}
                            </td>
                            <td>
                                {v.razonSocial}
                            </td>
                            <td>

                            </td>
                            <td>
                                {decimalAdjust('floor', v.estadoCe === ESTADO_CE.ACEPTADO ? v.gravadas : 0, -2)}
                            </td>
                            <td>
                                {decimalAdjust('floor', v.estadoCe === ESTADO_CE.ACEPTADO ? v.exoneradas : 0, -2)}
                            </td>
                            <td>
                                {decimalAdjust('floor', v.estadoCe === ESTADO_CE.ACEPTADO ? v.inafectas : 0, -2)}
                            </td>
                            <td>

                            </td>
                            <td>
                                {decimalAdjust('floor', v.estadoCe === ESTADO_CE.ACEPTADO ? v.igv : 0, -2)}
                            </td>
                            <td>
                                {decimalAdjust('floor', v.estadoCe === ESTADO_CE.ACEPTADO ? v.icbper : 0, -2)}
                            </td>
                            <td>
                                {decimalAdjust('floor', v.estadoCe === ESTADO_CE.ACEPTADO ? v.total : 0, -2)}
                            </td>
                            <td>

                            </td>
                            <td>
                                {v.fechaDocAfectado ? moment(v.fechaDocAfectado).format("DD/MM/YYYY") : ""}
                            </td>
                            <td>
                                {v.codDocAfectado ? v.codDocAfectado : ""}
                            </td>
                            <td>
                                {v.serieDocAfectado ? v.serieDocAfectado : ""}
                            </td>
                            <td>
                                {v.numDocAfectado ? v.numDocAfectado : ""}
                            </td>
                        </tr>
                    )}

                </table>
            </div>
        )
    }
}

import React from "react";
import {
    BOLETA, calcTotal,
    calculateCotizaciones,
    decimalAdjust,
    FACTURA,
    ICBPER,
    IGV,
    NUMBER_KEYS,
    TIPO_STOCK,
    VALE
} from "../../Global";
import BuscarClientePorDoc from "../../componentes/Preventas/BuscarClientePorDoc";
import ListaProductosPreventa from "../../componentes/Preventas/ListaProductosPreventa";
import ModalCliente from "../../componentes/clientes/ClienteModal";
import ClienteNuevo from "../../componentes/clientes/ClientesNuevo";
import Modal from "../../componentes/Modal";
import ModalBuscarCliente from "../../componentes/Preventas/ModalBuscarCliente";
import _ from "lodash";
import {
    FindPrecioEspecial,
    FindPrecioFamiliar, FindPrecioMenor,
    FindPrecioPorMayor,
    GetPrecioCosto, getPrecioPorMayor
} from "../../componentes/Preventas/PreciosPreventa";
import {
    setPreciosYDescuento,
    setValorUnitario
} from "../../helpers/calcularVenta";
import $ from "jquery";
import DetalleCotizaciones from "../../componentes/Cotizaciones/DetalleCotizaciones";
import {
    notificationCantidadNegativa,
    notificationNoHayProducto,
} from "../../componentes/Preventas/PreVentaNotify";
import Noty from "noty";
import moment from "moment";
import {notificarError} from "../../componentes/Almacenes/AlmacenNotify";
import {isNumber} from "../../helpers/utils";

export default class CrearCotizacion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            loading: true,
            error: null,
            buscar: "",
            IdCliente: "",
            dataProductos: {
                productos: []
            },
            Documento: "99999999",
            dataCliente: [],
            modalClienteIsOpen: false,
            totalMonto: 0,
            Gravado: 0,
            Inafecto: 0,
            ICBPERPreventa: 0,
            Exonerado: 0,
            IGVPreventa: 0,
            Gratuitas: 0,
            IVAP: 0,
            ISC: 0,
            cotizacionId: "",
            IdAlmacen: null,
            almacenes: {
                resuts: []
            },
            IdMoneda: 1,
            TasaIGV: IGV,
            TasaICBPER: ICBPER,
            Total: 1,
            IdTipoDocumentoSunat: 4,
            descripcion: "",
            RUSS: "",
            statusModal: false,
            statusModalSearchCliente: false,
            detalles: [],
            searchDisabled: false,
            load: false,
            redondeo: 0,
            afectsIgv: [],
            inputName: "",
            Alias: "",
        }
        this.getProductos = this.getProductos.bind(this);
        this.handleChangeBuscar = this.handleChangeBuscar.bind(this);
        this.AgregarCantidadDet = this.AgregarCantidadDet.bind(this)
        this.RestarCantidadDet = this.RestarCantidadDet.bind(this)
        this.RemoveProductoInList = this.RemoveProductoInList.bind(this)
        this.PrecioChange = this.PrecioChange.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleOnCloseModal = this.handleOnCloseModal.bind(this)
        this.handleClickBuscar = this.handleClickBuscar.bind(this)
        this.handleCloseBuscar = this.handleCloseBuscar.bind(this)
        this.handleKeyUp = this.handleKeyUp.bind(this)
        this.AsignarValorDoc = this.AsignarValorDoc.bind(this)
        this.handleEnterKeyUp = this.handleEnterKeyUp.bind(this);
        this.onSecretKeyPress = this.onSecretKeyPress.bind(this);
        this.onBlurCantidad = this.onBlurCantidad.bind(this);
        this.onBlurPrecioVenta = this.onBlurPrecioVenta.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.GetCliente = this.GetCliente.bind(this);
        this.handleClickNuevo = this.handleClickNuevo.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.setActiveInputDoc = this.setActiveInputDoc.bind(this);
        this.DocChange = this.DocChange.bind(this);
        this.AliasChange = this.AliasChange.bind(this);
        this.AddProductoInList = this.AddProductoInList.bind(this);
        this.InputCantidadDetalleChange = this.InputCantidadDetalleChange.bind(this);
        this.precioVentaChange = this.precioVentaChange.bind(this);
        this.InputPrecioVentaChange = this.InputPrecioVentaChange.bind(this);
        this.saveCotizacion = this.saveCotizacion.bind(this);
    }

    async componentDidMount() {
        this.getRUSS();
        this.getProductos();
        await Promise.all([
            await this.TraerClientePorDoc(this.state.Documento),
        ]).then(() => this.newCotizacion())
    }

    handleOpenModal() {
        this.setState({
            statusModal: true
        });
    }

    handleOnCloseModal() {
        this.setState({
            statusModal: false
        });
    }

    async newCotizacion() {
        this.setState({loading: true, error: null});
        let cotizaId = window.localStorage.getItem('cotizacionId')
        if (cotizaId) {
            this.setState({
                cotizacionId: cotizaId
            })
            try {
                const response = await fetch(`/api/cotizaciones/detalles/${cotizaId}`);
                const data = await response.json();
                let detsProd = data.dets.map(dp => ({
                    ...dp,
                    PrecioVenta: dp.precioVenta,
                    IdTipoStock: dp.IdTipoStock,
                    Tributos: dp.Tributos,
                    TasaISC: dp.TasaISC,
                    PrecioEspecial: FindPrecioEspecial([], dp),
                    PrecioFamiliar: FindPrecioFamiliar([], dp),
                    PrecioCosto: GetPrecioCosto([], dp),
                    PrecioMenor: FindPrecioMenor([], dp),
                    oldPrecios: [],
                    precioMayor: getPrecioPorMayor([], dp),
                }))
                this.montoTotalDetalle(detsProd)
                this.setState({detalles: detsProd})
            } catch (error) {
                this.setState({loading: false, error: error});
            }
        } else
            await this.createCotizacion();

    }

    montoTotalDetalle(items) {
        const itemsTmp = [...items];
        const totales = calculateCotizaciones(itemsTmp);
        this.setState({
            Gravado: totales.gravados,
            Inafecto: decimalAdjust('floor', totales.inafectos, -2),
            Exonerado: decimalAdjust('floor', totales.exonerados, -2),
            IGVPreventa: totales.gravados * 0.18,
            Gratuitas: decimalAdjust('floor', totales.gratuitos, -2),
            totalMonto: decimalAdjust('floor', totales.total, -2),
            ICBPERPreventa: decimalAdjust('floor', totales.icbper, -2),
            redondeo: 0
        })
    }

    async createCotizacion() {
        try {
            let res = await fetch("/api/cotizaciones/", {
                method: "POST",
                body: JSON.stringify({
                    IdCliente: this.state.IdCliente,
                    Estado: this.state.Estado,
                    IdMoneda: this.state.IdMoneda,
                    TasaIGV: this.state.TasaIGV,
                    TasaICBPER: this.state.TasaICBPER,
                    Total: this.state.totalMonto,
                    descripcion: this.state.descripcion
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })

            if (res.ok) {
                let data = await res.json();
                window.localStorage.setItem('cotizacionId', data.id);
                this.setState({cotizacionId: data.id})
            } else
                this.setState({error: "Error en la petición"})

        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    handleChangeBuscar(e) {
        this.setState({
            buscar: e.target.value
        });
    }

    async AgregarCantidadDet(item) {
        this.setState({load: true});
        const items = [...this.state.detalles]
        const i = items.indexOf(item)
        items[i].cantidad++
        items[i].Cantidad = items[i].cantidad
        FindPrecioPorMayor([], items[i])
        items[i].total = calcTotal(item, false, 'cantidad', 'precioVenta');
        this.setState({detalles: items})
        this.montoTotalDetalle(items)
        await this.updateDetCotizacion(item)
        await this.updateCotizacion();
        this.setState({load: false});
    }

    async updateCotizacion() {
        try {
            await fetch(`/api/cotizaciones/${this.state.cotizacionId}`, {
                method: "PUT",
                body: JSON.stringify({
                    total: this.state.totalMonto
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    async updateDetCotizacion(item) {
        try {
            await fetch(`/api/cotizaciones/detalles/${item.id}`, {
                method: "PUT",
                body: JSON.stringify({
                    idCotizacion: item.idCotizacion,
                    cantidad: item.cantidad,
                    total: item.total,
                    precioVenta: item.precioVenta,
                    valorUnitario: item.valorUnitario,
                    idAfectacionIgv: item.idAfectacionIgv,
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    async getRUSS() {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/preVentas/getEmpresaByUser`);
            const data = await response.json();
            this.setState({RUSS: data[0].EsRuss})
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    async getProductos(idTipoStock = TIPO_STOCK.CON_COMPROBANTE, query = '') {
        this.setState({loading: true, error: null});

        try {
            const response = await fetch(`/api/preVentas/?search=${query}&idTipoStock=${idTipoStock}`);
            const productos = await response.json();
            this.setState({
                loading: false,
                dataProductos: productos,
            });
            await this.TributosInProductos(this.state.dataProductos.productos)
        } catch (error) {
            this.setState({loading: false, error: error});
            console.log("getProductos error: ", error)
        }
    }

    async TraerClientePorDoc(DNI) {
        try {
            await fetch(`/api/preVentas/traerCliente/${DNI}`)
                .then(res => res.json())
                .then(data => {

                    this.setState({
                        loading: false,
                        dataCliente: data[0] ? data[0] : 0,
                        IdCliente: data[0] ? data[0].IdCliente : 0,
                        StockPorCliente: []
                    });
                    if (data[0]) {
                        if (this.state.RUSS === "Inactivo") {
                            if (this.state.IdTipoDocumentoSunat !== VALE) {
                                if (data[0].IdTipoDocumento === 1 || data[0].IdTipoDocumento === 7) {
                                    this.setState({
                                        IdTipoDocumentoSunat: BOLETA,
                                        filtroTipoStock: 1,
                                        checkfiltroTipoStock: true
                                    })
                                } else {
                                    this.setState({
                                        IdTipoDocumentoSunat: FACTURA,
                                        filtroTipoStock: 1,
                                        checkfiltroTipoStock: true
                                    })
                                }
                            }
                        }
                    }
                });

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    async AddProductoInList(item) {
        this.setState({load: true});
        $("#InputBuscarProd").focus();
        let detalles = [...this.state.detalles];
        let tmpDetails = [];
        let itemFounded = detalles.find(i => (String(i.IdPresentacion) === String(item.IdPresentacion)));

        if (itemFounded) {
            itemFounded.cantidad++;
            itemFounded.Cantidad = itemFounded.cantidad;
            FindPrecioPorMayor([], itemFounded)
            itemFounded.total = calcTotal(itemFounded, false, 'cantidad', 'precioVenta');
        } else {
            tmpDetails = detalles.map(dp => ({
                ...dp,
                cantidad: dp.cantidad,
                Cantidad: dp.Cantidad,
                precioVenta: dp.precioVenta,
                total: dp.total,
                id: dp.id,
                idStock: dp.IdStock,
                Tributos: dp.Tributos,
                PrecioEspecial: FindPrecioEspecial([], dp),
                PrecioFamiliar: FindPrecioFamiliar([], dp),
                PrecioCosto: GetPrecioCosto([], dp),
                PrecioMenor: FindPrecioMenor([], dp),
                precioMayor: getPrecioPorMayor([], dp),
                oldPrecios: dp.oldPrecios,
                idPresentacion: dp.IdPresentacion,
                tipoCambio: dp.TipoCambio,
                initialAfectGrat: item.idAfectacionIgv,
                idAlmacen: dp.IdAlmacen,
                idAfectacionIgv: dp.IdAfectacionIgv,
                valorUnitario: dp.ValorUnitario,
            }))
            FindPrecioPorMayor([], item)
            tmpDetails.push({
                ...item,
                cantidad: 1,
                Cantidad: 1,
                precioVenta: item.precioVenta,
                total: item.precioVenta,
                id: "",
                idStock: item.IdStock,
                Tributos: item.Tributos,
                idPresentacion: item.IdPresentacion,
                PrecioEspecial: FindPrecioEspecial([], item),
                PrecioFamiliar: FindPrecioFamiliar([], item),
                PrecioCosto: GetPrecioCosto([], item),
                PrecioMenor: FindPrecioMenor([], item),
                precioMayor: getPrecioPorMayor([], item),
                oldPrecios: [item.precioVenta],
                initialAfectGrat: item.idAfectacionIgv,
                tipoCambio: item.TipoCambio,
                idAlmacen: item.IdAlmacen,
                idAfectacionIgv: item.IdAfectacionIgv,
                valorUnitario: item.ValorUnitario,
            })
        }

        let dets = itemFounded ? detalles : tmpDetails;
        let targetItem = itemFounded ? itemFounded : tmpDetails[tmpDetails.length - 1];

        this.montoTotalDetalle(dets)

        if (itemFounded)
            await this.updateDetCotizacion(targetItem)
        else
            await this.addDetCotizacion(targetItem)

        await this.updateCotizacion();
        this.setState({load: false, buscar: '', detalles: dets});
    }

    async addDetCotizacion(item) {
        try {
            let res = await fetch("/api/cotizaciones/detalles", {
                method: "POST",
                body: JSON.stringify({
                    idCotizacion: this.state.cotizacionId,
                    cantidad: item.cantidad,
                    total: item.total,
                    idStock: item.idStock,
                    idPresentacion: item.idPresentacion,
                    idAfectacionIgv: item.idAfectacionIgv,
                    precioVenta: item.precioVenta,
                    tipoCambio: item.tipoCambio,
                    idAlmacen: item.idAlmacen,
                    valorUnitario: item.valorUnitario
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })

            let data = await res.json()
            item.id = data.id;
            item.idCotizacion = this.state.cotizacionId
            let detsItem = [...this.state.detalles];
            let index = detsItem.indexOf(detsItem);
            detsItem[index] = item
            this.setState({detalles: detsItem})

        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    DocChange(e) {
        this.setState({
            Documento: e.target.value
        }, () => {
            if (this.state.Documento.length === 8 ||
                this.state.Documento.length === 11 ||
                this.state.Documento.length === 9) {
                this.TraerClientePorDoc(this.state.Documento)

            }
        });
    }

    setActiveInputDoc(e, inputName) {
        e.target.select()
        this.setState({
            inputName: inputName
        });
    }

    handleClickNuevo() {
        this.setState({
            modalClienteIsOpen: true,
            itemcliente: {
                ...this.state.itemcliente,
                idcliente: null,
            }
        })
    }

    handleClickBuscar() {
        this.setState({
            statusModalSearchCliente: true
        })
    }

    handleCloseBuscar() {
        this.setState({
            statusModalSearchCliente: false
        })
    }

    AliasChange(e) {
        this.setState({
            Alias: e.target.value
        });
    }

    async handleEnterKeyUp(e) {
        const tipoStock = TIPO_STOCK.CON_COMPROBANTE;
        if (e.key === "Enter") {
            this.setState({searchDisabled: true})
            await this.getProductos(tipoStock, this.state.buscar);
            this.setState({searchDisabled: false})
            const productos = this.state.dataProductos.productos;
            if (productos.length === 1) {
                this.AddProductoInList(productos[0])
                this.setState({
                    buscar: "",
                    searchDisabled: false
                })
            } else {
                this.setState({
                    buscar: "",
                    searchDisabled: false
                })
                notificationNoHayProducto()
            }
            this.getProductos(tipoStock, this.state.buscar);
        } else {
            this.getProductos(tipoStock, this.state.buscar);
        }
    }

    handleChange(e) {
        this.setState({
            buscar: e.target.value
        });
    }

    onSecretKeyPress(event, detItem) {
        if (event.ctrlKey && event.shiftKey && event.which === NUMBER_KEYS.THREE_NUMBER_KEY)
            this.findPrecioUnitario(detItem, detItem.precio, "Mayor")
    }

    async findPrecioUnitario(item, value, precioSelectedText) {
        this.setState({load: true});
        await this.selectPrecioChange(item, value, precioSelectedText)
        this.setState({load: false});
    }

    async selectPrecioChange(item, value, precioSelectedText) {
        let listaItems = [...this.state.detalles]
        const key = listaItems.indexOf(item)
        let newItem = listaItems[key];
        const precio = item.precios.find(p => p.NombrePrecio.includes(precioSelectedText));

        newItem.precio = precio ? precio.Precio : 0
        newItem.precioVenta = precio ? precio.Precio : 0
        newItem.valorUnitario = precio ? precio.ValorUnitario : 0;
        newItem.total = calcTotal({Cantidad: listaItems[key].cantidad, PrecioVenta: newItem.precioVenta})
        listaItems[key] = newItem;

        this.setState({detalles: listaItems})
        await this.montoTotalDetalle(listaItems)
        await this.updateDetCotizacion(listaItems[key])
        await this.updateCotizacion();
    }

    PrecioChange(e, item) {
        this.findPrecioUnitario(item, e.target.value, e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text)
    }

    InputCantidadDetalleChange(event, item) {
        const items = [...this.state.detalles]
        const i = items.indexOf(item)
        const value = isNumber(event.target.value) ? event.target.value : 1;
        if (value >= 0) {
            items[i].cantidad = items[i].IdUnidad === 58 || items[i].IdUnidad === 59 ? parseInt(value) : value;
            items[i].Cantidad = items[i].cantidad
            FindPrecioPorMayor([], items[i])
            items[i].total = calcTotal(items[i], false, 'cantidad', 'precioVenta')
            this.setState({detalles: items});
            this.montoTotalDetalle(items);
        } else
            notificationCantidadNegativa()
    }

    InputPrecioVentaChange(event, item) {
        this.precioVentaChange(event.target.value, item)
    }

    precioVentaChange(val, item) {
        const listaItems = [...this.state.detalles]
        const key = listaItems.indexOf(item)
        const value = val;
        const itemcalculo = this.getItemCalculo(item);

        listaItems[key].oldPrecios.push(listaItems[key].precioVenta);
        listaItems[key].precioVenta = value
        listaItems[key].valorUnitario = setValorUnitario(itemcalculo)
        listaItems[key].total = calcTotal(listaItems[key], false, 'cantidad', 'precioVenta')
        this.setState({detalles: listaItems})
    }

    getItemCalculo(item) {
        return {
            "PrecioVenta": item.PrecioVenta,
            "Tributos": item.Tributos,
            "TasaISC": item.TasaISC,
            "Cantidad": item.cantidad,
            "Gratuito": item.Gratuito,
            "Descuento": item.Descuento
        }
    }

    async RestarCantidadDet(item) {
        this.setState({load: true});

        const items = [...this.state.detalles]
        const i = items.indexOf(item)

        if (items[i].cantidad > 1) {
            items[i].cantidad--
            items[i].Cantidad = items[i].cantidad
            items[i].total = calcTotal(items[i], false, 'cantidad', 'precioVenta')

            this.setState({detalles: items})
            this.montoTotalDetalle(items)
            await this.updateDetCotizacion(item)
            await this.updateCotizacion();
        }
        this.setState({load: false});
    }

    async RemoveProductoInList(item) {
        this.setState({load: true});

        let detsVenta = [...this.state.detalles];
        detsVenta.splice(detsVenta.indexOf(item), 1)
        this.setState({detalles: detsVenta})

        this.montoTotalDetalle(detsVenta)
        await this.removeDetCotizacion(item)
        await this.updateCotizacion();
        this.setState({load: false});
    }

    async removeDetCotizacion(item) {
        this.setState({loading: true, error: null});
        try {
            await fetch(`/api/cotizaciones/detalles/${item.id}`, {
                method: "DELETE",
                headers: {Accept: "application/json"}
            });
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    async onBlurCantidad(e, item) {
        this.setState({load: true});
        await this.updateCotizacion();
        await this.updateDetCotizacion(item)
        this.setState({load: false});
    }

    async onBlurPrecioVenta(e, item) {
        const {value} = e.target;
        if (value < item.PrecioCosto) {
            new Noty({
                type: "warning",
                theme: "bootstrap-v4",
                layout: "topCenter",
                text: `El precio ingresado es menor al precio de costo`,
                timeout: 1500
            }).show()
            let precio = item.oldPrecios.reverse().find(g => Number(g) >= Number(item.PrecioCosto));
            this.precioVentaChange(precio || item.PrecioCosto, item)
        } else {
            this.setState({load: true});
            this.montoTotalDetalle(this.state.detalles)
            await this.updateDetCotizacion(item);
            await this.updateCotizacion();
            this.setState({load: false});
        }
    }

    AsignarValorDoc(dataCliente) {
        this.setState({
            Documento: dataCliente.NroTipoDocumento
        })
        this.TraerClientePorDoc(dataCliente.NroTipoDocumento)
    }

    handleCloseModal() {
        this.setState({modalClienteIsOpen: false})
    }

    handleKeyUp(event) {
        if (event.key === "Escape" || event.key === "Enter") {
            this.setState({
                statusModal: false,
                statusModalDescuento: false
            });
        }
    }

    GetCliente(cliente) {
        this.handleCloseBuscar()
        this.setState({
            Documento: cliente.NroTipoDocumento
        })
        this.TraerClientePorDoc(cliente.NroTipoDocumento)
    }

    async TributosInProductos(prods) {
        let productos = [...prods];
        for (let i = 0; i < productos.length; i++) {
            const itemCalculado = setPreciosYDescuento(productos[i])
            productos[i].Precio = itemCalculado.PrecioVenta
            productos[i].precioVenta = itemCalculado.PrecioVenta
        }
        this.setState({dataProductos: {productos: productos}})
    }

    async saveCotizacion() {

        let payload = {
            estado: "GENERADO",
            fechaEmision: moment(new Date()).format("YYYY-MM-DD HH:mm"),
            descripcion: this.state.Alias || "",
            idCliente: this.state.IdCliente,
        }
        let res = await fetch(`/api/cotizaciones/update/${this.state.cotizacionId}`, {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        })
        if (res.ok) {
            window.localStorage.removeItem('cotizacionId')
            this.props.history.push("/cotizaciones")
        } else
            notificarError("No se pudo actualizar la cotización.")
    }

    render() {
        const productos = this.state.dataProductos.productos;

        return (
            <React.Fragment>
                <div className="preventa__container">
                    <div className="preventa">
                        <div className="encabezado">
                            <BuscarClientePorDoc
                                goBack={this.props.history.goBack}
                                DocChange={this.DocChange}
                                Documento={this.state.Documento}
                                setActiveInputDoc={this.setActiveInputDoc}
                                TraerClientePorDoc={this.TraerClientePorDoc}
                                dataCliente={this.state.dataCliente}
                                handleClickNuevo={this.handleClickNuevo}
                                handleClickBuscar={this.handleClickBuscar}
                                handleCloseBuscar={this.handleCloseBuscar}
                                Alias={this.state.Alias}
                                placeholder={"Descripción/Observación"}
                                aliasAsText={true}
                                AliasChange={this.AliasChange}
                            />

                            <div>
                                <input
                                    type="text"
                                    id="InputBuscarProd"
                                    placeholder="Buscar Producto"
                                    className="input__linea mr-2"
                                    onKeyUp={this.handleEnterKeyUp}
                                    disabled={this.state.searchDisabled}
                                    value={this.state.buscar}
                                    onChange={this.handleChange}
                                    autoFocus/>
                            </div>

                            <div className="form-group encabezadoPV">
                                <ListaProductosPreventa
                                    load={this.state.load}
                                    productos={productos}
                                    showStock={false}
                                    AddProductoInList={this.AddProductoInList}
                                    checkedTipoStock={true}
                                />
                            </div>
                        </div>

                        <div className="preventa__container_data">

                            <div className="preventa__cliente2">
                                {
                                    (this.state.dataCliente.IdTipoDocumento) ?
                                        (
                                            (this.state.dataCliente.IdTipoDocumento === 1 || this.state.dataCliente.IdTipoDocumento === 7) ?
                                                this.state.dataCliente.ApellidoPaterno
                                                    .concat(` ${this.state.dataCliente.ApellidoMaterno} ${this.state.dataCliente.PrimerNombre}`)
                                                : this.state.dataCliente.RazonSocial
                                        )
                                        : "CLIENTE NO REGISTRADO"
                                }
                            </div>

                            <DetalleCotizaciones
                                load={this.state.load}
                                conStockChecked={true}
                                detalles={this.state.detalles}
                                onSecretKeyPress={this.onSecretKeyPress}
                                venderConListaPrecios={true}
                                PrecioChange={this.PrecioChange}
                                InputCantidadDetalleChange={this.InputCantidadDetalleChange}
                                InputPrecioVentaChange={this.InputPrecioVentaChange}
                                RestarCantidadDet={this.RestarCantidadDet}
                                AgregarCantidadDet={this.AgregarCantidadDet}
                                RemoveProductoInList={this.RemoveProductoInList}
                                onBlurInputCantidad={this.onBlurCantidad}
                                onBlurPrecioVenta={this.onBlurPrecioVenta}
                            />
                        </div>

                        <ModalCliente isOpen={this.state.modalClienteIsOpen} onClose={this.handleCloseModal}
                                      title="NUEVO CLIENTE">
                            <ClienteNuevo onClose={this.handleCloseModal}
                                          NumeroDocumento={this.state.Documento}
                                          AsignarValorDoc={this.AsignarValorDoc}
                            />
                        </ModalCliente>
                        <Modal
                            isOpen={this.state.statusModalSearchCliente}
                            onClose={this.handleCloseBuscar}
                            handleKeyUp={this.handleKeyUp}
                        >
                            <ModalBuscarCliente
                                GetCliente={this.GetCliente}
                            />
                        </Modal>
                    </div>
                    <div className="totals__container">
                        <div className="totals__container--item">
                            {parseFloat(this.state.Gravado) > 0 ?
                                <p>
                                    Gravado{" "}
                                    <strong>
                                        {Number(this.state.Gravado).toFixed(2)}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.Inafecto) > 0 ?
                                <p>
                                    Inafecto{" "}
                                    <strong>
                                        {this.state.Inafecto}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.Exonerado) > 0 ?
                                <p>
                                    Exonerado{" "}
                                    <strong>
                                        {this.state.Exonerado}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.Gratuitas) > 0 ?
                                <p>
                                    Gratuitas{" "}
                                    <strong>
                                        {this.state.Gratuitas}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.ICBPERPreventa) > 0 ?
                                <p>
                                    ICBPER{" "}
                                    <strong>
                                        {this.state.ICBPERPreventa}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.IGVPreventa) > 0 ?
                                <p>
                                    IGV{" "}
                                    <strong>
                                        {Number(this.state.IGVPreventa).toFixed(2)}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.redondeo) > 0 ?
                                <p>
                                    Redondeo <strong>
                                    {decimalAdjust('floor', this.state.redondeo, -2)}
                                </strong>
                                </p>
                                : null
                            }

                        </div>
                        <div className="totals__container--item-total">
                            <span>{this.state.totalMonto}
                            </span>
                        </div>
                        <div className="totals__container--item">
                            <button
                                disabled={!(_.isNull(this.state.error) || _.isEmpty(this.state.error) || _.isUndefined(this.state.error))}
                                className="generar__boton btn btn-success"
                                type="submit"
                                onClick={this.saveCotizacion}
                            >
                                Crear
                                {_.isNull(this.state.error) || _.isEmpty(this.state.error) || _.isUndefined(this.state.error) ? "" : "Ah ocurrido un error"}
                            </button>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

import Noty from "noty";
import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";

export function notificationSuccess() {
    new Noty({
        type: "success",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: "Cambios realizados con éxito!!",
        timeout: 3000
    }).show();
}

export function notificationAdd() {
    new Noty({
        type: "success",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: "Almacén agregado exitosamente!!",
        timeout: 3000
    }).show();
}

export function notificationError() {
    new Noty({
        type: "warning",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: "Datos inválidos",
        timeout: 1500
    }).show();
}

export function notificationDarDeBaja() {
    new Noty({
        type: "error",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: "Almacén deshabilitado!!",
        timeout: 3000
    }).show();
}

export function notificationDarDeAlta() {
    new Noty({
        type: "success",
        theme: "bootstrap-v4",
        layout: "topCenter",
        text: "Almacén reactivado exitosamente!!",
        timeout: 3000
    }).show();
}

export function notificarError(msg, layout = "topCenter") {
    new Noty({
        type: "error",
        theme: "bootstrap-v4",
        layout: layout,
        text: msg,
        timeout: 3000
    }).show();
}

export function notificarMsg(msg, type = 'success', layout = "topCenter") {
    new Noty({
        type: type,
        theme: "bootstrap-v4",
        layout: layout,
        text: msg,
        timeout: 3000
    }).show();
}

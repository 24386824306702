import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'

const LinkButton = ({
  to,
  state,
  onClick,
  // ⬆ filtering out props that `button` doesn’t know what to do with.
  ...rest
}) => {
  const history = useHistory();
  return (
    <button
      {...rest} // `children` is just another prop!
      onClick={(event) => {
        onClick && onClick(event)
        history.push(to, state)
      }}
    />
  )
}

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default LinkButton

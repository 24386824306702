import React from 'react'
import {BotonCancelarLetras, BotonCancelarSinLetras, BotonLetras, BotonAdd} from '../Plantillas/Botones';
import ClientesNuevo from './ClientesNuevo';
import {DNI} from "../../Global";

class ClientesForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showApiUrls: props.formValues.IdTipoDocumento === String(DNI),
        }
    }

    evaluateShowApiUrls(e) {
        this.setState({showApiUrls: e.target.value === String(DNI)})
    }

    render() {
        return (

            <form onSubmit={this.props.onSubmit} className="grid">
                <ul className="nav nav-tabs" id="pills-tab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link text-secondary active" id="pills-home-tab" data-toggle="pill"
                           href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Datos
                            Generales</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-secondary" id="pills-ubigeo-tab" data-toggle="pill"
                           href="#pills-ubigeo" role="tab" aria-controls="pills-profile" aria-selected="false">Ubicación
                            y Contacto</a>
                    </li>
                    {/*<li className="nav-item">*/}
                    {/*    <a className="nav-link text-secondary" id="pills-profile-tab" data-toggle="pill"*/}
                    {/*       href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Condiciones*/}
                    {/*        de Contribuyente</a>*/}
                    {/*</li>*/}
                    {/*<li className="nav-item">*/}
                    {/*    <a className="nav-link text-secondary" id="pills-contact-tab" data-toggle="pill"*/}
                    {/*       href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Créditos*/}
                    {/*        y Formas de Pago</a>*/}
                    {/*</li>*/}
                    {/*<li className="nav-item">*/}
                    {/*    <a className="nav-link text-secondary" id="pills-estado-tab" data-toggle="pill"*/}
                    {/*       href="#pills-estado" role="tab" aria-controls="pills-estado"*/}
                    {/*       aria-selected="false">Estados</a>*/}
                    {/*</li>*/}
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active p-3" id="pills-home" role="tabpanel"
                         aria-labelledby="pills-home-tab">
                        <div className="form-group row">
                            <div className="col-3">
                                <label>Tipo de Cliente</label>
                                <select onChange={this.props.onChange} className="form-control" name="IdTipoCliente"
                                        value={this.props.formValues.IdTipoCliente}
                                    // disabled={this.props.accion === "Actualizar Cliente" ? true : false}
                                >
                                    <option>seleccionar</option>
                                    <option value="1">Nacional</option>
                                    <option value="2">Extranjero</option>
                                </select>
                            </div>
                            <div className="col-3">
                                <label>Tipo Persona</label>
                                <select onChange={this.props.onChange} className="form-control" name="IdTipoPersona"
                                        value={this.props.formValues.IdTipoPersona}
                                    // disabled={this.props.accion === 'Actualizar Cliente' ? true : false}
                                >
                                    <option>seleccionar</option>
                                    {this.props.formValues.IdTipoCliente == "1" && <option value="1">Natural</option>}
                                    {this.props.formValues.IdTipoCliente == "1" && <option value="2">Jurídico</option>}
                                    {this.props.formValues.IdTipoCliente == "2" &&
                                    <option value="3">No Domiciliado</option>}
                                </select>
                            </div>
                            <div className="col-3">
                                <label>Tipo Documento</label>
                                <select onChange={(e) => {
                                    this.evaluateShowApiUrls(e);
                                    this.props.onChange(e);
                                }} className="form-control" name="IdTipoDocumento"
                                        value={this.props.formValues.IdTipoDocumento}
                                    // disabled={this.props.accion === 'Actualizar Cliente' ? true : false}
                                >
                                    <option>Seleccionar</option>
                                    {this.props.formValues.IdTipoPersona == "1" && <option value="1">DNI</option>}
                                    {this.props.formValues.IdTipoPersona == "1" &&
                                    <option value="6">RUC</option> || this.props.formValues.IdTipoPersona == "2" &&
                                    <option value="6">RUC</option>}
                                    {this.props.formValues.IdTipoPersona == "3" && <option value="7">Pasaporte</option>}
                                    {this.props.formValues.IdTipoPersona == "3" &&
                                    <option value="4">Carnet de Extranjería</option>}
                                </select>
                            </div>
                            <div className="col-3">
                                <label> Nro Documento</label>
                                <div className="input-group mb-3">
                                    <input autoFocus onChange={this.props.onChange}
                                           className="form-control input__linea" type="text" name="NroTipoDocumento"
                                           value={this.props.formValues.NroTipoDocumento}
                                        // disabled={this.props.accion === 'Actualizar Cliente' ? true : false}
                                    />
                                    <div className="input-group-append">
                                        {
                                            this.props.formValues.IdTipoDocumento == "1" &&
                                            <button type="submit" className="btn btn-outline-secondary"
                                                    onClick={this.props.onSubmitDNI}
                                                // disabled={this.props.accion === 'Actualizar Cliente' ? true : false}
                                            >DNI</button>
                                        }
                                        {
                                            this.props.formValues.IdTipoDocumento == "6" &&
                                            <button type="submit" className="btn btn-outline-secondary"
                                                    onClick={this.props.onSubmitRUC}
                                                // disabled={this.props.accion === 'Actualizar Cliente' ? true : false}
                                            >RUC</button>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.showApiUrls && (
                                    <div className="col-3">
                                        <label className="checkbox-togglePreventa">
                                            <label>V1</label>
                                            <div className="togglePreventa mr-1">
                                                <input
                                                    type="checkbox"
                                                    className="toggle-state"
                                                    onChange={this.props.onChangeDniApi}
                                                />
                                                <div className="togglePreventa-inner">
                                                    <div className="indicator"/>
                                                </div>
                                                <div className="active-bg"/>
                                            </div>
                                            <label>V2</label>
                                        </label>
                                    </div>
                                )
                            }
                        </div>

                        {
                            this.props.formValues.IdTipoDocumento == "6" ?
                                <div>
                                    <div className="form-group">
                                        <label>Razon Social / Nombre</label>
                                        <input onChange={this.props.onChange} className="form-control input__linea"
                                               type="text" name="RazonSocial"
                                               value={this.props.formValues.RazonSocial}
                                            // disabled={this.props.formValues.IdTipoDocumento == '1' || this.props.formValues.IdTipoDocumento == '6' ? true : false}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>Nombre Comercial</label>
                                        <input onChange={this.props.onChange}
                                               className="form-control text-uppercase input__linea" type="text"
                                               name="NombreComercial"
                                               value={this.props.formValues.NombreComercial}
                                            // disabled={this.props.formValues.IdTipoDocumento == '1' || this.props.formValues.IdTipoDocumento == '6' ? true : false}
                                        />
                                    </div>
                                </div>
                                :
                                <div className="row m-1">
                                    <div className="form-group mr-2">
                                        <label>Apellido Paterno</label>
                                        <input onChange={this.props.onChange}
                                               className="form-control text-uppercase input__linea" type="text"
                                               name="ApellidoPaterno"
                                               value={this.props.formValues.ApellidoPaterno}
                                            // disabled={this.props.formValues.IdTipoDocumento == '1' || this.props.formValues.IdTipoDocumento == '6' ? true : false}
                                        />
                                    </div>
                                    <div className="form-group mr-2">
                                        <label>Apellido Materno</label>
                                        <input onChange={this.props.onChange}
                                               className="form-control text-uppercase input__linea" type="text"
                                               name="ApellidoMaterno"
                                               value={this.props.formValues.ApellidoMaterno}
                                            // disabled={this.props.formValues.IdTipoDocumento == '1' || this.props.formValues.IdTipoDocumento == '6' ? true : false}
                                        />
                                    </div>
                                    <div className="form-group mr-2">
                                        <label>Primer Nombre</label>
                                        <input onChange={this.props.onChange}
                                               className="form-control text-uppercase input__linea" type="text"
                                               name="PrimerNombre"
                                               value={this.props.formValues.PrimerNombre}
                                            // disabled={this.props.formValues.IdTipoDocumento == '1' || this.props.formValues.IdTipoDocumento == '6' ? true : false}
                                        />
                                    </div>
                                    <div className="form-group mr-2">
                                        <label>Segundo Nombre</label>
                                        <input onChange={this.props.onChange}
                                               className="form-control text-uppercase input__linea" type="text"
                                               name="SegundoNombre"
                                               value={this.props.formValues.SegundoNombre}
                                            // disabled={this.props.formValues.IdTipoDocumento == '1' || this.props.formValues.IdTipoDocumento == '6' ? true : false}

                                        />
                                    </div>
                                </div>
                        }


                    </div>

                    <div className="tab-pane fade p-3" id="pills-ubigeo" role="tabpanel"
                         aria-labelledby="pills-ubigeo-tab">
                        <div className="form-group">
                            <label>Región</label>
                            <select onChange={this.props.onChangeRegion}
                                    className="form-control custom-select col-3 m-2" name="Region"
                                    value={this.props.formValues.Region}>
                                <option value={null}>selecccione región</option>
                                {
                                    this.props.datareg.map((item) => {
                                        return <option key={item.IdUbigeo}
                                                       value={item.CodRegion}> {item.NombreUbigeo} </option>
                                    })
                                }
                            </select>
                            <label>Provincia</label>
                            <select onChange={this.props.onChangeProvincia}
                                    className="form-control custom-select col-3 m-2" name="Provincia"
                                    value={this.props.formValues.Provincia}>
                                <option value={null}>selecccione provincia</option>
                                {
                                    this.props.dataprov.map((item) => {
                                        return <option key={item.IdUbigeo}
                                                       value={item.CodProvincia}> {item.NombreUbigeo} </option>
                                    })
                                }
                            </select>
                            <label>Distrito</label>
                            <select onChange={this.props.onChangeDistrito}
                                    className="form-control custom-select col-3 m-2" name="Distrito"
                                    value={this.props.formValues.Distrito}>
                                <option value={null}>selecccione distrito</option>
                                {
                                    this.props.datadist.map((item) => {
                                        return <option key={item.IdUbigeo}
                                                       value={item.CodDistrito}> {item.NombreUbigeo} </option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group row">
                            <div className="col-12">
                                <label>Dirección</label>
                                <textarea onChange={this.props.onChange}
                                          className="form-control text-uppercase input__linea" type="text"
                                          name="Direccion"
                                          value={this.props.formValues.Direccion}/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-3">
                                <label>Telefono</label>
                                <input onChange={this.props.onChange} className="form-control input__linea" type="text"
                                       name="Telefono"
                                       value={this.props.formValues.Telefono}/>
                            </div>
                            <div className="col-3">
                                <label>Celular</label>
                                <input onChange={this.props.onChange} className="form-control input__linea" type="text"
                                       name="Celular"
                                       value={this.props.formValues.Celular}/>
                            </div>
                            <div className="col-6">
                                <label>Correo Electrónico</label>

                                <input onChange={this.props.onChange} className="form-control input__linea" type="text"
                                       name="CorreoElectronico" placeholder="nombre@ejemplo.com"
                                       value={this.props.formValues.CorreoElectronico}/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-12">
                                <label>Persona Contacto</label>
                                <input onChange={this.props.onChange}
                                       className="form-control text-uppercase input__linea" type="text"
                                       name="PersonaContacto"
                                       value={this.props.formValues.PersonaContacto}/>
                            </div>
                        </div>

                    </div>

                    {/*<div className="tab-pane fade p-3" id="pills-profile" role="tabpanel"*/}
                    {/*     aria-labelledby="pills-profile-tab">*/}
                    {/*    <div className="form-group col-center">*/}
                    {/*        <div className="form-check m-3">*/}
                    {/*            <input onChange={this.props.onChangeCheckBox} className="form-check-input"*/}
                    {/*                   type="checkbox" name="BuenContribuyente"*/}
                    {/*                   checked={this.props.formValues.BuenContribuyente} id="BuenContribuyente"/>*/}
                    {/*            <label className="form-check-label" htmlFor="BuenContribuyente">Buen*/}
                    {/*                Contribuyente</label>*/}
                    {/*        </div>*/}
                    {/*        <div className="form-check m-3">*/}
                    {/*            <input onChange={this.props.onChangeCheckBox} className="form-check-input"*/}
                    {/*                   type="checkbox" name="AgentePercepcion"*/}
                    {/*                   checked={this.props.formValues.AgentePercepcion} id="AgentePercepcion"/>*/}
                    {/*            <label className="form-check-label" htmlFor="AgentePercepcion">Agente Percepción</label>*/}
                    {/*        </div>*/}
                    {/*        <div className="form-check m-3">*/}
                    {/*            <input onChange={this.props.onChangeCheckBox} className="form-check-input"*/}
                    {/*                   type="checkbox" name="AgenteRetencion"*/}
                    {/*                   checked={this.props.formValues.AgenteRetencion} id="AgenteRetencion"/>*/}
                    {/*            <label className="form-check-label" htmlFor="AgenteRetencion">Agente Retención</label>*/}
                    {/*        </div>*/}
                    {/*        <div className="form-check m-3">*/}
                    {/*            <input onChange={this.props.onChangeCheckBox} className="form-check-input"*/}
                    {/*                   type="checkbox" name="Prico"*/}
                    {/*                   checked={this.props.formValues.Prico} id="Prico"/>*/}
                    {/*            <label className="form-check-label" htmlFor="Prico">Prico</label>*/}
                    {/*        </div>*/}
                    {/*        <div className="form-check m-3">*/}
                    {/*            <input onChange={this.props.onChangeCheckBox} className="form-check-input"*/}
                    {/*                   type="checkbox" name="DAOT"*/}
                    {/*                   checked={this.props.formValues.DAOT} id="DAOT"/>*/}
                    {/*            <label className="form-check-label" htmlFor="DAOT">DAOT</label>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="form-group row">*/}
                    {/*        <div className="col-6 m-3">*/}
                    {/*            <label>CIIU</label>*/}
                    {/*            <input onChange={this.props.onChange} className="form-control input__linea" type="text"*/}
                    {/*                   name="CIIU"*/}
                    {/*                   value={this.props.formValues.CIIU}/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*</div>*/}

                    {/*<div className="tab-pane fade p-3" id="pills-contact" role="tabpanel"*/}
                    {/*     aria-labelledby="pills-contact-tab">*/}
                    {/*    <div className="form-group row">*/}
                    {/*        <div className="col-3">*/}
                    {/*            <label>Tipo Moneda</label>*/}
                    {/*            <select onChange={this.props.onChange} className="form-control custom-select "*/}
                    {/*                    name="IdTipoMoneda"*/}
                    {/*                    value={this.props.formValues.IdTipoMoneda}>*/}
                    {/*                <option>seleccionar</option>*/}
                    {/*                {*/}
                    {/*                    this.props.dataMoneda.results.map((item) => {*/}
                    {/*                        return <option key={item.IdMoneda}*/}
                    {/*                                       value={item.IdMoneda}> {item.Nombre} </option>*/}
                    {/*                    })*/}
                    {/*                }*/}
                    {/*            </select>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-6">*/}
                    {/*            <label>Línea de Crédito</label>*/}
                    {/*            <input type="number" min="0" max="100000000" step="0.03"*/}
                    {/*                   onChange={this.props.onChange} className="form-control input__linea"*/}
                    {/*                   name="LineaCredito"*/}
                    {/*                   value={this.props.formValues.LineaCredito}/>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-3">*/}
                    {/*            <label>Forma de Pago</label>*/}
                    {/*            <select onChange={this.props.onChange} className="form-control custom-select"*/}
                    {/*                    name="FormaPago"*/}
                    {/*                    value={this.props.formValues.FormaPago}>*/}
                    {/*                <option value={null}>seleccionar</option>*/}
                    {/*                <option value="Contado">Contado</option>*/}
                    {/*                <option value="Credito">Crédito</option>*/}
                    {/*            </select>*/}
                    {/*        </div>*/}

                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="tab-pane fade p-3" id="pills-estado" role="tabpanel"*/}
                    {/*     aria-labelledby="pills-estado-tab">*/}
                    {/*    <div className="form-group row">*/}
                    {/*        <div className="col-4">*/}
                    {/*            <label>Estado SUNAT</label>*/}
                    {/*            <select onChange={this.props.onChange} className="form-control custom-select "*/}
                    {/*                    name="EstadoSunat"*/}
                    {/*                    value={this.props.formValues.EstadoSunat}>*/}
                    {/*                <option value={null}>seleccionar</option>*/}
                    {/*                <option value="Activo">Activo</option>*/}
                    {/*                <option value="Inactivo">Inactivo</option>*/}
                    {/*            </select>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-4">*/}
                    {/*            <label>Condición Domicilio SUNAT</label>*/}
                    {/*            <select onChange={this.props.onChange} className="form-control custom-select "*/}
                    {/*                    name="Situacion"*/}
                    {/*                    value={this.props.formValues.Situacion}>*/}
                    {/*                <option value={null}>seleccionar</option>*/}
                    {/*                <option value="Habido">Habido</option>*/}
                    {/*                <option value="No Habido">No Habido</option>*/}
                    {/*            </select>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-4">*/}
                    {/*            <label>Estado Sistema</label>*/}
                    {/*            <select onChange={this.props.onChange} className="form-control custom-select "*/}
                    {/*                    name="Estado"*/}
                    {/*                    value={this.props.formValues.Estado}>*/}
                    {/*                <option value="Activo" selected>Activo</option>*/}
                    {/*                <option value="Inactivo">Inactivo</option>*/}
                    {/*            </select>*/}
                    {/*        </div>*/}

                    {/*    </div>*/}
                    {/*</div>*/}

                </div>
                <div className="form-row justify-content-center" align="center">
                    <BotonCancelarSinLetras type="button" onClick={this.props.onClose}
                                            className="mr-2">Cancelar</BotonCancelarSinLetras>
                    <BotonLetras type="submit" onClick={this.props.onSubmit}>{this.props.accion}</BotonLetras>
                    {this.props.respuestaInsert == 'Duplicado' && <p className="text-danger">Cliente Duplicado !!!</p>}
                </div>
                {this.props.error && <p className="text-danger">{this.props.error.message}</p>}
                {this.props.msjerror != '' && <p className="text-danger">{this.props.msjerror}</p>}


            </form>
            // </div>
        );
    }
}

export default ClientesForm;

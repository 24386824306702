import React from 'react';
import './styles/Almacen.css';
import LinkButton from "../componentes/LinkButton";
//componentes
import './styles/PreVenta.css';
import EmpresasTablaList from './empresasComponentes/EmpresasTablaList.js';
import {Link} from 'react-router-dom';
import PageLoading from './PageLoading.js';
//Pdf
import jsPDF from "jspdf";
// import apiFetch from '../helpers/apiFetch';

import ReactExport from "react-data-export";
import { BotonPDF, BotonExcel,BotonConfiguracion, BotonAdd, BotonEditar, BotonMonedas, BotonVehiculos, BotonConductores } from "./Plantillas/Botones";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Empresas extends React.Component{
    constructor(){
        super()
        this.state={
            Activo:'Activo',
            EstRetenedor:"",
            EstPercepcion:"",
            EstadoEmpresa:true,
            ID:'',
            buscar:"",
            data: {
                empresas:[]
            },
            loading: true,
            error:null,
            statusModal: false,
        }
        this.obtenerEmpresas=this.obtenerEmpresas.bind(this)
        this.handleChange=this.handleChange.bind(this)
        this.EstadoEmpresa = this.EstadoEmpresa.bind(this)
        this.handleOnCloseModal = this.handleOnCloseModal.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleSavePDF = this.handleSavePDF.bind(this);

    }

    componentDidMount(){
        this.obtenerEmpresas()
        this.intervalo = setInterval(this.obtenerEmpresas,5000);
    } 
    componentWillUnmount(){
        clearInterval(this.intervalo);
    }

    obtenerEmpresas = async () =>{
        this.setState({loading:true, error:null})
        try {
            const response = await fetch('/api/empresas');
            const data = await response.json();
            this.setState({
                loading: false,
                data: data,
            })
            // console.log(data.empresas)
        }catch(error){
            this.setState({loading: false, error: error})
        }
    }

    DarBajaEmpresa(IdEmpresa,RazonSocial){
        if (window.confirm(`¿Dar de Baja a: ${RazonSocial}?`)) {
            fetch(`/api/empresas/BajaEmpresa/${IdEmpresa}`)
            this.props.history.push('/empresas');
        } 
    }

    DarAltaEmpresa(IdEmpresa,RazonSocial){
        if (window.confirm(`¿Activar a: ${RazonSocial}?`)) {
            fetch(`/api/empresas/AltaEmpresa/${IdEmpresa}`)
            this.props.history.push('/empresas');
        }
    }

    EstadoEmpresa(Estado,IdEmpresa,RazonSocial){
        if (Estado === 'Activo'){
            return(
                <React.Fragment>
                <button className="badge badge-light" onClick={()=>
                    this.DarBajaEmpresa(IdEmpresa,
                    RazonSocial)}>Baja
                </button>
                </React.Fragment>
            )
        }else{
            return(
                <React.Fragment>
                    <button className="badge badge-light" onClick={()=>
                        this.DarAltaEmpresa(IdEmpresa,
                            RazonSocial)}>Activar
                    </button>
                </React.Fragment>
            )
        }
        //console.log(Estado,IdEmpresa,RazonSocial)

    }

    handleOnCloseModal() {
        this.setState({
          statusModal: false
        });
      }
      handleOnClick(IdEmpresa) {
        return () => {
          this.setState({
            statusModal: true,
            ID: IdEmpresa
          })
        //   console.log(IdEmpresa)
        };
      }
      handleKeyUp(event) {
        if (event.key === "Escape") {
          this.setState({
            statusModal: false
          });
        }
      }

    handleChange = (e) => {
        const {name,value}= e.target
        this.setState({
            [name]:value
        })
    }
    EstadoChange = (e) => {
        const { checked } = e.target;
        this.setState({
            EstadoEmpresa: e.target.value
        });

        if (checked === false) {
            this.setState({
                Activo:"Inactivo",
                EstadoEmpresa: checked
            });
          } else {
            this.setState({
                Activo:"Activo",
                EstadoEmpresa: checked
            });
    }
}
    RetenedorChange = (e) => {
        this.setState({
            EstRetenedor: e.target.value
        });
    }
    PercepcionChange = (e) => {
        this.setState({
            EstPercepcion: e.target.value
        });
    }
    //Cambiar acá si es necesario mostrar más datos en el pdf
    handleSavePDF(e) {
        e.preventDefault();
        let doc = new jsPDF({
          orientation: "landscape"
        });
        
        var pageHeight =
          doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth =
          doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
          let empresasFiltradas = this.state.data.empresas.filter((empresa) => {
            return empresa.Ruc.concat(empresa.RazonSocial).concat(empresa.NombreComercial).includes(this.state.buscar.toLowerCase()) 
            && empresa.Estado.includes(this.state.Activo)
            && empresa.Percepcion.includes(this.state.EstPercepcion)
            && empresa.Retenedor.includes(this.state.EstRetenedor)
        })
        doc.setFontSize(10);
        doc.text('"RELACIÓN DE EMPRESAS REGISTRADAS"', 10, 10)
        doc.text(`Empresas que hacen uso de "Mi Factura Perú".`,10,20)
        doc.autoTable({
          body: empresasFiltradas,
          startY: 25,
          margin: 5,
          columns:[
            {header: 'RUC', dataKey: 'Ruc'},
            {header: 'RAZÓN SOCIAL', dataKey: 'RazonSocial'},
            // {header: 'NOMBRE COMERCIAL', dataKey: 'NombreComercial',
            {header: 'DIRECCIÓN', dataKey: 'Direccion'},
            // {header: 'UBIGEO', dataKey: 'Ubigeo'},
            {header: 'TELEFONO', dataKey: 'Telefono'},
            {header: 'CORREO ELECTRÓNICO', dataKey: 'CorreoElectronico'},
            // {header: 'PÁGINA WEB', dataKey: 'PaginaWeb'},
            // {header: 'ESTADO', dataKey: 'Estado'}
          ],
          styles: {
            overflow: "linebreak",
            lineWidth: 0.1,
            tableWidth: "auto",
            columnWidth: "auto",
            halign: "center"
            },
            theme: "plain"
        }).setFontSize(11);
        doc.setFontSize(10);
        doc.setFontType("italic");
        doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");
    
        doc.save("relación_Empresas.pdf");
      }

      getDataExportExcel(dataSet) {
        return [
          {
            columns: [
              {
                title: `RELACION DE EMPRESAS`,
                width: { wpx: 80 }
              }
            ],
            data:[]
          },
          {
            columns:[
              {
                title: `RUC`,
                width: { wpx: 150 }
              },
              {
                title: `RAZÓN SOCIAL`,
                width: { wpx: 150 }
              },
              {
                title: `REPRESENTANTE LEGAL`,
                width: { wpx: 150 }
              },
              {
                title: `DIRECCIÓN `,
                width: { wpx: 150 }
              },
              {
                title: `TELÉFONO `,
                width: { wpx: 80 }
              },
              {
                title: `UBIGEO  `,
                width: { wpx: 150 }
              },
              {
                title: `PLAN SUNAT`,
                width: { wpx: 150 }
              }
            ],
            data: dataSet.map(row => {
              return [
                {
                  value: row.Ruc,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.RazonSocial,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.RepresentanteLegal,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.Direccion,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.Telefono,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.Ubigeo,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                },
                {
                  value: row.Plan,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                  }
                }
              ];
            })
          }
        ];
      }

    render(){

            let empresasFiltradas = this.state.data.empresas.filter((empresa) => {
                return empresa.Ruc.concat(empresa.RazonSocial).concat(empresa.NombreComercial).includes(this.state.buscar.toLowerCase()) 
                && empresa.Estado.includes(this.state.Activo)
                && empresa.Percepcion.includes(this.state.EstPercepcion)
                && empresa.Retenedor.includes(this.state.EstRetenedor)
            })
            const dataExport = this.getDataExportExcel(empresasFiltradas)

            if(this.state.data.error){
                return `Error: ${this.state.error.message}`;
            }

            return(
                <React.Fragment>
                <div id="productosContainer">
                    <div className="container">
                        <div className="  row mb-3" >
                                {/* <input id="buscar" type="text" placeholder="Buscar" value={this.state.buscar}
                                    onChange={this.handleChange}/> */}
                                <div align="right" className="col-12">
                                    <label align="left" className="mr-3">Activas</label>
                                    <label align="left" className="checkbox-toggle">
                                        <div className="toggle">
                                            <input
                                            type="checkbox"
                                            name="Valorizacion"
                                            className="toggle-state"
                                            onChange={this.EstadoChange}
                                            checked={this.state.EstadoEmpresa}
                                            />
                                            <div className="toggle-inner">
                                            <div className="indicator"/>
                                            </div>
                                            <div className="active-bg"/>
                                        </div>
                                    </label>
                                    <BotonAdd 
                                        to="/empresas/add" 
                                        title="Nueva Empresa"
                                        className="mr-3 ml-2"
                                    />
                                    <BotonMonedas
                                    to= "/empresas/precios"
                                    title="Gestión de Precios"
                                    className="mr-3"
                                    />
                                    <BotonVehiculos
                                    to= "/empresas/vehiculos"
                                    title="Gestión de Vehículos"
                                    className="mr-3"
                                    />
                                    <BotonConductores
                                    to="/empresas/transportistas"
                                    title="Gestión de Conductores"
                                    className="mr-3"
                                    />
                                    {/* <LinkButton title="Gestión de Precios" className="btn btn-outline-warning ml-1 mr-1" to= "/empresas/precios"><i className="ml-1 fa fa-coins" ></i></LinkButton> */}
                                    {/* <LinkButton title="Gestión de Vehículos" className="btn btn-outline-dark ml-1 mr-1" to= "/empresas/vehiculos"><i className="fas fa-car"></i></LinkButton> */}
                                    {/* <LinkButton title="Gestión de Transportistas" className="btn btn-outline-success ml-1 mr-1" to= "/empresas/transportistas"><i class="fas fa-biking"></i></LinkButton> */}
                                    <BotonPDF 
                                        className="mr-3"
                                        title="Guardar PDF"
                                        onClick={this.handleSavePDF}
                                    />
                                    <ExcelFile
                                        element={
                                            <BotonExcel 
                                            className="mr-3"    
                                                type="button" 
                                                title="Guardar Excel" 
                                                style={{display:`${this.state.displayButton}`}}/>
                                        }
                                        filename={`Relacion_Empresas`}
                                    >
                                        <ExcelSheet
                                            dataSet={dataExport}
                                            name="EmpresasFile"
                                        >
                                        </ExcelSheet>
                                    </ExcelFile>
                                </div>
                                {/* <label className="m-3"> Retenedor: </label>
                                <select onChange={this.RetenedorChange} className="btn btn-outline-dark dropdown-toggle m-3" aria-haspopup="true" aria-expanded="false">
                                    <option value="" defaultValue>Seleccionar</option>
                                    <option value="Activo">Activo</option>
                                    <option value="Inactivo">Inactivo</option>
                                </select>

                                <label className="m-3"> Percepcion: </label>
                                <select onChange={this.PercepcionChange} className="btn btn-outline-dark dropdown-toggle m-3" aria-haspopup="true" aria-expanded="false">
                                    <option value="" defaultValue>Seleccionar</option>
                                    <option value="Activo">Activo</option>
                                    <option value="Inactivo">Inactivo</option>
                                </select> */}
                            <div className="mt-3">
                            <EmpresasTablaList 
                                ID = {this.state.ID}
                                empresasFiltradas = {empresasFiltradas}
                                EstadoEmpresa = {this.EstadoEmpresa}
                                statusModal={this.state.statusModal}
                                handleOnCloseModal = {this.handleOnCloseModal}
                                handleOnClick = {this.handleOnClick}
                                handleKeyUp = {this.handleKeyUp}
                            />
                            </div>
                            {/* <div align ="right" >
                                <label>Estado de Empresa</label>
                                    <select onChange={this.EstadoChange} className="btn btn-outline-dark dropdown-toggle m-5" aria-haspopup="true" aria-expanded="false">
                                        <option value="Activo">Activos</option>
                                        <option value="Inactivo">Inactivos</option>
                                    </select>
                            </div> */}
                        </div>
                    </div>
                </div>
                </React.Fragment>
            )
    }

}

export default Empresas;
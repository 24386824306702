import React from 'react';
import "react-moment";
import './styles/tabla.css';
import EmpresaCuentaServidor from './empresasComponentes/EmpresaCuentaServidor.js';
import EmpresaSolOse from './empresasComponentes/EmpresaSolOse.js';
//Header
import Header from "../componentes/Header";
import parent_icon_header from "../componentes/images/icons8-barcode-52.png";
import child_icon_header from "../componentes/images/icons8-clipboard-52.png";
//ApiFetch para manejar estados
import swal from "sweetalert";


class ConfigFacElectronica extends React.Component{
    constructor(){
        super()
        this.state={
            IdEmpresa:'',
            NombreCtaCorreoFacturacion:null,
            ClaveCtaCorreoFacturacion:null,
            ServidorSaliente:null,
            Puerto:null,

            dataTD:{
                tipoDocumento:[] //Todos los documentos que estan en la tabla TipoDocumentoSunat
            },
            IdTipoDocTabla:'',
            IdTipoDocumento:2,
            Proveedor:'Sunat',
            UsuarioProveedor: '',
            ClaveProveedor: '',
            Ruta:'',

            dataDet:{
                Detalles:[] //Todos los documentos registrados en una empresa (relleno de la tabla)
            },

            DetallesTemp:[],

            AddOrEdit:1,

            loading: true,
            error:null,
            type: 'password',
        }
        this.obtenerEmpresaCtaFact=this.obtenerEmpresaCtaFact.bind(this)
        this.getTipoDocumento=this.getTipoDocumento.bind(this)

        this.listTipoDocumentoSunat = this.listTipoDocumentoSunat.bind(this)
        this.addTipoDocumentoSunat = this.addTipoDocumentoSunat.bind(this)
        this.LimpiarPantalla = this.LimpiarPantalla.bind(this)
        this.filtrarInfoParaEditar = this.filtrarInfoParaEditar.bind(this)
        this.editTipoDocumentoSunat = this.editTipoDocumentoSunat.bind(this)
        this.addOrEditTipoDocumentoSunat = this.addOrEditTipoDocumentoSunat.bind(this)

        this.editEmpresa=this.editEmpresa.bind(this)
        this.showHide = this.showHide.bind(this);
    }

    componentDidMount = () => {
        this.setState({
            IdEmpresa: this.props.match.params.id
        })
        this.obtenerEmpresaCtaFact(this.props.match.params.id)
        this.getTipoDocumento()
        this.listTipoDocumentoSunat(this.props.match.params.id)
        //this.intervalo = setInterval(this.obtenerEmpresaCtaFact,5000);
        //console.log(this.state)
    }

    // componentWillUnmount = () => {
    //     clearInterval(this.intervalo);
    // }

    obtenerEmpresaCtaFact = async(IdEmpresa) => {
        this.setState({loading:true, error:null})
        try {
            const response = await fetch(`/api/configFactElectronica/configFacElEmpresa/${IdEmpresa}`);
            const data = await response.json();
            this.setState({
                loading: false,
                NombreCtaCorreoFacturacion:data[0].NombreCtaCorreoFacturacion,
                ClaveCtaCorreoFacturacion:data[0].ClaveCtaCorreoFacturacion,
                ServidorSaliente:data[0].ServidorSaliente,
                Puerto:data[0].Puerto,
            })
        }catch(error){
            this.setState({loading: false, error: error})
        }
    }

    editEmpresa = (e)=>{
        fetch(`/api/configFactElectronica/configFacElEmpresa/edit/${this.state.IdEmpresa}`,{
            method:'PUT',
            body: JSON.stringify({
                NombreCtaCorreoFacturacion:this.state.NombreCtaCorreoFacturacion,
                ClaveCtaCorreoFacturacion:this.state.ClaveCtaCorreoFacturacion,
                ServidorSaliente:this.state.ServidorSaliente,
                Puerto:this.state.Puerto,                
            }),
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(responseData => {
            const [[table_data], [header]] = responseData;
            let error = false;
            if (header._estadoInsertarEmpresaFactEl) error = header._estadoInsertarEmpresaFactEl;
            else if (header._estadoInsertarEmpresaFactEl !== true)
              error = header._estadoInsertarEmpresaFactEl;
            if (error) {
              swal("ERROR", error, "error");
            } else {
              const [{ IdEmpresa }] = table_data;
              this.setState({ IdEmpresaNew: IdEmpresa });
              //this.props.history.push("/empresas/");
            }
          })
        .catch(err => console.log(err))
        e.preventDefault()
        //this.LimpiarPantalla();
        //this.props.history.push('/empresas');
    }

    getTipoDocumento = async() => {
            this.setState({loading:true, error:null})
            try {
                const response = await fetch('/api/configFactElectronica/configFacElEmpresa/documentoSunat/tipoDoc');
                const data = await response.json();
                // console.log(data)
                this.setState({
                    loading: false,
                    dataTD: data
                })
            }catch(error){
                this.setState({loading: false, error: error})
            }
        }

        tipoDocumentoChange = (event) => {
            this.setState({
                IdTipoDocumento: event.target.value 
            })
        }

    listTipoDocumentoSunat = async(IdEmpresa) =>{
        //Va la ruta que lista elemento a la tabla DetallesEmpresaProveedorTipoDocSunat
        this.LimpiarPantalla();

        this.setState({loading:true, error:null})
        try {
            const response = await fetch(`/api/configFactElectronica/configFacElDetalles/Detalles/${IdEmpresa}`);
            const data = await response.json();
            // console.log(data)
            this.setState({
                loading: false,
                dataDet: data
            })
        }catch(error){
            this.setState({loading: false, error: error})
        }
        
    }

    addOrEditTipoDocumentoSunat = (e) => {
        //Evaluar si se añadirà uno nuevo o se editará uno ya existente
        e.preventDefault()
        if (this.state.AddOrEdit === 1) {
            this.addTipoDocumentoSunat();
        }else{
            this.editTipoDocumentoSunat();
        }
    }

    addTipoDocumentoSunat = async (e) => {
        //Va la ruta que agrega elementos de tabla DetallesEmpresaProveedorTipoDocSunat
        //e.preventDefault()
        try{
            const res = await fetch(`/api/configFactElectronica/configFacElEmpresa/DetallesAdd/add`,{
                method:'POST',
                body: JSON.stringify({
                    IdEmpresa: this.state.IdEmpresa,
                    Proveedor: this.state.Proveedor,
                    IdTipoDocumentoSunat: this.state.IdTipoDocumento,
                    UsuarioProveedor: this.state.UsuarioProveedor,
                    ClaveProveedor: this.state.ClaveProveedor,
                    Ruta: this.state.Ruta,              
                }),
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                }
            })        
            // console.log(res.json())    
        }
        catch (err) {console.log(err)}
        
        this.listTipoDocumentoSunat(this.state.IdEmpresa)
    }

    editTipoDocumentoSunat = async (e) => {
        //e.preventDefault()
        //console.log(this.state)
        try{
            const res = await fetch(`/api/configFactElectronica/configFacElDetalles/DetallesEdit/${this.state.IdTipoDocTabla}`,{
                method:'PUT',
                body: JSON.stringify({
                    Proveedor: this.state.Proveedor,
                    IdTipoDocumentoSunat: this.state.IdTipoDocumento,
                    UsuarioProveedor: this.state.UsuarioProveedor,
                    ClaveProveedor: this.state.ClaveProveedor,
                    Ruta: this.state.Ruta,              
                }),
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                }
            })
            // console.log(res.json())
            
        }
        catch (err) {console.log(err)}
        this.setState({
            AddOrEdit: 1
        })        
        this.listTipoDocumentoSunat(this.state.IdEmpresa)
    }
    
    filtrarInfoParaEditar = (e) => {
        //Vamos a traer la info del tipo de documento que se ha registrado, para posteriormente filtrarlo y para editar
        this.state.DetallesTemp = this.state.dataDet.Detalles.filter((obj) => {
            return obj.IdTabla ==  e.target.value 
        })
       
        this.setState({
            IdTipoDocTabla:e.target.value,
            AddOrEdit: 2
        }) 
        this.llenarInfo()
    }

    llenarInfo = () => {
        this.setState({
            IdTipoDocumento:this.state.DetallesTemp[0].IdTipoDocumentoSunat,
            Proveedor:this.state.DetallesTemp[0].Proveedor,
            UsuarioProveedor: this.state.DetallesTemp[0].Usuario,
            ClaveProveedor: this.state.DetallesTemp[0].Clave,
            Ruta:this.state.DetallesTemp[0].Ruta,

        });
    }

    LimpiarPantalla = () =>{
        this.setState({
            IdTipoDocumento:2,
            Proveedor:'Sunat',
            UsuarioProveedor: '',
            ClaveProveedor: '',
            Ruta:''
        });
        
    }

    handleChange = (event)=>{
        const {name,value}= event.target
        this.setState({
            [name]:value
        })
        
    }

    handleChangeProveedor = (event)=>{
        const {name,value}= event.target
        this.setState({
            [name]:value
        })
    }

    showHide(e){
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            type: this.state.type === 'text' ? 'password' : 'text'
        })  
  }

    render = () => {

        // if(this.state.loading === true && this.state.ConfigFactElectronica === undefined){
        //    return <PageLoading/>;
        // }

        // if(this.state.ConfigFactElectronica.ConfigFactElectronica.length === 0){
        //     return (
        //         <div>
        //             <h4>No se encontraron registros...</h4>
        //             <Link className="btn btn-primary" to= "/empresas/add">Crear Nuevo Empresa</Link>
        //         </div>
        //     );
        // }else{        
        //     if(this.state.ConfigFactElectronica.error){
        //         return `Error: ${this.state.error.message}`;
        //     }
            return(
                <React.Fragment>
                    <Header
                        parent_icon={parent_icon_header}
                        child_icon={child_icon_header}
                        parent="Empresas"
                        child="Agregar"
                    />
                <div className="container text-right">
                    <div className="row justify-content-md-center">
                        <div className="col col-lg-12"  >
                            <div className="card cardEmpresas bg-light ">
                                <div className=" bg-dark text-white text-center card-header">Configuración Facturación Electrónica </div>
                                <div className="card-body">
                                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="false">Cuenta y Servidor</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Sol - Ose</a>
                                            </li>
                                        </ul>

                                        <div className="tab-content" id="pills-tabContent">

                                            {/* ################################  Cuenta y Servidor ################################### */}
                                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                                                <EmpresaCuentaServidor
                                                    state = {this.state}
                                                    editEmpresa = {this.editEmpresa}
                                                    handleChange = {this.handleChange}
                                                    showHide = {this.showHide}
                                                />

                                            </div>  
                                            {/* ################################ SOL - OSE ####################################### */}
                                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                <EmpresaSolOse
                                                    state = {this.state}
                                                    addOrEditTipoDocumentoSunat = {this.addOrEditTipoDocumentoSunat}
                                                    handleChange = {this.handleChange}
                                                    tipoDocumentoChange = {this.tipoDocumentoChange}
                                                    handleChangeProveedor = {this.handleChangeProveedor}
                                                    showHide = {this.showHide}
                                                    filtrarInfoParaEditar = {this.filtrarInfoParaEditar}
                                                />
                                                
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </React.Fragment>
            )
        // }



    }

}

export default ConfigFacElectronica
import React from 'react';
import Select from 'react-select';

const data = [
    { label: "Luis", value:1 },
    { label: "Miriam", value:1 },
    { label: "Rodrigo", value:1 },
    { label: "Luana", value:1 },
    { label: "Anggelo", value:1 },
    { label: "Eugenio", value:1 },
    { label: "Elvira", value:1 },
    { label: "Sabina", value:1 },
    { label: "Gaby", value:1 },
    { label: "Marixa", value:1 },
    { label: "Vanesa", value:1 },
    { label: "Briela", value:1 },
    { label: "Cesar", value:1 },
    { label: "Santos", value:1 }
];


function ComboList(){
    return(
        <div className="container m-4">
            <div className="row">
                <div className="col-md-4">
                </div>
                <div className="col-md-4">
                    <Select options={data} />
                </div>
                <div className="col-md-4">
                </div>
            </div>
        </div>
    );
}

export default ComboList;
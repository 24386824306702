import React from "react";
import '../styles/tabla.css';

class EmpresaCuentaServidor extends React.Component {
    
    render(){
        return (
            <form onSubmit={this.props.editEmpresa}>
                <div className="row form-group col-12 ">
                        <label className="col-3">Nombre Cta Correo </label>
                        <input  name="NombreCtaCorreoFacturacion" 
                            className="empresa_input form-control col-4" 
                            onChange={this.props.handleChange} 
                            defaultValue={this.props.state.NombreCtaCorreoFacturacion} 
                            placeholder="Nombre Cta Correo" 
                            type="email" 
                            />
                    <label className="col-1">Clave</label>
                    <input  name="ClaveCtaCorreoFacturacion" 
                            className="empresa_input form-control col-3" 
                            onChange={this.props.handleChange} 
                            defaultValue={this.props.state.ClaveCtaCorreoFacturacion} 
                            placeholder="Clave" 
                            type={this.props.state.type}
                            />
                    <button className="col-1 bg-dark text-white text-center" onClick={this.props.showHide}>{this.props.state.type === 'text' ? 'Oculto' : 'Ver'}</button>
                </div>

                <div className="row form-group col-12 ">
                    <label className="col-3">Servidor Saliente</label>
                    <input  name="ServidorSaliente" 
                            className="empresa_input form-control col-4" 
                            onChange={this.props.handleChange} 
                            defaultValue={this.props.state.ServidorSaliente} 
                            placeholder="Servidor Saliente" 
                            type="text" 
                            />
                    <label className="col-1">Puerto</label>
                    <input  name="Puerto" 
                            className="empresa_input form-control col-2" 
                            onChange={this.props.handleChange} 
                            defaultValue={this.props.state.Puerto} 
                            placeholder="Puerto" 
                            type="number" 
                            min="900"
                            max="999"
                            step="1"
                            />
                </div>

                <button className="btn btn-primary" type="submit">Guardar <i className="ml-1 fa fa-save"></i></button>
            </form>
        );
    }
}
export default EmpresaCuentaServidor;